import styled, { Color, Size } from 'styled-components'

interface IconProps {
  children: React.ReactNode
  color?: Color
  size?: Size
  onClick?: React.MouseEventHandler<HTMLDivElement>
  triggerEvents?: boolean
  hoverColor?: Color
  className?: string
  style?: React.CSSProperties
}
interface StyledProps {
  readonly $color: Color
  readonly $size: Size
  readonly $isClickable: boolean
  readonly $hoverColor: Color
}

const StyledDiv = styled.div<StyledProps>`
  font-size: ${(props) => props.theme.sizes[props.$size]};
  color: ${(props) => props.theme.colors[props.$color]};
  display: inline-flex;
  ${(props) => props.$isClickable && 'cursor: pointer;'}
  &:hover {
    color: ${(props) => props.theme.colors[props.$hoverColor]};
  }
  .anticon {
    vertical-align: 0;
    margin-right: 0;
  }
  span,
  .MuiSvgIcon-root {
    font-size: 1em;
    color: inherit;
    ${(props) => props.theme.mixins.flexCenter}
  }
  .anticon,
  span,
  .MuiSvgIcon-root {
    transition: ${(props) => props.theme.transitions.default};
  }
`
const Icon: React.FC<IconProps> = ({
  children,
  size = 'm',
  color = 'grayG07',
  onClick,
  hoverColor,
  className,
  style,
}) => {
  return (
    <StyledDiv
      className={className}
      $hoverColor={hoverColor || color}
      onClick={onClick}
      $size={size}
      $color={color}
      $isClickable={!!onClick}
      style={style}
    >
      {children}
    </StyledDiv>
  )
}

export default Icon
