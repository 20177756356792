import Text from 'components/atoms/text'
import styled from 'styled-components'

const StyledDiv = styled.div`
  margin-left: ${(props) => props.theme.sizes.m};
`

const OrganizationModalSectionTemplate: React.FC<{ headerText: string }> = ({ headerText, children }) => {
  return (
    <>
      <Text textStyle="regularText-smallSemiBold">{headerText}</Text>
      <StyledDiv>{children}</StyledDiv>
    </>
  )
}

export default OrganizationModalSectionTemplate
