import { Space, Spin } from 'antd'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'redux/store'
import styled from 'styled-components'
import theme from 'styles/Styles.theme'
import {
  fetchPermissions,
  fetchProjectDisciplines,
  fetchProjects,
  setSelectedProject,
} from 'redux/user-profile/permissions'
import {
  selectFetchPermissionsLoading,
  selectFetchProjectsLoading,
  selectFetchProjectsDisciplinesLoading,
  selectPortfolioProjects,
  selectSelectedProject,
} from 'redux/user-profile/permissions/UserPermissions.selectors'
import FormSelect from 'components/molecules/form-item/FormSelect'
import { t } from '@lingui/macro'
import UserPermissionsTable from './table/UserPermissionsTable'
import UserProfilePermissionsAlert from './UserProfilePermissionsAlert'

const StyledDiv = styled.div`
  width: 400px;
`

const UserProfilePermissions: React.FC = () => {
  const dispatch = useAppDispatch()
  const availableProjects = useSelector(selectPortfolioProjects)
  const selectedProject = useSelector(selectSelectedProject)
  const { isLoading: fetchingProjectsLoading } = useSelector(selectFetchProjectsLoading)
  const { isLoading: fetchingDisciplinesLoading } = useSelector(selectFetchProjectsDisciplinesLoading)
  const { isLoading: fetchingPermissionsLoading } = useSelector(selectFetchPermissionsLoading)
  useEffect(() => {
    dispatch(fetchProjects())
  }, [dispatch])

  useEffect(() => {
    if (selectedProject !== undefined) {
      dispatch(fetchPermissions(selectedProject))
      dispatch(fetchProjectDisciplines(selectedProject))
    }
  }, [dispatch, selectedProject])
  const isLoading = fetchingProjectsLoading
  const isTableLoading = fetchingDisciplinesLoading || fetchingPermissionsLoading
  return (
    <Spin spinning={isLoading}>
      {!fetchingProjectsLoading && (
        <Space direction="vertical" size="middle">
          <StyledDiv
            style={
              selectedProject === undefined
                ? { borderStyle: 'solid', borderWidth: '1px', borderColor: theme.colors.red6 }
                : {}
            }
          >
            <FormSelect
              noMargin
              onChange={(val) => dispatch(setSelectedProject(val as number))}
              value={selectedProject}
              placeholder={t({ id: 'profile.permissions.select_project_placehoder', message: 'Select project' })}
              options={availableProjects.map((p) => ({ label: p.name, value: p.id }))}
            />
          </StyledDiv>
          <Spin spinning={isTableLoading}>
            <UserPermissionsTable />
          </Spin>
        </Space>
      )}
      <UserProfilePermissionsAlert />
    </Spin>
  )
}

export default UserProfilePermissions
