import { InputNumber } from 'antd'
import { FormItemProps } from 'antd/lib/form'
import { InputNumberProps } from 'antd/lib/input-number'
import { WithGetErrors } from 'hooks/useFormValidity'
import BaseFormItem from './BaseFormItem'

const preventNonNumericalInput: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
  const event = e || window.event
  const isBackspace = event.code === 'Backspace'
  if (isBackspace) return
  const charCode = typeof event.which === 'undefined' ? event.keyCode : event.which
  const charStr = String.fromCharCode(charCode)
  const val = Number(`${e.currentTarget.value}${e.key}`)
  const { max, min } = e.currentTarget
  const isValInRange = val <= Number(max) && val >= Number(min)
  if (!charStr.match(/^[0-9]+$/) || !isValInRange) event.preventDefault()
}

const FormInputNumber: React.FC<FormItemProps & InputNumberProps & WithGetErrors> = ({
  placeholder,
  disabled = false,
  onChange,
  value,
  label,
  name,
  rules,
  size,
  onFocus,
  onBlur,
  validateStatus,
  help,
  min,
  max,
  style,
  getErrors,
}) => {
  return (
    <BaseFormItem
      rules={rules}
      label={label}
      name={name}
      validateStatus={validateStatus}
      getErrors={getErrors}
      help={help}
    >
      <InputNumber
        size={size}
        type="number"
        onFocus={onFocus}
        onBlur={onBlur}
        defaultValue={value}
        onChange={onChange}
        placeholder={placeholder && placeholder.toString()}
        disabled={disabled}
        min={min}
        max={max}
        style={style}
        onKeyDown={preventNonNumericalInput}
      />
    </BaseFormItem>
  )
}

export default FormInputNumber
