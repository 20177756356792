import { Trans } from '@lingui/macro'
import { Space } from 'antd'
import Text from 'components/atoms/text'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectFlowConfig,
  selectGettingFlowLoading,
  selectPrevFlowsTableVisibility,
} from 'redux/flow-conifg/general/FlowGeneralConfig.selectors'
import { selectProjectFlows } from 'redux/project/project-config/flow/ProjectFlowConfig.selectors'
import { setPrevFlows, setPrevFlowsTableVisibility } from 'redux/flow-conifg/general/FlowGeneralConfig.slice'
import PrevFlowsTable from './prev-flows-table/PrevFlowsTable'
import FlowGeneralConfigContentAlerts from './FlowGeneralConfigContentAlerts'
import FlowGeneralConfigForm from './FlowGeneralConfigForm'

const FlowGeneralConfigContent: React.FC = () => {
  const flow = useSelector(selectFlowConfig)
  const flows = useSelector(selectProjectFlows)
  const prevFlowsTableVisibility = useSelector(selectPrevFlowsTableVisibility)
  const { isLoading: isGettingFlowLoading } = useSelector(selectGettingFlowLoading)
  const dispatch = useDispatch()
  useEffect(() => {
    if (!prevFlowsTableVisibility) {
      dispatch(setPrevFlows([]))
    }
  }, [dispatch, prevFlowsTableVisibility])
  useEffect(() => {
    if (!isGettingFlowLoading && flow.prevFlows.length !== 0) dispatch(setPrevFlowsTableVisibility(true))
  }, [dispatch, isGettingFlowLoading, flow])

  if (isGettingFlowLoading) return null
  return (
    <Space direction="vertical" size="large">
      <Text textStyle="regularText-medium">
        <Trans id="flow_config.general.heading">General settings</Trans>
      </Text>
      <FlowGeneralConfigForm
        flow={flow}
        isPrevFlowsTableVisible={prevFlowsTableVisibility}
        isEmptyFlowList={flows.filter(({ id }) => id !== flow.id).length === 0}
      />
      {prevFlowsTableVisibility && (
        <PrevFlowsTable prevFlows={flow.prevFlows} currentFlowId={flow.id} availableFlows={flows} />
      )}
      <FlowGeneralConfigContentAlerts />
    </Space>
  )
}

export default FlowGeneralConfigContent
