import { CloseOutlined } from '@ant-design/icons'
import Icon from 'components/atoms/icon'
import SelectLanguage from 'components/molecules/select-language'
import styled from 'styled-components'
import theme from 'styles/Styles.theme'

interface FullScreenModalHeaderProps {
  handleClose?: () => void
  text?: React.ReactNode
}

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  > :nth-child(2) {
    margin-left: auto;
  }
`

const FullScreenModalHeader: React.FC<FullScreenModalHeaderProps> = ({ handleClose, text }) => {
  return (
    <StyledDiv>
      {text}
      <SelectLanguage />
      <Icon style={{ marginLeft: theme.sizes.m }} onClick={handleClose}>
        <CloseOutlined />
      </Icon>
    </StyledDiv>
  )
}

export default FullScreenModalHeader
