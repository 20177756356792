import styled from 'styled-components'

export const StyledContent = styled.div`
  display: grid;
  height: 100%;
  overflow: hidden;
  grid-template-areas:
    'header header header'
    'leftAside main rightAside'
    'leftAside bottomPanel bottomPanel';
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 1fr auto;
  > * {
    position: relative;
  }
`
export const StyledViewer = styled.section`
  grid-row: 1/-1;
  grid-column: 1/-1;
  .cesium-viewer-bottom {
    display: none;
  }
  .cesium-viewer-toolbar {
    display: none;
  }
`
export const StyledTopPanel = styled.div`
  grid-area: header;
  width: 100%;
`
export const StyledLeftAside = styled.aside`
  grid-area: leftAside;
`
export const StyledRightAside = styled.aside`
  grid-area: rightAside;
  display: flex;
`
export const StyledBottomPanel = styled.div`
  grid-area: bottomPanel;
`
