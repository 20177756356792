import { Trans } from '@lingui/macro'
import ButtonElement from 'components/molecules/button-element'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'

const AddingFlowButton: React.FC = () => {
  const history = useHistory()
  const projectId = useSelector(selectProjectId)
  return (
    <ButtonElement type="secondary" onClick={() => history.push(`/flow-config/${projectId}/1`)}>
      <Trans id="project_config.flow.adding_flow_button">Add new flow</Trans>
    </ButtonElement>
  )
}

export default AddingFlowButton
