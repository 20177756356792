import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FormSelect from 'components/molecules/form-item/FormSelect'
import { Form } from 'antd'
import { fetchNewEntryMetadataAndDisciplines } from 'redux/files/files-new-entry'
import {
  selectNewEntryAvailableAttributes,
  selectNewEntryAttributeFields,
  selectFetchingNewEntryMetadataAndDisciplinesLoading,
} from 'redux/files/files-new-entry/FilesNewEntry.selectors'
import { FieldData } from 'models/components'
import { changeNewEntryAttributeField } from 'redux/files/files-new-entry/FilesNewEntry.slice'
import { SpinModalLayout } from 'components/molecules/modal/Modal'
import { selectText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import { disciplineText } from 'data/messages/misc'
import {
  selectFetchingFilesDefaultSuitabilityCodeLoading,
  selectFilesDefaultSuitabilityCode,
} from 'redux/files/files-suitability/FilesSuitability.selectors'
import { fetchDefaultSuitabilityCode } from 'redux/files/files-suitability'
import FileSuitabilityCodeItem from 'components/molecules/files/suitability-code/FileSuitabilityCodeItem'
import StyledDiv from './AddEntry.styles'
import EntryAttributesFormError from './EntryAttributesFormError'

const checkIfDisabled = (fieldData: FieldData<number>[], name: string) => {
  const idx = fieldData.findIndex(
    (field) => field.name === name || (Array.isArray(field.name) && field.name[0] === name)
  )
  if (idx === 0 || idx === -1) return false
  return fieldData[idx - 1].value === undefined
}
const EntryAttributesForm: React.FC = () => {
  const dispatch = useDispatch()
  const availableAttributes = useSelector(selectNewEntryAvailableAttributes)
  const newEntryAttributeFields = useSelector(selectNewEntryAttributeFields)
  const defaultSuitabilityCode = useSelector(selectFilesDefaultSuitabilityCode)
  const { isLoading: isAttribuesFetchingLoading } = useSelector(selectFetchingNewEntryMetadataAndDisciplinesLoading)
  const { isLoading: isFetchingSuitabilityCodeLoading } = useSelector(selectFetchingFilesDefaultSuitabilityCodeLoading)
  const areAttributesFetched = availableAttributes.disciplines.length !== 0 && availableAttributes.metadata.length !== 0
  useEffect(() => {
    if (!areAttributesFetched) {
      dispatch(fetchNewEntryMetadataAndDisciplines())
    }
  }, [dispatch, areAttributesFetched])
  useEffect(() => {
    dispatch(fetchDefaultSuitabilityCode())
  }, [dispatch])
  const onChange = (changedFields: FieldData<number>[]) => dispatch(changeNewEntryAttributeField(changedFields[0]))
  return (
    <StyledDiv>
      <SpinModalLayout spinning={isAttribuesFetchingLoading || isFetchingSuitabilityCodeLoading} size="large">
        <Form layout="vertical" fields={newEntryAttributeFields} onFieldsChange={onChange}>
          {availableAttributes.disciplines.length !== 0 && (
            <FormSelect
              label={trans(disciplineText)}
              showSearch
              placeholder={trans(selectText)}
              name="discipline"
              options={availableAttributes.disciplines.map(({ id, name, code }) => ({
                label: `${code} - ${name}`,
                value: id,
              }))}
            />
          )}
          {availableAttributes.metadata.map(({ name, values, id }) => (
            <FormSelect
              key={id}
              name={name}
              label={name}
              showSearch
              placeholder={trans(selectText)}
              disabled={checkIfDisabled(newEntryAttributeFields, name)}
              options={values.map((value) => ({
                label: `${value.code} - ${value.value}`,
                value: value.id,
              }))}
            />
          ))}
          <FileSuitabilityCodeItem code={defaultSuitabilityCode} />
        </Form>
        <EntryAttributesFormError />
      </SpinModalLayout>
    </StyledDiv>
  )
}

export default EntryAttributesForm
