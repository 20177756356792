import { useSelector } from 'react-redux'
import { Spin, Alert } from 'antd'
import Text from 'components/atoms/text'
import {
  selectGisFileLayers,
  selectFetchGisFileLayersLoading,
} from 'redux/map/map-gis-layers/map-gis-file-layers/MapGisFileLayers.selectors'
import { selectWmsLayers, selectFetchWmsLayersLoading } from 'redux/map/map-wms-layers/MapWmsLayers.selectors'
import MapSideDrawerTree from 'components/molecules/map/map-sidedrawer-tree'
import DividerElement from 'components/atoms/divider'
import {
  selectFetchGisProjectLayersLoading,
  selectGisProjectLayers,
} from 'redux/map/map-gis-layers/map-gis-project-layers/MapGisProjectLayers.selectors'
import { t, Trans } from '@lingui/macro'
import { errorText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import WmsSideDrawerTree from './WmsSideDrawerTree'
import GisFileSideDrawerTree from './GisFileSideDrawerTree'
import GisProjectSideDrawerTree from './GisProjectSideDrawerTree'

const MapSideDrawerLayers: React.FC = () => {
  const gisFileLayers = useSelector(selectGisFileLayers)
  const gisFileLayersLoading = useSelector(selectFetchGisFileLayersLoading)

  const gisProjectLayers = useSelector(selectGisProjectLayers)
  const gisProjectLayersLoading = useSelector(selectFetchGisProjectLayersLoading)

  const wmsLayers = useSelector(selectWmsLayers)
  const wmsLayersLoading = useSelector(selectFetchWmsLayersLoading)

  return (
    <>
      <Text textStyle="header-H2b">
        <Trans id="project.map.sidedrawer.layers_header">Layers</Trans>
      </Text>
      <DividerElement />
      <Text textStyle="regularText-small" color="grayG05">
        <Trans id="project.map.sidedrawer.general_maps_text">General maps</Trans>
      </Text>
      <Spin spinning={wmsLayersLoading.isLoading}>
        <WmsSideDrawerTree data={wmsLayers} />
        {wmsLayersLoading.error && (
          <Alert
            message={trans(errorText)}
            description={t({
              id: 'project.map.sidedrawer.general_layers_fetch_failed',
              message: 'Could not fetch general layers',
            })}
            type="error"
            showIcon
          />
        )}
      </Spin>
      <DividerElement />
      <Text textStyle="regularText-small" color="grayG05">
        <Trans id="project.map.sidedrawer.own_layers_text">Own layers</Trans>
      </Text>
      <MapSideDrawerTree
        data={[
          {
            id: 1,
            name: t({ id: 'project.map.sidedrawer.zones_text', message: 'Zones' }),
            children: [
              {
                id: 2,
                name: 'Obwodnica zatora',
                children: [],
              },
            ],
          },
        ]}
      />
      <DividerElement />
      <Text textStyle="regularText-small" color="grayG05">
        <Trans id="project.map.sidedrawer.project_gis_layers_text">GIS layers (for project)</Trans>
      </Text>
      <Spin spinning={gisProjectLayersLoading.isLoading}>
        <GisProjectSideDrawerTree data={gisProjectLayers} />
        {gisProjectLayersLoading.error && (
          <Alert
            message={trans(errorText)}
            description={t({
              id: 'project.map.sidedrawer.project_gis_layers_fetch_failed',
              message: "Could not fetch project's gis layers",
            })}
            type="error"
            showIcon
          />
        )}
      </Spin>
      <DividerElement />
      <Text textStyle="regularText-small" color="grayG05">
        <Trans id="project.map.sidedrawer.file_gis_layers_text">GIS layers (from files)</Trans>
      </Text>
      <Spin spinning={gisFileLayersLoading.isLoading}>
        <GisFileSideDrawerTree data={gisFileLayers} />
        {gisFileLayersLoading.error && (
          <Alert
            message={trans(errorText)}
            description={t({
              id: 'project.map.sidedrawer.files_gis_layers_fetch_failed',
              message: "Could not fetch project's gis layers",
            })}
            type="error"
            showIcon
          />
        )}
      </Spin>
    </>
  )
}

export default MapSideDrawerLayers
