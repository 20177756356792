import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentStageIndex,
  selectEndedStageIndex,
  selectProjectStages,
} from 'redux/project/project-config/stages/ProjectStagesConfig.selectors'
import { changeStagesOrder } from 'redux/project/project-config/stages/ProjectStagesConfig.slice'
import { selectProjectIsActive } from 'redux/project/project-details/ProjectDetails.selectors'
import styled from 'styled-components'
import StagesDroppable from './StagesDraggable'

const StyledDiv = styled.div`
  display: flex;
  gap: ${(props) => props.theme.sizes.xs};
  align-items: center;
  max-width: 1000px;
  overflow-x: auto;
`

const StagesDragger: React.FC = () => {
  const dispatch = useDispatch()
  const stages = useSelector(selectProjectStages)?.stages || []
  const endedStageIdx = useSelector(selectEndedStageIndex)
  const currentStageIdx = useSelector(selectCurrentStageIndex)
  const isProjectActive = useSelector(selectProjectIsActive)
  return (
    <DragDropContext
      onDragEnd={({ source, destination }) => {
        if (destination) {
          const handleChangeOrder = (): void => {
            dispatch(changeStagesOrder({ sourceIdx: source.index, destinationIdx: destination.index }))
          }
          if (isProjectActive) {
            if (currentStageIdx && destination.index > currentStageIdx) return handleChangeOrder()
            if (endedStageIdx && destination.index > endedStageIdx) return handleChangeOrder()
          } else {
            handleChangeOrder()
          }
        }
        return undefined
      }}
    >
      <Droppable droppableId="droppable" direction="horizontal">
        {(droppableProvided) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <StyledDiv ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
            {stages.map(({ id, name }, idx) => (
              <StagesDroppable key={id} id={id} name={name} idx={idx} />
            ))}
            {droppableProvided.placeholder}
          </StyledDiv>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default StagesDragger
