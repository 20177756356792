import { Tooltip } from 'antd'
import { TooltipProps } from 'antd/lib/tooltip'
import Text from 'components/atoms/text'

interface DisableableTooltipElementProps {
  disabled?: boolean
}

export type TooltipElementProps = DisableableTooltipElementProps & TooltipProps

const TooltipElement: React.FC<TooltipElementProps> = ({ title, placement, children, disabled = false }) => {
  return (
    <Tooltip
      placement={placement}
      title={
        !disabled && (
          <Text textStyle="regularText-small" color="grayG01" paragraph>
            {title}
          </Text>
        )
      }
    >
      <div>{children}</div>
    </Tooltip>
  )
}

export default TooltipElement
