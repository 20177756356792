import Text from 'components/atoms/text'
import trans from 'helpers/i18n.helpers'
import { organizationActiveText, organizationInactiveText } from '../texts'

const DisplayOrganizationActivityStatus: React.FC<{ isActive: boolean }> = ({ isActive }) => {
  const textColor = isActive ? 'green8' : 'red6'
  const text = trans(isActive ? organizationActiveText : organizationInactiveText)
  return (
    <Text textStyle="regularText-small" color={textColor}>
      {text}
    </Text>
  )
}

export default DisplayOrganizationActivityStatus
