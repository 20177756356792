import { ColumnsType } from 'antd/lib/table'
import useTableColumnFiltering, { onFilterStringColumn } from 'components/molecules/table/useTableColumnFiltering'
import { t } from '@lingui/macro'
import { Member } from 'models/project/projectConfig'

const useReviewersSelectionColumns = (): ColumnsType<Member> => {
  return [
    {
      title: t({ id: 'flow_config.form_selection.table.member.name', message: 'Name and surname' }),
      dataIndex: 'name',
      key: 'name',
      width: 240,
      render: (value, { name }) => name,
      ...useTableColumnFiltering(
        t({ id: 'flow_config.form_selection.table.member.name_placeholder', message: 'Search for name' })
      ),
      onFilter: (value: string | number | boolean, record: Member) => onFilterStringColumn(value, record.name),
    },
    {
      title: t({ id: 'flow_config.form_selection.table.member.organization', message: 'Organization' }),
      dataIndex: 'organization',
      key: 'organization',
      width: 240,
      render: (value, { organization }) => organization.abbreviation,
      ...useTableColumnFiltering(
        t({
          id: 'flow_config.form_selection.table.member.organization_placeholder',
          message: 'Search for organization',
        })
      ),
      onFilter: (value: string | number | boolean, record: Member) =>
        onFilterStringColumn(value, record.organization.abbreviation),
    },
  ]
}

export default useReviewersSelectionColumns
