import { Trans } from '@lingui/macro'
import { Space } from 'antd'
import ButtonElement from 'components/molecules/button-element'

interface FullScreenModalFooterProps {
  onNextBtnClick: () => void
  onPrevBtnClick: () => void
  isPrevBtnDisabled: boolean
  isNextBtnDisabled: boolean
  nextBtnText: string
  isLoading?: boolean
}

const FullScreenModalFooter: React.FC<FullScreenModalFooterProps> = ({
  onNextBtnClick,
  onPrevBtnClick,
  isPrevBtnDisabled,
  isNextBtnDisabled,
  nextBtnText,
  isLoading,
}) => {
  return (
    <Space style={{ marginLeft: 'auto' }}>
      <ButtonElement onClick={onPrevBtnClick} type="tertiary" disabled={isPrevBtnDisabled} loading={isLoading}>
        <Trans id="project_config.fullscreen_modal.previous_step">Previous step</Trans>
      </ButtonElement>
      <ButtonElement onClick={onNextBtnClick} disabled={isNextBtnDisabled} loading={isLoading}>
        {nextBtnText}
      </ButtonElement>
    </Space>
  )
}

export default FullScreenModalFooter
