import { RcFile } from 'antd/lib/upload'
import backendAxios from 'axios/axios'
import { BACKEND_SERVER_URL } from 'environment/environment'
import formatDate, { formatTimeString, sortByDate } from 'helpers/Dates.helpers'
import { removedErrorsFromLoading } from 'helpers/HttpError.helpers'
import { Backup, BackupSettings } from 'models/administration'
import {
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
} from 'redux/redux.shared'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { t } from '@lingui/macro'
import { openSuccessNotification } from 'helpers/Notifications.helpers'
import trans from 'helpers/i18n.helpers'
import { noText, yesText } from 'data/messages/misc'
import showConfirmationModal, { showWarningModal } from 'helpers/Modals.helpers'
import { Checkbox, Space, Modal } from 'antd'
import { loadingTypes, LoadingTypes, AdministrationBackupsState, WarningData } from './AdministrationBackups.types'

export const fetchSystemBackupsList = createAsyncBackendThunk('fetchSystemBackupsList', async () => {
  return (await backendAxios.get('/backup/')).data
})

export const createBackup = createAsyncBackendThunk('createBackup', async (delay: number) => {
  const response = await backendAxios.post('/backup/', { delay })
  openSuccessNotification({
    message: t({
      id: 'administration.backups.notifications.created_text',
      message: 'Backup creation has started',
    }),
  })
  return response.data
})

export const deleteBackup = createAsyncBackendThunk('deleteBackup', async (backupId: number) => {
  await backendAxios.delete(`/backup/${backupId}/`)
  openSuccessNotification({
    message: t({
      id: 'administration.backups.notifications.deleted_text',
      message: 'Backup has been deleted',
    }),
  })
})

export const downloadBackupReport = createAsyncBackendThunk('downloadBackupReport', async (backupId: number) => {
  return backendAxios.get(`backup/${backupId}/report/token_for_download/`).then((res) => {
    const link = document.createElement('a')
    link.href = `${BACKEND_SERVER_URL}/backup/${backupId}/report/download/?token=${res.data}`
    link.click()
  })
})

export const downloadBackup = createAsyncBackendThunk(
  'downloadBackup',
  async ({ backupId, withReport }: { backupId: number; withReport: boolean }, { dispatch }) => {
    return backendAxios.get(`backup/${backupId}/token_for_download/`).then((res) => {
      const link = document.createElement('a')
      link.href = `${BACKEND_SERVER_URL}/backup/${backupId}/download/?token=${res.data}`
      link.click()
      if (withReport) {
        showConfirmationModal({
          content: t({
            id: 'administration.backups.download_report_question',
            message: `Do you want to also download backup report?`,
          }),
          okText: trans(yesText),
          cancelText: trans(noText),
          onOk: () => dispatch(downloadBackupReport(backupId)),
        })
      }
    })
  }
)

export const importBackupFromFile = createAsyncBackendThunk('importBackupFromFile', async (file: RcFile) => {
  const formData = new FormData()
  formData.append('file', file)
  const response = await backendAxios.post(`/backup/upload/`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  openSuccessNotification({
    message: t({
      id: 'administration.backups.notifications.imported_text',
      message: 'Backup has been imported',
    }),
  })
  return response.data
})

export const resetSystemToBackupVersion = createAsyncBackendThunk('restoreBackup', async (backupId: number) => {
  return (await backendAxios.post(`backup/${backupId}/load/`)).data
})

const preprocessSettingsResponse: (data: BackupSettings) => BackupSettings = (data) => {
  const [h, m] = data.hour.split(':')
  const date = new Date()
  date.setUTCHours(Number(h))
  return { ...data, hour: `${formatTimeString(date.getHours())}:${formatTimeString(Number(m))}` }
}

export const fetchBackupSettings = createAsyncBackendThunk('fetchBackupSettings', async () => {
  return (await backendAxios.get(`backup/settings/`)).data
})

export const editBackupSettings = createAsyncBackendThunk(
  'editBackupSettings',
  async (data: Partial<BackupSettings>) => {
    const response = await backendAxios.patch(`backup/settings/`, data)
    openSuccessNotification({
      message: t({
        id: 'administration.backups.notifications.settings_edited',
        message: 'Backup settings have been changed',
      }),
    })
    return response.data
  }
)

export const fetchBackupWarning = createAsyncBackendThunk(
  'fetchBackupWarning',
  async ({
    backupWarningModalVisibilityRef,
    backupStartWarningModalAppearedRef,
    showBackupWarningModalAgainRef,
  }: {
    backupWarningModalVisibilityRef: React.MutableRefObject<boolean>
    backupStartWarningModalAppearedRef: React.MutableRefObject<boolean>
    showBackupWarningModalAgainRef: React.MutableRefObject<boolean>
  }) => {
    if (
      backupWarningModalVisibilityRef.current &&
      backupStartWarningModalAppearedRef.current &&
      !showBackupWarningModalAgainRef.current
    )
      return undefined
    const { isInProgress, nextBackup }: WarningData = (await backendAxios.get(`backup/warning/`)).data
    const modalProps = isInProgress
      ? {
          title: t({
            id: 'administration.backups.warning_modal.in_progress_title',
            message: 'Creating system backup in progress.',
          }),
          content: t({
            id: 'administration.backups.warning_modal.in_progress_description',
            message: 'System changes will be suspended while the backup is being created.',
          }),
        }
      : {
          title: t({
            id: 'administration.backups.warning_modal.next_backup_title',
            message: 'Creating system backup will start on {date}.',
            values: { date: formatDate(nextBackup) },
          }),
          content: t({
            id: 'administration.backups.warning_modal.next_backup_description',
            message: 'System changes will be suspended while the backup is being created.',
          }),
        }
    if (
      (isInProgress && !backupStartWarningModalAppearedRef.current) ||
      (nextBackup && showBackupWarningModalAgainRef.current && !backupWarningModalVisibilityRef.current)
    ) {
      let dontShowAgainCheckboxVal = false
      backupWarningModalVisibilityRef.current = true
      if (isInProgress) {
        backupStartWarningModalAppearedRef.current = true
      }
      showWarningModal({
        title: modalProps.title,
        content: (
          <Space direction="vertical">
            <span>{modalProps.content}</span>
            <Checkbox
              onChange={(e) => {
                dontShowAgainCheckboxVal = e.target.checked
              }}
            >
              {t({
                id: 'administration.backups.warning_modal.next_backup_checkbox',
                message: "Don't show again",
              })}
            </Checkbox>
          </Space>
        ),
        onOk: () => {
          backupWarningModalVisibilityRef.current = false
          showBackupWarningModalAgainRef.current = !dontShowAgainCheckboxVal
          if (isInProgress) Modal.destroyAll()
        },
      })
    }
    return undefined
  }
)

const sortBackups: (backup1: Backup, backup2: Backup) => number = (backup1, backup2) =>
  sortByDate(backup1.timestamp || backup1.expectedStartDate || 0, backup2.timestamp || backup2.expectedStartDate || 0)

const initialState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  backups: [],
  importModalData: { isVisible: false },
  isCreateBackupModalVisible: false,
  isSettingsModalVisible: false,
} as AdministrationBackupsState

const administrationBackupsSlice = createSlice({
  name: 'administrationBackups',
  initialState,
  reducers: {
    removeErrors: (state) => {
      state.loading = removedErrorsFromLoading(state.loading)
    },
    setFileModalVisibility: (state, visible: PayloadAction<boolean>) => {
      state.importModalData = { isVisible: visible.payload }
      state.loading = removedErrorsFromLoading(state.loading)
    },
    setCreateBackupModalVisiblity: (state, visible: PayloadAction<boolean>) => {
      state.isCreateBackupModalVisible = visible.payload
    },
    setSettingsModalVisibility: (state, visible: PayloadAction<boolean>) => {
      state.isSettingsModalVisible = visible.payload
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<AdministrationBackupsState, LoadingTypes, Backup[]>({
      promise: fetchSystemBackupsList,
      loadingType: 'fetchSystemBackupsList',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        state.backups = action.payload.sort(sortBackups)
      },
    }),
    generateExtraBackendReducers<AdministrationBackupsState, LoadingTypes, Backup>({
      promise: createBackup,
      loadingType: 'createBackup',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        state.backups.push(action.payload)
        state.backups.sort(sortBackups)
      },
    }),
    generateExtraBackendReducers<AdministrationBackupsState, LoadingTypes, void, number>({
      promise: deleteBackup,
      loadingType: 'deleteBackup',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        state.backups = state.backups.filter((b) => b.id !== action.meta.arg).sort(sortBackups)
      },
    }),
    generateExtraBackendReducers<AdministrationBackupsState, LoadingTypes, Backup>({
      promise: importBackupFromFile,
      loadingType: 'importBackupFromFile',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        state.backups.push(action.payload)
        state.backups.sort(sortBackups)
        state.importModalData.isVisible = false
      },
    }),
    generateExtraBackendReducers<AdministrationBackupsState, LoadingTypes, Backup>({
      promise: resetSystemToBackupVersion,
      loadingType: 'restoreBackup',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
    }),
    generateExtraBackendReducers<AdministrationBackupsState, LoadingTypes, BackupSettings>({
      promise: fetchBackupSettings,
      loadingType: 'fetchBackupSettings',
      onFulfilled: (state, action) => {
        state.settings = preprocessSettingsResponse(action.payload)
      },
    }),
    generateExtraBackendReducers<AdministrationBackupsState, LoadingTypes, BackupSettings>({
      promise: editBackupSettings,
      loadingType: 'editBackupSettings',
      onFulfilled: (state, action) => {
        state.settings = preprocessSettingsResponse(action.payload)
        state.isSettingsModalVisible = false
      },
    }),
  ]),
})

export const {
  removeErrors,
  setFileModalVisibility,
  setCreateBackupModalVisiblity,
  setSettingsModalVisibility,
} = administrationBackupsSlice.actions

export const administrationBackupsReducer = administrationBackupsSlice.reducer
