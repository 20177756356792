import { generateStyledTableWithType } from 'helpers/Styles.helpers'
import { css } from 'styled-components'

const tableMixin = css`
  overflow-x: hidden;
  .ant-table-thead > tr > th {
    font-size: ${({ theme }) => theme.sizes.s};
    color: ${({ theme }) => theme.colors.grayG06};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    background-color: ${({ theme }) => theme.colors.grayG01};
  }
  && {
    th,
    td {
      padding: 0 ${({ theme }) => theme.sizes.s};
      height: ${({ theme }) => theme.sizes.l};
      vertical-align: middle;
    }
  }
  tr:hover .entry-options {
    opacity: 1;
    visibility: visible;
  }
  tr.highlighted-success {
    background-color: ${({ theme }) => theme.colors.success};
  }
  .ant-table-filter-column {
    height: 100%;
  }
`

export default generateStyledTableWithType(tableMixin)
