import { Input } from 'antd'
import { FormItemProps } from 'antd/lib/form'
import { SearchProps } from 'antd/lib/input'
import BaseFormItem from './BaseFormItem'

const FormInputSearch: React.FC<FormItemProps & SearchProps> = ({
  placeholder,
  disabled = false,
  onChange,
  value,
  label,
  name,
  rules,
  size,
  prefix,
  allowClear,
  onSearch,
}) => (
  <BaseFormItem rules={rules} label={label} name={name}>
    <Input.Search
      size={size}
      prefix={prefix}
      allowClear={allowClear}
      value={value}
      onChange={onChange}
      placeholder={placeholder && placeholder.toString()}
      disabled={disabled}
      onSearch={onSearch}
    />
  </BaseFormItem>
)

export default FormInputSearch
