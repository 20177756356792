import { BackendLoading } from 'redux/redux.shared'

export const loadingTypes = ['fetchFlowConfigFinishedSteps', 'addFlowConfigFinishedStep'] as const
export type LoadingTypes = typeof loadingTypes[number]

export const flowConfigSteps = ['GENERAL', 'INITIAL', 'METADATA', 'START_CHANGES', 'EXIT_CHANGES'] as const

export type FlowConfigStep = typeof flowConfigSteps[number]

export interface FlowStepsConfigState {
  loading: Record<LoadingTypes, BackendLoading>
  finishedSteps: FlowConfigStep[]
  finishedStepsLoaded: boolean
}
