import { AttributeValue, Attribute } from 'models/project/projectConfig'
import NameAndId from 'models/shared'
import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import { EditRequest } from './ProjectAttributesConfig.types'

export const selectIsAddingMetadata: (state: RootState) => boolean = (state) =>
  state.project.config.attributes.isAddingMetadata

export const selectMetadata: (state: RootState) => Attribute[] = (state) => state.project.config.attributes.metadata

export const selectAttributeById = (id: number | undefined) => (state: RootState): Attribute | undefined =>
  selectMetadata(state).find((attr) => attr.id === id)

export const selectSelectedMetadata: (state: RootState) => Attribute[] = (state) =>
  selectMetadata(state).filter((metadata) => metadata.selected)

export const selectAvailableMetadata: (state: RootState) => NameAndId[] = (state) =>
  selectMetadata(state)
    .filter((metadata) => !metadata.selected)
    .map(({ id, name }) => ({ id, name }))

export const generateMetadataValueIdsByMetadataIdSelector: (id: number) => (state: RootState) => number[] = (id) => (
  state
) =>
  selectMetadata(state)
    .find((metadata) => metadata.id === id)
    ?.attributeValues.filter((metadata) => metadata.selected)
    .map((attr) => attr.id) as number[]

export const generateSelectedMetadataListIdSelector: (state: RootState) => EditRequest[] = (state) =>
  selectMetadata(state)
    .filter((attr: Attribute) => attr.selected)
    .map((attr: Attribute) => ({
      metadata: attr.id,
      values: attr.attributeValues.filter((metadata) => metadata.selected).map(({ id }) => id),
    }))

export const selectInitialMetadataFetched: (state: RootState) => boolean = (state) =>
  state.project.config.attributes.initialMetadataFetched

export const selectModalVisibility: (state: RootState) => boolean = (state) =>
  state.project.config.attributes.modalData.isVisible

export const selectAttributeInModal: (state: RootState) => Attribute | undefined = (state) =>
  state.project.config.attributes.modalData.attr

export const selectIsAttributesConfigValid: (state: RootState) => boolean = (state) => {
  const filterSelected = (val: AttributeValue) => val.selected
  return selectSelectedMetadata(state).reduce(
    (prev: boolean, curr) => prev && curr.attributeValues.filter(filterSelected).length !== 0,
    true
  )
}

// loadings

export const selectFetchMetadataLoading: (state: RootState) => BackendLoading = (state) =>
  state.project.config.attributes.loading.fetchMetadata

export const selectIsProjectAttributesContentLoading: (state: RootState) => boolean = (state) =>
  selectFetchMetadataLoading(state).isLoading

export const selectSelectMetadataLoading: (state: RootState) => BackendLoading = (state) =>
  state.project.config.attributes.loading.selectMetadata

export const selectPatchMetadataValueLoading: (state: RootState) => BackendLoading = (state) =>
  state.project.config.attributes.loading.patchMetadataValue

export const selectRemoveMetadataLoading: (state: RootState) => BackendLoading = (state) =>
  state.project.config.attributes.loading.removeMetadata

export const selectToggleMetadataRequiredLoading: (state: RootState) => BackendLoading = (state) =>
  state.project.config.attributes.loading.toggleMetadataRequired

export const selectEditMetadataLoading: (state: RootState) => BackendLoading = (state) =>
  state.project.config.attributes.loading.editMetadata
