import { FrontendFile } from 'models/files'
import backendAxios from 'axios/axios'
import { refreshEntries } from 'redux/files/files-documentation'
import { AppDispatch } from 'redux/store'
import { successText } from 'data/messages/controls'
import { defineMessage, t } from '@lingui/macro'
import { openDocumentationErrorNotification, openDocumentationNotification } from './Notifications.helpers'
import trans from './i18n.helpers'

const ACCEPTANCE_ERROR_CODES = {
  'file.deleted': defineMessage({
    id: 'project.files.flow.errors.file_deleted',
    message: 'This file is already deleted',
  }),
  'file.not_newest': defineMessage({
    id: 'project.files.flow.errors.delete_old_revision',
    message: 'Only newest revision may be deleted',
  }),
  'file.not_in_progress': defineMessage({
    id: 'project.files.flow.errors.file_not_in_progress',
    message: 'Files outside WIP directory cannot be deleted',
  }),
  'entry.deleted': defineMessage({
    id: 'project.files.flow.errors.entry_deleted',
    message: 'File is already deleted',
  }),
  'entry.not_in_progress': defineMessage({
    id: 'project.files.flow.errors.entry_not_in_progress',
    message: 'This file is not in WIP directory',
  }),
}

export const deleteFile = async (file: FrontendFile, dispatch: AppDispatch): Promise<void> => {
  const url = `files/${file.fileId}/`
  try {
    await backendAxios.delete(url)
    dispatch(refreshEntries({}))
    openDocumentationNotification('success', {
      message: trans(successText),
      description: t({
        id: 'project.files.flow.revision_deleted',
        message: 'Revision has been deleted',
      }),
    })
  } catch (err) {
    openDocumentationErrorNotification(ACCEPTANCE_ERROR_CODES, err)
  }
}

export const deleteEntry = async (file: FrontendFile, dispatch: AppDispatch): Promise<void> => {
  const url = `entries/${file.entryId}/`
  try {
    await backendAxios.delete(url)
    dispatch(refreshEntries({}))
    openDocumentationNotification('success', {
      message: trans(successText),
      description: t({
        id: 'project.files.flow.file_deleted',
        message: 'File has been deleted',
      }),
    })
  } catch (err) {
    openDocumentationErrorNotification(ACCEPTANCE_ERROR_CODES, err)
  }
}
