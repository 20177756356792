import { useEffect } from 'react'
import { Space } from 'antd'
import Text from 'components/atoms/text'
import Table from 'components/molecules/table'
import FormSelect from 'components/molecules/form-item/FormSelect'
import { cdeStatusOptions } from 'data/cdeStatus'
import { cdeDirectoryText } from 'data/messages/misc'
import trans from 'helpers/i18n.helpers'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'redux/store'
import {
  selectProjectFilteredSuitabilityCodes,
  selectProjectSuitabilityCodesSelectedStatus,
} from 'redux/project/project-config/suitability/ProjectSuitabilityConfig.selectors'
import { fetchSuitabilityOverview, setSelectedStatus } from 'redux/project/project-config/suitability'
import { SelectedStatus } from 'redux/project/project-config/suitability/ProjectSuitabilityConfig.types'
import { t, Trans } from '@lingui/macro'
import styled from 'styled-components'
import sortByStatus from 'helpers/Models.helpers'
import useProjectSuitabilityConfigColumns from './useProjectSuitabilityConfigColumns'
import ProjectSuitabilityAlerts from './ProjectSuitabilityConfigAlerts'

const StyledTable = styled(Table)`
  width: 800px;
`

const ProjectSuitabilityConfigContent: React.FC = () => {
  const dispatch = useAppDispatch()
  const suitabilityCodes = useSelector(selectProjectFilteredSuitabilityCodes)
  const columns = useProjectSuitabilityConfigColumns()
  const selectedStatus = useSelector(selectProjectSuitabilityCodesSelectedStatus)

  useEffect(() => {
    dispatch(fetchSuitabilityOverview())
  }, [dispatch])

  return (
    <Space direction="vertical" size="large">
      <Text textStyle="regularText-medium">
        <Trans id="project_config.suitability.step_header">Suitability codes</Trans>
      </Text>
      <Space direction="vertical" size="small">
        <FormSelect
          bordered
          noMargin
          onChange={(val) => dispatch(setSelectedStatus(val as SelectedStatus))}
          value={selectedStatus}
          label={trans(cdeDirectoryText)}
          options={[
            {
              value: 'ALL',
              label: t({ id: 'administration.suitability.cde_directories_all_text' }),
            },
            ...cdeStatusOptions.map(({ value, label }) => ({ value, label: trans(label) })),
          ]}
        />
        <StyledTable
          columns={columns}
          dataSource={[...suitabilityCodes].sort((c1, c2) => sortByStatus(c1.status, c2.status))}
          bordered
          pagination={false}
          rowKey="id"
          scroll={{ y: 'calc(100vh - 310px)' }}
        />
      </Space>
      <ProjectSuitabilityAlerts />
    </Space>
  )
}

export default ProjectSuitabilityConfigContent
