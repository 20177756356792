import { defineMessage, t } from '@lingui/macro'
import ErrorAlert from 'components/atoms/error-alert'
import { handleApiErrors } from 'helpers/HttpError.helpers'
import { useSelector } from 'react-redux'
import {
  selectFetchingProjectStagesLoading,
  selectRemovingProjectStageLoading,
} from 'redux/administration/administration-project-stages/AdministrationProjectStages.selectors'

const errorDict = {
  'project_stage.is_used': defineMessage({
    id: 'administration.project_stages.is_used_error',
    message: 'Project stage is used.',
  }),
}

const AdministrationProjectStagesAlerts: React.FC = () => {
  const fetchingProjectStagesLoading = useSelector(selectFetchingProjectStagesLoading)
  const removingProjectStageLoading = useSelector(selectRemovingProjectStageLoading)
  const alertMessageDict = [
    {
      error: fetchingProjectStagesLoading.error,
      message: t({ id: 'administration.project_stages.fetch_failed', message: 'Could not retrieve project stages' }),
    },
    {
      error: removingProjectStageLoading.error,
      message: handleApiErrors(errorDict, removingProjectStageLoading.error?.errors),
    },
  ]
  return <ErrorAlert alertMessageDict={alertMessageDict} />
}

export default AdministrationProjectStagesAlerts
