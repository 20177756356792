import { useSelector } from 'react-redux'
import { useLayoutEffect } from 'react'
import { selectAccessToken } from '../redux/login/Login.selectors'
import backendAxios from '../axios/axios'

const useAccessTokenForAxiosBackend = (): void => {
  const accessToken = useSelector(selectAccessToken)
  useLayoutEffect(() => {
    if (accessToken) {
      backendAxios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
      delete backendAxios.defaults.headers.common.Authorization
    }
  }, [accessToken])
}

export default useAccessTokenForAxiosBackend
