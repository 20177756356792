import { TransferUser } from 'components/organisms/project-config/members/content/modal/UsersTransfer'
import { Member, MemberOrganization } from 'models/project/projectConfig'
import { ActiveUser } from 'models/users'
import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import { ProjectMembersConfigState } from './ProjectMembersConfig.types'

const selectProjectMembersConfig: (state: RootState) => ProjectMembersConfigState = (state) =>
  state.project.config.members

export const selectProjectMembers: (state: RootState) => Member[] = (state) => selectProjectMembersConfig(state).members

export const selectProjectMembersFetchingLoading: (state: RootState) => BackendLoading = (state) =>
  selectProjectMembersConfig(state).loading.fetchProjectMembers

export const selectProjectMemberEditLoading: (state: RootState) => BackendLoading = (state) =>
  selectProjectMembersConfig(state).loading.setProjectMemberActivity

export const selectProjectMemberRemoveLoading: (state: RootState) => BackendLoading = (state) =>
  selectProjectMembersConfig(state).loading.removeProjectMember

export const selectIsProjectMembersConfigContentLoading: (state: RootState) => boolean = (state) =>
  selectProjectMembersFetchingLoading(state).isLoading

export const selectIsProjectMembersModalVisible: (state: RootState) => boolean = (state) =>
  selectProjectMembersConfig(state).isModalVisible

export const selectProjectMembersUsers: (state: RootState) => ActiveUser[] | undefined = (state) =>
  selectProjectMembersConfig(state).users

export const selectProjectMembersOrganizations: (state: RootState) => MemberOrganization[] | undefined = (state) =>
  selectProjectMembersConfig(state).organizations

export const selectProjectMembersOrganizationsFetchingLoading: (state: RootState) => BackendLoading = (state) =>
  selectProjectMembersConfig(state).loading.fetchOrganizations

export const selectProjectMembersUserFetchingLoading: (state: RootState) => BackendLoading = (state) =>
  selectProjectMembersConfig(state).loading.fetchActiveUsers

export const selectProjectMembersUsersAddableToProject: (state: RootState) => TransferUser[] | undefined = (state) => {
  const users = selectProjectMembersUsers(state)
  if (users === undefined) {
    return undefined
  }
  const projectMemberIds = selectProjectMembers(state).map((user) => user.userId)
  return users
    .filter((user) => !projectMemberIds.includes(user.id))
    .map((user) => ({ key: user.id.toString(), user } as TransferUser))
}
