import { Menu } from 'antd'
import Text from 'components/atoms/text'
import trans from 'helpers/i18n.helpers'
import { useSelector } from 'react-redux'
import { setSelectedAdministrationMenuKey } from 'redux/administration/administration-menu'
import selectSelectedAdministrationMenuKey from 'redux/administration/administration-menu/AdministrationMenu.selectors'
import { useAppDispatch } from 'redux/store'
import styled from 'styled-components'
import administrationOptions from '../administrationOptions'

const StyledMenu = styled(Menu)`
  height: 100%;
  box-shadow: ${(props) => props.theme.effects.leftPanelShadow};
`

const AdministrationMenu: React.FC = () => {
  const selectedAdministrationMenuKey = useSelector(selectSelectedAdministrationMenuKey)
  const dispatch = useAppDispatch()
  return (
    <StyledMenu
      onSelect={({ key }) => {
        dispatch(setSelectedAdministrationMenuKey(key as string))
      }}
      selectedKeys={[selectedAdministrationMenuKey]}
    >
      {administrationOptions.map(({ key, name }) => (
        <Menu.Item key={key}>
          <Text textStyle="regularText-medium">{trans(name)}</Text>
        </Menu.Item>
      ))}
    </StyledMenu>
  )
}

export default AdministrationMenu
