import { Form, Space } from 'antd'
import { findFieldValueByName } from 'helpers/Collection.helpers'
import useFormValidity from 'hooks/useFormValidity'
import { FieldData } from 'models/components'
import { ConsequencesType, ConsequencesTypes, FlowSettingsFields } from 'models/flow'
import { setConsequenceFlowValues } from 'redux/flow-conifg/consequences/FlowConsequencesConfig.slice'
import { useAppDispatch } from 'redux/store'
import styled from 'styled-components'
import CdeStatusCodesSelect from './CdeStatusCodesSelect'
import ConsequenceTypeItem from './ConsequenceTypeItem'
import FileRequiredRadio from './FileRequiredRadio'
import SuitabilityCodesSelect from './SuitabilityCodesSelect'

interface FlowConsequencesConfigFormProps {
  consequence: FlowSettingsFields
  type: ConsequencesType
  validationFormName: string
}

const StyledSpace = styled(Space)`
  width: 428px;
`
const getCdeStatusFromCodesOrFields = (
  changedFields: FieldData<string | number, string | number | (string | number)[]>[],
  fields: FieldData<string | number, string | number | (string | number)[]>[]
) => {
  const cdeStatus = findFieldValueByName(changedFields, 'followingCdeStatus')
  if (cdeStatus !== undefined) {
    return cdeStatus
  }
  const pastValue = findFieldValueByName(fields, 'followingCdeStatus')
  if (pastValue !== undefined) {
    return pastValue
  }
  return -1
}
const FormContent: React.FC<FlowConsequencesConfigFormProps> = ({ consequence, type, validationFormName }) => {
  const dispatch = useAppDispatch()
  const { form, validate } = useFormValidity(undefined, validationFormName)
  const handleChange = (changedFields: FieldData<string | number>[], allFields: FieldData<string | number>[]) => {
    const code = findFieldValueByName(changedFields, 'followingSuitabilityCode')
    const cdeStatus = getCdeStatusFromCodesOrFields(changedFields, allFields)
    const fields = [
      { name: 'consequenceType', value: type },
      { name: 'fileUploadRequired', value: findFieldValueByName(allFields, 'fileUploadRequired') },
      { name: 'followingCdeStatus', value: cdeStatus },
      { name: 'followingSuitabilityCode', value: code || findFieldValueByName(allFields, 'followingSuitabilityCode') },
    ] as FlowSettingsFields
    validate()
    dispatch(setConsequenceFlowValues({ fields, type }))
  }
  return (
    <Form form={form} layout="vertical" fields={consequence} onFieldsChange={handleChange}>
      <StyledSpace direction="vertical" size="large">
        {type !== ConsequencesTypes.NEUTRAL && <ConsequenceTypeItem type={type} />}
        <SuitabilityCodesSelect type={type} />
        <CdeStatusCodesSelect />
        {type !== ConsequencesTypes.NEUTRAL && <FileRequiredRadio />}
      </StyledSpace>
    </Form>
  )
}

export default FormContent
