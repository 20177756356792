import { defineMessage, Trans } from '@lingui/macro'
import Text from 'components/atoms/text'
import formatDate from 'helpers/Dates.helpers'
import trans from 'helpers/i18n.helpers'
import getAccountStatus from 'helpers/Users.helpers'
import { ActivationDetails } from 'models/administration'
import { AccountActivityStatuses } from 'redux/administration/administration-users/AdministrationUsers.types'
import { userActiveText, userInactiveText } from '../texts'

interface StatusProps {
  activationDetails: ActivationDetails
}

const activationCodeSentMessage = defineMessage({
  id: 'administration.users.account_status.activation_code_sent',
  message: 'Activation code sent',
})

const StatusColumn: React.FC<StatusProps> = ({ activationDetails }) => {
  const textStatusDict = {
    [AccountActivityStatuses.ACTIVE]: (
      <Text center color="successText" textStyle="regularText-small">
        {trans(userActiveText)}
      </Text>
    ),
    [AccountActivityStatuses.INACTIVE]: (
      <Text center color="failureText" textStyle="regularText-small">
        {trans(userInactiveText)}
      </Text>
    ),
    [AccountActivityStatuses.LINK_ACTIVE]:
      activationDetails && activationDetails.activationCodeExpiry ? (
        <>
          <Text center textStyle="regularText-small">
            {trans(activationCodeSentMessage)}
          </Text>
          <Text center color="successText" textStyle="regularText-small">
            <Trans id="administration.users.account_status.activation_code_valid_till">
              Valid till ${formatDate(activationDetails.activationCodeExpiry)}
            </Trans>
          </Text>
        </>
      ) : (
        <div />
      ),
    [AccountActivityStatuses.LINK_EXPIRED]:
      activationDetails && activationDetails.activationCodeExpiry ? (
        <>
          <Text center textStyle="regularText-small">
            {trans(activationCodeSentMessage)}
          </Text>
          <Text center color="failureText" textStyle="regularText-small">
            <Trans id="administration.users.account_status.activation_code_expired">
              Expired on ${formatDate(activationDetails.activationCodeExpiry)}
            </Trans>
          </Text>
        </>
      ) : (
        <div />
      ),
    [AccountActivityStatuses.LINK_DEACTIVATED]: (
      <Text center color="failureText" textStyle="regularText-small">
        <Trans id="administration.users.account_status.activation_code_invalidated">Activation link invalidated</Trans>
      </Text>
    ),
  }
  return textStatusDict[getAccountStatus(activationDetails)]
}

export default StatusColumn
