import { t } from '@lingui/macro'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import { removedErrorsFromLoading } from 'helpers/HttpError.helpers'
import { openSuccessNotification } from 'helpers/Notifications.helpers'
import { OrganizationDetails } from 'models/administration'
import {
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
} from 'redux/redux.shared'
import {
  loadingTypes,
  LoadingTypes,
  AdministrationOrganizationsState,
  OrganizationEditableData,
} from './AdministrationOrganizations.types'

export const fetchOrganizations = createAsyncBackendThunk('fetchOrganizations', async () => {
  const response = await backendAxios.get('/organizations/details/')
  return response.data
})

interface UpdateOrganizationArgs {
  organizationId: number
  organizationFieldsToUpdate: Partial<OrganizationEditableData>
}

export const updateOrganization = createAsyncBackendThunk(
  'updateOrganization',
  async ({ organizationId, organizationFieldsToUpdate }: UpdateOrganizationArgs, { dispatch }) => {
    await backendAxios.patch(`/organizations/${organizationId}/`, organizationFieldsToUpdate)
    dispatch(fetchOrganizations())
    openSuccessNotification({
      message: t({
        id: 'administration.organizations.notifications.updated_text',
        message: 'Organization has been updated',
      }),
    })
  }
)

export const addOrganization = createAsyncBackendThunk(
  'addOrganization',
  async (organization: OrganizationEditableData, { dispatch }) => {
    await backendAxios.post(`/organizations/`, organization)
    dispatch(fetchOrganizations())
    openSuccessNotification({
      message: t({
        id: 'administration.organizations.notifications.added_text',
        message: 'Organization has been added',
      }),
    })
  }
)

export const removeOrganization = createAsyncBackendThunk('removeOrganization', async (organizationId: number) => {
  await backendAxios.delete(`/organizations/${organizationId}/`)
  openSuccessNotification({
    message: t({
      id: 'administration.organizations.notifications.deleted_text',
      message: 'Organization has been deleted',
    }),
  })
})

const initialState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  organizations: [],
  modalState: { isModalVisible: false, organization: undefined },
} as AdministrationOrganizationsState

const administrationOrganizationsSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    openOrganizationModal: (state, action: PayloadAction<OrganizationDetails | undefined>) => {
      state.modalState.isModalVisible = true
      state.modalState.organization = action.payload
    },
    closeOrganizationModal: (state) => {
      state.modalState = initialState.modalState
      state.loading = removedErrorsFromLoading(state.loading)
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<AdministrationOrganizationsState, LoadingTypes, OrganizationDetails[]>({
      promise: fetchOrganizations,
      loadingType: 'fetchOrganizations',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        state.organizations = action.payload
      },
    }),
    generateExtraBackendReducers<AdministrationOrganizationsState, LoadingTypes, void, UpdateOrganizationArgs>({
      promise: updateOrganization,
      loadingType: 'updateOrganization',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state) => {
        state.modalState = initialState.modalState
      },
    }),
    generateExtraBackendReducers<AdministrationOrganizationsState, LoadingTypes, OrganizationDetails>({
      promise: addOrganization,
      loadingType: 'addOrganization',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state) => {
        state.modalState = initialState.modalState
      },
    }),
    generateExtraBackendReducers<AdministrationOrganizationsState, LoadingTypes, void, number>({
      promise: removeOrganization,
      loadingType: 'removeOrganization',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        const organizationId = action.meta.arg
        state.organizations = state.organizations.filter(({ id }) => id !== organizationId)
      },
    }),
  ]),
})

export const { openOrganizationModal, closeOrganizationModal } = administrationOrganizationsSlice.actions

export const administrationOrganizationsReducer = administrationOrganizationsSlice.reducer
