import { Trans } from '@lingui/macro'
import { useDispatch } from 'react-redux'
import { openOrganizationModal } from 'redux/administration/administration-organizations'
import AdministrationToolbarButton from '../toolbar'

const AdministrationOrganizationsToolbar: React.FC = () => {
  const dispatch = useDispatch()
  return (
    <AdministrationToolbarButton
      text={
        <Trans id="administration.organizations.create_new_organization_button_text">Create new organization</Trans>
      }
      onClick={() => dispatch(openOrganizationModal())}
    />
  )
}

export default AdministrationOrganizationsToolbar
