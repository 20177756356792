import { FormOutlined, EnvironmentOutlined, PrinterOutlined } from '@ant-design/icons'
import { Layers, Straighten, Map } from '@material-ui/icons'
import { defineMessage } from '@lingui/macro'
import MapSideDrawerLayers from '../map-sidedrawer-content/map-sidedrawer-layers'

export default [
  {
    option: <Layers />,
    type: 'layers',
    content: <MapSideDrawerLayers />,
    tooltip: defineMessage({ id: 'project.map.sidedrawer.layers_tooltip', message: 'Layers' }),
  },
  {
    option: <FormOutlined />,
    type: '1',
    content: <div>Content 1</div>,
  },
  {
    option: <EnvironmentOutlined />,
    type: '2',
    content: <div>Content 2</div>,
  },
  {
    option: <Straighten />,
    type: '3',
    content: <div>Content 3</div>,
  },
  {
    option: <Map />,
    type: '4',
    content: <div>Content 4</div>,
  },
  {
    option: <PrinterOutlined />,
    type: '5',
    content: <div>Content 5</div>,
  },
]
