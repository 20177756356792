import { Alert } from 'antd'
import { errorText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'

interface BaseErrorAlertProps {
  description: string
}

const BaseErrorAlert: React.FC<BaseErrorAlertProps> = ({ description }) => {
  return <Alert message={trans(errorText)} description={description} type="error" showIcon />
}

export default BaseErrorAlert
