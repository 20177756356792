import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import { Member, MemberOrganization } from 'models/project/projectConfig'
import { ActiveUser } from 'models/users'
import {
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
} from 'redux/redux.shared'
import { openSuccessNotification } from 'helpers/Notifications.helpers'
import { loadingTypes, LoadingTypes, ProjectMembersConfigState } from './ProjectMembersConfig.types'

interface ProjectMemberActivity {
  isActive: boolean
}

interface ProjectMemberIdentifierProps {
  projectId: number
  userId: number
}

interface ProjectMembersAddProps {
  projectId: number
  users: number[]
}

interface ProjectMemberEditProps extends ProjectMemberActivity, ProjectMemberIdentifierProps {}

export const fetchProjectMembers = createAsyncBackendThunk('users/fetchProjectMembers', async (projectId: number) => {
  return (await backendAxios.get(`/projects/${projectId}/members/`)).data
})

export const setProjectMemberActivity = createAsyncBackendThunk(
  'users/setProjectMemberActivity',
  async ({ projectId, userId, isActive }: ProjectMemberEditProps) => {
    const response = await backendAxios.patch(`/projects/${projectId}/members/${userId}/`, { isActive })
    openSuccessNotification({ description: 'Pomyślnie zmieniono dostęp użytkownika' })
    return response.data
  }
)

export const removeProjectMember = createAsyncBackendThunk(
  'users/removeProjectMember',
  async ({ projectId, userId }: ProjectMemberIdentifierProps) => {
    await backendAxios.delete(`/projects/${projectId}/members/${userId}/`)
    openSuccessNotification({ description: 'Pomyślnie usunięto użytkownika' })
  }
)

export const fetchActiveUsers = createAsyncBackendThunk('users/fetchActiveUsers', async () => {
  return (await backendAxios.get(`/users/active/`)).data
})

export const fetchOrganizations = createAsyncBackendThunk('users/fetchOrganizations', async () => {
  return (await backendAxios.get(`/organizations/`)).data
})

export const addProjectMembers = createAsyncBackendThunk(
  'users/addProjectMembers',
  async ({ projectId, users }: ProjectMembersAddProps) => {
    const response = await backendAxios.post(`/projects/${projectId}/members/`, users)
    openSuccessNotification({ description: 'Pomyślnie dodano użytkowników' })
    return response.data
  }
)

const initialState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  members: [],
  isModalVisible: false,
  users: undefined,
  organizations: undefined,
} as ProjectMembersConfigState

const projectMembersConfigSlice = createSlice({
  name: 'projectMembers',
  initialState,
  reducers: {
    setProjectMembersModalVisible: (state, action: PayloadAction<boolean>) => {
      state.isModalVisible = action.payload
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<ProjectMembersConfigState, LoadingTypes, Member[]>({
      promise: fetchProjectMembers,
      loadingType: 'fetchProjectMembers',
      onFulfilled: (state, action) => {
        state.members = action.payload
      },
    }),
    generateExtraBackendReducers<ProjectMembersConfigState, LoadingTypes, void, ProjectMemberIdentifierProps>({
      promise: removeProjectMember,
      loadingType: 'removeProjectMember',
      onFulfilled: (state, action) => {
        state.members = state.members.filter((member) => member.id !== action.meta.arg.userId)
      },
    }),
    generateExtraBackendReducers<ProjectMembersConfigState, LoadingTypes, ActiveUser[]>({
      promise: fetchActiveUsers,
      loadingType: 'fetchActiveUsers',
      onFulfilled: (state, action) => {
        state.users = action.payload
      },
    }),
    generateExtraBackendReducers<ProjectMembersConfigState, LoadingTypes, MemberOrganization[]>({
      promise: fetchOrganizations,
      loadingType: 'fetchOrganizations',
      onFulfilled: (state, action) => {
        state.organizations = action.payload
      },
    }),
    generateExtraBackendReducers<ProjectMembersConfigState, LoadingTypes, Member[]>({
      promise: addProjectMembers,
      loadingType: 'addProjectMembers',
      onFulfilled: (state, action) => {
        state.isModalVisible = false
        state.members = action.payload
      },
    }),
    generateExtraBackendReducers<
      ProjectMembersConfigState,
      LoadingTypes,
      ProjectMemberActivity,
      ProjectMemberEditProps
    >({
      promise: setProjectMemberActivity,
      loadingType: 'setProjectMemberActivity',
      onFulfilled: (state, action) => {
        state.members = state.members.map((member) => {
          if (member.id === action.meta.arg.userId) {
            return { ...member, isActive: action.payload.isActive }
          }
          return member
        })
      },
    }),
  ]),
})

export const { setProjectMembersModalVisible } = projectMembersConfigSlice.actions
export const projectMembersConfigReducer = projectMembersConfigSlice.reducer
