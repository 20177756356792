import { InfoCircleOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'
import { Space } from 'antd'
import Icon from 'components/atoms/icon'
import Text from 'components/atoms/text'
import TooltipElement from 'components/molecules/tooltip-element'
import { ConsequencesType, ConsequencesTypes } from 'models/flow'

interface FlowConsequencesConfigFormProps {
  type: ConsequencesType
}

const ConsequenceTypeItem: React.FC<FlowConsequencesConfigFormProps> = ({ type }) => {
  const text =
    type === ConsequencesTypes.NEGATIVE ? (
      <Trans id="flow_config.consequences.negative_header">Negative decision</Trans>
    ) : (
      <Trans id="flow_config.consequences.positive_header">Positive decision</Trans>
    )
  const tooltip =
    type === ConsequencesTypes.NEGATIVE ? (
      <Trans id="flow_config.consequences.negative_tooltip">
        In case of negative reviews decisions the following changes will be applied
      </Trans>
    ) : (
      <Trans id="flow_config.consequences.positive_tooltip">
        In case of positive reviews decisions the following changes will be applied
      </Trans>
    )

  return (
    <Space direction="horizontal">
      <Text textStyle="regularText-smallSemiBold" color={type === ConsequencesTypes.POSITIVE ? 'green8' : 'red6'}>
        {text}
      </Text>
      <TooltipElement placement="top" title={tooltip}>
        <Icon size="default" color="tooltipIconGray" style={{ verticalAlign: 'middle' }}>
          <InfoCircleOutlined />
        </Icon>
      </TooltipElement>
    </Space>
  )
}

export default ConsequenceTypeItem
