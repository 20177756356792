import { useParams } from 'react-router'

interface UseStepsParamsReturnValue {
  stepIdx: number
  projectId: string
  isStepIdxNan: boolean
  step: string
}

const useStepsParams = (): UseStepsParamsReturnValue => {
  const { step, projectId } = useParams<{ step: string; projectId: string }>()
  const stepIdx = Number(step) - 1
  const isStepIdxNan = Number.isNaN(stepIdx)
  return { stepIdx, projectId, isStepIdxNan, step }
}

export default useStepsParams
