import { t } from '@lingui/macro'
import ErrorAlert from 'components/atoms/error-alert'
import { useSelector } from 'react-redux'
import { selectBackupSavingLoading } from 'redux/administration/administration-backups/AdministrationBackups.selectors'

const BackupSettingsModalAlert: React.FC = () => {
  const savingLoading = useSelector(selectBackupSavingLoading)

  const alertMessageDict = [
    {
      error: savingLoading.error,
      message: t({ id: 'administration.backups.saving_error_message', message: 'Could not save backup settings' }),
    },
  ]
  return <ErrorAlert alertMessageDict={alertMessageDict} />
}

export default BackupSettingsModalAlert
