import { EditOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { WithDividerElement } from 'components/atoms/divider'
import ButtonWithTooltip, { DeleteButton } from 'components/molecules/buttons'
import { deleteText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import showConfirmationModal from 'helpers/Modals.helpers'
import { useSelector } from 'react-redux'
import { removeOrganization, openOrganizationModal } from 'redux/administration/administration-organizations'
import { selectAdministrationOrganizations } from 'redux/administration/administration-organizations/AdministrationOrganizations.selectors'
import { useAppDispatch } from 'redux/store'

interface OrganizationActionsProps {
  organizationId: number
  canDelete: boolean
  name: string
}

const OrganizationActions: React.FC<OrganizationActionsProps> = ({ organizationId, canDelete, name }) => {
  const dispatch = useAppDispatch()
  const organization = useSelector(selectAdministrationOrganizations).find((org) => org.id === organizationId)
  if (organization === undefined) {
    return null
  }
  return (
    <WithDividerElement>
      <ButtonWithTooltip
        onClick={(event) => {
          event.stopPropagation()
          dispatch(openOrganizationModal(organization))
        }}
        tooltipText={t({
          id: 'administration.organizations.edit_organization_tooltip',
          message: 'Edit organization',
        })}
        icon={<EditOutlined />}
      />
      <DeleteButton
        onClick={(event) => {
          event.stopPropagation()
          showConfirmationModal({
            content: t({
              id: 'administration.organizations.delete_confirmation_text',
              message: `Delete organization "${name}"?`,
            }),
            okText: trans(deleteText),
            onOk: async () => {
              await dispatch(removeOrganization(organizationId))
            },
          })
        }}
        disabled={!canDelete}
        tooltip={t({
          id: 'administration.organizations.delete_organization_tooltip',
          message: 'Delete organization',
        })}
        disabledTooltip={t({
          id: 'administration.organizations.delete_organization_with_users_tooltip',
          message: 'Organization has members and cannot be deleted',
        })}
      />
    </WithDividerElement>
  )
}

export default OrganizationActions
