import { Attribute, NamingScheme, NamingSchemeElement } from 'models/administration'
import { BackendLoading } from 'redux/redux.shared'
import { EntryNamingScheme } from 'models/project/projectConfig'

export const loadingTypes = [
  'fetchNamingScheme',
  'updateNamingScheme',
  'fetchAvailableMetadata',
  'fetchDisciplineCode',
  'fetchNamingSchemes',
  'fetchDisciplineLength',
] as const
export type LoadingTypes = typeof loadingTypes[number]

export interface ProjectNamingSchemeConfigState {
  loading: Record<LoadingTypes, BackendLoading>
  scheme: EntryNamingScheme
  schemeDraft: NamingSchemeElement[]
  availableSchemes: NamingScheme[]
  availableMetadata: Attribute[]
  disciplineCode?: string
  disciplineData: DisciplineForSchema
}

export interface DisciplineForSchema {
  exampleCode: string
  codeLength: number
  values: ValueDto[]
}

export interface ValueDto {
  id: number
  value: string
  code: string
}

export interface MetadataDto {
  id: number
  name: string
  valueCodeLength: number
  values: ValueDto[]
}

export interface DisciplineWithLengthDto {
  length: number
  values: ValueDto[]
}
