import { BackendLoading } from 'redux/redux.shared'

export interface FilesViewerState {
  loading: Record<FilesViewerLoadingTypes, BackendLoading>
  fileLink?: string
  fileName?: string
  isModalVisible: boolean
}

export const filesViewerLoadingTypes = ['showFileViewerModal'] as const

export type FilesViewerLoadingTypes = typeof filesViewerLoadingTypes[number]
