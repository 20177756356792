import { t } from '@lingui/macro'
import ErrorAlert from 'components/atoms/error-alert'
import { useSelector } from 'react-redux'
import {
  selectBackupsListLoading,
  selectRestoreBackupLoading,
} from 'redux/administration/administration-backups/AdministrationBackups.selectors'

const AdministrationBackupsAlert: React.FC = () => {
  const fetchingBackupsLoading = useSelector(selectBackupsListLoading)
  const restoringLoading = useSelector(selectRestoreBackupLoading)

  const alertMessageDict = [
    {
      error: fetchingBackupsLoading.error,
      message: t({ id: 'administration.backups.errors.fetch_failed', message: 'Could not retrieve backups' }),
    },
    {
      error: restoringLoading.error,
      message: t({
        id: 'administration.backups.errors.restore_failed',
        message: 'Could not restore system from backup',
      }),
    },
  ]
  return <ErrorAlert alertMessageDict={alertMessageDict} />
}

export default AdministrationBackupsAlert
