import { t } from '@lingui/macro'
import ErrorAlert from 'components/atoms/error-alert'
import { useSelector } from 'react-redux'
import {
  selectFetchingStagesOverviewLoading,
  selectUpdatingProjectStagesLoading,
} from 'redux/project/project-config/stages/ProjectStagesConfig.selectors'

const ProjectStagesConfigAlert: React.FC = () => {
  const fetchingStagesOverviewLoading = useSelector(selectFetchingStagesOverviewLoading)
  const updatingProjectStagesLoading = useSelector(selectUpdatingProjectStagesLoading)

  const alertMessageDict = [
    {
      error: fetchingStagesOverviewLoading.error,
      message: t({ id: 'project_config.stages.alert.stages_fetch_failed', message: 'Could not retrieve stages.' }),
    },
    {
      error: updatingProjectStagesLoading.error,
      message: t({ id: 'project_config.stages.alert.stages_update_failed', message: 'Could not update stages.' }),
    },
  ]
  return <ErrorAlert alertMessageDict={alertMessageDict} />
}

export default ProjectStagesConfigAlert
