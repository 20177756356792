import { Trans } from '@lingui/macro'
import { Space } from 'antd'
import Text from 'components/atoms/text'
import { useSelector } from 'react-redux'
import {
  selectDecidionsFlowConfig,
  selectGettingFlowDecisionsLoading,
} from 'redux/flow-conifg/decisions/FlowDecisionsConfig.selectors'
import FlowDecisionsConfigContentAlerts from './FlowDecisionsConfigContentAlerts'
import FlowDecisionsConfigForm from './FlowDecisionsConfigForm'

const FlowGeneralConfigContent: React.FC = () => {
  const flow = useSelector(selectDecidionsFlowConfig)
  const { isLoading: isGettingDecisionLoading } = useSelector(selectGettingFlowDecisionsLoading)

  if (isGettingDecisionLoading) return null
  return (
    <Space direction="vertical" size="large">
      <Text textStyle="regularText-medium">
        <Trans id="flow_config.decisions.heading">Settings after flow execution</Trans>
      </Text>
      <FlowDecisionsConfigForm flow={flow} />
      <FlowDecisionsConfigContentAlerts />
    </Space>
  )
}

export default FlowGeneralConfigContent
