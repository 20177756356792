import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import {
  selectIsGeneralConfigContentLoading,
  selectShouldCreateFlow,
  selectPrevFlowsValidity,
} from 'redux/flow-conifg/general/FlowGeneralConfig.selectors'
import { createFlow, updateFlow } from 'redux/flow-conifg/general/FlowGeneralConfig.slice'
import getFormValiditySelector from 'redux/forms-validity/FormsValidity.selectors'
import { fetchFlows } from 'redux/project/project-config/flow/ProjectFlowConfig.slice'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import BaseFlowConfigView from '../base-flow-config-view/BaseFlowConfigView'
import FlowGeneralConfigContent from './content/FlowGeneralConfigContent'

const FlowGeneralConfig: React.FC = () => {
  const isLoading = useSelector(selectIsGeneralConfigContentLoading)
  const shouldCreateFlow = useSelector(selectShouldCreateFlow)
  const prevFlowsValidity = useSelector(selectPrevFlowsValidity)
  const flowConfigValidity = useSelector(getFormValiditySelector())
  const dispatch = useDispatch()
  const history = useHistory()
  const projectId = useSelector(selectProjectId)

  useEffect(() => {
    if (projectId) {
      dispatch(fetchFlows(projectId))
    }
  }, [dispatch, projectId])

  if (!projectId) return null
  const savingThunk = shouldCreateFlow
    ? createFlow({
        projectId,
        replaceHistory: (newFlowId) => history.replace(`/flow-config/${projectId}/2/${newFlowId}`),
      })
    : updateFlow(projectId)
  return (
    <BaseFlowConfigView
      isContentValid={flowConfigValidity && prevFlowsValidity}
      saveStepThunk={savingThunk}
      content={<FlowGeneralConfigContent />}
      isContentLoading={isLoading}
      stepName="GENERAL"
    />
  )
}

export default FlowGeneralConfig
