import { useEffect } from 'react'
import MainNavBar from 'components/organisms/main-navbar'
import UserProfileContent from 'components/organisms/user-profile/user-profile-content'
import UserProfileMenu from 'components/organisms/user-profile/user-profile-menu'
import AdministrationTemplate from 'components/templates/administration'
import WithHeaderTemplate from 'components/templates/with-header'
import { useDispatch } from 'react-redux'
import { clearUserProfileState } from 'redux/user-profile'

const UserProfilePage: React.FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    return () => {
      dispatch(clearUserProfileState())
    }
  }, [dispatch])
  return (
    <WithHeaderTemplate
      header={<MainNavBar />}
      content={<AdministrationTemplate left={<UserProfileMenu />} right={<UserProfileContent />} />}
    />
  )
}

export default UserProfilePage
