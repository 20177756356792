import { AdministrationProjectStagesState, LoadingTypes } from './AdministrationProjectStages.types'

const removedErrorsFromLoading = (
  loading: AdministrationProjectStagesState['loading']
): AdministrationProjectStagesState['loading'] => {
  const loadingWithoutErrors: AdministrationProjectStagesState['loading'] = loading
  Object.keys(loading).forEach((key) => {
    loadingWithoutErrors[key as LoadingTypes].error = undefined
  })
  return loadingWithoutErrors
}

export default removedErrorsFromLoading
