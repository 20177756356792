import { AttributeValue } from 'models/project/projectConfig'
import { arrayContainsItem } from 'helpers/Collection.helpers'
import SelectElement from 'components/atoms/select-element'
import { Select, Spin } from 'antd'
import CheckboxElement from 'components/molecules/checkbox'
import { SelectValue } from 'antd/lib/select'
import trans from 'helpers/i18n.helpers'
import { selectText } from 'data/messages/controls'

const { Option } = Select

interface SelectAttributesValuesProps {
  attributeValues?: AttributeValue[]
  addAttributeValue?: (val: SelectValue) => void
  removeAttributeValue?: (val: SelectValue) => void
  disabled?: boolean
}

const SelectAttributesValues: React.FC<SelectAttributesValuesProps> = ({
  attributeValues,
  addAttributeValue,
  removeAttributeValue,
  disabled,
}) => {
  const loadingAttributeValuesIds = attributeValues?.filter(({ isLoading }) => isLoading).map(({ id }) => id)

  const createSelectionFn = (fn?: (val: SelectValue) => void) => (val: SelectValue) => {
    if (loadingAttributeValuesIds && !arrayContainsItem(loadingAttributeValuesIds, val) && fn) {
      fn(val as SelectValue)
    }
  }
  const onSelect = createSelectionFn(addAttributeValue)
  const onDeselect = createSelectionFn(removeAttributeValue)
  const options = attributeValues?.map(({ name, id, isUsed }) => ({ name, value: id, isUsed }))
  const selectedValues = attributeValues?.filter(({ selected }) => selected).map(({ id }) => id)
  return (
    <SelectElement
      showSearch={false}
      disabled={disabled}
      mode="multiple"
      placeholder={trans(selectText)}
      showArrow
      onSelect={onSelect}
      onDeselect={onDeselect}
      value={selectedValues}
      loading={loadingAttributeValuesIds && loadingAttributeValuesIds.length > 0}
    >
      {options?.map((option) => (
        <Option
          key={option.value}
          value={option.value}
          disabled={arrayContainsItem(loadingAttributeValuesIds, option.value) || option.isUsed}
          label={option.name}
        >
          <Spin spinning={arrayContainsItem(loadingAttributeValuesIds, option.value)}>
            <CheckboxElement
              disabled={option.isUsed}
              noEvents
              checked={arrayContainsItem(selectedValues, option.value)}
            >
              {option.name}
            </CheckboxElement>
          </Spin>
        </Option>
      ))}
    </SelectElement>
  )
}

export default SelectAttributesValues
