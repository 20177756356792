import { t } from '@lingui/macro'
import ErrorAlert from 'components/atoms/error-alert'
import { useSelector } from 'react-redux'
import { selectAdministrationSuitabilityCodesLoading } from 'redux/administration/administration-suitability/AdministrationSuitability.selectors'

const AdministrationSuitabilityAlerts: React.FC = () => {
  const fetchingSuitabilityCodesLoading = useSelector(selectAdministrationSuitabilityCodesLoading)
  const alertMessageDict = [
    {
      error: fetchingSuitabilityCodesLoading.error,
      message: t({ id: 'administration.suitability.fetch_failed', message: 'Could not retrieve suitability codes' }),
    },
  ]
  return <ErrorAlert alertMessageDict={alertMessageDict} />
}

export default AdministrationSuitabilityAlerts
