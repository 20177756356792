import { Trans } from '@lingui/macro'
import SpaceElement from 'components/atoms/space'
import ButtonElement from 'components/molecules/button-element'
import { useDispatch } from 'react-redux'
import { setFileModalVisibility, showUserModal } from 'redux/administration/administration-users'
import AdministrationToolbarButton from '../toolbar/AdministrationToolbar'

const AdministrationUsersToolbar: React.FC = () => {
  const dispatch = useDispatch()
  return (
    <SpaceElement direction="horizontal" size="m">
      <ButtonElement onClick={() => dispatch(setFileModalVisibility(true))} type="secondary">
        <Trans id="administration.users.load_users_from_file_button_text">Add users from file</Trans>
      </ButtonElement>
      <AdministrationToolbarButton
        text={<Trans id="administration.users.add_user_button_text">Add a user</Trans>}
        onClick={() => dispatch(showUserModal())}
      />
    </SpaceElement>
  )
}

export default AdministrationUsersToolbar
