const objectsDiff: <T extends Record<string, unknown>>(
  oldValues: T,
  newValues: T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  compareFns?: Record<string, (oldVal: any, newVal: any) => boolean>
) => Record<string, unknown> = (oldValues, newValues, compareFns = {}) => {
  return Object.keys(newValues).reduce((obj, key) => {
    const funcDefined = key in compareFns
    if (
      (funcDefined && compareFns[key](oldValues[key], newValues[key])) ||
      (!funcDefined && oldValues[key] === newValues[key])
    ) {
      return obj
    }
    return {
      ...obj,
      [key]: newValues[key],
    }
  }, {})
}

export default objectsDiff
