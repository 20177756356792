import { defineMessage, t } from '@lingui/macro'
import ErrorAlert from 'components/atoms/error-alert'
import { handleApiErrors } from 'helpers/HttpError.helpers'
import { useSelector } from 'react-redux'
import {
  selectFetchingUsersLoading,
  selectEditingActivityLoading,
} from 'redux/administration/administration-users/AdministrationUsers.selectors'

const updateUserErrorDict = {
  'user.cannot_remove_last_admin': defineMessage({
    id: 'administration.users.cannot_remove_last_admin_error',
    message: 'At least one admin must remain in the system.',
  }),
  'user.cannot_deactivate_last_admin': defineMessage({
    id: 'administration.users.cannot_deactivate_last_admin_error',
    message: 'At least one admin must remain active in the system.',
  }),
}

const AdministrationUsersAlerts: React.FC = () => {
  const fetchingUsersLoading = useSelector(selectFetchingUsersLoading)
  const editingLoading = useSelector(selectEditingActivityLoading)
  const alertMessageDict = [
    {
      error: fetchingUsersLoading.error,
      message: t({ id: 'administration.users.fetch_failed', message: 'Could not retrieve users' }),
    },
    {
      error: editingLoading.error,
      message: `${t({ id: 'administration.users.update_failed', message: 'Could not update user' })} ${handleApiErrors(
        updateUserErrorDict,
        editingLoading.error?.errors
      )}`,
    },
  ]
  return <ErrorAlert alertMessageDict={alertMessageDict} />
}

export default AdministrationUsersAlerts
