import { SelectProps } from 'antd/lib/select'
import { AttributeType } from 'redux/administration/administration-naming-scheme/AdministrationNamingScheme.types'
import { CdeStatus } from './files'
import { PeriodicDay, PeriodType } from './periods'
import NameAndId from './shared'

export interface ActivationDetails {
  isActive: boolean
  activationCodeExpiry: string | undefined
  deactivated: boolean
}

export interface CodeField {
  code: string
  isValid: boolean
}

interface OrganizationUserCountSummary {
  active: number
  inactive: number
}

export interface OrganizationContactUser {
  email: string
  firstName: string
  lastName: string
  phoneNumber: string
  position: string
}

export interface Organization {
  id: number
  name: string
  abbreviation: string
}

export const getOrganizationsOptions = (organizations: Organization[]): SelectProps<number[]>['options'] =>
  organizations.map(({ name, id }) => ({
    label: name,
    value: id,
  }))

export interface OrganizationDetails extends Organization {
  code: CodeField
  address: string | null
  isCodeValid: boolean
  isActive: boolean
  users: OrganizationUserCountSummary
  contact: OrganizationContactUser | null
  isUsed: boolean
}

export interface Discipline {
  id: number
  name: string
  code: CodeField
  marker: number
  isUsed: boolean
}

export interface AttributeValue {
  id: number
  value: string
  code: string
  isUsed: boolean
}

export interface Attribute {
  id: number
  name: string
  values: AttributeValue[]
  projectValuesAllowed: boolean
  valueCodeLength: number
  isUsed: boolean
}

export interface ProjectStage {
  id: number
  name: string
  code: string
}

export interface ProjectStageWithUsage {
  id: number
  name: string
  code: CodeField
  isUsed: boolean
}

export interface SuitabilityCode {
  id: number
  name: string
  code: string
  status: CdeStatus
  isDefault: boolean
}

export const getSuitabilityCodesOptions = (codes: SuitabilityCode[]): SelectProps<number[]>['options'] =>
  codes.map(({ id, name, code }) => ({ value: id, label: `${code} (${name})` }))

export const getAvailableSuitabilityCodesFromStatuses = (
  codes: SuitabilityCode[],
  statuses: string[]
): SuitabilityCode[] => (statuses.length === 0 ? codes : codes.filter(({ status }) => statuses.includes(status)))

export interface SuitabilityCodeWithDetails extends Omit<SuitabilityCode, 'code'> {
  code: CodeField
  isUsed: boolean
}

export interface User {
  id: number
  email: string
  isAdmin: boolean
  firstName: string
  lastName: string
  organization: OrganizationDetails
  activationDetails: ActivationDetails
  disciplines: NameAndId[]
}

export interface ProjectAdmin {
  id: number
  name: string
  email: string
}

export interface Project {
  id: number
  name: string
  location: string
  isEnded: boolean
  isActive: boolean
  code: CodeField
  admins: ProjectAdmin[]
}

export interface NamingSchemeElemenentArg {
  id?: number
  length?: number
  inShortName: boolean
}

export interface NamingSchemeElement {
  key: AttributeType
  args?: NamingSchemeElemenentArg
}

export interface NamingScheme {
  id: number
  name: string
  scheme: NamingSchemeElement[]
  isDefault: boolean
  isEditable: boolean
  isUsed: boolean
}

export interface WipGroup {
  id?: number
  organizations: BasicOrganizationData[]
  displayedId: number
}

export interface BasicOrganizationData {
  id: number
  name: string
  abbreviation: string
}

export interface BackupSettings {
  creationPeriod: PeriodType
  days: PeriodicDay[]
  hour: string
  maxStoredBackups: number
}

export const backupStatuses = ['CREATED', 'IN_PROGRESS', 'ERROR', 'FINISHED'] as const

export type BackupStatus = typeof backupStatuses[number]

export const BackupStatuses: Record<BackupStatus, BackupStatus> = {
  CREATED: 'CREATED',
  IN_PROGRESS: 'IN_PROGRESS',
  ERROR: 'ERROR',
  FINISHED: 'FINISHED',
}

export interface Backup {
  id: number
  name: string
  timestamp: string | null
  report: string | null
  status: BackupStatus
  size: number | null
  expectedStartDate: string | null
}
