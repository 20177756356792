import { Attribute, NamingScheme, NamingSchemeElement } from 'models/administration'
import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import { ProjectNamingSchemeConfigState } from './ProjectNamingSchemeConfig.types'

const selectNamingSchemeState: (state: RootState) => ProjectNamingSchemeConfigState = (state) =>
  state.project.config.namingScheme

export const selectScheme: (state: RootState) => NamingSchemeElement[] = (state) =>
  selectNamingSchemeState(state).scheme.scheme

export const selectSchemeSource: (state: RootState) => number | undefined = (state) =>
  selectNamingSchemeState(state).scheme.namingSchemeSource

export const selectSchemeDraft: (state: RootState) => NamingSchemeElement[] = (state) =>
  selectNamingSchemeState(state).schemeDraft

export const selectAvailableMetadata: (state: RootState) => Attribute[] = (state) =>
  selectNamingSchemeState(state).availableMetadata

export const selectDisciplineCode: (state: RootState) => string | undefined = (state) =>
  selectNamingSchemeState(state).disciplineCode

export const selectAvailableSchemes: (state: RootState) => NamingScheme[] = (state) =>
  selectNamingSchemeState(state).availableSchemes

export const selectSelectedScheme: (state: RootState) => NamingScheme | undefined = (state) => {
  const schemes = selectAvailableSchemes(state)
  const source = selectSchemeSource(state)
  return schemes.find(({ id, isDefault }) => (source ? id === source : isDefault))
}

// loadings
export const selectFetchNamingSchemeLoading: (state: RootState) => BackendLoading = (state) =>
  selectNamingSchemeState(state).loading.fetchNamingScheme

export const selectFetchAvailableMetadataLoading: (state: RootState) => BackendLoading = (state) =>
  selectNamingSchemeState(state).loading.fetchAvailableMetadata

export const selectUpdateNamingSchemeLoading: (state: RootState) => BackendLoading = (state) =>
  selectNamingSchemeState(state).loading.updateNamingScheme

export const selectIsProjectStagesConfigContentLoading: (state: RootState) => boolean = (state) =>
  selectUpdateNamingSchemeLoading(state).isLoading ||
  selectFetchAvailableMetadataLoading(state).isLoading ||
  selectFetchNamingSchemeLoading(state).isLoading
