import { useSelector } from 'react-redux'
import { selectIsProjectEntriesStructureConfigContentLoading } from 'redux/project/project-config/entries-structure/ProjectEntriesStructureConfig.selectors'
import { updateEntriesStructure } from 'redux/project/project-config/entries-structure/ProjectEntriesStructureConfig.slice'
import BaseProjectConfigView from '../base-config-view/BaseProjectConfigView'
import ProjectEntriesStructureConfigContent from './content'

const ProjectEntriesStructureConfig: React.FC = () => {
  const isContentLoading = useSelector(selectIsProjectEntriesStructureConfigContentLoading)
  return (
    <BaseProjectConfigView
      content={<ProjectEntriesStructureConfigContent />}
      saveStepThunk={updateEntriesStructure()}
      isContentLoading={isContentLoading}
    />
  )
}

export default ProjectEntriesStructureConfig
