import { Trans } from '@lingui/macro'
import { useDispatch } from 'react-redux'
import { showSuitabilityCodeModal } from 'redux/administration/administration-suitability'
import AdministrationToolbarButton from '../toolbar/AdministrationToolbar'

const AdministrationSuitabilityToolbar: React.FC = () => {
  const dispatch = useDispatch()
  return (
    <AdministrationToolbarButton
      text={<Trans id="administration.suitability.create_new_code_button_text">Create new suitability code</Trans>}
      onClick={() => dispatch(showSuitabilityCodeModal())}
    />
  )
}

export default AdministrationSuitabilityToolbar
