import { Trans } from '@lingui/macro'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import Text from 'components/atoms/text'
import Table from 'components/molecules/table'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectMembersConfig } from 'redux/flow-conifg/decisions/FlowDecisionsConfig.selectors'
import styled from 'styled-components'
import useMembersSelectionColumns from './useMembersSelectionColumns'

interface FlowGeneralConfigFormProps {
  initialSelection: number[]
  updateUsersFn: ActionCreatorWithPayload<number[]>
}

const StyledTable = styled(Table)`
  width: 450px;
`

const MembersSelectionTable: React.FC<FlowGeneralConfigFormProps> = ({ initialSelection, updateUsersFn }) => {
  const dispatch = useDispatch()
  const members = useSelector(selectMembersConfig)
  const columns = useMembersSelectionColumns()
  const [selectedMemebers, selectMembers] = useState<number[]>([])

  const rowSelection = {
    selectedRowKeys: initialSelection,
    onChange: (selectedRowKeys: number[]) => {
      selectMembers(selectedRowKeys)
      dispatch(updateUsersFn(selectedRowKeys))
    },
  }
  return (
    <>
      {selectedMemebers.length === 0 && (
        <Text color="volcano5" textStyle="regularText-smallSemiBold">
          <Trans id="project.flow.config.members.alert">Please select at least one member!</Trans>
        </Text>
      )}
      <StyledTable
        columns={columns}
        dataSource={members}
        bordered
        pagination={false}
        rowKey="userId"
        rowSelection={rowSelection}
        scroll={{ y: '400px' }}
      />
    </>
  )
}

export default MembersSelectionTable
