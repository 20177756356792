import { Upload } from 'antd'
import styled from 'styled-components'

const FileUpload = styled(Upload.Dragger)`
  &.ant-upload.ant-upload-drag .ant-upload {
    padding: ${(props) => props.theme.sizes.m};
    display: block;
    &-drag-container {
      display: block;
    }
  }
`

export default FileUpload
