import { Modal, Spin } from 'antd'
import { SpinProps } from 'antd/lib/spin'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .ant-modal-content {
  }
  .ant-modal-content {
    position: absolute;
    min-width: 534px;
    left: 50%;
    transform: translateX(-50%);
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100%;
  }
  .ant-modal-body {
    overflow-y: auto;
    display: grid;
    padding: 0;
  }
  .ant-modal-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > *:nth-child(2) {
      margin-right: ${(props) => props.theme.sizes.xl};
      height: ${(props) => props.theme.sizes.m};
    }
  }
  .ant-modal-close-x {
    ${(props) => props.theme.mixins.flexCenter}
  }
`

const ModalWithClassname: React.FC<SpinProps> = ({ children, spinning, size, className }) => {
  return (
    <Spin spinning={spinning} size={size} wrapperClassName={className}>
      {children}
    </Spin>
  )
}

export const SpinModalLayout = styled(ModalWithClassname)`
  margin: 0 ${(props) => `${props.theme.sizes.l}`};
  > .ant-spin-container {
    > * {
      margin-top: ${(props) => props.theme.sizes.default};
      &:last-child {
        margin-bottom: ${(props) => props.theme.sizes.default};
      }
    }
    display: grid;
    grid-template-columns: 100%;
  }
`

export const ModalLeftSide = styled.aside`
  border-right: 1px solid ${(props) => props.theme.colors.grayG02};
  padding-right: ${(props) => props.theme.sizes.xl};
  flex-shrink: 0;
  ${(props) => props.theme.mixins.flexCenter}
`
export const ModalRightSide = styled.div`
  flex-grow: 1;
  display: flex;
  max-height: 100%;
  overflow: auto;
`

export default StyledModal
