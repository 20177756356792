import { BellOutlined } from '@ant-design/icons'
import { Popover } from 'antd'
import Dot from 'components/atoms/dot'
import Icon from 'components/atoms/icon'
import useNotifications from 'hooks/notifications'
import { useSelector } from 'react-redux'
import {
  selectNewNotificationsNumber,
  selectNotificationsPopoverVisibility,
} from 'redux/notifications/Notifications.selectors'
import styled from 'styled-components'
import NotificationsPopoverContent from './main-navbar-notifications-popover-content/NotificationsPopoverContent'
import NotificationsPopoverTitle from './NotificationsPopoverTitle'

const StyledDot = styled(Dot)`
  position: absolute;
  top: 50%;
  left: 50%;
`

interface NotificationsPopoverProps {
  onVisibleChange: (visible: boolean) => void
}

const NotificationsPopover: React.FC<NotificationsPopoverProps> = ({ onVisibleChange }) => {
  const newNotificationsNumber = useSelector(selectNewNotificationsNumber)
  const isNotificationsPopoverVisible = useSelector(selectNotificationsPopoverVisibility)
  useNotifications(!isNotificationsPopoverVisible)
  return (
    <Popover
      trigger={['click']}
      visible={isNotificationsPopoverVisible}
      onVisibleChange={onVisibleChange}
      content={isNotificationsPopoverVisible && <NotificationsPopoverContent />}
      title={isNotificationsPopoverVisible && <NotificationsPopoverTitle />}
      placement="bottomRight"
    >
      <Icon>
        <BellOutlined />
        {newNotificationsNumber !== 0 && (
          <StyledDot color="volcano5" size="defaultM" fontColor="baseWhiteish">
            {newNotificationsNumber >= 10 ? '9+' : newNotificationsNumber}
          </StyledDot>
        )}
      </Icon>
    </Popover>
  )
}

export default NotificationsPopover
