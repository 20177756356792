import { Alert, Button, Form, Spin } from 'antd'
import ButtonElement from 'components/atoms/button.legacy/Button'
import PASSWORD_ERROR_MESSAGES from 'data/messages/passwords'
import { handleApiErrors } from 'helpers/HttpError.helpers'
import useQueryParams from 'hooks/query/queryParams'
import { useSelector } from 'react-redux'
import { resetPassword } from 'redux/password'
import { passwordResetResultSelector, passwordSelector } from 'redux/password/Password.selectors'
import { useAppDispatch } from 'redux/store'
import { AlertProps } from 'antd/lib/alert'
import { t, Trans } from '@lingui/macro'
import { errorText, successText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import FormInputPasswordPanel from 'components/molecules/form-item/FormInputPasswordPanel'

const ResetPasswordForm: React.FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const token = useQueryParams().get('token')
  const handlePasswordReset = (value: { password: string }): void => {
    dispatch(resetPassword({ password: value.password, token }))
  }

  const { loading } = useSelector(passwordSelector)

  const passwordResetResult = useSelector(passwordResetResultSelector)

  const passwordResetResultsAlerts: { [key: string]: AlertProps } = {
    success: {
      message: trans(successText),
      description: t({ id: 'password_reset.password_changed_message', message: 'Password has been changed.' }),
      type: 'success',
      action: (
        <Button size="small" href="/login">
          <Trans id="password_reset.sign_in_button_text">Sign in</Trans>
        </Button>
      ),
    },
    failed: {
      message: trans(errorText),
      description: handleApiErrors(PASSWORD_ERROR_MESSAGES, loading.resetPassword.error?.errors),
      type: 'error',
    },
  }

  const alertDetails = passwordResetResultsAlerts[passwordResetResult]

  return (
    <Spin spinning={loading.resetPassword.isLoading}>
      <Form layout="vertical" onFinish={handlePasswordReset}>
        <FormInputPasswordPanel passwordLabel={<Trans id="password_reset.new_password_label">New password</Trans>} />
        <Form.Item>
          <ButtonElement elementType="submit">
            <Trans id="password_reset.set_new_password_button_text">Set password</Trans>
          </ButtonElement>
        </Form.Item>
        {passwordResetResult !== 'pending' && (
          <Alert
            message={alertDetails.message}
            description={alertDetails.description}
            type={alertDetails.type}
            action={alertDetails.action}
          />
        )}
      </Form>
    </Spin>
  )
}

export default ResetPasswordForm
