import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'

const selectFilesViewerState = (state: RootState) => state.files.viewer

export const selectGettingLinkToIfcFileLoading = (state: RootState): BackendLoading =>
  selectFilesViewerState(state).loading.showFileViewerModal

export const selectViewerModalVisibility = (state: RootState): boolean => selectFilesViewerState(state).isModalVisible

export const selectIfcFileLink = (state: RootState): string | undefined => selectFilesViewerState(state).fileLink

export const selectIfcFileName = (state: RootState): string | undefined => selectFilesViewerState(state).fileName
