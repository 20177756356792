import { EditOutlined } from '@ant-design/icons'
import { WithDividerElement } from 'components/atoms/divider'
import { CellWrapper } from 'components/molecules/table/Table'
import ButtonWithTooltip, { DeleteButton } from 'components/molecules/buttons'
import showConfirmationModal from 'helpers/Modals.helpers'
import { useSelector } from 'react-redux'
import { removeDiscipline, showDisciplineModal } from 'redux/administration/administration-disciplines'
import { selectAdministrationDisciplines } from 'redux/administration/administration-disciplines/AdministrationDisciplines.selectors'
import { useAppDispatch } from 'redux/store'
import { t } from '@lingui/macro'
import { deleteText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'

const DisciplineActions: React.FC<{ id: number | undefined; canDelete: boolean; name: string }> = ({
  id,
  canDelete,
  name,
}) => {
  const dispatch = useAppDispatch()
  const discipline = useSelector(selectAdministrationDisciplines).find((disc) => disc.id === id)
  return (
    <CellWrapper>
      <WithDividerElement>
        <ButtonWithTooltip
          onClick={() => dispatch(showDisciplineModal(discipline))}
          tooltipText={t({ id: 'administration.disciplines.edit_discipline_tooltip', message: 'Edit discipline' })}
          icon={<EditOutlined />}
        />
        <DeleteButton
          onClick={() =>
            showConfirmationModal({
              content: t({
                id: 'administration.disciplines.delete_confirmation_text',
                message: `Delete discipline "${name}"?`,
              }),
              okText: trans(deleteText),
              onOk: async () => {
                if (id) await dispatch(removeDiscipline(id))
              },
            })
          }
          disabled={!canDelete}
          tooltip={t({ id: 'administration.disciplines.delete_discipline_tooltip', message: 'Delete discipline' })}
          disabledTooltip={t({
            id: 'administration.disciplines.delete_used_discipline_tooltip',
            message: 'Discipline is used and cannot be deleted',
          })}
        />
      </WithDividerElement>
    </CellWrapper>
  )
}

export default DisciplineActions
