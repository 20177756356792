import { useHistory, useLocation } from 'react-router'

const useEntryRouting = (): ((entryId: number) => void) => {
  const { pathname } = useLocation()
  const history = useHistory()
  const routeToEntry = (entryId: number) =>
    history.replace(`${pathname}?sideDrawer=files&content=documentation&entryId=${entryId}`)
  return routeToEntry
}

export default useEntryRouting
