import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import { removedErrorsFromLoading } from 'helpers/HttpError.helpers'
import { BasicOrganizationData, WipGroup } from 'models/administration'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import {
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
  getStateAsAny,
  combine,
} from 'redux/redux.shared'
import { addProjectConfigFinishedStep } from '../status/ProjectStatusConfig.slice'
import { selectProjectWipGroups } from './ProjectWipGroupsConfig.selectors'
import { loadingTypes, LoadingTypes, ProjectWipGroupsConfigState } from './ProjectWipGroupsConfig.types'

export const fetchWipGroups = createAsyncBackendThunk('fetchWipGroups', async (projectId: number) => {
  return (await backendAxios.get(`/projects/${projectId}/organizations/groups/`)).data
})

export const updateWipGroups = createAsyncBackendThunk('updateWipGroups', async (args, { getState, dispatch }) => {
  const state = getStateAsAny(getState)
  const projectId = selectProjectId(state) as number
  const groups = selectProjectWipGroups(state).map((wip) => ({
    id: wip.id,
    organizations: wip.organizations.map((org) => org.id),
  }))
  const response = await backendAxios.put(`/projects/${projectId}/organizations/groups/`, groups)
  await dispatch(addProjectConfigFinishedStep({ projectId, step: 'ORGANIZATION_GROUPS' }))
  return response.data
})

const initialState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  wipGroups: [],
  shouldUpdateWipGroups: false,
} as ProjectWipGroupsConfigState

const projectWipGroupsConfigSlice = createSlice({
  name: 'projectWipGroups',
  initialState,
  reducers: {
    changeWipGroupForOrganization: (
      state,
      action: PayloadAction<{
        sourceWip: number
        movedOrg: BasicOrganizationData
        destinationWip: number
      }>
    ) => {
      const { sourceWip, movedOrg, destinationWip } = action.payload
      const wipGroups = state.wipGroups.map((wip) => {
        if (wip.displayedId === sourceWip) {
          const organizations = wip.organizations.filter((o) => o.id !== movedOrg.id)
          return { ...wip, organizations }
        }
        if (wip.displayedId === destinationWip) {
          wip.organizations.push(movedOrg)
          return { ...wip }
        }
        return { ...wip }
      })
      state.wipGroups = wipGroups
        .filter((wip) => wip.organizations.length > 0)
        .map((wip, index) => ({ ...wip, displayedId: index + 1 }))
    },
    addNewWipGroupForOrganization: (
      state,
      action: PayloadAction<{
        sourceWip: number
        movedOrg: BasicOrganizationData
        newId: number
      }>
    ) => {
      const { sourceWip, movedOrg, newId } = action.payload
      const wipGroups = state.wipGroups.map((wip) => {
        if (wip.displayedId === sourceWip) {
          const organizations = wip.organizations.filter((o) => o.id !== movedOrg.id)
          return { ...wip, organizations }
        }
        return { ...wip }
      })
      wipGroups.push({ organizations: [movedOrg], displayedId: newId })
      state.wipGroups = wipGroups
        .filter((wip) => wip.organizations.length > 0)
        .map((wip, index) => ({ ...wip, displayedId: index + 1 }))
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<ProjectWipGroupsConfigState, LoadingTypes, WipGroup[]>({
      promise: fetchWipGroups,
      loadingType: 'fetchWipGroups',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        state.wipGroups = action.payload.map((wip, index) => ({ ...wip, displayedId: index + 1 }))
      },
    }),
  ]),
})

export const { changeWipGroupForOrganization, addNewWipGroupForOrganization } = projectWipGroupsConfigSlice.actions

export const projectWipGroupsConfigReducer = projectWipGroupsConfigSlice.reducer
