import { RootState } from 'redux/store'
import { ProjectEntries } from 'models/files'
import { BackendLoading } from 'redux/redux.shared'
import { isDefinedAndNotEmpty } from 'helpers/Collection.helpers'

export const selectProjectEntries = (state: RootState): ProjectEntries | undefined =>
  state.files.documentation.projectEntries
export const selectFetchProjectEntriesLoading = (state: RootState): BackendLoading =>
  state.files.documentation.loading.fetchProjectEntries
export const selectProjectEntriesExist = (state: RootState): boolean =>
  isDefinedAndNotEmpty(selectProjectEntries(state)?.groups)
