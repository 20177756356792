import { SuitabilityCodeWithDetails } from 'models/administration'
import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import { AdministrationSuitabilityState } from './AdministrationSuitability.types'

const selectAdministrationSuitability: (state: RootState) => AdministrationSuitabilityState = (state) =>
  state.administration.suitability

export const selectAdministrationSuitabilityCodes: (state: RootState) => SuitabilityCodeWithDetails[] = (state) =>
  selectAdministrationSuitability(state).codes

export const selectAdministrationSuitabilityCodesLoading: (state: RootState) => BackendLoading = (state) =>
  selectAdministrationSuitability(state).loading.fetchSuitabilityCodes

export const selectCreatingSuitabilityCodeLoading: (state: RootState) => BackendLoading = (state) =>
  selectAdministrationSuitability(state).loading.createSuitabilityCode

export const selectSuitabilityCodeModalVisibility: (state: RootState) => boolean = (state) =>
  selectAdministrationSuitability(state).modalData.isModalVisible
