import styled from 'styled-components'
import TooltipElement from 'components/molecules/tooltip-element'
import DefaultIcon from 'components/atoms/icons/DefaultIcon'

interface SuitabilityCodeNameProps {
  isDefault: boolean
  tooltipText?: string
}

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const WithDefaultMarker: React.FC<SuitabilityCodeNameProps> = ({ children, tooltipText, isDefault }) => {
  const icon = <DefaultIcon />
  return (
    <StyledDiv>
      {children}
      {isDefault && (tooltipText ? <TooltipElement title={tooltipText}>{icon}</TooltipElement> : icon)}
    </StyledDiv>
  )
}

export default WithDefaultMarker
