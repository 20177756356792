import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RcFile } from 'antd/lib/upload/interface'
import backendAxios from 'axios/axios'
import { FrontendFile, MetadataDetails } from 'models/files'
import {
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
  BackendLoading,
  getStateAsAny,
} from 'redux/redux.shared'
import { refreshEntries } from 'redux/files/files-documentation'
import { openDocumentationNotification } from 'helpers/Notifications.helpers'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import { t } from '@lingui/macro'
import { VersionInfoDto } from '../Files.dto'

const loadingTypes = ['fetchNewVersionEntryMetadata', 'addNewEntryVersion'] as const
type LoadingTypes = typeof loadingTypes[number]

export interface NewFileVersionAddingState {
  loading: Record<LoadingTypes, BackendLoading>
  isModalVisible: boolean
  frontendFile?: FrontendFile
  entryMetadata: MetadataDetails[]
}

export const fetchNewVersionEntryMetadata = createAsyncThunk(
  'newVersion/fetchNewVersionEntryMetadata',
  async (entryId: number, { getState }) => {
    const projectId = selectProjectId(getStateAsAny(getState))
    const response = await backendAxios.post(`/projects/${projectId}/entries/common-metadata/`, [entryId])
    return response.data
  }
)
export const addNewEntryVersion = createAsyncBackendThunk(
  'newVersion/addNewEntryVersion',
  async (
    {
      file,
      frontendFile,
      routeToEntry,
    }: { file: RcFile; frontendFile: FrontendFile; routeToEntry: (entryId: number) => void },
    { dispatch }
  ) => {
    const formData = new FormData()
    formData.append('file', file)
    const response = await backendAxios.post(`/entries/${frontendFile.entryId}/files/`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    const { id } = response.data as VersionInfoDto
    dispatch(refreshEntries({ addedFileId: id }))
    if (frontendFile.cdeStatus !== 'IN_PROGRESS') {
      routeToEntry(frontendFile.entryId)
    }
    openDocumentationNotification('success', {
      message: t({
        id: 'project.files.notifications.revision_added',
        message: `New revision of file ${frontendFile.shortName} has been added`,
      }),
    })
  }
)

const initialState = {
  isModalVisible: false,
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
} as NewFileVersionAddingState

const filesNewVersionSlice = createSlice({
  name: 'newVersion',
  initialState,
  reducers: {
    showNewVersionPopover: (state, action: PayloadAction<FrontendFile>) => {
      state.isModalVisible = true
      state.frontendFile = action.payload
    },
    hideNewVersionPopover: () => initialState,
  },
  extraReducers: combine([
    generateExtraBackendReducers<NewFileVersionAddingState, LoadingTypes, MetadataDetails[]>({
      promise: fetchNewVersionEntryMetadata,
      loadingType: 'fetchNewVersionEntryMetadata',
      onFulfilled: (state, action) => {
        state.entryMetadata = action.payload
      },
    }),
    generateExtraBackendReducers<NewFileVersionAddingState, LoadingTypes>({
      promise: addNewEntryVersion,
      loadingType: 'addNewEntryVersion',
      onFulfilled: (state) => {
        Object.assign(state, initialState)
      },
    }),
  ]),
})

export const { showNewVersionPopover, hideNewVersionPopover } = filesNewVersionSlice.actions

export const filesNewVersionReducer = filesNewVersionSlice.reducer
