import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Login from 'components/pages/login/Login'
import Activation from 'components/pages/activation/Activation'
import { selectGlobalPermissions, selectIsAuthenticated } from 'redux/login/Login.selectors'
import ProjectPage from 'components/pages/project'
import PortfolioPage from 'components/pages/portfolio'
import PasswordReset from 'components/pages/password/PasswordReset'
import ChangePasswordPage from 'components/pages/change-password'
import NotificationsPage from 'components/pages/notifications'
import AdministrationPage from 'components/pages/administration'
import ProjectConfigPage from 'components/pages/project-config'
import { setRedirectLink } from 'redux/login/Login.slice'
import UserProfilePage from 'components/pages/user-profile'
import FlowConfigPage from 'components/pages/flow-config/FlowConfigPage'

export default function Routes(): JSX.Element {
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const globalPermissions = useSelector(selectGlobalPermissions)
  const isRedirectLinkSetRef = useRef(false)
  const { pathname, search } = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isAuthenticated && !isRedirectLinkSetRef.current) {
      dispatch(setRedirectLink(pathname + search))
    }
    isRedirectLinkSetRef.current = true
  }, [dispatch, isAuthenticated, pathname, search])

  return isAuthenticated ? (
    <Switch>
      <Route path="/project/:projectId" component={ProjectPage} />
      <Route path="/" exact component={PortfolioPage} />
      <Route path="/change-password" exact component={ChangePasswordPage} />
      <Route path="/profile" exact component={UserProfilePage} />
      <Route path="/notifications" exact component={NotificationsPage} />
      {globalPermissions?.isAdmin && <Route path="/administration" exact component={AdministrationPage} />}
      <Route path="/activation" component={Activation} />
      <Route path="/project-config/:projectId/:step?" component={ProjectConfigPage} />
      <Route path="/flow-config/:projectId/:step/:flowId" exact component={FlowConfigPage} />
      <Route path="/flow-config/:projectId/:step" exact component={FlowConfigPage} />
      <Redirect to="/" />
    </Switch>
  ) : (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/activation" component={Activation} />
      <Route path="/password-reset" component={PasswordReset} />
      <Redirect to="/login" />
    </Switch>
  )
}
