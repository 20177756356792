import { Attribute } from 'models/administration'
import { FlowAttributeConfig } from 'models/flow'
import type { BackendLoading } from 'redux/redux.shared'

export const loadingType = ['fetchProjectAttributes', 'fetchFlowData', 'updateFlowAttributes'] as const
export type LoadingType = typeof loadingType[number]

export interface FlowAttributesConfigState {
  loading: Record<LoadingType, BackendLoading>
  flowMetadataConfig: FlowAttributeConfig[]
  projectAttributes: Attribute[]
  isAdding: boolean
}

export interface RawFlowMetadata {
  metadata: number
  values: number[]
}
