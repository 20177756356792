import { useSelector } from 'react-redux'
import {
  selectIsProjectStagesConfigContentLoading,
  selectIsStagesStepValid,
} from 'redux/project/project-config/stages/ProjectStagesConfig.selectors'
import { updateProjectStages } from 'redux/project/project-config/stages/ProjectStagesConfig.slice'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import BaseProjectConfigView from '../base-config-view/BaseProjectConfigView'
import ProjectStagesConfigContent from './content'

const ProjectStagesConfig: React.FC = () => {
  const projectId = useSelector(selectProjectId)
  const isContentValid = useSelector(selectIsStagesStepValid)
  const isContentLoading = useSelector(selectIsProjectStagesConfigContentLoading)
  return (
    <BaseProjectConfigView
      content={<ProjectStagesConfigContent />}
      saveStepThunk={updateProjectStages(projectId as number)}
      isContentValid={isContentValid}
      isContentLoading={isContentLoading}
    />
  )
}

export default ProjectStagesConfig
