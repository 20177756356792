import SelectElement from 'components/atoms/select-element'
import Table from 'components/molecules/table'
import { selectText } from 'data/messages/controls'
import { itemArr } from 'helpers/Collection.helpers'
import trans from 'helpers/i18n.helpers'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import {
  selectIsAddingNewDiscipline,
  selectSelectedDisciplines,
  selectAvailableDisciplines,
} from 'redux/project/project-config/disciplines/ProjectDisciplinesConfig.selectors'
import { addDisciplineToSelected } from 'redux/project/project-config/disciplines'
import { useAppDispatch } from 'redux/store'
import styled from 'styled-components'
import useDisciplinesColumns from './columns'

const StyledTable = styled(Table)`
  &&&& {
    width: 800px;
    td {
      &:nth-child(1),
      &:nth-child(3) {
        text-align: center;
      }
    }
  }
`

const ProjectDisciplinesConfigTable: React.FC = memo(() => {
  const dispatch = useAppDispatch()
  const availableDisciplines = useSelector(selectAvailableDisciplines)
  const selectedDisciplines = useSelector(selectSelectedDisciplines)
  const isAddingNewDiscipline = useSelector(selectIsAddingNewDiscipline)
  const columns = useDisciplinesColumns()

  return (
    <StyledTable
      columns={columns}
      dataSource={[
        ...selectedDisciplines,
        ...itemArr(
          {
            id: -1,
            name: (
              <SelectElement
                bordered={false}
                placeholder={trans(selectText)}
                options={availableDisciplines.map(({ name, id }) => ({ label: name, value: id }))}
                onChange={(val) => dispatch(addDisciplineToSelected(val as number))}
              />
            ),
          },
          isAddingNewDiscipline
        ),
      ]}
      bordered
      pagination={false}
      rowKey="id"
      scroll={{ y: 600 }}
    />
  )
})

export default ProjectDisciplinesConfigTable
