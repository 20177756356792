import Text from 'components/atoms/text'
import { SelectProps, SelectValue } from 'antd/lib/select'
import AutoSelect from 'components/atoms/select-element/AutoSelect'
import { FormItemProps } from 'antd/lib/form'
import { WithGetErrors } from 'hooks/useFormValidity'
import BaseFormItem, { BaseFormItemProps } from './BaseFormItem'

const FormSelect: React.FC<SelectProps<SelectValue> & FormItemProps & BaseFormItemProps & WithGetErrors> = ({
  label,
  placeholder,
  options,
  disabled = false,
  showArrow = true,
  showSearch = false,
  name,
  rules,
  value,
  defaultValue,
  mode,
  tagRender,
  onChange,
  loading,
  noMargin,
  allowClear,
  validateStatus,
  getErrors,
  help,
}) => (
  <BaseFormItem
    rules={rules}
    name={name}
    label={label}
    noMargin={noMargin}
    getErrors={getErrors}
    validateStatus={validateStatus}
    help={help}
  >
    <AutoSelect
      allowClear={allowClear}
      disabled={disabled}
      mode={mode}
      tagRender={tagRender}
      showArrow={showArrow}
      showSearch={showSearch}
      onChange={onChange}
      value={value}
      defaultValue={defaultValue}
      optionFilterProp="label"
      loading={loading}
      placeholder={
        <Text textStyle="regularText-small" color="grayG04">
          {placeholder}
        </Text>
      }
      options={options}
    />
  </BaseFormItem>
)

export default FormSelect
