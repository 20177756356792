import { t } from '@lingui/macro'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import { removedErrorsFromLoading } from 'helpers/HttpError.helpers'
import { openSuccessNotification } from 'helpers/Notifications.helpers'
import { Discipline } from 'models/administration'
import {
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
} from 'redux/redux.shared'
import { loadingTypes, LoadingTypes, AdministrationDisciplinesState } from './AdministrationDisciplines.types'

interface DisciplineProperties {
  name?: string
  code?: string
}

export const fetchDisciplines = createAsyncBackendThunk('fetchDisciplines', async () => {
  const response = await backendAxios.get('/disciplines/')
  return response.data
})

export const removeDiscipline = createAsyncBackendThunk('removeDiscipline', async (disciplineId: number) => {
  await backendAxios.delete(`/disciplines/${disciplineId}/`)
  openSuccessNotification({
    message: t({
      id: 'administration.disciplines.notifications.deleted_text',
      message: 'Discipline has been deleted',
    }),
  })
})

export const addDiscipline = createAsyncBackendThunk('addDiscipline', async (discipline: DisciplineProperties) => {
  const response = await backendAxios.post(`/disciplines/`, discipline)
  openSuccessNotification({
    message: t({
      id: 'administration.disciplines.notifications.added_text',
      message: 'Discipline has been added',
    }),
  })
  return response.data
})

interface EditDisciplineArgs {
  disciplineId: number
  fieldsToUpdate: DisciplineProperties
}

export const editDiscipline = createAsyncBackendThunk(
  'editDiscipline',
  async ({ disciplineId, fieldsToUpdate }: EditDisciplineArgs) => {
    const response = await backendAxios.patch(`/disciplines/${disciplineId}/`, fieldsToUpdate)
    openSuccessNotification({
      message: t({
        id: 'administration.disciplines.notifications.updated_text',
        message: 'Discipline has been updated',
      }),
    })
    return response.data
  }
)

const initialState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  modalData: { isModalVisible: false },
  disciplines: [],
} as AdministrationDisciplinesState

const administrationDisciplinesSlice = createSlice({
  name: 'disciplines',
  initialState,
  reducers: {
    showDisciplineModal: (state, discipline: PayloadAction<Discipline | undefined>) => {
      state.modalData.isModalVisible = true
      state.modalData.discipline = discipline?.payload
    },
    hideDisciplineModal: (state) => {
      state.modalData.isModalVisible = false
      state.modalData.discipline = undefined
      state.loading = removedErrorsFromLoading(state.loading)
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<AdministrationDisciplinesState, LoadingTypes, Discipline[]>({
      promise: fetchDisciplines,
      loadingType: 'fetchDisciplines',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        state.disciplines = action.payload
      },
    }),
    generateExtraBackendReducers<AdministrationDisciplinesState, LoadingTypes, void, number>({
      promise: removeDiscipline,
      loadingType: 'removeDiscipline',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        const disciplineId = action.meta.arg
        state.disciplines = state.disciplines.filter(({ id }) => id !== disciplineId)
      },
    }),
    generateExtraBackendReducers<AdministrationDisciplinesState, LoadingTypes, Discipline>({
      promise: addDiscipline,
      loadingType: 'addDiscipline',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        const discipline = action.payload
        state.disciplines.push(discipline)
        state.modalData.isModalVisible = false
        state.modalData.discipline = undefined
      },
    }),
    generateExtraBackendReducers<AdministrationDisciplinesState, LoadingTypes, Discipline, EditDisciplineArgs>({
      promise: editDiscipline,
      loadingType: 'editDiscipline',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        const { disciplineId } = action.meta.arg
        state.modalData = initialState.modalData
        state.disciplines = state.disciplines.map((discipline) => {
          const itemsUpdated = discipline.id === disciplineId ? action.payload : {}
          return { ...discipline, ...itemsUpdated }
        })
      },
    }),
  ]),
})

export const { showDisciplineModal, hideDisciplineModal } = administrationDisciplinesSlice.actions

export const administrationDisciplinesReducer = administrationDisciplinesSlice.reducer
