import { Spin } from 'antd'
import styled from 'styled-components'

const StyledSpin = styled(Spin)`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const FullscreenSpin: React.FC = () => {
  return <StyledSpin size="large" />
}

export default FullscreenSpin
