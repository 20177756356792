import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectUserAccountData } from 'redux/login/Login.selectors'
import { fetchUserAccountData } from 'redux/login/Login.slice'

const MainNavBarUserData: React.FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchUserAccountData())
  }, [dispatch])
  const userData = useSelector(selectUserAccountData)
  return <ul>{userData && `${userData.name} (${userData.organization})`}</ul>
}

export default MainNavBarUserData
