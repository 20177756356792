import { useDispatch, useSelector } from 'react-redux'
import { TreeProps } from 'rc-tree'
import { TreeNode } from 'components/molecules/map/map-sidedrawer-tree/MapSideDrawerTreeTypes'
import { setVisibleGisProjectLayers, setCheckedGisProjectNodes } from 'redux/map/map-gis-layers/map-gis-project-layers'
import { selectCheckedGisProjectLayerNodes } from 'redux/map/map-gis-layers/map-gis-project-layers/MapGisProjectLayers.selectors'
import MapSideDrawerTree from 'components/molecules/map/map-sidedrawer-tree/MapSideDrawerTree'
import { ProjectLayer } from 'models/map'

interface GisProjectSideDrawerTreeProps {
  data: ProjectLayer[]
}

const GisProjectSideDrawerTree: React.FC<GisProjectSideDrawerTreeProps> = ({ data }) => {
  const checkedKeys = useSelector(selectCheckedGisProjectLayerNodes)
  const dispatch = useDispatch()
  const handleNodeCheck: TreeProps['onCheck'] = (checked, info) => {
    const checkedNodes = info.checkedNodes.map((node: TreeNode) => node.key)
    dispatch(setCheckedGisProjectNodes(checkedNodes))
    const visibleLayers = info.checkedNodes.flatMap((node: TreeNode) => node.gsLayersReferences || [])
    dispatch(setVisibleGisProjectLayers(visibleLayers))
  }
  return <MapSideDrawerTree data={data} onCheck={handleNodeCheck} checkedKeys={checkedKeys} />
}

export default GisProjectSideDrawerTree
