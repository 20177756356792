import useQueryParams from './queryParams'

interface UseActivationParamsReturnValue {
  activationCode: string | null
}

const useActivationParams = (): UseActivationParamsReturnValue => {
  const query = useQueryParams()
  const activationCode = query.get('code')
  return { activationCode }
}

export default useActivationParams
