import { t } from '@lingui/macro'
import { Modal } from 'antd'
import axios from 'axios'
import { errorText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import { BACKEND_SERVER_URL } from '../environment/environment'

const backendAxios = axios.create({
  baseURL: BACKEND_SERVER_URL,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createErrorInterceptor: () => (error: any) => any = () => {
  let isModalVisible = false
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errorInterceptor = (error: any) => {
    const { response } = error
    if (response.status === 423 && response.data?.detail === 'SYSTEM_BACKUP_IN_PROGRESS' && !isModalVisible) {
      isModalVisible = true
      Modal.error({
        title: trans(errorText),
        content: t({
          id: 'common.errors.backup_in_progress_error',
          message: 'System backup is currently being created. The system works in read-only mode.',
        }),
        onOk: () => {
          isModalVisible = false
        },
      })
    }
    return Promise.reject(error)
  }
  return errorInterceptor
}

backendAxios.interceptors.response.use(undefined, createErrorInterceptor())

export default backendAxios
