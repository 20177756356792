import styled from 'styled-components'

interface PortfolioTemplateProps {
  children: React.ReactNode
}

const StyledSection = styled.section`
  padding: ${(props) => props.theme.sizes.m} ${(props) => props.theme.sizes.xxl};
`

const PortfolioTemplate: React.FC<PortfolioTemplateProps> = ({ children }) => {
  return <StyledSection>{children}</StyledSection>
}

export default PortfolioTemplate
