import Text from 'components/atoms/text'
import trans from 'helpers/i18n.helpers'
import { getProjectStatus } from 'helpers/Models.helpers'
import { ProjectStatusType } from 'redux/administration/administration-projects/AdministrationProjects.types'
import { Color } from 'styled-components'
import projectStatusLabels from '../texts'

interface ProjectStatusProps {
  isEnded?: boolean
  isActive?: boolean
}

const statusColor: Record<ProjectStatusType, Color> = {
  IN_CONFIG: 'orange6',
  ACTIVE: 'green8',
  ARCHIVED: 'grayG07',
  ALL: 'baseBlackish',
}

const DisplayProjectStatus: React.FC<ProjectStatusProps> = ({ isEnded, isActive }) => {
  const status = getProjectStatus({ isActive, isEnded })
  const color = statusColor[status]
  return (
    <Text color={color} textStyle="regularText-small">
      {trans(projectStatusLabels[status])}
    </Text>
  )
}

export default DisplayProjectStatus
