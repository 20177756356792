import { Discipline, Permission } from 'models/project/projectConfig'
import { BackendLoading } from 'redux/redux.shared'

export const loadingTypes = [
  'fetchMembersPermissions',
  'updateMembersPermissions',
  'fetchAvailableDisciplines',
] as const
export type LoadingTypes = typeof loadingTypes[number]

export interface MemberPermission {
  id: number
  user: number
  permissions: {
    discipline: number
    permissions: Permission
  }[]
}

export interface ProjectMembersPermissionsConfigState {
  loading: Record<LoadingTypes, BackendLoading>
  membersPermissions: MemberPermission[]
  selectedOrganizations: number[]
  selectedDisciplines: number[]
  selectedUsers: number[]
  checkedUsers: number[]
  permissionsFetched: boolean
  changedUsers: number[]
  availableDisciplines: Discipline[]
}
