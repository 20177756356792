import { SuitabilityCode } from 'models/administration'
import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import { ProjectSuitabilityConfigState, SelectedStatus } from './ProjectSuitabilityConfig.types'

const selectProjectSuitabilityConfig: (state: RootState) => ProjectSuitabilityConfigState = (state) =>
  state.project.config.suitability

export const selectProjectSuitabilityCodes: (state: RootState) => SuitabilityCode[] = (state) =>
  selectProjectSuitabilityConfig(state).codes

export const selectProjectSuitabilityCodesSelectedStatus: (state: RootState) => SelectedStatus = (state) =>
  selectProjectSuitabilityConfig(state).selectedStatus

export const selectProjectFilteredSuitabilityCodes: (state: RootState) => SuitabilityCode[] = (state) => {
  const status = selectProjectSuitabilityCodesSelectedStatus(state)
  const codes = selectProjectSuitabilityCodes(state)
  if (status === 'ALL') {
    return codes
  }
  return codes.filter((code) => code.status === status)
}

export const selectProjectSuitabilityOverviewLoading: (state: RootState) => BackendLoading = (state) =>
  selectProjectSuitabilityConfig(state).loading.fetchSuitabilityOverview

export const selectIsProjectSuitabilityConfigContentLoading: (state: RootState) => boolean = (state) =>
  selectProjectSuitabilityOverviewLoading(state).isLoading
