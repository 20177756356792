import { Trans } from '@lingui/macro'
import Text from 'components/atoms/text'
import SelectWithLoading from 'components/atoms/select-element/SelectWithLoading'
import { selectText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import styled from 'styled-components'
import { useAppDispatch } from 'redux/store'
import { useSelector } from 'react-redux'
import { editCurrentEPSG } from 'redux/project/project-details'
import { selectMapProjections } from 'redux/project/project-details/ProjectDetails.selectors'

interface SystemArgs {
  system: string
}

const StyledLi = styled.li`
  cursor: pointer;
`

const CoordinatesSystemItem: React.FC<SystemArgs> = ({ system }) => {
  const projections = useSelector(selectMapProjections)
  const dispatch = useAppDispatch()
  const possibleProjections = projections.map((p) => ({ name: p.name, value: p.id }))
  return (
    <StyledLi>
      <span>
        <Text textStyle="regularText-small">
          <Trans id="molecules.coordinates_panel.label.system">Coordinates system:</Trans>
        </Text>
        <SelectWithLoading
          bordered={false}
          placeholder={trans(selectText)}
          onChange={async (val) => {
            dispatch(editCurrentEPSG(val as number))
          }}
          value={system}
          options={possibleProjections}
        />
      </span>
    </StyledLi>
  )
}

export default CoordinatesSystemItem
