import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Breadcrumb } from 'antd'
import Text from 'components/atoms/text'
import withKeys from 'helpers/Component.helpers'
import useCurrentPath from 'hooks/query/currentPath'
import { preparePath, getDirectoriesKeysFromPath } from 'helpers/Files.helpers'
import trans from 'helpers/i18n.helpers'
import { documentationText } from 'data/messages/misc'

const StyledHeader = styled.header`
  padding: ${(props) => props.theme.sizes.s} ${(props) => props.theme.sizes.default};
  display: flex;
  width: 100%;
  align-items: flex-end;
  > *:not(:first-child) {
    margin-left: ${(props) => props.theme.sizes.m};
  }
  .ant-breadcrumb {
    color: ${(props) => props.theme.colors.grayG06};
    font-size: ${(props) => props.theme.sizes.s};
    &-separator {
      margin: 0 4px;
    }
  }
`

const StyledBreadcrumb = styled(Breadcrumb)`
  white-space: nowrap;
  max-width: calc(100% - 164px);
  overflow: hidden;
  text-overflow: ellipsis;
`

const FilesSideDrawerDocumentationHeading: React.FC = () => {
  const pathParam = useCurrentPath()
  const { pathname } = useLocation()
  const pathItems = pathParam ? pathParam.split('/').map((item) => <span>{item}</span>) : []
  const selectedKeys = getDirectoriesKeysFromPath(pathParam || '')
  return (
    <StyledHeader>
      <Text textStyle="header-H2b">{trans(documentationText)}</Text>
      <StyledBreadcrumb>
        {withKeys(pathItems).map((item, idx) => (
          <Breadcrumb.Item key={item.key}>
            <Link to={preparePath(pathname, selectedKeys[idx])}>{item.component}</Link>
          </Breadcrumb.Item>
        ))}
      </StyledBreadcrumb>
    </StyledHeader>
  )
}

export default FilesSideDrawerDocumentationHeading
