import { Space } from 'antd'
import SpaceElement from 'components/atoms/space'
import Table from 'components/molecules/table/Table'
import SchemeOrderDragger from 'components/organisms/scheme-order-dragger/SchemeOrderDragger'
import { NamingSchemeElement } from 'models/administration'
import { useSelector } from 'react-redux'
import { selectAdministrationCodesSettingsDict } from 'redux/administration/administration-code-settings/AdministrationCodeSettings.selectors'
import { getSchemeData } from 'redux/administration/administration-naming-scheme/AdministrationNamingScheme.helpers'
import styled from 'styled-components'
import { AttributeType } from 'redux/administration/administration-naming-scheme/AdministrationNamingScheme.types'
import { selectAdministrationNamingSchemesMetadata } from 'redux/administration/administration-naming-scheme/AdministrationNamingScheme.selectors'
import useSchemeDetailsColumns from './columns'

interface NamingSchemeDetailsProps {
  value?: NamingSchemeElement[]
  onChange?: (value: NamingSchemeElement[]) => void
}

interface StyledTableProps {
  $hideHeader: boolean
  $grayed?: boolean
}

const StyledTable = styled(Table)<StyledTableProps>`
  max-width: 1000px;
  &&&& {
    th {
      ${(props) => (props.$hideHeader ? 'display: none;' : '')}
    }
    .ant-table-row-level-0 td {
      ${(props) =>
        props.$hideHeader
          ? `border-top: 1px solid ${props.theme.colors.table}; background-color: ${props.theme.colors.grayG02};`
          : ''}
    }
    td {
      border-color: #e3e3e8;
      ${(props) => (props.$grayed ? `background-color: ${props.theme.colors.grayG02};` : '')}
      &:nth-child(2),
      &:nth-child(5),
      &:nth-child(6) {
        text-align: center;
      }
    }
  }
`

const StyledSpace = styled(Space)`
  display: inline-flex;
`

const NamingSchemeDetails: React.FC<NamingSchemeDetailsProps> = ({ value, onChange }) => {
  const attributes = useSelector(selectAdministrationNamingSchemesMetadata)
  const settings = useSelector(selectAdministrationCodesSettingsDict)

  const toggleInShortName: (type: AttributeType, id: number | undefined, selected: boolean) => void = (
    type,
    id,
    selected
  ) => {
    if (!onChange || !value) return
    const newValue = value.map(({ key, args }) =>
      type === key && args?.id === id
        ? { key, args: { id: args?.id, length: args?.length, inShortName: selected } }
        : { key, args }
    )
    onChange(newValue)
  }

  const setNumberLength: (length: number) => void = (length) => {
    if (!onChange || !value) return
    const newValue = value.map(({ key, args }) =>
      key === 'fileNumber'
        ? { key, args: { id: args?.id, inShortName: args?.inShortName || false, length } }
        : { key, args }
    )
    onChange(newValue)
  }

  const removeField: (type: AttributeType, id: number | undefined) => void = (type, id) => {
    if (!onChange || !value) return
    const newValue = value.filter(({ key, args }) => type !== key || args?.id !== id)
    onChange(newValue)
  }

  const columns = useSchemeDetailsColumns(true, toggleInShortName, setNumberLength, removeField)

  const schemeData = getSchemeData(value || [], attributes, settings, true)
  const tableData = schemeData.filter(({ type }) => type !== 'revision')
  const revisionData = schemeData.filter(({ type }) => type === 'revision')

  return (
    <SpaceElement>
      <SchemeOrderDragger scheme={value} attributes={attributes} onChange={onChange} />
      <StyledSpace direction="vertical" size="large">
        <StyledTable columns={columns} dataSource={tableData} bordered pagination={false} scroll={{ y: '225px' }} />
        {revisionData.length > 0 && (
          <StyledTable columns={columns} bordered pagination={false} dataSource={revisionData} $hideHeader />
        )}
      </StyledSpace>
    </SpaceElement>
  )
}

export default NamingSchemeDetails
