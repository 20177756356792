import { ProjectStage } from 'models/administration'
import { BackendLoading } from 'redux/redux.shared'

export const loadingTypes = ['fetchProjectStages', 'fetchStagesOverview', 'updateProjectStages'] as const
export type LoadingTypes = typeof loadingTypes[number]

export interface ProjectStages {
  stages: ProjectStage[]
  current: number | null
}

export interface ProjectStagesConfigState {
  loading: Record<LoadingTypes, BackendLoading>
  stages?: ProjectStages
  stagesOverview: ProjectStage[]
  isProjectActive?: boolean
  endedStage?: number
}
