import { useSelector } from 'react-redux'
import { selectSelectedGroup, selectCheckedGroups } from 'redux/files/files-table/FilesTable.selectors'
import { generateAttribute } from 'helpers/Files.helpers'
import { SelectedGroup } from 'models/files'
import { identity } from 'helpers/Functions.helpers'
import { t } from '@lingui/macro'
import FilesDetailsDir from './FilesDetailsGroup'

const FilesDetailsDirCheck: React.FC = () => {
  const selectedGroup = useSelector(selectSelectedGroup)
  const checkedGroups = useSelector(selectCheckedGroups)
  if (!selectedGroup || checkedGroups.length === 0) return null
  const displayName = (() => {
    if (checkedGroups.length === 1) {
      return checkedGroups[0].value.name
    }
    return t({
      id: 'project.files.selected_directories_count',
      message: `Selected directories: ${checkedGroups.length}`,
    })
  })()

  const additionalAttribute = (() => {
    if (checkedGroups.length === 1) {
      const dir = checkedGroups[0]
      if (!dir.grouping || !selectedGroup.key) return null
      return generateAttribute(selectedGroup.key, selectedGroup.value, dir.value.name)
    }
    return null
  })()
  const frontendFiles = checkedGroups
    .flatMap((group) => [...(group.frontendFiles ? group.frontendFiles : [])])
    .filter(identity)
  const checkedSelectedEntries: SelectedGroup = {
    containFrontendFiles: frontendFiles.length !== 0,
    groups: checkedGroups,
    frontendFiles,
    isRoot: false,
    displayName,
    attributes: [...selectedGroup.attributes, ...(additionalAttribute ? [additionalAttribute] : [])],
  }
  return <FilesDetailsDir showName={checkedGroups.length <= 1} group={checkedSelectedEntries} />
}

export default FilesDetailsDirCheck
