import { generateStyledTableWithType } from 'helpers/Styles.helpers'
import styled, { css } from 'styled-components'

const tableCellMixin = css`
  font-size: ${(props) => props.theme.sizes.s};
  line-height: ${(props) => props.theme.sizes.default};
  color: ${(props) => props.theme.colors.grayG07};
  vertical-align: middle;
`

const tableMixin = css`
  &&& {
    .ant-table,
    .ant-table-wrapper {
      margin: 0;
    }
    .ant-table-content > table {
      .ant-checkbox-checked .ant-checkbox-inner::after {
        border-spacing: 0;
      }
    }
    .ant-table-container {
      border-top: none;
      border-left: none;
      .ant-table-body {
        overflow-y: auto !important;
      }
    }
    .ant-table-empty {
      td.ant-table-cell {
        border: none;
        text-align: center;
      }
      th.ant-table-cell {
        border: none;
      }
    }
    .ant-table-thead {
      tr {
        th.ant-table-cell {
          background-color: transparent;
          border: none;
          ${tableCellMixin}
          font-weight: ${(props) => props.theme.fontWeights.semiBold};
          border-bottom: 1px solid #f0f0f0;
        }
      }
    }
    .ant-table-tbody {
      tr {
        td {
          ${tableCellMixin}
          padding: ${(props) => `${props.theme.sizes.s} ${props.theme.sizes.xs}`};
          .ant-select,
          .ant-form-item,
          .ant-form-item-control,
          .ant-form-item-control-input,
          .ant-form-item-control-input-content,
          .ant-input {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            height: 100%;
            &:not(.ant-form-item-has-error input) {
              border: none;
            }
            right: 0;
            display: flex;
            align-items: center;
            .ant-select-selector {
              width: 100%;
              ${tableCellMixin}
              .ant-select-selection-item {
                ${tableCellMixin}
              }
            }
            &-disabled {
              .ant-select-selector {
                .ant-select-selection-item {
                  color: ${(props) => props.theme.colors.disabled};
                }
              }
            }
          }
          position: relative;
          &:first-child:not(.ant-table-selection-column) {
            border-left: 1px solid #f0f0f0;
          }
        }
      }
    }
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-spin-nested-loading {
      margin: 0;
    }
  }
`

export const CellWrapper = styled.div`
  height: 20px;
  position: relative;
  ${(props) => props.theme.mixins.flexCenter}
  &:not(:last-child) {
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-spin-container {
    display: flex;
    align-items: center;
  }
`

export default generateStyledTableWithType(tableMixin)
