import { MessageDescriptor } from '@lingui/core'
import { defineMessage } from '@lingui/macro'

export const monthNames: MessageDescriptor[] = [
  defineMessage({ id: 'common.dates.months.abbreviations.january', message: 'Jan' }),
  defineMessage({ id: 'common.dates.months.abbreviations.february', message: 'Feb' }),
  defineMessage({ id: 'common.dates.months.abbreviations.march', message: 'Mar' }),
  defineMessage({ id: 'common.dates.months.abbreviations.april', message: 'Apr' }),
  defineMessage({ id: 'common.dates.months.abbreviations.may', message: 'May' }),
  defineMessage({ id: 'common.dates.months.abbreviations.june', message: 'Jun' }),
  defineMessage({ id: 'common.dates.months.abbreviations.july', message: 'Jul' }),
  defineMessage({ id: 'common.dates.months.abbreviations.august', message: 'Aug' }),
  defineMessage({ id: 'common.dates.months.abbreviations.september', message: 'Aep' }),
  defineMessage({ id: 'common.dates.months.abbreviations.october', message: 'Oct' }),
  defineMessage({ id: 'common.dates.months.abbreviations.november', message: 'Nov' }),
  defineMessage({ id: 'common.dates.months.abbreviations.december', message: 'Dec' }),
]

export const dayNames: MessageDescriptor[] = [
  defineMessage({ id: 'common.dates.days.abbreviations.monday', message: 'Mon' }),
  defineMessage({ id: 'common.dates.days.abbreviations.tuesday', message: 'Tue' }),
  defineMessage({ id: 'common.dates.days.abbreviations.wednesday', message: 'Wed' }),
  defineMessage({ id: 'common.dates.days.abbreviations.thursday', message: 'Thu' }),
  defineMessage({ id: 'common.dates.days.abbreviations.friday', message: 'Fri' }),
  defineMessage({ id: 'common.dates.days.abbreviations.satudray', message: 'Sat' }),
  defineMessage({ id: 'common.dates.days.abbreviations.sunday', message: 'Sun' }),
]
