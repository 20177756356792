import { useSelector } from 'react-redux'
import { selectFilesDetailsActiveTab } from 'redux/files/files-details/FilesDetails.selectors'
import FilesDetailsHeading from 'components/molecules/files/files-details/FilesDetailsHeading'
import FilesDetailsItem from 'components/molecules/files/files-details/FilesDetailsItem'
import { DetailsItem, DetailsItemWithRender } from 'models/files'
import Text from 'components/atoms/text'
import CdeStatusItem from 'components/molecules/files/cde-status'
import { Trans } from '@lingui/macro'

interface FileDetailsContentProps {
  title: string
  icon: JSX.Element
  characteristics: DetailsItem[]
  attributes: DetailsItem[]
}

const toDetailsItemWithRender = (detailsItems: DetailsItem[]): DetailsItemWithRender[] =>
  detailsItems.map((item) => ({
    ...item,
    renderValue: item.key === 'status' ? (val) => <CdeStatusItem status={val} /> : undefined,
  }))

const FileDetailsContent: React.FC<FileDetailsContentProps> = ({ title, icon, characteristics, attributes }) => {
  const activeTab = useSelector(selectFilesDetailsActiveTab)

  const detailsContentDict = {
    CHARACTERISTICS: toDetailsItemWithRender(characteristics),
    ATTRIBUTES: toDetailsItemWithRender(attributes),
  }

  return (
    <>
      <FilesDetailsHeading title={title} icon={icon} />
      <div>
        {detailsContentDict[activeTab].length ? (
          detailsContentDict[activeTab].map(({ name, value, renderValue }) => (
            <FilesDetailsItem key={name} name={name} value={value} renderValue={renderValue} />
          ))
        ) : (
          <Text textStyle="regularText-medium" center>
            <Trans id="project.files.file_info.no_attributes">No attributes</Trans>
          </Text>
        )}
      </div>
    </>
  )
}

export default FileDetailsContent
