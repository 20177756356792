import { ReactComponent as DefaultIconChecked } from 'assets/misc-icons/default-marker-checked.svg'
import { ReactComponent as DefaultIconUnchecked } from 'assets/misc-icons/default-marker-unchecked.svg'

interface DefaultIconProps {
  checked?: boolean
}

const DefaultIcon: React.FC<DefaultIconProps> = ({ checked = true }) => {
  return checked ? <DefaultIconChecked /> : <DefaultIconUnchecked />
}

export default DefaultIcon
