import { Discipline } from 'models/administration'
import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'

export const selectAdministrationDisciplines: (state: RootState) => Discipline[] = (state) =>
  state.administration.disciplines.disciplines

export const selectFetchingDisciplinesLoading: (state: RootState) => BackendLoading = (state) =>
  state.administration.disciplines.loading.fetchDisciplines

export const selectRemovingDisciplinesLoading: (state: RootState) => BackendLoading = (state) =>
  state.administration.disciplines.loading.removeDiscipline

export const selectIsDisciplineModalVisible = (state: RootState): boolean =>
  state.administration.disciplines.modalData.isModalVisible

export const selectModalDiscipline = (state: RootState): Discipline | undefined =>
  state.administration.disciplines.modalData.discipline

export const selectAddDisciplineLoading = (state: RootState): BackendLoading =>
  state.administration.disciplines.loading.addDiscipline

export const selectEditDisciplineLoading = (state: RootState): BackendLoading =>
  state.administration.disciplines.loading.editDiscipline
