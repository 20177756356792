import { t } from '@lingui/macro'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import { openSuccessNotification } from 'helpers/Notifications.helpers'
import { ProjectStageWithUsage } from 'models/administration'
import {
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
} from 'redux/redux.shared'
import removedErrorsFromState from './AdministrationProjectStages.helpers'
import { AdministrationProjectStagesState, loadingTypes, LoadingTypes } from './AdministrationProjectStages.types'

interface ProjectStageProperties {
  name: string
  code: string
}

interface UpdateProjectStageArgs {
  stageId: number
  stageFieldsToUpdate: Partial<ProjectStageProperties>
}

export const fetchProjectStages = createAsyncBackendThunk('fetchProjectStages', async () => {
  return (await backendAxios.get('/projects/stages/')).data
})

export const updateProjectStage = createAsyncBackendThunk(
  'updateProjectStage',
  async ({ stageId, stageFieldsToUpdate }: UpdateProjectStageArgs) => {
    let data
    if (stageFieldsToUpdate.name || stageFieldsToUpdate.code) {
      data = (await backendAxios.patch(`/projects/stages/${stageId}/`, stageFieldsToUpdate)).data
    }
    openSuccessNotification({
      message: t({
        id: 'administration.project_stages.notifications.updated_text',
        message: 'Project stage has been updated',
      }),
    })
    return data
  }
)

export const addProjectStage = createAsyncBackendThunk(
  'addProjectStage',
  async (stage: ProjectStageProperties, { dispatch }) => {
    await backendAxios.post(`/projects/stages/`, stage)
    dispatch(fetchProjectStages())
    openSuccessNotification({
      message: t({
        id: 'administration.project_stages.notifications.added_text',
        message: 'Project stage has been added',
      }),
    })
  }
)

export const removeProjectStage = createAsyncBackendThunk('removeProjectStage', async (stageId: number) => {
  await backendAxios.delete(`/projects/stages/${stageId}/`)
  openSuccessNotification({
    message: t({
      id: 'administration.project_stages.notifications.deleted_text',
      message: 'Project stage has been deleted',
    }),
  })
})

const initialState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  stages: [],
  modalState: { isModalVisible: false, stage: undefined },
} as AdministrationProjectStagesState

const administrationProjectStagesSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    openProjectStageModal: (state, action: PayloadAction<ProjectStageWithUsage | undefined>) => {
      state.modalState.isModalVisible = true
      state.modalState.stage = action.payload
    },
    closeProjectStageModal: (state) => {
      state.modalState = initialState.modalState
      state.loading = removedErrorsFromState(state.loading)
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<AdministrationProjectStagesState, LoadingTypes, ProjectStageWithUsage[]>({
      promise: fetchProjectStages,
      loadingType: 'fetchProjectStages',
      onPending: (state) => {
        state.loading = removedErrorsFromState(state.loading)
      },
      onFulfilled: (state, action) => {
        state.stages = action.payload
      },
    }),
    generateExtraBackendReducers<
      AdministrationProjectStagesState,
      LoadingTypes,
      ProjectStageWithUsage | undefined,
      UpdateProjectStageArgs
    >({
      promise: updateProjectStage,
      loadingType: 'updateProjectStage',
      onPending: (state) => {
        state.loading = removedErrorsFromState(state.loading)
      },
      onFulfilled: (state, action) => {
        const editedStage = action.payload
        if (editedStage !== undefined) {
          const { stageId } = action.meta.arg
          state.stages = state.stages.map((stage) => {
            return stage.id === stageId ? editedStage : stage
          })
        }
        state.modalState = initialState.modalState
      },
    }),
    generateExtraBackendReducers<AdministrationProjectStagesState, LoadingTypes>({
      promise: addProjectStage,
      loadingType: 'addProjectStage',
      onPending: (state) => {
        state.loading = removedErrorsFromState(state.loading)
      },
      onFulfilled: (state) => {
        state.modalState = initialState.modalState
      },
    }),
    generateExtraBackendReducers<AdministrationProjectStagesState, LoadingTypes, void, number>({
      promise: removeProjectStage,
      loadingType: 'removeProjectStage',
      onPending: (state) => {
        state.loading = removedErrorsFromState(state.loading)
      },
      onFulfilled: (state, action) => {
        const stageId = action.meta.arg
        state.stages = state.stages.filter(({ id }) => id !== stageId)
      },
    }),
  ]),
})

export const { openProjectStageModal, closeProjectStageModal } = administrationProjectStagesSlice.actions

export const administrationProjectStagesReducer = administrationProjectStagesSlice.reducer
