import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectPortfolioProjects,
  selectProjectsTypeSelectState,
  selectPortfolioSearchBarValue,
  selectFetchProjectsLoading,
} from 'redux/portfolio/PortfolioProjects.selectors'
import { Spin } from 'antd'
import { fetchProjects, clearPortfolioProjectsState } from 'redux/portfolio/PortfolioProjects.slice'
import PortfolioTemplate from 'components/templates/portfolio/PortfolioTemplate'
import MainNavBar from 'components/organisms/main-navbar'
import WithHeaderTemplate from 'components/templates/with-header'
import PortfolioHeader from 'components/organisms/portfolio/portfolio-header'
import PortfolioItems from 'components/organisms/portfolio/PortfolioItems'
import { EmptyPortfolioContent } from 'components/organisms/portfolio'

const PortfolioPage: React.FC = () => {
  const dispatch = useDispatch()
  const portfolioProjects = useSelector(selectPortfolioProjects)
  const projectsTypeSelectState = useSelector(selectProjectsTypeSelectState)
  const searchBarValue = useSelector(selectPortfolioSearchBarValue)
  const { isLoading } = useSelector(selectFetchProjectsLoading)
  const filteredProjects = portfolioProjects
    ? portfolioProjects.filter((project) => project.name.toUpperCase().includes(searchBarValue.toUpperCase()))
    : []
  useEffect(() => {
    dispatch(fetchProjects({ ended: projectsTypeSelectState.isEnded }))
  }, [dispatch, projectsTypeSelectState.isEnded])
  useEffect(() => {
    return () => {
      dispatch(clearPortfolioProjectsState())
    }
  }, [dispatch])
  return (
    <WithHeaderTemplate
      header={<MainNavBar />}
      content={
        <Spin size="large" spinning={isLoading}>
          <PortfolioTemplate>
            <PortfolioHeader projectNumber={filteredProjects.length} />
            {portfolioProjects && portfolioProjects.length === 0 ? (
              <EmptyPortfolioContent />
            ) : (
              <PortfolioItems projects={filteredProjects} />
            )}
          </PortfolioTemplate>
        </Spin>
      }
    />
  )
}

export default PortfolioPage
