import { Trans } from '@lingui/macro'
import { useDispatch } from 'react-redux'
import { showDisciplineModal } from 'redux/administration/administration-disciplines'
import AdministrationToolbarButton from '../toolbar'

const AdministrationDisciplinesToolbar: React.FC = () => {
  const dispatch = useDispatch()
  return (
    <AdministrationToolbarButton
      text={<Trans id="administration.disciplines.create_new_discipline_button_text">Create new discipline</Trans>}
      onClick={() => dispatch(showDisciplineModal())}
    />
  )
}

export default AdministrationDisciplinesToolbar
