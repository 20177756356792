import { combineReducers } from 'redux'
import { userProfileClear } from 'redux/action.types'
import { wrapWithClear } from 'helpers/Functions.helpers'
import { userProfileMenuReducer } from './user-profile-menu'
import { userProfilePermissionReducer } from './permissions'

const userProfileReducer = combineReducers({
  menu: userProfileMenuReducer,
  permissions: userProfilePermissionReducer,
})

export default wrapWithClear(userProfileReducer, userProfileClear)
