import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import userProfileOptions from 'components/organisms/user-profile/userProfileOptions'

interface UserProfileMenuState {
  selectedSubMenu: string
  selectedItem: string
}

const initialState = {
  selectedSubMenu: userProfileOptions[0].key,
  selectedItem: userProfileOptions[0].items[0].key,
} as UserProfileMenuState

interface SelectionProps {
  subMenu: string
  item: string
}

const userProfileMenuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setSelectedUserProfileMenuKey: (state, action: PayloadAction<SelectionProps>) => {
      state.selectedItem = action.payload.item
      state.selectedSubMenu = action.payload.subMenu
    },
  },
})

export const { setSelectedUserProfileMenuKey } = userProfileMenuSlice.actions

export const userProfileMenuReducer = userProfileMenuSlice.reducer
