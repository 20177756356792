import {
  acceptOptionDescription,
  acceptOptionLabel,
  acceptWithCommentOptionDescription,
  acceptWithCommentOptionLabel,
  rejectDescription,
  rejectLabel,
  rejectWithCommentOptionDescription,
  rejectWithCommentOptionLabel,
} from './messages'

export const acceptOption = {
  label: acceptOptionLabel,
  value: 'ACCEPTED',
  labelDescription: acceptOptionDescription,
  disabled: false,
}
export const acceptWithCommentOption = {
  label: acceptWithCommentOptionLabel,
  value: 'ACCEPTED_WITH_COMMENT',
  labelDescription: acceptWithCommentOptionDescription,
  disabled: true,
}
export const rejectOption = {
  label: rejectLabel,
  value: 'REJECTED',
  labelDescription: rejectDescription,
  disabled: false,
}
export const rejectWithCommentOption = {
  label: rejectWithCommentOptionLabel,
  value: 'REJECTED_WITH_COMMENT',
  labelDescription: rejectWithCommentOptionDescription,
  disabled: true,
}
