import themePxValueToNumber from 'helpers/Styles.helpers'
import styled, { Color, Size } from 'styled-components'

interface StyledDotProps {
  color: Color
  size?: Size
  fontColor?: Color
  className?: string
}

interface StyledSpanProps {
  $color: Color
  $size: Size
  $fontColor: Color
}

const StyledSpan = styled.span<StyledSpanProps>`
  &&& {
    background-color: ${(props) => props.theme.colors[props.$color]};
    width: ${(props) => props.theme.sizes[props.$size]};
    height: ${(props) => props.theme.sizes[props.$size]};
    display: inline-block;
    border-radius: 50%;
    line-height: ${(props) => props.theme.sizes[props.$size]};
    font-size: ${(props) => themePxValueToNumber(props.theme.sizes[props.$size]) - 5}px;
    color: ${(props) => props.theme.colors[props.$fontColor]};
    text-align: center;
  }
`

const Dot: React.FC<StyledDotProps> = ({ color, size = 'xs', children, className, fontColor = 'baseBlackish' }) => {
  return (
    <StyledSpan className={className} $color={color} $size={size} $fontColor={fontColor}>
      {children}
    </StyledSpan>
  )
}

export default Dot
