import { Discipline } from 'models/administration'
import PortfolioProject from 'models/portfolio'
import { Permission } from 'models/project/projectConfig'
import { BackendLoading } from 'redux/redux.shared'

export const loadingTypes = ['fetchUserProjectPermissions', 'fetchProjectDisciplines', 'fetchProjects'] as const
export type LoadingTypes = typeof loadingTypes[number]

export interface UserPermissionState {
  loading: Record<LoadingTypes, BackendLoading>
  disciplines: Discipline[]
  permissions: UserPermission[]
  projects: PortfolioProject[]
  selectedProject?: number
}

export interface UserPermission {
  discipline: number
  permissions: Permission
}

export interface ProjectUserPermissionsConfigState {
  loading: Record<LoadingTypes, BackendLoading>
  userPermissions: UserPermission[]
}
