import Text from 'components/atoms/text'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { selectBackupsSettings } from 'redux/administration/administration-backups/AdministrationBackups.selectors'
import { Trans } from '@lingui/macro'
import SpaceElement from 'components/atoms/space'
import ButtonElement from 'components/molecules/button-element'
import { setSettingsModalVisibility } from 'redux/administration/administration-backups'
import trans from 'helpers/i18n.helpers'
import { numberOfStoredBackupsText } from '../messages'
import BackupSettingDisplay from './BackupSettingDisplay'

const Column = styled.div`
  display: table;
  height: 100%;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const BackupSettings: React.FC = () => {
  const dispatch = useDispatch()
  const settings = useSelector(selectBackupsSettings)
  return (
    <SpaceElement direction="vertical">
      <Header>
        <Text textStyle="regularText-smallSemiBold">
          <Trans id="administration.backups.backup_config_header">Backup creation configuration</Trans>
        </Text>
        <ButtonElement type="secondary" onClick={() => dispatch(setSettingsModalVisibility(true))}>
          <Trans id="administration.backups.edit_settings_button">Edit settings</Trans>
        </ButtonElement>
      </Header>
      <SpaceElement direction="horizontal" size="xl">
        {/* <Column>
          {settings?.creationPeriod && (
            <BackupSettingDisplay
              label={trans(backupCreationPeriodText)}
              value={trans(periodLabels[settings?.creationPeriod])}
            />
          )}
          {settings?.days && settings?.creationPeriod && (
            <BackupSettingDisplay
              label={trans(backupDaysText)}
              value={formatPeriodicDays(settings?.creationPeriod, settings?.days)}
            />
          )}
          {settings?.hour && <BackupSettingDisplay label={trans(backupTimeText)} value={settings?.hour} />}
        </Column> */}
        <Column>
          {settings?.maxStoredBackups !== undefined && (
            <BackupSettingDisplay label={trans(numberOfStoredBackupsText)} value={settings?.maxStoredBackups} />
          )}
          {/* {settings?.days && settings?.creationPeriod && settings?.hour && (
            <NextBackupCountdownDisplay period={settings?.creationPeriod} days={settings?.days} time={settings?.hour} />
          )} */}
        </Column>
      </SpaceElement>
    </SpaceElement>
  )
}

export default BackupSettings
