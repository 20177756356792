import { useSelector } from 'react-redux'
import { Form, Checkbox } from 'antd'
import Text from 'components/atoms/text'
import FormInput from 'components/molecules/form-item/FormInput'
import { nameInputRules, required, requiredTyped, maxStringLength } from 'helpers/Validators.helpers'
import FormSelect from 'components/molecules/form-item/FormSelect'
import { selectAdministrationOrganizations } from 'redux/administration/administration-organizations/AdministrationOrganizations.selectors'
import { selectEditedUser } from 'redux/administration/administration-users/AdministrationUsers.selectors'
import { FormInstance } from 'antd/lib/form'
import { selectAdministrationDisciplines } from 'redux/administration/administration-disciplines/AdministrationDisciplines.selectors'
import { selectText } from 'data/messages/controls'
import { t, Trans } from '@lingui/macro'
import trans from 'helpers/i18n.helpers'
import BaseFormItem from 'components/molecules/form-item/BaseFormItem'
import MultipleSelectWithTag from 'components/atoms/select-element/multiple-with-tag/MultipleSelectWithTag'
import { MAX_EMAIL_LENGTH, USER_NAME_LENGTH, USER_SURNAME_LENGTH } from 'data/model_restrictions'
import { GetFormItemErrors } from 'hooks/useFormValidity'

interface UserLoadingValues {
  loading: boolean
  isCreateModal: boolean
  isAdmin: boolean
}

interface ModalProps {
  loadingValues: UserLoadingValues
  form: FormInstance
  validate: () => void
  getFormItemErrors: GetFormItemErrors
}

const UserModalForm: React.FC<ModalProps> = ({ loadingValues, form, validate, getFormItemErrors }) => {
  const editedUser = useSelector(selectEditedUser)
  const availableOrganizations = useSelector(selectAdministrationOrganizations)
  const availableDisciplines = useSelector(selectAdministrationDisciplines)
  return (
    <Form
      form={form}
      labelCol={{ sm: { span: 7 } }}
      wrapperCol={{ sm: { span: 16 } }}
      initialValues={{
        isAdmin: editedUser?.isAdmin ?? false,
        disciplines: editedUser?.disciplines.map((d) => d.id) || [],
        email: editedUser?.email,
      }}
      onFieldsChange={validate}
    >
      {loadingValues.isCreateModal && (
        <>
          <FormInput
            label={<Trans id="administration.users.modal.name_field_label">Name</Trans>}
            name="firstName"
            placeholder={t({ id: 'administration.users.modal.name_field_placeholder', message: 'Enter name' })}
            rules={nameInputRules(USER_NAME_LENGTH)}
            getErrors={getFormItemErrors}
          />
          <FormInput
            label={<Trans id="administration.users.modal.surname_field_label">Surname</Trans>}
            name="lastName"
            placeholder={t({ id: 'administration.users.modal.surname_field_placeholder', message: 'Enter surname' })}
            rules={nameInputRules(USER_SURNAME_LENGTH)}
            getErrors={getFormItemErrors}
          />
          <FormSelect
            key="org"
            name="organization"
            label={<Trans id="administration.users.modal.organization_field_label">Organization</Trans>}
            showSearch
            placeholder={trans(selectText)}
            rules={[required()]}
            options={availableOrganizations.map(({ code, name, id }) => ({
              label: `${code.code} - ${name}`,
              value: id,
            }))}
            getErrors={getFormItemErrors}
          />
        </>
      )}
      <FormInput
        disabled={editedUser?.activationDetails.isActive}
        label={<Trans id="administration.users.modal.email_field_label">Email</Trans>}
        name="email"
        placeholder={t({ id: 'administration.users.modal.email_field_placeholder', message: 'Enter email' })}
        validateFirst
        rules={[
          required(),
          maxStringLength(MAX_EMAIL_LENGTH),
          requiredTyped(
            'email',
            t({ id: 'administration.users.modal.email_field_error', message: 'Invalid email address' })
          ),
        ]}
        getErrors={getFormItemErrors}
      />
      <Form.Item wrapperCol={{ sm: { span: 40, offset: 5 } }} name="isAdmin" valuePropName="checked">
        <Checkbox defaultChecked={false}>
          <Text textStyle="regularText-small">
            <Trans id="administration.users.modal.admin_permissions_field_label">Admin permissions</Trans>
          </Text>
        </Checkbox>
      </Form.Item>
      <BaseFormItem
        name="disciplines"
        label={<Trans id="administration.users.modal.disciplines_field_label">Disciplines</Trans>}
      >
        <MultipleSelectWithTag
          options={availableDisciplines.map(({ code, name, id }) => ({ label: `${code.code} - ${name}`, value: id }))}
          filterOption={(inputValue, option) => {
            return option && option.children.props.children.toLowerCase().includes(inputValue.toLowerCase())
          }}
        />
      </BaseFormItem>
    </Form>
  )
}

export default UserModalForm
