import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchEntriesCommonMetadata, clearEntriesCommonMetadata } from 'redux/files/files-details'
import { selectCheckedFrontendFiles } from 'redux/files/files-table/FilesTable.selectors'
import { uniqueArr } from 'helpers/Collection.helpers'

const useFilesCommonMetadata = (): void => {
  const checkedFrontendFiles = useSelector(selectCheckedFrontendFiles)
  const dispatch = useDispatch()
  useEffect(() => {
    const checkedEntryIds = checkedFrontendFiles.map(({ entryId }) => entryId)
    const entryUniqueIds = uniqueArr(checkedEntryIds)
    dispatch(fetchEntriesCommonMetadata(entryUniqueIds))
    return () => {
      dispatch(clearEntriesCommonMetadata())
    }
  }, [checkedFrontendFiles, dispatch])
}

export default useFilesCommonMetadata
