import { Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import Sider from 'antd/lib/layout/Sider'
import styled from 'styled-components'

interface AdministrationTemplateProps {
  left: React.ReactNode
  right: React.ReactNode
}

const StyledLayout = styled(Layout)`
  min-height: 100%;
`

const StyledSider = styled(Sider)`
  overflow: auto;
  height: 100%;
  position: fixed;
  left: 0;
`

const siderWidth = 264

const StyledContent = styled(Content)`
  margin-left: ${siderWidth}px;
  padding: ${({ theme }) => theme.sizes.m};
`

const AdministrationTemplate: React.FC<AdministrationTemplateProps> = ({ left, right }) => {
  return (
    <StyledLayout>
      <StyledSider width={siderWidth}>{left}</StyledSider>
      <StyledContent>{right}</StyledContent>
    </StyledLayout>
  )
}

export default AdministrationTemplate
