import { Trans } from '@lingui/macro'
import { Card, Row, Space } from 'antd'
import Text from 'components/atoms/text'
import trans from 'helpers/i18n.helpers'
import themePxValueToNumber from 'helpers/Styles.helpers'
import { useSelector } from 'react-redux'
import selectSelectedAdministrationMenuKey from 'redux/administration/administration-menu/AdministrationMenu.selectors'
import styled from 'styled-components'
import administrationOptions from '../administrationOptions'

const StyledDiv = styled.div`
  height: 100%;
  .ant-typography {
    flex-shrink: 0;
  }
`

const StyledCard = styled(Card)`
  min-height: 100%;
  min-width: max-content;
  display: flex;
  .ant-card-body {
    width: 100%;
  }
`

const StyledSpace = styled(Space)`
  height: calc(100% - ${(props) => themePxValueToNumber(props.theme.sizes.l)}px);
  & > .ant-space-item:nth-child(2) {
    height: calc(100% - ${(props) => themePxValueToNumber(props.theme.sizes.m) * 2}px);
    overflow-y: auto;
  }
`

const AdministrationContent: React.FC = () => {
  const selectedAdministrationMenuKey = useSelector(selectSelectedAdministrationMenuKey)
  const currentOption = administrationOptions.find(({ key }) => key === selectedAdministrationMenuKey)
  return (
    <StyledDiv>
      <Text textStyle="header-H1">
        <Trans id="administration.common.main_header">System administration</Trans>
      </Text>
      <StyledSpace size="middle" direction="vertical" style={{ width: '100%' }}>
        <Row justify="space-between" align="bottom">
          <Text textStyle="header-H2">{currentOption?.name ? trans(currentOption?.name) : ''}</Text>
          {currentOption?.heading}
        </Row>
        <StyledCard>{currentOption?.component}</StyledCard>
      </StyledSpace>
    </StyledDiv>
  )
}

export default AdministrationContent
