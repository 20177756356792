import Text from 'components/atoms/text'
import prettyBytes from 'pretty-bytes'
import { Trans } from '@lingui/macro'

interface BackupsSizeDisplayProps {
  size: number | null
}

const BackupsSizeDisplay: React.FC<BackupsSizeDisplayProps> = ({ size }) => {
  const color = size !== null ? 'baseBlackish' : 'grayG04'
  const text =
    size !== null ? (
      prettyBytes(size)
    ) : (
      <Trans id="administration.backups.table.no_backup_file_text">No file created</Trans>
    )
  return (
    <Text color={color} textStyle="regularText-small">
      {text}
    </Text>
  )
}

export default BackupsSizeDisplay
