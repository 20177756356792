import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import { moveArrayItemToNewIndex } from 'helpers/Collection.helpers'
import { identity } from 'helpers/Functions.helpers'
import { ProjectStage } from 'models/administration'
import {
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
  getStateAsAny,
} from 'redux/redux.shared'
import { addProjectConfigFinishedStep } from '../status/ProjectStatusConfig.slice'
import { selectProjectStages } from './ProjectStagesConfig.selectors'
import { loadingTypes, LoadingTypes, ProjectStages, ProjectStagesConfigState } from './ProjectStagesConfig.types'

export const fetchProjectStages = createAsyncBackendThunk(
  'project-config/fetchProjectStages',
  async (projectId: number) => {
    return (await backendAxios.get(`/projects/${projectId}/stages/`)).data
  }
)

export const updateProjectStages = createAsyncBackendThunk(
  'project-config/updateProjectStages',
  async (projectId: number, { getState, dispatch }) => {
    const state = getStateAsAny(getState)
    const stages = selectProjectStages(state)
    const stagesToUpdate = stages?.stages.map(({ id }) => id)
    await backendAxios.post(`/projects/${projectId}/stages/`, { current: stages?.current, stages: stagesToUpdate })
    await dispatch(addProjectConfigFinishedStep({ projectId, step: 'STAGES' }))
  }
)

export const fetchStagesOverview = createAsyncBackendThunk('project-config/fetchStagesOverview', async () => {
  return (await backendAxios.get(`/projects/stages/overview/`)).data
})

const initialState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  stagesOverview: [],
  shouldStagesUpdate: false,
} as ProjectStagesConfigState

const projectStagesConfigSlice = createSlice({
  name: 'projectSuitability',
  initialState,
  reducers: {
    addStages: (state, action: PayloadAction<number[]>) => {
      const { stagesOverview } = state
      if (state.stages) {
        const stagesToAdd = action.payload
          .map((stageId) => stagesOverview.find(({ id }) => id === stageId))
          .filter(identity) as ProjectStage[]
        state.stages.stages = [...state.stages.stages, ...stagesToAdd]
      }
    },
    removeStage: (state, action: PayloadAction<number>) => {
      if (state.stages) {
        const stageToRemove = action.payload
        const filteredStages = state.stages.stages.filter((stage) => stage.id !== stageToRemove)
        state.stages.stages = filteredStages
        if (stageToRemove === state.stages.current) {
          state.stages.current = null
        }
      }
    },
    changeStagesOrder: (state, action: PayloadAction<{ sourceIdx: number; destinationIdx: number }>) => {
      if (state.stages) {
        const { sourceIdx, destinationIdx } = action.payload
        moveArrayItemToNewIndex(state.stages.stages, sourceIdx, destinationIdx)
      }
    },
    setCurrentStage: (state, action: PayloadAction<number>) => {
      if (state.stages) {
        state.stages.current = action.payload
        state.endedStage = undefined
      }
    },
    endStage: (state) => {
      if (state.stages && state.stages.current) {
        state.endedStage = state.stages.current
        state.stages.current = null
      }
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<ProjectStagesConfigState, LoadingTypes, ProjectStages>({
      promise: fetchProjectStages,
      loadingType: 'fetchProjectStages',
      onFulfilled: (state, action) => {
        state.stages = action.payload
      },
    }),
    generateExtraBackendReducers<ProjectStagesConfigState, LoadingTypes, ProjectStage[]>({
      promise: fetchStagesOverview,
      loadingType: 'fetchStagesOverview',
      onFulfilled: (state, action) => {
        state.stagesOverview = action.payload
      },
    }),
    generateExtraBackendReducers<ProjectStagesConfigState, LoadingTypes, ProjectStage[]>({
      promise: updateProjectStages,
      loadingType: 'updateProjectStages',
    }),
  ]),
})

export const { addStages, removeStage, changeStagesOrder, setCurrentStage, endStage } = projectStagesConfigSlice.actions

export const projectStagesConfigReducer = projectStagesConfigSlice.reducer
