import { Attribute, NamingScheme } from 'models/administration'
import { RootState } from 'redux/store'
import { BackendLoading } from 'redux/redux.shared'
import { AdministrationNamingSchemeState } from './AdministrationNamingScheme.types'

const selectAdministrationNamingSchemesBase: (state: RootState) => AdministrationNamingSchemeState = (state) =>
  state.administration.namingSchemes

export const selectAdministrationNamingSchemes: (state: RootState) => NamingScheme[] = (state) =>
  selectAdministrationNamingSchemesBase(state).schemes

export const selectAdministrationNamingSchemesFetchingLoading: (state: RootState) => BackendLoading = (state) =>
  selectAdministrationNamingSchemesBase(state).loading.fetchNamingSchemes

export const selectAdministrationNamingSchemesMetadataFetchingLoading: (state: RootState) => BackendLoading = (state) =>
  selectAdministrationNamingSchemesBase(state).loading.fetchMetadata

export const selectAdministrationDeletingSchemaLoading: (state: RootState) => BackendLoading = (state) =>
  selectAdministrationNamingSchemesBase(state).loading.deleteNamingScheme

export const selectAdministrationPatchingSelectedSchemeLoading: (state: RootState) => BackendLoading = (state) =>
  selectAdministrationNamingSchemesBase(state).loading.patchSelectedScheme

export const selectAdministrationNamingSchemeCreationLoading: (state: RootState) => BackendLoading = (state) =>
  selectAdministrationNamingSchemesBase(state).loading.createNamingScheme

export const selectAdministrationNamingSchemesMetadata: (state: RootState) => Attribute[] = (state) =>
  selectAdministrationNamingSchemesBase(state).metadata

export const selectAdministationNamingSchemeModalIsOpen: (state: RootState) => boolean = (state) =>
  selectAdministrationNamingSchemesBase(state).modalState.isOpen

export const selectAdministationNamingSchemeInModal: (state: RootState) => NamingScheme | undefined = (state) =>
  selectAdministrationNamingSchemesBase(state).modalState.scheme

export const selectAdministationNamingSchemeIsModalActionCopy: (state: RootState) => boolean = (state) =>
  selectAdministrationNamingSchemesBase(state).modalState.isActionCopy
