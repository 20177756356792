import { Attribute, NamingScheme, NamingSchemeElemenentArg } from 'models/administration'
import { BackendLoading } from 'redux/redux.shared'

export const loadingTypes = [
  'fetchNamingSchemes',
  'createNamingScheme',
  'fetchMetadata',
  'deleteNamingScheme',
  'patchSelectedScheme',
  'setSchemeAsDefault',
] as const
export type LoadingTypes = typeof loadingTypes[number]

export interface NamingSchemeModalState {
  isOpen: boolean
  scheme?: NamingScheme
  isActionCopy: boolean
}

export interface SchemeElementData {
  name: string
  type: AttributeType
  code: string
  values?: string[]
  length?: number
  args?: NamingSchemeElemenentArg
}

export interface AdministrationNamingSchemeState {
  loading: Record<LoadingTypes, BackendLoading>
  schemes: NamingScheme[]
  metadata: Attribute[]
  modalState: NamingSchemeModalState
}

export interface NamingSchemeElementWithMetadata {
  key: AttributeType
  metadataName?: string
}

export interface NamingSchemeWithMetadata {
  id: number
  name: string
  scheme: NamingSchemeElementWithMetadata[]
  isDefault: boolean
}

export type AttributeType =
  | 'initiator'
  | 'project'
  | 'discipline'
  | 'projectStage'
  | 'fileNumber'
  | 'revision'
  | 'date'
  | 'metadata'
  | 'suitability'

export type NamingSchemeWithDetails = NamingScheme & { details: SchemeElementData[] }
