import { Trans } from '@lingui/macro'
import Text from 'components/atoms/text'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProjectStages, fetchStagesOverview } from 'redux/project/project-config/stages'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import { Divider, Space } from 'antd'
import StagesManagement from './stages-management/StagesManagement'
import StagesOrderTable from './table/StagesOrderTable'
import ProjectStagesConfigAlert from './ProjectStagesConfigAlert'

const ProjectStagesConfigContent: React.FC = () => {
  const dispatch = useDispatch()
  const projectId = useSelector(selectProjectId)
  useEffect(() => {
    dispatch(fetchStagesOverview())
  }, [dispatch])
  useEffect(() => {
    if (projectId) {
      dispatch(fetchProjectStages(projectId))
    }
  }, [dispatch, projectId])
  return (
    <Space direction="vertical" size="large" style={{ gridColumn: '1/-1' }}>
      <Text textStyle="regularText-medium">
        <Trans id="project_config.stages.step_header">Project stages</Trans>
      </Text>
      <StagesManagement />
      <Divider style={{ margin: '0' }} />
      <StagesOrderTable />
      <ProjectStagesConfigAlert />
    </Space>
  )
}

export default ProjectStagesConfigContent
