import { ColumnType } from 'antd/lib/table/interface'

interface TableSortingProps<T> {
  showSorterTooltip: boolean
  sorter: ColumnType<T>['sorter']
  defaultSortOrder: ColumnType<T>['defaultSortOrder']
  sortDirections?: ColumnType<T>['sortDirections']
}

interface TableSortingArgs<T, Q> {
  sortBy: (arg: T) => Q
  withTooltip?: boolean
  defaultOrder?: ColumnType<T>['defaultSortOrder']
  comparator?: (arg1: Q, arg2: Q) => number
}

function useTableColumnSorting<T, Q>({
  sortBy,
  defaultOrder,
  withTooltip = false,
  comparator,
}: TableSortingArgs<T, Q>): TableSortingProps<T> {
  return {
    showSorterTooltip: withTooltip,
    sorter: (x: T, y: T) => {
      const fieldX = sortBy(x)
      const fieldY = sortBy(y)
      if (comparator) {
        return comparator(fieldX, fieldY)
      }
      return typeof fieldX === 'string' && typeof fieldY === 'string'
        ? fieldX.localeCompare(fieldY)
        : Number(fieldX) - Number(fieldY)
    },
    defaultSortOrder: defaultOrder,
  }
}

export default useTableColumnSorting
