import ErrorAlert from 'components/atoms/error-alert'
import { handleApiErrors } from 'helpers/HttpError.helpers'
import { useSelector } from 'react-redux'
import { selectRemovingDisciplinesLoading } from 'redux/administration/administration-disciplines/AdministrationDisciplines.selectors'
import { defineMessage, t } from '@lingui/macro'

const removeDisciplineErrorDict = {
  'discipline.used_in_project': defineMessage({
    id: 'administration.disciplines.discipline_is_used_error',
    message: 'Discipline is used in project(s) and cannot be deleted.',
  }),
}

const AdministrationDisciplinesAlerts: React.FC = () => {
  const removingDisciplineLoading = useSelector(selectRemovingDisciplinesLoading)
  const alertMessageDict = [
    {
      error: removingDisciplineLoading.error,
      message: `${t({
        id: 'administration.disciplines.delete_error',
        message: 'Could not delete discipline.',
      })}. ${handleApiErrors(removeDisciplineErrorDict, removingDisciplineLoading.error?.errors)}`,
    },
  ]
  return <ErrorAlert alertMessageDict={alertMessageDict} />
}

export default AdministrationDisciplinesAlerts
