import { Trans } from '@lingui/macro'
import Text from 'components/atoms/text'
import styled from 'styled-components'

const StyledEmptyContent = styled.div`
  position: static;
  margin-top: 10%;
  margin-left: 50%;
  width: 600px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.sizes.default};
`

const NoReviewersWarning: React.FC = () => {
  return (
    <StyledEmptyContent>
      <Text textStyle="regularText-smallSemiBold" color="volcano5">
        <Trans id="project.flows.modal.no_reviewers_warning">Warning! No reviewers available!</Trans>
      </Text>
      <Text textStyle="regularText-smallSemiBold" paragraph center>
        <Trans id="project.flows.modal.no_reviewers_description_info">
          If this flow should be executed for selected file, ask project admin to add corect possible reviewer.
        </Trans>
      </Text>
    </StyledEmptyContent>
  )
}

export default NoReviewersWarning
