import { RootState } from 'redux/store'
import { FieldData } from 'models/components'
import { BackendLoading } from 'redux/redux.shared'
import { Discipline, Metadata } from './FilesNewEntry.slice'

export const selectIsEntryModalVisible = (state: RootState): boolean => state.files.newEntry.isModalVisible
export const selectNewEntryAvailableAttributes = (
  state: RootState
): {
  disciplines: Discipline[]
  metadata: Metadata[]
} => ({ disciplines: state.files.newEntry.disciplines, metadata: state.files.newEntry.metadata })
export const selectNewEntryAttributeFields = (state: RootState): FieldData<number>[] =>
  state.files.newEntry.attributeFields
export const selectIsNewEntryAttributesFormValid = (state: RootState): boolean =>
  state.files.newEntry.isAttributesFormValid
export const selectNewEntryDescription = (state: RootState): string | undefined => state.files.newEntry.description
export const selectIsNewEntryDescriptionValid = (state: RootState): boolean => state.files.newEntry.isDescriptionValid
export const selectFetchingNewEntryMetadataAndDisciplinesLoading = (state: RootState): BackendLoading =>
  state.files.newEntry.loading.fetchNewEntryMetadataAndDisciplines
export const selectAddNewEntryLoading = (state: RootState): BackendLoading => state.files.newEntry.loading.addNewEntry

export const selectIsAddingNextFile = (state: RootState): boolean => state.files.newEntry.isAddingNextFile
