import { useSelector } from 'react-redux'
import { useAppDispatch } from 'redux/store'
import { selectAdministrationProjectStages } from 'redux/administration/administration-project-stages/AdministrationProjectStages.selectors'
import { openProjectStageModal, removeProjectStage } from 'redux/administration/administration-project-stages'
import ButtonWithTooltip, { DeleteButton } from 'components/molecules/buttons'
import { EditOutlined } from '@ant-design/icons'
import { WithDividerElement } from 'components/atoms/divider'
import showConfirmationModal from 'helpers/Modals.helpers'
import { t } from '@lingui/macro'
import { deleteText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'

interface ProjectStageActionsProps {
  stageId: number
  canDelete: boolean
  name: string
}

const ProjectStageActions: React.FC<ProjectStageActionsProps> = ({ stageId, canDelete, name }) => {
  const dispatch = useAppDispatch()
  const stage = useSelector(selectAdministrationProjectStages).find((projectStage) => projectStage.id === stageId)
  if (stage === undefined) {
    return null
  }
  return (
    <WithDividerElement>
      <ButtonWithTooltip
        onClick={() => dispatch(openProjectStageModal(stage))}
        tooltipText={t({
          id: 'administration.project_stages.edit_project_stage_tooltip',
          message: 'Edit project stage',
        })}
        icon={<EditOutlined />}
      />
      <DeleteButton
        onClick={() =>
          showConfirmationModal({
            content: t({
              id: 'administration.project_stages.delete_confirmation_text',
              message: `Delete project stage "${name}"?`,
            }),
            okText: trans(deleteText),
            onOk: async () => {
              await dispatch(removeProjectStage(stageId))
            },
          })
        }
        disabled={!canDelete}
        tooltip={t({
          id: 'administration.project_stages.delete_project_stage_tooltip',
          message: 'Delete project stage',
        })}
        disabledTooltip={t({
          id: 'administration.project_stages.delete_used_project_stage_tooltip',
          message: 'Project stage is used and cannot be deleted',
        })}
      />
    </WithDividerElement>
  )
}

export default ProjectStageActions
