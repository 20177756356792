import BackgroundOnHoverWrapper from 'components/atoms/hover'
import SpaceElement from 'components/atoms/space'
import TooltipElement from 'components/molecules/tooltip-element'
import React from 'react'
import { SchemeElementData } from 'redux/administration/administration-naming-scheme/AdministrationNamingScheme.types'

interface NamingSchemeSampleNameProps {
  scheme: SchemeElementData[]
}

const NamingSchemeSampleName: React.FC<NamingSchemeSampleNameProps> = ({ scheme }) => {
  return (
    <SpaceElement size="xxs" direction="horizontal">
      {scheme.map(({ code, name, type, args }, idx) => (
        <React.Fragment key={`${type}${args?.id || 0}`}>
          <TooltipElement title={name} placement="top">
            <BackgroundOnHoverWrapper color="primaryP01">{code}</BackgroundOnHoverWrapper>
          </TooltipElement>
          {idx !== scheme.length - 1 && '-'}
        </React.Fragment>
      ))}
    </SpaceElement>
  )
}

export default NamingSchemeSampleName
