import cdeStatusDict from 'data/cdeStatus'
import { CdeStatus } from 'models/files'
import Text from 'components/atoms/text'
import Dot from 'components/atoms/dot'
import SpaceElement from 'components/atoms/space'
import styled from 'styled-components'
import trans from 'helpers/i18n.helpers'

interface SuitabilityCodeStatusDisplayProps {
  status: CdeStatus
}

const StyledSpaceElement = styled(SpaceElement)`
  align-items: center;
`

const SuitabilityCodeStatusDisplay: React.FC<SuitabilityCodeStatusDisplayProps> = ({ status }) => {
  const { color, text } = cdeStatusDict[status]
  return (
    <StyledSpaceElement size="xs" direction="horizontal">
      <Dot color={color} />
      <Text textStyle="regularText-small" color="grayG07">
        {trans(text)}
      </Text>
    </StyledSpaceElement>
  )
}

export default SuitabilityCodeStatusDisplay
