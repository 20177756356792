import { Row } from 'antd'
import styled from 'styled-components'

interface LoginTemplateProps {
  children: React.ReactNode
}

const StyledRow = styled(Row)`
  min-height: 100vh;
  text-align: center;
`

const LoginTemplate: React.FC<LoginTemplateProps> = ({ children }) => {
  return (
    <StyledRow justify="center" align="middle">
      {children}
    </StyledRow>
  )
}

export default LoginTemplate
