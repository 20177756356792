import { Trans } from '@lingui/macro'
import { Select } from 'antd'
import { SelectProps } from 'antd/lib/select'
import { SelectValue } from 'antd/lib/tree-select'
import themePxValueToNumber from 'helpers/Styles.helpers'
import styled from 'styled-components'
import Text from '../text'

const StyledSelect = styled(Select)`
  &&& {
    &.ant-select-single {
      .ant-select-selection-item,
      &.ant-select-show-arrow .ant-select-selection-item {
        font-size: ${(props) => props.theme.sizes.defaultS};
        color: ${(props) => props.theme.colors.grayG06};
        display: flex;
        align-items: center;
      }
      &.ant-select-disabled {
        .ant-select-selection-placeholder,
        .ant-select-arrow {
          color: ${(props) => props.theme.colors.disabled};
        }
      }
    }
    &.ant-select-multiple {
      &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-color: ${(props) => props.theme.colors.primaryP04};
      }
      .ant-select-selector {
        border: none;
        background-color: transparent;
        text-align: left;
        box-shadow: none;
        height: 100%;
        overflow: hidden;
        align-items: flex-start;
        flex-wrap: wrap;
        max-height: ${(props) => themePxValueToNumber(props.theme.sizes.default) * 2}px;
        padding-top: 0;
        .ant-select-selection-item {
          background: transparent;
          border: none;
          cursor: pointer;
          height: ${(props) => props.theme.sizes.default};
          margin-right: 0;
          padding-right: 0;
          &-content {
            margin-right: 3px;

            .ant-checkbox {
              display: none;
              & + span {
                padding: 0;
              }
            }
            .ant-spin {
              display: none;
            }
          }
          &::after {
            content: ';';
            display: inline-block;
          }
          .ant-select-selection-item-remove {
            display: none;
          }
        }
      }
      &.ant-select-disabled {
        .ant-select-selector {
          .ant-select-selection-item {
            cursor: not-allowed;
          }
        }
        .ant-select-selection-item {
          &::after {
            color: #abadba;
          }
          &-content {
            color: #abadba;
            .ant-typography {
              color: #abadba;
            }
          }
        }
      }
      .ant-select-selection-placeholder {
        font-size: ${(props) => props.theme.sizes.s};
      }
      .ant-select-arrow {
        font-size: ${(props) => props.theme.sizes.default};
        ${(props) => props.theme.mixins.flexCenter}
      }
    }
  }
`

const SelectElement: React.FC<SelectProps<SelectValue>> = ({ children, options, ...props }) => {
  return (
    <StyledSelect
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      notFoundContent={
        <Text color="grayG04" textStyle="regularText-small" center>
          <Trans id="common.select.no_options_text">No options available</Trans>
        </Text>
      }
      options={options}
    >
      {children}
    </StyledSelect>
  )
}

export default SelectElement
