import useQueryParams from 'hooks/query/queryParams'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { verifyPasswordResetToken } from 'redux/password'
import { passwordResetTokenInvalidSelector } from 'redux/password/Password.selectors'
import { useAppDispatch } from 'redux/store'
import { Alert, Button } from 'antd'
import { Trans } from '@lingui/macro'
import ResetPasswordForm from './ResetPasswordForm'

const ResetPassword: React.FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const token = useQueryParams().get('token')

  useEffect(() => {
    if (token) {
      dispatch(verifyPasswordResetToken({ token }))
    }
  }, [token, dispatch])

  const tokenInvalid = useSelector(passwordResetTokenInvalidSelector)

  return tokenInvalid ? (
    <Alert
      message={<Trans id="password_reset.invalid_token_error">Invalid token</Trans>}
      description={<Trans id="password_reset.invalid_token_error_description">Password reset token is invalid</Trans>}
      type="error"
      showIcon
      action={
        <Button size="small" href="/password-reset">
          <Trans id="password_reset.generate_new_token_button_text">Create a new token</Trans>
        </Button>
      }
    />
  ) : (
    <ResetPasswordForm />
  )
}

export default ResetPassword
