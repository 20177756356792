import { generateStyledTableWithType } from 'helpers/Styles.helpers'
import { useSelector } from 'react-redux'
import { selectProjectStages } from 'redux/project/project-config/stages/ProjectStagesConfig.selectors'
import { css } from 'styled-components'
import useStagesOrderColumns from './useStagesOrderColumns'

const tableMixin = css`
  & table {
    width: auto;
  }
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    border-bottom: none;
    padding: ${({ theme }) => theme.sizes.xs} ${({ theme }) => theme.sizes.xxs};
  }
  &&& th {
    font-size: ${({ theme }) => theme.sizes.s};
    color: ${({ theme }) => theme.colors.grayG06};
    padding: ${({ theme }) => theme.sizes.xxs};
    background-color: transparent;
  }
`

const StyledTable = generateStyledTableWithType(tableMixin)

const StagesOrderTable: React.FC = () => {
  const columns = useStagesOrderColumns()
  const stages = useSelector(selectProjectStages)?.stages
  if (!stages || stages.length === 0) return null
  return <StyledTable rowKey="id" columns={columns} dataSource={stages} pagination={false} />
}

export default StagesOrderTable
