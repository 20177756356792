import { useSelector } from 'react-redux'
import {
  selectCreateProjectLoading,
  selectProjectDisplayedInModal,
  selectUpdateProjectLoading,
} from 'redux/administration/administration-projects/AdministrationProjects.selectors'
import { MessageErrorAlert } from 'components/atoms/error-alert'
import { handleApiErrors } from 'helpers/HttpError.helpers'
import { defineMessage, t } from '@lingui/macro'
import { invalidCodeErrorMessage } from 'data/messages/misc'

const errorMessageDict = {
  'project.already_exist': defineMessage({
    id: 'administration.projects.name_already_exists_error',
    message: 'Project with given name already exists.',
  }),
  'project.invalid_code_length': defineMessage({
    id: 'administration.projects.invalid_code_length_error',
    message: "Invaid project's code length.",
  }),
  'project.code_already_exists': defineMessage({
    id: 'administration.projects.code_already_exists_error',
    message: 'Project with given code already exists.',
  }),
  'project.location_too_long': defineMessage({
    id: 'administration.projects.location_too_long_error',
    message: 'Location must not have more than 256 characters',
  }),
  'codes.code_invalid': invalidCodeErrorMessage,
}

const ProjectModalError: React.FC = () => {
  const project = useSelector(selectProjectDisplayedInModal)
  const { error: updateError } = useSelector(selectUpdateProjectLoading)
  const { error: addError } = useSelector(selectCreateProjectLoading)
  if (project === undefined) {
    return addError ? (
      <MessageErrorAlert
        message={t({ id: 'administration.projects.modal.project_adding_failed', message: 'Could not add project.' })}
        description={handleApiErrors(errorMessageDict, addError.errors)}
      />
    ) : null
  }
  return updateError ? (
    <MessageErrorAlert
      message={t({ id: 'administration.projects.modal.project_editing_failed', message: 'Could not update project.' })}
      description={handleApiErrors(errorMessageDict, updateError.errors)}
    />
  ) : null
}

export default ProjectModalError
