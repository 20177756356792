import styled from 'styled-components'
import { CloseOutlined } from '@ant-design/icons'
import { WithDividerElement } from 'components/atoms/divider'
import Icon from 'components/atoms/icon'
import Text from 'components/atoms/text'
import Button from 'components/molecules/button-element'
import Modal from 'components/molecules/modal/Modal'
import { Trans } from '@lingui/macro'
import trans from 'helpers/i18n.helpers'
import { cancelText, saveText } from 'data/messages/controls'
import { useSelector } from 'react-redux'
import {
  selectIsFlowReviewModalVisible,
  selectFetchingReviewDetailsLoading,
  selectReviewDecision,
  selectReviewedFile,
} from 'redux/files/flows/Flows.selectors'
import { useAppDispatch } from 'redux/store'
import { setFlowReviewModalVisibility, reviewFlow, getFileCurrentFlowReviewDetails } from 'redux/files/flows'
import { useEffect } from 'react'
import FlowDetailsPanel from './content/FlowDetailsPanel'
import FlowReviewDecisionPanel from './content/FlowReviewDecisionPanel'
import FlowReviewModalAlerts from './FlowReviewModalAlerts'

const StyledModal = styled(Modal)`
  .ant-modal-content {
    min-height: 300px;
    min-width: 800px;
  }
`

const StyledDiv = styled.div`
  margin: ${(props) => props.theme.sizes.defaultM};
`

const FlowExecModal: React.FC = () => {
  const dispatch = useAppDispatch()
  const file = useSelector(selectReviewedFile)
  const decision = useSelector(selectReviewDecision)
  const { isLoading } = useSelector(selectFetchingReviewDetailsLoading)
  const isVisible = useSelector(selectIsFlowReviewModalVisible)

  useEffect(() => {
    if (file && file.fileId) {
      dispatch(getFileCurrentFlowReviewDetails({ fileId: file.fileId }))
    }
  }, [file, dispatch])

  if (!isVisible || file === undefined) {
    return null
  }
  const { fileId } = file
  const handleCancel = () => dispatch(setFlowReviewModalVisibility({ isVisible: false }))
  const handleSubmit = () => fileId && !!decision && dispatch(reviewFlow({ fileId, decision }))
  const isValid = !!decision
  return (
    <StyledModal
      title={
        <Text textStyle="header-H2b">
          <Trans id="project.flows.modal.review">Change file status</Trans>
        </Text>
      }
      closeIcon={
        <Icon>
          <CloseOutlined />
        </Icon>
      }
      maskClosable={false}
      footer={
        <>
          <Button onClick={handleCancel} type="tertiary">
            {trans(cancelText)}
          </Button>
          <Button loading={isLoading} onClick={isValid ? handleSubmit : undefined} disabled={!isValid}>
            {trans(saveText)}
          </Button>
        </>
      }
      onCancel={handleCancel}
      visible
    >
      <StyledDiv>
        <WithDividerElement height="350px">
          <FlowDetailsPanel />
          <FlowReviewDecisionPanel />
        </WithDividerElement>
        <FlowReviewModalAlerts />
      </StyledDiv>
    </StyledModal>
  )
}

export default FlowExecModal
