import { OrganizationDetails } from 'models/administration'
import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'

export const selectAdministrationOrganizations: (state: RootState) => OrganizationDetails[] = (state) =>
  state.administration.organizations.organizations

export const selectFetchingOrganizationsLoading: (state: RootState) => BackendLoading = (state) =>
  state.administration.organizations.loading.fetchOrganizations

export const selectUpdatingOrganizationLoading: (state: RootState) => BackendLoading = (state) =>
  state.administration.organizations.loading.updateOrganization

export const selectAddingOrganizationLoading: (state: RootState) => BackendLoading = (state) =>
  state.administration.organizations.loading.addOrganization

export const selectRemovingOrganizationLoading: (state: RootState) => BackendLoading = (state) =>
  state.administration.organizations.loading.removeOrganization

export const selectIsOrganizationModalOpen: (state: RootState) => boolean = (state) =>
  state.administration.organizations.modalState.isModalVisible

export const selectOrganizationDisplayedInModal: (state: RootState) => OrganizationDetails | undefined = (state) =>
  state.administration.organizations.modalState.organization
