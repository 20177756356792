import { Trans } from '@lingui/macro'
import { useDispatch } from 'react-redux'
import { openProjectStageModal } from 'redux/administration/administration-project-stages'
import AdministrationToolbarButton from '../toolbar'

const AdministrationProjectStagesToolbar: React.FC = () => {
  const dispatch = useDispatch()
  return (
    <AdministrationToolbarButton
      text={
        <Trans id="administration.project_stages.create_new_project_stage_button_text">Create new project stage</Trans>
      }
      onClick={() => dispatch(openProjectStageModal())}
    />
  )
}

export default AdministrationProjectStagesToolbar
