import { Organization } from 'models/administration'
import { FlowConditionsConfig } from 'models/flow'
import type { BackendLoading } from 'redux/redux.shared'

export const loadingType = ['getFlowConditions', 'getOrganizations', 'updateFlowConditions'] as const
export type LoadingType = typeof loadingType[number]

export interface FlowConditionsConfigState {
  loading: Record<LoadingType, BackendLoading>
  flowConditionsConfig?: FlowConditionsConfig
  organizations: Organization[]
}
