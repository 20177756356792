import SelectElement from 'components/atoms/select-element'
import { Select } from 'antd'
import CheckboxElement from 'components/molecules/checkbox'
import trans from 'helpers/i18n.helpers'
import { selectText } from 'data/messages/controls'
import { FlowAttributeValueConfig } from 'models/flow'
import { SelectValue } from 'antd/lib/select'
import { setAllValuesSelected, toggleValueSelected } from 'redux/flow-conifg/attributes/FlowAttributesConfig.slice'
import { useDispatch } from 'react-redux'
import { t, Trans } from '@lingui/macro'
import Text from 'components/atoms/text'
import TooltipElement from 'components/molecules/tooltip-element'
import theme from 'styles/Styles.theme'

const { Option } = Select

interface SelectAttributesValuesProps {
  values: FlowAttributeValueConfig[]
  attributeId?: number
}

interface DropdownWithSelectAllProps {
  setSelected: (selected: boolean) => void
  indeterminate: boolean
  checked: boolean
}

const DropdownWithSelectAll: React.FC<DropdownWithSelectAllProps> = ({
  children,
  setSelected,
  checked,
  indeterminate,
}) => {
  return (
    <div>
      <div style={{ marginLeft: theme.sizes.s }}>
        <CheckboxElement indeterminate={indeterminate} checked={checked} onChange={() => setSelected(!checked)}>
          <Text textStyle="regularText-smallSemiBold">
            <Trans id="flow_config.attributes.select_all">Select all</Trans>
          </Text>
        </CheckboxElement>
      </div>
      {children}
    </div>
  )
}

const SelectAttributeValues: React.FC<SelectAttributesValuesProps> = ({ values, attributeId }) => {
  const dispatch = useDispatch()
  const options = values?.map(({ value, id, isSelected }) => ({
    name: value,
    value: id,
    checked: isSelected,
  }))
  const selectEnabled = attributeId !== undefined
  const allValuesSelected = values.filter(({ isSelected }) => !isSelected).length === 0
  const anyValuesSelected = values.filter(({ isSelected }) => isSelected).length > 0
  const onToggle = (val: SelectValue) => {
    if (attributeId !== undefined) {
      dispatch(toggleValueSelected({ attributeId, valueId: Number(val) }))
    }
  }
  return (
    <TooltipElement
      disabled={selectEnabled}
      title={t({ id: 'flow_config.attributes.select_attribute_before_value_text', message: 'Select attribute first' })}
    >
      <SelectElement
        showSearch={false}
        disabled={!selectEnabled}
        mode="multiple"
        placeholder={trans(selectText)}
        showArrow
        onSelect={onToggle}
        onDeselect={onToggle}
        value={options.filter(({ checked }) => checked).map(({ value }) => value)}
        dropdownRender={(originNode) => (
          <DropdownWithSelectAll
            checked={allValuesSelected}
            indeterminate={anyValuesSelected && !allValuesSelected}
            setSelected={(selected) => {
              if (attributeId) {
                dispatch(setAllValuesSelected({ attributeId, selected }))
              }
            }}
          >
            {originNode}
          </DropdownWithSelectAll>
        )}
      >
        {options?.map((option) => (
          <Option key={option.value} value={option.value} label={option.value}>
            <CheckboxElement noEvents checked={option.checked}>
              {option.name}
            </CheckboxElement>
          </Option>
        ))}
      </SelectElement>
    </TooltipElement>
  )
}

export default SelectAttributeValues
