import { defineMessage, t } from '@lingui/macro'
import ErrorAlert from 'components/atoms/error-alert'
import { handleApiErrors } from 'helpers/HttpError.helpers'
import { useSelector } from 'react-redux'
import {
  selectFetchingOrganizationsLoading,
  selectRemovingOrganizationLoading,
} from 'redux/administration/administration-organizations/AdministrationOrganizations.selectors'

const organizationErrorDict = {
  'organization.is_used': defineMessage({
    id: 'administration.organizations.organization_is_used_error',
    message: 'Organization is used',
  }),
}

const AdministrationOrganizationsAlerts: React.FC = () => {
  const fetchingOrganizationsLoading = useSelector(selectFetchingOrganizationsLoading)
  const removingOrganizationLoading = useSelector(selectRemovingOrganizationLoading)
  const alertMessageDict = [
    {
      error: fetchingOrganizationsLoading.error,
      message: t({ id: 'administration.organizations.fetch_failed', message: 'Could not retrieve organizations' }),
    },
    {
      error: removingOrganizationLoading.error,
      message: handleApiErrors(organizationErrorDict, removingOrganizationLoading.error?.errors),
    },
  ]
  return <ErrorAlert alertMessageDict={alertMessageDict} />
}

export default AdministrationOrganizationsAlerts
