import { t } from '@lingui/macro'
import { unwrapResult } from '@reduxjs/toolkit'
import { Modal } from 'antd'
import { confirmText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import { openSuccessNotification } from 'helpers/Notifications.helpers'
import { useSelector } from 'react-redux'
import {
  selectConfigCompletionType,
  selectIsActivationTimeSelectedIfNecessary,
  selectIsProjectActivationConfigContentLoading,
  selectIsProjectActivationScheduled,
} from 'redux/project/project-config/activation/ProjectActivationConfig.selectors'
import {
  removeScheduledProjectActivation,
  scheduleProjectActivation,
  activateProject,
} from 'redux/project/project-config/activation/ProjectActivationConfig.slice'
import { useAppDispatch } from 'redux/store'
import BaseProjectConfigView from '../base-config-view/BaseProjectConfigView'
import ProjectActivationConfigContent from './content'

const getConfirm: ({ text, onOk }: { text: string; onOk: () => void }) => () => void = ({ text, onOk }) => {
  return () => {
    Modal.confirm({
      title: t({ id: 'project_config.activation.confirm.title', message: 'Finish configuration' }),
      content: text,
      cancelText: t({ id: 'project_config.activation.confirm.cancel_text', message: 'Return to configuration' }),
      okText: trans(confirmText),
      onOk,
    })
  }
}

const ProjectActivationConfig: React.FC = () => {
  const configCompletionType = useSelector(selectConfigCompletionType)
  const isActivationScheduled = useSelector(selectIsProjectActivationScheduled)
  const isActivationContentValid = useSelector(selectIsActivationTimeSelectedIfNecessary)
  const isContentLoading = useSelector(selectIsProjectActivationConfigContentLoading)
  const dispatch = useAppDispatch()
  const handleComplete = {
    saveProgressWithoutRunningProject: (onProjectConfigLeave: () => void) =>
      getConfirm({
        text: t({
          id: 'project_config.activation.run.without_run.confirm.text',
          message:
            'The project will not start. The changes made so far will be saved. You can go back to your project configuration at any time.',
        }),
        onOk: () => {
          const notificationText = t({
            id: 'project_config.activation.run.without_run.notification.description',
            message: 'The changes are saved. The project will not be activated.',
          })
          if (isActivationScheduled) {
            dispatch(removeScheduledProjectActivation())
              .then(unwrapResult)
              .then(() => {
                onProjectConfigLeave()
                openSuccessNotification({ description: notificationText })
              })
          } else {
            onProjectConfigLeave()
            openSuccessNotification({ description: notificationText })
          }
        },
      }),
    runProjectImmediately: (onProjectConfigLeave: () => void) =>
      getConfirm({
        text: t({
          id: 'project_config.activation.run.now.confirmation.text',
          message:
            'From the moment the project is activated, it will be available to project members. Information about the project and the ability to modify the settings after starting the project will be available in Project Settings.',
        }),
        onOk: () => {
          dispatch(activateProject())
            .then(unwrapResult)
            .then(() => {
              onProjectConfigLeave()
              openSuccessNotification({
                description: t({
                  id: 'project_config.activation.run.now.notification.description',
                  message: 'Project is activated',
                }),
              })
            })
        },
      }),
    runAtCustomTime: (onProjectConfigLeave: () => void) =>
      getConfirm({
        text: t({
          id: 'project_config.activation.run.custom.confirmation.text',
          message:
            'The project will be activated at the selected time. You can edit the project configuration until it starts.',
        }),
        onOk: () => {
          dispatch(scheduleProjectActivation())
            .then(unwrapResult)
            .then(() => {
              onProjectConfigLeave()
              openSuccessNotification({
                description: t({
                  id: 'project_config.activation.run.custom.notification.description',
                  message: 'Activation is schedulded',
                }),
              })
            })
        },
      }),
  }[configCompletionType]
  return (
    <BaseProjectConfigView
      content={<ProjectActivationConfigContent />}
      handleCompleteProjectConfig={handleComplete}
      isContentValid={isActivationContentValid}
      isContentLoading={isContentLoading}
    />
  )
}

export default ProjectActivationConfig
