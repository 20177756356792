import { TableProps } from 'antd/lib/table'
import styled, { FlattenInterpolation, StyledComponent, ThemeProps } from 'styled-components'
import { Table } from 'antd'

export default function themePxValueToNumber(cssVariableWithPx: string): number {
  const cssVarName = cssVariableWithPx.replace('var(', '').replace(')', '')
  const cssVarValue = getComputedStyle(document.body).getPropertyValue(cssVarName)
  return Number(cssVarValue.replace('px', ''))
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function generateStyledTableWithType<T extends Record<string, any>>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tableStyles?: FlattenInterpolation<ThemeProps<any>>
): StyledComponent<
  React.FC<TableProps<T>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  Record<string, unknown>,
  never
> {
  // eslint-disable-next-line react/jsx-props-no-spreading
  const TableWithType: React.FC<TableProps<T>> = (props) => <Table<T> {...props} />
  return styled(TableWithType)`
    ${tableStyles}
  `
}
