import { t, Trans } from '@lingui/macro'
import FormInput from 'components/molecules/form-item/FormInput'
import {
  MAX_EMAIL_LENGTH,
  ORGANIZATION_CONTACT_NAME_LENGTH,
  ORGANIZATION_CONTACT_POSITION_LENGTH,
} from 'data/model_restrictions'
import { maxStringLength, nameInputRules, required, requiredTyped } from 'helpers/Validators.helpers'
import { GetFormItemErrors } from 'hooks/useFormValidity'
import OrganizationModalSectionTemplate from './OrganizationModalSectionTemplate'

const isPhoneNumber = (n: string) => /^\+?[\d]{9,15}$/.test(n)
const samplePhoneNumber = '+489998887'
const maxPhoneNumberLength = 15

interface ContactModalSectionProps {
  getFormItemErrors: GetFormItemErrors
}

const ContactModalSection: React.FC<ContactModalSectionProps> = ({ getFormItemErrors }) => {
  return (
    <OrganizationModalSectionTemplate
      headerText={t({
        id: 'administration.organizations.modal.contact_section_header',
        message: 'Contact details',
      })}
    >
      <FormInput
        label={<Trans id="administration.organizations.modal.contact_name_field_label">Name</Trans>}
        name="contactFirstName"
        placeholder={t({
          id: 'administration.organizations.modal.contact_name_field_placeholder',
          message: 'Enter name',
        })}
        rules={nameInputRules(ORGANIZATION_CONTACT_NAME_LENGTH)}
        getErrors={getFormItemErrors}
      />
      <FormInput
        label={<Trans id="administration.organizations.modal.contact_surname_field_label">Surname</Trans>}
        name="contactLastName"
        placeholder={t({
          id: 'administration.organizations.modal.contact_surname_field_placeholder',
          message: 'Enter surname',
        })}
        rules={nameInputRules(ORGANIZATION_CONTACT_NAME_LENGTH)}
        getErrors={getFormItemErrors}
      />
      <FormInput
        label={<Trans id="administration.organizations.modal.contact_position_field_label">Position</Trans>}
        name="contactPosition"
        placeholder={t({
          id: 'administration.organizations.modal.contact_position_field_placeholder',
          message: 'Enter postion',
        })}
        rules={nameInputRules(ORGANIZATION_CONTACT_POSITION_LENGTH)}
        getErrors={getFormItemErrors}
      />
      <FormInput
        label={<Trans id="administration.organizations.modal.contact_email_field_label">Email</Trans>}
        name="contactEmail"
        placeholder={t({
          id: 'administration.organizations.modal.contact_email_field_placeholder',
          message: 'Enter email',
        })}
        validateFirst
        rules={[
          required(),
          maxStringLength(MAX_EMAIL_LENGTH),
          requiredTyped(
            'email',
            t({
              id: 'administration.organizations.modal.contact_email_field_error',
              message: 'Invalid email address',
            })
          ),
        ]}
        getErrors={getFormItemErrors}
      />
      <FormInput
        name="contactPhoneNumber"
        label={<Trans id="administration.organizations.modal.contact_phone_number_field_label">Phone number</Trans>}
        placeholder={t({
          id: 'administration.organizations.modal.contact_phone_number_field_placeholder',
          message: 'Enter phone number',
        })}
        validateFirst
        rules={[
          required(),
          () => ({
            validator(_, value) {
              if (isPhoneNumber(value) || value === undefined) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error(
                  t({
                    id: 'administration.organizations.modal.contact_phone_number_error',
                    message: `Required format: ${samplePhoneNumber} (max ${maxPhoneNumberLength} digits)`,
                  })
                )
              )
            },
          }),
        ]}
        getErrors={getFormItemErrors}
      />
    </OrganizationModalSectionTemplate>
  )
}

export default ContactModalSection
