import { CloseOutlined } from '@ant-design/icons'
import { Row } from 'antd'
import Icon from 'components/atoms/icon'
import Text from 'components/atoms/text'
import { notificationsText } from 'data/messages/misc'
import trans from 'helpers/i18n.helpers'
import { hideNotificationsPopover } from 'redux/notifications'
import { useAppDispatch } from 'redux/store'

const NotificationsPopoverTitle: React.FC = () => {
  const dispatch = useAppDispatch()
  return (
    <Row justify="space-between" align="middle">
      <Text textStyle="regularText-smallSemiBold">{trans(notificationsText)}</Text>
      <Icon size="default" onClick={() => dispatch(hideNotificationsPopover())}>
        <CloseOutlined />
      </Icon>
    </Row>
  )
}

export default NotificationsPopoverTitle
