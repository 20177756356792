import { t } from '@lingui/macro'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import { removedErrorsFromLoading } from 'helpers/HttpError.helpers'
import { openSuccessNotification } from 'helpers/Notifications.helpers'
import { Project } from 'models/administration'
import { ActiveUser } from 'models/users'
import {
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
} from 'redux/redux.shared'
import { loadingTypes, LoadingTypes, AdministrationProjectsState } from './AdministrationProjects.types'

interface UpdateProjectArgs {
  projectId: number
  projectData: { code?: string; admins?: number[] }
}

type UpdatedProjectData = Pick<Project, 'code' | 'admins'>

interface DeleteProjectArgs {
  projectId: number
}

export const fetchProjects = createAsyncBackendThunk('fetchProjects', async () => {
  return (await backendAxios.get('/projects/all/')).data
})

export const createProject = createAsyncBackendThunk(
  'createProject',
  async (payload: { name: string; admins: number[]; location: string; code: string }) => {
    const response = await backendAxios.post(`/projects/`, payload)
    openSuccessNotification({
      message: t({
        id: 'administration.projects.notifications.added_text',
        message: 'Project has been added',
      }),
    })
    return response.data
  }
)

export const updateProject = createAsyncBackendThunk(
  'updateProject',
  async ({ projectId, projectData }: UpdateProjectArgs) => {
    let data
    if (projectData.code || projectData.admins) {
      data = (await backendAxios.post(`/projects/${projectId}/edit/`, projectData)).data
    }
    openSuccessNotification({
      message: t({
        id: 'administration.projects.notifications.updated_text',
        message: 'Project has been updated',
      }),
    })
    return data
  }
)

export const deleteProject = createAsyncBackendThunk('deleteProject', async ({ projectId }: DeleteProjectArgs) => {
  await backendAxios.delete(`/projects/${projectId}/`)
  openSuccessNotification({
    message: t({
      id: 'administration.projects.notifications.deleted_text',
      message: 'Project has been deleted',
    }),
  })
})

export const fetchActiveUsers = createAsyncBackendThunk('fetchActiveUsers', async () => {
  return (await backendAxios.get('/users/active/')).data
})

const initialState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  projects: [],
  users: [],
  modalState: { isModalVisible: false, project: undefined },
} as AdministrationProjectsState

const administrationProjectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    openProjectModal: (state, action: PayloadAction<Project | undefined>) => {
      state.modalState = { isModalVisible: true, project: action.payload }
    },
    closeProjectModal: (state) => {
      state.modalState = initialState.modalState
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<AdministrationProjectsState, LoadingTypes, Project[]>({
      promise: fetchProjects,
      loadingType: 'fetchProjects',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        state.projects = action.payload
      },
    }),
    generateExtraBackendReducers<AdministrationProjectsState, LoadingTypes, ActiveUser[]>({
      promise: fetchActiveUsers,
      loadingType: 'fetchActiveUsers',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        state.users = action.payload
      },
    }),
    generateExtraBackendReducers<AdministrationProjectsState, LoadingTypes, Project>({
      promise: createProject,
      loadingType: 'createProject',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        state.modalState = initialState.modalState
        state.projects = [...state.projects, action.payload]
      },
    }),
    generateExtraBackendReducers<
      AdministrationProjectsState,
      LoadingTypes,
      UpdatedProjectData | undefined,
      UpdateProjectArgs
    >({
      promise: updateProject,
      loadingType: 'updateProject',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        state.modalState = initialState.modalState
        if (action.payload) {
          state.projects = state.projects.map((project) => {
            if (project.id !== action.meta.arg.projectId) {
              return project
            }
            return { ...project, ...action.payload }
          })
        }
      },
    }),
    generateExtraBackendReducers<AdministrationProjectsState, LoadingTypes, void, DeleteProjectArgs>({
      promise: deleteProject,
      loadingType: 'deleteProject',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        const deletedId = action.meta.arg.projectId
        state.projects = state.projects.filter((proj) => proj.id !== deletedId)
      },
    }),
  ]),
})

export const { openProjectModal, closeProjectModal } = administrationProjectsSlice.actions

export const administrationProjectsReducer = administrationProjectsSlice.reducer
