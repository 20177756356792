import { useEffect } from 'react'
import AdministrationContent from 'components/organisms/administration/administration-content'
import AdministrationMenu from 'components/organisms/administration/administration-menu'
import MainNavBar from 'components/organisms/main-navbar'
import AdministrationTemplate from 'components/templates/administration'
import WithHeaderTemplate from 'components/templates/with-header'
import { useDispatch } from 'react-redux'
import { clearAdministrationState } from 'redux/administration'

const AdministrationPage: React.FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    return () => {
      dispatch(clearAdministrationState())
    }
  }, [dispatch])
  return (
    <WithHeaderTemplate
      header={<MainNavBar />}
      content={<AdministrationTemplate left={<AdministrationMenu />} right={<AdministrationContent />} />}
    />
  )
}

export default AdministrationPage
