import { Trans } from '@lingui/macro'
import trans from 'helpers/i18n.helpers'
import { Form, Space } from 'antd'
import BaseFormItem from 'components/molecules/form-item/BaseFormItem'
import FormInput from 'components/molecules/form-item/FormInput'
import FormTextArea from 'components/molecules/form-item/FormTextArea'
import YesNoRadioGroup from 'components/molecules/radio/YesNoRadioGroup'
import { MAX_COMMAND_LENGTH, MAX_DESCRIPTION_LENGTH, MAX_NAME_LENGTH } from 'data/flow/generalConfigValidationData'
import { maxStringLength, required } from 'helpers/Validators.helpers'
import useFormValidity from 'hooks/useFormValidity'
import { FlowConfig } from 'models/flow'
import { FieldData } from 'models/components'
import { setGeneralFlowValues, setPrevFlowsTableVisibility } from 'redux/flow-conifg/general/FlowGeneralConfig.slice'
import styled from 'styled-components'
import { AppDispatch, useAppDispatch } from 'redux/store'
import {
  commandMaxLengthText,
  commandPlaceholderText,
  commandRequiredText,
  descriptionMaxLengthText,
  descriptionPlaceholderText,
  descriptionRequiredText,
  nameMaxLenghtText,
  namePlaceholderText,
  nameRequiredText,
} from './messages'

interface PrevFlowsTableVisibilityHandler {
  isPrevFlowsTableVisible: boolean
}

interface FlowGeneralConfigFormProps extends PrevFlowsTableVisibilityHandler {
  flow: FlowConfig
  isEmptyFlowList: boolean
}

interface PrevFlowsRadiosProps extends PrevFlowsTableVisibilityHandler {
  isEmptyFlowList: boolean
  dispatch: AppDispatch
}

const PrevFlowsRadios: React.FC<PrevFlowsRadiosProps> = ({ isPrevFlowsTableVisible, isEmptyFlowList, dispatch }) => {
  return (
    <BaseFormItem
      label={<Trans id="flow_config.general.prev_flows_radio_label">Should other flows be performed beforehand?</Trans>}
      tooltip={
        <Trans id="flow_config.general.prev_flows_radio_tooltip">
          Running one of the selected flows will be required to make the currently defined flow available for the
          selected file
        </Trans>
      }
    >
      <YesNoRadioGroup
        value={isEmptyFlowList ? false : isPrevFlowsTableVisible}
        onChange={(e) => dispatch(setPrevFlowsTableVisibility(e.target.value))}
        disableYesRadio={isEmptyFlowList}
        disableNoRadio={isEmptyFlowList}
      />
    </BaseFormItem>
  )
}

const StyledSpace = styled(Space)`
  width: 428px;
`
const FlowGeneralConfigForm: React.FC<FlowGeneralConfigFormProps> = ({
  flow,
  isPrevFlowsTableVisible,
  isEmptyFlowList,
}) => {
  const dispatch = useAppDispatch()
  const { form, validate, getFormItemErrors } = useFormValidity()
  const handleChange = (changedFields: FieldData<string | number>[], values: Omit<FlowConfig, 'prevFlows'>) => {
    dispatch(setGeneralFlowValues(values))
  }
  return (
    <Form form={form} layout="vertical" initialValues={flow} onValuesChange={handleChange} onFieldsChange={validate}>
      <StyledSpace direction="vertical" size="large">
        <FormInput
          getErrors={getFormItemErrors}
          name="name"
          label={<Trans id="flow_config.general.input_name_label">Name</Trans>}
          placeholder={trans(namePlaceholderText)}
          rules={[required(trans(nameRequiredText)), maxStringLength(MAX_NAME_LENGTH, trans(nameMaxLenghtText))]}
          tooltip={
            <Trans id="flow_config.general.input_name_tooltip">
              The name can be up to {MAX_NAME_LENGTH} characters long and must be unique within the project
            </Trans>
          }
        />
        <FormTextArea
          getErrors={getFormItemErrors}
          name="description"
          label={<Trans id="flow_config.general.input_description_label">Description</Trans>}
          placeholder={trans(descriptionPlaceholderText)}
          autoSize={{ minRows: 4 }}
          style={{ resize: 'none' }}
          tooltip={
            <Trans id="flow_config.general.input_description_tooltip">
              The description can be up to {MAX_DESCRIPTION_LENGTH} characters long
            </Trans>
          }
          rules={[
            required(trans(descriptionRequiredText)),
            maxStringLength(MAX_DESCRIPTION_LENGTH, trans(descriptionMaxLengthText)),
          ]}
        />
        <FormInput
          getErrors={getFormItemErrors}
          name="command"
          label={<Trans id="flow_config.general.input_command_label">Command</Trans>}
          placeholder={trans(commandPlaceholderText)}
          tooltip={
            <Trans id="flow_config.general.input_command_tooltip">
              The initialization command can be up to {MAX_COMMAND_LENGTH} characters long and does not have to be
              unique in the project
            </Trans>
          }
          rules={[
            required(trans(commandRequiredText)),
            maxStringLength(MAX_COMMAND_LENGTH, trans(commandMaxLengthText)),
          ]}
        />
        <PrevFlowsRadios
          isPrevFlowsTableVisible={isPrevFlowsTableVisible}
          isEmptyFlowList={isEmptyFlowList}
          dispatch={dispatch}
        />
      </StyledSpace>
    </Form>
  )
}

export default FlowGeneralConfigForm
