import { Button } from 'antd'
import styled, { Color, Size } from 'styled-components'
import Text from 'components/atoms/text'
import { TextType } from 'components/atoms/text/text.types'

interface ButtonElementProps {
  type?: ButtonType
  size?: ButtonSize
  children?: React.ReactNode
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLElement>
  loading?: boolean
  isActive?: boolean
  block?: boolean
  htmlType?: 'button' | 'submit'
  icon?: React.ReactNode
}

type ButtonType = 'primary' | 'tertiary' | 'secondary' | 'dashed'
type ButtonSize = 'small' | 'medium' | 'large'

const typeDict: {
  [key: string]: {
    color: Color
    colorHover: Color
    colorFocused: Color
    colorPressed: Color
    colorDisabled: Color
    background: Color
    backgroundHover: Color
    backgroundFocused: Color
    backgroundPressed: Color
    backgroundDisabled: Color
  }
} = {
  primary: {
    color: 'baseWhiteish',
    colorHover: 'baseWhiteish',
    colorFocused: 'baseWhiteish',
    colorPressed: 'baseWhiteish',
    colorDisabled: 'grayG04',
    background: 'primaryP04',
    backgroundHover: 'primaryP03',
    backgroundFocused: 'primaryP03',
    backgroundPressed: 'primaryP05',
    backgroundDisabled: 'grayG01',
  },
  tertiary: {
    color: 'baseBlackish',
    colorHover: 'baseWhiteish',
    colorFocused: 'baseWhiteish',
    colorPressed: 'baseWhiteish',
    colorDisabled: 'grayG04',
    background: 'transparent',
    backgroundHover: 'primaryP01Rgba',
    backgroundFocused: 'primaryP01Rgba',
    backgroundPressed: 'primaryP02Rgba',
    backgroundDisabled: 'transparent',
  },
  secondary: {
    color: 'primaryP04',
    colorHover: 'primaryP03',
    colorFocused: 'primaryP03',
    colorPressed: 'primaryP03',
    colorDisabled: 'primaryP05',
    background: 'transparent',
    backgroundHover: 'transparent',
    backgroundFocused: 'transparent',
    backgroundPressed: 'transparent',
    backgroundDisabled: 'transparent',
  },
  dashed: {
    color: 'baseBlackish',
    colorHover: 'baseWhiteish',
    colorFocused: 'baseWhiteish',
    colorPressed: 'baseWhiteish',
    colorDisabled: 'grayG04',
    background: 'transparent',
    backgroundHover: 'primaryP01Rgba',
    backgroundFocused: 'primaryP01Rgba',
    backgroundPressed: 'primaryP02Rgba',
    backgroundDisabled: 'transparent',
  },
}
const sizeDict: {
  [key: string]: { paddings: { vertical: Size; horizontal: Size }; textStyle: TextType; height: Size }
} = {
  small: {
    paddings: {
      vertical: 'xxs',
      horizontal: 'default',
    },
    textStyle: 'regularText-smallSemiBold',
    height: 'buttonSmall',
  },
  medium: {
    paddings: {
      vertical: 'xs',
      horizontal: 'default',
    },
    textStyle: 'regularText-smallSemiBold',
    height: 'buttonMedium',
  },
  large: {
    paddings: {
      vertical: 'xs',
      horizontal: 'default',
    },
    textStyle: 'header-H2b',
    height: 'buttonLarge',
  },
}

interface StyledButtonProps {
  $type: ButtonType
  $size: ButtonSize
  $isActive: boolean
}

const StyledButton = styled(Button)<StyledButtonProps>`
  &.ant-btn {
    background-color: ${(props) => props.theme.colors[typeDict[props.$type].background]};
    color: ${(props) => props.theme.colors[typeDict[props.$type].color]};
    height: ${(props) => props.theme.sizes[sizeDict[props.$size].height]};
    border: ${(props) =>
      props.$type === 'dashed' ? `dashed 1px ${props.theme.colors[typeDict[props.$type].color]}` : 'none'};
    ${(props) =>
      props.$type === 'secondary' ? `border: 1px solid ${props.theme.colors[typeDict[props.$type].color]};` : ''}
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: ${(props) =>
      `${props.theme.sizes[sizeDict[props.$size].paddings.vertical]} ${
        props.theme.sizes[sizeDict[props.$size].paddings.horizontal]
      }`};
    &:hover {
      background-color: ${(props) => props.theme.colors[typeDict[props.$type].backgroundHover]};
      color: ${(props) => props.theme.colors[typeDict[props.$type].colorHover]};
    }
    &:focus {
      background-color: ${(props) => props.theme.colors[typeDict[props.$type].backgroundFocused]};
      color: ${(props) => props.theme.colors[typeDict[props.$type].colorFocused]};
    }
    &:disabled {
      background-color: ${(props) => props.theme.colors[typeDict[props.$type].backgroundDisabled]};
      color: ${(props) => props.theme.colors[typeDict[props.$type].colorDisabled]};
      ${(props) => (props.$type === 'primary' ? `border: 1px solid ${props.theme.colors.grayG04};` : '')}
    }
    &:active {
      background-color: ${(props) => props.theme.colors[typeDict[props.$type].backgroundPressed]};
      color: ${(props) => props.theme.colors[typeDict[props.$type].colorPressed]};
    }
    ${(props) =>
      props.$isActive &&
      `background-color: ${props.theme.colors.actionSelect};
      border: 1px solid ${props.theme.colors.primaryP04};
       > * {
         color: ${props.theme.colors.primaryP04};
       }
    `}
  }
`

const ButtonElement: React.FC<ButtonElementProps> = ({
  children,
  type = 'primary',
  size = 'medium',
  disabled,
  onClick,
  loading = false,
  isActive = false,
  block = false,
  htmlType = 'button',
  icon,
}) => {
  return (
    <StyledButton
      $isActive={isActive}
      loading={loading}
      onClick={onClick}
      disabled={disabled}
      $type={type}
      $size={size}
      block={block}
      htmlType={htmlType}
      icon={icon}
    >
      <Text color={disabled ? 'grayG04' : typeDict[type].color} textStyle={sizeDict[size].textStyle}>
        {children}
      </Text>
    </StyledButton>
  )
}

export default ButtonElement
