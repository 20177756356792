import Text from 'components/atoms/text'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectProjectMembers,
  selectProjectMembersOrganizations,
} from 'redux/project/project-config/members/ProjectMembersConfig.selectors'
import styled from 'styled-components'
import { SelectValue } from 'antd/lib/select'
import {
  setSelectedDisciplines,
  setSelectedOrganizations,
  setSelectedUsers,
} from 'redux/project/project-config/members-permissions/ProjectMembersPermissionsConfig.slice'
import {
  selectAvailableDisciplines,
  selectSelectedDisciplines,
  selectSelectedOrganizations,
  selectSelectedUsers,
} from 'redux/project/project-config/members-permissions/ProjectMembersPermissionsConfig.selectors'
import { getOptionsFromNameAndId } from 'helpers/Collection.helpers'
import { getUsersOptions } from 'models/users'
import MultipleSelectWithTag from 'components/atoms/select-element/multiple-with-tag/MultipleSelectWithTag'
import { t } from '@lingui/macro'

const StyledToolBar = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 400px);
  gap: ${(props) => props.theme.sizes.m};
`

const StyledSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.sizes.xxs};
`

const ProjectMembersPermissionsConfigToolBar: React.FC = () => {
  const projectMembersOrganizations = useSelector(selectProjectMembersOrganizations)
  const selectedOrganizations = useSelector(selectSelectedOrganizations)
  const disciplines = useSelector(selectAvailableDisciplines)
  const users = useSelector(selectProjectMembers)
  const selectedDisciplines = useSelector(selectSelectedDisciplines)
  const selectedUsers = useSelector(selectSelectedUsers)
  const dispatch = useDispatch()
  const selectElementsData = [
    {
      label: t({ id: 'project_config.members_permissions.tool_bar.organization', message: 'Organization' }),
      options: getOptionsFromNameAndId(projectMembersOrganizations),
      onChange: (val: SelectValue) => {
        dispatch(setSelectedOrganizations(val as number[]))
      },
      value: selectedOrganizations,
    },
    {
      label: t({ id: 'project_config.members_permissions.tool_bar.user_discipline', message: 'User discipline' }),
      options: getOptionsFromNameAndId(disciplines),
      onChange: (val: SelectValue) => {
        dispatch(setSelectedDisciplines(val as number[]))
      },
      value: selectedDisciplines,
    },
    {
      label: t({ id: 'project_config.members_permissions.tool_bar.user', message: 'User' }),
      options: getUsersOptions(users),
      onChange: (val: SelectValue) => {
        dispatch(setSelectedUsers(val as number[]))
      },
      value: selectedUsers,
    },
  ]
  return (
    <StyledToolBar>
      {selectElementsData.map(({ label, options, onChange, value }) => (
        <StyledSelectContainer key={label}>
          <Text textStyle="regularText-smallSemiBold">{label}</Text>
          <MultipleSelectWithTag
            options={options}
            onChange={onChange}
            value={value}
            optionFilterProp="label"
            filterOption={(input, option) =>
              option?.children?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />
        </StyledSelectContainer>
      ))}
    </StyledToolBar>
  )
}

export default ProjectMembersPermissionsConfigToolBar
