import { NamingSchemeElement } from 'models/administration'
import NameAndId, { ValueFields } from 'models/shared'
import { CdeStatus } from 'models/files'
import { defineMessage } from '@lingui/macro'
import { MessageDescriptor } from '@lingui/core'
import { attributeText, cdeDirectoryText, disciplineText } from 'data/messages/misc'
import { SelectProps } from 'antd/lib/select'

export interface AttributeValue extends ValueFields {
  selected: boolean
  isLoading: boolean
  isProjectValue: boolean
  isUsed: boolean
}

export interface Attribute extends NameAndId {
  selected: boolean
  attributeValues: AttributeValue[]
  projectValuesAllowed: boolean
  valueCodeLength: number
  isUsed: boolean
}

export interface EntryNamingScheme {
  scheme: NamingSchemeElement[]
  namingSchemeSource?: number
}

export type Status = 'IN_PROGRESS' | 'SHARED' | 'PUBLISHED'

export interface MemberOrganization {
  id: number
  name: string
  abbreviation: string
}

export interface Member {
  id: number
  userId: number
  name: string
  email: string
  organization: MemberOrganization
  isAdmin: boolean
  isActive: boolean
  isUserActive: boolean
  canBeRemoved: boolean
  disciplines: number[]
}

const entryValues = ['discipline', 'metadata', 'status', 'initiator', 'stage'] as const
export type EntryValue = typeof entryValues[number]

export const EntryValuesLabelsDict: Record<EntryValue, MessageDescriptor> = {
  discipline: disciplineText,
  status: cdeDirectoryText,
  initiator: defineMessage({ id: 'project_config.levels.names.initiator', message: "Author's organization" }),
  stage: defineMessage({ id: 'project_config.levels.names.stage', message: 'Project stage' }),
  metadata: attributeText,
}

export interface Entry {
  key: EntryValue
  id?: number
}

export type ProjectConfigCompletion = 'saveProgressWithoutRunningProject' | 'runProjectImmediately' | 'runAtCustomTime'

export const ProjectConfigCompletionTypes: Record<string, ProjectConfigCompletion> = {
  RUN_IMMEDIATELY: 'runProjectImmediately',
  RUN_AT_CUSTOM_TIME: 'runAtCustomTime',
  SAVE_WITHOUT_RUNNING: 'saveProgressWithoutRunningProject',
}

export interface ProjectActivationData {
  isScheduled: boolean
  details?: { id: number; executionTimestamp: string }
}

export interface Permission {
  canReadFrom?: CdeStatus
  canWrite: boolean
  canWriteArchive: boolean
  canCheckIn: CdeStatus[]
  canAcceptIn: CdeStatus[]
}

export interface Discipline {
  id: number
  name: string
  code: string
  isUsed: boolean
  isSelected: boolean
}

export const getDisciplinesOptions = (disciplines: Discipline[]): SelectProps<number[]>['options'] =>
  disciplines.map(({ name, id, code }) => ({
    label: `${code} (${name})`,
    value: id,
  }))
