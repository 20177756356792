import { useDispatch, useSelector } from 'react-redux'
import { Modal, Form, Spin, Tag } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import Text from 'components/atoms/text'
import Icon from 'components/atoms/icon'
import FormSelect from 'components/molecules/form-item/FormSelect'
import FormInput from 'components/molecules/form-item/FormInput'

import Button from 'components/molecules/button-element'
import { nameInputRules, required } from 'helpers/Validators.helpers'
import { closeProjectModal, createProject } from 'redux/administration/administration-projects'
import {
  selectCreateProjectLoading,
  selectProjectAdministrationActiveUsers,
  selectProjectDisplayedInModal,
  selectUpdateProjectLoading,
} from 'redux/administration/administration-projects/AdministrationProjects.selectors'
import { Project } from 'models/administration'
import { updateProject } from 'redux/administration/administration-projects/AdministrationProjects.slice'
import { arraysHaveSameElements } from 'helpers/Collection.helpers'
import { SelectProps, SelectValue } from 'antd/lib/select'
import { t, Trans } from '@lingui/macro'
import { cancelText, saveText, selectText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import { selectAdministrationCodeLengthDict } from 'redux/administration/administration-code-settings/AdministrationCodeSettings.selectors'
import CodeFormInput from 'components/molecules/code-length/CodeFormInput'
import styled from 'styled-components'
import { PROJECT_LOCATION_LENGTH } from 'data/model_restrictions'
import useFormValidity from 'hooks/useFormValidity'
import ProjectModalError from './ProjectModalError'

interface InitialFormValue {
  code?: string
  admins?: number[]
}

const getFieldsToUpdate: (currentValues: Project, newValues: InitialFormValue) => InitialFormValue = (
  currentValues,
  newValues
) => {
  const codeChanged = currentValues.code.code !== newValues.code
  const adminsChanged = !arraysHaveSameElements(
    currentValues.admins.map((admin) => admin.id),
    newValues.admins || []
  )
  return {
    code: codeChanged ? newValues.code : undefined,
    admins: adminsChanged ? newValues.admins : undefined,
  }
}

const adminSelectionTag: SelectProps<SelectValue>['tagRender'] = (props) => {
  const { label, closable, onClose } = props
  return (
    <Tag color="blue" closable={closable} onClose={onClose}>
      <Text textStyle="regularText-small" color="baseBlackish">
        {label}
      </Text>
    </Tag>
  )
}

const StyledDiv = styled.div`
  max-width: 90%;
`

// eslint-disable-next-line max-lines-per-function
const ProjectModal: React.FC = () => {
  const { form, isFormValid, validate, getFormItemErrors } = useFormValidity()
  const project = useSelector(selectProjectDisplayedInModal)
  const activeUsers = useSelector(selectProjectAdministrationActiveUsers)
  const currentLengthDict = useSelector(selectAdministrationCodeLengthDict)
  const isActionCreate = project === undefined

  const dispatch = useDispatch()
  const handleCancel = () => dispatch(closeProjectModal())

  const { isLoading: isAddingLoading } = useSelector(selectCreateProjectLoading)
  const { isLoading: isUpdatingLoading } = useSelector(selectUpdateProjectLoading)
  const isLoading = isAddingLoading || isUpdatingLoading

  const modalTitle = isActionCreate ? (
    <Trans id="administration.projects.modal.add_project_header">Create project</Trans>
  ) : (
    <Trans id="administration.projects.modal.edit_project_header">Edit project &quot;{project?.name}&quot;</Trans>
  )

  const handleSubmit = () => {
    if (project === undefined) {
      dispatch(createProject(form.getFieldsValue()))
    } else {
      const fieldsToUpdate = getFieldsToUpdate(project, form.getFieldsValue())
      dispatch(updateProject({ projectId: project.id, projectData: fieldsToUpdate }))
    }
  }
  return (
    <Modal
      visible
      onCancel={handleCancel}
      closeIcon={
        <Icon>
          <CloseOutlined />
        </Icon>
      }
      maskClosable={false}
      title={
        <StyledDiv>
          <Text textStyle="header-H2b">{modalTitle}</Text>
        </StyledDiv>
      }
      footer={
        <>
          <Button onClick={handleCancel} type="tertiary">
            {trans(cancelText)}
          </Button>
          <Button loading={isLoading} onClick={handleSubmit} disabled={!isFormValid}>
            {trans(saveText)}
          </Button>
        </>
      }
    >
      <Spin spinning={isLoading}>
        <Form
          form={form}
          labelCol={{ sm: { span: 6 } }}
          initialValues={project && { code: project.code.code, admins: project.admins.map((admin) => admin.id) }}
          onFieldsChange={validate}
        >
          {isActionCreate && (
            <FormInput
              label={<Trans id="administration.projects.modal.name_field_label">Name</Trans>}
              name="name"
              rules={nameInputRules()}
              placeholder={t({ id: 'administration.projects.modal.name_field_placeholder', message: 'Enter name' })}
              getErrors={getFormItemErrors}
            />
          )}
          <CodeFormInput
            codeLength={currentLengthDict.PROJECT_CODE}
            label={<Trans id="administration.projects.modal.code_field_label">Code</Trans>}
            getErrors={getFormItemErrors}
          />
          <FormSelect
            showSearch
            mode="multiple"
            name="admins"
            tagRender={adminSelectionTag}
            options={activeUsers.map((user) => ({ label: user.email, value: user.id }))}
            label={<Trans id="administration.projects.modal.administrators_field_label">Administrators</Trans>}
            placeholder={trans(selectText)}
            rules={
              project === undefined || project.admins.length > 0
                ? [
                    required(
                      t({
                        id: 'administration.projects.modal.select_at_least_one_admin_error',
                        message: 'Select at least one project admin',
                      })
                    ),
                  ]
                : undefined
            }
            getErrors={getFormItemErrors}
          />
          {isActionCreate && (
            <FormInput
              name="location"
              label={<Trans id="administration.projects.modal.location_field_label">Location</Trans>}
              placeholder={t({
                id: 'administration.projects.modal.location_field_placeholder',
                message: 'Enter location',
              })}
              rules={nameInputRules(PROJECT_LOCATION_LENGTH)}
              getErrors={getFormItemErrors}
            />
          )}
        </Form>
        <ProjectModalError />
      </Spin>
    </Modal>
  )
}

export default ProjectModal
