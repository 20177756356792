import styled from 'styled-components'
import PortfolioItem from 'components/molecules/portfolio/portfolio-item'
import PortfolioProject from 'models/portfolio'

interface PortfolioItemsProps {
  projects: PortfolioProject[]
}

const StyledUl = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${(props) => props.theme.sizes.l} ${(props) => props.theme.sizes.m};
  margin: ${(props) => props.theme.sizes.l} 0;
`

const PortfolioItems: React.FC<PortfolioItemsProps> = ({ projects }) => {
  return (
    <StyledUl>
      {projects.map(({ id, name, sector, contractingAuthority, isEnded, location, isActive, imageAccessToken }) => (
        <PortfolioItem
          key={id}
          id={id}
          name={name}
          sector={sector}
          contractingAuthority={contractingAuthority}
          isEnded={isEnded}
          location={location}
          isActive={isActive}
          imageAccessToken={imageAccessToken}
        />
      ))}
    </StyledUl>
  )
}

export default PortfolioItems
