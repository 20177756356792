import { t } from '@lingui/macro'
import ErrorAlert from 'components/atoms/error-alert'
import { useSelector } from 'react-redux'
import {
  selectGettingFlowDecisionsLoading,
  selectGettingSuitabilityCodesLoading,
  selectUpdatingFlowDecisionsLoading,
} from 'redux/flow-conifg/decisions/FlowDecisionsConfig.selectors'

const FlowGeneralConfigContentAlerts: React.FC = () => {
  const { error: fetchingDecisionError } = useSelector(selectGettingFlowDecisionsLoading)
  const { error: updatingDecisionError } = useSelector(selectUpdatingFlowDecisionsLoading)
  const { error: getSuitabilityCodesError } = useSelector(selectGettingSuitabilityCodesLoading)

  const alertMessageDict = [
    {
      error: fetchingDecisionError,
      message: t({
        id: 'flow_config.decisions.alert.fetching_flows_failed',
        message: 'Could not retrieve decisions.',
      }),
    },
    {
      error: getSuitabilityCodesError,
      message: t({
        id: 'flow_config.decisions.alert.fetching_suitability_codes_failed',
        message: 'Could not retrieve suitability codes.',
      }),
    },
    {
      error: updatingDecisionError,
      message: t({
        id: 'flow_config.decisions.alert.update_flow_failed',
        message: 'Could not update flow.',
      }),
    },
  ]
  return <ErrorAlert alertMessageDict={alertMessageDict} />
}

export default FlowGeneralConfigContentAlerts
