import PortfolioProject from 'models/portfolio'
import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import { ProjectsTypeSelectState } from './PortfolioProjects.slice'

export const selectPortfolioProjects = (state: RootState): PortfolioProject[] | undefined => state.portfolio.projects
export const selectProjectsTypeSelectState = (state: RootState): ProjectsTypeSelectState =>
  state.portfolio.projectsTypeSelectState
export const selectPortfolioSearchBarValue = (state: RootState): string => state.portfolio.searchBarValue
export const selectFetchProjectsLoading = (state: RootState): BackendLoading => state.portfolio.loading.fetchProjects
