import { t, Trans } from '@lingui/macro'
import { ColumnsType } from 'antd/lib/table'
import useTableColumnFiltering, { onFilterStringColumn } from 'components/molecules/table/useTableColumnFiltering'
import { actionsTableColumnText } from 'data/messages/controls'
import { attributeText } from 'data/messages/misc'
import trans from 'helpers/i18n.helpers'
import AttributesActions from './AttributesActions'
import ConfigAttributeRecord from './ProjectAttributesConfig.types'
import SelectAttributesValues from './SelectAttributeValues'

const useAttributesColumns = (): ColumnsType<ConfigAttributeRecord> => {
  return [
    {
      title: trans(attributeText),
      dataIndex: 'name',
      key: 'name',
      width: 250,
      render: (content) => content,
      ...useTableColumnFiltering(
        t({ id: 'project_config.attributes.table.search_attribute_placeholder', message: 'Search for attribute' })
      ),
      onFilter: (value: string | number | boolean, record: ConfigAttributeRecord) =>
        onFilterStringColumn(value, record.nameText),
    },
    {
      title: <Trans id="project_config.attributes.table.colum_values_label">Available values</Trans>,
      dataIndex: 'availableValues',
      key: 'availableValues',
      render: (value, { toggleValue, attributeValues, attrId }) => (
        <SelectAttributesValues
          addAttributeValue={(valId) => toggleValue && toggleValue(valId, true, attrId)}
          removeAttributeValue={(valId) => toggleValue && toggleValue(valId, false, attrId)}
          attributeValues={attributeValues}
          disabled={!toggleValue}
        />
      ),
    },
    {
      title: trans(actionsTableColumnText),
      dataIndex: 'actions',
      key: 'actions',
      width: 50,
      render: (_, { remove, attrId, projectValuesAllowed, isUsed, attributeValues }) => (
        <AttributesActions
          attrId={attrId}
          isUsed={isUsed}
          remove={remove}
          attributeValuesExist={attributeValues.filter((a) => a.isProjectValue).length > 0}
          projectValuesAllowed={projectValuesAllowed}
        />
      ),
    },
  ]
}

export default useAttributesColumns
