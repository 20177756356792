import { ColumnsType } from 'antd/lib/table'
import useTableColumnFiltering, { onFilterStringColumn } from 'components/molecules/table/useTableColumnFiltering'
import { actionsTableColumnText } from 'data/messages/controls'
import { t } from '@lingui/macro'
import trans from 'helpers/i18n.helpers'
import { Discipline } from 'models/project/projectConfig'
import DisciplineActions from './DisciplineActions'

const useDisciplinesColumns = (): ColumnsType<Discipline> => {
  return [
    {
      title: t({ id: 'project_config.disciplines.table.column_code_header', message: 'Code' }),
      dataIndex: 'code',
      key: 'code',
      width: 50,
      render: (value, { code }) => code,
      ...useTableColumnFiltering(
        t({ id: 'project_config.disciplines.table.search_code_placeholder', message: 'Search for code' })
      ),
      onFilter: (value: string | number | boolean, record: Discipline) => onFilterStringColumn(value, record.code),
    },
    {
      title: t({ id: 'project_config.disciplines.table.column_name_header', message: 'Name' }),
      dataIndex: 'discipline',
      key: 'discipline',
      width: 390,
      render: (value, { name }) => name,
      ...useTableColumnFiltering(
        t({ id: 'project_config.disciplines.table.search_name_placeholder', message: 'Search for name' })
      ),
      onFilter: (value: string | number | boolean, record: Discipline) => onFilterStringColumn(value, record.name),
    },
    {
      title: trans(actionsTableColumnText),
      dataIndex: 'actions',
      key: 'actions',
      width: 50,
      render: (value, { id, isUsed, name }) => <DisciplineActions id={id} canDelete={!isUsed} name={name} />,
    },
  ]
}

export default useDisciplinesColumns
