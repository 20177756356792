const ALMOST_UNLIMITED = 65535

export const MAX_CODE_LENGTH = 32
export const MAX_NAME_LENGTH = 512

export const ORGANIZATION_ABBREVIATION_LENGTH = 128
export const ORGANIZATION_ADDRESS_LENGTH = ALMOST_UNLIMITED
export const ORGANIZATION_CONTACT_NAME_LENGTH = 256
export const ORGANIZATION_CONTACT_SURNAME_LENGTH = 256
export const ORGANIZATION_CONTACT_POSITION_LENGTH = 256

export const PROJECT_LOCATION_LENGTH = 256
export const PROJECT_SECTOR_LENGTH = 1024
export const PROJECT_CONTRACTING_AUTHORITY_LENGTH = 256

export const USER_NAME_LENGTH = 150
export const USER_SURNAME_LENGTH = 150

export const MAX_EMAIL_LENGTH = 254
