import { identity } from 'helpers/Functions.helpers'
import { Discipline, Member, Permission } from 'models/project/projectConfig'
import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import { selectProjectMembers, selectProjectMembersFetchingLoading } from '../members/ProjectMembersConfig.selectors'
import { MemberPermission, ProjectMembersPermissionsConfigState } from './ProjectMembersPermissionsConfig.types'

const selectProjectMembersPermissionsConfigState: (state: RootState) => ProjectMembersPermissionsConfigState = (
  state
) => state.project.config.membersPermissions

export const selectProjectMembersPermissions: (state: RootState) => MemberPermission[] = (state) =>
  selectProjectMembersPermissionsConfigState(state).membersPermissions

export const selectAreProjectMembersPermissionsFetched: (state: RootState) => boolean = (state) =>
  selectProjectMembersPermissionsConfigState(state).permissionsFetched

export const selectSelectedOrganizations: (state: RootState) => number[] = (state) =>
  selectProjectMembersPermissionsConfigState(state).selectedOrganizations

export const selectSelectedDisciplines: (state: RootState) => number[] = (state) =>
  selectProjectMembersPermissionsConfigState(state).selectedDisciplines

export const selectSelectedUsers: (state: RootState) => number[] = (state) =>
  selectProjectMembersPermissionsConfigState(state).selectedUsers

export const selectChangedUsers: (state: RootState) => number[] = (state) =>
  selectProjectMembersPermissionsConfigState(state).changedUsers

export const selectCheckedUsers: (state: RootState) => number[] = (state) =>
  selectProjectMembersPermissionsConfigState(state).checkedUsers

const filterIdByIds = (id: number, ids: number[]) => (ids.length === 0 ? true : !!ids.find((i) => i === id))

export const selectAvailableDisciplines: (state: RootState) => Discipline[] = (state) =>
  selectProjectMembersPermissionsConfigState(state).availableDisciplines

export const selectFilteredUsers: (state: RootState) => Member[] = (state) => {
  const users = selectProjectMembers(state)
  const selectedDisciplines = selectSelectedDisciplines(state)
  const selectedUsers = selectSelectedUsers(state)
  const selectedOrganizations = selectSelectedOrganizations(state)
  return (
    users?.filter(({ organization, id, disciplines }) => {
      return (
        filterIdByIds(organization.id, selectedOrganizations) &&
        filterIdByIds(id, selectedUsers) &&
        (selectedDisciplines.length === 0
          ? true
          : disciplines.find((discipline) => selectedDisciplines.includes(discipline)))
      )
    }) || []
  )
}

export const selectUsersPermissionsByDiscipline = (discipline: number) => (state: RootState): Permission[] => {
  const checkedUsers = selectCheckedUsers(state)
  const membersPermissions = selectProjectMembersPermissions(state)
  return membersPermissions
    .filter(({ user }) => checkedUsers.find((userId) => userId === user))
    .map(({ permissions }) => permissions.find((permission) => permission.discipline === discipline)?.permissions)
    .filter(identity) as Permission[]
}

export const selectFetchingMembersPermissionsLoading: (state: RootState) => BackendLoading = (state) =>
  selectProjectMembersPermissionsConfigState(state).loading.fetchMembersPermissions

export const selectUpdatingMembersPermissionsLoading: (state: RootState) => BackendLoading = (state) =>
  selectProjectMembersPermissionsConfigState(state).loading.updateMembersPermissions

export const selectFetchingAvailableDisciplinesLoading: (state: RootState) => BackendLoading = (state) =>
  selectProjectMembersPermissionsConfigState(state).loading.fetchAvailableDisciplines

export const selectIsProjectMembersPermissionsConfigContentLoading: (state: RootState) => boolean = (state) =>
  selectFetchingMembersPermissionsLoading(state).isLoading ||
  selectUpdatingMembersPermissionsLoading(state).isLoading ||
  selectProjectMembersFetchingLoading(state).isLoading ||
  selectFetchingAvailableDisciplinesLoading(state).isLoading

export const selectSortedDisciplines: (state: RootState) => Discipline[] = (state) => {
  const disciplines = selectAvailableDisciplines(state)
  const selectedDisciplines = selectSelectedDisciplines(state)
  const filteredDisciplines = disciplines.filter(({ id }) => !selectedDisciplines.find((disc) => disc === id))
  const selectedDisciplinesWithData = selectedDisciplines
    .map((discipline) => disciplines.find(({ id }) => id === discipline))
    .filter(identity) as Discipline[]
  return [...selectedDisciplinesWithData, ...filteredDisciplines]
}
