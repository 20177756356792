import { Entry } from 'models/project/projectConfig'
import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import { selectFetchAvailableMetadataLoading } from '../naming-scheme/ProjectNamingSchemeConfig.selectors'
import { ProjectEntriesStructureConfigState } from './ProjectEntriesStructureConfig.types'

const selectEntriesStructureState: (state: RootState) => ProjectEntriesStructureConfigState = (state) =>
  state.project.config.entriesStructure

export const selectEntriesStructure: (state: RootState) => Entry[] = (state) =>
  selectEntriesStructureState(state).entriesStructure

export const selectIsAddingEntry: (state: RootState) => boolean = (state) =>
  selectEntriesStructureState(state).isAddingEntry

export const selectGetEntriesStructureLoading: (state: RootState) => BackendLoading = (state) =>
  selectEntriesStructureState(state).loading.getEntriesStructure

export const selectUpdateEntriesStructureLoading: (state: RootState) => BackendLoading = (state) =>
  selectEntriesStructureState(state).loading.updateEntriesStructure

export const selectIsProjectEntriesStructureConfigContentLoading: (state: RootState) => boolean = (state) =>
  selectFetchAvailableMetadataLoading(state).isLoading || selectUpdateEntriesStructureLoading(state).isLoading
