import { Alert } from 'antd'

interface ErrorAlertProps {
  message: string
  description?: string
}

const MessageErrorAlert: React.FC<ErrorAlertProps> = ({ message, description }) => {
  return <Alert message={message} description={description} type="error" showIcon />
}

export default MessageErrorAlert
