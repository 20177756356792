import { DragIndicator } from '@material-ui/icons'
import { ControlOutlined } from '@ant-design/icons'
import MapSideDrawerDropdown from 'components/atoms/map/map-sidedrawer-dropdown'
import { Trans } from '@lingui/macro'
import { TreeNode } from './MapSideDrawerTreeTypes'

export default (onDropdownClose: () => void) => (nodeData: TreeNode): JSX.Element => {
  if (nodeData.isLeaf && !nodeData.geodataLayers) {
    return (
      <>
        <DragIndicator className="icon icon--drag" /> {nodeData.title}
        <MapSideDrawerDropdown
          onClose={onDropdownClose}
          options={[
            <span>
              <Trans id="map.sidedrawer.titles.transparency">Transparency</Trans>
            </span>,
            <span>
              <Trans id="map.sidedrawer.titles.priority">Display priority</Trans>
            </span>,
            <span>
              <Trans id="map.sidedrawer.titles.isolate_layer">Isolate layer</Trans>
            </span>,
          ]}
        >
          <ControlOutlined className="icon icon--control" />
        </MapSideDrawerDropdown>
      </>
    )
  }
  return <>{nodeData.title}</>
}
