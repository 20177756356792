import { Discipline } from 'models/administration'
import PortfolioProject from 'models/portfolio'
import { Permission } from 'models/project/projectConfig'
import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import { UserPermission } from './UserPermissions.types'

export const selectPortfolioProjects = (state: RootState): PortfolioProject[] => state.profile.permissions.projects

export const selectSelectedProject = (state: RootState): number | undefined => state.profile.permissions.selectedProject

export const selectAvailableDisciplines = (state: RootState): Discipline[] => state.profile.permissions.disciplines

export const selectUserPermissions = (state: RootState): UserPermission[] => state.profile.permissions.permissions

export const selectUsersPermissionsByDiscipline = (disciplineId: number) => (state: RootState): Permission[] => {
  const userPermissions = selectUserPermissions(state)
  return userPermissions.filter(({ discipline }) => discipline === disciplineId).map(({ permissions }) => permissions)
}

export const selectFetchProjectsLoading = (state: RootState): BackendLoading =>
  state.profile.permissions.loading.fetchProjects

export const selectFetchProjectsDisciplinesLoading = (state: RootState): BackendLoading =>
  state.profile.permissions.loading.fetchProjectDisciplines

export const selectFetchPermissionsLoading = (state: RootState): BackendLoading =>
  state.profile.permissions.loading.fetchUserProjectPermissions
