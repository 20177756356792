import styled from 'styled-components'
import { Tree } from 'antd'

export default styled(Tree)`
  &.ant-tree {
    font-size: ${(props) => props.theme.sizes.s};
    color: ${(props) => props.theme.colors.baseBlackish};
    line-height: 14px;
  }
  .ant-tree-switcher {
    font-size: ${(props) => props.theme.sizes.default};
    color: ${(props) => props.theme.colors.grayG05};
    display: flex;
    align-items: center;
    background: transparent;
  }
  .ant-tree-indent-unit::before,
  .ant-tree-switcher-leaf-line::before,
  .ant-tree-switcher-leaf-line::after {
    border: none;
  }
  .icon {
    position: absolute;
    font-size: ${(props) => props.theme.sizes.default};
    opacity: 0;
    transform: translateY(-50%);
    top: 50%;
    transition: ${(props) => props.theme.transitions.default};
    &--drag {
      left: 26px;
    }
    &--control {
      right: ${(props) => props.theme.sizes.xs};
      color: ${(props) => props.theme.colors.grayG06};
      cursor: pointer;
      pointer-events: auto;
    }
  }
  .ant-tree-treenode {
    padding: ${(props) => props.theme.sizes.xs};
    padding-left: 0;
    transition: ${(props) => props.theme.transitions.default};
    width: 100%;
    position: relative;
    .ant-tree-node-content-wrapper,
    .ant-tree-title {
      position: static;
      pointer-events: none;
    }
    &:hover {
      background-color: ${(props) => props.theme.colors.actionHover};
    }
    &-selected {
      background-color: ${(props) => props.theme.colors.actionSelect} !important;
    }
    &:hover,
    &-selected {
      .icon--drag,
      .icon--control {
        opacity: 1;
      }
    }
    &-switcher-close:hover,
    &-switcher-open:hover {
      background-color: transparent;
      cursor: default;
    }
    .ant-tree-node-content-wrapper:hover,
    .ant-tree-node-selected {
      background-color: transparent;
    }
  }
`
