import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import backendAxios from 'axios/axios'
import { MapSettings } from 'models/project/projectDetails'
import { fetchProjectDetails } from 'redux/project/project-details'
import {
  selectProjectId,
  selectProjectOriginalMapSettings,
} from 'redux/project/project-details/ProjectDetails.selectors'
import {
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  combine,
  generateInitialLoadingState,
  getStateAsAny,
} from 'redux/redux.shared'
import { addProjectConfigFinishedStep } from '../status/ProjectStatusConfig.slice'
import { selectImageToUpdate, selectMapSettingsToUpdate } from './ProjectMapConfig.selectors'
import { ProjectMapConfigState, LoadingTypes, loadingTypes } from './ProjectMapConfig.types'

export const changeProjectionForProject = createAsyncBackendThunk(
  'changeProjectionForProject',
  async (selectedProjectionId: number | undefined, { getState }) => {
    if (selectedProjectionId) {
      const state = getStateAsAny(getState)
      const projectId = selectProjectId(state)
      const response = await backendAxios.patch(`/projects/${projectId}/projections/${selectedProjectionId}/`, {
        isDefault: true,
      })
      return response.data
    }
    return undefined
  }
)

export const changeCoordinatesAndScaleForProject = createAsyncBackendThunk(
  'changeCoordinatesAndScaleForProject',
  async (args, { getState, dispatch }) => {
    const state = getStateAsAny(getState)
    const mapSettings = selectMapSettingsToUpdate(state)
    const projectId = selectProjectId(state)
    const settingsDefined = !!selectProjectOriginalMapSettings(state)
    const image = selectImageToUpdate(state)
    if (mapSettings) {
      const response: AxiosResponse = settingsDefined
        ? await backendAxios.put(`/projects/${projectId}/map-settings/`, mapSettings)
        : await backendAxios.post(`/projects/${projectId}/map-settings/`, mapSettings)
      if (image) {
        backendAxios.put(`/projects/${projectId}/portfolio-image/`, { image })
      }
      dispatch(fetchProjectDetails(projectId as number))
      return response.data
    }
    return undefined
  }
)

export const updateMapConfig = createAsyncBackendThunk(
  'updateMapConfig',
  async (selectedProjectionId: number | undefined, { dispatch, getState }) => {
    const state = getStateAsAny(getState)
    const projectId = selectProjectId(state) as number
    await Promise.all([
      dispatch(changeProjectionForProject(selectedProjectionId)),
      dispatch(changeCoordinatesAndScaleForProject()),
      dispatch(addProjectConfigFinishedStep({ projectId, step: 'MAP_SETTINGS' })),
    ])
  }
)

const initialState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  projections: [],
  shouldCoordinatesUpdate: false,
  shouldProjectionUpdate: false,
  mapSettingsToUpdate: {
    longitude: 19.13444444,
    latitude: 51.91916667,
    scale: 1000000,
  },
} as ProjectMapConfigState

const projectMapConfigSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setMapSettingsToUpdate: (state, action: PayloadAction<MapSettings>) => {
      state.mapSettingsToUpdate = action.payload
    },
    setImageToUpdate: (state, action: PayloadAction<string>) => {
      state.imageToUpdate = action.payload
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<ProjectMapConfigState, LoadingTypes>({
      promise: changeProjectionForProject,
      loadingType: 'changeProjectionForProject',
    }),
    generateExtraBackendReducers<ProjectMapConfigState, LoadingTypes, MapSettings, MapSettings>({
      promise: changeCoordinatesAndScaleForProject,
      loadingType: 'changeCoordinatesAndScaleForProject',
      onFulfilled: (state, action) => {
        state.mapSettingsToUpdate = action.payload
      },
    }),
    generateExtraBackendReducers<ProjectMapConfigState, LoadingTypes>({
      promise: updateMapConfig,
      loadingType: 'updateMapConfig',
    }),
  ]),
})

export const { setMapSettingsToUpdate, setImageToUpdate } = projectMapConfigSlice.actions

export const projectMapConfigReducer = projectMapConfigSlice.reducer
