import { useDispatch, useSelector } from 'react-redux'
import Text from 'components/atoms/text'
import Space from 'components/atoms/space'
import { Trans } from '@lingui/macro'
import { fetchGeneralSettings } from 'redux/project/project-config/general'
import { selectProjectDetails } from 'redux/project/project-details/ProjectDetails.selectors'
import { useEffect } from 'react'
import ProjectGeneralConfigAlert from './ProjectGeneralConfigAlert'
import ProjectGeneralConfigForm from './ProjectGeneralConfigForm'

const ProjectConfigGeneralContent: React.FC = () => {
  const dispatch = useDispatch()

  const projectDetails = useSelector(selectProjectDetails)
  useEffect(() => {
    const projectId = projectDetails?.id
    const name = projectDetails?.name
    const isEnded = projectDetails?.isEnded
    if (name !== undefined && isEnded !== undefined && projectId !== undefined) {
      dispatch(fetchGeneralSettings({ projectId, name, isEnded }))
    }
  }, [dispatch, projectDetails])

  return (
    <Space>
      <header>
        <Text textStyle="regularText-medium">
          <Trans id="project_config.general.step_header">General settings</Trans>
        </Text>
      </header>
      <ProjectGeneralConfigForm />
      <ProjectGeneralConfigAlert />
    </Space>
  )
}

export default ProjectConfigGeneralContent
