import { useSelector } from 'react-redux'
import { Alert, Spin, Card, Space } from 'antd'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import LoginForm from 'components/organisms/login/LoginForm'
import Text from 'components/atoms/text'
import LoginTemplate from 'components/templates/login'
import { loginUser, setRedirectLink } from 'redux/login/Login.slice'
import { selectLoginUserLoading, selectRedirectLink } from 'redux/login/Login.selectors'
import { handleErrorByCode } from 'helpers/HttpError.helpers'
import { unwrapResult } from '@reduxjs/toolkit'
import { useAppDispatch } from 'redux/store'
import logo from 'assets/logo.svg'
import { defineMessage, Trans } from '@lingui/macro'
import trans from 'helpers/i18n.helpers'
import { errorText } from 'data/messages/controls'
import { SelectLanguageWithPlacement } from 'components/molecules/select-language'

const StyledSpan = styled.span`
  font-size: 14px;
  color: ${(props) => props.theme.colors.grayG08};
  position: fixed;
  left: 50%;
  bottom: 35px;
  transform: translateX(-50%);
`
const StyledCard = styled(Card)`
  .ant-card-body {
    width: 392px;
    > * {
      width: 100%;
    }
  }
`

const LOGIN_ERROR_MESSAGES = {
  401: defineMessage({ id: 'login.invalid_password_error', message: 'Invalid password or user does not exist.' }),
  429: defineMessage({
    id: 'login.too_many_attempts_error',
    message: 'Too many login attempts. Try again in a few minutes.',
  }),
}

const StyledImg = styled.img`
  height: 60px;
`

function Login(): JSX.Element {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const handleLogin = (email: string, password: string) => dispatch(loginUser({ email, password })).then(unwrapResult)
  const { isLoading, error } = useSelector(selectLoginUserLoading)
  const redirectLink = useSelector(selectRedirectLink)

  const handleConfirmForm = async (values: { loginName: string; password: string }) => {
    try {
      await handleLogin(values.loginName, values.password)
      if (redirectLink) {
        history.replace(redirectLink)
        dispatch(setRedirectLink(undefined))
      }
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }

  // While clicking the button in Login or Activation page (submit button), there is an error: "Warning: findDOMNode is
  // deprecated in StrictMode. findDOMNode was passed an instance of DomWrapper which is inside StrictMode."
  // It's a atom design's fault, the creators are fixing it

  return (
    <LoginTemplate>
      <Spin spinning={isLoading} size="large">
        <Space direction="vertical">
          <StyledImg src={logo} alt="InfraCDE" />
          <StyledCard>
            <Space direction="vertical" size="middle">
              <Text textStyle="header-H1">
                <Trans id="login.form_header_text">Sign in</Trans>
              </Text>
              <LoginForm submitForm={handleConfirmForm} loading={isLoading} />
              {error && (
                <Alert
                  message={trans(errorText)}
                  description={handleErrorByCode(LOGIN_ERROR_MESSAGES, error)}
                  type="error"
                  showIcon
                />
              )}
            </Space>
          </StyledCard>
        </Space>
        <StyledSpan>www.infrabyte.pl</StyledSpan>
        <SelectLanguageWithPlacement />
      </Spin>
    </LoginTemplate>
  )
}

export default Login
