import { Trans } from '@lingui/macro'
import { Spin } from 'antd'
import Text from 'components/atoms/text'
import { itemArr } from 'helpers/Collection.helpers'
import { FlowAttributeConfig } from 'models/flow'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  selectFlowAttributesIsAdding,
  selectFlowAttributesIsLoading,
  selectFlowUsedAttributes,
} from 'redux/flow-conifg/attributes/FlowAttributesConfig.selectors'
import styled from 'styled-components'
import Table from 'components/molecules/table'
import SpaceElement from 'components/atoms/space'
import useAttributesColumns from './columns/useAttributesColumns'
import AddAttributeButton from './AddAttributeButton'
import FlowAttributesConfigContentAlerts from './FlowAttributesConfigContentAlerts'

const StyledTable = styled(Table)`
  width: 925px;
  &&&& {
    td {
      &:nth-child(3) {
        text-align: center;
      }
    }
  }
`

const FlowAttributesConfigContent: React.FC = () => {
  const flowAttributes = useSelector(selectFlowUsedAttributes)
  const isAdding = useSelector(selectFlowAttributesIsAdding)
  const isLoading = useSelector(selectFlowAttributesIsLoading)
  const columns = useAttributesColumns()

  const [data, setData] = useState<FlowAttributeConfig[]>([])
  useEffect(() => {
    setData([...flowAttributes, ...itemArr({ id: undefined, name: '', isSelected: true, values: [] }, isAdding)])
  }, [flowAttributes, isAdding])

  return (
    <Spin spinning={isLoading}>
      <SpaceElement direction="vertical" size="l">
        <SpaceElement direction="vertical" size="xxs">
          <Text textStyle="regularText-medium">
            <Trans id="flow_config.attributes.heading">Attributes settings</Trans>
          </Text>
          <Text textStyle="regularText-small">
            <Trans id="flow_config.attributes.heading_description">
              If flow should be available only for files with given attributes values, this values should be added
              below.
            </Trans>
          </Text>
        </SpaceElement>
        <StyledTable
          columns={columns}
          dataSource={data}
          bordered
          pagination={false}
          rowKey={(row: FlowAttributeConfig) => row.id || -1}
          scroll={{ y: 'calc(100vh - 315px)' }}
        />
        <AddAttributeButton />
        <FlowAttributesConfigContentAlerts />
      </SpaceElement>
    </Spin>
  )
}

export default FlowAttributesConfigContent
