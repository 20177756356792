import Dot from 'components/atoms/dot'
import Text from 'components/atoms/text'
import styled, { Color } from 'styled-components'
import TooltipElement from 'components/molecules/tooltip-element'
import cdeStatusDict from 'data/cdeStatus'
import trans from 'helpers/i18n.helpers'

const StyledSpan = styled.span`
  display: flex;
  align-items: center;

  > *:first-child {
    margin-right: ${(props) => props.theme.sizes.xs};
  }
`

const CdeStatusItem: React.FC<{ status: string; textColor?: Color; abbreviated?: boolean }> = ({
  status,
  textColor,
  abbreviated = false,
}) => {
  const content = (text: string) => (
    <StyledSpan>
      <Dot color={cdeStatusDict[status].color} />
      <Text textStyle="regularText-small" color={textColor}>
        {text}
      </Text>
    </StyledSpan>
  )
  return (
    <TooltipElement placement="top" title={trans(cdeStatusDict[status].text)} disabled={!abbreviated}>
      {content(trans(cdeStatusDict[status].abbr))}
    </TooltipElement>
  )
}

export default CdeStatusItem
