import useStepsParams from 'hooks/stepsParams'
import { Redirect, useHistory, useParams } from 'react-router'
import { useEffect } from 'react'
import { getFlow } from 'redux/flow-conifg/general/FlowGeneralConfig.slice'
import { unwrapResult } from '@reduxjs/toolkit'
import { useAppDispatch } from 'redux/store'
import FlowConditionsConfig from './conditions'
import FlowGeneralConfig from './general/FlowGeneralConfig'
import FlowDecisionsConfig from './decisions/FlowDecisionsConfig'
import FlowConsequencesConfig from './consequences/FlowConsequencesConfig'
import FlowAttributesConfig from './attributes/FlowAttributesConfig'
import useFlowConfigRedirection from './useFlowConfigRedirection'

export const flowConfigContents = [
  <FlowGeneralConfig />,
  <FlowConditionsConfig />,
  <FlowAttributesConfig />,
  <FlowDecisionsConfig />,
  <FlowConsequencesConfig />,
]

const FlowConfig: React.FC = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { stepIdx, projectId, isStepIdxNan } = useStepsParams()
  const { flowId } = useParams<{ flowId?: string; projectId: string }>()
  const projectIdNumber = Number(projectId)
  const flowIdNumber = Number(flowId)
  const isFlowAdded = flowId !== undefined && !Number.isNaN(flowIdNumber)
  const isStepIdxInvalid = isStepIdxNan || stepIdx < 0 || stepIdx >= flowConfigContents.length

  useFlowConfigRedirection(isFlowAdded, isStepIdxInvalid)

  useEffect(() => {
    if (isFlowAdded && projectIdNumber && !Number.isNaN(projectIdNumber)) {
      dispatch(getFlow({ flowId: flowIdNumber, projectId: projectIdNumber }))
        .then(unwrapResult)
        .catch(() => {
          history.replace(`/project-config/${projectId}/12`)
        })
    }
  }, [dispatch, flowIdNumber, isFlowAdded, projectIdNumber, history, projectId])
  // in case when flow is not added and user tries to go to step other than 1
  return stepIdx !== 0 && !isFlowAdded ? (
    <Redirect to={`/flow-config/${projectId}/1/`} />
  ) : (
    <>{flowConfigContents[stepIdx]}</>
  )
}

export default FlowConfig
