import { SearchOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'
import { Input, Space } from 'antd'
import ButtonElement from 'components/molecules/button-element'
import { useEffect, useRef } from 'react'

export interface FilterProps {
  placeholder: string
  selectedKeys: React.Key[]
  setSelectedKeys: (selectedKeys: React.Key[]) => void
  confirm: () => void
  clearFilters?: () => void
  visible: boolean
}

const FilterDropdown: React.FC<FilterProps> = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  placeholder,
  visible,
}: FilterProps) => {
  const inputRef = useRef<Input>(null)

  const handleSearch = () => {
    confirm()
  }
  const handleReset = () => {
    if (clearFilters !== undefined) clearFilters()
    confirm()
  }
  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        inputRef.current?.input.focus()
      })
    }
  }, [visible, inputRef])
  return (
    <div style={{ padding: 8 }}>
      <Input
        ref={inputRef}
        placeholder={placeholder}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch()}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <ButtonElement type="primary" onClick={() => handleSearch()} icon={<SearchOutlined />} size="medium">
          <Trans id="common.filtering.filter_button_text">Search</Trans>
        </ButtonElement>
        <ButtonElement type="secondary" onClick={() => handleReset()} size="medium">
          <Trans id="common.filtering.reset_filter_button_text">Reset</Trans>
        </ButtonElement>
      </Space>
    </div>
  )
}

FilterDropdown.defaultProps = {
  clearFilters: () => {},
}

export default FilterDropdown
