import { identity } from 'helpers/Functions.helpers'
import { Group } from 'models/files'
import { useDispatch } from 'react-redux'
import { setCheckedGroups } from 'redux/files/files-table/FilesTable.slice'
import StyledTable from '../FilesTable.styles'
import { GroupDataSource } from './FilesGroups.types'
import useFilesGroupColumns from './useFilesGroupColumns'

interface FilesGroupsTableProps {
  groups?: Group[]
}

const getGroupsDataSource = (groups?: Group[]) =>
  groups?.map(
    ({ value, lastUpdated }): GroupDataSource => {
      return { key: value.id || value.name, name: value.name, lastUpdated }
    }
  )

const FilesGroupsTable: React.FC<FilesGroupsTableProps> = ({ groups }) => {
  const columns = useFilesGroupColumns()
  const dispatch = useDispatch()
  return (
    <StyledTable
      rowSelection={{
        type: 'checkbox',
        onChange: (selectedRowKeys: React.Key[]) =>
          dispatch(
            setCheckedGroups(
              selectedRowKeys
                .map((key) => groups?.find(({ value }) => (value.id ? value.id === key : value.name === key)))
                .filter(identity) as Group[]
            )
          ),
      }}
      dataSource={getGroupsDataSource(groups)}
      columns={columns}
      pagination={false}
      rowKey="key"
    />
  )
}

export default FilesGroupsTable
