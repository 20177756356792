import { Spin } from 'antd'
import { SelectValue } from 'antd/lib/select'
import useLoading from 'hooks/loading'
import { useState } from 'react'
import SelectElement from '.'

type EventWithPromise = (value: SelectValue) => Promise<unknown> | void

interface SelectWithLoadingProps {
  onChange?: EventWithPromise
  onSelect?: EventWithPromise
  onDeselect?: EventWithPromise
}

const SelectWithLoading: React.FC<
  Omit<React.ComponentProps<typeof SelectElement>, 'onChange'> & SelectWithLoadingProps
> = ({ loading, onChange, onSelect, onDeselect, options, disabled, ...props }) => {
  const { isLoading, functionWithLoading } = useLoading(onChange)
  const [loadingOptions, setLoadingOptions] = useState<number[]>([])
  const isComponentLoading = loading || isLoading
  const generateSelectionFn = (fn: EventWithPromise) => async (val: SelectValue) => {
    setLoadingOptions((prev) => [...prev, val as number])
    await fn(val)
    setLoadingOptions((prev) => prev.filter((opt) => opt !== val))
  }
  return (
    <SelectElement
      loading={isComponentLoading}
      disabled={isComponentLoading || disabled}
      onSelect={onSelect && generateSelectionFn(onSelect)}
      onDeselect={onDeselect && generateSelectionFn(onDeselect)}
      onChange={functionWithLoading}
      options={options?.map(({ name, value }) => {
        const isSpinning = !!loadingOptions.find((opt) => opt === value)
        return {
          label: (
            <Spin style={isSpinning ? { userSelect: 'none' } : {}} spinning={isSpinning}>
              {name}
            </Spin>
          ),
          value,
        }
      })}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
}

export default SelectWithLoading
