import ButtonElement from 'components/atoms/button.legacy'
import Text from 'components/atoms/text'
import { useDispatch } from 'react-redux'
import { downloadBackupReport } from 'redux/administration/administration-backups/AdministrationBackups.slice'
import { Trans } from '@lingui/macro'

interface BackupReportProps {
  report: string | null
  backupId: number
}

const BackupReport: React.FC<BackupReportProps> = ({ report, backupId }) => {
  const dispatch = useDispatch()
  const text = report || <Trans id="administration.backups.table.no_report_text">Report not generated</Trans>
  const color = report !== null ? 'primaryP04' : 'grayG04'
  if (report === null) {
    return (
      <Text color={color} textStyle="regularText-small">
        {text}
      </Text>
    )
  }
  return (
    <ButtonElement
      type="link"
      size="small"
      elementType="button"
      onClick={() => dispatch(downloadBackupReport(backupId))}
    >
      <Text color={color} textStyle="regularText-small">
        {text}
      </Text>
    </ButtonElement>
  )
}

export default BackupReport
