import { defineMessage } from '@lingui/macro'

export const deleteAttributeValueText = defineMessage({
  id: 'administration.attributes.common.delete_value_text',
  message: 'Delete value',
})
export const deleteUsedAttributeValueText = defineMessage({
  id: 'administration.attributes.common.delete_used_value_text',
  message: 'Value is used and cannot be deleted',
})
