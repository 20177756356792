import { Trans } from '@lingui/macro'
import BaseFormItem from 'components/molecules/form-item/BaseFormItem'
import YesNoRadioGroup from 'components/molecules/radio/YesNoRadioGroup'
import { FlowDecisionConfig } from 'models/flow'
import { setDecisionFlowValues } from 'redux/flow-conifg/decisions/FlowDecisionsConfig.slice'
import { useAppDispatch } from 'redux/store'

interface FlowGeneralConfigFormProps {
  flow: FlowDecisionConfig
}

const ReviewRequiredRadio: React.FC<FlowGeneralConfigFormProps> = ({ flow }) => {
  const dispatch = useAppDispatch()
  return (
    <BaseFormItem
      label={<Trans id="flow_config.decisions.review_required_radio_label">Is the decision for file required?</Trans>}
      tooltip={
        <Trans id="flow_config.decisions.review_required_radio_tooltip">
          If &apos;Yes&apos; selected, then file would require making decision by other user.
        </Trans>
      }
    >
      <YesNoRadioGroup
        value={flow.reviewRequired}
        onChange={(e) => dispatch(setDecisionFlowValues({ ...flow, reviewRequired: e.target.value }))}
      />
    </BaseFormItem>
  )
}

export default ReviewRequiredRadio
