import { useSelector } from 'react-redux'
import { defineMessage, t } from '@lingui/macro'
import {
  selectAdministationNamingSchemeInModal,
  selectAdministationNamingSchemeIsModalActionCopy,
  selectAdministrationNamingSchemeCreationLoading,
  selectAdministrationPatchingSelectedSchemeLoading,
} from 'redux/administration/administration-naming-scheme/AdministrationNamingScheme.selectors'
import { MessageErrorAlert } from 'components/atoms/error-alert'
import { handleApiErrors } from 'helpers/HttpError.helpers'

const errorsDict = {
  'entry_schema.name_exists': defineMessage({
    id: 'administration.naming_schemes.modal.errors.name_exists',
    message: 'Naming scheme with this name already exists',
  }),
  'entry_schema.duplicated_group': defineMessage({
    id: 'administration.naming_schemes.modal.errors.duplicated_group',
    message: 'Each field may appear at most once',
  }),
  'entry_schema.empty': defineMessage({
    id: 'administration.naming_schemes.modal.errors.scheme_is_empty',
    message: 'Naming scheme must contain at least one field',
  }),
  'entry_schema.cannot_set_as_not_default': defineMessage({
    id: 'administration.naming_schemes.modal.errors.cannot_set_as_not_default',
    message: 'Exactly one scheme must be set as default',
  }),
  'entry_schema.not_editable': defineMessage({
    id: 'administration.naming_schemes.modal.errors.not_editable',
    message: 'This scheme cannot be edited',
  }),
  'entry_schema.missing_revision': defineMessage({
    id: 'administration.naming_schemes.modal.errors.missing_revision',
    message: 'Scheme must contain the "revision" field',
  }),
  'entry_schema.missing_file_number': defineMessage({
    id: 'administration.naming_schemes.modal.errors.missing_file_number',
    message: 'Scheme must contain the "file number" field',
  }),
  'entry_schema.revision_not_last': defineMessage({
    id: 'administration.naming_schemes.modal.errors.revision_not_last',
    message: 'The "revision" field must be the last one',
  }),
}

const NamingSchemeModalAlert: React.FC = () => {
  const scheme = useSelector(selectAdministationNamingSchemeInModal)
  const isActionCopy = useSelector(selectAdministationNamingSchemeIsModalActionCopy)

  const { error: editError } = useSelector(selectAdministrationPatchingSelectedSchemeLoading)
  const { error: createError } = useSelector(selectAdministrationNamingSchemeCreationLoading)

  if (scheme === undefined || isActionCopy) {
    return createError ? (
      <MessageErrorAlert
        message={t({
          id: 'administration.naming_schemes.modal.create_error',
          message: 'Could not create naming scheme',
        })}
        description={handleApiErrors(errorsDict, createError.errors)}
      />
    ) : null
  }
  return editError ? (
    <MessageErrorAlert
      message={t({
        id: 'administration.naming_schemes.modal.edit_error',
        message: 'Could not edit naming scheme',
      })}
      description={handleApiErrors(errorsDict, editError.errors)}
    />
  ) : null
}

export default NamingSchemeModalAlert
