import { t, Trans } from '@lingui/macro'
import ButtonElement from 'components/molecules/button-element'
import TooltipElement from 'components/molecules/tooltip-element'
import { useDispatch, useSelector } from 'react-redux'
import { selectAreAnyFlowAttributesNotSelected } from 'redux/flow-conifg/attributes/FlowAttributesConfig.selectors'
import { setIsAdding } from 'redux/flow-conifg/attributes/FlowAttributesConfig.slice'

const AddAttributeButton: React.FC = () => {
  const dispatch = useDispatch()
  const anyAttributesNotSelected = useSelector(selectAreAnyFlowAttributesNotSelected)
  const isButtonDisabled = !anyAttributesNotSelected
  const button = (
    <ButtonElement type="secondary" onClick={() => dispatch(setIsAdding(true))} disabled={isButtonDisabled}>
      <Trans id="flow_config.attributes.add_attribute_button">Add attribute</Trans>
    </ButtonElement>
  )
  return (
    <div style={{ width: '150px' }}>
      {isButtonDisabled ? (
        <TooltipElement
          placement="top"
          title={t({
            id: 'flow_config.attributes.all_attributes_selected_tooltip',
            message: 'All available attributes are already selected',
          })}
        >
          {button}
        </TooltipElement>
      ) : (
        button
      )}
    </div>
  )
}

export default AddAttributeButton
