import { Locale } from 'antd/lib/locale-provider'
import { FALLBACK_LOCALE, Language, localesData } from 'data/i18n'
import { RootState } from 'redux/store'
import LanguageSelectState from './Language.types'

const selectLanguageState = (state: RootState): LanguageSelectState => state.language

export const selectCurrentLanguage = (state: RootState): Language | undefined => selectLanguageState(state).language

export const selectAntdLocale = (state: RootState): Locale =>
  localesData[selectCurrentLanguage(state) || FALLBACK_LOCALE].antd
