import { Attribute, AttributeValue } from 'models/administration'
import { BackendLoading } from 'redux/redux.shared'

export const loadingTypes = [
  'fetchAttributes',
  'removeAttribute',
  'removeAttributeValue',
  'addAttribute',
  'editAttribute',
] as const
export type LoadingTypes = typeof loadingTypes[number]

export type AttributeArgs = Partial<
  Exclude<Attribute, 'id' | 'values'> & { values: (Pick<AttributeValue, 'value' | 'code'> & { id?: number })[] }
>

export interface ModalData {
  isModalVisible: boolean
  attribute?: Attribute
}

export interface AdministrationAttributesState {
  loading: Record<LoadingTypes, BackendLoading>
  attributes: Attribute[]
  modalData: ModalData
}
