import Text from 'components/atoms/text'
import trans from 'helpers/i18n.helpers'
import { OrganizationContactUser } from 'models/administration'
import styled from 'styled-components'
import { noContactPersonDefinedText } from '../texts'

interface DisplayContactUserProps {
  contact: OrganizationContactUser | null
  isExpanded: boolean
}

const StyledDiv = styled.div`
  max-width: 95%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.sizes.xs};
`

const textColor = 'grayG07'

const DisplayContactUser: React.FC<DisplayContactUserProps> = ({ contact, isExpanded }) => {
  if (!contact) {
    return (
      <Text textStyle="regularText-small" color="grayG05">
        {trans(noContactPersonDefinedText)}
      </Text>
    )
  }
  const { firstName, lastName, position, phoneNumber, email } = contact
  const ellipsis = !isExpanded ? { rows: 2 } : undefined
  return (
    <StyledDiv>
      <Text paragraph textStyle="regularText-smallSemiBold" color={textColor} ellipsis={ellipsis}>
        {firstName} {lastName}
      </Text>
      {isExpanded && (
        <>
          <Text paragraph textStyle="regularText-small" color={textColor} ellipsis={ellipsis}>
            {position}
          </Text>
          <Text textStyle="regularText-small" color={textColor}>
            {phoneNumber}
          </Text>
          <Text paragraph textStyle="regularText-small" color={textColor}>
            {email}
          </Text>
        </>
      )}
    </StyledDiv>
  )
}

export default DisplayContactUser
