import { DownOutlined } from '@ant-design/icons'
import { t, Trans } from '@lingui/macro'
import { Dropdown, Menu, Space } from 'antd'
import SpaceElement from 'components/atoms/space'
import ButtonElement from 'components/molecules/button-element'
import BaseFormItem from 'components/molecules/form-item/BaseFormItem'
import TooltipElement from 'components/molecules/tooltip-element'
import { NamingSchemeElement } from 'models/administration'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAvailableSchemeElements } from 'redux/administration/administration-naming-scheme/AdministrationNamingScheme.helpers'
import { selectAdministrationNamingSchemesMetadata } from 'redux/administration/administration-naming-scheme/AdministrationNamingScheme.selectors'
import styled from 'styled-components'
import DefaultSchemeCheckbox from './DefaultSchemeCheckbox'
import NamingSchemeDetails from './NamingSchemeDetails'
import NamingSchemeFieldsMenu from './NamingSchemeFieldsMenu'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const StyledSpace = styled(SpaceElement)`
  align-items: center;
`

const SpaceWithWidth = styled(Space)`
  max-width: 100%;
`

interface SchemeDetailsFieldProps {
  value?: NamingSchemeElement[]
  onChange?: (value: NamingSchemeElement[]) => void
  canSetAsNotDefault: boolean
}

const SchemeDetailsField: React.FC<SchemeDetailsFieldProps> = ({ value, onChange, canSetAsNotDefault }) => {
  const [isFieldSelectionMenuVisible, setIsFieldSelectionMenuVisible] = useState(false)

  const attributes = useSelector(selectAdministrationNamingSchemesMetadata)
  const availableElements = useMemo(() => getAvailableSchemeElements(attributes, value || []), [attributes, value])
  const dropdownDisabled = availableElements.length === 0

  return (
    <SpaceWithWidth direction="vertical">
      <Row>
        <TooltipElement
          title={t({
            id: 'administration.naming_schemes.modal.add_field_disabled_button_tooltip',
            message: 'All available attributes have been selected',
          })}
          disabled={!dropdownDisabled}
        >
          <Dropdown
            overlay={
              <Menu>
                <NamingSchemeFieldsMenu
                  availableElements={availableElements}
                  onClose={() => setIsFieldSelectionMenuVisible(false)}
                  onChange={onChange}
                  value={value}
                />
              </Menu>
            }
            disabled={dropdownDisabled}
            visible={isFieldSelectionMenuVisible}
            onVisibleChange={(visible) => setIsFieldSelectionMenuVisible(visible)}
            trigger={['click']}
          >
            <ButtonElement type="secondary" disabled={dropdownDisabled}>
              <StyledSpace size="xs" direction="horizontal">
                <Trans id="administration.naming_scheme.modal.add_field_button_text">Add field</Trans>
                <DownOutlined />
              </StyledSpace>
            </ButtonElement>
          </Dropdown>
        </TooltipElement>
        <BaseFormItem name="isDefault">
          <DefaultSchemeCheckbox disableable={canSetAsNotDefault} />
        </BaseFormItem>
      </Row>
      <BaseFormItem name="scheme">
        <NamingSchemeDetails />
      </BaseFormItem>
    </SpaceWithWidth>
  )
}

export default SchemeDetailsField
