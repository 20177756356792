import { UserDeleteOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import ButtonWithTooltip from 'components/molecules/buttons'
import { editAccountActivationField } from 'redux/administration/administration-users'
import { useAppDispatch } from 'redux/store'

interface ActionProps {
  id: number
}

const AccountDeactivateButton: React.FC<ActionProps> = ({ id }) => {
  const dispatch = useAppDispatch()
  return (
    <ButtonWithTooltip
      onClick={async () => {
        if (id) {
          dispatch(editAccountActivationField({ id, isAccountActive: false }))
        }
      }}
      tooltipText={t({ id: 'administration.users.disable_account_button_text', message: 'Disable account' })}
      icon={<UserDeleteOutlined />}
      withLoading
    />
  )
}

export default AccountDeactivateButton
