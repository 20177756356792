import { Menu, Dropdown } from 'antd'
import styled from 'styled-components'
import withKeys from 'helpers/Component.helpers'

interface MapSideDrawerDropdownProps {
  children: React.ReactNode
  trigger?: ('contextMenu' | 'click' | 'hover')[]
  options: JSX.Element[]
  onClose?: () => void
}

const StyledMenuItem = styled(Menu.Item)`
  font-size: 12px;
  color: #000;
  line-height: 14px;
`

const MapSideDrawerDropdown: React.FC<MapSideDrawerDropdownProps> = ({
  children,
  trigger = ['click'],
  options,
  onClose,
}) => {
  const optionsWithKeys = withKeys(options)
  const handleClose = (visible: boolean) => {
    if (!visible && onClose) {
      onClose()
    }
  }
  return (
    <Dropdown
      trigger={trigger}
      onVisibleChange={handleClose}
      overlay={
        <Menu>
          {optionsWithKeys.map((option) => (
            <StyledMenuItem key={option.key}>{option.component}</StyledMenuItem>
          ))}
        </Menu>
      }
    >
      {children}
    </Dropdown>
  )
}

export default MapSideDrawerDropdown
