import { WithDividerElement } from 'components/atoms/divider'
import { ConsequencesTypes } from 'models/flow'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectConsequenceFilteredByType } from 'redux/flow-conifg/consequences/FlowConsequencesConfig.selectors'
import { addEmptyConsequence } from 'redux/flow-conifg/consequences/FlowConsequencesConfig.slice'
import { selectIsReviewRequired } from 'redux/flow-conifg/decisions/FlowDecisionsConfig.selectors'
import { useAppDispatch } from 'redux/store'
import FormContent from './form-items/FormContent'

const FlowConsequencesConfigForm: React.FC = () => {
  const dispatch = useAppDispatch()
  const isReviewRequired = useSelector(selectIsReviewRequired)
  const positiveConsequence = useSelector(selectConsequenceFilteredByType(ConsequencesTypes.POSITIVE))
  const negativeConsequence = useSelector(selectConsequenceFilteredByType(ConsequencesTypes.NEGATIVE))
  const neutralConsequence = useSelector(selectConsequenceFilteredByType(ConsequencesTypes.NEUTRAL))
  useEffect(() => {
    if (!negativeConsequence && isReviewRequired) {
      dispatch(addEmptyConsequence(ConsequencesTypes.NEGATIVE))
    }
    if (!positiveConsequence && isReviewRequired) {
      dispatch(addEmptyConsequence(ConsequencesTypes.POSITIVE))
    }
    if (!neutralConsequence && !isReviewRequired) {
      dispatch(addEmptyConsequence(ConsequencesTypes.NEUTRAL))
    }
  }, [positiveConsequence, negativeConsequence, neutralConsequence, isReviewRequired, dispatch])
  if (isReviewRequired) {
    return (
      <WithDividerElement height="550px">
        {negativeConsequence && (
          <FormContent
            validationFormName="negativeConsequenceForm"
            consequence={negativeConsequence}
            type={ConsequencesTypes.NEGATIVE}
          />
        )}
        {positiveConsequence && (
          <FormContent
            validationFormName="positiveConsequenceForm"
            consequence={positiveConsequence}
            type={ConsequencesTypes.POSITIVE}
          />
        )}
      </WithDividerElement>
    )
  }
  if (neutralConsequence) {
    return (
      <FormContent
        validationFormName="neutralConsequenceForm"
        consequence={neutralConsequence}
        type={ConsequencesTypes.NEUTRAL}
      />
    )
  }
  return null
}

export default FlowConsequencesConfigForm
