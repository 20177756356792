import { cdeDirectoryText } from 'data/messages/misc'
import trans from 'helpers/i18n.helpers'
import { t, Trans } from '@lingui/macro'
import { ColumnsType } from 'antd/lib/table'
import SuitabilityCodeStatusDisplay from 'components/atoms/administration/suitability-status-display'
import useTableColumnFiltering, { onFilterStringColumn } from 'components/molecules/table/useTableColumnFiltering'
import { SuitabilityCode } from 'models/administration'
import { cdeStatusFilters } from 'helpers/Models.helpers'
import WithDefaultMarker from 'components/atoms/with-default-marker'

const useProjectSuitabilityConfigColumns = (): ColumnsType<SuitabilityCode> => {
  return [
    {
      title: <Trans id="project_config.suitability.table.column_code_label">Code</Trans>,
      dataIndex: 'code',
      key: 'code',
      width: 50,
      render: (value, { code }) => code,
      ...useTableColumnFiltering(
        t({ id: 'project_config.suitability.table.search_code_placeholder', message: 'Search for code' })
      ),
      onFilter: (value: string | number | boolean, record: SuitabilityCode) => onFilterStringColumn(value, record.code),
    },
    {
      title: <Trans id="project_config.suitability.table.column_name_label">Name</Trans>,
      dataIndex: 'name',
      key: 'name',
      width: 390,
      render: (value, { name, isDefault }) => (
        <WithDefaultMarker
          isDefault={!!isDefault}
          tooltipText={t({ id: 'project_config.suitability.table.default_code_tooltip', message: 'Default code' })}
        >
          {name}
        </WithDefaultMarker>
      ),
      ...useTableColumnFiltering(
        t({ id: 'project_config.suitability.table.search_name_placeholder', message: 'Search for name' })
      ),
      onFilter: (value: string | number | boolean, record: SuitabilityCode) => onFilterStringColumn(value, record.name),
    },
    {
      title: trans(cdeDirectoryText),
      dataIndex: 'status',
      key: 'status',
      width: 130,
      render: (value, { status }) => <SuitabilityCodeStatusDisplay status={status} />,
      filters: cdeStatusFilters(),
      onFilter: (value, record) => record.status === value,
    },
  ]
}

export default useProjectSuitabilityConfigColumns
