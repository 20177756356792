import { RequestPasswordResetForm, ResetPassword } from 'components/organisms/password'
import useQueryParams from 'hooks/query/queryParams'
import PasswordTemplate from 'components/templates/password/PasswordTemplate'
import Title from 'antd/es/typography/Title'
import { t } from '@lingui/macro'

function PasswordReset(): JSX.Element {
  const token = useQueryParams().get('token')

  return (
    <PasswordTemplate>
      <Title level={2}>{t({ id: 'password_reset.header_text', message: 'Reset password' })}</Title>
      {token ? <ResetPassword /> : <RequestPasswordResetForm />}
    </PasswordTemplate>
  )
}

export default PasswordReset
