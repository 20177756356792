import { useSelector } from 'react-redux'
import Modal from 'components/molecules/modal/Modal'
import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import Icon from 'components/atoms/icon'
import {
  addUsersFromFile,
  setFileModalVisibility,
} from 'redux/administration/administration-users/AdministrationUsers.slice'
import { useAppDispatch } from 'redux/store'
import {
  selectAddingFromFileLoading,
  selectFileInfoModalVisibility,
  selectLoadingUsersErrors,
} from 'redux/administration/administration-users/AdministrationUsers.selectors'
import useUpload from 'hooks/upload'
import { MessageErrorAlert } from 'components/atoms/error-alert'
import { handleApiErrors } from 'helpers/HttpError.helpers'
import TooltipElement from 'components/molecules/tooltip-element'
import Text from 'components/atoms/text'
import { UploadSingleFile } from 'components/organisms/upload/single-file'
import { defineMessage, t, Trans } from '@lingui/macro'
import ModalFooter from '../ModalFooter'
import LoadUsersFromFileInfoModal from './LoadUsersFromFileInfoModal'
import { emailSendingFailedError } from '../../texts'

const errorDict = {
  'users_file.too_big': defineMessage({
    id: 'administration.users.file_modal.file_too_big_error',
    message: 'File is too big. Max file size: 25MB',
  }),
  'users_file.read_failed': defineMessage({
    id: 'administration.users.file_modal.file_read_failed',
    message: 'Could not read the file. Supported formats: xls, xlsx, xlsm, ods',
  }),
  'users_file.invalid': defineMessage({
    id: 'administration.users.file_modal.file_invalid',
    message: 'File does not contain all necessary columns.',
  }),
  'users_file.invalid_data': defineMessage({
    id: 'administration.users.file_modal.invalid_data_in_file',
    message: 'File contains invalid data. Check file content and try again.',
  }),
  'users_file.empty': defineMessage({
    id: 'administration.users.file_modal.file_is_empty',
    message: 'File is empty',
  }),
  'email.sending_failed': emailSendingFailedError,
}

const helpText = (
  <>
    <Trans id="administration.users.file_modal.help.header">File must cantain the following columns:</Trans>
    <ul>
      <li>
        <Trans id="administration.users.file_modal.help.name_row">Name</Trans>
      </li>
      <li>
        <Trans id="administration.users.file_modal.help.surname_row">Surname</Trans>
      </li>
      <li>
        <Trans id="administration.users.file_modal.help.email_row">Email</Trans>
      </li>
      <li>
        <Trans id="administration.users.file_modal.help.admin_row">Admin - 0 (for normal user) or 1 (for admin)</Trans>
      </li>
      <li>
        <Trans id="administration.users.file_modal.help.organization_row">Organization - organization code</Trans>
      </li>
      <li>
        <Trans id="administration.users.file_modal.help.disciplines_row">
          Disciplines - list of comma-separated codes of disciplines
        </Trans>
      </li>
    </ul>
  </>
)
const helpTooltip = (
  <TooltipElement placement="top" title={helpText}>
    <Icon hoverColor="primaryP03">
      <QuestionCircleOutlined />
    </Icon>
  </TooltipElement>
)

const FileModal: React.FC<{ visible: boolean }> = ({ visible }) => {
  const dispatch = useAppDispatch()
  const { file, beforeUpload, onRemove } = useUpload()
  const isAddingFromFileLoading = useSelector(selectAddingFromFileLoading)
  const isErrorModalVisible = useSelector(selectFileInfoModalVisibility)
  const loadingErrors = useSelector(selectLoadingUsersErrors)
  const handleSubmit = () => {
    if (file) {
      dispatch(addUsersFromFile(file))
    }
  }
  const handleCancel = () => dispatch(setFileModalVisibility(false))

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      maskClosable={false}
      title={
        <>
          <Text textStyle="header-H2b">
            <Trans id="administration.users.file_modal.header_text">Add users from file</Trans>
          </Text>
          {helpTooltip}
        </>
      }
      footer={
        <ModalFooter
          isCreateModal
          loading={isAddingFromFileLoading.isLoading}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          submitEnabled={!!file}
        />
      }
      closeIcon={
        <Icon>
          <CloseOutlined />
        </Icon>
      }
    >
      <UploadSingleFile file={file} onRemove={onRemove} beforeUpload={beforeUpload} />
      {isErrorModalVisible && <LoadUsersFromFileInfoModal errors={loadingErrors} />}
      {isAddingFromFileLoading.error !== undefined && (
        <MessageErrorAlert
          message={t({ id: 'administration.users.file_modal.adding_failed', message: 'Could not add users. ' })}
          description={handleApiErrors(errorDict, isAddingFromFileLoading.error.errors)}
        />
      )}
    </Modal>
  )
}

export default FileModal
