import { RootState } from 'redux/store'
import { BackendLoading } from 'redux/redux.shared'
import { ProjectLayer } from '../../../../models/map'

export const selectGisProjectLayers = (state: RootState): ProjectLayer[] => state.map.gisProjectLayers.layers
export const selectVisibleGisProjectLayers = (state: RootState): string[] => state.map.gisProjectLayers.visibleLayers
export const selectCheckedGisProjectLayerNodes = (state: RootState): (string | number)[] =>
  state.map.gisProjectLayers.checkedLayerNodes
export const selectFetchGisProjectLayersLoading = (state: RootState): BackendLoading =>
  state.map.gisProjectLayers.loading.fetchGisProjectLayers
