import { t } from '@lingui/macro'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import { removedErrorsFromLoading } from 'helpers/HttpError.helpers'
import { openSuccessNotification } from 'helpers/Notifications.helpers'
import { Attribute, NamingScheme } from 'models/administration'
import {
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
} from 'redux/redux.shared'
import { getSetSchemeDefault } from './AdministrationNamingScheme.helpers'
import {
  loadingTypes,
  LoadingTypes,
  AdministrationNamingSchemeState,
  NamingSchemeModalState,
} from './AdministrationNamingScheme.types'

export const fetchNamingSchemes = createAsyncBackendThunk('namingSchemes/fetchNamingSchemes', async () => {
  return (await backendAxios.get('/entries/naming-scheme/')).data
})

export const createNamingScheme = createAsyncBackendThunk(
  'namingSchemes/createNamingScheme',
  async (schemeData: Omit<NamingScheme, 'id'>) => {
    const response = await backendAxios.post('/entries/naming-scheme/', schemeData)
    openSuccessNotification({
      message: t({
        id: 'administration.naming_schemes.notifications.added_text',
        message: 'Naming scheme has been added',
      }),
    })
    return response.data
  }
)

export const deleteNamingScheme = createAsyncBackendThunk('namingSchemes/deleteNamingScheme', async (id: number) => {
  await backendAxios.delete(`/entries/naming-scheme/${id}/`)
  openSuccessNotification({
    message: t({
      id: 'administration.naming_schemes.notifications.deleted_text',
      message: 'Naming scheme has been deleted',
    }),
  })
})

export const fetchNamingSchemeMetadata = createAsyncBackendThunk('namingSchemes/fetchMetadata', async () => {
  return (await backendAxios.get('/metadata/')).data
})

export const patchScheme = createAsyncBackendThunk(
  'namingSchemes/patchScheme',
  async ({ schemeId, data }: { schemeId: number; data: Omit<NamingScheme, 'id' | 'isEditable'> }) => {
    const response = await backendAxios.patch(`/entries/naming-scheme/${schemeId}/`, data)
    openSuccessNotification({
      message: t({
        id: 'administration.naming_schemes.notifications.updated_text',
        message: 'Naming scheme has been updated',
      }),
    })
    return response.data
  }
)

export const setSchemeAsDefault = createAsyncBackendThunk(
  'namingSchemes/setSechemeAsDefault',
  async (schemeId: number) => {
    const response = await backendAxios.patch(`/entries/naming-scheme/${schemeId}/`, { isDefault: true })
    openSuccessNotification({
      message: t({
        id: 'administration.naming_schemes.notifications.scheme_set_as_default',
        message: 'Scheme has been set as default',
      }),
    })
    return response.data
  }
)

const initialModalState = {
  isOpen: false,
  isActionCopy: false,
} as NamingSchemeModalState

const initialState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  schemes: [],
  metadata: [],
  modalState: initialModalState,
} as AdministrationNamingSchemeState

const administrationNamingSchemeSlice = createSlice({
  name: 'administrationNamingSchemes',
  initialState,
  reducers: {
    openSchemeModal: (state, action: PayloadAction<{ schemeId?: number; copy?: boolean } | undefined>) => {
      const { schemeId, copy } = action.payload || { schemeId: undefined, copy: false }
      state.modalState = {
        isOpen: true,
        scheme: action.payload !== undefined ? state.schemes.find(({ id }) => id === schemeId) : undefined,
        isActionCopy: !!copy,
      }
    },
    closeSchemeModal: (state) => {
      state.modalState = initialModalState
      state.loading = removedErrorsFromLoading(state.loading)
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<AdministrationNamingSchemeState, LoadingTypes, NamingScheme[]>({
      promise: fetchNamingSchemes,
      loadingType: 'fetchNamingSchemes',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        state.schemes = action.payload
      },
    }),
    generateExtraBackendReducers<AdministrationNamingSchemeState, LoadingTypes, NamingScheme>({
      promise: createNamingScheme,
      loadingType: 'createNamingScheme',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        const mapNotEdited = getSetSchemeDefault(action.payload.isDefault)
        state.schemes = [...state.schemes.map(mapNotEdited), action.payload]
        state.modalState = initialModalState
      },
    }),
    generateExtraBackendReducers<AdministrationNamingSchemeState, LoadingTypes, Attribute[]>({
      promise: fetchNamingSchemeMetadata,
      loadingType: 'fetchMetadata',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        state.metadata = action.payload
      },
    }),
    generateExtraBackendReducers<AdministrationNamingSchemeState, LoadingTypes, undefined, number>({
      promise: deleteNamingScheme,
      loadingType: 'deleteNamingScheme',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        state.schemes = state.schemes.filter(({ id }) => id !== action.meta.arg)
      },
    }),
    generateExtraBackendReducers<AdministrationNamingSchemeState, LoadingTypes, NamingScheme, number>({
      promise: setSchemeAsDefault,
      loadingType: 'setSchemeAsDefault',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        const changedId = action.meta.arg
        state.schemes = state.schemes.map((scheme) =>
          scheme.id === changedId ? action.payload : { ...scheme, isDefault: false }
        )
      },
    }),
    generateExtraBackendReducers<
      AdministrationNamingSchemeState,
      LoadingTypes,
      NamingScheme,
      { schemeId: number; scheme: NamingScheme }
    >({
      promise: patchScheme,
      loadingType: 'patchSelectedScheme',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        const mapNotEdited = getSetSchemeDefault(action.payload.isDefault)
        state.schemes = state.schemes.map((scheme) =>
          scheme.id === action.meta.arg.schemeId ? action.payload : mapNotEdited(scheme)
        )
        state.modalState = initialModalState
      },
    }),
  ]),
})

export const { openSchemeModal, closeSchemeModal } = administrationNamingSchemeSlice.actions

export const administrationNamingSchemesReducer = administrationNamingSchemeSlice.reducer
