import { t } from '@lingui/macro'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import { removedErrorsFromLoading } from 'helpers/HttpError.helpers'
import { openSuccessNotification } from 'helpers/Notifications.helpers'
import objectsDiff from 'helpers/Objects.helpers'
import { Attribute } from 'models/administration'
import {
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
  getStateAsAny,
} from 'redux/redux.shared'
import { selectModalAttribute } from './AdministrationAttributes.selectors'
import {
  loadingTypes,
  LoadingTypes,
  AdministrationAttributesState,
  AttributeArgs,
} from './AdministrationAttributes.types'

interface DeleteAttributeValueArgs {
  parentId: number
  attributeValueId: number
}

interface EditAttributeArgs {
  attributeId: number
  attributeFieldsToUpdate: AttributeArgs
}

export const fetchAttributes = createAsyncBackendThunk('fetchAttributes', async () => {
  const response = await backendAxios.get('/metadata/')
  return response.data
})

export const addAttribute = createAsyncBackendThunk('addAttribute', async (attribute: AttributeArgs) => {
  const response = await backendAxios.post(`/metadata/`, attribute)
  openSuccessNotification({
    message: t({
      id: 'administration.attributes.notifications.added_text',
      message: 'Attribute has been added',
    }),
  })
  return response.data
})

export const editAttribute = createAsyncBackendThunk(
  'editAttribute',
  async ({ attributeId, attributeFieldsToUpdate }: EditAttributeArgs, { getState }) => {
    const editedAttribute = selectModalAttribute(getStateAsAny(getState))
    const diff: Partial<Exclude<Attribute, 'id'>> = objectsDiff(editedAttribute || {}, attributeFieldsToUpdate)
    let data
    if (Object.keys(diff).length !== 0) {
      data = (await backendAxios.patch(`/metadata/${attributeId}/`, attributeFieldsToUpdate)).data
    }
    openSuccessNotification({
      message: t({
        id: 'administration.attributes.notifications.updated_text',
        message: 'Attribute has been updated',
      }),
    })
    return data
  }
)

export const removeAttribute = createAsyncBackendThunk('removeAttribute', async (attribiuteId: number) => {
  await backendAxios.delete(`/metadata/${attribiuteId}/`)
  openSuccessNotification({
    message: t({
      id: 'administration.attributes.notifications.deleted_text',
      message: 'Attribute has been deleted',
    }),
  })
})

export const removeAttributeValue = createAsyncBackendThunk(
  'removeAttributeValue',
  async ({ attributeValueId, parentId }: DeleteAttributeValueArgs) => {
    await backendAxios.post(`/metadata/${parentId}/values/delete/`, [{ id: attributeValueId }])
    openSuccessNotification({
      message: t({
        id: 'administration.attributes.notifications.value_deleted_text',
        message: 'Attribute value has been deleted',
      }),
    })
  }
)

const initialState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  attributes: [],
  modalData: { isModalVisible: false },
} as AdministrationAttributesState

const administrationAttributesSlice = createSlice({
  name: 'attributes',
  initialState,
  reducers: {
    showAttributeModal: (state, action: PayloadAction<Attribute | undefined>) => {
      state.modalData.isModalVisible = true
      state.modalData.attribute = action?.payload
    },
    hideAttributeModal: (state) => {
      state.modalData.isModalVisible = false
      state.modalData.attribute = undefined
      state.loading = removedErrorsFromLoading(state.loading)
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<AdministrationAttributesState, LoadingTypes, Attribute[]>({
      promise: fetchAttributes,
      loadingType: 'fetchAttributes',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        state.attributes = action.payload
      },
    }),
    generateExtraBackendReducers<AdministrationAttributesState, LoadingTypes, Attribute>({
      promise: addAttribute,
      loadingType: 'addAttribute',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        const attribute = action.payload
        state.attributes.push(attribute)
        state.modalData = initialState.modalData
      },
    }),
    generateExtraBackendReducers<AdministrationAttributesState, LoadingTypes, Attribute | undefined, EditAttributeArgs>(
      {
        promise: editAttribute,
        loadingType: 'editAttribute',
        onPending: (state) => {
          state.loading = removedErrorsFromLoading(state.loading)
        },
        onFulfilled: (state, action) => {
          state.modalData = initialState.modalData
          if (action.payload !== undefined) {
            const { attributeId } = action.meta.arg
            state.attributes = state.attributes.map((attr) => {
              const itemsUpdated = attr.id === attributeId ? action.payload : {}
              return { ...attr, ...itemsUpdated }
            })
          }
        },
      }
    ),
    generateExtraBackendReducers<AdministrationAttributesState, LoadingTypes, void, number>({
      promise: removeAttribute,
      loadingType: 'removeAttribute',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        const attributeId = action.meta.arg
        state.attributes = state.attributes.filter(({ id }) => id !== attributeId)
      },
    }),
    generateExtraBackendReducers<AdministrationAttributesState, LoadingTypes, void, DeleteAttributeValueArgs>({
      promise: removeAttributeValue,
      loadingType: 'removeAttributeValue',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        const { attributeValueId, parentId } = action.meta.arg
        state.attributes = state.attributes.map((attr) => {
          if (attr.id === parentId) {
            attr.values = attr.values.filter(({ id }) => id !== attributeValueId)
          }
          return attr
        })
      },
    }),
  ]),
})

export const { showAttributeModal, hideAttributeModal } = administrationAttributesSlice.actions

export const administrationAttributesReducer = administrationAttributesSlice.reducer
