import { useSelector } from 'react-redux'
import { selectSelectedGroup } from 'redux/files/files-table/FilesTable.selectors'
import FilesGroupsTable from './groups/FilesGroupsTable'
import FilesFrontendFilesTable from './frontendfiles/FilesFrontendFilesTable'

const FilesTable: React.FC = () => {
  const selectedGroup = useSelector(selectSelectedGroup)
  if (!selectedGroup) return null
  const { groups, containFrontendFiles, frontendFiles } = selectedGroup
  return containFrontendFiles ? (
    <FilesFrontendFilesTable frontendFiles={frontendFiles} />
  ) : (
    <FilesGroupsTable groups={groups} />
  )
}

export default FilesTable
