import { suitabilityCodeText } from 'data/messages/misc'
import Text from 'components/atoms/text'
import trans from 'helpers/i18n.helpers'
import { Input } from 'antd'
import { SuitabilityCode } from 'models/administration'

const FileSuitabilityCodeItem: React.FC<{ code?: SuitabilityCode | null }> = ({ code }) => {
  return (
    <div>
      <Text textStyle="regularText-small" color="grayG08">
        {trans(suitabilityCodeText)}
      </Text>
      <Input disabled value={code ? `${code.code} - ${code.name}` : undefined} />
    </div>
  )
}

export default FileSuitabilityCodeItem
