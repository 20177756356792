import { Space } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { MessageErrorAlert } from 'components/atoms/error-alert'
import Icon from 'components/atoms/icon'
import Text from 'components/atoms/text'
import Button from 'components/molecules/button-element'
import Modal from 'components/molecules/modal/Modal'
import { UploadSingleFile } from 'components/organisms/upload/single-file'
import { handleApiErrors } from 'helpers/HttpError.helpers'
import useUpload from 'hooks/upload'
import { useAppDispatch } from 'redux/store'
import styled from 'styled-components'
import { setFileModalVisibility, importBackupFromFile } from 'redux/administration/administration-backups'
import { selectImportFromFileLoading } from 'redux/administration/administration-backups/AdministrationBackups.selectors'
import { t, defineMessage, Trans } from '@lingui/macro'
import { cancelText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 10px;
    > * > * {
      margin-left: ${({ theme }) => theme.sizes.xl};
    }
    > .ant-space .ant-space-item:nth-child(2) > * {
      margin: 0;
    }
  }
`

const errorDict = {
  'backup.tar_gz_file_required': defineMessage({
    id: 'administration.backups.modal.invalid_file_format_error',
    message: 'Invalid file format. Required format: tar.gz.',
  }),
  'backup.invalid_file': defineMessage({
    id: 'administration.backups.modal.invalid_file_error',
    message: 'Selected file is not a valid backup.',
  }),
  'backup.already_exists': defineMessage({
    id: 'administration.backups.modal.backup_already_exists_error',
    message: 'This backup already exists in the system.',
  }),
}

const FileModal: React.FC<{ visible: boolean }> = ({ visible }) => {
  const dispatch = useAppDispatch()
  const { file, beforeUpload, onRemove } = useUpload()
  const { isLoading, error } = useSelector(selectImportFromFileLoading)
  const handleSubmit = () => {
    if (file) {
      dispatch(importBackupFromFile(file))
    }
  }
  const handleCancel = () => dispatch(setFileModalVisibility(false))

  return (
    <StyledModal
      visible={visible}
      onCancel={handleCancel}
      maskClosable={false}
      title={
        <Text textStyle="header-H2b">
          <Trans id="administration.backups.modal.upload_backup_header">Import backup</Trans>
        </Text>
      }
      footer={
        <>
          <Button onClick={handleCancel} type="tertiary">
            {trans(cancelText)}
          </Button>
          <Button loading={isLoading} onClick={file ? handleSubmit : undefined} disabled={!file}>
            <Trans id="administration.backups.modal.import_button_text">Import</Trans>
          </Button>
        </>
      }
      closeIcon={
        <Icon>
          <CloseOutlined />
        </Icon>
      }
    >
      <Space direction="vertical">
        <Text textStyle="regularText-small">
          <Trans id="administration.backups.modal.select_backup_text">Select backup to import</Trans>
        </Text>
        <UploadSingleFile file={file} onRemove={onRemove} beforeUpload={beforeUpload} />
        {error !== undefined && (
          <MessageErrorAlert
            message={t({ id: 'administration.backups.modal.upload_error', message: 'Could not upload backup' })}
            description={handleApiErrors(errorDict, error.errors)}
          />
        )}
      </Space>
    </StyledModal>
  )
}

export default FileModal
