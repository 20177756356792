import { Discipline } from 'models/administration'
import { BackendLoading } from 'redux/redux.shared'

export const loadingTypes = ['fetchDisciplines', 'removeDiscipline', 'addDiscipline', 'editDiscipline'] as const
export type LoadingTypes = typeof loadingTypes[number]

export interface ModalData {
  isModalVisible: boolean
  discipline?: Discipline
}

export interface AdministrationDisciplinesState {
  loading: Record<LoadingTypes, BackendLoading>
  disciplines: Discipline[]
  modalData: ModalData
}
