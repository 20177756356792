import { useEffect } from 'react'
import ProjectConfig from 'components/organisms/project-config'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsAuthenticated } from 'redux/login/Login.selectors'
import { clearProjectDetailsState } from 'redux/project/project-details/ProjectDetails.slice'
import { selectProjectIsActive } from 'redux/project/project-details/ProjectDetails.selectors'
import { fetchProjectConfigFinishedSteps } from 'redux/project/project-config/status/ProjectStatusConfig.slice'
import useProjectDetailsRedirection from 'hooks/projectDetailsRedirection'
import useProjectId from 'hooks/useProjectId'

const ProjectConfigPage: React.FC = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const isProjectActive = useSelector(selectProjectIsActive)
  const dispatch = useDispatch()
  const projectId = useProjectId()
  const projectIdNumber = Number(projectId)

  useProjectDetailsRedirection(true)

  useEffect(() => {
    if (isAuthenticated && isProjectActive === false && projectIdNumber) {
      dispatch(fetchProjectConfigFinishedSteps(projectIdNumber))
    }
  }, [dispatch, isAuthenticated, isProjectActive, projectIdNumber])
  useEffect(() => {
    return () => {
      dispatch(clearProjectDetailsState())
    }
  }, [dispatch])

  return <ProjectConfig />
}

export default ProjectConfigPage
