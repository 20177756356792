import { User } from 'models/administration'
import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import { LoadingTypes, LoadUserError } from './AdministrationUsers.types'

export const selectAdministrationUsers: (state: RootState) => User[] = (state) => state.administration.users.users

export const selectAdministrationLoading: (state: RootState) => Record<LoadingTypes, BackendLoading> = (state) =>
  state.administration.users.loading

export const selectFetchingUsersLoading: (state: RootState) => BackendLoading = (state) =>
  selectAdministrationLoading(state).fetchUsers

export const selectEditingAdminLoading: (state: RootState) => BackendLoading = (state) =>
  selectAdministrationLoading(state).editUserAccount

export const selectEditingActivityLoading: (state: RootState) => BackendLoading = (state) =>
  selectAdministrationLoading(state).editAccountActivationField

export const selectAddingUsersLoading: (state: RootState) => BackendLoading = (state) =>
  selectAdministrationLoading(state).addUser

export const selectUserModalVisibility = (state: RootState): boolean =>
  state.administration.users.modalData.isModalVisible

export const selectEditedUser = (state: RootState): User | undefined => state.administration.users.modalData.user

export const selectFileModalVisibility: (state: RootState) => boolean = (state) =>
  state.administration.users.fileModalData.isVisible

export const selectFileInfoModalVisibility: (state: RootState) => boolean = (state) =>
  state.administration.users.fileModalData.isInfoVisible

export const selectLoadingUsersErrors: (state: RootState) => LoadUserError[] = (state) =>
  state.administration.users.fileModalData.loadErrors

export const selectAddingFromFileLoading: (state: RootState) => BackendLoading = (state) =>
  state.administration.users.loading.addUsersFromFile
