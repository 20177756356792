import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RcFile } from 'antd/lib/upload/interface'
import backendAxios from 'axios/axios'
import { FieldData } from 'models/components'
import {
  BackendLoading,
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
  getStateAsAny,
} from 'redux/redux.shared'
import { refreshEntries } from 'redux/files/files-documentation'
import { openDocumentationNotification } from 'helpers/Notifications.helpers'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import { t } from '@lingui/macro'
import { EntryInfoDto, VersionInfoDto } from '../Files.dto'

const loadingTypes = ['fetchNewEntryMetadataAndDisciplines', 'addNewEntry'] as const
type LoadingTypes = typeof loadingTypes[number]

export interface NewEntryAddingState {
  loading: Record<LoadingTypes, BackendLoading>
  isModalVisible: boolean
  disciplines: Discipline[]
  metadata: Metadata[]
  attributeFields: FieldData<number>[]
  isAttributesFormValid: boolean
  description?: string
  isDescriptionValid: boolean
  isAddingNextFile: boolean
}

export interface Discipline {
  code: string
  enabledMetadata: string[]
  enabledValues: string[]
  id: number
  name: string
}

export interface Metadata {
  id: number
  name: string
  values: {
    code: string
    id: number
    value: string
  }[]
}

export const fetchNewEntryMetadataAndDisciplines = createAsyncThunk(
  'newEntry/fetchNewEntryMetadataAndDisciplines',
  async (args, { getState }) => {
    const projectId = selectProjectId(getStateAsAny(getState))
    const disciplinesPromise = backendAxios.get(`/projects/${projectId}/disciplines/writable/`)
    const metadataPromise = backendAxios.get(`/projects/${projectId}/metadata/`)
    const responses = await Promise.all([disciplinesPromise, metadataPromise])
    return { disciplines: responses[0].data, metadata: responses[1].data }
  }
)
export const addNewEntry = createAsyncBackendThunk(
  'newEntry/addNewEntry',
  async (
    { file, routeToNewEntry }: { file: RcFile; routeToNewEntry: (entryId: number) => void },
    { getState, dispatch }
  ) => {
    const state = getStateAsAny(getState)
    const { description, attributeFields } = state.files.newEntry
    const discipline = attributeFields.find(
      (attr: FieldData<number>) =>
        attr.name === 'discipline' || (Array.isArray(attr.name) && attr.name[0] === 'discipline')
    ).value
    const metadataValues = attributeFields
      .filter(
        (attr: FieldData<number>) =>
          attr.name === 'discipline' || (Array.isArray(attr.name) && attr.name[0] !== 'discipline')
      )
      .map((attr: FieldData<number>) => ({ id: attr.value }))
    const type = 'DOC'
    const projectId = selectProjectId(state)
    const entryResponse = await backendAxios.post(`/projects/${projectId}/entries/`, {
      description,
      discipline,
      metadataValues,
      type,
      stage: null,
    })
    const entryInfo = entryResponse.data as EntryInfoDto
    const formData = new FormData()
    formData.append('file', file)
    const newVersionResponse = await backendAxios.post(`/entries/${entryInfo.id}/files/`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    const { id } = newVersionResponse.data as VersionInfoDto
    await dispatch(refreshEntries({ addedFileId: id }))
    routeToNewEntry(entryInfo.id)
    openDocumentationNotification('success', {
      message: t({ id: 'project.files.notifications.file_added', message: 'File has been added' }),
    })
  }
)

const fieldsToValidity = (prevVal: boolean, currentVal: FieldData<number>) => {
  return prevVal && currentVal.value !== undefined
}

const initialState = {
  isModalVisible: false,
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  disciplines: [],
  metadata: [],
  attributeFields: [],
  isAttributesFormValid: false,
  isDescriptionValid: false,
  description: undefined,
  isAddingNextFile: false,
} as NewEntryAddingState

const filesNewEntrySlice = createSlice({
  name: 'newEntry',
  initialState,
  reducers: {
    showNewEntryModal: (state) => {
      state.isModalVisible = true
    },
    hideNewEntryModal: () => initialState,
    changeNewEntryAttributeField: (state, action: PayloadAction<FieldData<number>>) => {
      const name = Array.isArray(action.payload.name) && action.payload.name?.[0]
      state.attributeFields = state.attributeFields.map((field) =>
        Array.isArray(field.name) && field.name?.[0] === name ? action.payload : field
      )
      state.isAttributesFormValid = state.attributeFields.reduce(fieldsToValidity, true)
    },
    setNewEntryDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload
      state.isDescriptionValid = action.payload !== undefined && action.payload !== ''
    },
    setAddingNextFile: (state, action: PayloadAction<boolean>) => {
      state.isAddingNextFile = action.payload
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<
      NewEntryAddingState,
      LoadingTypes,
      { disciplines: Discipline[]; metadata: Metadata[] }
    >({
      promise: fetchNewEntryMetadataAndDisciplines,
      loadingType: 'fetchNewEntryMetadataAndDisciplines',
      onFulfilled: (state, action) => {
        const { disciplines, metadata } = action.payload
        state.disciplines = disciplines
        state.metadata = metadata
        state.attributeFields = [{ name: ['discipline'] }, ...metadata.map(({ name }) => ({ name: [name] }))]
      },
    }),
    generateExtraBackendReducers<NewEntryAddingState, LoadingTypes>({
      promise: addNewEntry,
      loadingType: 'addNewEntry',
      onFulfilled: (state) => {
        if (!state.isAddingNextFile) {
          Object.assign(state, initialState)
        } else {
          state.description = undefined
          state.isDescriptionValid = false
        }
      },
    }),
  ]),
})

export const {
  showNewEntryModal,
  hideNewEntryModal,
  changeNewEntryAttributeField,
  setNewEntryDescription,
  setAddingNextFile,
} = filesNewEntrySlice.actions

export const filesNewEntryReducer = filesNewEntrySlice.reducer
