import { DeleteOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'
import Icon from 'components/atoms/icon'
import Text from 'components/atoms/text'
import FormSelect from 'components/molecules/form-item/FormSelect'
import themePxValueToNumber from 'helpers/Styles.helpers'
import styled from 'styled-components'

interface SelectEntryConfigProps {
  entryLevel: number
  onDeleteEntry?: () => void
}

interface StyledDivProps {
  $entryLevel: number
}

const StyledDiv = styled.div<StyledDivProps>`
  position: relative;
  & > *:first-child {
    margin-left: ${(props) => `${themePxValueToNumber(props.theme.sizes.default) * (props.$entryLevel - 1)}px`};
  }
  & > *:nth-child(2) {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translate(50%, -50%);
  }
`

const SelectEntryConfig: React.FC<React.ComponentProps<typeof FormSelect> & SelectEntryConfigProps> = ({
  onDeleteEntry,
  entryLevel,
  ...props
}) => {
  return (
    <StyledDiv $entryLevel={entryLevel}>
      <FormSelect
        label={
          <Text textStyle="regularText-medium" color="grayG06">
            <Trans id="project_config.entries_structure.table.content.level_text">Level {entryLevel}.:</Trans>
          </Text>
        }
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      {onDeleteEntry && (
        <Icon color="grayG06" onClick={onDeleteEntry}>
          <DeleteOutlined />
        </Icon>
      )}
    </StyledDiv>
  )
}

export default SelectEntryConfig
