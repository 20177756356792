import Checkbox from 'components/molecules/checkbox'
import { CellWrapper } from 'components/molecules/table/Table'
import { NamingSchemeElemenentArg } from 'models/administration'
import { AttributeType } from 'redux/administration/administration-naming-scheme/AdministrationNamingScheme.types'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

interface AdminProps {
  args?: NamingSchemeElemenentArg
  type: AttributeType
  disabled?: boolean
  onChange: (selected: boolean) => void
}

const FieldUsedInShortName: React.FC<AdminProps> = ({ args, type, disabled = false, onChange }) => {
  const isFieldDisabled = !['initiator', 'project', 'discipline', 'projectStage', 'metadata'].includes(type)
  return (
    <CellWrapper>
      <Checkbox
        checked={args?.inShortName === true}
        disabled={disabled || isFieldDisabled}
        onChange={(e: CheckboxChangeEvent) => onChange(e.target.checked)}
      />
    </CellWrapper>
  )
}

export default FieldUsedInShortName
