import { t } from '@lingui/macro'
import { Radio } from 'antd'
import TooltipElement from 'components/molecules/tooltip-element'
import { yesText } from 'data/messages/misc'
import trans from 'helpers/i18n.helpers'
import showConfirmationModal from 'helpers/Modals.helpers'
import { useDispatch } from 'react-redux'
import { setSchemeAsDefault } from 'redux/administration/administration-naming-scheme/AdministrationNamingScheme.slice'

interface NamingSchemeDefaultButtonProps {
  isDefault: boolean
  schemeId: number
  name: string
}

const NamingSchemeDefaultButton: React.FC<NamingSchemeDefaultButtonProps> = ({ isDefault, schemeId, name }) => {
  const dispatch = useDispatch()
  return (
    <TooltipElement
      title={t({ id: 'administration.naming_schemes.set_as_default_tooltip', message: 'Set this scheme as default' })}
      disabled={isDefault}
    >
      <Radio
        checked={isDefault}
        onChange={() => {
          if (!isDefault) {
            showConfirmationModal({
              okText: trans(yesText),
              content: t({
                id: 'administration.naming_schemes.set_as_default_confirmation_text',
                message: `Set scheme "${name}" as default?`,
              }),
              onOk: () => dispatch(setSchemeAsDefault(schemeId)),
            })
          }
        }}
      />
    </TooltipElement>
  )
}

export default NamingSchemeDefaultButton
