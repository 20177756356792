import { Radio, Space } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import { noText, yesText } from 'data/messages/misc'
import trans from 'helpers/i18n.helpers'

interface YesNoRadioGroupProps {
  value?: boolean
  onChange?: (e: RadioChangeEvent) => void
  disableYesRadio?: boolean
  disableNoRadio?: boolean
}

const YesNoRadioGroup: React.FC<YesNoRadioGroupProps> = ({ value, onChange, disableYesRadio, disableNoRadio }) => {
  return (
    <Radio.Group value={value} onChange={onChange}>
      <Space direction="vertical">
        <Radio value disabled={disableYesRadio}>
          {trans(yesText)}
        </Radio>
        <Radio value={false} disabled={disableNoRadio}>
          {trans(noText)}
        </Radio>
      </Space>
    </Radio.Group>
  )
}

export default YesNoRadioGroup
