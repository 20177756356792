import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import { SuitabilityCode } from 'models/administration'
import { FlowDecisionConfig, ReviewerSelectionMethods, ReviewersUsersTypes, ReviewTypes } from 'models/flow'
import { Member } from 'models/project/projectConfig'
import {
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
  getStateAsAny,
} from 'redux/redux.shared'
import { selectDecidionsFlowConfig } from './FlowDecisionsConfig.selectors'
import { FlowDecisionsConfigState, loadingType, LoadingType } from './FlowDecisionsConfig.types'

export const fetchSuitabilityOverview = createAsyncBackendThunk('fetchSuitabilityOverview', async () => {
  return (await backendAxios.get('/suitability/overview/')).data
})

export const fetchProjectMembers = createAsyncBackendThunk('fetchProjectMembers', async (projectId: number) => {
  return (await backendAxios.get(`/projects/${projectId}/members/`)).data
})

export const getFlowDecisions = createAsyncBackendThunk(
  'flowDecisionsConfig/getFlowDecisions',
  async ({ projectId, flowId }: { projectId: number; flowId: number }) => {
    return (await backendAxios.get(`/projects/${projectId}/flows/config/${flowId}/decisions/`)).data
  }
)

export const updateFlowDecisions = createAsyncBackendThunk(
  'flowDecisionsConfig/updateFlowDecisions',
  async (args: { projectId: number; flowId: number }, { getState }) => {
    const state = getStateAsAny(getState)
    const flowDecisionsConfig = selectDecidionsFlowConfig(state)
    await backendAxios.patch(`/projects/${args.projectId}/flows/config/${args.flowId}/decisions/`, flowDecisionsConfig)
  }
)

const emptyDecision = {
  reviewUsersList: [],
  reviewRequired: false,
  reviewersUsersType: ReviewersUsersTypes.CHECKING_USER,
  reviewType: ReviewTypes.SINGULAR_REVIEWER,
  reviewerSelectionMethod: ReviewerSelectionMethods.SELECTED,
} as FlowDecisionConfig

const initialState: FlowDecisionsConfigState = {
  loading: generateInitialLoadingState<LoadingType>(loadingType),
  flowDecisionConfig: emptyDecision,
  codes: [],
  members: [],
}

const flowDecisionsConfigSlice = createSlice({
  name: 'flowDecisionsConfig',
  initialState,
  reducers: {
    setDecisionFlowValues: (state, action: PayloadAction<FlowDecisionConfig>) => {
      state.flowDecisionConfig = { ...state.flowDecisionConfig, ...action.payload }
      if (state.flowDecisionConfig.reviewRequired) {
        state.flowDecisionConfig.reviewType = state.flowDecisionConfig.reviewType || ReviewTypes.SINGULAR_REVIEWER
        state.flowDecisionConfig.reviewerSelectionMethod =
          state.flowDecisionConfig.reviewerSelectionMethod || ReviewerSelectionMethods.SELECTED
        state.flowDecisionConfig.reviewersUsersType =
          state.flowDecisionConfig.reviewersUsersType || ReviewersUsersTypes.CHECKING_USER
      } else {
        state.flowDecisionConfig.reviewType = undefined
        state.flowDecisionConfig.reviewerSelectionMethod = undefined
        state.flowDecisionConfig.reviewersUsersType = undefined
        state.flowDecisionConfig.reviewUsersList = []
      }
    },
    setReviewUsersList: (state, action: PayloadAction<number[]>) => {
      state.flowDecisionConfig.reviewUsersList = action.payload
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<FlowDecisionsConfigState, LoadingType, FlowDecisionConfig>({
      promise: getFlowDecisions,
      loadingType: 'getFlowDecisions',
      onFulfilled: (state, action) => {
        state.flowDecisionConfig = action.payload
      },
    }),
    generateExtraBackendReducers<FlowDecisionsConfigState, LoadingType, Member[]>({
      promise: fetchProjectMembers,
      loadingType: 'fetchProjectMembers',
      onFulfilled: (state, action) => {
        state.members = action.payload
      },
    }),
    generateExtraBackendReducers<FlowDecisionsConfigState, LoadingType>({
      promise: updateFlowDecisions,
      loadingType: 'updateFlowDecisions',
    }),
    generateExtraBackendReducers<FlowDecisionsConfigState, LoadingType, SuitabilityCode[]>({
      promise: fetchSuitabilityOverview,
      loadingType: 'fetchSuitabilityOverview',
      onFulfilled: (state, action) => {
        state.codes = action.payload
      },
    }),
  ]),
})

export const { setDecisionFlowValues, setReviewUsersList } = flowDecisionsConfigSlice.actions

export const flowDecisionsConfigReducer = flowDecisionsConfigSlice.reducer
