import SelectElement from 'components/atoms/select-element'
import { selectText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import { selectFlowNotUsedAttributes } from 'redux/flow-conifg/attributes/FlowAttributesConfig.selectors'
import { useDispatch, useSelector } from 'react-redux'
import { setIsAdding, setAttributeSelected } from 'redux/flow-conifg/attributes/FlowAttributesConfig.slice'

interface AttributeNameProps {
  name: string
  id?: number
}

const AttributeName: React.FC<AttributeNameProps> = ({ id, name }) => {
  const dispatch = useDispatch()
  const availableAttributes = useSelector(selectFlowNotUsedAttributes)
  if (id !== undefined) {
    return <div>{name}</div>
  }
  return (
    <SelectElement
      bordered={false}
      placeholder={trans(selectText)}
      options={availableAttributes
        .filter(({ id: attrId }) => attrId !== undefined)
        .map(({ name: attrName, id: attrId }) => ({ label: attrName, value: attrId || -1 }))}
      onChange={(val) => {
        dispatch(setAttributeSelected({ attributeId: Number(val), selected: true }))
        dispatch(setIsAdding(false))
      }}
    />
  )
}

export default AttributeName
