import Text from 'components/atoms/text'
import { t, Trans } from '@lingui/macro'
import { Checkbox, Form } from 'antd'
import FormInput from 'components/molecules/form-item/FormInput'
import { nameInputRules } from 'helpers/Validators.helpers'
import { FormInstance } from 'antd/lib/form'
import { Attribute } from 'models/administration'
import DividerElement from 'components/atoms/divider'
import ManageValueAndNameFields from 'components/molecules/code-form-field/ManageValueAndNameFields'
import CodeLengthEdit from 'components/molecules/code-length'
import { GetFormItemErrors } from 'hooks/useFormValidity'

function getFormInitialValues(editedAttribute: Attribute | undefined) {
  return {
    name: editedAttribute?.name,
    values: editedAttribute?.values,
    valueCodeLength: editedAttribute?.valueCodeLength || 1,
    projectValuesAllowed: editedAttribute?.projectValuesAllowed,
  }
}

interface AttributeModalFormProps {
  form: FormInstance
  attribute?: Attribute
  validate: () => void
  getFormItemErrors: GetFormItemErrors
}

const codeFormItemName = 'valueCodeLength'

const AttributeModalForm: React.FC<AttributeModalFormProps> = ({ form, attribute, validate, getFormItemErrors }) => {
  const initialValues = getFormInitialValues(attribute)
  const codeLength = form.getFieldValue(codeFormItemName)
  const validateFormFields = () => form.validateFields()
  return (
    <Form form={form} initialValues={initialValues} onFieldsChange={validate}>
      <div style={{ maxWidth: '500px' }}>
        <FormInput
          label={<Trans id="administration.attributes.modal.name_field_label">Name</Trans>}
          name="name"
          placeholder={t({ id: 'administration.attributes.modal.name_field_placeholder', message: 'Enter name' })}
          rules={nameInputRules()}
          getErrors={getFormItemErrors}
        />
      </div>
      <Form.Item name="projectValuesAllowed" valuePropName="checked">
        <Checkbox>
          <Text textStyle="regularText-small">
            <Trans id="administration.attributes.modal.project_values_allowed_checkbox_text">
              Project admins can add values available in projects
            </Trans>
          </Text>
        </Checkbox>
      </Form.Item>
      <DividerElement />
      <CodeLengthEdit
        currentLength={initialValues.valueCodeLength}
        valueName={codeFormItemName}
        bestValues={{ bestMin: 1, bestMax: 3 }}
        withWarning
        getErrors={getFormItemErrors}
      />
      <DividerElement />
      <ManageValueAndNameFields
        formListName="values"
        isDisabled={false}
        valueFieldName="value"
        deletable={attribute?.values.map((val) => !val.isUsed) || []}
        codeLength={codeLength}
        codeFormItemName={codeFormItemName}
        onAddValue={validateFormFields}
        onRemoveValue={validateFormFields}
      />
    </Form>
  )
}

export default AttributeModalForm
