import styled from 'styled-components'

interface MainNavBarItemProps {
  isActive?: boolean
}

interface StyledLiProps {
  $isActive: MainNavBarItemProps['isActive']
}

const StyledLi = styled.li<StyledLiProps>`
  position: relative;
  padding: ${(props) => props.theme.sizes.default} ${(props) => props.theme.sizes.xs};
  transition: ${(props) => props.theme.transitions.default};
  background-color: ${(props) => (props.$isActive ? props.theme.colors.primaryP01 : 'transparent')};
  > * {
    cursor: pointer;
  }
`

const MainNavBarItem: React.FC<MainNavBarItemProps> = ({ children, isActive }) => {
  return <StyledLi $isActive={isActive}>{children}</StyledLi>
}

export default MainNavBarItem
