import backendAxios from 'axios/axios'
import {
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
  getStateAsAny,
} from 'redux/redux.shared'
import { createSlice } from '@reduxjs/toolkit'
import { selectProjectIsActive } from 'redux/project/project-details/ProjectDetails.selectors'
import { loadingTypes, LoadingTypes, ProjectStatusConfigState, Step } from './ProjectStatusConfig.types'

export const fetchProjectConfigFinishedSteps = createAsyncBackendThunk(
  'fetchProjectConfigFinishedSteps',
  async (projectId: number) => {
    return (await backendAxios.get(`/projects/${projectId}/config-status/`)).data
  }
)

export const addProjectConfigFinishedStep = createAsyncBackendThunk(
  'addProjectConfigFinishedStep',
  async ({ projectId, step }: { projectId: number; step: Step }, { getState }) => {
    const state = getStateAsAny(getState)
    const isProjectActive = selectProjectIsActive(state)
    if (isProjectActive) return []
    return (await backendAxios.post(`/projects/${projectId}/config-status/`, { step })).data
  }
)

const initialState: ProjectStatusConfigState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  finishedSteps: [],
  finishedStepsLoaded: false,
}

const projectStatusConfigSlice = createSlice({
  name: 'projectStatus',
  initialState,
  reducers: {},
  extraReducers: combine([
    generateExtraBackendReducers<ProjectStatusConfigState, LoadingTypes, Step[]>({
      promise: fetchProjectConfigFinishedSteps,
      loadingType: 'fetchProjectConfigFinishedSteps',
      onFulfilled: (state, action) => {
        state.finishedSteps = action.payload
        state.finishedStepsLoaded = true
      },
    }),
    generateExtraBackendReducers<ProjectStatusConfigState, LoadingTypes, Step[]>({
      promise: addProjectConfigFinishedStep,
      loadingType: 'addProjectConfigFinishedStep',
      onFulfilled: (state, action) => {
        state.finishedSteps = action.payload
      },
    }),
  ]),
})

export const projectStatusConfigReducer = projectStatusConfigSlice.reducer
