import { Menu } from 'antd'
import React from 'react'
import styled from 'styled-components'
import Text from 'components/atoms/text'
import menuItemMixin from './FilesEntryMenu.styles'

const { SubMenu } = Menu

const StyledSubMenu = styled(SubMenu)`
  &.ant-menu-submenu {
    ${menuItemMixin}
    .ant-menu-submenu-title {
      height: auto;
      line-height: inherit;
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      margin-top: 0;
      margin-bottom: 0;
      > *:first-child {
        margin-right: ${(props) => props.theme.sizes.xs};
      }
      .ant-menu-submenu-arrow {
        right: 0;
        color: ${(props) => props.theme.colors.grayG06};
      }
    }
  }
`

const FilesEntrySubMenu: React.FC<React.ComponentProps<typeof SubMenu>> = ({
  children,
  title,
  disabled,
  ...otherProps
}) => {
  const textColor = disabled ? 'grayG04' : 'grayG07'
  return (
    <StyledSubMenu
      disabled={disabled}
      /* eslint-disable react/jsx-props-no-spreading */
      {...otherProps}
      title={
        <Text color={textColor} textStyle="regularText-small">
          {title}
        </Text>
      }
    >
      {children}
    </StyledSubMenu>
  )
}

export default FilesEntrySubMenu
