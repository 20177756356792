import { ProjectAdmin } from 'models/administration'
import Text from 'components/atoms/text'
import FoldableList from 'components/atoms/list'

interface ProjectAdminsProps {
  admins: ProjectAdmin[]
  isExpanded?: boolean
  numberOfAdminsDisplayedInNotExpanded?: number
}

const DisplayProjectAdmins: React.FC<ProjectAdminsProps> = ({
  admins,
  isExpanded,
  numberOfAdminsDisplayedInNotExpanded = 2,
}) => {
  return (
    <FoldableList
      numberOfItemsIfFolded={numberOfAdminsDisplayedInNotExpanded}
      isFolded={!isExpanded}
      items={admins}
      renderItem={({ name }: ProjectAdmin) => (
        <Text color="baseBlackish" textStyle="regularText-small">
          {name}
        </Text>
      )}
    />
  )
}

export default DisplayProjectAdmins
