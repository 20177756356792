import { useSelector, useDispatch } from 'react-redux'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import CheckboxElement from 'components/molecules/checkbox'
import { selectFilesControlsShowAllVersions } from 'redux/files/files-controls/FilesControls.selectors'
import { setShowAllVersions } from 'redux/files/files-controls'
import { Trans } from '@lingui/macro'

const FilesControls: React.FC = () => {
  const dispatch = useDispatch()
  const showAllVersions = useSelector(selectFilesControlsShowAllVersions)
  const handleVersionsChange = (e: CheckboxChangeEvent): void | undefined => {
    dispatch(setShowAllVersions(e.target.checked))
  }
  return (
    <>
      <CheckboxElement
        checked={showAllVersions}
        onChange={handleVersionsChange}
        textStyle="regularText-small"
        color="grayG07"
      >
        <Trans id="project.files.options.show_all_file_revisions">Show all revisions of files</Trans>
      </CheckboxElement>
    </>
  )
}

export default FilesControls
