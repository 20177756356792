import { Spin } from 'antd'
import { SpinProps } from 'antd/lib/spin'
import useLoading from 'hooks/loading'
import { useEffect, useRef } from 'react'
import styled from 'styled-components'

interface WithClickLoadingWrapperProps {
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

interface StyledDivProps {
  $clickable: boolean
}

const StyledDiv = styled.div<StyledDivProps>`
  ${(props) => (props.$clickable ? 'cursor: pointer; color: blue6' : '')}
`

const WithClickLoadingWrapper: React.FC<WithClickLoadingWrapperProps & SpinProps> = ({ children, onClick, size }) => {
  const { isLoading, functionWithLoading } = useLoading(onClick)
  const isMounted = useRef(true)
  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])
  return (
    <Spin spinning={isMounted.current && isLoading} size={size}>
      <StyledDiv onClick={onClick ? functionWithLoading : undefined} $clickable={!!onClick}>
        {children}
      </StyledDiv>
    </Spin>
  )
}

export default WithClickLoadingWrapper
