import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface FilesControlsState {
  isVisible: boolean
  showAllVersions: boolean
}

const filesControlsSlice = createSlice({
  name: 'controls',
  initialState: {
    isVisible: false,
  } as FilesControlsState,
  reducers: {
    setControlsVisibility: (state, action: PayloadAction<boolean>) => {
      state.isVisible = action.payload
    },
    setShowAllVersions: (state, action: PayloadAction<boolean>) => {
      state.showAllVersions = action.payload
    },
  },
})

export const { setControlsVisibility, setShowAllVersions } = filesControlsSlice.actions

export const filesControlsReducer = filesControlsSlice.reducer
