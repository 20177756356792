import { createSlice } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import { removedErrorsFromLoading } from 'helpers/HttpError.helpers'
import { SuitabilityCode, SuitabilityCodeWithDetails } from 'models/administration'
import { openSuccessNotification } from 'helpers/Notifications.helpers'
import {
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
} from 'redux/redux.shared'
import { t } from '@lingui/macro'
import { loadingTypes, LoadingTypes, AdministrationSuitabilityState } from './AdministrationSuitability.types'

export const fetchSuitabilityCodes = createAsyncBackendThunk('fetchSuitabilityCodes', async () => {
  return (await backendAxios.get('/suitability/')).data
})

export const createSuitabilityCode = createAsyncBackendThunk('createSuitabilityCode', async (code: SuitabilityCode) => {
  const response = await backendAxios.post('/suitability/', code)
  openSuccessNotification({
    message: t({
      id: 'administration.suitability.notifications.added_text',
      message: 'Suitability code has been added',
    }),
  })
  return response.data
})

const initialState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  codes: [],
  selectedStatus: 'ALL',
  modalData: { isModalVisible: false },
} as AdministrationSuitabilityState

const administrationSuitabilitySlice = createSlice({
  name: 'administrationSuitability',
  initialState,
  reducers: {
    removeErrors: (state) => {
      state.loading = removedErrorsFromLoading(state.loading)
    },
    showSuitabilityCodeModal: (state) => {
      state.modalData.isModalVisible = true
    },
    hideSuitabilityCodeModal: (state) => {
      state.modalData.isModalVisible = false
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<AdministrationSuitabilityState, LoadingTypes, SuitabilityCodeWithDetails[]>({
      promise: fetchSuitabilityCodes,
      loadingType: 'fetchSuitabilityCodes',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        state.codes = action.payload
      },
    }),
    generateExtraBackendReducers<AdministrationSuitabilityState, LoadingTypes, SuitabilityCodeWithDetails>({
      promise: createSuitabilityCode,
      loadingType: 'createSuitabilityCode',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        if (!action.payload.isDefault) {
          state.codes.push(action.payload)
        } else {
          state.codes = [...state.codes.map((code) => ({ ...code, isDefault: false })), action.payload]
        }
      },
    }),
  ]),
})

export const {
  removeErrors,
  showSuitabilityCodeModal,
  hideSuitabilityCodeModal,
} = administrationSuitabilitySlice.actions

export const administrationSuitabilityReducer = administrationSuitabilitySlice.reducer
