import { t, Trans } from '@lingui/macro'
import { DatePicker, Radio, Space } from 'antd'
import { RadioGroupProps } from 'antd/lib/radio'
import Text from 'components/atoms/text'
import { selectText } from 'data/messages/controls'
import { range } from 'helpers/Collection.helpers'
import { addTimezoneInfo, datetimeToMoment } from 'helpers/Dates.helpers'
import trans from 'helpers/i18n.helpers'
import { ProjectConfigCompletionTypes } from 'models/project/projectConfig'
import moment, { Moment } from 'moment'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { setConfigCompletionType } from 'redux/project/project-config/activation'
import {
  selectConfigCompletionType,
  selectNewProjectActivationTimestamp,
  selectProjectScheduledActivationTimestamp,
} from 'redux/project/project-config/activation/ProjectActivationConfig.selectors'
import {
  fetchProjectActivation,
  setNewProjectActivationTime,
} from 'redux/project/project-config/activation/ProjectActivationConfig.slice'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import { useAppDispatch } from 'redux/store'
import ProjectActivationConfigAlert from './ProjectActivationConfigAlert'

const getActivationMoment: (
  originalValue: string | undefined,
  currentValue: string | undefined
) => Moment | undefined = (originalValue, currentValue) => {
  const value = currentValue || originalValue
  return value !== undefined ? datetimeToMoment(value) : undefined
}

const getDisabledDate: (date: Moment) => boolean = (date) => {
  return date.isBefore(moment(), 'day')
}

const getDisabledTime = (date: Moment | null) => {
  const currentDate = moment()
  if (
    date &&
    date.year() === currentDate.year() &&
    date.month() === currentDate.month() &&
    date.date() === currentDate.date()
  ) {
    return {
      disabledHours: () => range(currentDate.hour()),
      disabledMinutes: () => (date.hour() === currentDate.hour() ? range(currentDate.minute() + 1) : []),
    }
  }
  return {}
}

const ProjectActivationConfigContent: React.FC = () => {
  const dispatch = useAppDispatch()
  const configCompletionType = useSelector(selectConfigCompletionType)
  const projectId = useSelector(selectProjectId)

  const scheduledActivationTimestamp = useSelector(selectProjectScheduledActivationTimestamp)
  const currentActivationTimestamp = useSelector(selectNewProjectActivationTimestamp)

  const handleChange: RadioGroupProps['onChange'] = (e) => {
    dispatch(setConfigCompletionType(e.target.value))
  }
  useEffect(() => {
    if (projectId) dispatch(fetchProjectActivation(projectId))
  }, [dispatch, projectId])

  const radiosData = [
    {
      value: ProjectConfigCompletionTypes.SAVE_WITHOUT_RUNNING,
      text: t({
        id: 'project_config.activation.radios.without_run',
        message: 'Save configuration without running the project',
      }),
    },
    {
      value: ProjectConfigCompletionTypes.RUN_IMMEDIATELY,
      text: t({ id: 'project_config.activation.radios.now', message: 'Run now' }),
    },
    {
      value: ProjectConfigCompletionTypes.RUN_AT_CUSTOM_TIME,
      text: t({ id: 'project_config.activation.radios.custom_time', message: 'Run at the given time:' }),
      content: (
        <DatePicker
          style={{ width: 200 }}
          onChange={(val, ds) => {
            dispatch(setNewProjectActivationTime(addTimezoneInfo(ds)))
          }}
          disabledDate={getDisabledDate}
          disabledTime={getDisabledTime}
          value={getActivationMoment(scheduledActivationTimestamp, currentActivationTimestamp)}
          format="YYYY-MM-DD HH:mm"
          placeholder={trans(selectText)}
          showTime
          showNow={false}
          disabled={configCompletionType !== ProjectConfigCompletionTypes.RUN_AT_CUSTOM_TIME}
          allowClear={false}
        />
      ),
    },
  ]

  return (
    <Space direction="vertical" size="large">
      <Text textStyle="regularText-medium">
        <Trans id="project_config.activation.step_header">Project activation</Trans>
      </Text>
      <Radio.Group onChange={handleChange} value={configCompletionType}>
        <Space direction="vertical" size="middle">
          {radiosData.map(({ value, text, content }) => {
            return (
              <Space size="small" key="value">
                <Radio value={value}>
                  <Text textStyle="regularText-small">{text}</Text>
                </Radio>
                {content}
              </Space>
            )
          })}
        </Space>
      </Radio.Group>
      <ProjectActivationConfigAlert />
    </Space>
  )
}

export default ProjectActivationConfigContent
