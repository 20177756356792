import NameAndId from 'models/shared'
import Text from 'components/atoms/text'
import { t } from '@lingui/macro'
import FoldableList from 'components/atoms/list'
import styled from 'styled-components'

interface DisciplinesColumnProps {
  disciplines: NameAndId[]
  isExpanded: boolean
}

const StyledDiv = styled.div`
  max-width: 95%;
`

const DisciplinesColumn: React.FC<DisciplinesColumnProps> = ({ disciplines, isExpanded }) => {
  if (disciplines.length === 0) {
    return (
      <Text color="grayG03" textStyle="regularText-small">
        {t({ id: 'administration.users.no_disciplines_text', message: 'No disciplines' })}
      </Text>
    )
  }
  return (
    <FoldableList
      numberOfItemsIfFolded={2}
      isFolded={!isExpanded}
      items={disciplines}
      renderItem={({ name }: NameAndId) => (
        <StyledDiv>
          <Text paragraph={isExpanded} center={!isExpanded} color="baseBlackish" textStyle="regularText-small">
            {name}
          </Text>
        </StyledDiv>
      )}
    />
  )
}

export default DisciplinesColumn
