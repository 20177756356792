import { Form, Space } from 'antd'
import { FlowDecisionConfig } from 'models/flow'
import styled from 'styled-components'
import ReviewersSelectionMethodRadio from './form-items/ReviewersSelectionMethodRadio'
import ReviewersUsersTypeRadio from './form-items/ReviewersUsersTypeRadio'
import ReviewRequiredRadio from './form-items/ReviewRequiredRadio'
import ReviewTypeRadio from './form-items/ReviewTypeRadio'
import SuitabilityCodesSelect from './form-items/SuitabilityCodesSelect'

interface FlowGeneralConfigFormProps {
  flow: FlowDecisionConfig
}

const StyledSpace = styled(Space)`
  width: 428px;
`
const FlowDecisionsConfigForm: React.FC<FlowGeneralConfigFormProps> = ({ flow }) => {
  return (
    <Form layout="vertical" initialValues={{ ...flow, afterInitSuitabilityCode: flow.afterInitSuitabilityCode ?? -1 }}>
      <StyledSpace direction="vertical" size="large">
        <SuitabilityCodesSelect flow={flow} />
        <ReviewRequiredRadio flow={flow} />
        {flow.reviewRequired && (
          <Space direction="vertical">
            <ReviewersUsersTypeRadio flow={flow} />
            <ReviewTypeRadio flow={flow} />
            <ReviewersSelectionMethodRadio flow={flow} />
          </Space>
        )}
      </StyledSpace>
    </Form>
  )
}

export default FlowDecisionsConfigForm
