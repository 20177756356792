import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import administrationOptions from 'components/organisms/administration/administrationOptions'

interface AdministrationMenuState {
  selectedKey: string
}

const initialState = {
  selectedKey: administrationOptions[0].key,
} as AdministrationMenuState

const administrationMenuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setSelectedAdministrationMenuKey: (state, action: PayloadAction<string>) => {
      state.selectedKey = action.payload
    },
  },
})

export const { setSelectedAdministrationMenuKey } = administrationMenuSlice.actions

export const administrationMenuReducer = administrationMenuSlice.reducer
