import { t } from '@lingui/macro'
import ErrorAlert from 'components/atoms/error-alert'
import { useSelector } from 'react-redux'
import { selectFetchDisciplinesLoading } from 'redux/project/project-config/disciplines/ProjectDisciplinesConfig.selectors'

const ProjectDisciplinesConfigAlerts: React.FC = () => {
  const fetchDisciplinesLoading = useSelector(selectFetchDisciplinesLoading)
  const alertMessageDict = [
    {
      error: fetchDisciplinesLoading.error,
      message: t({
        id: 'project_config.attributes.alert.disciplines_fetch_failed',
        message: 'Could not retrieve disciplines.',
      }),
    },
  ]
  return <ErrorAlert alertMessageDict={alertMessageDict} />
}

export default ProjectDisciplinesConfigAlerts
