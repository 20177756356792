import { Select } from 'antd'
import SelectElement from 'components/atoms/select-element'
import { useSelector, useDispatch } from 'react-redux'
import { selectCurrentLanguage } from 'redux/select-language/Language.selectors'
import styled from 'styled-components'
import Text from 'components/atoms/text'
import { Language, localesLabels } from 'data/i18n'
import { setLanguage } from 'redux/select-language/Language.slice'

const { Option } = Select

const StyledSelect = styled(SelectElement)`
  min-width: 80px;
  .ant-select-selection-item {
    justify-content: flex-end;
  }
  .ant-select-arrow {
    color: ${(props) => props.theme.colors.grayG08};
  }
  :hover {
    background-color: ${(props) => props.theme.colors.baseBackground};
  }
  &.ant-select-open {
    background-color: ${(props) => props.theme.colors.primaryP01};
  }
`

const options = Object.values(localesLabels)

const SelectLanguage: React.FC = () => {
  const dispatch = useDispatch()
  const currentLanguage = useSelector(selectCurrentLanguage)
  return (
    <StyledSelect
      bordered={false}
      value={currentLanguage}
      onChange={(value) => dispatch(setLanguage({ language: value as Language }))}
    >
      {options.map(({ label, value }) => (
        <Option value={value} key={value}>
          <Text textStyle="regularText-smallSemiBold" color="grayG08">
            {label}
          </Text>
        </Option>
      ))}
    </StyledSelect>
  )
}

export default SelectLanguage
