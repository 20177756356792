import { FileSuitabilityCode } from 'models/files'
import Text from 'components/atoms/text'
import trans from 'helpers/i18n.helpers'
import { noSuitabilityCodeText } from 'data/messages/files'
import FilesTableCellItem from './FilesTableCellItem'

interface FilesTableCellSuitabilityCodeProps {
  suitabilityCode: FileSuitabilityCode | null
  bold: boolean
}

const FilesTableCellSuitabilityCode: React.FC<FilesTableCellSuitabilityCodeProps> = ({ suitabilityCode, bold }) => {
  if (!suitabilityCode) {
    return (
      <Text textStyle="regularText-small" color="grayG04">
        {trans(noSuitabilityCodeText)}
      </Text>
    )
  }
  return <FilesTableCellItem text={suitabilityCode.code} tooltip={suitabilityCode.name} bold={bold} abbreviated />
}

export default FilesTableCellSuitabilityCode
