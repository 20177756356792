import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import { findFieldValueByName } from 'helpers/Collection.helpers'
import { SuitabilityCode } from 'models/administration'
import { ConsequencesType, FlowConsequence, FlowSettingsFields } from 'models/flow'
import {
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
  getStateAsAny,
} from 'redux/redux.shared'
import { selectConsequencesFlowConfig } from './FlowConsequencesConfig.selectors'
import { FlowConsequencesConfigState, loadingType, LoadingType } from './FlowConsequencesConfig.types'

export const fetchSuitabilityOverview = createAsyncBackendThunk('fetchSuitabilityOverview', async () => {
  return (await backendAxios.get('/suitability/overview/')).data
})

export const getFlowConsequences = createAsyncBackendThunk(
  'flowConsequencesConfig/getFlowConsequences',
  async ({ projectId, flowId }: { projectId: number; flowId: number }) => {
    const consequences = await backendAxios.get(`/projects/${projectId}/flows/config/${flowId}/consequences/`)
    return consequences.data as FlowConsequence[]
  }
)

export const updateFlowConsequences = createAsyncBackendThunk(
  'flowConsequencesConfig/updateFlowConsequences',
  async (args: { projectId: number; flowId: number }, { getState }) => {
    const state = getStateAsAny(getState)
    const flowConsequencesConfig = selectConsequencesFlowConfig(state)
    const consequences = flowConsequencesConfig.onFinished.map((consequence) => {
      const followingCdeStatus = findFieldValueByName(consequence, 'followingCdeStatus')
      const followingSuitabilityCode = findFieldValueByName(consequence, 'followingSuitabilityCode')
      return {
        followingCdeStatus: followingCdeStatus === -1 ? null : followingCdeStatus,
        followingSuitabilityCode: followingSuitabilityCode === -1 ? null : followingSuitabilityCode,
        fileUploadRequired: findFieldValueByName(consequence, 'fileUploadRequired'),
        consequenceType: findFieldValueByName(consequence, 'consequenceType'),
      } as FlowConsequence
    })
    await backendAxios.patch(`/projects/${args.projectId}/flows/config/${args.flowId}/consequences/`, consequences)
  }
)

interface ChangeConsequence {
  type: ConsequencesType
  fields: FlowSettingsFields
}

const initialState: FlowConsequencesConfigState = {
  loading: generateInitialLoadingState<LoadingType>(loadingType),
  flowConsequencesConfig: {
    onFinished: [],
  },
  codes: [],
}

const flowConsequencesConfigSlice = createSlice({
  name: 'flowConsequencesConfig',
  initialState,
  reducers: {
    addEmptyConsequence: (state, action: PayloadAction<ConsequencesType>) => {
      const consequence = [
        { name: 'consequenceType', value: action.payload },
        { name: 'fileUploadRequired', value: false },
        { name: 'followingCdeStatus', value: -1 },
        { name: 'followingSuitabilityCode', value: -1 },
      ] as FlowSettingsFields
      state.flowConsequencesConfig.onFinished.push(consequence)
    },
    setConsequenceFlowValues: (state: FlowConsequencesConfigState, action: PayloadAction<ChangeConsequence>) => {
      const { fields, type } = action.payload
      state.flowConsequencesConfig.onFinished = state.flowConsequencesConfig.onFinished.map((consequence) => {
        if (findFieldValueByName(consequence, 'consequenceType') === type) {
          return fields
        }
        return consequence
      })
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<FlowConsequencesConfigState, LoadingType, FlowConsequence[]>({
      promise: getFlowConsequences,
      loadingType: 'getFlowConsequences',
      onFulfilled: (state, action) => {
        const onFinished = action.payload
        state.flowConsequencesConfig.onFinished = onFinished.map((consequence) => [
          { name: 'consequenceType', value: consequence.consequenceType },
          { name: 'fileUploadRequired', value: consequence.fileUploadRequired },
          { name: 'followingCdeStatus', value: consequence.followingCdeStatus ?? -1 },
          { name: 'followingSuitabilityCode', value: consequence.followingSuitabilityCode ?? -1 },
        ])
      },
    }),
    generateExtraBackendReducers<FlowConsequencesConfigState, LoadingType>({
      promise: updateFlowConsequences,
      loadingType: 'updateFlowConsequences',
    }),
    generateExtraBackendReducers<FlowConsequencesConfigState, LoadingType, SuitabilityCode[]>({
      promise: fetchSuitabilityOverview,
      loadingType: 'fetchSuitabilityOverview',
      onFulfilled: (state, action) => {
        state.codes = action.payload
      },
    }),
  ]),
})

export const { setConsequenceFlowValues, addEmptyConsequence } = flowConsequencesConfigSlice.actions

export const flowConsequencesConfigReducer = flowConsequencesConfigSlice.reducer
