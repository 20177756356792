import { defineMessage, t } from '@lingui/macro'
import { Space } from 'antd'
import Text from 'components/atoms/text'
import { flowStatuses } from 'data/flow/status'
import cdeStatusDict from 'data/cdeStatus'
import { preparePath } from 'helpers/Files.helpers'
import { Notification } from 'models/notifications'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { setInitialFileChecked } from 'redux/files/files-table'
import { hideNotificationsPopover, readNotifications } from 'redux/notifications'
import styled from 'styled-components'
import { projectText } from 'data/messages/misc'
import trans from 'helpers/i18n.helpers'
import NotificationDetail from './NotificationDetail'

interface NotificationDescriptionProps {
  isRead: boolean
  message: string
  details: Notification['details']
  id: number
}

const AcceptanceStatusItem: React.FC<{ status?: string | null }> = ({ status }) => {
  return (
    <Text textStyle="regularText-small" color={status ? flowStatuses[status].color : 'grayG06'}>
      {status ? trans(flowStatuses[status].text) : t({ id: 'notifications.empty_status', message: 'In progress' })}
    </Text>
  )
}

const CdeStatusItem: React.FC<{ status: string }> = ({ status }) => {
  return (
    <Text textStyle="regularText-small" color={cdeStatusDict[status].color}>
      {trans(cdeStatusDict[status].text)}
    </Text>
  )
}

const StyledSpace = styled(Space)`
  && {
    .ant-space-item:last-child {
      max-width: 320px;
    }
  }
`

const statusChangeToMessage = defineMessage({ id: 'notifications.status_change_to_text', message: 'to' })

const NotificationDescription: React.FC<NotificationDescriptionProps> = ({ isRead, message, details, id }) => {
  const dispatch = useDispatch()
  return (
    <StyledSpace size="small" direction="vertical">
      <Text textStyle={isRead ? 'regularText-small' : 'regularText-smallSemiBold'}>{message}</Text>
      <NotificationDetail name={trans(projectText)} value={details.project?.name} />
      <NotificationDetail
        name={t({ id: 'notifications.detail_keys.filename', message: 'File' })}
        value={details.entry?.name || details.file?.name}
      />
      <NotificationDetail
        name={t({ id: 'notifications.detail_keys.directory_change', message: 'CDE directory changed' })}
        value={
          details.status && (
            <>
              <CdeStatusItem status={details.status.old} />{' '}
              <Text textStyle="regularText-small" color="grayG05">
                {trans(statusChangeToMessage)}
              </Text>{' '}
              <CdeStatusItem status={details.status.new} />
            </>
          )
        }
      />
      <NotificationDetail
        name={t({ id: 'notifications.detail_keys.acceptance_status_changed', message: 'Acceptance status changed' })}
        value={
          details.acceptanceStatus && (
            <>
              <AcceptanceStatusItem status={details.acceptanceStatus.old} />{' '}
              <Text textStyle="regularText-small" color="grayG05">
                {trans(statusChangeToMessage)}
              </Text>{' '}
              <AcceptanceStatusItem status={details.acceptanceStatus.new} />
            </>
          )
        }
      />
      <NotificationDetail
        name={t({ id: 'notifications.messages.details.backup_name_text', message: "Backup's name" })}
        value={details.backup?.name}
      />
      <NotificationDetail
        name={t({ id: 'notifications.messages.details.flow_name_text', message: "Flow's name" })}
        value={details.flow?.config.name}
      />
      {details.project && (details.file || details.entry) && (
        <Link
          onClick={() => {
            dispatch(setInitialFileChecked({ fileId: details.file?.id, entryId: details.entry?.id }))
            dispatch(hideNotificationsPopover())
            if (!isRead) {
              dispatch(readNotifications([id]))
            }
          }}
          to={preparePath(`/project/${details.project.id}`)}
        >
          <Text textStyle="regularText-small" color="primaryP04">
            {t({ id: 'notifications.go_to_file_text', message: 'Go to file' })} &gt;
          </Text>
        </Link>
      )}
    </StyledSpace>
  )
}

export default NotificationDescription
