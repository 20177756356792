import { Space, Spin } from 'antd'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  selectAdministrationOrganizations,
  selectFetchingOrganizationsLoading,
  selectIsOrganizationModalOpen,
} from 'redux/administration/administration-organizations/AdministrationOrganizations.selectors'
import { useAppDispatch } from 'redux/store'
import styled from 'styled-components'
import { fetchCodeLength } from 'redux/administration/administration-code-settings'
import {
  selectCodeLengthModalVisibility,
  selectFetchingCodeLengthLoading,
} from 'redux/administration/administration-code-settings/AdministrationCodeSettings.selectors'
import { CodeTypes } from 'redux/administration/administration-code-settings/AdministrationCodeSettings.types'
import { fetchOrganizations } from 'redux/administration/administration-organizations/AdministrationOrganizations.slice'
import EditCodeLengthModal from 'components/organisms/administration/administration-content/modals/edit-code-length/EditCodeLengthModal'
import { t } from '@lingui/macro'
import ExpandableTable from 'components/molecules/table/ExpandableTable'
import useAdministrationOrganizationsColumns from './table-columns/useAdministrationOrganizationsColumns'
import OrganizationModal from './modals'
import AdministrationOrganizationsAlerts from './AdministrationOrganizationsAlerts'

// eslint-disable-next-line react/jsx-props-no-spreading
const StyledTable = styled((props) => <ExpandableTable {...props} />)`
  width: 1545px;
  &&&& {
    td {
      &:nth-child(3),
      &:nth-child(4) {
        text-align: center;
      }
      &:first-child {
        border-right: 0px;
      }
      &:last-child {
        padding-right: 10px;
      }
    }
  }
`

const AdministrationOrganizations: React.FC = () => {
  const dispatch = useAppDispatch()
  const { isLoading: fetchingOrgsLoading } = useSelector(selectFetchingOrganizationsLoading)
  const { isLoading: fetchingCodeLoading } = useSelector(selectFetchingCodeLengthLoading)
  const organizations = useSelector(selectAdministrationOrganizations)

  const columns = useAdministrationOrganizationsColumns()
  useEffect(() => {
    dispatch(fetchOrganizations())
    dispatch(fetchCodeLength(CodeTypes.ORGANIZATION_CODE))
  }, [dispatch])
  const isModalVisible = useSelector(selectIsOrganizationModalOpen)
  const isCodeModalVisible = useSelector(selectCodeLengthModalVisibility)
  const isLoading = fetchingOrgsLoading || fetchingCodeLoading
  return (
    <Spin spinning={isLoading}>
      {!fetchingOrgsLoading && !fetchingCodeLoading && (
        <Space direction="vertical" size="middle">
          <StyledTable
            columns={columns}
            dataSource={organizations}
            bordered
            pagination={false}
            rowKey="id"
            scroll={{ y: 'calc(100vh - 330px)' }}
          />
          {isModalVisible && <OrganizationModal />}
          <AdministrationOrganizationsAlerts />
          {isCodeModalVisible && (
            <EditCodeLengthModal
              codeType="ORGANIZATION_CODE"
              data={organizations}
              reloadDataFun={fetchOrganizations}
              nameFieldLabel={t({
                id: 'administration.organizations.code_length_modal.name_field_label',
                message: 'Organization',
              })}
            />
          )}
        </Space>
      )}
    </Spin>
  )
}

export default AdministrationOrganizations
