import { defineMessage, t } from '@lingui/macro'
import ErrorAlert from 'components/atoms/error-alert'
import { handleApiErrors } from 'helpers/HttpError.helpers'
import { useSelector } from 'react-redux'
import {
  selectFlowAttributesDataFetchingLoading,
  selectFlowAttributesFetchingLoading,
  selectFlowAttributesUpdatingLoading,
} from 'redux/flow-conifg/attributes/FlowAttributesConfig.selectors'

const updateErrorDict = {
  'flow.no_metadata_values': defineMessage({
    id: 'flow_config.attributes.alert.empty_values_list_error',
    message: 'At least one value must be selected for every attribute',
  }),
}

const FlowAttributesConfigContentAlerts: React.FC = () => {
  const { error: fetchingAttributesError } = useSelector(selectFlowAttributesFetchingLoading)
  const { error: fetchingFlowError } = useSelector(selectFlowAttributesDataFetchingLoading)
  const updatingFlowLoading = useSelector(selectFlowAttributesUpdatingLoading)

  const alertMessageDict = [
    {
      error: fetchingAttributesError,
      message: t({
        id: 'flow_config.attributes.alert.fetching_attributes_failed',
        message: 'Could not retrieve attributes',
      }),
    },
    {
      error: fetchingFlowError,
      message: t({
        id: 'flow_config.attributes.alert.fetching_flow_attributes_failed',
        message: 'Could not retrieve flow attributes',
      }),
    },
    {
      error: updatingFlowLoading.error,
      message: handleApiErrors(updateErrorDict, updatingFlowLoading.error?.errors),
    },
  ]
  return <ErrorAlert alertMessageDict={alertMessageDict} />
}

export default FlowAttributesConfigContentAlerts
