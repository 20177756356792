import { useSelector } from 'react-redux'
import { FileOutlined } from '@ant-design/icons'
import prettyBytes from 'pretty-bytes'
import { selectEntriesCommonMetadata } from 'redux/files/files-details/FilesDetails.selectors'
import { selectCheckedFrontendFiles } from 'redux/files/files-table/FilesTable.selectors'
import formatDate, { reduceToNewestTimeEntry, reduceToOldestTimeEntry } from 'helpers/Dates.helpers'
import { metadataDetailsToAttributes } from 'helpers/Files.helpers'
import useFilesCommonMetadata from 'hooks/filesCommonMetadata'
import trans from 'helpers/i18n.helpers'
import { t } from '@lingui/macro'
import { leastRecentUpdateText, mostRecentUpdateText } from 'data/messages/files'
import FilesDetailsContent from './FileDetailsContent'

interface WithSize {
  size: number | null
}

const reduceToSize = <T extends WithSize>(previousValue: number, currentValue: T): number => {
  return currentValue.size ? currentValue.size + previousValue : 0
}

const FilesDetailsDoc: React.FC = () => {
  const checkedFrontendFiles = useSelector(selectCheckedFrontendFiles)
  const commonMetadata = useSelector(selectEntriesCommonMetadata)
  useFilesCommonMetadata()
  const size = checkedFrontendFiles.reduce(reduceToSize, 0)
  const filesNumber = checkedFrontendFiles.length
  const newestDateFile = checkedFrontendFiles.reduce(reduceToNewestTimeEntry)
  const oldestDateFile = checkedFrontendFiles.reduce(reduceToOldestTimeEntry)

  const attributes = metadataDetailsToAttributes(commonMetadata || [])
  const characteristics = [
    {
      name: t({ id: 'project.files.file_info.number_of_selected_files', message: 'Files selected' }),
      value: filesNumber,
    },
    { name: t({ id: 'project.files.file_info.size', message: 'Size' }), value: prettyBytes(size) },
    {
      name: trans(mostRecentUpdateText),
      value: `${newestDateFile.filename} ${formatDate(newestDateFile.lastUpdated || newestDateFile.uploadDate || '')}`,
    },
    {
      name: trans(leastRecentUpdateText),
      value: `${oldestDateFile.filename} ${formatDate(oldestDateFile.lastUpdated || oldestDateFile.uploadDate || '')}`,
    },
  ]
  return (
    <FilesDetailsContent
      title={t({ id: 'project.files.selected_files_count_info', message: `Files selected: ${filesNumber}` })}
      icon={<FileOutlined />}
      attributes={attributes}
      characteristics={characteristics}
    />
  )
}

export default FilesDetailsDoc
