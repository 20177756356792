import { defineMessage, t } from '@lingui/macro'
import { useSelector } from 'react-redux'
import {
  selectExecutingFlowLoading,
  selectFetchingProjectFlowsLoading,
  selectFetchingReviewersLoading,
} from 'redux/files/flows/Flows.selectors'
import { handleApiErrors } from 'helpers/HttpError.helpers'
import ErrorAlert from 'components/atoms/error-alert'

const flowsErrorDict = {
  'flow_exec.other_flow_currently_running': defineMessage({
    id: 'flow_exec.other_flow_currently_running',
    message: 'Other flow is currently running.',
  }),
  'flow_exec.missing_available_reviewers': defineMessage({
    id: 'flow_exec.missing_available_reviewers',
    message: 'There is no available reviewer. Please report it to project admin.',
  }),
}

const FlowExecModalAlerts: React.FC = () => {
  const fetchingFlowsDetailsLoading = useSelector(selectFetchingProjectFlowsLoading)
  const executingFlowsDetailsLoading = useSelector(selectExecutingFlowLoading)
  const fetchingReviewersDetailsLoading = useSelector(selectFetchingReviewersLoading)
  const alertMessageDict = [
    {
      error: fetchingFlowsDetailsLoading.error,
      message: t({ id: 'project.flows.modal.alert.fetch_flows', message: 'Could not retrieve flows.' }),
    },
    {
      error: fetchingReviewersDetailsLoading.error,
      message: t({
        id: 'project.flows.modal.alert.fetch_reviewers',
        message: 'Could not retrieve available reviewers.',
      }),
    },
    {
      error: executingFlowsDetailsLoading.error,
      message: handleApiErrors(
        flowsErrorDict,
        executingFlowsDetailsLoading.error?.errors,
        t({
          id: 'project.flows.modal.alert.exec_flow',
          message: 'Could not execute flow.',
        })
      ),
    },
  ]
  return <ErrorAlert alertMessageDict={alertMessageDict} />
}

export default FlowExecModalAlerts
