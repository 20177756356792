import { FilePdfOutlined, CloseCircleOutlined } from '@ant-design/icons'
import Icon from 'components/atoms/icon'
import Text from 'components/atoms/text'
import SpaceElement from 'components/atoms/space'
import WithOverflow from 'components/atoms/with-overflow'

interface FilesUploadNameProps {
  name: string
  handleRemove?: React.MouseEventHandler<HTMLElement>
}

const FilesUploadName: React.FC<FilesUploadNameProps> = ({ name, handleRemove }) => {
  return (
    <SpaceElement size="m" direction="horizontal">
      <Icon color="grayG07">
        <FilePdfOutlined />
      </Icon>
      <WithOverflow>
        <Text textStyle="regularText-medium">{name}</Text>
      </WithOverflow>
      {handleRemove && (
        <Icon color="grayG05" onClick={handleRemove}>
          <CloseCircleOutlined />
        </Icon>
      )}
    </SpaceElement>
  )
}

export default FilesUploadName
