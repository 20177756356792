import { RootState } from 'redux/store'
import { FrontendFile, MetadataDetails } from 'models/files'
import { BackendLoading } from 'redux/redux.shared'

export const selectIsNewFileVersionModalVisible = (state: RootState): boolean => state.files.newVersion.isModalVisible
export const selectNewVersionEntryMetadata = (state: RootState): MetadataDetails[] =>
  state.files.newVersion.entryMetadata
export const selectNewVersionFile = (state: RootState): FrontendFile | undefined => state.files.newVersion.frontendFile
export const selectFetchNewVersionEntryMetadataLoading = (state: RootState): BackendLoading =>
  state.files.newVersion.loading.fetchNewVersionEntryMetadata
export const selectAddNewEntryVersionLoading = (state: RootState): BackendLoading =>
  state.files.newVersion.loading.addNewEntryVersion
