import { Trans } from '@lingui/macro'
import { InfoOutlined } from '@material-ui/icons'
import { Dropdown, Menu } from 'antd'
import Icon from 'components/atoms/icon'
import Text from 'components/atoms/text'
import styled from 'styled-components'

interface SchemeFieldValuesDisplayProps {
  values?: string[]
}

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

const StyledMenu = styled(Menu)`
  background-color: ${({ theme }) => theme.colors.grayG01};
  max-height: 250px;
  max-width: 500px;
  overflow: auto;
`

const StyledDiv = styled.div`
  > .ant-typography {
    max-width: 100%;
  }
`

const SchemeFieldValuesDisplay: React.FC<SchemeFieldValuesDisplayProps> = ({ values }) => {
  if (values === undefined) {
    return <Trans id="administration.naming_schemes.not_applicable_text">Not applicable</Trans>
  }
  if (values.length === 0) {
    return <Trans id="administration.naming_schemes.no_values_text">No values</Trans>
  }
  return (
    <StyledContainer>
      <div>{values[0]}</div>
      <Dropdown
        trigger={['click']}
        placement="bottomRight"
        overlay={
          <StyledMenu>
            {values.map((val) => (
              <Menu.Item disabled key={val}>
                <StyledDiv>
                  <Text textStyle="regularText-small" color="grayG05" ellipsis>
                    {val}
                  </Text>
                </StyledDiv>
              </Menu.Item>
            ))}
          </StyledMenu>
        }
      >
        <Icon color="grayG03" size="default">
          <InfoOutlined />
        </Icon>
      </Dropdown>
    </StyledContainer>
  )
}

export default SchemeFieldValuesDisplay
