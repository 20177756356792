import { useSelector } from 'react-redux'
import { updateMapConfig } from 'redux/project/project-config/map/ProjectMapConfig.slice'
import { selectIsProjectWipGroupsConfigContentLoading } from 'redux/project/project-config/wip-groups/ProjectWipGroupsConfig.selectors'
import { selectMapCurrentProjection } from 'redux/project/project-details/ProjectDetails.selectors'
import BaseProjectConfigView from '../base-config-view/BaseProjectConfigView'
import ProjectMapConfigContent from './ProjectMapConfigContent'

const ProjectMapConfig: React.FC = () => {
  const mapCurrentProjection = useSelector(selectMapCurrentProjection)
  const isContentLoading = useSelector(selectIsProjectWipGroupsConfigContentLoading)
  return (
    <BaseProjectConfigView
      content={<ProjectMapConfigContent />}
      saveStepThunk={updateMapConfig(mapCurrentProjection?.id)}
      isContentLoading={isContentLoading}
    />
  )
}

export default ProjectMapConfig
