import Text from 'components/atoms/text'
import MainNavBar from 'components/organisms/main-navbar'
import NotificationsDashboard from 'components/organisms/notifications'
import NotificationsTemplate from 'components/templates/notifications'
import WithHeaderTemplate from 'components/templates/with-header'
import { notificationsText } from 'data/messages/misc'
import trans from 'helpers/i18n.helpers'

const NotificationsPage: React.FC = () => {
  return (
    <WithHeaderTemplate
      header={<MainNavBar />}
      content={
        <NotificationsTemplate>
          <Text textStyle="header-H1">{trans(notificationsText)}</Text>
          <NotificationsDashboard />
        </NotificationsTemplate>
      }
    />
  )
}

export default NotificationsPage
