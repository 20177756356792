import { useState } from 'react'

interface UseStepsReturnValue {
  handleNextStep: () => void
  handlePrevStep: () => void
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
  currentStep: number
  isLastStep: boolean
}

const useSteps = (numberOfSteps: number, initialStep = 0): UseStepsReturnValue => {
  const [currentStep, setCurrentStep] = useState(initialStep)
  const lastStepIdx = numberOfSteps - 1
  const handleNextStep = () => {
    setCurrentStep((prev) => {
      if (prev < lastStepIdx) return prev + 1
      return prev
    })
  }
  const handlePrevStep = () => {
    setCurrentStep((prev) => {
      if (prev > 0) return prev - 1
      return prev
    })
  }
  const isLastStep = lastStepIdx === currentStep
  return { handleNextStep, handlePrevStep, currentStep, isLastStep, setCurrentStep }
}

export default useSteps
