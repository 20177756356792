import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectFetchingFlowsLoading } from 'redux/project/project-config/flow/ProjectFlowConfig.selectors'
import { fetchFlows } from 'redux/project/project-config/flow/ProjectFlowConfig.slice'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import BaseProjectConfigView from '../base-config-view/BaseProjectConfigView'
import ProjectFlowConfigContent from './content'

const ProjectFlowConfig: React.FC = () => {
  const { isLoading } = useSelector(selectFetchingFlowsLoading)
  const projectId = useSelector(selectProjectId)
  const dispatch = useDispatch()
  useEffect(() => {
    if (projectId) {
      dispatch(fetchFlows(projectId))
    }
  }, [dispatch, projectId])
  return <BaseProjectConfigView content={<ProjectFlowConfigContent />} isContentLoading={isLoading} />
}

export default ProjectFlowConfig
