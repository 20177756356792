import { InfoCircleOutlined } from '@ant-design/icons'
import { Dropdown, Menu, Space } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import Icon from 'components/atoms/icon'
import Table from 'components/molecules/table'
import { LoadUserError } from 'redux/administration/administration-users/AdministrationUsers.types'
import { Color } from 'styled-components'
import Text from 'components/atoms/text'
import { defineMessage, t } from '@lingui/macro'
import { attributeText, uppercaseNoText, uppercaseYesText } from 'data/messages/misc'
import trans from 'helpers/i18n.helpers'
import { MessageDescriptor } from '@lingui/core'

interface FieldError {
  key: string
  current: string
  inFile: string
}

const boolToText: (val: boolean) => string = (val) => trans(val ? uppercaseYesText : uppercaseNoText)

const errorToList: (err: LoadUserError) => FieldError[] = (err) => {
  return Object.entries(err)
    .filter(([key]) => key !== 'email')
    .map(([key, { current, inFile }]) =>
      key === 'admin' ? { key, current: boolToText(current), inFile: boolToText(inFile) } : { key, current, inFile }
    )
    .filter(({ current, inFile }) => current !== inFile)
}

const fieldNames: Record<string, MessageDescriptor> = {
  disciplines: defineMessage({
    id: 'administration.users.file_modal.disciplines_text',
    message: 'Disciplines',
  }),
  organization: defineMessage({
    id: 'administration.users.file_modal.organizations_text',
    message: 'Organizations',
  }),
  name: defineMessage({
    id: 'administration.users.file_modal.name_text',
    message: 'Name',
  }),
  surname: defineMessage({
    id: 'administration.users.file_modal.surname_text',
    message: 'Surname',
  }),
  admin: defineMessage({
    id: 'administration.users.file_modal.admin_permissions_text',
    message: 'Admin permissions',
  }),
}

const ErrorOverview: React.FC<{ error: LoadUserError }> = ({ error }) => {
  const detailsColumns: ColumnsType<FieldError> = [
    {
      title: trans(attributeText),
      dataIndex: 'attributeName',
      key: 'attributeName',
      width: 100,
      render: (value, { key }) => trans(fieldNames[key]),
    },
    {
      title: t({
        id: 'administration.users.file_modal.differences_table.system_value_header',
        message: 'Current value',
      }),
      dataIndex: 'systemValue',
      key: 'systemValue',
      width: 100,
      render: (value, { current }) => current,
    },
    {
      title: t({
        id: 'administration.users.file_modal.differences_table.file_value_header',
        message: 'Value in file',
      }),
      dataIndex: 'fileValue',
      key: 'fileValue',
      width: 100,
      render: (value, { inFile }) => inFile,
    },
  ]
  const errors = errorToList(error)
  const text =
    errors.length === 0
      ? t({
          id: 'administration.users.file_modal.differences_table.user_data_matches_file',
          message: 'Data in file matches system data',
        })
      : t({
          id: 'administration.users.file_modal.differences_table.user_data_in_file_and_system_differ',
          message: 'Data in file and system differ',
        })
  const textColor: Color = errors.length === 0 ? 'green8' : 'red6'
  const errorDetails = (
    <Dropdown
      trigger={['click']}
      placement="bottomRight"
      overlay={
        <Menu>
          <Table columns={detailsColumns} dataSource={errors} bordered pagination={false} rowKey="attributeName" />
        </Menu>
      }
    >
      <Icon color="grayG03" size="default">
        <InfoCircleOutlined />
      </Icon>
    </Dropdown>
  )
  return (
    <Space direction="horizontal">
      <Text color={textColor} textStyle="regularText-small">
        {text}
      </Text>
      {errors.length > 0 && errorDetails}
    </Space>
  )
}

export default ErrorOverview
