import { RootState } from 'redux/store'
import { ActiveTab, DetailsType, MetadataDetails } from 'models/files'

export const selectFilesDetailsVisibility = (state: RootState): boolean => state.files.details.isVisible
export const selectFilesDetailsActiveTab = (state: RootState): ActiveTab => state.files.details.activeTab
export const selectDetailsType = (state: RootState): DetailsType | undefined => {
  const { selectedGroup, checkedFrontendFiles, checkedGroups } = state.files.table
  const checkedFrontendFilesLength = checkedFrontendFiles.length
  const checkedGroupsLength = checkedGroups.length
  if (!selectedGroup) return undefined
  if (selectedGroup.containFrontendFiles) {
    if (checkedFrontendFilesLength === 1) return 'DOC'
    if (checkedFrontendFilesLength > 1) return 'DOCS'
  } else if (checkedGroupsLength > 0) return 'DIR_CHECKED'
  return 'DIR_CURRENT'
}
export const selectEntriesCommonMetadata = (state: RootState): MetadataDetails[] | undefined =>
  state.files.details.entriesCommonMetadata
