import { SettingOutlined } from '@ant-design/icons'
import MainNavBarItem from 'components/atoms/main-navbar'
import Icon from 'components/atoms/icon'
import { recordWithKeys } from 'helpers/Component.helpers'
import { Space } from 'antd'
import { useHistory, useLocation } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { selectNotificationsPopoverVisibility } from 'redux/notifications/Notifications.selectors'
import { Link } from 'react-router-dom'
import { itemArr } from 'helpers/Collection.helpers'
import { selectGlobalPermissions } from 'redux/login/Login.selectors'
import { onNotificationsPopoverVisibilityChange } from 'redux/notifications'
import MainNavBarUserMenu from './MainNavBarUserMenu'
import NotificationsPopover from './main-navbar-notifications-popover/NotificationsPopover'

const MainNavBarOptions: React.FC = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const isNotificationsPopoverVisible = useSelector(selectNotificationsPopoverVisibility)
  const globalPermissions = useSelector(selectGlobalPermissions)
  const onNotificationVisibilityChange = (visibile: boolean) => {
    if (location.pathname === '/notifications') {
      history.goBack()
    } else {
      dispatch(onNotificationsPopoverVisibilityChange(visibile))
    }
  }
  const options: { component: React.ReactNode; isOptionActive?: boolean; key: number }[] = recordWithKeys([
    ...itemArr(
      {
        component:
          location.pathname !== '/administration' ? (
            <Link to="/administration">
              <Icon>
                <SettingOutlined />
              </Icon>
            </Link>
          ) : (
            <Icon>
              <SettingOutlined onClick={() => history.goBack()} />
            </Icon>
          ),
        isOptionActive: location.pathname === '/administration',
      },
      globalPermissions?.isAdmin
    ),
    {
      component: <NotificationsPopover onVisibleChange={onNotificationVisibilityChange} />,
      isOptionActive: location.pathname === '/notifications' || isNotificationsPopoverVisible,
    },
    {
      component: <MainNavBarUserMenu />,
      isOptionActive: location.pathname === '/profile',
    },
  ])
  return (
    <ul>
      <Space size="middle">
        {options.map((option) => (
          <MainNavBarItem key={option.key} isActive={option.isOptionActive}>
            {option.component}
          </MainNavBarItem>
        ))}
      </Space>
    </ul>
  )
}

export default MainNavBarOptions
