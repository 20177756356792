import { Color } from 'styled-components'
import { MessageDescriptor } from '@lingui/core'
import { defineMessage } from '@lingui/macro'

const cdeStatusDict: { [key: string]: { color: Color; text: MessageDescriptor; abbr: MessageDescriptor } } = {
  IN_PROGRESS: {
    color: 'yellow6',
    text: defineMessage({ id: 'common.cde_directories.work_in_progress', message: 'Work in progress' }),
    abbr: defineMessage({ id: 'common.cde_directories.work_in_progress_abbr', message: 'WIP' }),
  },
  SHARED: {
    color: 'orange6',
    text: defineMessage({ id: 'common.cde_directories.shared', message: 'Shared' }),
    abbr: defineMessage({ id: 'common.cde_directories.shared_abbr', message: 'SHA' }),
  },
  PUBLISHED: {
    color: 'cyan6',
    text: defineMessage({ id: 'common.cde_directories.published', message: 'Published' }),
    abbr: defineMessage({ id: 'common.cde_directories.published_abbr', message: 'PUB' }),
  },
  ARCHIVED: {
    color: 'blue6',
    text: defineMessage({ id: 'common.cde_directories.archived', message: 'Archived' }),
    abbr: defineMessage({ id: 'common.cde_directories.archived_abbr', message: 'ARC' }),
  },
}

export const cdeStatusOptions = Object.keys(cdeStatusDict).map((k) => {
  return { value: k, label: cdeStatusDict[k].text }
})

export default cdeStatusDict
