import { t, Trans } from '@lingui/macro'
import { Modal, Space } from 'antd'
import Text from 'components/atoms/text'
import ButtonElement from 'components/molecules/button-element'
import { cancelText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import { useAppDispatch } from 'redux/store'
import {
  createBackup,
  setCreateBackupModalVisiblity,
} from 'redux/administration/administration-backups/AdministrationBackups.slice'
import { useSelector } from 'react-redux'
import { selectCreateBackupLoading } from 'redux/administration/administration-backups/AdministrationBackups.selectors'
import { useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import FormInput from 'components/molecules/form-item/FormInput'
import CreatingBackupAlert from './CreatingBackupAlert'

const BackupCreationModal: React.FC = () => {
  const dispatch = useAppDispatch()
  const { isLoading } = useSelector(selectCreateBackupLoading)
  const [delay, setDelay] = useState(10)
  const onCancel = () => dispatch(setCreateBackupModalVisiblity(false))
  const handleSubmit = () => {
    dispatch(createBackup(delay)).then(unwrapResult).then(onCancel)
  }
  return (
    <Modal
      visible
      onCancel={onCancel}
      maskClosable={false}
      title={
        <Text textStyle="header-H2b">
          <Trans id="administration.backups.modal.create_backup_header">System backup</Trans>
        </Text>
      }
      footer={
        <>
          <ButtonElement onClick={onCancel} type="tertiary">
            {trans(cancelText)}
          </ButtonElement>
          <ButtonElement loading={isLoading} onClick={handleSubmit}>
            <Trans id="administration.backups.modal.create_button_text">Create system backup</Trans>
          </ButtonElement>
        </>
      }
    >
      <Space direction="vertical">
        <Text textStyle="header-H2b">
          <Trans id="administration.backups.modal.create_backup_confirmation_heading">
            Do you want to create a system backup?
          </Trans>
        </Text>
        <Text textStyle="regularText-medium" paragraph>
          <Trans id="administration.backups.modal.create_backup_confirmation_text">
            Working with the system will be limited. System changes will be suspended while the backup is being created.
          </Trans>
        </Text>
        <FormInput
          type="number"
          value={delay}
          onChange={(e) => {
            const val = Number(e.target.value)
            if (val > 0) setDelay(val)
          }}
          label={t({
            id: 'administration.backups.modal.create_backup_input_label',
            message: 'System backup creation delay (in minutes):',
          })}
        />
        <CreatingBackupAlert />
      </Space>
    </Modal>
  )
}

export default BackupCreationModal
