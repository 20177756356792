import { useDispatch, useSelector } from 'react-redux'
import { TreeProps } from 'rc-tree'
import { TreeNode } from 'components/molecules/map/map-sidedrawer-tree/MapSideDrawerTreeTypes'
import { setCheckedWmsNodes, setVisibleWmsLayers } from 'redux/map/map-wms-layers'
import { selectCheckedWmsLayerNodes } from 'redux/map/map-wms-layers/MapWmsLayers.selectors'
import MapSideDrawerTree from 'components/molecules/map/map-sidedrawer-tree/MapSideDrawerTree'
import { ProjectLayer } from 'models/map'

interface WmsSideDrawerTreeProps {
  data: ProjectLayer[]
}

const WmsSideDrawerTree: React.FC<WmsSideDrawerTreeProps> = ({ data }) => {
  const checkedKeys = useSelector(selectCheckedWmsLayerNodes)
  const dispatch = useDispatch()
  const handleNodeCheck: TreeProps['onCheck'] = (checked, info) => {
    const checkedNodes = info.checkedNodes.map((node: TreeNode) => node.key)
    dispatch(setCheckedWmsNodes(checkedNodes))
    const visibleLayers = info.checkedNodes.map(
      (node: TreeNode) => ({ references: node.gsLayersReferences?.flat(2), url: node.geoServerUrl } || [])
    )
    dispatch(setVisibleWmsLayers(visibleLayers))
  }
  return <MapSideDrawerTree data={data} onCheck={handleNodeCheck} checkedKeys={checkedKeys} />
}

export default WmsSideDrawerTree
