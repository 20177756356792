import { RootState } from 'redux/store'
import { SelectedGroup, FrontendFile, Group, FileInfo } from 'models/files'

export const selectSelectedGroup = (state: RootState): SelectedGroup | undefined => state.files.table.selectedGroup
export const selectCheckedFrontendFiles = (state: RootState): FrontendFile[] => state.files.table.checkedFrontendFiles
export const selectCheckedGroups = (state: RootState): Group[] => state.files.table.checkedGroups
export const selectNewlyAddedFilesId = (state: RootState): number[] => state.files.table.newlyAddedFilesId
export const createFrontendFileCheckedStateSelector = (frontendFile: FrontendFile) => (state: RootState): boolean =>
  !!state.files.table.checkedFrontendFiles.find((file) => file.key === frontendFile.key)
export const createGroupCheckboxStateSelector = (group: Group) => (state: RootState): boolean =>
  !!state.files.table.checkedGroups.find((grp) => grp.value.name === group.value.name)
export const selectTableHeadingCheckboxState = (state: RootState): { checked: boolean; indeterminate: boolean } => {
  const checkedGroupsLength = state.files.table.checkedGroups.length
  const checkedFrontendFilesLength = state.files.table.checkedFrontendFiles.length
  const { selectedGroup } = state.files.table
  const frontendFilesLength = selectedGroup?.frontendFiles?.length
  const groupLength = selectedGroup?.groups?.length
  if (groupLength === checkedGroupsLength || frontendFilesLength === checkedFrontendFilesLength)
    return { checked: true, indeterminate: false }
  if (checkedGroupsLength || checkedFrontendFilesLength) return { indeterminate: true, checked: false }
  return { checked: false, indeterminate: false }
}

export const selectIntialFileChecked: (state: RootState) => FileInfo | undefined = (state) =>
  state.files.table.initialFileChecked
