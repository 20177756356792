import { Trans } from '@lingui/macro'
import BaseFormItem from 'components/molecules/form-item/BaseFormItem'
import FormSelect from 'components/molecules/form-item/FormSelect'
import cdeStatusDict from 'data/cdeStatus'
import { noChangesText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import { ConsequencesType } from 'models/flow'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectSuitabilityCodesConfig } from 'redux/flow-conifg/consequences/FlowConsequencesConfig.selectors'

const tooltip: Record<ConsequencesType, JSX.Element> = {
  NEGATIVE: (
    <Trans id="flow_config.consequences.suitability_code.negative_tooltip">
      This suitability code will be assigned to file after flow is finished negative.
    </Trans>
  ),
  POSITIVE: (
    <Trans id="flow_config.consequences.suitability_code.positive_tooltip">
      This suitability code will be assigned to file after flow is finished positive.
    </Trans>
  ),
  NEUTRAL: (
    <Trans id="flow_config.consequences.suitability_code.neutral_tooltip">
      This suitability code will be assigned to file after flow is finished.
    </Trans>
  ),
}

interface FlowConsequencesConfigFormProps {
  type: ConsequencesType
}

const SuitabilityCodesSelect: React.FC<FlowConsequencesConfigFormProps> = ({ type }) => {
  const codes = useSelector(selectSuitabilityCodesConfig)
  const options = useMemo(
    () => [
      ...codes.map(({ id, name, code, status }) => ({
        value: id,
        label: `${code} (${name}) - ${trans(cdeStatusDict[status].abbr)}`,
      })),
    ],
    [codes]
  )
  return (
    <BaseFormItem
      label={<Trans id="flow_config.consequences.suitability_code_label">Following suitability code</Trans>}
      tooltip={tooltip[type]}
    >
      <FormSelect
        showSearch
        name="followingSuitabilityCode"
        placeholder={trans(noChangesText)}
        options={[{ value: -1, label: trans(noChangesText) }, ...options]}
      />
    </BaseFormItem>
  )
}

export default SuitabilityCodesSelect
