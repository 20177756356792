import { t } from '@lingui/macro'
import ErrorAlert from 'components/atoms/error-alert'
import { useSelector } from 'react-redux'
import {
  selectGettingFlowConditionsLoading,
  selectGettingOrganizationsLoading,
  selectUpdatingFlowConditionsLoading,
} from 'redux/flow-conifg/conditions/FlowConditionsConfig.selectors'
import {
  selectGettingMembersConfigLoading,
  selectGettingSuitabilityCodesLoading,
} from 'redux/flow-conifg/decisions/FlowDecisionsConfig.selectors'
import { selectFetchingAvailableDisciplinesLoading } from 'redux/project/project-config/members-permissions/ProjectMembersPermissionsConfig.selectors'

const FlowConditionsConfigContentAlerts: React.FC = () => {
  const { error: gettingConditionsError } = useSelector(selectGettingFlowConditionsLoading)
  const { error: updatingConditionsError } = useSelector(selectUpdatingFlowConditionsLoading)
  const { error: getSuitabilityCodesError } = useSelector(selectGettingSuitabilityCodesLoading)
  const { error: fetchingDisciplinesError } = useSelector(selectFetchingAvailableDisciplinesLoading)
  const { error: gettingMembersConfigError } = useSelector(selectGettingMembersConfigLoading)
  const { error: gettingOrganizationsError } = useSelector(selectGettingOrganizationsLoading)

  const alertMessageDict = [
    {
      error: gettingConditionsError,
      message: t({
        id: 'flow_config.conditions.alert.fetching_conditions_failed',
        message: 'Could not retrieve conditions.',
      }),
    },
    {
      error: getSuitabilityCodesError,
      message: t({
        id: 'flow_config.conditions.alert.fetching_suitability_codes_failed',
        message: 'Could not retrieve suitability codes.',
      }),
    },
    {
      error: updatingConditionsError,
      message: t({
        id: 'flow_config.conditions.alert.update_conditions_failed',
        message: 'Could not update flow conditions.',
      }),
    },
    {
      error: fetchingDisciplinesError,
      message: t({
        id: 'flow_config.conditions.alert.fetching_disciplines_failed',
        message: 'Could not retrieve organizations.',
      }),
    },
    {
      error: gettingMembersConfigError,
      message: t({
        id: 'flow_config.conditions.alert.getting_members_config_failed',
        message: 'Could not retrieve members.',
      }),
    },
    {
      error: gettingOrganizationsError,
      message: t({
        id: 'flow_config.conditions.alert.getting_organizations_failed',
        message: 'Could not retrieve organizations.',
      }),
    },
  ]
  return <ErrorAlert alertMessageDict={alertMessageDict} />
}

export default FlowConditionsConfigContentAlerts
