import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import backendAxios from 'axios/axios'
import { openNotification } from 'helpers/Notifications.helpers'
import {
  BackendLoading,
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
} from 'redux/redux.shared'
import { requestPasswordResetUrl, resetPasswordUrl, verifyPasswordResetTokenUrl } from 'routes/external.routes'

const loadingTypes = ['requestPasswordReset', 'resetPassword', 'verifyPasswordResetToken', 'changePassword'] as const
type LoadingTypes = typeof loadingTypes[number]

export interface PasswordResetState {
  loading: Record<LoadingTypes, BackendLoading>
  isRequestFinished: boolean
  isPasswordResetSuccessful: boolean
  isPasswordResetFinished: boolean
  isTokenValid: boolean
}

export const requestPasswordReset = createAsyncBackendThunk(
  'password/requestReset',
  async ({ email }: { email: string }) => {
    const response = await axios.post(requestPasswordResetUrl, { email })
    return response
  }
)

export const resetPassword = createAsyncBackendThunk(
  'password/resetPassword',
  async ({ password, token }: { password: string; token: string | null }) => {
    const response = await axios.post(resetPasswordUrl, { password }, { params: { token } })
    return response
  }
)

export const verifyPasswordResetToken = createAsyncBackendThunk(
  'password/verifyToken',
  async ({ token }: { token: string | null }) => {
    const response = await axios.get(verifyPasswordResetTokenUrl, { params: { token } })
    return response.data.isValid
  }
)

export const changePassword = createAsyncBackendThunk(
  'password/changePassword',
  async ({
    newPassword,
    oldPassword,
    onSuccess,
  }: {
    newPassword: string
    oldPassword: string
    onSuccess: () => void
  }) => {
    await backendAxios.post('users/password/', { oldPassword, newPassword })
    openNotification('success', {
      placement: 'bottomRight',
      message: 'Zmieniono hasło. Zostaniesz przekierowany...',
      duration: 2,
    })
    await new Promise((resolve) =>
      setTimeout(() => {
        onSuccess()
        resolve(undefined)
      }, 2000)
    )
  }
)

const passwordResetSlice = createSlice({
  name: 'passwordReset',
  initialState: {
    loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
    isRequestFinished: false,
    isPasswordResetSuccessful: false,
    isPasswordResetFinished: false,
    isTokenValid: false,
  } as PasswordResetState,
  reducers: {},
  extraReducers: combine([
    generateExtraBackendReducers<PasswordResetState, LoadingTypes>({
      promise: requestPasswordReset,
      loadingType: 'requestPasswordReset',
      onFulfilled: (state) => {
        state.isRequestFinished = true
      },
    }),
    generateExtraBackendReducers<PasswordResetState, LoadingTypes>({
      promise: resetPassword,
      loadingType: 'resetPassword',
      onFulfilled: (state) => {
        state.isPasswordResetSuccessful = true
        state.isPasswordResetFinished = true
      },
      onRejected: (state) => {
        state.isPasswordResetSuccessful = false
        state.isPasswordResetFinished = true
      },
    }),
    generateExtraBackendReducers<PasswordResetState, LoadingTypes, boolean>({
      promise: verifyPasswordResetToken,
      loadingType: 'verifyPasswordResetToken',
      onFulfilled: (state, action) => {
        state.isTokenValid = action.payload
      },
    }),
    generateExtraBackendReducers<PasswordResetState, LoadingTypes>({
      promise: changePassword,
      loadingType: 'changePassword',
    }),
  ]),
})

export const passwordResetReducer = passwordResetSlice.reducer
