import { Backup, BackupSettings } from 'models/administration'
import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import { AdministrationBackupsState } from './AdministrationBackups.types'

const selectAdministrationBackups: (state: RootState) => AdministrationBackupsState = (state) =>
  state.administration.backups

export const selectBackups: (state: RootState) => Backup[] = (state) => selectAdministrationBackups(state).backups

export const selectBackupsSettings: (state: RootState) => BackupSettings | undefined = (state) =>
  selectAdministrationBackups(state).settings

export const selectFileModalVisibility: (state: RootState) => boolean = (state) =>
  selectAdministrationBackups(state).importModalData.isVisible

export const selectBackupCreationModalVisibility: (state: RootState) => boolean = (state) =>
  selectAdministrationBackups(state).isCreateBackupModalVisible

export const selectBackupSettingsModalVisibility: (state: RootState) => boolean = (state) =>
  selectAdministrationBackups(state).isSettingsModalVisible

export const selectBackupsListLoading: (state: RootState) => BackendLoading = (state) =>
  selectAdministrationBackups(state).loading.fetchSystemBackupsList

export const selectImportFromFileLoading: (state: RootState) => BackendLoading = (state) =>
  selectAdministrationBackups(state).loading.importBackupFromFile

export const selectRestoreBackupLoading: (state: RootState) => BackendLoading = (state) =>
  selectAdministrationBackups(state).loading.restoreBackup

export const selectCreateBackupLoading: (state: RootState) => BackendLoading = (state) =>
  selectAdministrationBackups(state).loading.createBackup

export const selectBackupSavingLoading: (state: RootState) => BackendLoading = (state) =>
  selectAdministrationBackups(state).loading.editBackupSettings
