import { useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import {
  selectFinishedSteps,
  selectFinishedStepsLoaded,
} from 'redux/project/project-config/status/ProjectStatusConfig.selectors'
import { selectProjectIsActive } from 'redux/project/project-details/ProjectDetails.selectors'
import { itemArr } from 'helpers/Collection.helpers'
import FullscreenSpin from 'components/atoms/fullscreen-spin'
import useStepsParams from '../../../hooks/stepsParams'
import ProjectActivationConfig from './activation'
import ProjectMapConfig from './map'
import ProjectWipGroupsConfig from './wips'
import ProjectSuitabilityConfig from './suitability'
import ProjectMembersPermissionsConfig from './members-permissions'
import ProjectMembersConfig from './members/ProjectMembersConfig'
import ProjectEntriesStructureConfig from './entries-structure'
import ProjectNamingSchemeConfig from './naming-scheme'
import ProjectStagesConfig from './stages'
import ProjectConfigGeneral from './general'
import ProjectAttributesConfig from './attributes'
import ProjectFlowConfig from './flow'
import ProjectDisciplinesConfig from './disciplines'

const getProjectConfigStepContents = (isProjectActive?: boolean) => [
  <ProjectConfigGeneral />,
  <ProjectStagesConfig />,
  <ProjectNamingSchemeConfig />,
  <ProjectDisciplinesConfig />,
  <ProjectAttributesConfig />,
  <ProjectEntriesStructureConfig />,
  <ProjectMembersConfig />,
  <ProjectMembersPermissionsConfig />,
  <ProjectSuitabilityConfig />,
  <ProjectWipGroupsConfig />,
  <ProjectMapConfig />,
  ...itemArr(<ProjectFlowConfig />, isProjectActive),
  ...itemArr(<ProjectActivationConfig />, !isProjectActive),
]

const ProjectConfig: React.FC = () => {
  const { stepIdx, projectId, isStepIdxNan } = useStepsParams()
  const redirectedRef = useRef(false)
  const finishedSteps = useSelector(selectFinishedSteps)
  const finishedStepsLoaded = useSelector(selectFinishedStepsLoaded)
  const isProjectActive = useSelector(selectProjectIsActive)
  const history = useHistory()
  const stepsData = getProjectConfigStepContents(isProjectActive)
  const isStepIdxInvalid =
    isStepIdxNan ||
    stepIdx < 0 ||
    stepIdx >= stepsData.length ||
    (finishedStepsLoaded && stepIdx > finishedSteps.length)
  const replaceHistoryToStep = useCallback(
    (step: number) => {
      history.replace(`/project-config/${projectId}/${step}`)
      redirectedRef.current = true
    },
    [projectId, history]
  )
  useEffect(() => {
    if (!redirectedRef.current && isStepIdxInvalid) {
      // redirect to newest visited step when first entered
      if (finishedStepsLoaded && !isProjectActive) replaceHistoryToStep(finishedSteps.length + 1)
      // redirect to first step when proejct active and wrong step param provided
      else if (isProjectActive) replaceHistoryToStep(1)
    }
  }, [history, finishedSteps, finishedStepsLoaded, replaceHistoryToStep, isStepIdxInvalid, isProjectActive])

  return isStepIdxInvalid ? <FullscreenSpin /> : <>{stepsData[stepIdx]}</>
}

export default ProjectConfig
