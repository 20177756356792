import styled from 'styled-components'

interface WithOverflowProps {
  children: React.ReactNode
  indent?: boolean
}

interface StyledSpanProps {
  $indent: boolean
}

const StyledSpan = styled.span<StyledSpanProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;
  > *,
  > .ant-typography {
    ${(props) => (props.$indent ? `margin-left: ${props.theme.sizes.default};` : '')}
  }
`

const WithOverflow: React.FC<WithOverflowProps> = ({ children, indent = false }) => {
  return <StyledSpan $indent={indent}>{children}</StyledSpan>
}

export default WithOverflow
