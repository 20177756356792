import { t, Trans } from '@lingui/macro'
import { Alert, Button, Form, Spin } from 'antd'
import ButtonElement from 'components/atoms/button.legacy/Button'
import FormInput from 'components/molecules/form-item/FormInput'
import { successText } from 'data/messages/controls'
import { required } from 'helpers/Validators.helpers'
import trans from 'helpers/i18n.helpers'
import { useSelector } from 'react-redux'
import { requestPasswordReset } from 'redux/password'
import { passwordSelector } from 'redux/password/Password.selectors'
import { useAppDispatch } from 'redux/store'

const RequestPasswordResetForm: React.FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const handleRequestResetPassword = (value: { email: string }): void => {
    dispatch(requestPasswordReset(value))
  }

  const { loading, isRequestFinished } = useSelector(passwordSelector)

  return (
    <Spin spinning={loading.requestPasswordReset.isLoading}>
      <Form
        layout="vertical"
        name="request_password_reset"
        className="request-password-reset-form"
        onFinish={handleRequestResetPassword}
      >
        <FormInput
          label={<Trans id="password_reset.email_label">email</Trans>}
          placeholder={t({ id: 'password_reset.email_placeholder', message: 'Enter email' })}
          name="email"
          rules={[required()]}
        />
        <Form.Item>
          <ButtonElement elementType="submit">
            <Trans id="password_reset.reset_button_text">Reset password</Trans>
          </ButtonElement>
        </Form.Item>
        {isRequestFinished && (
          <Alert
            message={trans(successText)}
            description={
              <Trans id="password_reset.email_sent_message">
                An email with details has been sent to the given address
              </Trans>
            }
            type="success"
            showIcon
            action={
              <Button size="small" href="/login">
                <Trans id="password_reset.go_back_button_text">Go back</Trans>
              </Button>
            }
          />
        )}
      </Form>
    </Spin>
  )
}

export default RequestPasswordResetForm
