import { ProjectStageWithUsage } from 'models/administration'
import { BackendLoading } from 'redux/redux.shared'

export const loadingTypes = [
  'fetchProjectStages',
  'updateProjectStage',
  'addProjectStage',
  'removeProjectStage',
] as const
export type LoadingTypes = typeof loadingTypes[number]

export interface AdministrationProjectStagesModalState {
  isModalVisible: boolean
  stage?: ProjectStageWithUsage
}

export interface AdministrationProjectStagesState {
  loading: Record<LoadingTypes, BackendLoading>
  stages: ProjectStageWithUsage[]
  modalState: AdministrationProjectStagesModalState
}
