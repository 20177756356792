import { MessageDescriptor } from '@lingui/core'
import { Radio, Space } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import Text from 'components/atoms/text'
import trans from 'helpers/i18n.helpers'

interface RadioOption {
  value: string
  label: MessageDescriptor
  labelDescription?: MessageDescriptor
  disabled?: boolean
}

interface RadioGroupProps {
  selectedValue?: number | string
  options: RadioOption[]
  onChange?: (e: RadioChangeEvent) => void
}

const RadioGroup: React.FC<RadioGroupProps> = ({ selectedValue, onChange, options }) => {
  return (
    <Radio.Group value={selectedValue} onChange={onChange}>
      <Space direction="vertical">
        {options?.map(({ value, labelDescription, label, disabled }) => (
          <Radio value={value} disabled={disabled} key={value}>
            <Text textStyle="regularText-small">{trans(label)}</Text>
            {labelDescription && (
              <div>
                <Text textStyle="regularText-small" color="grayG06">
                  {trans(labelDescription)}
                </Text>
              </div>
            )}
          </Radio>
        ))}
      </Space>
    </Radio.Group>
  )
}

export default RadioGroup
