import cdeStatusDict from 'data/cdeStatus'
import { Attribute, NamingScheme, NamingSchemeElement } from 'models/administration'
import { MessageDescriptor } from '@lingui/core'
import trans from 'helpers/i18n.helpers'
import { defineMessage } from '@lingui/macro'
import { cdeDirectoryText, dateText, disciplineText, projectText, revisionText } from 'data/messages/misc'
import { CodeSetting, CodeType, CodeTypes } from '../administration-code-settings/AdministrationCodeSettings.types'
import { AttributeType, NamingSchemeWithDetails, SchemeElementData } from './AdministrationNamingScheme.types'

export const removeFromScheme: (
  scheme: NamingSchemeElement[],
  fieldType: AttributeType,
  metadataId?: number
) => NamingSchemeElement[] = (scheme, fieldType, metadataId) => {
  return scheme.filter((elem) => elem.key !== fieldType || elem.args?.id !== metadataId)
}

const generateNumber: (length: number) => string = (length) => {
  return '1'.padStart(length, '0')
}

const keyData: Record<string, CodeType> = {
  discipline: CodeTypes.DISCIPLINE_CODE,
  project: CodeTypes.PROJECT_CODE,
  suitability: CodeTypes.SUITABILITY_CODE_CODE,
  projectStage: CodeTypes.PROJECT_STAGE_CODE,
  initiator: CodeTypes.ORGANIZATION_CODE,
}

const fieldNames: Record<string, MessageDescriptor> = {
  discipline: disciplineText,
  project: projectText,
  suitability: defineMessage({
    id: 'administration.naming_schemes.field_names.suitability_code',
    message: 'Suitability code',
  }),
  projectStage: defineMessage({
    id: 'administration.naming_schemes.field_names.project_stage',
    message: 'Project stage',
  }),
  initiator: defineMessage({
    id: 'administration.naming_schemes.field_names.initiator',
    message: "Author's organization",
  }),
  fileNumber: defineMessage({ id: 'administration.naming_schemes.field_names.fileNumber', message: 'File number' }),
  folderCde: cdeDirectoryText,
  revision: revisionText,
  date: dateText,
}

export interface SchemeElementWithName extends NamingSchemeElement {
  name: string
}

export const getAvailableSchemeElements = (
  attributes: Attribute[],
  existingSchemeElements: NamingSchemeElement[]
): SchemeElementWithName[] => {
  const attributeElements = attributes
    .filter(({ id }) => !existingSchemeElements.find(({ args, key }) => key === 'metadata' && args?.id === id))
    .map(({ name, id }) => ({
      name,
      key: 'metadata' as AttributeType,
      args: { id, inShortName: false },
    }))
  const keyElements = Object.keys(keyData)
    .filter((key) => !existingSchemeElements.find((schemeEl) => schemeEl.key === key))
    .map((key) => ({
      name: trans(fieldNames[key]),
      key: key as AttributeType,
      args: { inShortName: false },
    }))
  const fileNumber = { key: 'fileNumber', args: { length: 3, inShortName: true }, name: 'Numer' }
  const data = { key: 'date', name: 'Data', args: { inShortName: false } }
  const revision = { key: 'revision', name: 'Rewizja', args: { inShortName: false } }
  const restElements = [fileNumber, data, revision].filter(
    (el) => !existingSchemeElements.find(({ key }) => key === el.key)
  ) as SchemeElementWithName[]
  return [...attributeElements, ...keyElements, ...restElements]
}

const getSettingExampleCode = (codeSetting?: CodeSetting): string => {
  if (codeSetting) {
    if (codeSetting.values.length > 0) {
      return codeSetting.values[0].code
    }
    return '_'.repeat(codeSetting?.length)
  }
  return ''
}

const getAttributeExampleCode = (attribute?: Attribute): string => {
  if (attribute) {
    if (attribute.values.length > 0) {
      return attribute.values[0].code
    }
    return '_'.repeat(attribute?.valueCodeLength)
  }
  return ''
}

const getSchemeElementData: (
  element: NamingSchemeElement,
  attributes: Attribute[],
  settings: Record<CodeType, CodeSetting>,
  splitRevision: boolean
) => SchemeElementData | SchemeElementData[] = (element, attributes, settings, splitRevision) => {
  const { key, args } = element
  if (key === 'metadata') {
    const attribute = attributes.find((attr: Attribute) => attr.id === args?.id)
    return {
      name: attribute?.name || '',
      code: getAttributeExampleCode(attribute),
      length: attribute?.valueCodeLength,
      values: attribute?.values.map((val) => val.value) || [],
      type: key,
      args,
      key: `${key}_${args?.id}`,
    }
  }
  if (key in keyData) {
    const settingsKeyVal = settings[keyData[key]]
    return {
      name: trans(fieldNames[key]),
      code: getSettingExampleCode(settingsKeyVal),
      length: settingsKeyVal && settingsKeyVal.length,
      values: settingsKeyVal && settingsKeyVal.values.map((val) => val.name),
      type: key,
      args,
      key,
    }
  }
  if (key === 'fileNumber') {
    return {
      name: trans(fieldNames[key]),
      code: generateNumber((args && args.length) || 0),
      length: args ? args.length : 0,
      type: key,
      args,
      key,
    }
  }
  if (key === 'revision') {
    return splitRevision
      ? [
          {
            name: trans(fieldNames.folderCde),
            code: 'W',
            length: 1,
            values: Object.values(cdeStatusDict).map((val) => trans(val.abbr)),
            type: key,
            args,
            key: 'cdeFolder',
          },
          {
            name: trans(fieldNames.revision),
            code: '01.04',
            length: 5,
            type: key,
            args,
            key: 'revision',
          },
        ]
      : {
          name: trans(fieldNames.revision),
          code: 'W01.04',
          length: 5,
          type: key,
          args,
          key: 'revision',
        }
  }
  return {
    name: trans(fieldNames.date),
    code: '211217',
    length: 6,
    type: key,
    args,
    key: 'date',
  }
}

export const getSchemeData: (
  scheme: NamingSchemeElement[],
  attributes: Attribute[],
  settings: Record<CodeType, CodeSetting>,
  splitRevision?: boolean
) => SchemeElementData[] = (scheme, attributes, settings, splitRevision = false) => {
  return scheme.flatMap((elem) => getSchemeElementData(elem, attributes, settings, splitRevision))
}

export const getSchemeWithDetails: (
  scheme: NamingScheme,
  attributes: Attribute[],
  settings: Record<CodeType, CodeSetting>
) => NamingSchemeWithDetails = (scheme, attributes, settings) => {
  return {
    ...scheme,
    details: getSchemeData(scheme.scheme, attributes, settings),
  }
}

export const getSetSchemeDefault: (isEditedDefault: boolean) => (scheme: NamingScheme) => NamingScheme = (
  isEditedDefault
) => {
  if (!isEditedDefault) return (scheme) => scheme
  return (scheme) => ({ ...scheme, isDefault: false })
}
