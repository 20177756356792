import { useAppDispatch } from 'redux/store'
import Button from 'components/molecules/button-element'
import { hideProjectAttributesModal } from 'redux/project/project-config/attributes'
import trans from 'helpers/i18n.helpers'
import { cancelText, saveText } from 'data/messages/controls'

interface ModalProps {
  handleSubmit: () => Promise<void>
  isLoading: boolean
}

const AttributeModalFooter: React.FC<ModalProps> = ({ handleSubmit, isLoading }) => {
  const dispatch = useAppDispatch()
  const handleCancel = () => dispatch(hideProjectAttributesModal())
  return (
    <>
      <Button onClick={handleCancel} type="tertiary">
        {trans(cancelText)}
      </Button>
      <Button loading={isLoading} onClick={handleSubmit}>
        {trans(saveText)}
      </Button>
    </>
  )
}

export default AttributeModalFooter
