import CheckboxWithLoading from 'components/molecules/checkbox/CheckboxWithLoading'
import { setProjectMemberActivity } from 'redux/project/project-config/members/ProjectMembersConfig.slice'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import { useAppDispatch } from 'redux/store'
import { useSelector } from 'react-redux'

interface SelectUserIsActiveProps {
  id: number
  isActive: boolean
  isAdmin: boolean
}

const SelectUserIsActive: React.FC<SelectUserIsActiveProps> = ({ id, isActive, isAdmin }) => {
  const dispatch = useAppDispatch()
  const projectId = useSelector(selectProjectId)
  if (projectId === undefined) {
    return null
  }
  return (
    <CheckboxWithLoading
      disabled={isAdmin}
      checked={isActive}
      onChange={async (value) => {
        await dispatch(setProjectMemberActivity({ projectId, userId: id, isActive: value.target.checked }))
      }}
    />
  )
}

export default SelectUserIsActive
