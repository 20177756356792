import type { BackendLoading } from 'redux/redux.shared'

export interface GlobalPermissions {
  isAdmin: boolean
}

export interface UserAccountData {
  name: string
  organization: string
  id: number
}

export const loadingType = ['loginUser', 'initialTokenRefresh', 'logoutUser', 'fetchAccountData'] as const
export type LoadingType = typeof loadingType[number]

export interface LoginState {
  loading: Record<LoadingType, BackendLoading>
  isAuthenticated: boolean
  accessToken?: string
  refreshToken?: string
  permissions?: GlobalPermissions
  accountData?: UserAccountData
  redirectLink?: string
}
