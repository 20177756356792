import Text from 'components/atoms/text'
import styled from 'styled-components'

interface BackupSettingDisplayProps {
  label: React.ReactNode
  value: React.ReactNode
}

const StyledSpace = styled.div`
  display: table-row;
  > * {
    display: table-cell;
    padding-bottom: ${(props) => props.theme.sizes.s};
  }
  > :first-child {
    padding-right: 10px;
  }
`

const BackupSettingDisplay: React.FC<BackupSettingDisplayProps> = ({ label, value }) => {
  return (
    <StyledSpace>
      <div style={{ display: 'table-cell', paddingRight: '10px' }}>
        <Text paragraph textStyle="regularText-small">
          {label}:
        </Text>
      </div>
      <div style={{ display: 'table-cell', maxWidth: '150px' }}>
        <Text textStyle="regularText-smallSemiBold" paragraph>
          {value}
        </Text>
      </div>
    </StyledSpace>
  )
}

export default BackupSettingDisplay
