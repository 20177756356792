import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import { removedErrorsFromLoading } from 'helpers/HttpError.helpers'
import { Discipline } from 'models/administration'
import PortfolioProject from 'models/portfolio'
import {
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
} from 'redux/redux.shared'
import { loadingTypes, LoadingTypes, UserPermission, UserPermissionState } from './UserPermissions.types'

export const fetchProjectDisciplines = createAsyncBackendThunk('fetchProjectDisciplines', async (projectId: number) => {
  return (await backendAxios.get(`/projects/${projectId}/disciplines/`)).data
})

export const fetchProjects = createAsyncBackendThunk('fetchProjects', async () => {
  return (await backendAxios.get(`/projects/`, { params: { active: true } })).data
})

export const fetchPermissions = createAsyncBackendThunk('fetchUserProjectPermissions', async (projectId: number) => {
  return (await backendAxios.get(`/projects/${projectId}/members/permissions/current/`)).data
})

const initialState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  disciplines: [],
  permissions: [],
  projects: [],
} as UserPermissionState

const userProfilePermissionSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setSelectedProject: (state, action: PayloadAction<number | undefined>) => {
      state.selectedProject = action.payload
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<UserPermissionState, LoadingTypes, PortfolioProject[]>({
      promise: fetchProjects,
      loadingType: 'fetchProjects',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        state.projects = action.payload
      },
    }),
    generateExtraBackendReducers<UserPermissionState, LoadingTypes, Discipline[]>({
      promise: fetchProjectDisciplines,
      loadingType: 'fetchProjectDisciplines',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        state.disciplines = action.payload
      },
    }),
    generateExtraBackendReducers<UserPermissionState, LoadingTypes, UserPermission[]>({
      promise: fetchPermissions,
      loadingType: 'fetchUserProjectPermissions',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        state.permissions = action.payload
      },
    }),
  ]),
})

export const { setSelectedProject } = userProfilePermissionSlice.actions

export const userProfilePermissionReducer = userProfilePermissionSlice.reducer
