import { Divider, Row, Spin } from 'antd'
import Text from 'components/atoms/text'
import themePxValueToNumber from 'helpers/Styles.helpers'
import { useDispatch, useSelector } from 'react-redux'
import { selectAreNewNotificationsLoading, selectNewNotifications } from 'redux/notifications/Notifications.selectors'
import styled from 'styled-components'
import React from 'react'
import useInfiniteScroll from 'hooks/infiniteScroll'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router-dom'
import { hideNotificationsPopover, readNotifications } from 'redux/notifications'
import TooltipElement from 'components/molecules/tooltip-element'
import Icon from 'components/atoms/icon'
import { ReadOutlined } from '@ant-design/icons'
import WithClickLoadingWrapper from 'components/atoms/with-click-loading'
import { t, Trans } from '@lingui/macro'
import NotificationsPopoverNotification from './NotificationsPopoverNotification'

const StyledDiv = styled.div`
  width: 550px;
`

const StyledDivider = styled(Divider)`
  width: calc(100% + ${(props) => themePxValueToNumber(props.theme.sizes.m) * 2 - 8}px);
  margin: 16px -${(props) => themePxValueToNumber(props.theme.sizes.m) - 4}px;
`

const StyledRow = styled(Row)`
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 -${(props) => themePxValueToNumber(props.theme.sizes.m)}px;
  padding: 0 ${(props) => props.theme.sizes.m};
  > * {
    width: 100%;
  }
`

const NotificationsPopoverContent: React.FC = () => {
  const newNotifications = useSelector(selectNewNotifications)
  const { currentData, getMoreData, hasMore } = useInfiniteScroll(newNotifications, 10)
  const areNewNotificationsLoading = useSelector(selectAreNewNotificationsLoading)
  const dispatch = useDispatch()
  const unreadNotificationsIds = newNotifications.filter(({ isRead }) => !isRead).map((notification) => notification.id)
  const unreadNotificationsExist = unreadNotificationsIds.length !== 0
  return (
    <StyledDiv>
      <Spin spinning={areNewNotificationsLoading.isLoading}>
        <StyledRow id="scrollableNotificationsContainer">
          {currentData.length !== 0 && (
            <InfiniteScroll
              style={{ overflow: 'visible' }}
              dataLength={currentData.length}
              next={getMoreData}
              hasMore={hasMore}
              loader={<Spin />}
              scrollableTarget="scrollableNotificationsContainer"
            >
              {currentData.map((notification, idx) => (
                <React.Fragment key={notification.id}>
                  <NotificationsPopoverNotification notification={notification} />
                  {idx !== currentData.length - 1 && <StyledDivider />}
                </React.Fragment>
              ))}
            </InfiniteScroll>
          )}
          {currentData.length === 0 && !areNewNotificationsLoading.isLoading && (
            <Text textStyle="regularText-small">
              <Trans id="notifications.popover.no_new_notifications_text">No new notifications</Trans>
            </Text>
          )}
        </StyledRow>
      </Spin>
      <StyledDivider />
      <Row justify="space-between" align="middle">
        <TooltipElement title={t({ id: 'notifications.popover.mark_all_as_read', message: 'Mark all as read' })}>
          <WithClickLoadingWrapper
            onClick={unreadNotificationsExist ? () => dispatch(readNotifications(unreadNotificationsIds)) : undefined}
          >
            <Icon size="default" color={unreadNotificationsExist ? 'baseBlackish' : 'disabled'}>
              <ReadOutlined />
            </Icon>
          </WithClickLoadingWrapper>
        </TooltipElement>
        <Link to="/notifications" onClick={() => dispatch(hideNotificationsPopover())}>
          <Text textStyle="regularText-smallSemiBold" color="primaryP04">
            <Trans id="notifications.popover.open_notifications_panel">Open notifications panel</Trans>
          </Text>
        </Link>
      </Row>
    </StyledDiv>
  )
}

export default NotificationsPopoverContent
