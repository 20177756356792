import styled, { Color } from 'styled-components'

export interface CdeIconProps {
  status: CdeIconStatus
  onClick?: () => void
}

interface BaseCdeIconProps extends CdeIconProps {
  icon: JSX.Element
}

export type CdeIconStatus = 'ON' | 'OFF' | 'ON/OFF' | 'DISABLE' | 'REQUEST'

const iconBackgroundDict: Record<CdeIconStatus, Color> = {
  OFF: 'transparent',
  ON: 'cdeOn',
  'ON/OFF': 'primaryP04',
  DISABLE: 'grayG02',
  REQUEST: 'cdeRequest',
}

const iconColorDict: Record<CdeIconStatus, Color> = {
  OFF: 'baseBlackish',
  ON: 'baseWhiteish',
  'ON/OFF': 'baseWhiteish',
  DISABLE: 'grayG06',
  REQUEST: 'baseWhiteish',
}

interface StyledDivProps {
  $status: CdeIconStatus
  onClick?: () => void
}

const StyledDiv = styled.div<StyledDivProps>`
  border: 1px solid ${(props) => props.theme.colors.grayG01};
  width: 32px;
  height: 32px;
  ${(props) => props.theme.mixins.flexCenter};
  background-color: ${(props) => props.theme.colors[iconBackgroundDict[props.$status]]};
  cursor: ${({ $status, onClick }) => ($status !== 'DISABLE' && onClick ? 'pointer' : 'auto')};
  svg {
    fill: ${({ $status, theme }) => theme.colors[iconColorDict[$status]]};
  }
`

const BaseCdeIcon: React.FC<BaseCdeIconProps> = ({ icon, status, onClick }) => {
  return (
    <StyledDiv onClick={onClick} $status={status}>
      {icon}
    </StyledDiv>
  )
}

export default BaseCdeIcon
