import { defineMessage } from '@lingui/macro'

export const acceptOptionLabel = defineMessage({
  id: 'project.flows.modal.decision.accept.label',
  message: 'Accept',
})
export const acceptOptionDescription = defineMessage({
  id: 'project.flows.modal.decision.accept.description',
  message: 'File verification did not show any errors',
})
export const acceptWithCommentOptionLabel = defineMessage({
  id: 'project.flows.modal.decision.accept_comment.label',
  message: 'Accept with comment - AVAILABLE SOON',
})
export const acceptWithCommentOptionDescription = defineMessage({
  id: 'project.flows.modal.decision.accept_comment.description',
  message: 'Add file with comments - AVAILABLE SOON',
})
export const rejectLabel = defineMessage({
  id: 'project.flows.modal.decision.reject.label',
  message: 'Reject',
})
export const rejectDescription = defineMessage({
  id: 'project.flows.modal.decision.reject.description',
  message: 'The uploaded file does not meet the requirements',
})
export const rejectWithCommentOptionLabel = defineMessage({
  id: 'project.flows.modal.decision.reject_comment.label',
  message: 'Reject with comment - AVAILABLE SOON',
})
export const rejectWithCommentOptionDescription = defineMessage({
  id: 'project.flows.modal.decision.reject_comment.description',
  message: 'Add file with comments - AVAILABLE SOON',
})
