import { ErrorAlertProps, ErrorDictRecord } from 'helpers/HttpError.helpers'
import BaseErrorAlert from './BaseErrorAlert'

const generateErrorMessage = (errorsDict: ErrorDictRecord[]): string =>
  errorsDict
    .filter(({ error }) => error)
    .map(({ message }) => message)
    .join(' ')

const ErrorAlert: React.FC<ErrorAlertProps> = ({ alertMessageDict }) => {
  const errorMessage = generateErrorMessage(alertMessageDict)
  return <div>{errorMessage && <BaseErrorAlert description={errorMessage} />}</div>
}

export default ErrorAlert
