import { Trans } from '@lingui/macro'
import BaseFormItem from 'components/molecules/form-item/BaseFormItem'
import RadioGroup from 'components/molecules/radio/RadioGroup'
import { FlowDecisionConfig, reviewerSelectionMethods } from 'models/flow'
import { setDecisionFlowValues } from 'redux/flow-conifg/decisions/FlowDecisionsConfig.slice'
import { useAppDispatch } from 'redux/store'
import { reviewerSelectionMethodsDict } from '../FlowDecision.helpers'

interface FlowGeneralConfigFormProps {
  flow: FlowDecisionConfig
}

const ReviewersSelectionMethodRadio: React.FC<FlowGeneralConfigFormProps> = ({ flow }) => {
  const dispatch = useAppDispatch()
  return (
    <BaseFormItem
      label={<Trans id="flow_config.decisions.reviewers_selection_method_radio_label">Reviewer selection method</Trans>}
      tooltip={
        <Trans id="flow_config.decisions.reviewers_selection_method_radio_tooltip">
          User from selected group will be selected using chosen algorithm.
        </Trans>
      }
    >
      <RadioGroup
        selectedValue={flow.reviewerSelectionMethod}
        options={reviewerSelectionMethods.map((value) => ({
          label: reviewerSelectionMethodsDict[value],
          value: value as string,
        }))}
        onChange={(e) => dispatch(setDecisionFlowValues({ ...flow, reviewerSelectionMethod: e.target.value }))}
      />
    </BaseFormItem>
  )
}

export default ReviewersSelectionMethodRadio
