import { t } from '@lingui/macro'
import ErrorAlert from 'components/atoms/error-alert'
import { useSelector } from 'react-redux'
import {
  selectFetchAvailableMetadataLoading,
  selectFetchNamingSchemeLoading,
  selectUpdateNamingSchemeLoading,
} from 'redux/project/project-config/naming-scheme/ProjectNamingSchemeConfig.selectors'

const ProjectNamingSchemeAlerts: React.FC = () => {
  const fetchNamingSchemeLoading = useSelector(selectFetchNamingSchemeLoading)
  const fetchAvailableMetadataLoading = useSelector(selectFetchAvailableMetadataLoading)
  const updateNamingSchemeLoading = useSelector(selectUpdateNamingSchemeLoading)
  const alertMessageDict = [
    {
      error: fetchNamingSchemeLoading.error,
      message: t({
        id: 'project_config.naming_scheme.alert.scheme_fetch_failed',
        message: 'Could not retrieve files naming scheme.',
      }),
    },
    {
      error: fetchAvailableMetadataLoading.error,
      message: t({
        id: 'project_config.naming_scheme.alert.metadata_fetch_failed',
        message: 'Could not retrieve attributes.',
      }),
    },
    {
      error: updateNamingSchemeLoading.error,
      message: t({
        id: 'project_config.naming_scheme.alert.scheme_update_failed',
        message: 'Could not update files naming scheme.',
      }),
    },
  ]
  return <ErrorAlert alertMessageDict={alertMessageDict} />
}

export default ProjectNamingSchemeAlerts
