import { t, Trans } from '@lingui/macro'
import { ColumnsType } from 'antd/lib/table'
import useTableColumnFiltering, { onFilterStringColumn } from 'components/molecules/table/useTableColumnFiltering'
import CheckboxElement from 'components/molecules/checkbox'
import { actionsTableColumnText } from 'data/messages/controls'
import { noText, yesText } from 'data/messages/misc'
import trans from 'helpers/i18n.helpers'
import { Member } from 'models/project/projectConfig'
import SelectUserIsActive from './SelectUserIsActive'
import UserActions from './UserActions'
import UserDataDisplay from './UserDataDisplay'

const useMembersColumns = (): ColumnsType<Member> => {
  return [
    {
      title: <Trans id="project_config.members.table.colum_user_label">User</Trans>,
      dataIndex: 'user',
      key: 'user',
      width: 250,
      render: (value, { name, email, organization, isUserActive }) => (
        <UserDataDisplay
          name={name}
          email={email}
          organization={organization.abbreviation}
          isDisabled={!isUserActive}
        />
      ),
      ...useTableColumnFiltering(
        t({ id: 'project_config.members.table.search_user_placeholder', message: 'Search for user' })
      ),
      onFilter: (value: string | number | boolean, record: Member) =>
        onFilterStringColumn(value, `${record.name} ${record.organization.abbreviation} ${record.email}`),
    },
    {
      title: <Trans id="project_config.members.table.colum_access_label">Active access</Trans>,
      dataIndex: 'isActive',
      key: 'isActive',
      width: 110,
      render: (value, { id, isActive, isAdmin }) => (
        <SelectUserIsActive id={id} isActive={isActive} isAdmin={isAdmin} />
      ),
      filters: [
        { text: trans(yesText), value: true },
        { text: trans(noText), value: false },
      ],
      onFilter: (value, record) => record.isActive === value,
    },
    {
      title: <Trans id="project_config.members.table.colum_admin_label">Administration permissions</Trans>,
      dataIndex: 'isAdmin',
      key: 'isAdmin',
      width: 110,
      render: (value, { isAdmin }) => <CheckboxElement checked={isAdmin} disabled />,
      filters: [
        { text: trans(yesText), value: true },
        { text: trans(noText), value: false },
      ],
      onFilter: (value, record) => record.isAdmin === value,
    },
    {
      title: trans(actionsTableColumnText),
      dataIndex: 'actions',
      key: 'actions',
      width: 60,
      render: (value, { id, isAdmin, canBeRemoved, name }) => (
        <UserActions id={id} isAdmin={isAdmin} canBeRemoved={canBeRemoved} name={name} />
      ),
    },
  ]
}

export default useMembersColumns
