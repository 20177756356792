import { ProjectStageWithUsage } from 'models/administration'
import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import { AdministrationProjectStagesState } from './AdministrationProjectStages.types'

const selectProjectStages: (state: RootState) => AdministrationProjectStagesState = (state) =>
  state.administration.projectStages

export const selectAdministrationProjectStages: (state: RootState) => ProjectStageWithUsage[] = (state) =>
  selectProjectStages(state).stages

export const selectFetchingProjectStagesLoading: (state: RootState) => BackendLoading = (state) =>
  selectProjectStages(state).loading.fetchProjectStages

export const selectUpdatingProjectStageLoading: (state: RootState) => BackendLoading = (state) =>
  selectProjectStages(state).loading.updateProjectStage

export const selectAddingProjectStageLoading: (state: RootState) => BackendLoading = (state) =>
  selectProjectStages(state).loading.addProjectStage

export const selectRemovingProjectStageLoading: (state: RootState) => BackendLoading = (state) =>
  selectProjectStages(state).loading.removeProjectStage

export const selectIsProjectStageModalVisible: (state: RootState) => boolean = (state) =>
  selectProjectStages(state).modalState.isModalVisible

export const selectProjectStageDisplayedInModal: (state: RootState) => ProjectStageWithUsage | undefined = (state) =>
  selectProjectStages(state).modalState.stage
