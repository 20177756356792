import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectFlowAttributesConfigValidity } from 'redux/flow-conifg/attributes/FlowAttributesConfig.selectors'
import { fetchFlowData, updateFlowAttributes } from 'redux/flow-conifg/attributes/FlowAttributesConfig.slice'
import { selectIsDecisionsConfigContentLoading } from 'redux/flow-conifg/decisions/FlowDecisionsConfig.selectors'
import { selectFlowId } from 'redux/flow-conifg/general/FlowGeneralConfig.selectors'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import BaseFlowConfigView from '../base-flow-config-view/BaseFlowConfigView'
import FlowAttributesConfigContent from './content/FlowAttributesConfigContent'

const FlowAttributesConfig: React.FC = () => {
  const isLoading = useSelector(selectIsDecisionsConfigContentLoading)
  const flowAttributesValidity = useSelector(selectFlowAttributesConfigValidity)
  const dispatch = useDispatch()
  const projectId = useSelector(selectProjectId)
  const flowId = useSelector(selectFlowId)

  useEffect(() => {
    if (projectId && flowId) {
      dispatch(fetchFlowData({ flowId, projectId }))
    }
  }, [dispatch, flowId, projectId])

  if (!projectId || !flowId) return null
  const savingThunk = updateFlowAttributes({ projectId, flowId })
  return (
    <BaseFlowConfigView
      isContentValid={flowAttributesValidity}
      saveStepThunk={savingThunk}
      content={<FlowAttributesConfigContent />}
      isContentLoading={isLoading}
      stepName="METADATA"
    />
  )
}

export default FlowAttributesConfig
