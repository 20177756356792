import { defineMessage } from '@lingui/macro'

export const backupCreationPeriodText = defineMessage({
  id: 'administration.backups.backup_creation_period_label',
  message: 'Create backup',
})
export const backupDaysText = defineMessage({
  id: 'administration.backups.backup_creation_day_general_label',
  message: 'Day(s)',
})
export const backupTimeText = defineMessage({
  id: 'administration.backups.backup_creation_time_label',
  message: 'Time',
})
export const numberOfStoredBackupsText = defineMessage({
  id: 'administration.backups.stored_backups_number_label',
  message: 'Number of stored backups',
})
export const backupCountdownText = defineMessage({
  id: 'administration.backups.backup_creation_countdown_label',
  message: 'Backup will be created in',
})
