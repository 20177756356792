import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { Close } from '@material-ui/icons'
import Icon from 'components/atoms/icon'
import Text from 'components/atoms/text'
import { hideDetails } from 'redux/files/files-details'

interface FilesDetailsHeadingProps {
  title: string
  icon: JSX.Element
}

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  > *:not(:last-child) {
    margin-right: ${(props) => props.theme.sizes.s};
  }
  > *:last-child {
    margin-left: auto;
  }
`

const FilesDetailsHeading: React.FC<FilesDetailsHeadingProps> = ({ title, icon }) => {
  const dispatch = useDispatch()
  return (
    <StyledDiv>
      <Icon color="blue4" size="m">
        {icon}
      </Icon>
      <Text textStyle="header-H2b">{title}</Text>
      <Icon size="default" onClick={() => dispatch(hideDetails())}>
        <Close />
      </Icon>
    </StyledDiv>
  )
}

export default FilesDetailsHeading
