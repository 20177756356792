import { Col, Row } from 'antd'
import NotificationReadStatus from 'components/molecules/notifications/NotificationReadStatus'
import { Notification } from 'models/notifications'
import moment from 'moment'
import 'moment/locale/pl'
import styled from 'styled-components'
import NotificationDescription from 'components/molecules/notifications/notifications-description'

interface NotificationsPopoverNotificationProps {
  notification: Notification
}

const StyledRow = styled(Row)`
  width: 100%;
  .ant-space {
    width: 100%;
  }
  .ant-col {
    &:nth-child(1) {
      .ant-space-item:last-child {
        width: 90%;
      }
    }
    &:nth-child(2) {
      .ant-space-item:last-child {
        width: 70%;
      }
    }
  }
`

const NotificationsPopoverNotification: React.FC<NotificationsPopoverNotificationProps> = ({ notification }) => {
  const { creationDate, message, details, isRead, id } = notification
  return (
    <StyledRow gutter={24} align="top" wrap={false}>
      <Col span={5}>
        <NotificationReadStatus isRead={isRead} dateStr={moment(creationDate).fromNow()} id={id} />
      </Col>
      <Col span={19}>
        <NotificationDescription isRead={isRead} details={details} message={message} id={id} />
      </Col>
    </StyledRow>
  )
}

export default NotificationsPopoverNotification
