import { t } from '@lingui/macro'
import { FieldData } from 'models/components'
import NameAndId from 'models/shared'

export function updateMapEntry<K, V>(map: Map<K, V>, key: K, value: V): Map<K, V> {
  const updatedMap = new Map(map)
  updatedMap.set(key, value)
  return updatedMap
}

export function mapKeys<K, V>(map: Map<K, V>): K[] {
  return Array.from(map.keys())
}

export function mapEntries<K, V>(map: Map<K, V>): [K, V][] {
  return Array.from(map.entries())
}

export const isUndefinedOrEmpty = (array: unknown[] | undefined): boolean => array === undefined || array.length === 0
export const isDefinedAndNotEmpty = (array: unknown[] | undefined): boolean => array !== undefined && array.length > 0

export function uniqueArr<T>(array: T[]): T[] {
  return Array.from(new Set(array))
}

export function itemArr<T>(item: T, condition?: boolean): T[] {
  return condition ? [item] : []
}

export function findFieldValueByName<T>(fields: FieldData<T>[], name: string): T | undefined {
  return fields.find((field) => field.name === name || (Array.isArray(field.name) && field.name[0] === name))?.value
}

export function arrayContainsItem<T>(arr: T[] | undefined, item: T): boolean {
  return !!arr && !!arr.find((arrItem) => arrItem === item)
}

export function moveArrayItemToNewIndex<T>(arr: T[], oldIdx: number, newIdx: number): T[] {
  if (oldIdx >= arr.length) {
    let k = newIdx - arr.length + 1
    while (k) {
      arr.push()
      k -= 1
    }
  }
  arr.splice(newIdx, 0, arr.splice(oldIdx, 1)[0])
  return arr
}

export function notEmpty<T>(item: T | undefined | null): item is T {
  return item !== undefined && item !== null
}

export function arrFromArrOrUndefined<T>(arr: T[] | undefined): T[] {
  return arr || []
}

export function range(end: number): number[] {
  return Array.from({ length: end }, (x, i) => i)
}

export function areArraysEqual<T>(arr1: T[], arr2: T[]): boolean {
  return arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index])
}

export function arraysHaveSameElements<T>(
  arr1: T[],
  arr2: T[],
  compareFn?: ((a: T, b: T) => number) | undefined
): boolean {
  return areArraysEqual(arr1.sort(compareFn), arr2.sort(compareFn))
}

export const getOptionsFromNameAndId = <T extends NameAndId>(
  data: T[] | undefined
): { value: number; label: string }[] | undefined => data?.map(({ id, name }) => ({ value: id, label: name }))

export const withStepTitles = <T extends Record<string, unknown>>(stepsData: T[]): (T & { title: string })[] =>
  stepsData.map((data, idx) => ({
    ...data,
    title: t({ id: 'common.misc.step_with_number_text', message: `Step ${idx + 1}` }),
  }))

export const getUndefinedIfNegative = (num?: number): number | undefined => (num && num >= 0 ? num : undefined)

export const withIdKeys = <T extends { id: number | string }>(arr: T[]): (T & { key: number | string })[] =>
  arr.map((item) => ({ ...item, key: item.id }))

export function sortByDict<T extends string>(dict: Record<T, number>): (x: T, y: T) => number {
  return (x: T, y: T) => dict[x] - dict[y]
}
