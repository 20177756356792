import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { CaretLeftFilled, DownOutlined, FolderOutlined } from '@ant-design/icons'
import { selectProjectEntries } from 'redux/files/files-documentation/FilesDocumentation.selectors'
import { selectProjectDetails } from 'redux/project/project-details/ProjectDetails.selectors'
import { ProjectEntries, Group, GroupValue, DetailsItem, TreeNode } from 'models/files'
import Icon from 'components/atoms/icon'
import { generateAttribute, getDirectoriesKeysFromPath } from 'helpers/Files.helpers'
import useCurrentPath from 'hooks/query/currentPath'
import StyledFilesDocumentationTree from './FilesDocumentationTree.styles'
import useDocumentationRouting from './useDocumentationRouting'

interface FilesDocumentationTreeProps {
  isVisible: boolean
  toggleVisibility: (e: React.MouseEvent<HTMLElement>) => void
}

const withChildren = (
  projectEntries: ProjectEntries | undefined,
  parentPath?: GroupValue['name'],
  parentAttributes: DetailsItem[] = []
): TreeNode[] => {
  if (!projectEntries) return []
  const groupToNodeData = (group: Group) => {
    const { grouping, value, frontendFiles, lastUpdated } = group
    const displayName: GroupValue['name'] = value.name
    const path = `${parentPath ? `${parentPath}/` : ''}${displayName}`
    const dirAttribute = generateAttribute(projectEntries.key, projectEntries.value, value.name)
    const attributes: DetailsItem[] = [...parentAttributes, ...(dirAttribute ? [dirAttribute] : [])]
    const children = withChildren(grouping, path, attributes)
    const isLeaf = !!frontendFiles
    return {
      key: path,
      id: value.id,
      selectedGroup: {
        containFrontendFiles: isLeaf,
        groups: grouping?.groups,
        frontendFiles,
        displayName,
        isRoot: false,
        lastUpdated,
        attributes,
        key: grouping?.key || projectEntries.key,
        value: grouping?.value || projectEntries.value,
      },
      title: displayName,
      children,
      icon: isLeaf ? <FolderOutlined /> : undefined,
    }
  }
  return projectEntries.groups.map(groupToNodeData)
}

const generateTreeData = (
  projectEntries: ProjectEntries | undefined,
  projectName: string,
  toggleVisibility: (e: React.MouseEvent<HTMLElement>) => void
): TreeNode[] => [
  {
    key: projectName,
    title: (
      <span className="title-content">
        <span className="title-text">{projectName}</span>
        <span className="title-icon">
          <Icon onClick={toggleVisibility} size="default" color="grayG05">
            <CaretLeftFilled />
          </Icon>
        </span>
      </span>
    ),
    selectedGroup: {
      containFrontendFiles: false,
      groups: projectEntries?.groups,
      displayName: projectName,
      isRoot: true,
      attributes: [],
      key: projectEntries?.key,
      value: projectEntries?.value,
    },
    children: withChildren(projectEntries, projectName),
  },
]
const FilesDocumentationTree: React.FC<FilesDocumentationTreeProps> = ({ isVisible, toggleVisibility }) => {
  const projectEntries = useSelector(selectProjectEntries)
  const projectDetails = useSelector(selectProjectDetails)
  const projectName = projectDetails ? projectDetails.name : ''
  const pathParam = useCurrentPath()
  const [expandedKeys, setExpandedKeys] = useState(getDirectoriesKeysFromPath(pathParam || '') as React.Key[])
  const treeData = useMemo(() => generateTreeData(projectEntries, projectName, toggleVisibility), [
    projectEntries,
    projectName,
    toggleVisibility,
  ])

  const onExpand = (keys: React.Key[]) => {
    setExpandedKeys(keys)
  }

  const { onSelect } = useDocumentationRouting({ projectName, treeData, setExpandedKeys })

  if (!projectEntries || !projectDetails) return null
  return (
    <StyledFilesDocumentationTree
      $isVisible={isVisible}
      onSelect={onSelect}
      selectedKeys={pathParam ? [pathParam] : undefined}
      onExpand={onExpand}
      expandedKeys={expandedKeys}
      switcherIcon={<DownOutlined />}
      treeData={treeData}
    />
  )
}

export default FilesDocumentationTree
