import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios'
import { parseString } from 'xml2js'
import backendAxios from 'axios/axios'
import {
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  BackendLoading,
  generateInitialLoadingState,
} from 'redux/redux.shared'
import { ProjectLayer, WmsService } from 'models/map'
import { CORS_ANYWHERE_URL } from 'environment/environment'
import { notEmpty } from 'helpers/Collection.helpers'

const loadingTypes = ['fetchWmsLayers'] as const
type LoadingTypes = typeof loadingTypes[number]

export interface WmsLayersState {
  loading: Record<LoadingTypes, BackendLoading>
  layers: ProjectLayer[]
  checkedLayerNodes: (string | number)[]
  layersInfo: LayerInfo[]
}

interface WmsLayerDto {
  Title: string
  Layer?: WmsLayerDto[]
  Name?: string[]
}

export interface LayerInfo {
  url: string
  visibleLayers: string[]
}

export const fetchWmsLayers = createAsyncBackendThunk('layers/fetchWmsLayers', async () => {
  const servicesResponse = await backendAxios.get(`/wms-services/`)
  const services = servicesResponse.data
  const promises: Promise<AxiosResponse>[] = services.map((service: WmsService) => {
    return axios.get(`${CORS_ANYWHERE_URL}/${service.url}`, {
      params: { SERVICE: 'WMS', REQUEST: 'GetCapabilities', language: 'pol' },
    })
  })
  const responses = await Promise.allSettled(promises)
  const toProjectLayer = (wmsLayers: WmsLayerDto[], geoServerUrl: string | undefined): ProjectLayer[] => {
    return wmsLayers.map((wmsLayer: WmsLayerDto) => {
      const children = wmsLayer.Layer ? toProjectLayer(wmsLayer.Layer, geoServerUrl) : []
      const layer: ProjectLayer = { id: wmsLayer.Title[0], name: wmsLayer.Title[0], children, geoServerUrl }
      if (wmsLayer.Name) layer.gsLayersReferences = wmsLayer.Name
      return layer
    })
  }

  const layersInfo: LayerInfo[] = []
  const wmsLayers: ProjectLayer[] = responses
    .filter((response) => response.status === 'fulfilled')
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map((response) => response as PromiseFulfilledResult<AxiosResponse<any>>)
    .flatMap((response) => {
      try {
        layersInfo.push({
          url: response.value.config.url as string,
          visibleLayers: [],
        })
        const parseStringToProjectLayer = (
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          stringData: AxiosResponse<any>,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          parseFn: (result: any) => ProjectLayer[]
        ) => {
          let layers: ProjectLayer[] = []
          parseString(stringData, (err, result) => {
            layers = parseFn(result)
          })
          return layers
        }
        return parseStringToProjectLayer(response.value.data, (result) => {
          const capabilities = result.WMS_Capabilities || result.WMT_MS_Capabilities
          return capabilities.Capability.map((Capability: { Layer: WmsLayerDto[] }) =>
            toProjectLayer(Capability.Layer, response.value.config.url)
          )
        })
      } catch (e) {
        return undefined
      }
    })
    .filter(notEmpty)
    .flat()

  return { wmsLayers, layersInfo }
})

const wmsLayersSlice = createSlice({
  name: 'wmsLayers',
  initialState: {
    loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
    layers: [],
    checkedLayerNodes: [],
    layersInfo: [],
  } as WmsLayersState,
  reducers: {
    setCheckedWmsNodes: (state, action: PayloadAction<(string | number)[]>) => {
      state.checkedLayerNodes = action.payload
    },
    setVisibleWmsLayers: (
      state,
      action: PayloadAction<
        {
          references: string[] | undefined
          url: string | undefined
        }[]
      >
    ) => {
      state.layersInfo.forEach((info) => {
        info.visibleLayers = []
      })
      action.payload.forEach((node) => {
        state.layersInfo = state.layersInfo.map((info) => {
          if (info.url === node.url && node.references) {
            return { ...info, visibleLayers: [...info.visibleLayers, ...node.references] }
          }
          return info
        })
      })
    },
  },
  extraReducers: generateExtraBackendReducers<
    WmsLayersState,
    LoadingTypes,
    { wmsLayers: ProjectLayer[]; layersInfo: LayerInfo[] }
  >({
    promise: fetchWmsLayers,
    loadingType: 'fetchWmsLayers',
    onFulfilled: (state, action) => {
      state.layers = action.payload.wmsLayers
      state.layersInfo = action.payload.layersInfo
    },
  }),
})

export const { setCheckedWmsNodes, setVisibleWmsLayers } = wmsLayersSlice.actions

export const wmsLayersReducer = wmsLayersSlice.reducer
