import { BackendLoading } from 'redux/redux.shared'

export const loadingTypes = ['editCodeLength', 'fetchCodeLength', 'fetchCodeSettings'] as const
export type LoadingTypes = typeof loadingTypes[number]

export const CodeTypes = {
  ORGANIZATION_CODE: 'ORGANIZATION_CODE',
  DISCIPLINE_CODE: 'DISCIPLINE_CODE',
  PROJECT_CODE: 'PROJECT_CODE',
  SUITABILITY_CODE_CODE: 'SUITABILITY_CODE_CODE',
  PROJECT_STAGE_CODE: 'PROJECT_STAGE_CODE',
  WAITING_ROOM_SIZE: 'WAITING_ROOM_SIZE',
} as const

export type CodeType = keyof typeof CodeTypes

interface ModalData {
  isVisible: boolean
}

interface CodeSettingValues {
  id: number
  code: string
  name: string
}

export interface CodeSetting {
  length: number
  values: CodeSettingValues[]
}

export interface AdministrationCodeSettingsState {
  loading: Record<LoadingTypes, BackendLoading>
  codeLengthDict: Record<CodeType, number>
  codesData: Record<CodeType, CodeSetting>
  modalData: ModalData
}
