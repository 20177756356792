import { Space, Spin } from 'antd'
import Table from 'components/molecules/table'
import EditCodeLengthModal from 'components/organisms/administration/administration-content/modals/edit-code-length/EditCodeLengthModal'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { fetchCodeLength } from 'redux/administration/administration-code-settings'
import {
  selectCodeLengthModalVisibility,
  selectFetchingCodeLengthLoading,
} from 'redux/administration/administration-code-settings/AdministrationCodeSettings.selectors'
import { CodeTypes } from 'redux/administration/administration-code-settings/AdministrationCodeSettings.types'
import {
  selectFetchingDisciplinesLoading,
  selectAdministrationDisciplines,
  selectIsDisciplineModalVisible,
} from 'redux/administration/administration-disciplines/AdministrationDisciplines.selectors'
import { fetchDisciplines } from 'redux/administration/administration-disciplines'
import { useAppDispatch } from 'redux/store'
import styled from 'styled-components'
import { disciplineText } from 'data/messages/misc'
import trans from 'helpers/i18n.helpers'
import useAdministrationDisciplinesColumns from './columns/useAdministrationDisciplinesColumns'
import AdministrationDisciplinesAlerts from './AdministrationDisciplinesAlerts'
import DisciplineModal from './modals'

const StyledTable = styled(Table)`
  width: 750px;
  &&&& {
    td {
      &:nth-child(2) {
        text-align: center;
      }
    }
  }
`

const AdministrationDisciplines: React.FC = () => {
  const dispatch = useAppDispatch()
  const { isLoading: fetchingDisciplinesLoading } = useSelector(selectFetchingDisciplinesLoading)
  const { isLoading: fetchingCodeLoading } = useSelector(selectFetchingCodeLengthLoading)
  const administrationDisciplines = useSelector(selectAdministrationDisciplines)
  const disciplineModalVisibility = useSelector(selectIsDisciplineModalVisible)
  const columns = useAdministrationDisciplinesColumns()
  useEffect(() => {
    dispatch(fetchDisciplines())
    dispatch(fetchCodeLength(CodeTypes.DISCIPLINE_CODE))
  }, [dispatch])
  const isCodeModalVisible = useSelector(selectCodeLengthModalVisibility)
  return (
    <Spin spinning={fetchingDisciplinesLoading || fetchingCodeLoading}>
      {!fetchingDisciplinesLoading && !fetchingCodeLoading && (
        <Space direction="vertical" size="middle">
          <StyledTable
            columns={columns}
            dataSource={administrationDisciplines}
            bordered
            pagination={false}
            rowKey="id"
            scroll={{ y: 'calc(100vh - 310px)' }}
          />
          {disciplineModalVisibility && <DisciplineModal visible={disciplineModalVisibility} />}
          <AdministrationDisciplinesAlerts />
          {isCodeModalVisible && (
            <EditCodeLengthModal
              codeType="DISCIPLINE_CODE"
              data={administrationDisciplines}
              reloadDataFun={fetchDisciplines}
              nameFieldLabel={trans(disciplineText)}
            />
          )}
        </Space>
      )}
    </Spin>
  )
}

export default AdministrationDisciplines
