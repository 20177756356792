import { useDispatch, useSelector } from 'react-redux'
import { TreeProps } from 'rc-tree'
import { TreeNode } from 'components/molecules/map/map-sidedrawer-tree/MapSideDrawerTreeTypes'
import { setVisibleGisFileLayers, setCheckedGisFileNodes } from 'redux/map/map-gis-layers/map-gis-file-layers'
import { selectCheckedGisFileLayerNodes } from 'redux/map/map-gis-layers/map-gis-file-layers/MapGisFileLayers.selectors'
import MapSideDrawerTree from 'components/molecules/map/map-sidedrawer-tree/MapSideDrawerTree'
import { EntryLayers } from 'models/map'

interface GisFileSideDrawerTreeProps {
  data: EntryLayers[]
}

const GisFileSideDrawerTree: React.FC<GisFileSideDrawerTreeProps> = ({ data }) => {
  const checkedKeys = useSelector(selectCheckedGisFileLayerNodes)
  const dispatch = useDispatch()
  const handleNodeCheck: TreeProps['onCheck'] = (checked, info) => {
    const checkedNodes = info.checkedNodes.map((node: TreeNode) => node.key)
    dispatch(setCheckedGisFileNodes(checkedNodes))
    const visibleLayers = info.checkedNodes.flatMap((node: TreeNode) => node.gsLayersReferences || [])
    dispatch(setVisibleGisFileLayers(visibleLayers))
  }
  return <MapSideDrawerTree data={data} onCheck={handleNodeCheck} checkedKeys={checkedKeys} />
}

export default GisFileSideDrawerTree
