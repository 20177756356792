import { useSelector } from 'react-redux'
import {
  selectIsAttributesConfigValid,
  selectIsProjectAttributesContentLoading,
} from 'redux/project/project-config/attributes/ProjectAttributesConfig.selectors'
import { updateAttributesConfig } from 'redux/project/project-config/attributes/ProjectAttributesConfig.slice'
import BaseProjectConfigView from '../base-config-view/BaseProjectConfigView'
import ProjectAttributesConfigContent from './content'

const ProjectAttributesConfig: React.FC = () => {
  const isContentLoading = useSelector(selectIsProjectAttributesContentLoading)
  const isContentValid = useSelector(selectIsAttributesConfigValid)
  return (
    <BaseProjectConfigView
      isContentLoading={isContentLoading}
      content={<ProjectAttributesConfigContent />}
      saveStepThunk={updateAttributesConfig()}
      isContentValid={isContentValid}
    />
  )
}

export default ProjectAttributesConfig
