import { SuitabilityCode } from 'models/administration'
import { FlowDecisionConfig, ReviewersUsersTypes } from 'models/flow'
import { Member } from 'models/project/projectConfig'
import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import { FlowDecisionsConfigState } from './FlowDecisionsConfig.types'

const selectFlowDecisionsConfigState = (state: RootState): FlowDecisionsConfigState => state.flowConfig.decisions

export const selectDecidionsFlowConfig = (state: RootState): FlowDecisionConfig =>
  selectFlowDecisionsConfigState(state).flowDecisionConfig

export const selectIsReviewRequired = (state: RootState): boolean =>
  selectFlowDecisionsConfigState(state).flowDecisionConfig.reviewRequired

export const selectAfterInitSuitabilityCode = (state: RootState): number | undefined =>
  selectFlowDecisionsConfigState(state).flowDecisionConfig.afterInitSuitabilityCode

export const selectSuitabilityCodesConfig = (state: RootState): SuitabilityCode[] =>
  selectFlowDecisionsConfigState(state).codes

export const selectMembersConfig = (state: RootState): Member[] => selectFlowDecisionsConfigState(state).members

export const selectFlowDecisionConfigValidity = (state: RootState): boolean => {
  const {
    reviewRequired,
    reviewUsersList,
    reviewType,
    reviewerSelectionMethod,
    reviewersUsersType,
  } = selectDecidionsFlowConfig(state)
  const isReviewersListCorrect = reviewersUsersType !== ReviewersUsersTypes.SELECTED || reviewUsersList.length > 0
  const reviewFieldsValid = !reviewRequired || (!!reviewType && !!reviewerSelectionMethod && !!reviewersUsersType)
  return isReviewersListCorrect && reviewFieldsValid
}

export const selectUpdatingFlowDecisionsLoading = (state: RootState): BackendLoading =>
  selectFlowDecisionsConfigState(state).loading.updateFlowDecisions

export const selectGettingFlowDecisionsLoading = (state: RootState): BackendLoading =>
  selectFlowDecisionsConfigState(state).loading.getFlowDecisions

export const selectGettingSuitabilityCodesLoading = (state: RootState): BackendLoading =>
  selectFlowDecisionsConfigState(state).loading.fetchSuitabilityOverview

export const selectGettingMembersConfigLoading = (state: RootState): BackendLoading =>
  selectFlowDecisionsConfigState(state).loading.fetchProjectMembers

export const selectIsDecisionsConfigContentLoading = (state: RootState): boolean =>
  selectUpdatingFlowDecisionsLoading(state).isLoading ||
  selectGettingFlowDecisionsLoading(state).isLoading ||
  selectGettingSuitabilityCodesLoading(state).isLoading ||
  selectGettingMembersConfigLoading(state).isLoading
