import { defineMessage, t } from '@lingui/macro'
import { MessageErrorAlert } from 'components/atoms/error-alert'
import { handleApiErrors } from 'helpers/HttpError.helpers'
import { useSelector } from 'react-redux'
import {
  selectProjectMemberEditLoading,
  selectProjectMemberRemoveLoading,
  selectProjectMembersFetchingLoading,
} from 'redux/project/project-config/members/ProjectMembersConfig.selectors'

const editErrorMessages = {
  'user.is_project_admin': defineMessage({
    id: 'project_config.members.alert.user_is_project_admin_error',
    message: 'The user is the project administrator. He cannot be revoked from the project.',
  }),
}

const removeErrorMessages = {
  'user.has_data_in_project': defineMessage({
    id: 'project_config.members.alert.user_has_data_in_project_error',
    message: 'The user has added files to project. He cannot be removed from project.',
  }),
  'user.is_project_admin': defineMessage({
    id: 'project_config.members.alert.user_is_project_admin_error',
    message: 'The user is the project administrator. He cannot be revoked from the project.',
  }),
}

const ProjectAttributesConfigAlerts: React.FC = () => {
  const fetchMembersLoading = useSelector(selectProjectMembersFetchingLoading)
  const editMemberLoading = useSelector(selectProjectMemberEditLoading)
  const removeMemberLoading = useSelector(selectProjectMemberRemoveLoading)

  return (
    <div>
      {fetchMembersLoading.error && (
        <MessageErrorAlert
          message={t({ id: 'project_config.members.alert.member_fetch', message: 'Could not update user. ' })}
        />
      )}
      {editMemberLoading.error && (
        <MessageErrorAlert
          message={t({ id: 'project_config.members.alert.member_edit', message: 'Could not edit user. ' })}
          description={handleApiErrors(editErrorMessages, editMemberLoading.error.errors)}
        />
      )}
      {removeMemberLoading.error && (
        <MessageErrorAlert
          message={t({ id: 'project_config.members.alert.member_delete', message: 'Could not delete user. ' })}
          description={handleApiErrors(removeErrorMessages, removeMemberLoading.error.errors)}
        />
      )}
    </div>
  )
}

export default ProjectAttributesConfigAlerts
