import styled, { Size, Direction } from 'styled-components'

interface SpaceElementProps {
  size?: Size
  direction?: Direction
  className?: string
  children: React.ReactNode
}

interface StyledDivProps {
  readonly $size: Size
  readonly $direction: Direction
}

const StyledDiv = styled.div<StyledDivProps>`
  display: flex;
  ${(props) => `
  gap: ${props.theme.sizes[props.$size]};
  flex-wrap: wrap;
    ${props.$direction === 'vertical' ? `flex-direction: column;` : ''}
  `}
`

const SpaceElement: React.FC<SpaceElementProps> = ({ size = 's', children, direction = 'vertical', className }) => {
  return (
    <StyledDiv className={className} $size={size} $direction={direction}>
      {children}
    </StyledDiv>
  )
}

export default SpaceElement
