import { memo, FunctionComponent } from 'react'
import { CameraFlyTo } from 'resium'
import { Cartesian3 } from 'cesium'

interface CameraPosition {
  x: number
  y: number
  z: number
}

interface SurfaceNormalCameraProps {
  actualPosition: CameraPosition
}

const SurfaceNormalCamera: FunctionComponent<SurfaceNormalCameraProps> = ({ actualPosition }) => {
  return (
    <CameraFlyTo
      destination={Cartesian3.fromElements(actualPosition.x, actualPosition.y, actualPosition.z)}
      duration={0}
    />
  )
}

export default memo(
  SurfaceNormalCamera,
  (prevProps, nextProps) =>
    prevProps.actualPosition.x === nextProps.actualPosition.x &&
    prevProps.actualPosition.y === nextProps.actualPosition.y &&
    prevProps.actualPosition.z === nextProps.actualPosition.z
)
