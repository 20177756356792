import { Divider } from 'antd'
import { DividerProps } from 'antd/lib/divider'
import styled, { Size, Color } from 'styled-components'

interface DividerElementProps extends DividerProps {
  size?: Size
  color?: Color
}
interface StyledDividerProps {
  readonly $size: Size
  readonly type: 'horizontal' | 'vertical'
  readonly $color?: Color
}

const StyledDivider = styled(Divider)<StyledDividerProps>`
  margin: ${(props) =>
    props.type === 'horizontal' ? `${props.theme.sizes[props.$size]} 0` : `0 ${props.theme.sizes[props.$size]}`};
  ${(props) => props.$color && `border-color: ${props.theme.colors[props.$color]}`}
`

const DividerElement: React.FC<DividerElementProps> = ({ size = 's', type = 'horizontal', color, ...props }) => {
  return (
    <StyledDivider
      type={type}
      $size={size}
      $color={color}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
}

export default DividerElement
