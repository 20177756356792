import { createSlice } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import { removedErrorsFromLoading } from 'helpers/HttpError.helpers'
import { Flow } from 'models/flow'
import {
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
} from 'redux/redux.shared'
import { loadingTypes, LoadingTypes, ProjectFlowConfigState } from './ProjectFlowConfig.types'

export const fetchFlows = createAsyncBackendThunk('fetchFlows', async (projectId: number) => {
  return (await backendAxios.get(`/projects/${projectId}/flows/`)).data
})

export const deleteFlow = createAsyncBackendThunk(
  'deleteFlow',
  async ({ projectId, flowId }: { projectId: number; flowId: number }) => {
    await backendAxios.delete(`/projects/${projectId}/flows/${flowId}/`)
  }
)

const initialState: ProjectFlowConfigState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  flows: [],
}

const projectFlowConfigSlice = createSlice({
  name: 'projectFlowConfig',
  initialState,
  reducers: {},
  extraReducers: combine([
    generateExtraBackendReducers<ProjectFlowConfigState, LoadingTypes, Required<Flow>[]>({
      promise: fetchFlows,
      loadingType: 'fetchFlows',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        state.flows = action.payload
      },
    }),
    generateExtraBackendReducers<ProjectFlowConfigState, LoadingTypes, undefined, { flowId: number }>({
      promise: deleteFlow,
      loadingType: 'deleteFlow',
      onFulfilled: (state, action) => {
        state.flows = state.flows.filter(({ id }) => id !== action.meta.arg.flowId)
      },
    }),
  ]),
})

export const projectFlowConfigReducer = projectFlowConfigSlice.reducer
