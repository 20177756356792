import styled from 'styled-components'
import Text from 'components/atoms/text'

interface MapPerspectiveSwitcherItemProps {
  children: React.ReactNode
  active: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

interface StyledDivProps {
  $active: boolean
}

const StyledDiv = styled.div<StyledDivProps>`
  padding: ${(props) => props.theme.sizes.xs};
  cursor: ${(props) => (props.$active ? 'auto' : 'pointer')};
`

const MapPerspectiveSwitcherItem: React.FC<MapPerspectiveSwitcherItemProps> = ({ children, active, onClick }) => {
  return (
    <StyledDiv onClick={active ? undefined : onClick} $active={active}>
      <Text copyable={false} textStyle="regularText-smallSemiBold" color={active ? 'primaryP04' : 'grayG03'}>
        {children}
      </Text>
    </StyledDiv>
  )
}

export default MapPerspectiveSwitcherItem
