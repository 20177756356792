import { Trans } from '@lingui/macro'
import { useDispatch } from 'react-redux'
import { showAttributeModal } from 'redux/administration/administration-attributes'
import AdministrationToolbarButton from '../toolbar'

const AdministrationAttributesToolbar: React.FC = () => {
  const dispatch = useDispatch()
  return (
    <AdministrationToolbarButton
      text={<Trans id="administration.attributes.create_new_attribute_button_text">Create new attribute</Trans>}
      onClick={() => dispatch(showAttributeModal())}
    />
  )
}

export default AdministrationAttributesToolbar
