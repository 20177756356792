import CdePermissions from 'components/molecules/cde-permissions/CdePermissions'
import { CdeStatus } from 'models/files'
import { useSelector } from 'react-redux'
import { selectUsersPermissionsByDiscipline } from 'redux/user-profile/permissions/UserPermissions.selectors'

interface CdeStatusPermissionsProps {
  disciplineId: number
  cdeStatus: CdeStatus
}

const CdeStatusPermissions: React.FC<CdeStatusPermissionsProps> = ({ disciplineId, cdeStatus }) => {
  const permissions = useSelector(selectUsersPermissionsByDiscipline(disciplineId))
  return <CdePermissions cdeStatus={cdeStatus} permissions={permissions} singleUserMode />
}

export default CdeStatusPermissions
