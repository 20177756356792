import { defineMessage, t } from '@lingui/macro'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RcFile } from 'antd/lib/upload'
import backendAxios from 'axios/axios'
import { removedErrorsFromLoading } from 'helpers/HttpError.helpers'
import { openSuccessNotification } from 'helpers/Notifications.helpers'
import { formatAddedUsersMessage } from 'helpers/Users.helpers'
import { ActivationDetails, OrganizationDetails, User } from 'models/administration'
import NameAndId from 'models/shared'
import {
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
} from 'redux/redux.shared'
import {
  loadingTypes,
  LoadingTypes,
  AdministrationUsersState,
  NewUser,
  LoadUserError,
} from './AdministrationUsers.types'

export const fetchUsers = createAsyncBackendThunk('fetchUsers', async () => {
  const response = await backendAxios.get('/users/')
  return response.data
})

interface EditUserAccountArgs {
  id: number
  isAdmin?: boolean
  disciplines?: number[]
  email?: string
}

interface UpdateAccountActiveFieldArgs {
  id: number
  isAccountActive: boolean
}

interface UpdateResponse {
  isAccountActive: boolean
  isAdmin: boolean
  id: number
  organization: OrganizationDetails
  disciplines: NameAndId[]
  email: string
  activationDetails: ActivationDetails
}

interface LoadUsersFromFileResponse {
  users: User[]
  errors: LoadUserError[]
}

const userUpdatedMessage = defineMessage({
  id: 'administration.users.notifications.updated_text',
  message: 'User has been updated',
})

export const editAccountActivationField = createAsyncBackendThunk(
  'editAccountActivationField',
  async ({ id, isAccountActive }: UpdateAccountActiveFieldArgs) => {
    const response = await backendAxios.patch(`/users/${id}/`, { isAccountActive })
    openSuccessNotification({ message: t({ id: userUpdatedMessage.id }) })
    return response.data
  }
)

export const editUserAccount = createAsyncBackendThunk(
  'editUserAccount',
  async ({ id, isAdmin, disciplines, email }: EditUserAccountArgs) => {
    const response = await backendAxios.patch(`/users/${id}/`, { isAdmin, disciplines, email })
    openSuccessNotification({ message: t({ id: userUpdatedMessage.id }) })
    return response.data
  }
)

export const addUser = createAsyncBackendThunk('addUser', async (user: NewUser) => {
  const response = await backendAxios.post(`/users/`, user)
  openSuccessNotification({
    message: t({
      id: 'administration.users.notifications.added_text',
      message: 'User has been added',
    }),
  })
  return response.data
})

export const addUsersFromFile = createAsyncBackendThunk('addUsersFromFile', async (file: RcFile) => {
  const formData = new FormData()
  formData.append('file', file)
  const response = await backendAxios.post(`/users/load/`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  const addedUsersCount = response.data.users.length
  const errorsCount = response.data.errors.length
  openSuccessNotification({ message: formatAddedUsersMessage(addedUsersCount, errorsCount) })
  return response.data
})

export const deactivateLink = createAsyncBackendThunk('deactivateLink', async (id: number) => {
  const response = await backendAxios.post(`/users/account/${id}/actions/link/deactivate/`)
  openSuccessNotification({
    message: t({
      id: 'administration.users.notifications.link_invalidated_text',
      message: 'Account activation code has been invalidated',
    }),
  })
  return response.data
})

export const resendLink = createAsyncBackendThunk('resendLink', async (id: number) => {
  const response = await backendAxios.post(`/users/account/${id}/actions/link/resend/`)
  openSuccessNotification({
    message: t({
      id: 'administration.users.notifications.link_resent_text',
      message: 'Account activation code has been sent',
    }),
  })
  return response.data
})

const initialState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  modalData: { isModalVisible: false },
  users: [],
  fileModalData: { isVisible: false, isInfoVisible: false, loadErrors: [] },
} as AdministrationUsersState

const administrationUsersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    showUserModal: (state, user: PayloadAction<User | undefined>) => {
      state.modalData.isModalVisible = true
      state.modalData.user = user?.payload
      state.loading = removedErrorsFromLoading(state.loading)
    },
    hideUserModal: (state) => {
      state.modalData.isModalVisible = false
      state.modalData.user = undefined
      state.loading = removedErrorsFromLoading(state.loading)
    },
    setFileModalVisibility: (state, visible: PayloadAction<boolean>) => {
      state.fileModalData = { isVisible: visible.payload, isInfoVisible: false, loadErrors: [] }
      state.loading = removedErrorsFromLoading(state.loading)
    },
    setFileInfoModalVisible: (state, visible: PayloadAction<boolean>) => {
      state.fileModalData.isInfoVisible = visible.payload
      state.fileModalData.isVisible = visible.payload
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<AdministrationUsersState, LoadingTypes, User[]>({
      promise: fetchUsers,
      loadingType: 'fetchUsers',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        state.users = action.payload
      },
    }),
    generateExtraBackendReducers<AdministrationUsersState, LoadingTypes, UpdateResponse, UpdateAccountActiveFieldArgs>({
      promise: editAccountActivationField,
      loadingType: 'editAccountActivationField',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        const { id } = action.meta.arg
        const { isAccountActive } = action.payload
        state.users = state.users.map((user) => {
          if (user.id === id) {
            const activationDetails: ActivationDetails = {
              activationCodeExpiry: undefined,
              isActive: isAccountActive,
              deactivated: false,
            }
            return { ...user, activationDetails }
          }
          return user
        })
      },
    }),
    generateExtraBackendReducers<AdministrationUsersState, LoadingTypes, UpdateResponse, EditUserAccountArgs>({
      promise: editUserAccount,
      loadingType: 'editUserAccount',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        const { id } = action.meta.arg
        const { isAdmin, disciplines, email, activationDetails } = action.payload
        state.users = state.users.map((user) => {
          if (user.id === id) {
            return { ...user, isAdmin, disciplines, email, activationDetails }
          }
          return user
        })
        state.modalData = initialState.modalData
      },
    }),
    generateExtraBackendReducers<AdministrationUsersState, LoadingTypes, User>({
      promise: addUser,
      loadingType: 'addUser',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        const user = action.payload
        state.users.push(user)
        state.modalData.isModalVisible = false
      },
    }),
    generateExtraBackendReducers<AdministrationUsersState, LoadingTypes, ActivationDetails, number>({
      promise: deactivateLink,
      loadingType: 'deactivateLink',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        const id = action.meta.arg
        state.users = state.users.map((user) => {
          if (user.id === id) {
            return { ...user, activationDetails: action.payload }
          }
          return user
        })
      },
    }),
    generateExtraBackendReducers<AdministrationUsersState, LoadingTypes, ActivationDetails, number>({
      promise: resendLink,
      loadingType: 'resendLink',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        const id = action.meta.arg
        state.users = state.users.map((user) => {
          if (user.id === id) {
            return { ...user, activationDetails: action.payload }
          }
          return user
        })
      },
    }),
    generateExtraBackendReducers<AdministrationUsersState, LoadingTypes, LoadUsersFromFileResponse>({
      promise: addUsersFromFile,
      loadingType: 'addUsersFromFile',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        state.users = [...state.users, ...action.payload.users]
        if (action.payload.errors.length > 0) {
          state.fileModalData.loadErrors = action.payload.errors
          state.fileModalData.isInfoVisible = true
        } else {
          state.fileModalData.isVisible = false
        }
      },
    }),
  ]),
})

export const {
  showUserModal,
  hideUserModal,
  setFileModalVisibility,
  setFileInfoModalVisible,
} = administrationUsersSlice.actions

export const administrationUsersReducer = administrationUsersSlice.reducer
