import { useSelector } from 'react-redux'
import { MessageErrorAlert } from 'components/atoms/error-alert'
import { handleApiErrors } from 'helpers/HttpError.helpers'
import { defineMessage, t } from '@lingui/macro'
import {
  selectAddingProjectStageLoading,
  selectProjectStageDisplayedInModal,
  selectUpdatingProjectStageLoading,
} from 'redux/administration/administration-project-stages/AdministrationProjectStages.selectors'
import { invalidCodeErrorMessage } from 'data/messages/misc'

const errorMessageDict = {
  'project_stage.name_exists': defineMessage({
    id: 'administration.project_stages.name_already_exists',
    message: 'Project stage with given name already exists',
  }),
  'project_stage.code_exists': defineMessage({
    id: 'administration.project_stages.code_already_exists',
    message: 'Project stage with given code already exists',
  }),
  'project_stage.invalid_code_length': defineMessage({
    id: 'administration.project_stages.invalid_code_length',
    message: "Invalid project stage's code length",
  }),
  'codes.code_invalid': invalidCodeErrorMessage,
}

const ProjectStageModalError: React.FC = () => {
  const stage = useSelector(selectProjectStageDisplayedInModal)
  const { error: updateError } = useSelector(selectUpdatingProjectStageLoading)
  const { error: addError } = useSelector(selectAddingProjectStageLoading)

  if (stage === undefined) {
    return addError ? (
      <MessageErrorAlert
        message={t({
          id: 'administration.project_stages.modal.stage_adding_failed',
          message: 'Could not add project stage. ',
        })}
        description={handleApiErrors(errorMessageDict, addError.errors)}
      />
    ) : null
  }
  return updateError ? (
    <MessageErrorAlert
      message={t({
        id: 'administration.project_stages.modal.stage_editing_failed',
        message: 'Could not update project stage. ',
      })}
      description={handleApiErrors(errorMessageDict, updateError.errors)}
    />
  ) : null
}

export default ProjectStageModalError
