import { combineReducers } from 'redux'
import { wrapWithClear } from 'helpers/Functions.helpers'
import { projectConfigClear } from 'redux/action.types'
import { projectGeneralConfigReducer } from './general'
import { projectAttributesConfigReducer } from './attributes'
import { projectNamingSchemeConfigReducer } from './naming-scheme'
import { projectEntriesStructureConfigReducer } from './entries-structure'
import { projectActivationConfigReducer } from './activation'
import { projectMapConfigReducer } from './map'
import { projectMembersConfigReducer } from './members'
import { projectSuitabilityConfigReducer } from './suitability'
import { projectMembersPermissionsConfigReducer } from './members-permissions/ProjectMembersPermissionsConfig.slice'
import { projectWipGroupsConfigReducer } from './wip-groups'
import { projectStagesConfigReducer } from './stages'
import { projectStatusConfigReducer } from './status/ProjectStatusConfig.slice'
import { projectFlowConfigReducer } from './flow/ProjectFlowConfig.slice'
import { projectDisciplinesConfigReducer } from './disciplines'

const projectConfig = combineReducers({
  general: projectGeneralConfigReducer,
  map: projectMapConfigReducer,
  members: projectMembersConfigReducer,
  membersPermissions: projectMembersPermissionsConfigReducer,
  namingScheme: projectNamingSchemeConfigReducer,
  entriesStructure: projectEntriesStructureConfigReducer,
  activation: projectActivationConfigReducer,
  attributes: projectAttributesConfigReducer,
  disciplines: projectDisciplinesConfigReducer,
  suitability: projectSuitabilityConfigReducer,
  wipGroups: projectWipGroupsConfigReducer,
  stages: projectStagesConfigReducer,
  status: projectStatusConfigReducer,
  flow: projectFlowConfigReducer,
})

export default wrapWithClear(projectConfig, projectConfigClear)
