import { EditOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { removeAttribute, showAttributeModal } from 'redux/administration/administration-attributes'
import { getSelectAttributeByIdSelector } from 'redux/administration/administration-attributes/AdministrationAttributes.selectors'
import { useAppDispatch } from 'redux/store'
import { WithDividerElement } from 'components/atoms/divider'
import ButtonWithTooltip, { DeleteButton } from 'components/molecules/buttons'
import showConfirmationModal from 'helpers/Modals.helpers'
import { t } from '@lingui/macro'
import { deleteText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'

interface AttributeActionsProps {
  id: number
  canDelete: boolean
  name: string
}

const AttributeActions: React.FC<AttributeActionsProps> = ({ id, canDelete, name }) => {
  const dispatch = useAppDispatch()
  const attribute = useSelector(getSelectAttributeByIdSelector(id))
  const handleEditAttribute = () => {
    if (attribute !== undefined) {
      dispatch(showAttributeModal(attribute))
    }
  }
  return (
    <WithDividerElement>
      <ButtonWithTooltip
        onClick={async () => handleEditAttribute()}
        tooltipText={t({ id: 'administration.attributes.edit_attribute_tooltip', message: 'Edit attribute' })}
        icon={<EditOutlined />}
      />
      <DeleteButton
        onClick={() =>
          showConfirmationModal({
            content: t({
              id: 'administration.attributes.delete_confirmation_text',
              message: `Delete attribute "${name}" and all associated values?`,
            }),
            okText: trans(deleteText),
            onOk: async () => {
              await dispatch(removeAttribute(id))
            },
          })
        }
        disabled={!canDelete}
        tooltip={t({ id: 'administration.attributes.delete_attribute_tooltip', message: 'Delete attribute' })}
        disabledTooltip={t({
          id: 'administration.attributes.delete_used_attribute_tooltip',
          message: 'Attribute is used and cannot be deleted',
        })}
      />
    </WithDividerElement>
  )
}

export default AttributeActions
