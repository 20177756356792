import { createRef, useEffect, useState } from 'react'
import { IfcViewerAPI } from 'web-ifc-viewer'
import { Spin } from 'antd'

interface IfcViewerProps {
  ifcUrl: string
}

const IfcViewer: React.FC<IfcViewerProps> = ({ ifcUrl }) => {
  const ifcContainerRef = createRef<HTMLDivElement>()
  const [viewer, setViewer] = useState<IfcViewerAPI>()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (ifcContainerRef.current) {
      const container = ifcContainerRef.current
      const ifcViewer = new IfcViewerAPI({ container })
      setViewer(ifcViewer)
      ifcViewer.IFC.setWasmPath('../../')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (viewer) {
      setIsLoading(true)
      viewer.IFC.loadIfcUrl(ifcUrl, true).then(() => setIsLoading(false))
    }
  }, [ifcUrl, viewer])

  return (
    <Spin spinning={isLoading}>
      <div ref={ifcContainerRef} style={{ height: '100%' }} />
    </Spin>
  )
}

export default IfcViewer
