import { MessageDescriptor } from '@lingui/core'
import React from 'react'
import { t } from '@lingui/macro'
import { FALLBACK_LOCALE, Language, Languages } from 'data/i18n'

const trans = (message: MessageDescriptor): string => t({ id: message.id })

export const transIfDescriptorType = (message?: React.ReactNode | MessageDescriptor): React.ReactNode =>
  typeof message === 'object' && !React.isValidElement(message) ? trans(message as MessageDescriptor) : message

export default trans

export const getInitialLanguage = (): Language => {
  const browserLanguage = navigator.language
  const parts = browserLanguage.split('-')
  if (Languages.find((val) => val === parts[0])) {
    return parts[0] as Language
  }
  return FALLBACK_LOCALE
}
