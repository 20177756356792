import { Space, Spin } from 'antd'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'redux/store'
import Table from 'components/molecules/table'
import styled from 'styled-components'
import {
  selectAdministrationAttributes,
  selectFetchingAttributesLoading,
  selectIsAttributeModalVisible,
} from 'redux/administration/administration-attributes/AdministrationAttributes.selectors'
import { fetchAttributes } from 'redux/administration/administration-attributes'
import useAdministrationAttributesColumns from './columns/useAdministrationAttributesColumns'
import AttributeModal from './attribute-modal'
import AdministrationAttributesAlerts from './AdministrationAttributesAlerts'

const StyledTable = styled(Table)`
  width: 1050px;
  &&&& {
    td {
      &:nth-child(3) {
        text-align: center;
      }
    }
    td:nth-of-type(2) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`

const AdministrationAttributes: React.FC = () => {
  const dispatch = useAppDispatch()
  const { isLoading } = useSelector(selectFetchingAttributesLoading)
  const administrationAttributes = useSelector(selectAdministrationAttributes)
  const attributeModalVisibility = useSelector(selectIsAttributeModalVisible)
  const columns = useAdministrationAttributesColumns()
  useEffect(() => {
    dispatch(fetchAttributes())
  }, [dispatch])
  return (
    <Spin spinning={isLoading}>
      {!isLoading && (
        <Space direction="vertical" size="middle">
          <StyledTable
            columns={columns}
            dataSource={administrationAttributes}
            bordered
            pagination={false}
            rowKey="id"
            scroll={{ y: 'calc(100vh - 320px)' }}
          />
          {attributeModalVisibility && <AttributeModal visible={attributeModalVisibility} />}
          <AdministrationAttributesAlerts />
        </Space>
      )}
    </Spin>
  )
}

export default AdministrationAttributes
