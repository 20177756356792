import { Input } from 'antd'
import styled from 'styled-components'
import Text from 'components/atoms/text'
import { t, Trans } from '@lingui/macro'
import { FormListFieldData } from 'antd/lib/form/FormList'
import { codeInputRules } from 'helpers/Validators.helpers'
import BaseFormItem from '../form-item/BaseFormItem'

const StyledCodeItem = styled(BaseFormItem)`
  display: inline-block;
  > :last-child {
    margin-top: 0;
  }
`

interface CodeItemProps {
  isFirstInColumn: boolean
  field: FormListFieldData
  showButtons: boolean
  disabled: boolean
  valueCodeLength?: number
  formListName: string
  codeFormItemName?: string
}

const CodeItem: React.FC<CodeItemProps> = ({
  isFirstInColumn,
  field,
  valueCodeLength,
  formListName,
  showButtons = false,
  disabled = false,
  codeFormItemName = 'codeLength',
}) => {
  return (
    <StyledCodeItem
      label={
        isFirstInColumn && (
          <Text textStyle="regularText-smallSemiBold">
            <Trans id="administration.common.codes.modal.code_label">Code</Trans>
          </Text>
        )
      }
      validateFirst
      colon={false}
      name={[field.name, 'code']}
      style={{
        display: 'inline-block',
        width: showButtons ? 'calc(36% - 8px)' : 'calc(39% - 8px)',
      }}
      dependencies={[codeFormItemName]}
      rules={[
        ...codeInputRules(valueCodeLength),
        ({ getFieldValue }) => ({
          validator(_, value) {
            const values = getFieldValue(formListName) as ({ code: string } | undefined)[]
            if (values.filter((val) => val?.code === value).length !== 1) {
              return Promise.reject(
                t({
                  id: 'administration.common.codes.modal.code_not_unique_error',
                  message: 'Code not unique',
                })
              )
            }
            return Promise.resolve()
          },
        }),
      ]}
      fieldKey={[field.fieldKey, 'code']}
      noMargin
    >
      <Input
        disabled={disabled}
        placeholder={t({ id: 'administration.attributes.modal.value_code_placeholder', message: 'Code' })}
      />
    </StyledCodeItem>
  )
}

export default CodeItem
