import { FileOutlined } from '@ant-design/icons'
import { documentationText } from 'data/messages/misc'
import FilesDocumentation from './content/documentation'

export default [
  // {
  //   option: <SearchOutlined />,
  //   type: '1',
  //   content: <div>Content 1</div>,
  // },
  {
    option: <FileOutlined />,
    type: 'documentation',
    content: <FilesDocumentation />,
    tooltip: documentationText,
  },
  // {
  //   option: <GatewayOutlined />,
  //   type: '3',
  //   content: <div>Content 3</div>,
  // },
]
