import { useState } from 'react'
import { EllipsisOutlined } from '@ant-design/icons'
import { Dropdown } from 'antd'
import styled from 'styled-components'
import Icon from 'components/atoms/icon'
import { Permissions, FrontendFile } from 'models/files'
import FilesEntryMenu from './FilesEntryMenu'

interface FilesTableEntryOptionsProps {
  permissions: Permissions
  frontendFile: FrontendFile
}

const StyledDiv = styled.div`
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(-24px, -50%);
  transition: ${(props) => props.theme.transitions.default};
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  display: flex;
  box-sizing: content-box;
  border: 1px solid ${(props) => props.theme.colors.grayG06};
  border-radius: 50%;
`

const FilesTableEntryOptions: React.FC<FilesTableEntryOptionsProps> = ({ permissions, frontendFile }) => {
  const [visible, setVisible] = useState(false)
  const onVisibleChange = (isVisible: boolean) => setVisible(isVisible)
  return (
    <Dropdown
      visible={visible}
      onVisibleChange={onVisibleChange}
      trigger={['click']}
      overlay={
        <FilesEntryMenu
          frontendFile={frontendFile}
          selectable={false}
          setVisible={setVisible}
          permissions={permissions}
        />
      }
    >
      <StyledDiv onClick={(e) => e.stopPropagation()} className="entry-options">
        <Icon color="grayG06" size="default">
          <EllipsisOutlined />
        </Icon>
      </StyledDiv>
    </Dropdown>
  )
}

export default FilesTableEntryOptions
