import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import { Notification } from 'models/notifications'
import {
  BackendLoading,
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
} from 'redux/redux.shared'
import { mapWithNotificationNameAndKey, markNotificationsAsRead } from './Notifications.helpers'

const loadingTypes = ['getNewNotifications', 'readNotifications', 'getAllNotifications'] as const
type LoadingTypes = typeof loadingTypes[number]

export interface NotificationsState {
  loading: Record<LoadingTypes, BackendLoading>
  isPopoverVisible: boolean
  newNotifications: Notification[]
  allNotifications: Notification[]
  notificationsSearchValue: string
}

export const getNewNotifications = createAsyncBackendThunk('notifications/getNewNotifications', async () => {
  const response = await backendAxios.get('/notifications/unread/')
  return response.data
})

export const readNotifications = createAsyncBackendThunk('notifications/readNotification', async (ids: number[]) => {
  await backendAxios.post(
    '/notifications/read/',
    ids.map((id) => ({ id }))
  )
})

export const getAllNotifications = createAsyncBackendThunk('notifications/getAllNotifications', async () => {
  const response = await backendAxios.get('/notifications/')
  return response.data
})

const initialState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  newNotifications: [],
  allNotifications: [],
  isPopoverVisible: false,
  notificationsSearchValue: '',
} as NotificationsState

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    onNotificationsPopoverVisibilityChange: (state, action: PayloadAction<boolean>) => {
      const isVisible = action.payload
      state.isPopoverVisible = isVisible
      if (!isVisible) {
        state.newNotifications = state.newNotifications.filter(({ isRead }) => !isRead)
      }
    },
    setNotificationsSearchValue: (state, action: PayloadAction<string>) => {
      state.notificationsSearchValue = action.payload.toLowerCase()
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<NotificationsState, LoadingTypes, Notification[]>({
      promise: getNewNotifications,
      loadingType: 'getNewNotifications',
      onFulfilled: (state, action) => {
        state.newNotifications = mapWithNotificationNameAndKey(action.payload)
      },
    }),
    generateExtraBackendReducers<NotificationsState, LoadingTypes, void, number[]>({
      promise: readNotifications,
      loadingType: 'readNotifications',
      onFulfilled: (state, action) => {
        const notificationIds = action.meta.arg
        state.newNotifications = markNotificationsAsRead(state.newNotifications, notificationIds)
        state.allNotifications = markNotificationsAsRead(state.allNotifications, notificationIds)
      },
    }),
    generateExtraBackendReducers<NotificationsState, LoadingTypes, Notification[]>({
      promise: getAllNotifications,
      loadingType: 'getAllNotifications',
      onFulfilled: (state, action) => {
        state.allNotifications = mapWithNotificationNameAndKey(action.payload)
      },
    }),
  ]),
})

export const { onNotificationsPopoverVisibilityChange, setNotificationsSearchValue } = notificationsSlice.actions

export const hideNotificationsPopover = (): PayloadAction<boolean> => onNotificationsPopoverVisibilityChange(false)

export const notificationsReducer = notificationsSlice.reducer
