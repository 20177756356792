import { Project } from 'models/administration'
import { ActiveUser } from 'models/users'
import { BackendLoading } from 'redux/redux.shared'

export const loadingTypes = [
  'fetchProjects',
  'createProject',
  'updateProject',
  'fetchActiveUsers',
  'addProjectAdmin',
  'deleteProject',
] as const
export type LoadingTypes = typeof loadingTypes[number]

export const projectStatusTypes = ['ALL', 'ACTIVE', 'ARCHIVED', 'IN_CONFIG']

export type ProjectStatusType = typeof projectStatusTypes[number]

export const ProjectStatusTypes: Record<ProjectStatusType, ProjectStatusType> = {
  ALL: 'ALL',
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  IN_CONFIG: 'IN_CONFIG',
}

export interface AdministrationProjectModalState {
  isModalVisible: boolean
  project?: Project
}

export interface AdministrationProjectsState {
  loading: Record<LoadingTypes, BackendLoading>
  projects: Project[]
  users: ActiveUser[]
  modalState: AdministrationProjectModalState
}
