import { Flow } from 'models/flow'
import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import { ProjectFlowConfigState } from './ProjectFlowConfig.types'

const selectProjectFlowConfig: (state: RootState) => ProjectFlowConfigState = (state) => state.project.config.flow

export const selectProjectFlows: (state: RootState) => Required<Flow>[] = (state) =>
  selectProjectFlowConfig(state).flows

export const selectFetchingFlowsLoading: (state: RootState) => BackendLoading = (state) =>
  selectProjectFlowConfig(state).loading.fetchFlows
