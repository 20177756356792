import { defineMessage } from '@lingui/macro'

export const leastRecentUpdateText = defineMessage({
  id: 'project.files.file_info.least_recent_update',
  message: 'Least recent update',
})

export const mostRecentUpdateText = defineMessage({
  id: 'project.files.file_info.most_recent_update',
  message: 'Most recent update',
})

export const noSuitabilityCodeText = defineMessage({
  id: 'project.files.no_suitability_code_text',
  message: 'No suitability code',
})
