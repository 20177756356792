import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import { identity } from 'helpers/Functions.helpers'
import { CdeStatus, cdeStatuses, cdeStatusesOrderDict } from 'models/files'
import { Discipline, Permission } from 'models/project/projectConfig'
import {
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
  getStateAsAny,
} from 'redux/redux.shared'
import { Discipline as SystemDiscipline } from 'models/administration'
import { addProjectConfigFinishedStep } from '../status/ProjectStatusConfig.slice'
import {
  emptyPermission,
  findPermissionByDiscipline,
  getReadStatus,
  handlePermissionChanged,
  isMemberChecked,
} from './ProjectMembersPermissionsConfig.helpers'
import { selectChangedUsers, selectProjectMembersPermissions } from './ProjectMembersPermissionsConfig.selectors'
import {
  loadingTypes,
  LoadingTypes,
  MemberPermission,
  ProjectMembersPermissionsConfigState,
} from './ProjectMembersPermissionsConfig.types'

export const fetchMembersPermissions = createAsyncBackendThunk(
  'projectMembers/fetchMembersPermissions',
  async ({ projectId, disciplines }: { projectId: number; disciplines: SystemDiscipline[] }) => {
    const permissions = (await backendAxios.get(`/projects/${projectId}/members/permissions/`))
      .data as MemberPermission[]
    return permissions.map((permission) => ({
      ...permission,
      permissions: disciplines.map(
        (discipline) =>
          findPermissionByDiscipline(permission, discipline.id) || {
            discipline: discipline.id,
            permissions: { ...emptyPermission },
          }
      ),
    }))
  }
)

export const updateMembersPermissions = createAsyncBackendThunk(
  'projectMembers/updateMembersPermissions',
  async (projectId: number, { getState, dispatch }) => {
    const state = getStateAsAny(getState)
    const membersPermissions = selectProjectMembersPermissions(state)
    const changedUsers = selectChangedUsers(state)
    const permissionsToUpdate = (changedUsers
      .map((userId) => membersPermissions.find(({ user }) => user === userId))
      .filter(identity) as MemberPermission[]).map(({ id, permissions }) => ({
      users: [id],
      permissions: permissions.filter((permission) => permission.permissions.canReadFrom),
    }))
    await backendAxios.post(`/projects/${projectId}/members/permissions/`, permissionsToUpdate)
    await dispatch(addProjectConfigFinishedStep({ projectId, step: 'PERMISSIONS' }))
  }
)

export const fetchAvailableDisciplines = createAsyncBackendThunk(
  'projectMembers/fetchAvailableDisciplines',
  async (projectId: number) => {
    return (await backendAxios.get(`/projects/${projectId}/disciplines/`)).data
  }
)

const initialState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  membersPermissions: [],
  selectedOrganizations: [],
  selectedUsers: [],
  selectedDisciplines: [],
  checkedUsers: [],
  permissionsFetched: false,
  shouldPermissionsUpdate: false,
  changedUsers: [],
  availableDisciplines: [],
} as ProjectMembersPermissionsConfigState

interface ManualPermissionChangeProps {
  discipline: number
  cdeStatus: CdeStatus
  isIconStatusOff: boolean
  isIconStatusMixed: boolean
}

const projectMembersPermissionsConfigSlice = createSlice({
  name: 'projectMembersPermissionsConfig',
  initialState,
  reducers: {
    setSelectedOrganizations: (state, action: PayloadAction<number[]>) => {
      state.selectedOrganizations = action.payload
    },
    setSelectedDisciplines: (state, action: PayloadAction<number[]>) => {
      state.selectedDisciplines = action.payload
    },
    setSelectedUsers: (state, action: PayloadAction<number[]>) => {
      state.selectedUsers = action.payload
    },
    checkUser: (state, action: PayloadAction<number>) => {
      state.checkedUsers.push(action.payload)
    },
    uncheckUser: (state, action: PayloadAction<number>) => {
      state.checkedUsers = state.checkedUsers.filter((id) => id !== action.payload)
    },
    handleReadStatusChange: (state, action: PayloadAction<ManualPermissionChangeProps>) => {
      handlePermissionChanged(state)
      state.membersPermissions = state.membersPermissions.map((permission) => {
        const memberChecked = isMemberChecked(state.checkedUsers, permission.user)
        const { isIconStatusOff, cdeStatus, discipline, isIconStatusMixed } = action.payload
        if (memberChecked) {
          const getCheckOrAcceptStatusList = (
            permissions: Permission,
            key: 'canCheckIn' | 'canAcceptIn'
          ): CdeStatus[] =>
            isIconStatusOff
              ? permissions[key]
              : permissions[key].filter((status) => cdeStatusesOrderDict[status] > cdeStatusesOrderDict[cdeStatus])
          const getWriteArchiveStatus = (permissions: Permission) =>
            cdeStatus === 'ARCHIVED' ? false : permissions.canWriteArchive
          return {
            ...permission,
            permissions: permission.permissions.map((p) => {
              if (p.discipline !== discipline) return p
              const changedReadStatus =
                isIconStatusMixed &&
                ((p.permissions.canReadFrom &&
                  cdeStatusesOrderDict[p.permissions.canReadFrom] > cdeStatusesOrderDict[cdeStatus]) ||
                  !p.permissions.canReadFrom)
                  ? p.permissions.canReadFrom
                  : cdeStatuses[cdeStatusesOrderDict[cdeStatus] + 1]
              return {
                ...p,
                permissions: {
                  canReadFrom: isIconStatusOff ? cdeStatus : changedReadStatus,
                  canWrite: isIconStatusOff ? p.permissions.canWrite : false,
                  canWriteArchive: isIconStatusOff
                    ? p.permissions.canWriteArchive
                    : getWriteArchiveStatus(p.permissions),
                  canCheckIn: getCheckOrAcceptStatusList(p.permissions, 'canCheckIn'),
                  canAcceptIn: getCheckOrAcceptStatusList(p.permissions, 'canAcceptIn'),
                },
              }
            }),
          }
        }
        return permission
      })
    },
    handleWriteStatusChange: (state, action: PayloadAction<ManualPermissionChangeProps>) => {
      handlePermissionChanged(state)
      state.membersPermissions = state.membersPermissions.map((permission) => {
        const memberChecked = isMemberChecked(state.checkedUsers, permission.user)
        if (memberChecked) {
          const { isIconStatusOff } = action.payload
          const { cdeStatus } = action.payload
          const permissionObjDiffDict: Record<
            'IN_PROGRESS' | 'ARCHIVED',
            { canWrite?: boolean; canWriteArchive?: boolean }
          > = {
            IN_PROGRESS: { canWrite: isIconStatusOff },
            ARCHIVED: { canWriteArchive: isIconStatusOff },
          }
          return {
            ...permission,
            permissions: permission.permissions.map((p) =>
              p.discipline === action.payload.discipline
                ? {
                    ...p,
                    permissions: {
                      ...p.permissions,
                      ...permissionObjDiffDict[cdeStatus as 'IN_PROGRESS' | 'ARCHIVED'],
                      canReadFrom: isIconStatusOff
                        ? getReadStatus(p.permissions.canReadFrom, cdeStatus)
                        : p.permissions.canReadFrom,
                    },
                  }
                : p
            ),
          }
        }
        return permission
      })
    },
    handleCheckStatusChange: (state, action: PayloadAction<ManualPermissionChangeProps>) => {
      handlePermissionChanged(state)
      state.membersPermissions = state.membersPermissions.map((permission) => {
        const memberChecked = isMemberChecked(state.checkedUsers, permission.user)
        if (memberChecked) {
          const { isIconStatusOff } = action.payload
          const { cdeStatus } = action.payload
          return {
            ...permission,
            permissions: permission.permissions.map((p) =>
              p.discipline === action.payload.discipline
                ? {
                    ...p,
                    permissions: {
                      ...p.permissions,
                      canCheckIn: isIconStatusOff
                        ? [...p.permissions.canCheckIn, cdeStatus]
                        : p.permissions.canCheckIn.filter((status) => status !== cdeStatus),
                      canReadFrom: isIconStatusOff
                        ? getReadStatus(p.permissions.canReadFrom, cdeStatus)
                        : p.permissions.canReadFrom,
                    },
                  }
                : p
            ),
          }
        }
        return permission
      })
    },
    handleAcceptStatusChange: (state, action: PayloadAction<ManualPermissionChangeProps>) => {
      handlePermissionChanged(state)
      state.membersPermissions = state.membersPermissions.map((permission) => {
        const memberChecked = isMemberChecked(state.checkedUsers, permission.user)
        if (memberChecked) {
          const { isIconStatusOff } = action.payload
          const { cdeStatus } = action.payload
          return {
            ...permission,
            permissions: permission.permissions.map((p) =>
              p.discipline === action.payload.discipline
                ? {
                    ...p,
                    permissions: {
                      ...p.permissions,
                      canAcceptIn: isIconStatusOff
                        ? [...p.permissions.canAcceptIn, action.payload.cdeStatus]
                        : p.permissions.canAcceptIn.filter((status) => status !== action.payload.cdeStatus),
                      canReadFrom: isIconStatusOff
                        ? getReadStatus(p.permissions.canReadFrom, cdeStatus)
                        : p.permissions.canReadFrom,
                    },
                  }
                : p
            ),
          }
        }
        return permission
      })
    },
    checkUsers: (state, action: PayloadAction<number[]>) => {
      state.checkedUsers = action.payload
    },
    uncheckUsers: (state) => {
      state.checkedUsers = []
    },
    clearMembersPermissionsConfigState: () => initialState,
  },
  extraReducers: combine([
    generateExtraBackendReducers<ProjectMembersPermissionsConfigState, LoadingTypes, MemberPermission[]>({
      promise: fetchMembersPermissions,
      loadingType: 'fetchMembersPermissions',
      onFulfilled: (state, action) => {
        state.membersPermissions = action.payload
        state.permissionsFetched = true
      },
    }),
    generateExtraBackendReducers<ProjectMembersPermissionsConfigState, LoadingTypes>({
      promise: updateMembersPermissions,
      loadingType: 'updateMembersPermissions',
    }),
    generateExtraBackendReducers<ProjectMembersPermissionsConfigState, LoadingTypes, Discipline[]>({
      promise: fetchAvailableDisciplines,
      loadingType: 'fetchAvailableDisciplines',
      onFulfilled: (state, action) => {
        state.availableDisciplines = action.payload
      },
    }),
  ]),
})

export const {
  setSelectedOrganizations,
  setSelectedDisciplines,
  setSelectedUsers,
  checkUser,
  uncheckUser,
  handleReadStatusChange,
  checkUsers,
  uncheckUsers,
  handleWriteStatusChange,
  handleCheckStatusChange,
  handleAcceptStatusChange,
  clearMembersPermissionsConfigState,
} = projectMembersPermissionsConfigSlice.actions
export const projectMembersPermissionsConfigReducer = projectMembersPermissionsConfigSlice.reducer
