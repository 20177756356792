import { t } from '@lingui/macro'
import ErrorAlert from 'components/atoms/error-alert'
import { useSelector } from 'react-redux'
import {
  selectFetchMetadataLoading,
  selectSelectMetadataLoading,
  selectPatchMetadataValueLoading,
  selectRemoveMetadataLoading,
  selectToggleMetadataRequiredLoading,
} from 'redux/project/project-config/attributes/ProjectAttributesConfig.selectors'

const ProjectAttributesConfigAlerts: React.FC = () => {
  const selectMetadataLoading = useSelector(selectSelectMetadataLoading)
  const fetchMetadataLoading = useSelector(selectFetchMetadataLoading)
  const patchMetadataValueLoading = useSelector(selectPatchMetadataValueLoading)
  const removeMetadataLoading = useSelector(selectRemoveMetadataLoading)
  const toggleMetadataRequiredLoading = useSelector(selectToggleMetadataRequiredLoading)

  const alertMessageDict = [
    {
      error: fetchMetadataLoading.error,
      message: t({
        id: 'project_config.attributes.alert.metadata_fetch_failed',
        message: 'Could not retrieve attributes.',
      }),
    },
    {
      error: selectMetadataLoading.error,
      message: t({
        id: 'project_config.attributes.alert.metadata_select_failed',
        message: 'Could not add attribute.',
      }),
    },
    {
      error: patchMetadataValueLoading.error,
      message: t({
        id: 'project_config.attributes.alert.metadata_edit_failed',
        message: 'Could not update attribute value.',
      }),
    },
    {
      error: removeMetadataLoading.error,
      message: t({
        id: 'project_config.attributes.alert.metadata_remove_failed',
        message: 'Could not remove attribute value.',
      }),
    },
    {
      error: toggleMetadataRequiredLoading.error,
      message: t({
        id: 'project_config.attributes.alert.metadata_toggle_failed',
        message: 'Could not change attribute value status.',
      }),
    },
  ]
  return <ErrorAlert alertMessageDict={alertMessageDict} />
}

export default ProjectAttributesConfigAlerts
