import { FlowConfig } from 'models/flow'
import type { BackendLoading } from 'redux/redux.shared'

export const loadingType = ['createFlow', 'getFlow', 'updateFlow'] as const
export type LoadingType = typeof loadingType[number]

export interface FlowGeneralConfigState {
  loading: Record<LoadingType, BackendLoading>
  flowConfig: FlowConfig
  shouldCreateFlow: boolean
  originalName?: string
  prevFlowsTableVisibility: boolean
}
