import { Space } from 'antd'
import Text from 'components/atoms/text'
import WithOverflow from 'components/atoms/with-overflow'

const NotificationDetail: React.FC<{ name: string; value?: React.ReactNode }> = ({ value, name }) =>
  value ? (
    <Space>
      <Text textStyle="regularText-small" color="grayG05">
        {name}:
      </Text>
      <WithOverflow>
        <Text textStyle="regularText-small">{value}</Text>
      </WithOverflow>
    </Space>
  ) : null

export default NotificationDetail
