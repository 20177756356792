import styled from 'styled-components'
import { OpenInNew } from '@material-ui/icons'
import Icon from 'components/atoms/icon'
import FilesOptions from './FilesOptions'

const StyledDiv = styled.div`
  padding: ${(props) => props.theme.sizes.default} ${(props) => props.theme.sizes.s};
  width: ${(props) => props.theme.sizes.leftAsideWidth};
  background-color: ${(props) => props.theme.colors.baseWhiteish};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  position: relative;
  z-index: 1;
`

const FilesOptionBar: React.FC = () => {
  return (
    <StyledDiv>
      <FilesOptions />
      <Icon color="grayG07">
        <OpenInNew />
      </Icon>
    </StyledDiv>
  )
}

export default FilesOptionBar
