import { Steps } from 'antd'
import styled from 'styled-components'
import StepData from 'models/components'
import { stepText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'

interface StepsElementProps {
  stepsData: StepData[]
}

const StyledSteps = styled(Steps)`
  &.ant-steps {
    width: auto;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: ${(props) => props.theme.colors.primaryP03};
    border-color: transparent;
  }
  .ant-steps-item .ant-steps-item-container {
    .ant-steps-item-icon {
      margin-right: ${(props) => props.theme.sizes.xs};
    }
    .ant-steps-item-content {
      > * {
        line-height: ${(props) => props.theme.sizes.default};
      }
    }
    .ant-steps-item-description {
      padding-bottom: ${(props) => props.theme.sizes.l};
    }
  }
  .ant-steps-item .ant-steps-item-container {
    .ant-steps-item-title,
    .ant-steps-item-description {
      font-size: ${(props) => props.theme.sizes.s};
      color: ${(props) => props.theme.colors.grayG06};
    }
    .ant-steps-item-title {
      font-weight: ${(props) => props.theme.fontWeights.semiBold};
    }
  }
  .ant-steps-item-active .ant-steps-item-container {
    .ant-steps-item-title {
      color: ${(props) => props.theme.colors.grayG08};
    }
  }
`

const { Step } = Steps

const StepsElement: React.FC<StepsElementProps & React.ComponentProps<typeof Steps>> = ({
  direction,
  stepsData,
  current,
  onChange,
}) => {
  return (
    <StyledSteps direction={direction} current={current} onChange={onChange}>
      {stepsData.map(({ title, description, disabled, status }, idx) => (
        <Step
          key={idx.toString()}
          title={title || `${trans(stepText)} ${idx + 1}`}
          description={description}
          disabled={disabled}
          status={status}
        />
      ))}
    </StyledSteps>
  )
}

export default StepsElement
