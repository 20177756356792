import { defineMessage } from '@lingui/macro'

const PASSWORD_ERROR_MESSAGES = {
  'password.too_short': defineMessage({ id: 'password.too_short', message: 'Password is too short' }),
  'password.too_common': defineMessage({ id: 'password.too_common', message: 'Password is too common' }),
  'password.numeric': defineMessage({
    id: 'password.numeric',
    message: 'Password must contain at least one non-digit character',
  }),
  'password.too_similar_to_other_user_data': defineMessage({
    id: 'password.too_similar_to_other_user_data',
    message: 'Password is too similar to account data',
  }),
  'password.invalid_reset_token': defineMessage({ id: 'password.invalid_reset_token', message: 'Invalid token' }),
  'password.old_invalid': defineMessage({ id: 'password.old_invalid', message: 'Current password is invalid' }),
}

export default PASSWORD_ERROR_MESSAGES
