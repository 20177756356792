import { OrganizationDetails } from 'models/administration'
import { BackendLoading } from 'redux/redux.shared'

export const loadingTypes = [
  'fetchOrganizations',
  'updateOrganization',
  'addOrganization',
  'removeOrganization',
] as const
export type LoadingTypes = typeof loadingTypes[number]

export interface AdministrationOrganizationModalState {
  isModalVisible: boolean
  organization?: OrganizationDetails
}

export interface AdministrationOrganizationsState {
  loading: Record<LoadingTypes, BackendLoading>
  organizations: OrganizationDetails[]
  modalState: AdministrationOrganizationModalState
}

export type OrganizationEditableData = Omit<OrganizationDetails, 'id' | 'isCodeValid' | 'users' | 'isUsed' | 'code'> & {
  code: string
}
