import { Notification } from 'models/notifications'
import { BackendLoading } from 'redux/redux.shared'
import { RootState } from '../store'
import { NotificationsState } from './Notifications.slice'

const selectNotificationsState: (state: RootState) => NotificationsState = (state) => state.notifications

export const selectNewNotifications: (state: RootState) => Notification[] = (state) =>
  selectNotificationsState(state).newNotifications

export const selectNewNotificationsNumber: (state: RootState) => number = (state) =>
  selectNotificationsState(state).newNotifications.length

export const selectAreNewNotificationsLoading: (state: RootState) => BackendLoading = (state) =>
  selectNotificationsState(state).loading.getNewNotifications

export const selectNotificationsPopoverVisibility: (state: RootState) => boolean = (state) =>
  selectNotificationsState(state).isPopoverVisible

export const selectAllNotifications: (state: RootState) => Notification[] = (state) =>
  selectNotificationsState(state).allNotifications

export const selectNotificationsSearchValue: (state: RootState) => string = (state) =>
  selectNotificationsState(state).notificationsSearchValue

export const selectGetAllNotificationsLoading: (state: RootState) => BackendLoading = (state) =>
  selectNotificationsState(state).loading.getAllNotifications
