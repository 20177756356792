import { AttributeValue, Attribute } from 'models/project/projectConfig'
import { ValueFields } from 'models/shared'
import { MetadataDto } from './ProjectAttributesConfig.types'

export function toAttributesArr<T extends ValueFields[]>(
  withNameAndIdAllDto: T,
  withNameAndIdSelectedDto: T
): AttributeValue[] {
  return withNameAndIdAllDto.map(({ id, name, code, isProjectValue, isUsed }) => {
    const selectedDto = withNameAndIdSelectedDto.find((item) => item.id === id)
    return {
      id,
      name,
      selected: !!selectedDto,
      isLoading: false,
      code,
      isProjectValue: isProjectValue ?? false,
      isUsed,
    }
  })
}
export function valueToNameKeyArr<T extends { value: string; id: number; code: string; isUsed: boolean }[]>(
  valueArr: T
): ValueFields[] {
  return valueArr.map((val) => ({ ...val, name: val.value }))
}
export const setLoadingAndSelected = (
  attributeValues: AttributeValue[],
  attrValueId: number,
  shouldBeSelected: boolean | undefined,
  loading: boolean
): AttributeValue[] =>
  attributeValues.map(({ id, name, selected, isLoading, code, isProjectValue, isUsed }) => ({
    id,
    name,
    selected: id === attrValueId && shouldBeSelected !== undefined ? shouldBeSelected : selected,
    isLoading: attrValueId === id ? loading : isLoading,
    code,
    isProjectValue,
    isUsed,
  }))

export const mapAttributesWithAttrValuesById = (
  attributes: Attribute[],
  attrId: number,
  getAttributeVals: (attr: AttributeValue[]) => AttributeValue[]
): Attribute[] =>
  attributes.map((data) => {
    return {
      ...data,
      attributeValues: data.id === attrId ? getAttributeVals(data.attributeValues) : data.attributeValues,
    }
  })

export const allMetadataToAttributes = (allMetadata: MetadataDto[], selectedMetadata: MetadataDto[]): Attribute[] =>
  allMetadata.map(({ id, name, values, projectValuesAllowed, valueCodeLength }) => ({
    id,
    name,
    attributeValues: toAttributesArr(
      valueToNameKeyArr(values),
      valueToNameKeyArr(selectedMetadata.find((data) => data.id === id)?.values || [])
    ),
    selected: !!selectedMetadata.find((data) => data.id === id),
    loadingRequire: false,
    loadingRemoving: false,
    projectValuesAllowed,
    valueCodeLength,
    isUsed: selectedMetadata.find((data) => data.id === id)?.isUsed,
  })) as Attribute[]
