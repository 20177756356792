import WithValidityInfo from 'components/atoms/with-validity-info'
import { t } from '@lingui/macro'
import DataDisplay from 'components/molecules/users'

interface UserDataDisplayProps {
  name: string
  surname?: string
  email: string
  organization: string
  isDisabled?: boolean
}

const UserDataDisplay: React.FC<UserDataDisplayProps> = ({
  name,
  surname,
  email,
  organization,
  isDisabled = false,
}) => {
  return (
    <WithValidityInfo
      isValid={!isDisabled}
      invalidMessage={t({ id: 'project_config.members.table.inactive_user', message: 'Inactive user' })}
    >
      <DataDisplay name={name} surname={surname} organization={organization} email={email} />
    </WithValidityInfo>
  )
}

export default UserDataDisplay
