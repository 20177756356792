import styled from 'styled-components'
import Table from 'components/molecules/table'
import { useSelector } from 'react-redux'
import { selectAvailableReviewers, selectSelectedReviewer } from 'redux/files/flows/Flows.selectors'
import { useAppDispatch } from 'redux/store'
import { setReviewer } from 'redux/files/flows'
import { useEffect } from 'react'
import useReviewersSelectionColumns from './useReviewersSelectionColumns'

const StyledTable = styled(Table)`
  width: 600px;
`

const ReviewersTable: React.FC = () => {
  const dispatch = useAppDispatch()
  const reviewers = useSelector(selectAvailableReviewers)
  const reviewer = useSelector(selectSelectedReviewer)
  const columns = useReviewersSelectionColumns()
  const rowSelection = {
    type: 'radio',
    onChange: (selectedRowKeys: number[]) => dispatch(setReviewer(selectedRowKeys[0])),
    selectedRowKeys: reviewer ? [reviewer] : [],
  }
  useEffect(() => {
    if (reviewers.length === 1) {
      dispatch(setReviewer(reviewers[0].id))
    }
  }, [dispatch, reviewers])
  return (
    <StyledTable
      columns={columns}
      dataSource={reviewers}
      bordered
      pagination={false}
      rowKey="id"
      rowSelection={rowSelection}
      scroll={{ y: '400px' }}
    />
  )
}

export default ReviewersTable
