import { DatePicker } from 'antd'
import { DatePickerProps } from 'antd/lib/date-picker'
import { FormItemProps } from 'antd/lib/form'
import BaseFormItem from './BaseFormItem'

const FormDatePicker: React.FC<FormItemProps & DatePickerProps> = ({
  placeholder,
  disabled = false,
  onChange,
  value,
  label,
  name,
  rules,
  picker,
}) => (
  <BaseFormItem rules={rules} label={label} name={name}>
    <DatePicker
      value={value}
      picker={picker}
      onChange={onChange}
      placeholder={placeholder && placeholder.toString()}
      disabled={disabled}
    />
  </BaseFormItem>
)

export default FormDatePicker
