import { useSelector } from 'react-redux'
import { selectIsProjectStagesConfigContentLoading } from 'redux/project/project-config/naming-scheme/ProjectNamingSchemeConfig.selectors'
import { updateNamingScheme } from 'redux/project/project-config/naming-scheme/ProjectNamingSchemeConfig.slice'
import BaseProjectConfigView from '../base-config-view/BaseProjectConfigView'
import ProjectNamingSchemeConfigContent from './content/ProjectNamingSchemeConfigContent'

const ProjectNamingSchemeConfig: React.FC = () => {
  const isContentLoading = useSelector(selectIsProjectStagesConfigContentLoading)
  return (
    <BaseProjectConfigView
      content={<ProjectNamingSchemeConfigContent />}
      saveStepThunk={updateNamingScheme()}
      isContentLoading={isContentLoading}
    />
  )
}

export default ProjectNamingSchemeConfig
