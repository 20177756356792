import useSideDrawerParams from 'hooks/query/sideDrawerParams'
import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import SideDrawerType from './sideDrawer.types'

interface UseSideDrawerReturnValue {
  content: React.ReactNode
  isVisible: boolean
  handleClose: () => void
}

const useSideDrawer = (
  sideDrawerType: SideDrawerType,
  options: {
    option: JSX.Element
    type: string
    content: JSX.Element
  }[]
): UseSideDrawerReturnValue => {
  const [content, setContent] = useState<React.ReactNode>(null)
  const location = useLocation()
  const history = useHistory()
  const { contentParam, sideDrawerParam } = useSideDrawerParams()
  const isVisible = !!(sideDrawerParam === sideDrawerType && content)

  useEffect(() => {
    let timeout: number
    const matchedContent = options.find((option) => option.type === contentParam)?.content
    if (matchedContent) {
      setContent(matchedContent)
    } else {
      timeout = setTimeout(() => {
        setContent(undefined)
      }, 300)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [contentParam, options])

  const handleClose = () => history.push(location.pathname)

  return { content, isVisible, handleClose }
}

export default useSideDrawer
