import { EditOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import ButtonWithTooltip from 'components/molecules/buttons'
import { User } from 'models/administration'
import { useSelector } from 'react-redux'
import { showUserModal } from 'redux/administration/administration-users'
import { selectAdministrationUsers } from 'redux/administration/administration-users/AdministrationUsers.selectors'
import { useAppDispatch } from 'redux/store'

const EditUserButton: React.FC<Pick<User, 'id'>> = ({ id }) => {
  const dispatch = useAppDispatch()
  const user = useSelector(selectAdministrationUsers).find((u) => u.id === id)
  return (
    <ButtonWithTooltip
      onClick={async () => {
        if (id) {
          dispatch(showUserModal(user))
        }
      }}
      tooltipText={t({ id: 'administration.users.buttons.edit_account_text', message: 'Edit user' })}
      icon={<EditOutlined />}
    />
  )
}

export default EditUserButton
