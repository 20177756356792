import { t } from '@lingui/macro'
import TooltipElement from 'components/molecules/tooltip-element'
import UserDataDisplay from 'components/molecules/users'
import styled from 'styled-components'
import { ReactComponent as Admin } from 'assets/administration-icons/admin.svg'

interface UserDisplayProps {
  name: string
  surname: string
  email: string
  isAdmin: boolean
}

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const IconDiv = styled.div`
  width: ${(props) => props.theme.sizes.l};
  height: ${(props) => props.theme.sizes.l};
  ${(props) => props.theme.mixins.flexCenter};
`

const UserDisplay: React.FC<UserDisplayProps> = ({ name, surname, email, isAdmin }) => {
  return (
    <StyledDiv>
      <UserDataDisplay name={name} surname={surname} email={email} allowSendEmail />
      {isAdmin && (
        <TooltipElement
          placement="top"
          title={t({ id: 'administration.users.table.system_admin_marker_tooltip', message: 'System admin' })}
        >
          <IconDiv>
            <Admin />
          </IconDiv>
        </TooltipElement>
      )}
    </StyledDiv>
  )
}

export default UserDisplay
