import { combineReducers } from 'redux'
import { administrationClear } from 'redux/action.types'
import { wrapWithClear } from 'helpers/Functions.helpers'
import { administrationAttributesReducer } from './administration-attributes'
import { administrationCodeSettingsReducer } from './administration-code-settings'
import { administrationDisciplinesReducer } from './administration-disciplines'
import { administrationMenuReducer } from './administration-menu'
import { administrationOrganizationsReducer } from './administration-organizations'
import { administrationProjectStagesReducer } from './administration-project-stages'
import { administrationSuitabilityReducer } from './administration-suitability'
import { administrationUsersReducer } from './administration-users'
import { administrationProjectsReducer } from './administration-projects'
import { administrationNamingSchemesReducer } from './administration-naming-scheme'
import { administrationBackupsReducer } from './administration-backups'

const administrationReducer = combineReducers({
  menu: administrationMenuReducer,
  disciplines: administrationDisciplinesReducer,
  organizations: administrationOrganizationsReducer,
  attributes: administrationAttributesReducer,
  projectStages: administrationProjectStagesReducer,
  suitability: administrationSuitabilityReducer,
  users: administrationUsersReducer,
  projects: administrationProjectsReducer,
  codeSettings: administrationCodeSettingsReducer,
  namingSchemes: administrationNamingSchemesReducer,
  backups: administrationBackupsReducer,
})

export default wrapWithClear(administrationReducer, administrationClear)
