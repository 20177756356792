import { Trans } from '@lingui/macro'
import { Space } from 'antd'
import Text from 'components/atoms/text'
import { useSelector } from 'react-redux'
import { selectGettingFlowConsequencesLoading } from 'redux/flow-conifg/consequences/FlowConsequencesConfig.selectors'
import FlowConsequencesConfigContentAlerts from './FlowConsequencesConfigContentAlerts'
import FlowConsequencesConfigForm from './FlowConsequencesConfigForm'

const FlowConsequencesConfigContent: React.FC = () => {
  const { isLoading: isGettingConsequencesLoading } = useSelector(selectGettingFlowConsequencesLoading)

  if (isGettingConsequencesLoading) return null
  return (
    <Space direction="vertical" size="large">
      <Text textStyle="regularText-medium">
        <Trans id="flow_config.consequences.heading">Flow consequences</Trans>
      </Text>
      <FlowConsequencesConfigForm />
      <FlowConsequencesConfigContentAlerts />
    </Space>
  )
}

export default FlowConsequencesConfigContent
