import ErrorAlert from 'components/atoms/error-alert'
import { t } from '@lingui/macro'
import { useSelector } from 'react-redux'
import { selectProjectSuitabilityOverviewLoading } from 'redux/project/project-config/suitability/ProjectSuitabilityConfig.selectors'

const ProjectSuitabilityAlerts: React.FC = () => {
  const fetchingSuitabilityOverviewLoading = useSelector(selectProjectSuitabilityOverviewLoading)
  const alertMessageDict = [
    {
      error: fetchingSuitabilityOverviewLoading.error,
      message: t({ id: 'project_config.suitability.fetch_failed', message: 'Could not retrieve suitability codes' }),
    },
  ]
  return <ErrorAlert alertMessageDict={alertMessageDict} />
}

export default ProjectSuitabilityAlerts
