import { MessageDescriptor } from '@lingui/core'
import { t } from '@lingui/macro'
import { AxiosResponse } from 'axios'
import { BackendLoading } from 'redux/redux.shared'
import { ApiError, HttpBackendError } from 'models/errors'
import trans from './i18n.helpers'

export const parseResponse = (response: AxiosResponse): HttpBackendError => ({
  responseCode: response.status,
  errors: response.data ? response.data.errors : undefined,
})

export const handleApiErrors = (
  error_msg: Record<string, MessageDescriptor | string>,
  errors?: ApiError[],
  defaultMessage?: string
): string => {
  return (errors || [])
    .map((err) => {
      if (err.code in error_msg) {
        const msg = error_msg[err.code]
        if (typeof msg === 'string') return msg
        return trans(msg)
      }
      return defaultMessage || t({ id: 'errors.unknown_error', message: 'Unknown error' })
    })
    .join('\n')
}

export const handleErrorByCode = (
  error_msg: Record<number, MessageDescriptor | string>,
  error?: HttpBackendError
): string | undefined => {
  if (!error) return undefined
  const msg = error_msg[error.responseCode]
  if (msg === undefined) return undefined
  if (typeof msg === 'string') return msg
  return trans(msg)
}

export const removedErrorsFromLoading = <LoadingTypes extends string>(
  loading: Record<LoadingTypes, BackendLoading>
): Record<LoadingTypes, BackendLoading> => {
  const loadingWithoutErrors: Record<LoadingTypes, BackendLoading> = loading
  Object.keys(loading).forEach((key) => {
    loadingWithoutErrors[key as LoadingTypes].error = undefined
  })
  return loadingWithoutErrors
}

export interface ErrorAlertProps {
  alertMessageDict: ErrorDictRecord[]
}

export interface ErrorDictRecord {
  error?: HttpBackendError
  message: string
}

export function getAlertMessageDict(
  errorMessageDict: Record<string, string | MessageDescriptor>,
  error: HttpBackendError
): ErrorDictRecord[] {
  return [
    {
      error,
      message: handleApiErrors(errorMessageDict, error.errors),
    },
  ]
}
