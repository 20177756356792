/**
 * @deprecated This button should not be used. Instead, use components/molecules/button-element
 */

import { FormEvent } from 'react'
import { Button } from 'antd'
import styled from 'styled-components'

interface ButtonProps {
  children?: React.ReactNode
  elementLoading?: boolean | undefined
  elementType: 'submit' | 'button' | 'reset' | undefined
  submit?: (e: FormEvent) => void | undefined
  type?: 'primary' | 'ghost' | 'dashed' | 'link' | 'text' | 'default'
  size?: 'large' | 'middle' | 'small'
  block?: boolean
  icon?: JSX.Element
  shape?: 'circle' | 'round'
  onClick?: React.MouseEventHandler<HTMLElement>
  isActive?: boolean
}

interface StyledButtonProps {
  $isActive: boolean
}

const StyledButton = styled(Button)<StyledButtonProps>`
  ${(props) => props.theme.mixins.flexCenter};
  display: inline-flex;
  &.ant-btn {
    ${(props) =>
      props.$isActive &&
      `background-color: ${props.theme.colors.actionSelect};
      border: 1px solid ${props.theme.colors.primaryP04};
       > * {
         color: ${props.theme.colors.primaryP04};
       }
    `}
  }
`

const ButtonElement: React.FunctionComponent<ButtonProps & React.ComponentProps<typeof Button>> = ({
  children,
  elementLoading,
  elementType,
  type = 'default',
  size = 'middle',
  block = false,
  icon,
  shape,
  onClick,
  isActive = false,
  disabled,
}) => {
  return (
    <StyledButton
      htmlType={elementType}
      shape={shape}
      icon={icon}
      type={type}
      size={size}
      block={block}
      loading={elementLoading}
      onClick={onClick}
      $isActive={isActive}
      disabled={disabled}
    >
      {children}
    </StyledButton>
  )
}

export default ButtonElement
