import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import { Entry } from 'models/project/projectConfig'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import {
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  combine,
  generateInitialLoadingState,
  getStateAsAny,
} from 'redux/redux.shared'
import { addProjectConfigFinishedStep } from '../status/ProjectStatusConfig.slice'
import { selectEntriesStructure } from './ProjectEntriesStructureConfig.selectors'
import { ProjectEntriesStructureConfigState, LoadingTypes, loadingTypes } from './ProjectEntriesStructureConfig.types'

export const getEntriesStructure = createAsyncBackendThunk(
  'entryStructure/getEntriesStructure',
  async (projectId: number) => {
    const response = await backendAxios.get(`/projects/${projectId}/entry-structure/`)
    return response.data
  }
)

export const updateEntriesStructure = createAsyncBackendThunk(
  'entryStructure/updateEntriesStructure',
  async (args, { getState, dispatch }) => {
    const state = getStateAsAny(getState)
    const projectId = selectProjectId(state) as number
    const entriesStructure = selectEntriesStructure(state)
    await backendAxios.put(`/projects/${projectId}/entry-structure/`, entriesStructure)
    await dispatch(addProjectConfigFinishedStep({ projectId, step: 'ENTRIES_STRUCTURE' }))
  }
)

const initialState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  entriesStructure: [],
  isAddingEntry: false,
  shouldEntriesStructureUpdate: false,
} as ProjectEntriesStructureConfigState

const projectEntriesStructureConfigSlice = createSlice({
  name: 'entriesStructure',
  initialState,
  reducers: {
    setAddingEntry: (state, action: PayloadAction<boolean>) => {
      state.isAddingEntry = action.payload
    },
    changeEntry: (state, action: PayloadAction<{ index: number; entry: Entry }>) => {
      const { index, entry } = action.payload
      state.entriesStructure[index] = entry
    },
    addEntry: (state, action: PayloadAction<Entry>) => {
      state.entriesStructure.push(action.payload)
      state.isAddingEntry = false
    },
    removeEntry: (state, action: PayloadAction<number>) => {
      state.entriesStructure.splice(action.payload, 1)
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<ProjectEntriesStructureConfigState, LoadingTypes, Entry[]>({
      promise: getEntriesStructure,
      loadingType: 'getEntriesStructure',
      onFulfilled: (state, action) => {
        state.entriesStructure = action.payload
      },
    }),
    generateExtraBackendReducers<ProjectEntriesStructureConfigState, LoadingTypes>({
      promise: updateEntriesStructure,
      loadingType: 'updateEntriesStructure',
    }),
  ]),
})

export const { setAddingEntry, changeEntry, addEntry, removeEntry } = projectEntriesStructureConfigSlice.actions

export const projectEntriesStructureConfigReducer = projectEntriesStructureConfigSlice.reducer
