import { DownloadOutlined, SyncOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { Modal } from 'antd'
import { WithDividerElement } from 'components/atoms/divider'
import ButtonWithTooltip, { DeleteButton } from 'components/molecules/buttons'
import { deleteText, confirmText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import showConfirmationModal from 'helpers/Modals.helpers'
import { BackupStatus } from 'models/administration'
import { useDispatch } from 'react-redux'
import {
  deleteBackup,
  downloadBackup,
  resetSystemToBackupVersion,
} from 'redux/administration/administration-backups/AdministrationBackups.slice'

interface BackupsActionsFieldsProps {
  name: string
  id: number
  isDeleteDisabled: boolean
  status: BackupStatus
}

const BackupsActionsFields: React.FC<BackupsActionsFieldsProps> = ({ name, isDeleteDisabled, id, status }) => {
  const dispatch = useDispatch()
  const handleDelete = () => {
    showConfirmationModal({
      content: t({
        id: 'administration.backups.delete_confirmation_text',
        message: `Delete backup "${name}"? This operation cannot be undone.`,
      }),
      okText: trans(deleteText),
      onOk: () => dispatch(deleteBackup(id)),
    })
  }
  const handleRestoreSystem = () => {
    Modal.confirm({
      title: t({ id: 'administration.backups.restore_confirmation_title', message: 'Warning' }),
      content: t({
        id: 'administration.backups.restore_confirmation_text',
        message: `Restore system from backup "${name}"? This operation cannot be undone.`,
      }),
      okText: trans(confirmText),
      onOk: () => dispatch(resetSystemToBackupVersion(id)),
    })
  }
  const isFinished = status === 'FINISHED'
  return (
    <WithDividerElement>
      <DeleteButton
        disabled={isDeleteDisabled}
        disabledTooltip={t({
          id: 'administration.backups.delete_backup_disabled_tooltip',
          message: 'Backup cannot be deleted',
        })}
        tooltip={t({ id: 'administration.backups.delete_backup_tooltip', message: 'Delete backup' })}
        onClick={handleDelete}
      />
      <ButtonWithTooltip
        onClick={() => {
          dispatch(downloadBackup({ backupId: id, withReport: true }))
        }}
        icon={<DownloadOutlined />}
        disabled={!isFinished}
        tooltipText={
          isFinished
            ? t({ id: 'administration.backups.download_backup_tooltip', message: 'Download' })
            : t({ id: 'administration.backups.download_disabled_tooltip', message: "Backup file doesn't exist" })
        }
        tooltipPlacement="topRight"
      />
      <ButtonWithTooltip
        onClick={handleRestoreSystem}
        icon={<SyncOutlined />}
        disabled={!isFinished}
        tooltipText={
          isFinished
            ? t({ id: 'administration.backups.restore_backup_tooltip', message: 'Restore system' })
            : t({
                id: 'administration.backups.restore_disabled_tooltip',
                message: 'Cannot restore system from this backup',
              })
        }
        tooltipPlacement="topRight"
      />
    </WithDividerElement>
  )
}

export default BackupsActionsFields
