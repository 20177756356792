import { MapSettings } from 'models/project/projectDetails'
import { BackendLoading } from 'redux/redux.shared'

export const loadingTypes = [
  'changeProjectionForProject',
  'changeCoordinatesAndScaleForProject',
  'updateMapConfig',
] as const
export type LoadingTypes = typeof loadingTypes[number]

export interface ProjectMapConfigState {
  loading: Record<LoadingTypes, BackendLoading>
  mapSettingsToUpdate: MapSettings
  imageToUpdate?: string
}
