import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Alert, Col, Row, Spin } from 'antd'
import Title from 'antd/es/typography/Title'
import { validateActivationCode } from 'redux/account-activation/AccountActivation.slice'
import { handleApiErrors } from 'helpers/HttpError.helpers'
import selectAccountActivation from 'redux/account-activation/AccountActivation.selectors'
import useActivationParams from 'hooks/query/activationParams'
import { Redirect, useHistory } from 'react-router'
import { t, Trans } from '@lingui/macro'
import { errorText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import PASSWORD_ERROR_MESSAGES from 'data/messages/passwords'
import { selectIsAuthenticated } from 'redux/login/Login.selectors'
import showConfirmationModal from 'helpers/Modals.helpers'
import { SelectLanguageWithPlacement } from 'components/molecules/select-language'
import { logoutUser } from 'redux/login'
import ActivationForm from 'components/organisms/activation'
import styled from 'styled-components'

const StyledDiv = styled.div`
  margin-top: ${(props) => props.theme.sizes.m};
`

function Activation(): JSX.Element {
  const dispatch = useDispatch()
  const { loading, isValid, accountActivated, username, email } = useSelector(selectAccountActivation, shallowEqual)
  const { activationCode } = useActivationParams()
  const history = useHistory()

  const [redirectPath, setRedirectPath] = useState<string | undefined>(undefined)

  const isAuthenticated = useSelector(selectIsAuthenticated)
  const isLoading = loading.validateActivationCode.isLoading || loading.activateAccount.isLoading

  useEffect(() => {
    if (accountActivated) {
      setTimeout(() => {
        setRedirectPath('/login')
      }, 3000)
    }
  }, [accountActivated, dispatch])

  useEffect(() => {
    if (isAuthenticated) {
      showConfirmationModal({
        title: t({ id: 'account_activation.logout_modal_title', message: 'You will be logged out' }),
        onOk: () => dispatch(logoutUser()),
        onCancel: () => history.replace('/'),
      })
    }
  }, [dispatch, isAuthenticated, history])

  useEffect(() => {
    if (isValid === undefined) {
      dispatch(validateActivationCode({ activationCode: activationCode || '' }))
    }
  }, [dispatch, activationCode, isValid])

  const handleErrorMessage = () => {
    if (!isValid) {
      return t({ id: 'account_activation.invalid_activation_code_error', message: 'Invalid activation code' })
    }
    return handleApiErrors(PASSWORD_ERROR_MESSAGES, loading.activateAccount.error?.errors)
  }

  const successAlert = (
    <Alert
      message={t({
        id: 'account_activation.password_set_message',
        message: "Password has been set. You'll be redirected to the login page.",
      })}
      type="success"
      showIcon
    />
  )
  return (
    <div>
      <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
        <Col span={8} />
        <Col span={8}>
          {redirectPath !== undefined && <Redirect to={redirectPath} />}
          <Title level={2}>
            <Trans id="account_activation.header_text">Account activation</Trans>
          </Title>
          <Spin spinning={isLoading}>
            {isValid && <ActivationForm activationCode={activationCode} email={email} username={username} />}
            <StyledDiv>
              {accountActivated && successAlert}
              {isValid !== undefined && (loading.activateAccount.error || !isValid) && (
                <Alert message={trans(errorText)} description={handleErrorMessage()} type="error" showIcon />
              )}
            </StyledDiv>
          </Spin>
        </Col>
        <Col span={8} />
      </Row>
      <SelectLanguageWithPlacement />
    </div>
  )
}

export default Activation
