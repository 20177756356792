import { Tree } from 'antd'
import styled from 'styled-components'
import themePxValueToNumber from 'helpers/Styles.helpers'

const { DirectoryTree } = Tree

export interface StyledTreeProps {
  $isVisible: boolean
}

const StyledTree = styled(DirectoryTree)<StyledTreeProps>`
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${(props) => props.theme.colors.grayG01};
  .ant-tree-node-content-wrapper {
    overflow: hidden;
    display: flex;
    padding-left: 0;
    padding-right: ${(props) => props.theme.sizes.s};
  }
  .ant-tree-indent-unit {
    width: ${(props) => props.theme.sizes.s};
  }
  .ant-tree-title {
    font-size: ${(props) => props.theme.sizes.s};
    color: ${(props) => props.theme.colors.grayG07};
    margin-left: ${(props) => props.theme.sizes.defaultM};
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: ${(props) => props.theme.transitions.default};
    ${(props) => (props.$isVisible ? '' : 'opacity: 0; pointer-events: none;')}
    max-width: calc(100% - ${(props) => themePxValueToNumber(props.theme.sizes.s)}px);
  }
  &.ant-tree.ant-tree-directory .ant-tree-treenode {
    height: ${(props) => props.theme.sizes.l};
    display: flex;
    align-items: center;
    padding: 0;
    .ant-tree-node-content-wrapper {
      white-space: nowrap;
      padding-left: 0;
    }
    .ant-tree-iconEle.ant-tree-icon__customize {
      color: ${(props) => props.theme.colors.grayG05};
      margin-left: ${(props) => props.theme.sizes.s};
    }
    .ant-tree-switcher {
      color: ${(props) => props.theme.colors.grayG05};
      ${(props) => props.theme.mixins.flexCenter}
      width: auto;
      margin-left: ${(props) => props.theme.sizes.s};
    }
    &::before {
      height: 100%;
    }
    &:hover::before {
      background-color: ${(props) => (props.$isVisible ? props.theme.colors.actionHover : 'transparent')};
    }
    &.ant-tree-treenode-selected {
      .ant-tree-switcher {
        color: ${(props) => props.theme.colors.grayG05};
      }
      &::before {
        background-color: ${(props) => (props.$isVisible ? props.theme.colors.actionSelect : 'transparent')};
      }
      .ant-tree-iconEle.ant-tree-icon__customize {
        color: ${(props) => props.theme.colors.primaryP04};
      }
      .ant-tree-title {
        color: ${(props) => props.theme.colors.primaryP04};
      }
    }
  }
  &.ant-tree.ant-tree-directory .ant-tree-list-holder-inner {
    .ant-tree-title {
      margin-left: ${(props) => props.theme.sizes.s};
    }
    > .ant-tree-treenode {
      &-selected:first-child {
        &::before {
          background-color: ${(props) => (props.$isVisible ? props.theme.colors.primaryP02 : 'transparent')};
        }
        .title-icon {
          span {
            ${(props) => (props.$isVisible ? `color: ${props.theme.colors.primaryP04};` : '')}
          }
        }
      }
      &:first-child {
        .ant-tree-iconEle.ant-tree-icon__customize {
          display: none;
        }
        .ant-tree-title {
          color: ${(props) => props.theme.colors.grayG07};
          font-weight: ${(props) => props.theme.fontWeights.semiBold};
          flex-grow: 1;
          overflow: visible;
          opacity: 1;
          pointer-events: auto;
          .title-text {
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .title-content {
            display: flex;
            justify-content: space-between;
          }
          .title-icon {
            transition: ${(props) => props.theme.transitions.default};
            transform: ${(props) =>
              props.$isVisible ? 'rotate(0) translateX(0)' : `rotate(180deg) translateX(-12px)`};
            ${(props) => props.theme.mixins.flexCenter};
          }
        }
      }
    }
  }
`

export default StyledTree
