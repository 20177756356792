import { InfoCircleOutlined } from '@ant-design/icons'
import { Form, Space } from 'antd'
import { FormItemProps } from 'antd/lib/form'
import { NamePath } from 'antd/lib/form/interface'
import Text from 'components/atoms/text'
import { GetFormItemErrors, WithGetErrors } from 'hooks/useFormValidity'
import styled from 'styled-components'

const getValidateStatus = (getErrors?: GetFormItemErrors, name?: NamePath) => (name && getErrors?.(name) ? 'error' : '')

interface ValidationProps {
  validateStatus?: FormItemProps['validateStatus']
  getErrors?: GetFormItemErrors
  help?: React.ReactNode
  name?: NamePath
}

interface GetValidationPropsReturnVal {
  status?: FormItemProps['validateStatus']
  helpVal?: React.ReactNode
}

export const getValidationProps = ({
  validateStatus,
  getErrors,
  help,
  name,
}: ValidationProps): GetValidationPropsReturnVal => {
  const errors = name && getErrors?.(name)
  return {
    status: validateStatus || getErrors ? getValidateStatus(getErrors, name) : undefined,
    helpVal:
      help || getErrors
        ? (Array.isArray(errors) && (
            <Space size={0} direction="vertical">
              {errors.map((err) => (
                <span>{err}</span>
              ))}
            </Space>
          )) ||
          ''
        : undefined,
  }
}

export interface BaseFormItemProps {
  noMargin?: boolean
  boldLabel?: boolean
}

interface StyledFormItemProps {
  readonly showHelp: boolean
}

const StyledFormItem = styled(Form.Item)<StyledFormItemProps>`
  .ant-form-item-explain {
    display: ${({ showHelp }) => (showHelp ? 'block' : 'none')};
  }
`

const BaseFormItem: React.FC<BaseFormItemProps & FormItemProps & WithGetErrors & { showHelp?: boolean }> = ({
  label,
  children,
  name,
  rules,
  validateStatus,
  help,
  noMargin = false,
  hasFeedback,
  tooltip,
  dependencies,
  valuePropName,
  getErrors,
  className,
  style,
  validateFirst,
  fieldKey,
  boldLabel,
}) => {
  const { status, helpVal } = getValidationProps({ validateStatus, getErrors, help, name })
  return (
    <StyledFormItem
      showHelp={!!helpVal || getErrors === undefined}
      validateStatus={status}
      colon={false}
      dependencies={dependencies}
      name={name}
      hasFeedback={hasFeedback}
      tooltip={tooltip ? { title: tooltip, icon: <InfoCircleOutlined /> } : undefined}
      valuePropName={valuePropName}
      className={className}
      help={
        helpVal && (
          <Text textStyle="regularText-small" color={status === 'error' ? 'error' : 'grayG05'}>
            {helpVal}
          </Text>
        )
      }
      rules={rules}
      style={{ ...style, marginBottom: noMargin ? 0 : 16 }}
      label={
        label && (
          <Text textStyle={boldLabel ? 'regularText-smallSemiBold' : 'regularText-small'} color="grayG08">
            {label}
          </Text>
        )
      }
      validateFirst={validateFirst}
      fieldKey={fieldKey}
    >
      {children}
    </StyledFormItem>
  )
}

export default BaseFormItem
