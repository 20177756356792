import { Space } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import Table from 'components/molecules/table'
import Button from 'components/molecules/button-element'
import Modal from 'components/molecules/modal/Modal'
import { useDispatch } from 'react-redux'
import { setFileInfoModalVisible } from 'redux/administration/administration-users/AdministrationUsers.slice'
import { LoadUserError } from 'redux/administration/administration-users/AdministrationUsers.types'
import Text from 'components/atoms/text'
import styled from 'styled-components'
import { Trans } from '@lingui/macro'
import trans from 'helpers/i18n.helpers'
import { okText } from 'data/messages/controls'
import ErrorOverview from './UserOverview'

interface LoadingErrorInfoModalProps {
  errors: LoadUserError[]
}

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 10px;
  }
`

const useTableColumns = (): ColumnsType<LoadUserError> => {
  return [
    {
      title: <Trans id="administration.users.load_errors_modal.email_column_header">Email</Trans>,
      dataIndex: 'email',
      key: 'email',
      width: 75,
      render: (value, { email }) => email,
    },
    {
      title: <Trans id="administration.users.load_errors_modal.details_column_header">Details</Trans>,
      dataIndex: 'details',
      key: 'details',
      width: 200,
      render: (value, err) => <ErrorOverview error={err} />,
    },
  ]
}

const LoadUsersFromFileInfoModal: React.FC<LoadingErrorInfoModalProps> = ({ errors }) => {
  const dispatch = useDispatch()
  const handleClose = () => dispatch(setFileInfoModalVisible(false))
  const footer = <Button onClick={handleClose}>{trans(okText)}</Button>
  const modalTitle = <Trans id="administration.users.load_errors_modal.modal_header">Added users</Trans>
  const tableColumns = useTableColumns()
  return (
    <StyledModal visible maskClosable={false} footer={footer} title={modalTitle} onCancel={handleClose}>
      <Space direction="vertical">
        <Text textStyle="regularText-small">
          <Trans id="administration.users.load_errors_modal.users_already_exist_message">
            The following users are already defined. Their attributes were not modified.
          </Trans>
        </Text>
        <Table dataSource={errors} columns={tableColumns} pagination={false} rowKey="email" />
      </Space>
    </StyledModal>
  )
}

export default LoadUsersFromFileInfoModal
