import { Trans } from '@lingui/macro'
import { Space } from 'antd'
import SelectElement from 'components/atoms/select-element'
import Text from 'components/atoms/text'
import { selectText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import { useSelector } from 'react-redux'
import { setSelectedScheme } from 'redux/project/project-config/naming-scheme'
import {
  selectAvailableSchemes,
  selectSchemeSource,
} from 'redux/project/project-config/naming-scheme/ProjectNamingSchemeConfig.selectors'
import { useAppDispatch } from 'redux/store'

const SelectScheme: React.FC = () => {
  const schemes = useSelector(selectAvailableSchemes)
  const sourceScheme = useSelector(selectSchemeSource)
  const defaultScheme = sourceScheme ?? schemes.find(({ isDefault }) => isDefault)?.id
  const dispatch = useAppDispatch()
  return (
    <Space direction="vertical">
      <Text textStyle="regularText-small">
        <Trans id="project_config.naming_schemes.schame_name">Scheme name:</Trans>
      </Text>
      <SelectElement
        style={{ width: '200px' }}
        bordered
        onChange={(val) => {
          dispatch(setSelectedScheme(val as number))
        }}
        value={defaultScheme}
        placeholder={trans(selectText)}
        options={schemes.map((scheme) => ({
          value: scheme.id,
          label: scheme?.isDefault ? (
            <Text textStyle="regularText-smallSemiBold" color="primaryP05">
              {scheme.name}
            </Text>
          ) : (
            scheme.name
          ),
        }))}
      />
    </Space>
  )
}

export default SelectScheme
