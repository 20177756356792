import { useSelector } from 'react-redux'
import { Card } from 'antd'
import styled from 'styled-components'
import FilesDetailsTabs from 'components/organisms/files/sidedrawer/content/documentation/details/FilesDetailsTabs'
import { selectDetailsType } from 'redux/files/files-details/FilesDetails.selectors'
import FilesDetailsDoc from './FilesDetailsDoc'
import FilesDetailsDocs from './FilesDetailsDocs'
import FilesDetailsDirNav from './group/FilesDetailsGroupCurrent'
import FilesDetailsDirCheck from './group/FilesDetailsGroupChecked'

const StyledCard = styled(Card)`
  padding: ${(props) => props.theme.sizes.xs} 0;
  width: 296px;
  min-height: 0;
  .ant-card-body {
    display: flex;
    flex-direction: column;
    > * {
      &:first-child {
        margin: 0 ${(props) => props.theme.sizes.default} ${(props) => props.theme.sizes.default};
        order: 2;
      }
      &:nth-child(2) {
        margin: 0 ${(props) => props.theme.sizes.xs} ${(props) => props.theme.sizes.default};
        order: 1;
      }
      &:last-child {
        overflow: auto;
        order: 3;
      }
    }
  }
`

const FilesDetails: React.FC = () => {
  const detailsType = useSelector(selectDetailsType)

  if (!detailsType) return <StyledCard />

  const detailsDict = {
    DOC: <FilesDetailsDoc />,
    DOCS: <FilesDetailsDocs />,
    DIR_CURRENT: <FilesDetailsDirNav />,
    DIR_CHECKED: <FilesDetailsDirCheck />,
  }

  return (
    <StyledCard>
      <FilesDetailsTabs />
      {detailsDict[detailsType]}
    </StyledCard>
  )
}

export default FilesDetails
