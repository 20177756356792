import styled from 'styled-components'
import { MyLocation } from '@material-ui/icons'
import Icon from 'components/atoms/icon'
import { useDispatch } from 'react-redux'
import { setShouldCameraReset } from 'redux/cesium-viewer'

const StyledDiv = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 20px;
  padding: ${(props) => props.theme.sizes.xs};
  background-color: ${(props) => props.theme.colors.baseWhiteish};
  ${(props) => props.theme.mixins.flexCenter}
`

const ShowLocation: React.FC = () => {
  const dispatch = useDispatch()
  return (
    <StyledDiv>
      <Icon onClick={() => dispatch(setShouldCameraReset(true))}>
        <MyLocation />
      </Icon>
    </StyledDiv>
  )
}

export default ShowLocation
