import GlobalReset from './Global.reset'
import GlobalVariables from './Global.variables'
import GlobalBase from './Global.base'
import GlobalComponents from './Global.components'

function GlobalStyles(): JSX.Element {
  return (
    <>
      <GlobalReset />
      <GlobalVariables />
      <GlobalBase />
      <GlobalComponents />
    </>
  )
}

export default GlobalStyles
