import { BACKEND_SERVER_URL } from 'environment/environment'

export const loginUserUrl = `${BACKEND_SERVER_URL}/api/token/`

export const checkActivationCode = (activationCode: string): string => {
  return `${BACKEND_SERVER_URL}/activation-codes/${activationCode}/`
}

export const activateUser = `${BACKEND_SERVER_URL}/users/activation/`

export const requestPasswordResetUrl = `${BACKEND_SERVER_URL}/users/password/request-reset/`
export const resetPasswordUrl = `${BACKEND_SERVER_URL}/users/password/reset/`
export const verifyPasswordResetTokenUrl = `${BACKEND_SERVER_URL}/users/password/verify-token/`
