import { Input } from 'antd'
import Text from 'components/atoms/text'
import { t, Trans } from '@lingui/macro'
import { FormListFieldData } from 'antd/lib/form/FormList'
import { nameInputRules } from 'helpers/Validators.helpers'
import { WithGetErrors } from 'hooks/useFormValidity'
import BaseFormItem from '../form-item/BaseFormItem'

interface CodeItemProps {
  isFirstInColumn: boolean
  field: FormListFieldData
  showButtons: boolean
  fieldName: string
  disabled: boolean
  nameLabel?: string
}

const ValueItem: React.FC<CodeItemProps & WithGetErrors> = ({
  isFirstInColumn,
  field,
  showButtons,
  fieldName,
  disabled = false,
  nameLabel,
  getErrors,
}) => {
  return (
    <BaseFormItem
      name={[field.name, fieldName]}
      label={
        isFirstInColumn && (
          <Text textStyle="regularText-smallSemiBold">
            {nameLabel || <Trans id="administration.attributes.modal.value_column_label">Value</Trans>}
          </Text>
        )
      }
      colon={false}
      style={{
        display: 'inline-block',
        width: showButtons ? 'calc(55% - 8px)' : 'calc(57% - 8px)',
        margin: '0 8px',
      }}
      rules={nameInputRules()}
      fieldKey={[field.fieldKey, fieldName]}
      getErrors={getErrors}
    >
      <Input
        disabled={disabled || !fieldName}
        placeholder={t({ id: 'administration.attributes.modal.value_name_placeholder', message: 'Name' })}
      />
    </BaseFormItem>
  )
}

export default ValueItem
