import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import localStorage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import { FALLBACK_LOCALE, Language, localesData } from 'data/i18n'
import { getInitialLanguage } from 'helpers/i18n.helpers'
import { i18n } from '@lingui/core'
import moment from 'moment'
import { getStateAsAny } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import LanguageSelectState from './Language.types'

interface SetLanguageReturnValue {
  language: Language
  added: boolean
}

const selectLoadedLanguages = (state: RootState): Language[] => state.language.loadedLanguages

export const setLanguage = createAsyncThunk(
  'language/setLanguage',
  async ({ language }: { language?: Language }, { getState }): Promise<SetLanguageReturnValue> => {
    const state = getStateAsAny(getState)
    const lang: Language = language || state.language.language || getInitialLanguage()
    let languageAdded = false
    if (!selectLoadedLanguages(state).includes(lang)) {
      const { lingui } = localesData[lang] || localesData[FALLBACK_LOCALE]
      i18n.loadLocaleData(lang, { plurals: lingui.plurals })
      i18n.load(lang, lingui.messages)
      languageAdded = true
    }
    i18n.activate(lang)
    moment.locale(lang)
    return { language: lang, added: languageAdded }
  }
)

const initialState: LanguageSelectState = {
  loadedLanguages: [],
}

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder.addCase(setLanguage.fulfilled, (state, action: PayloadAction<SetLanguageReturnValue>) => {
      const { language, added } = action.payload
      if (state.language !== language) {
        state.language = language
      }
      if (added) {
        state.loadedLanguages.push(language)
      }
    }),
})

const persistConfig = {
  key: 'language',
  storage: localStorage,
  whitelist: ['language'],
}

export const languageReducer = persistReducer(persistConfig, languageSlice.reducer)
