import { PlusOutlined } from '@ant-design/icons'
import TooltipElement from 'components/molecules/tooltip-element'
import { t } from '@lingui/macro'
import ButtonElement from 'components/molecules/button-element'

interface AddButtonProps {
  showButtons: boolean
  disabled: boolean
  text?: string
  addValue: () => void
}

const DeleteValueButton: React.FC<AddButtonProps> = ({ showButtons, disabled, addValue, text }) => {
  if (!showButtons) {
    return null
  }
  return (
    <TooltipElement
      disabled={!disabled}
      title={t({
        id: 'administration.attributes.modal.project_values_not_allowed_text',
        message: 'Project values are not allowed',
      })}
      placement="left"
    >
      <ButtonElement type="dashed" onClick={() => addValue()} icon={<PlusOutlined />} disabled={disabled}>
        {text || t({ id: 'administration.attributes.modal.add_value_button_text', message: 'Add value' })}
      </ButtonElement>
    </TooltipElement>
  )
}

export default DeleteValueButton
