import { SuitabilityCodeWithDetails } from 'models/administration'
import { BackendLoading } from 'redux/redux.shared'

export const loadingTypes = ['fetchSuitabilityCodes', 'createSuitabilityCode'] as const
export type LoadingTypes = typeof loadingTypes[number]

export interface ModalData {
  isModalVisible: boolean
}

export interface AdministrationSuitabilityState {
  loading: Record<LoadingTypes, BackendLoading>
  codes: SuitabilityCodeWithDetails[]
  modalData: ModalData
}
