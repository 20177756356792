import { Trans } from '@lingui/macro'
import { useDispatch } from 'react-redux'
import { openProjectModal } from 'redux/administration/administration-projects'
import AdministrationToolbarButton from '../toolbar/AdministrationToolbar'

const AdministrationProjectsToolbar: React.FC = () => {
  const dispatch = useDispatch()
  return (
    <AdministrationToolbarButton
      text={<Trans id="administration.projects.create_new_project_button_text">Create new project</Trans>}
      onClick={() => dispatch(openProjectModal())}
    />
  )
}

export default AdministrationProjectsToolbar
