import { t } from '@lingui/macro'
import { DeleteButton } from 'components/molecules/buttons'
import { deleteText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import showConfirmationModal from 'helpers/Modals.helpers'
import { AttributeType } from 'redux/administration/administration-naming-scheme/AdministrationNamingScheme.types'

interface SchemeFieldActionsProps {
  metadataId?: number
  type: AttributeType
  name: string
  onDelete?: () => void
  disabled: boolean
}

const SchemeFieldActions: React.FC<SchemeFieldActionsProps> = ({ name, onDelete, disabled }) => {
  const handleDelete = () => {
    showConfirmationModal({
      content: t({
        id: 'administration.naming_schemes.delete_field_confirmation_text',
        message: `Remove field "${name}" from naming scheme?`,
      }),
      okText: trans(deleteText),
      onOk: () => onDelete?.(),
    })
  }
  const isButtonDisabled = disabled || !onDelete
  return (
    <DeleteButton
      disabled={isButtonDisabled}
      onClick={handleDelete}
      disabledTooltip={t({
        id: 'administration.naming_schemes.disabled_delete_field_tooltip',
        message: 'Field cannot be deleted',
      })}
      tooltip={t({
        id: 'administration.naming_schemes.delete_field_tooltip',
        message: 'Delete field',
      })}
    />
  )
}

export default SchemeFieldActions
