import { t } from '@lingui/macro'
import Modal, { ModalFuncProps } from 'antd/lib/modal'

const showConfirmationModal = (props: ModalFuncProps): void => {
  Modal.confirm({
    title: props.title || t({ id: 'common.confirmation_modal.title', message: 'Confirm' }),
    cancelText: props.cancelText || t({ id: 'common.confirmation_modal.cancel_text', message: 'Cancel' }),
    okText: props.okText || t({ id: 'common.confirmation_modal.ok_text', message: 'OK' }),
    ...props,
  })
}

export const showWarningModal = (props: ModalFuncProps): void => {
  Modal.warning({
    okText: props.okText || t({ id: 'common.confirmation_modal.ok_text', message: 'OK' }),
    ...props,
  })
}

export default showConfirmationModal
