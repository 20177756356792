import { Close } from '@material-ui/icons'
import { Space } from 'antd'
import Icon from 'components/atoms/icon'
import Text from 'components/atoms/text'
import ActivableText from 'components/molecules/activable-text'
import { Draggable } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import {
  createCanStageBeModifiedSelector,
  selectProjectStages,
} from 'redux/project/project-config/stages/ProjectStagesConfig.selectors'
import { removeStage } from 'redux/project/project-config/stages/ProjectStagesConfig.slice'
import { Color } from 'styled-components'

interface StagesDroppableProps {
  id: number
  name: string
  idx: number
}

const getTextColor = (canStageBeModified: boolean, isCurrentStage: boolean): Color => {
  if (!canStageBeModified && !isCurrentStage) return 'grayG05'
  return isCurrentStage ? 'baseWhiteish' : 'grayG07'
}

const StagesDroppable: React.FC<StagesDroppableProps> = ({ id, name, idx }) => {
  const dispatch = useDispatch()
  const currentStage = useSelector(selectProjectStages)?.current
  const canStageBeModified = useSelector(createCanStageBeModifiedSelector(id))
  const isCurrentStage = currentStage === id
  const textColor = getTextColor(canStageBeModified, isCurrentStage)
  return (
    <Draggable key={id} draggableId={id.toString()} index={idx} isDragDisabled={!canStageBeModified}>
      {(draggableProvided) => (
        <div
          ref={draggableProvided.innerRef}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...draggableProvided.draggableProps}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...draggableProvided.dragHandleProps}
          style={draggableProvided.draggableProps.style}
        >
          <Space size="small">
            <Text textStyle="regularText-small" color="grayG06">
              {idx + 1}.
            </Text>
            <ActivableText
              activeColor={isCurrentStage ? 'primaryP04' : 'primaryP01'}
              textColor={textColor}
              isActive
              icon={
                canStageBeModified && (
                  <Icon
                    onClick={() => {
                      dispatch(removeStage(id))
                    }}
                    size="default"
                    color={textColor}
                  >
                    <Close />
                  </Icon>
                )
              }
            >
              {name}
            </ActivableText>
          </Space>
        </div>
      )}
    </Draggable>
  )
}

export default StagesDroppable
