import styled from 'styled-components'
import FilesDetailsTab from 'components/molecules/files/files-details/FilesDetailsTab'
import { Trans } from '@lingui/macro'

const StyledDiv = styled.div`
  background-color: ${(props) => props.theme.colors.grayG01};
  padding: 0 ${(props) => props.theme.sizes.m};
  border-radius: ${(props) => props.theme.sizes.l};
  display: flex;
  > *:not(:last-child) {
    margin-right: ${(props) => props.theme.sizes.m};
  }
`

const FilesDetailsTabs: React.FC = () => {
  return (
    <StyledDiv>
      <FilesDetailsTab tabType="CHARACTERISTICS">
        <Trans id="project.files.file_info.details_text">Details</Trans>
      </FilesDetailsTab>
      <FilesDetailsTab tabType="ATTRIBUTES">
        <Trans id="project.files.file_info.attributes_text">Attributes</Trans>
      </FilesDetailsTab>
    </StyledDiv>
  )
}

export default FilesDetailsTabs
