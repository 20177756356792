import { t } from '@lingui/macro'
import { createSlice } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import { removedErrorsFromLoading } from 'helpers/HttpError.helpers'
import { openSuccessNotification } from 'helpers/Notifications.helpers'
import { CodeField } from 'models/administration'
import {
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
} from 'redux/redux.shared'
import {
  loadingTypes,
  LoadingTypes,
  AdministrationCodeSettingsState,
  CodeType,
  CodeTypes,
  CodeSetting,
} from './AdministrationCodeSettings.types'

const codeEditPaths: Record<string, string> = {
  [CodeTypes.ORGANIZATION_CODE]: '/organizations/settings/',
  [CodeTypes.DISCIPLINE_CODE]: '/disciplines/settings/',
  [CodeTypes.PROJECT_CODE]: '/projects/settings/',
  [CodeTypes.SUITABILITY_CODE_CODE]: '/suitability/settings/',
  [CodeTypes.PROJECT_STAGE_CODE]: '/projects/stages/settings/',
  [CodeTypes.WAITING_ROOM_SIZE]: '/waiting-room/settings/',
}

interface EditCodeArgs {
  codeType: CodeType
  newCodeLength: number
  values: {
    id: number
    code: CodeField
  }[]
}

export const fetchCodeLength = createAsyncBackendThunk('fetchCodeLength', async (codeType: CodeType) => {
  const response = await backendAxios.get(codeEditPaths[codeType])
  return response.data
})

const fetchCodeSettings = createAsyncBackendThunk('fetchCodeSettings', async (codeType: CodeType) => {
  return (await backendAxios.get(codeEditPaths[codeType])).data
})

export const fetchAllCodeSettings = createAsyncBackendThunk('fetchAllCodeSettings', async (_, { dispatch }) => {
  const promises = [
    dispatch(fetchCodeSettings(CodeTypes.ORGANIZATION_CODE)),
    dispatch(fetchCodeSettings(CodeTypes.DISCIPLINE_CODE)),
    dispatch(fetchCodeSettings(CodeTypes.PROJECT_CODE)),
    dispatch(fetchCodeSettings(CodeTypes.SUITABILITY_CODE_CODE)),
    dispatch(fetchCodeSettings(CodeTypes.PROJECT_STAGE_CODE)),
  ]
  await Promise.allSettled(promises)
})

export const editCodeLength = createAsyncBackendThunk(
  'editCodeLength',
  async ({ codeType, newCodeLength, values }: EditCodeArgs) => {
    const response = await backendAxios.patch(codeEditPaths[codeType], { length: newCodeLength, values })
    openSuccessNotification({
      message: t({
        id: 'administration.common.codes.notifications.updated_text',
        message: 'Code length has been updated',
      }),
    })
    return response.data
  }
)

const initialState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  modalData: { isVisible: false },
  codeLengthDict: {},
  codesData: {},
} as AdministrationCodeSettingsState

const administrationCodeSettingsSlice = createSlice({
  name: 'attributes',
  initialState,
  reducers: {
    showCodeSettingsModal: (state) => {
      state.modalData.isVisible = true
    },
    hideCodeSettingsModal: (state) => {
      state.modalData.isVisible = false
      state.loading = removedErrorsFromLoading(state.loading)
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<AdministrationCodeSettingsState, LoadingTypes, { length: number }, CodeType>({
      promise: fetchCodeLength,
      loadingType: 'fetchCodeLength',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        state.codeLengthDict[action.meta.arg] = action.payload.length
      },
    }),
    generateExtraBackendReducers<AdministrationCodeSettingsState, LoadingTypes, CodeSetting, CodeType>({
      promise: fetchCodeSettings,
      loadingType: 'fetchCodeSettings',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        state.codesData[action.meta.arg] = action.payload
      },
    }),
    generateExtraBackendReducers<AdministrationCodeSettingsState, LoadingTypes, { length: number }, EditCodeArgs>({
      promise: editCodeLength,
      loadingType: 'editCodeLength',
      onPending: (state) => {
        state.loading = removedErrorsFromLoading(state.loading)
      },
      onFulfilled: (state, action) => {
        state.codeLengthDict[action.meta.arg.codeType] = action.payload.length
        state.modalData = initialState.modalData
      },
    }),
  ]),
})

export const { showCodeSettingsModal, hideCodeSettingsModal } = administrationCodeSettingsSlice.actions

export const administrationCodeSettingsReducer = administrationCodeSettingsSlice.reducer
