import { SearchOutlined } from '@ant-design/icons'
import { FilterDropdownProps } from 'antd/lib/table/interface'
import Icon from 'components/atoms/icon'
import FilterDropdown, { FilterProps } from './FilterDropdown'

const useTableColumnFiltering = (
  placeholder: string
): {
  filterDropdown?: React.ReactNode | ((props: FilterDropdownProps) => React.ReactNode)
  filterIcon?: React.ReactNode | ((filtered: boolean) => React.ReactNode)
} => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, visible }: FilterProps) => (
    <FilterDropdown
      placeholder={placeholder}
      setSelectedKeys={setSelectedKeys}
      selectedKeys={selectedKeys}
      confirm={confirm}
      clearFilters={clearFilters}
      visible={visible}
    />
  ),
  filterIcon: (filtered: boolean) => (
    <Icon color={filtered ? 'cyan6' : 'grayG05'} size="s">
      <SearchOutlined />
    </Icon>
  ),
})

export function onFilterStringColumn(value: string | number | boolean, cell: string): boolean {
  return cell.toLowerCase().includes(value.toString().toLowerCase())
}

export default useTableColumnFiltering
