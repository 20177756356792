import { Spin } from 'antd'
import StepsElement from 'components/molecules/steps'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import useProjectSteps from './useProjectConfigSteps'

interface ProjectConfigStepsProps {
  currentStep: number
  getSavingStepHandler: (onComplete: () => void, shouldCompleteConfig: boolean) => () => void
  isLoading?: boolean
}

const ProjectConfigSteps: React.FC<ProjectConfigStepsProps> = ({ currentStep, getSavingStepHandler, isLoading }) => {
  const stepsData = useProjectSteps(currentStep)
  const history = useHistory()
  const projectId = useSelector(selectProjectId)
  return (
    <Spin spinning={isLoading}>
      <StepsElement
        direction="vertical"
        stepsData={stepsData}
        current={currentStep}
        onChange={(step) =>
          getSavingStepHandler(() => history.push(`/project-config/${projectId}/${step + 1}`), false)()
        }
      />
    </Spin>
  )
}

export default ProjectConfigSteps
