import { defineMessage } from '@lingui/macro'

export const organizationActiveText = defineMessage({
  id: 'administration.organizations.common.organization_activity_status_active_text',
  message: 'Active',
})

export const organizationInactiveText = defineMessage({
  id: 'administration.organizations.common.organization_activity_status_inactive_text',
  message: 'Inactive',
})

export const noContactPersonDefinedText = defineMessage({
  id: 'administration.organizations.common.no_contact_person_defined_text',
  message: 'No contact person defined',
})

export const noAddressDefinedText = defineMessage({
  id: 'administration.organizations.table.address.no_address_text',
  message: 'No address',
})
