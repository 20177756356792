import { useAppDispatch } from 'redux/store'
import Button from 'components/molecules/button-element'
import { hideAttributeModal } from 'redux/administration/administration-attributes'
import { BackendLoading } from 'redux/redux.shared'
import { addText, cancelText, saveText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'

interface ModalProps {
  handleSubmit: () => void
  isEditingModal: boolean
  addLoading: BackendLoading
  editLoading: BackendLoading
  isSubmitButtonDisabled: boolean
}

const AttributeModalFooter: React.FC<ModalProps> = ({
  handleSubmit,
  isEditingModal,
  addLoading,
  editLoading,
  isSubmitButtonDisabled,
}) => {
  const dispatch = useAppDispatch()
  const handleCancel = () => dispatch(hideAttributeModal())
  return (
    <>
      <Button onClick={handleCancel} type="tertiary">
        {trans(cancelText)}
      </Button>
      <Button
        loading={isEditingModal ? editLoading.isLoading : addLoading.isLoading}
        onClick={handleSubmit}
        disabled={isSubmitButtonDisabled}
      >
        {trans(isEditingModal ? saveText : addText)}
      </Button>
    </>
  )
}

export default AttributeModalFooter
