import { combineReducers } from 'redux'
import { wrapWithClear } from 'helpers/Functions.helpers'
import { filesClear } from 'redux/action.types'
import { filesNewEntryReducer } from './files-new-entry'
import { filesDocumentationReducer } from './files-documentation'
import { filesDetailsReducer } from './files-details'
import { filesControlsReducer } from './files-controls'
import { filesNewVersionReducer } from './files-new-version'
import { filesTableReducer } from './files-table'
import { filesSuitabilityReducer } from './files-suitability'
import { flowsReducer } from './flows'
import { filesViewerReducer } from './ifc-viewer/FilesViewer.slice'

const filesReducer = combineReducers({
  documentation: filesDocumentationReducer,
  details: filesDetailsReducer,
  controls: filesControlsReducer,
  newVersion: filesNewVersionReducer,
  newEntry: filesNewEntryReducer,
  table: filesTableReducer,
  suitability: filesSuitabilityReducer,
  flows: flowsReducer,
  viewer: filesViewerReducer,
})

export default wrapWithClear(filesReducer, filesClear)
