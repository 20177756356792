import { defineMessage, t, Trans } from '@lingui/macro'
import { useSelector } from 'react-redux'
import { Modal, Form, Spin } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import DividerElement from 'components/atoms/divider'
import ErrorAlert from 'components/atoms/error-alert'
import Text from 'components/atoms/text'
import Icon from 'components/atoms/icon'
import FormInput from 'components/molecules/form-item/FormInput'
import FormInputNumber from 'components/molecules/form-item/FormNumberInput'
import ManageValueAndNameFields from 'components/molecules/code-form-field/ManageValueAndNameFields'
import { useAppDispatch } from 'redux/store'
import { getAlertMessageDict } from 'helpers/HttpError.helpers'
import { AttributeValue } from 'models/project/projectConfig'
import {
  selectEditMetadataLoading,
  selectAttributeInModal,
} from 'redux/project/project-config/attributes/ProjectAttributesConfig.selectors'
import { hideProjectAttributesModal } from 'redux/project/project-config/attributes'
import { editAttributeValues } from 'redux/project/project-config/attributes/ProjectAttributesConfig.slice'
import AttributeModalFooter from './AttributeModalFooter'

interface ModalProps {
  visible: boolean
}

interface FormValues {
  systemValues: AttributeValue[]
  projectValues: AttributeValue[]
  valueCodeLength: number
  name: string
}

const errorMessageDict = {
  'metadata.is_used': defineMessage({
    id: 'project_config.attributes.modal.alert.metadata_is_used',
    message: 'Attribute is used and cannot be removed.',
  }),
  'metadata_values.currently_used': defineMessage({
    id: 'project_config.attributes.modal.alert.metadata_value_is_used',
    message: 'Attribute value is used and cannot be removed.',
  }),
  'metadata_values.already_exists': defineMessage({
    id: 'project_config.attributes.modal.alert.metadata_value_exists',
    message: 'Attribute value with given name already exists.',
  }),
  'metadata.invalid_code_length': defineMessage({
    id: 'project_config.attributes.modal.alert.metadata_code_length_iinvalid',
    message: 'Attribute code length is invalid.',
  }),
  'metadata_values.code_already_exists': defineMessage({
    id: 'project_config.attributes.modal.alert.metadata_value_code_exists',
    message: 'Attribute value with given code already exists.',
  }),
  'metadata_values.already_available_in_project': defineMessage({
    id: 'project_config.attributes.modal.alert.metadata_value_added_to_project',
    message: 'Attribute value has been already added to this project.',
  }),
  'metadata_values.invalid_code_length': defineMessage({
    id: 'project_config.attributes.modal.alert.metadata_value_invalid_code_length',
    message: 'Value code length is invalid.',
  }),
  'metadata.does_not_exist': defineMessage({
    id: 'project_config.attributes.modal.alert.metadata_does_not_exist',
    message: 'Attribute does not exist.',
  }),
}

function mapValues(values: AttributeValue[]) {
  return values.map(({ id, code, name }) => ({ id, code, value: name }))
}

const AttributeModal: React.FC<ModalProps> = ({ visible }) => {
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const handleCancel = () => dispatch(hideProjectAttributesModal())
  const editedAttribute = useSelector(selectAttributeInModal)
  const { isLoading, error } = useSelector(selectEditMetadataLoading)
  const handleSubmit = async () => {
    if (editedAttribute !== undefined) {
      const fieldsToUpdate: FormValues = form.getFieldsValue()
      const valuesList = fieldsToUpdate.projectValues
      if (valuesList === undefined) {
        handleCancel()
        return
      }
      dispatch(
        editAttributeValues({
          metadataId: editedAttribute.id,
          values: mapValues(valuesList),
        })
      )
    }
  }
  const titleText = (
    <Text textStyle="header-H2b">
      <Trans id="project_config.metadata.modal.attribute_project_values">Attribute project values</Trans>
    </Text>
  )
  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      width={1400}
      closeIcon={
        <Icon>
          <CloseOutlined />
        </Icon>
      }
      maskClosable={false}
      title={titleText}
      footer={<AttributeModalFooter handleSubmit={handleSubmit} isLoading={isLoading} />}
    >
      <Spin spinning={isLoading}>
        <Form
          form={form}
          labelCol={{ sm: { span: 7 } }}
          initialValues={{
            name: editedAttribute?.name,
            systemValues: editedAttribute?.attributeValues.filter((a) => !a.isProjectValue),
            projectValues: editedAttribute?.attributeValues.filter((a) => a.isProjectValue),
            valueCodeLength: editedAttribute?.valueCodeLength,
          }}
        >
          <FormInput
            disabled
            label={t({ id: 'project_config.attributes.modal.attribute_label', message: 'Attribute name' })}
            name="name"
          />
          <FormInputNumber
            disabled
            label={t({ id: 'project_config.attributes.modal.value_code_length_label', message: 'Value code length' })}
            name="valueCodeLength"
            placeholder={t({
              id: 'project_config.attributes.modal.value_code_placecholder',
              message: 'Enter value code',
            })}
          />
          <DividerElement />
          <Text textStyle="regularText-small">
            <Trans id="project_config.metadata.modal.project_values">Project values</Trans>
          </Text>
          <ManageValueAndNameFields
            isDisabled={false}
            formListName="projectValues"
            valueFieldName="name"
            buttonText={t({ id: 'project_config.attributes.modal.add_values', message: 'Add values to project' })}
            isAddingDisabled={!editedAttribute?.projectValuesAllowed}
            deletable={editedAttribute?.attributeValues.filter((a) => a.isProjectValue).map((v) => !v.selected)}
          />
          <DividerElement />
          <Text textStyle="regularText-small">
            <Trans id="project_config.metadata.modal.system_values">System values</Trans>
          </Text>
          <ManageValueAndNameFields isDisabled formListName="systemValues" valueFieldName="name" />
        </Form>
        {error !== undefined && <ErrorAlert alertMessageDict={getAlertMessageDict(errorMessageDict, error)} />}
      </Spin>
    </Modal>
  )
}

export default AttributeModal
