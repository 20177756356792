import { BasicOrganizationData, WipGroup } from 'models/administration'
import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import { ProjectWipGroupsConfigState } from './ProjectWipGroupsConfig.types'

const selectProjectWipGroupsConfig: (state: RootState) => ProjectWipGroupsConfigState = (state) =>
  state.project.config.wipGroups

export const selectProjectWipGroups: (state: RootState) => WipGroup[] = (state) =>
  selectProjectWipGroupsConfig(state).wipGroups

export const selectProjectWipGroupById = (id: number) => (state: RootState): WipGroup | undefined =>
  selectProjectWipGroups(state).find((wip) => wip.id === id)

export const selectOrganizationsByWipId = (wipId: number) => (state: RootState): BasicOrganizationData[] | undefined =>
  selectProjectWipGroups(state).find((wip) => wip.displayedId === wipId)?.organizations

export const selectFetchingWipGroupsLoading: (state: RootState) => BackendLoading = (state) =>
  selectProjectWipGroupsConfig(state).loading.fetchWipGroups

export const selectUpdatingWipGroupsLoading: (state: RootState) => BackendLoading = (state) =>
  selectProjectWipGroupsConfig(state).loading.updateWipGroups

export const selectIsProjectWipGroupsConfigContentLoading: (state: RootState) => boolean = (state) =>
  selectFetchingWipGroupsLoading(state).isLoading || selectUpdatingWipGroupsLoading(state).isLoading
