import { Project } from 'models/administration'
import { ActiveUser } from 'models/users'
import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import { AdministrationProjectsState } from './AdministrationProjects.types'

const selectProjectsState: (state: RootState) => AdministrationProjectsState = (state) => state.administration.projects

export const selectAdministrationProjects: (state: RootState) => Project[] = (state) =>
  selectProjectsState(state).projects

export const selectFetchingProjectsLoading: (state: RootState) => BackendLoading = (state) =>
  selectProjectsState(state).loading.fetchProjects

export const selectFetchingActiveUsersLoading: (state: RootState) => BackendLoading = (state) =>
  selectProjectsState(state).loading.fetchActiveUsers

export const selectUpdateProjectLoading: (state: RootState) => BackendLoading = (state) =>
  selectProjectsState(state).loading.updateProject

export const selectCreateProjectLoading: (state: RootState) => BackendLoading = (state) =>
  selectProjectsState(state).loading.createProject

export const selectIsProjectModalVisible: (state: RootState) => boolean = (state) =>
  selectProjectsState(state).modalState.isModalVisible

export const selectProjectDisplayedInModal: (state: RootState) => Project | undefined = (state) =>
  selectProjectsState(state).modalState.project

export const selectProjectAdministrationActiveUsers: (state: RootState) => ActiveUser[] = (state) =>
  selectProjectsState(state).users

export const selectRemovingProjectLoading: (state: RootState) => BackendLoading = (state) =>
  selectProjectsState(state).loading.deleteProject
