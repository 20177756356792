import { Menu } from 'antd'
import SubMenu from 'antd/lib/menu/SubMenu'
import Text from 'components/atoms/text'
import trans from 'helpers/i18n.helpers'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'redux/store'
import { setSelectedUserProfileMenuKey } from 'redux/user-profile/user-profile-menu'
import {
  selectSelectedUserProfileMenuItem,
  selectSelectedUserProfileSubMenu,
} from 'redux/user-profile/user-profile-menu/UserProfileMenu.selectors'
import styled from 'styled-components'
import userProfileOptions from '../userProfileOptions'

const StyledMenu = styled(Menu)`
  height: 100%;
  box-shadow: ${(props) => props.theme.effects.leftPanelShadow};
`

function createMenuItemKey(subMenuKey: string, itemKey: string): string {
  return `${subMenuKey}-${itemKey}`
}

function getParamsFromMenuItemKey(menuItemKey: string): { subMenu: string; item: string } {
  const keys = menuItemKey.split('-')
  return {
    subMenu: keys[0],
    item: keys[1],
  }
}

const UserProfileMenu: React.FC = () => {
  const selectedUserProfileMenuItem = useSelector(selectSelectedUserProfileMenuItem)
  const selectedUserProfileSubMenu = useSelector(selectSelectedUserProfileSubMenu)
  const dispatch = useAppDispatch()
  return (
    <StyledMenu
      onSelect={({ key }) => {
        if (key) {
          dispatch(setSelectedUserProfileMenuKey(getParamsFromMenuItemKey(key as string)))
        }
      }}
      defaultOpenKeys={[selectedUserProfileSubMenu, selectedUserProfileMenuItem]}
      defaultSelectedKeys={[createMenuItemKey(selectedUserProfileSubMenu, selectedUserProfileMenuItem)]}
      mode="inline"
      inlineCollapsed
    >
      {userProfileOptions.map(({ key, name, items }) => {
        if (items) {
          return (
            <SubMenu key={key} title={trans(name)}>
              {items.map(({ key: itemKey, name: itemName }) => (
                <Menu.Item key={createMenuItemKey(key, itemKey)}>
                  <Text textStyle="regularText-medium">{trans(itemName)}</Text>
                </Menu.Item>
              ))}
            </SubMenu>
          )
        }
        return (
          <Menu.Item key={key}>
            <Text textStyle="regularText-medium">{name}</Text>
          </Menu.Item>
        )
      })}
    </StyledMenu>
  )
}

export default UserProfileMenu
