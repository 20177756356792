import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FrontendFile, Group, FileInfo, SelectedGroup } from 'models/files'

export interface FilesTableState {
  selectedGroup?: SelectedGroup
  checkedGroups: Group[]
  checkedFrontendFiles: FrontendFile[]
  newlyAddedFilesId: number[]
  initialFileChecked?: FileInfo
}

const initialState = {
  checkedGroups: [],
  checkedFrontendFiles: [],
  newlyAddedFilesId: [],
} as FilesTableState

const filesTableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    setSelectedGroup: (state, action: PayloadAction<SelectedGroup | undefined>) => {
      state.selectedGroup = action.payload
    },
    setCheckedGroups: (state, action: PayloadAction<Group[]>) => {
      state.checkedGroups = action.payload
    },
    setCheckedFrontendFiles: (state, action: PayloadAction<FrontendFile[]>) => {
      state.checkedFrontendFiles = action.payload
    },
    removeCheckedFrontendFile: (state, action: PayloadAction<string>) => {
      state.checkedFrontendFiles = state.checkedFrontendFiles.filter((file) => file.key !== action.payload)
    },
    addNewlyAddedFileId: (state, action: PayloadAction<number>) => {
      state.newlyAddedFilesId.push(action.payload)
    },
    removeNewlyAddedFileId: (state, action: PayloadAction<number>) => {
      state.newlyAddedFilesId = state.newlyAddedFilesId.filter((id) => id !== action.payload)
    },
    setInitialFileChecked: (state, action: PayloadAction<FileInfo | undefined>) => {
      state.initialFileChecked = action.payload
    },
    checkFrontendFileByInitialFileChecked: (state, action: PayloadAction<FileInfo>) => {
      const { entryId, fileId } = action.payload
      const frontendFile = state.selectedGroup?.frontendFiles?.find(
        (file) => file.entryId === entryId || file.fileId === fileId
      )
      if (frontendFile) {
        state.checkedFrontendFiles.push(frontendFile)
      }
    },
  },
})

export const {
  setSelectedGroup,
  removeCheckedFrontendFile,
  addNewlyAddedFileId,
  removeNewlyAddedFileId,
  setInitialFileChecked,
  checkFrontendFileByInitialFileChecked,
  setCheckedGroups,
  setCheckedFrontendFiles,
} = filesTableSlice.actions

export const filesTableReducer = filesTableSlice.reducer
