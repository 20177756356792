import { Reducer, AnyAction } from '@reduxjs/toolkit'

export function identity<Type>(arg: Type): Type {
  return arg
}

export function wrapWithClear<S>(reducer: Reducer<S>, actionType: string) {
  return (state: S | undefined, action: AnyAction): S => {
    if (action.type === actionType) {
      return reducer(undefined, action)
    }
    return reducer(state, action)
  }
}

export function functionIf<T>(fn: T, condition: boolean): T | undefined {
  return condition ? fn : undefined
}

export function conditionIfNotUndefined<T>(condition: boolean, ifUndefined: T): boolean {
  return ifUndefined === undefined ? false : condition
}
