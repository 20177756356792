import { Trans } from '@lingui/macro'
import { Col, Row } from 'antd'
import SpaceElement from 'components/atoms/space'
import Text from 'components/atoms/text'
import ButtonElement from 'components/molecules/button-element'
import { addText, cancelText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { addStages } from 'redux/project/project-config/stages/ProjectStagesConfig.slice'
import styled from 'styled-components'
import AvailableStagesList from './AvailableStagesList'

interface AddingStagesPopoverContentProps {
  handlePopoverClose: () => void
}

const StyledDiv = styled.div`
  max-width: 600px;
`

const AddingStagesPopoverContent: React.FC<AddingStagesPopoverContentProps> = ({ handlePopoverClose }) => {
  const [selectedStages, setSelectedStages] = useState<number[]>([])
  const dispatch = useDispatch()
  return (
    <StyledDiv>
      <SpaceElement>
        <Row>
          <Col span={20}>
            <SpaceElement size="m">
              <Row>
                <Text textStyle="regularText-smallSemiBold" color="grayG07">
                  <Trans id="project_config.stages.popover.description">Select stages to add them to project.</Trans>
                </Text>
              </Row>
              <AvailableStagesList selectedStages={selectedStages} setSelectedStages={setSelectedStages} />
            </SpaceElement>
          </Col>
        </Row>
        <Row justify="end" gutter={16}>
          <Col>
            <ButtonElement onClick={handlePopoverClose} type="tertiary">
              {trans(cancelText)}
            </ButtonElement>
          </Col>
          <Col>
            <ButtonElement
              disabled={selectedStages.length === 0}
              onClick={() => {
                dispatch(addStages(selectedStages))
                handlePopoverClose()
                setSelectedStages([])
              }}
            >
              {trans(addText)}
            </ButtonElement>
          </Col>
        </Row>
      </SpaceElement>
    </StyledDiv>
  )
}

export default AddingStagesPopoverContent
