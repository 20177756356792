import FormSelect from 'components/molecules/form-item/FormSelect'
import FormInput from 'components/molecules/form-item/FormInput'
import { maxStringLength, nameInputRules, notWhitespace, required } from 'helpers/Validators.helpers'
import { t, Trans } from '@lingui/macro'
import { selectText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import { useSelector } from 'react-redux'
import { selectAdministrationCodeLengthDict } from 'redux/administration/administration-code-settings/AdministrationCodeSettings.selectors'
import CodeFormInput from 'components/molecules/code-length/CodeFormInput'
import { ORGANIZATION_ADDRESS_LENGTH, ORGANIZATION_ABBREVIATION_LENGTH } from 'data/model_restrictions'
import { GetFormItemErrors } from 'hooks/useFormValidity'
import { organizationActiveText, organizationInactiveText } from '../texts'
import OrganizationModalSectionTemplate from './OrganizationModalSectionTemplate'

interface OrganizationModalSectionProps {
  getFormItemErrors: GetFormItemErrors
}

const OrganizationModalSection: React.FC<OrganizationModalSectionProps> = ({ getFormItemErrors }) => {
  const currentLengthDict = useSelector(selectAdministrationCodeLengthDict)
  return (
    <OrganizationModalSectionTemplate
      headerText={t({
        id: 'administration.organizations.modal.organization_section_header',
        message: 'Organization data',
      })}
    >
      <FormInput
        label={<Trans id="administration.organizations.modal.name_field_label">Organization name</Trans>}
        name="name"
        rules={nameInputRules()}
        placeholder={t({ id: 'administration.organizations.modal.name_field_placeholder', message: 'Enter name' })}
        getErrors={getFormItemErrors}
      />
      <FormInput
        label={<Trans id="administration.organizations.modal.abbreviation_field_label">Abbreviation</Trans>}
        name="abbreviation"
        rules={nameInputRules(ORGANIZATION_ABBREVIATION_LENGTH)}
        placeholder={t({
          id: 'administration.organizations.modal.abbreviation_field_placeholder',
          message: 'Enter abbreviation',
        })}
        getErrors={getFormItemErrors}
      />
      <CodeFormInput
        codeLength={currentLengthDict.ORGANIZATION_CODE}
        label={<Trans id="administration.organizations.modal.code_field_label">Organization code</Trans>}
        getErrors={getFormItemErrors}
      />
      <FormInput
        label={<Trans id="administration.organizations.modal.address_field_label">Address</Trans>}
        name="address"
        placeholder={t({
          id: 'administration.organizations.modal.address_field_placeholder',
          message: 'Enter address (optional)',
        })}
        rules={[notWhitespace(), maxStringLength(ORGANIZATION_ADDRESS_LENGTH)]}
        getErrors={getFormItemErrors}
      />
      <FormSelect
        showSearch
        name="isActive"
        placeholder={trans(selectText)}
        options={[
          { value: 1, label: trans(organizationActiveText) },
          { value: 0, label: trans(organizationInactiveText) },
        ]}
        label={<Trans id="administration.organizations.modal.activity_status_field_label">Activity status</Trans>}
        rules={[required()]}
        getErrors={getFormItemErrors}
      />
    </OrganizationModalSectionTemplate>
  )
}

export default OrganizationModalSection
