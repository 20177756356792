import { UserAddOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import ButtonWithTooltip from 'components/molecules/buttons'
import { editAccountActivationField } from 'redux/administration/administration-users/AdministrationUsers.slice'
import { useAppDispatch } from 'redux/store'

interface ActionProps {
  id: number
}

const AccountReactivateButton: React.FC<ActionProps> = ({ id }) => {
  const dispatch = useAppDispatch()
  return (
    <ButtonWithTooltip
      onClick={async () => {
        if (id) {
          dispatch(editAccountActivationField({ id, isAccountActive: true }))
        }
      }}
      tooltipText={t({ id: 'administration.users.buttons.reactivate_account_text', message: 'Reactivate account' })}
      icon={<UserAddOutlined />}
      withLoading
    />
  )
}

export default AccountReactivateButton
