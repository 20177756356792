import ButtonElement from 'components/molecules/button-element'
import { addText, cancelText, nextText, previousStepText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'

interface ModalFooterProps {
  handlePrevStep: () => void
  handleNextStep: () => void
  onCancel: () => void
  currentStep: number
  isLastStep: boolean
  isContentValid?: boolean
  onComplete?: () => void
  isLoading: boolean
  onCompleteBtnText?: string
}

const ModalFooter: React.FC<ModalFooterProps> = ({
  handleNextStep,
  handlePrevStep,
  onCancel,
  currentStep,
  isLastStep,
  isContentValid = false,
  onComplete,
  isLoading,
  onCompleteBtnText,
}) => {
  const completeBtnText = onCompleteBtnText || trans(addText)
  return (
    <>
      <ButtonElement
        onClick={currentStep === 0 ? onCancel : handlePrevStep}
        disabled={isLoading && currentStep !== 0}
        type="tertiary"
      >
        {trans(currentStep === 0 ? cancelText : previousStepText)}
      </ButtonElement>
      <ButtonElement loading={isLoading} onClick={isLastStep ? onComplete : handleNextStep} disabled={!isContentValid}>
        {isLastStep ? completeBtnText : trans(nextText)}
      </ButtonElement>
    </>
  )
}

export default ModalFooter
