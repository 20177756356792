import { Locale } from 'antd/lib/locale-provider'
import antdPl from 'antd/lib/locale/pl_PL'
import antdEn from 'antd/lib/locale/en_US'
import { messages as messagesPl } from 'locales/pl/messages'
import { messages as messagesEn } from 'locales/en/messages'
import { pl as pluralsPl, en as pluralsEn, PluralCategory } from 'make-plural/plurals'

export const Languages = ['pl', 'en'] as const
export type Language = typeof Languages[number]

export const FALLBACK_LOCALE: Language = 'en'

interface LocaleLabel {
  label: string
  value: Language
}

interface LocaleData {
  antd: Locale
  lingui: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    messages: Record<string, any>
    plurals: (n: number | string, ord?: boolean) => PluralCategory
  }
}

export const localesLabels: Record<Language, LocaleLabel> = {
  pl: { label: 'Polski', value: 'pl' },
  en: { label: 'English', value: 'en' },
}

export const localesData: Record<Language, LocaleData> = {
  pl: { antd: antdPl, lingui: { messages: messagesPl, plurals: pluralsPl } },
  en: { antd: antdEn, lingui: { messages: messagesEn, plurals: pluralsEn } },
}
