import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  .ant-select-item {
    color: #73768c;
  }
  .ant-notification {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  }
  .ant-notification-notice-icon, .ant-notification-notice-close {
      top: 50%;
      transform: translateY(-50%);
  }
  .ant-notification-notice-message, .ant-notification-notice-description, .ant-alert-content .ant-alert-message {
    line-height: 24px;
    font-size: 16px;
    color: #0E1125;
  }
  .ant-alert-content .ant-alert-description {
    font-size: 12px;
    line-height: 16px;
    color: #0E1125;
  }
  .ant-notification-notice-description, .ant-alert-message {
    font-weight: 600;
  }
  .ant-notification-notice-closable .ant-notification-notice-message {
    margin-bottom: 0;
  }
  .ant-select-selection-item, .ant-select-selection-search {
    font-size: 12px;
    line-height: 16px;
    color: #2E2F38;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #FCFCFD;
  }
  .ant-modal-confirm-body {
    .ant-modal-confirm-title {
      font-size: 16px;
      line-height: 24px;
      color: #2E2F38;
      font-weight: 600;
    }
    .ant-modal-confirm-content {
      font-size: 12px;
      line-height: 16px;
      color: #454754;
    }
  }
  .ant-spin-nested-loading {
    height: 100%;
    > div > .ant-spin {
      max-height: 100%;
    }
  } 
  .ant-spin-container {
    height: 100%;
  }
  .ant-typography + h1.ant-typography, .ant-typography + h2.ant-typography, .ant-typography + h3.ant-typography, .ant-typography + h4.ant-typography, .ant-typography + h5.ant-typography {
    margin-top: 0;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  .ant-form-item-children-icon {
    top: 33% !important;
  }
  .ant-form-item-explain, .ant-form-item-extra {
    font-size: 12px;
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .ant-picker {
    width: 100%;
  }
  .ant-select-selection-item,
  .ant-picker-input > input,
  .ant-input:placeholder-shown,
  .ant-input-number-input:placeholder-shown,
  .ant-input-number-input,
  .ant-input {
    font-size: 12px;
    line-height: 24px;
    color: #2e2f38;
  }
  .ant-select-disabled .ant-select-selection-item, .ant-input:placeholder-shown::placeholder, .ant-input-number-inut:placeholder-shown::placeholder {
    color: #8F91A3;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled):not(.ant-select-item-option-active) {
    background-color: transparent;
  }
  .ant-select-item-option {
    align-items: center;
  }
  .ant-select-item-option-disabled {
    pointer-events: none;
    .ant-select-item-option-content {
      color: #E3E3E8;
    }
  }
  .ant-select-item-option-state {
    display: none;
  }
  .absolute-center {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
.ant-popover-title,.ant-popover-inner-content {
  padding: 16px 24px;
}
.ant-menu-vertical .ant-menu-item:not(:last-child), .ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-inline .ant-menu-item:not(:last-child),
.ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title {
  margin-top: 0;
  margin-bottom: 0;
}

.ant-table-filter-column-title {
  flex-grow: 0;
  padding: 0 0.25em 0 0 !important;
}
.ant-table-filter-trigger-container {
  position: relative;
}
.ant-table-filter-column, 
.ant-table-column-sorters {
  padding: 8px 8px;
}

.ant-notification {
  box-shadow: none;
}

.ant-form-item-tooltip {
  color: #DADADA !important;
}

.ant-form-item-no-colon {
  width: 100%;
}

.ant-tooltip-inner {
  font-size: 12px;
  line-height: 16px;
  border-radius: 2px;
}

.ant-radio-wrapper  span,  .ant-select-selection-placeholder, .ant-checkbox-wrapper span {
  font-size: 12px;
  line-height: 16px;
}
`
