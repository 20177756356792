import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import SceneMode from 'cesium/Source/Scene/SceneMode'
import backendAxios from 'axios/axios'
import { HttpError } from 'models/errors'
import { generateExtraReducers, generateInitialLoadingState, HttpLoading } from '../redux.shared'

export interface MousePositionCoordinates {
  latitude: number
  longitude: number
}

export type FeatureInfo = { [key: string]: string | number | undefined }

export interface GeoserverAuthenticationToken {
  token: string
}

export type GeoserverAuthenticationData = GeoserverAuthenticationToken | undefined

const loadingTypes = ['fetchGeoserverAuth'] as const
type LoadingTypes = typeof loadingTypes[number]

interface CesiumViewerState {
  loading: Record<LoadingTypes, HttpLoading>
  featuresInfo: FeatureInfo[]
  mousePositionCoordinates: MousePositionCoordinates
  geoserverAuthenticationData: GeoserverAuthenticationData
  sceneMode: SceneMode
  isScaleLocked: boolean
  shouldCameraReset: boolean
}

const initialMousePositionCoordinates: MousePositionCoordinates = {
  latitude: 0,
  longitude: 0,
}

export const fetchGeoserverAuth = createAsyncThunk('cesiumViewer/fetchGeoserverAuth', async (projectId: number) => {
  const response = await backendAxios.get(`/projects/${projectId}/geoserver-auth/`)
  return response.data
})

const initialState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  mousePositionCoordinates: initialMousePositionCoordinates,
  featuresInfo: [],
  geoserverAuthenticationData: undefined,
  sceneMode: SceneMode.SCENE3D,
  isScaleLocked: false,
  shouldCameraReset: false,
} as CesiumViewerState

const cesiumViewerSlice = createSlice({
  name: 'cesiumViewer',
  initialState,
  reducers: {
    setFeaturesInfo: (state, action: PayloadAction<FeatureInfo[]>) => {
      state.featuresInfo = action.payload
    },
    setMousePositionCoordinates: (state, action: PayloadAction<MousePositionCoordinates>) => {
      state.mousePositionCoordinates = action.payload
    },
    setShouldCameraReset: (state, action: PayloadAction<boolean>) => {
      state.shouldCameraReset = action.payload
    },
    clearCesiumViewerState: () => initialState,
    setSceneMode: (state, action: PayloadAction<SceneMode>) => {
      state.sceneMode = action.payload
    },
    setIsScaleLocked: (state, action: PayloadAction<boolean>) => {
      state.isScaleLocked = action.payload
    },
  },
  extraReducers: generateExtraReducers<HttpError, CesiumViewerState, LoadingTypes, GeoserverAuthenticationToken>({
    promise: fetchGeoserverAuth,
    loadingType: 'fetchGeoserverAuth',
    onFulfilled: (state, action) => {
      state.geoserverAuthenticationData = action.payload
    },
  }),
})

export const {
  setFeaturesInfo,
  setMousePositionCoordinates,
  clearCesiumViewerState,
  setSceneMode,
  setIsScaleLocked,
  setShouldCameraReset,
} = cesiumViewerSlice.actions

export const cesiumViewerReducer = cesiumViewerSlice.reducer
