import { uniqueArr } from 'helpers/Collection.helpers'
import { CdeStatus, cdeStatusesOrderDict } from 'models/files'
import { Permission } from 'models/project/projectConfig'
import { MemberPermission, ProjectMembersPermissionsConfigState } from './ProjectMembersPermissionsConfig.types'

export const findPermissionByDiscipline = (
  permission: MemberPermission,
  discipline: number
): { discipline: number; permissions: Permission } | undefined =>
  permission.permissions.find((p) => p.discipline === discipline)

export const emptyPermission: Permission = {
  canReadFrom: undefined,
  canWrite: false,
  canWriteArchive: false,
  canCheckIn: [],
  canAcceptIn: [],
}

export const isMemberChecked = (checkedUsers: number[], user: number): boolean =>
  !!checkedUsers.find((checkeedUser) => checkeedUser === user)

export const getReadStatus = (currentStatus: CdeStatus | undefined, changedStatus: CdeStatus): CdeStatus => {
  if (!currentStatus) return changedStatus
  return cdeStatusesOrderDict[changedStatus] <= cdeStatusesOrderDict[currentStatus] ? changedStatus : currentStatus
}

export const handlePermissionChanged = (state: ProjectMembersPermissionsConfigState): void => {
  const { checkedUsers, changedUsers } = state
  // eslint-disable-next-line no-param-reassign
  state.changedUsers = uniqueArr([...changedUsers, ...checkedUsers])
}
