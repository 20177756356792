import { useState } from 'react'
import styled from 'styled-components'
import FilesSideDrawerTree from 'components/organisms/files/sidedrawer/content/documentation/navigator/tree'

export interface StyledDivProps {
  $isVisible: boolean
}

const StyledDiv = styled.div<StyledDivProps>`
  background-color: ${(props) => props.theme.colors.grayG01};
  ${(props) => (props.$isVisible ? '' : 'margin-left: -202px;')}
  transition: ${(props) => props.theme.transitions.default};
  width: 218px;
`

const FilesDocumentationNavigator: React.FC = () => {
  const [isVisible, setIsVisible] = useState(true)
  const handleToggleVisibility = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setIsVisible((prev) => !prev)
  }
  return (
    <StyledDiv $isVisible={isVisible}>
      <FilesSideDrawerTree isVisible={isVisible} toggleVisibility={handleToggleVisibility} />
    </StyledDiv>
  )
}

export default FilesDocumentationNavigator
