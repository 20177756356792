import { EditOutlined } from '@ant-design/icons'
import { defineMessage, t } from '@lingui/macro'
import { WithDividerElement } from 'components/atoms/divider'
import ButtonWithTooltip, { DeleteButton } from 'components/molecules/buttons'
import { deleteText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import showConfirmationModal from 'helpers/Modals.helpers'
import { useSelector } from 'react-redux'
import { openProjectModal } from 'redux/administration/administration-projects'
import { selectAdministrationProjects } from 'redux/administration/administration-projects/AdministrationProjects.selectors'
import { deleteProject } from 'redux/administration/administration-projects/AdministrationProjects.slice'
import { useAppDispatch } from 'redux/store'

interface OrganizationActionsProps {
  projectId: number
}

const deleteEndedProjectTooltip = defineMessage({
  id: 'administration.projects.delete_ended_project_tooltip',
  message: 'Project is ended and cannot be deleted',
})
const deleteActiveProjectTooltip = defineMessage({
  id: 'administration.projects.delete_active_project_tooltip',
  message: 'Project has been activated and cannot be deleted',
})

const ProjectActions: React.FC<OrganizationActionsProps> = ({ projectId }) => {
  const dispatch = useAppDispatch()
  const project = useSelector(selectAdministrationProjects).find((proj) => proj.id === projectId)
  if (project === undefined) {
    return null
  }
  return (
    <div style={{ width: '75px' }}>
      <WithDividerElement
        onClick={(event) => {
          event.stopPropagation()
        }}
      >
        <ButtonWithTooltip
          onClick={(event) => {
            dispatch(openProjectModal(project))
            event.stopPropagation()
          }}
          tooltipText={t({ id: 'administration.projects.edit_project_tooltip', message: 'Edit project' })}
          icon={<EditOutlined />}
        />
        <DeleteButton
          onClick={(event) => {
            event.stopPropagation()
            showConfirmationModal({
              content: t({
                id: 'administration.projects.delete_confirmation_text',
                message: `Delete project "${project.name}"?`,
              }),
              okText: trans(deleteText),
              onOk: async () => {
                await dispatch(deleteProject({ projectId }))
              },
            })
          }}
          disabled={project.isActive}
          tooltip={t({ id: 'administration.projects.delete_project_tooltip', message: 'Delete project' })}
          disabledTooltip={trans(project.isEnded ? deleteEndedProjectTooltip : deleteActiveProjectTooltip)}
        />
      </WithDividerElement>
    </div>
  )
}

export default ProjectActions
