import 'antd/dist/antd.less'
import { BrowserRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Routes from 'routes/routes'
import useAccessTokenForAxiosBackend from 'hooks/axios'
import { selectIsInitialTokenLoading } from 'redux/login/Login.selectors'
import useBackupWarnings from 'hooks/backupWarnings'
import FullscreenSpin from 'components/atoms/fullscreen-spin'

function App(): JSX.Element {
  const isLoading = useSelector(selectIsInitialTokenLoading)

  useAccessTokenForAxiosBackend()
  useBackupWarnings()
  return isLoading ? (
    <FullscreenSpin />
  ) : (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  )
}

export default App
