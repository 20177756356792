import { useSelector } from 'react-redux'
import { selectSelectedGroup } from 'redux/files/files-table/FilesTable.selectors'
import FilesDetailsDir from './FilesDetailsGroup'

const FilesDetailsDirNav: React.FC = () => {
  const selectedGroup = useSelector(selectSelectedGroup)
  if (!selectedGroup) return null

  return <FilesDetailsDir group={selectedGroup} />
}

export default FilesDetailsDirNav
