import { useSelector } from 'react-redux'
import getFormValiditySelector from 'redux/forms-validity/FormsValidity.selectors'
import { updateGeneralSettings } from 'redux/project/project-config/general'
import { selectIsGeneralSettingsContentLoading } from 'redux/project/project-config/general/ProjectGeneralConfig.selectors'
import BaseProjectConfigView from '../base-config-view/BaseProjectConfigView'
import ProjectConfigGeneralContent from './ProjectGeneralConfigContent'

const ProjectGeneralConfig: React.FC = () => {
  const isContentValid = useSelector(getFormValiditySelector())
  const isContentLoading = useSelector(selectIsGeneralSettingsContentLoading)
  return (
    <BaseProjectConfigView
      content={<ProjectConfigGeneralContent />}
      isContentValid={isContentValid}
      saveStepThunk={updateGeneralSettings()}
      isContentLoading={isContentLoading}
    />
  )
}

export default ProjectGeneralConfig
