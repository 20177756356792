import ButtonElement from 'components/molecules/button-element'

interface AdministrationToolbarProps {
  text: React.ReactNode
  onClick: () => void
}

const AdministrationToolbarButton: React.FC<AdministrationToolbarProps> = ({ text, onClick }) => {
  return (
    <ButtonElement type="primary" onClick={onClick}>
      {text}
    </ButtonElement>
  )
}

export default AdministrationToolbarButton
