import { Entry } from 'models/project/projectConfig'
import { BackendLoading } from 'redux/redux.shared'

export const loadingTypes = ['getEntriesStructure', 'updateEntriesStructure'] as const
export type LoadingTypes = typeof loadingTypes[number]

export interface ProjectEntriesStructureConfigState {
  loading: Record<LoadingTypes, BackendLoading>
  entriesStructure: Entry[]
  isAddingEntry: boolean
}
