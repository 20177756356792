import { Space } from 'antd'
import { CdeIconStatus } from 'components/atoms/cde-icons/BaseCdeIcon'
import CdeIconAccept from 'components/atoms/cde-icons/CdeIconAccept'
import CdeIconRead from 'components/atoms/cde-icons/CdeIconRead'
import CdeIconReview from 'components/atoms/cde-icons/CdeIconReview'
import CdeIconWrite from 'components/atoms/cde-icons/CdeIconWrite'
import { CdeStatus, cdeStatusesOrderDict } from 'models/files'
import { Permission } from 'models/project/projectConfig'

interface CdePermissionsProps {
  permissions: Permission[]
  cdeStatus: CdeStatus
  onReadIconClick?: CdeIconClickHandler
  onWriteIconClick?: CdeIconClickHandler
  onReviewIconClick?: CdeIconClickHandler
  onAcceptIconClick?: CdeIconClickHandler
  singleUserMode?: boolean
}

type CdeIconClickHandler = (cdeIconStatus?: CdeIconStatus) => void

const getStatus = (prev: CdeIconStatus | undefined, condition?: boolean) => {
  if (prev === undefined) return condition ? 'ON' : 'OFF'
  if (prev === 'ON') return condition ? 'ON' : 'ON/OFF'
  if (prev === 'ON/OFF') return 'ON/OFF'
  return condition ? 'ON/OFF' : 'OFF'
}

const getReadIconStatusFromPermissions = (permissions: Permission[], cdeStatus: CdeStatus): CdeIconStatus => {
  return permissions.reduce((prev: CdeIconStatus | undefined, curr) => {
    const canRead = curr.canReadFrom && cdeStatusesOrderDict[curr.canReadFrom] <= cdeStatusesOrderDict[cdeStatus]
    return getStatus(prev, canRead)
  }, undefined) as CdeIconStatus
}

const writeKeysDict = {
  IN_PROGRESS: 'canWrite',
  ARCHIVED: 'canWriteArchive',
} as const

const getWriteIconStatusFromPermissions = (permissions: Permission[], cdeStatus: CdeStatus): CdeIconStatus => {
  if (cdeStatus === 'SHARED' || cdeStatus === 'PUBLISHED') return 'DISABLE'
  return permissions.reduce((prev: CdeIconStatus | undefined, curr) => {
    const canWrite = curr[writeKeysDict[cdeStatus]]
    return getStatus(prev, canWrite)
  }, undefined) as CdeIconStatus
}

const getReviewOrAcceptIconStatus = (
  permissions: Permission[],
  cdeStatus: CdeStatus,
  key: 'canCheckIn' | 'canAcceptIn'
): CdeIconStatus =>
  permissions.reduce((prev: CdeIconStatus | undefined, curr) => {
    const cdeStatuses = curr[key]
    return getStatus(prev, cdeStatuses.includes(cdeStatus))
  }, undefined) as CdeIconStatus

const getReviewIconStatusFromPermissions = (permissions: Permission[], cdeStatus: CdeStatus): CdeIconStatus => {
  if (cdeStatus === 'ARCHIVED') return 'DISABLE'
  return getReviewOrAcceptIconStatus(permissions, cdeStatus, 'canCheckIn')
}

const getAcceptIconStatusFromPermissions = (permissions: Permission[], cdeStatus: CdeStatus): CdeIconStatus => {
  if (cdeStatus === 'ARCHIVED') return 'DISABLE'
  return getReviewOrAcceptIconStatus(permissions, cdeStatus, 'canAcceptIn')
}

const passFnIfNotDisableStatus = (iconStatus: CdeIconStatus, fn?: () => void) =>
  iconStatus !== 'DISABLE' ? fn : undefined

const getDisableStatusIfPermissionsEmpty = (
  permissionsEmpty: boolean,
  singleUserMode: boolean
): CdeIconStatus | false => permissionsEmpty && !singleUserMode && 'DISABLE'

const CdePermissions: React.FC<CdePermissionsProps> = ({
  permissions,
  cdeStatus,
  onReadIconClick,
  onWriteIconClick,
  onReviewIconClick,
  onAcceptIconClick,
  singleUserMode = false,
}) => {
  const permissionsEmpty = permissions.length === 0
  const isDisableStatus = getDisableStatusIfPermissionsEmpty(permissionsEmpty, singleUserMode)
  const readIconStatus = isDisableStatus || getReadIconStatusFromPermissions(permissions, cdeStatus) || 'OFF'
  const writeIconStatus = isDisableStatus || getWriteIconStatusFromPermissions(permissions, cdeStatus) || 'OFF'
  const reviewIconStatus = isDisableStatus || getReviewIconStatusFromPermissions(permissions, cdeStatus) || 'OFF'
  const acceptIconStatus = isDisableStatus || getAcceptIconStatusFromPermissions(permissions, cdeStatus) || 'OFF'
  return (
    <Space size="small">
      <CdeIconRead status={readIconStatus} onClick={() => onReadIconClick && onReadIconClick(readIconStatus)} />
      <CdeIconWrite
        status={writeIconStatus}
        onClick={passFnIfNotDisableStatus(writeIconStatus, () => onWriteIconClick && onWriteIconClick(writeIconStatus))}
      />
      <CdeIconReview
        status={reviewIconStatus}
        onClick={passFnIfNotDisableStatus(
          reviewIconStatus,
          () => onReviewIconClick && onReviewIconClick(reviewIconStatus)
        )}
      />
      <CdeIconAccept
        status={acceptIconStatus}
        onClick={passFnIfNotDisableStatus(
          acceptIconStatus,
          () => onAcceptIconClick && onAcceptIconClick(acceptIconStatus)
        )}
      />
    </Space>
  )
}

export default CdePermissions
