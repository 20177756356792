import { findFieldValueByName } from 'helpers/Collection.helpers'
import { SuitabilityCode } from 'models/administration'
import { ConsequencesType, FlowConsequencesConfig, FlowSettingsFields } from 'models/flow'
import getFormValiditySelector from 'redux/forms-validity/FormsValidity.selectors'
import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import { selectGettingFlowConditionsLoading } from '../conditions/FlowConditionsConfig.selectors'
import { selectGettingFlowDecisionsLoading, selectIsReviewRequired } from '../decisions/FlowDecisionsConfig.selectors'
import { FlowConsequencesConfigState } from './FlowConsequencesConfig.types'

const selectFlowConsequencesConfigState = (state: RootState): FlowConsequencesConfigState =>
  state.flowConfig.consequences

export const selectConsequencesFlowConfig = (state: RootState): FlowConsequencesConfig =>
  selectFlowConsequencesConfigState(state).flowConsequencesConfig

export const selectConsequencesListFlowConfig = (state: RootState): FlowSettingsFields[] =>
  selectFlowConsequencesConfigState(state).flowConsequencesConfig.onFinished

export const selectConsequenceFilteredByType: (
  type: ConsequencesType
) => (state: RootState) => FlowSettingsFields | undefined = (type) => (state) => {
  return selectFlowConsequencesConfigState(state).flowConsequencesConfig.onFinished.find(
    (c) => findFieldValueByName(c, 'consequenceType') === type
  )
}

export const selectFlowConsequencesValidity = (state: RootState): boolean => {
  const isReviewRequired = selectIsReviewRequired(state)
  if (!isReviewRequired) {
    const neutralConsequenceValidity = getFormValiditySelector('neutralConsequenceForm')(state)
    return !!neutralConsequenceValidity
  }
  const negativeConsequenceValidity = getFormValiditySelector('negativeConsequenceForm')(state)
  const positiveConsequenceValidity = getFormValiditySelector('positiveConsequenceForm')(state)
  return !!negativeConsequenceValidity && !!positiveConsequenceValidity
}

export const selectSuitabilityCodesConfig = (state: RootState): SuitabilityCode[] =>
  selectFlowConsequencesConfigState(state).codes

export const selectUpdatingFlowConsequencesLoading = (state: RootState): BackendLoading =>
  selectFlowConsequencesConfigState(state).loading.updateFlowConsequences

export const selectGettingFlowConsequencesLoading = (state: RootState): BackendLoading =>
  selectFlowConsequencesConfigState(state).loading.getFlowConsequences

export const selectGettingSuitabilityCodesLoading = (state: RootState): BackendLoading =>
  selectFlowConsequencesConfigState(state).loading.fetchSuitabilityOverview

export const selectIsConsequencesConfigContentLoading = (state: RootState): boolean =>
  selectUpdatingFlowConsequencesLoading(state).isLoading ||
  selectGettingFlowConsequencesLoading(state).isLoading ||
  selectGettingSuitabilityCodesLoading(state).isLoading ||
  selectGettingFlowDecisionsLoading(state).isLoading ||
  selectGettingFlowConditionsLoading(state).isLoading
