import Table from 'components/molecules/table'
import { withIdKeys } from 'helpers/Collection.helpers'
import { Flow } from 'models/flow'
import styled from 'styled-components'
import useProjectFlowsTableColumns from './useProjectFlowsTableColumns'

interface ProjectFlowsTableProps {
  flows: Required<Flow>[]
}

const StyledTable = styled(Table)`
  width: 800px;
`

const ProjectFlowsTable: React.FC<ProjectFlowsTableProps> = ({ flows }) => {
  const columns = useProjectFlowsTableColumns()
  return <StyledTable dataSource={withIdKeys(flows)} columns={columns} pagination={false} scroll={{ y: 400 }} />
}

export default ProjectFlowsTable
