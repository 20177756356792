import { MessageDescriptor } from '@lingui/core'
import { defineMessage } from '@lingui/macro'
import { ProjectStatusType } from 'redux/administration/administration-projects/AdministrationProjects.types'

const projectStatusLabels: Record<ProjectStatusType, MessageDescriptor> = {
  ALL: defineMessage({
    id: 'administration.projects.common.project_activity_status_all_text',
    message: 'All',
  }),
  ACTIVE: defineMessage({
    id: 'administration.projects.common.project_activity_status_active_text',
    message: 'Active',
  }),
  ARCHIVED: defineMessage({
    id: 'administration.projects.common.project_activity_status_finished_text',
    message: 'Finished',
  }),
  IN_CONFIG: defineMessage({
    id: 'administration.projects.common.project_activity_status_in_configuration_text',
    message: 'In configuration',
  }),
}

export default projectStatusLabels
