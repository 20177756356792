import {
  ProjectActivationData,
  ProjectConfigCompletion,
  ProjectConfigCompletionTypes,
} from 'models/project/projectConfig'
import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import { ProjectActivationConfigState } from './ProjectActivationConfig.types'

const selectProjectActivationConfigState: (state: RootState) => ProjectActivationConfigState = (state) =>
  state.project.config.activation

export const selectConfigCompletionType: (state: RootState) => ProjectConfigCompletion = (state) =>
  selectProjectActivationConfigState(state).configCompletionType

export const selectActivateProjectLoading: (state: RootState) => BackendLoading = (state) =>
  selectProjectActivationConfigState(state).loading.activateProject

export const selectScheduleProjectActivationLoading: (state: RootState) => BackendLoading = (state) =>
  selectProjectActivationConfigState(state).loading.scheduleProjectActivation

export const selectFetchProjectScheduledActivationLoading: (state: RootState) => BackendLoading = (state) =>
  selectProjectActivationConfigState(state).loading.fetchProjectActivation

export const selectIsProjectActivationConfigContentLoading: (state: RootState) => boolean = (state) =>
  selectActivateProjectLoading(state).isLoading ||
  selectScheduleProjectActivationLoading(state).isLoading ||
  selectFetchProjectScheduledActivationLoading(state).isLoading

export const selectProjectScheduledActivation: (state: RootState) => ProjectActivationData = (state) =>
  selectProjectActivationConfigState(state).activationData

export const selectIsProjectActivationScheduled: (state: RootState) => boolean = (state) =>
  selectProjectActivationConfigState(state).activationData.isScheduled

export const selectProjectScheduledActivationTimestamp: (state: RootState) => string | undefined = (state) =>
  selectProjectScheduledActivation(state).details?.executionTimestamp

export const selectNewProjectActivationTimestamp: (state: RootState) => string | undefined = (state) =>
  selectProjectActivationConfigState(state).newActivationDatetime

export const selectIsActivationTimeSelectedIfNecessary: (state: RootState) => boolean = (state) => {
  const activationTimestamp = selectNewProjectActivationTimestamp(state)
  const activationType = selectConfigCompletionType(state)
  return activationType !== ProjectConfigCompletionTypes.RUN_AT_CUSTOM_TIME || activationTimestamp !== undefined
}
