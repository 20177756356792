import { Dropdown } from 'antd'
import WithValidityInfo from 'components/atoms/with-validity-info'
import Text from 'components/atoms/text'
import { AttributeValue } from 'models/administration'
import styled from 'styled-components'
import { t } from '@lingui/macro'
import AttributeValueListDropdownMenu from './dropdown-menu/AttributeValuesListDropdownMenu'

const StyledDiv = styled.div<{ reducedWidth: boolean }>`
  display: flex;
  gap: ${(props) => props.theme.sizes.xxs};
  align-items: center;
  height: 45px;
  .ant-typography {
    max-width: ${(props) => (props.reducedWidth ? `calc(90% - ${props.theme.sizes.defaultS})` : '90%')};
  }
`

interface AttributeParams {
  id: number
  values: AttributeValue[]
  valueCodeLength: number
}

const AttributeValuesDisplay: React.FC<AttributeParams> = ({ id, values, valueCodeLength }) => {
  const hasWrongLength = values.filter((v) => v.code.length !== valueCodeLength).length > 0

  return (
    <Dropdown
      trigger={['click']}
      disabled={values.length === 0}
      overlay={<AttributeValueListDropdownMenu values={values} id={id} valueCodeLength={valueCodeLength} />}
    >
      <StyledDiv onClick={(e) => e.stopPropagation()} reducedWidth={hasWrongLength}>
        {values.length > 0 ? (
          <Text textStyle="regularText-small" ellipsis>{`${values[0].code} - ${values[0].value}`}</Text>
        ) : (
          <Text textStyle="regularText-small" color="grayG05">
            {t({ id: 'administration.attributes.table.no_values_text', message: 'No values' })}
          </Text>
        )}
        <Text textStyle="regularText-smallSemiBold">{values.length > 1 ? `+${values.length - 1}` : ''}</Text>
        <WithValidityInfo
          isValid={!hasWrongLength}
          invalidMessage={t({
            id: 'administration.attributes.table.invalid_value_exists_error',
            message: 'Some values are invalid',
          })}
        />
      </StyledDiv>
    </Dropdown>
  )
}

export default AttributeValuesDisplay
