import { Space } from 'antd'
import styled from 'styled-components'

const StyledDiv = styled.div`
  width: 90%;
  margin: ${(props) => props.theme.sizes.m} auto;
`

const StyledSpace = styled(Space)`
  && {
    width: 100%;
  }
`

const NotificationsTemplate: React.FC = ({ children }) => {
  return (
    <StyledDiv>
      <StyledSpace direction="vertical" size="middle">
        {children}
      </StyledSpace>
    </StyledDiv>
  )
}

export default NotificationsTemplate
