import { Trans } from '@lingui/macro'
import { Space } from 'antd'
import Text from 'components/atoms/text'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearMembersPermissionsConfigState,
  fetchAvailableDisciplines,
  fetchMembersPermissions,
} from 'redux/project/project-config/members-permissions/ProjectMembersPermissionsConfig.slice'
import { useEffect } from 'react'
import useProjectId from 'hooks/useProjectId'
import { selectAreProjectMembersPermissionsFetched } from 'redux/project/project-config/members-permissions/ProjectMembersPermissionsConfig.selectors'
import { fetchDisciplines } from 'redux/project/project-config/disciplines'
import { selectDisciplines } from 'redux/project/project-config/disciplines/ProjectDisciplinesConfig.selectors'
import styled from 'styled-components'
import {
  fetchOrganizations,
  fetchProjectMembers,
} from 'redux/project/project-config/members/ProjectMembersConfig.slice'
import ProjectMembersPermissionsConfigUsers from './users/ProjectMembersPermissionsConfigUsers'
import MembersPermissionsTable from './table/MembersPermissionsTable'
import ProjectMembersPermissionsConfigToolBar from './ProjectMembersPermissionsConfigToolBar'
import ProjectMembersPermissionsConfigAlert from './ProjectMembersPermissionsConfigAlert'

const StyledSpace = styled(Space)`
  width: 100%;
  .ant-space-item {
    max-height: 550px;
    overflow: auto;
  }
`

const ProjectMembersPermissionsConfigContent: React.FC = () => {
  const dispatch = useDispatch()
  const projectId = useProjectId()
  const areProjectMembersPermissionsFetched = useSelector(selectAreProjectMembersPermissionsFetched)
  const disciplines = useSelector(selectDisciplines)

  useEffect(() => {
    if (projectId && !areProjectMembersPermissionsFetched && disciplines.length !== 0) {
      dispatch(fetchMembersPermissions({ projectId, disciplines }))
    }
  }, [dispatch, projectId, disciplines, areProjectMembersPermissionsFetched])

  useEffect(() => {
    dispatch(fetchOrganizations())
  }, [dispatch])

  useEffect(() => {
    if (projectId) {
      dispatch(fetchProjectMembers(projectId))
      dispatch(fetchAvailableDisciplines(projectId))
      dispatch(fetchDisciplines(projectId))
    }
  }, [dispatch, projectId])

  useEffect(() => {
    return () => {
      dispatch(clearMembersPermissionsConfigState())
    }
  }, [dispatch])

  return (
    <StyledSpace direction="vertical" size="large" style={{ width: '100%' }}>
      <Text textStyle="regularText-medium">
        <Trans id="project_config.members_permissions.step_header">Users permissions</Trans>
      </Text>
      <ProjectMembersPermissionsConfigToolBar />
      <Space style={{ alignItems: 'flex-start' }}>
        <ProjectMembersPermissionsConfigUsers />
        <MembersPermissionsTable />
      </Space>
      <ProjectMembersPermissionsConfigAlert />
    </StyledSpace>
  )
}

export default ProjectMembersPermissionsConfigContent
