import { RawFlowMetadata } from './FlowAttributesConfig.types'

const isFlowAttributeValueSelected: (
  flowAttributes: RawFlowMetadata[],
  metadataId: number,
  valueId: number
) => boolean = (flowAttributes, metadataId, valueId) => {
  return !!flowAttributes.find(({ metadata }) => metadata === metadataId)?.values.includes(valueId)
}

export default isFlowAttributeValueSelected
