import { WithDividerElement } from 'components/atoms/divider'
import { CellWrapper } from 'components/molecules/table/Table'
import getAccountStatus from 'helpers/Users.helpers'
import { ActivationDetails } from 'models/administration'
import { ReactNode } from 'react'
import { AccountActivityStatuses } from 'redux/administration/administration-users/AdministrationUsers.types'
import AccountDeactivateButton from './action-buttons/AccountDeactivateButton'
import AccountReactivateButton from './action-buttons/AccountReactivateButton'
import EditUserButton from './action-buttons/EditUserButton'
import LinkCancelButton from './action-buttons/LinkCancelButton'
import LinkResendButton from './action-buttons/LinkResendButton'

interface ActionProps {
  id: number
  activationDetails: ActivationDetails
}

const ActionColumn: React.FC<ActionProps> = ({ activationDetails, id }) => {
  const buttons = {
    DEACTIVATE: <AccountDeactivateButton id={id} key="deactivate" />,
    REACTIVARE: <AccountReactivateButton id={id} key="reactivate" />,
    CANCEL: <LinkCancelButton id={id} key="cancel" />,
    RESEND: <LinkResendButton id={id} key="resend" />,
  }

  const activationStatusDict: Record<string, ReactNode> = {
    [AccountActivityStatuses.ACTIVE]: [buttons.DEACTIVATE],
    [AccountActivityStatuses.INACTIVE]: [buttons.REACTIVARE],
    [AccountActivityStatuses.LINK_ACTIVE]: [buttons.CANCEL, buttons.RESEND],
    [AccountActivityStatuses.LINK_EXPIRED]: [buttons.RESEND],
    [AccountActivityStatuses.LINK_DEACTIVATED]: [buttons.RESEND],
  }
  return (
    <CellWrapper>
      <WithDividerElement
        onClick={(event) => {
          event.stopPropagation()
        }}
      >
        <EditUserButton id={id} />
        {activationStatusDict[getAccountStatus(activationDetails)]}
      </WithDividerElement>
    </CellWrapper>
  )
}

export default ActionColumn
