import { t } from '@lingui/macro'
import { DeleteButton } from 'components/molecules/buttons'
import { CellWrapper } from 'components/molecules/table/Table'
import { useDispatch } from 'react-redux'
import { setIsAdding, setAttributeSelected } from 'redux/flow-conifg/attributes/FlowAttributesConfig.slice'

interface AttributeActionsProps {
  id?: number
}

const AttributeActions: React.FC<AttributeActionsProps> = ({ id }) => {
  const dispatch = useDispatch()
  return (
    <CellWrapper>
      <DeleteButton
        onClick={() => {
          if (id !== undefined) {
            dispatch(setAttributeSelected({ attributeId: id, selected: false }))
          } else {
            dispatch(setIsAdding(false))
          }
        }}
        tooltip={t({
          id: 'flow_config.attributes.remove_attribute_tooltip',
          message: 'Remove attribute',
        })}
      />
    </CellWrapper>
  )
}

export default AttributeActions
