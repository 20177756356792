import { Space, Spin } from 'antd'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'redux/store'
import styled from 'styled-components'
import {
  selectAdministrationUsers,
  selectFetchingUsersLoading,
  selectFileModalVisibility,
  selectUserModalVisibility,
} from 'redux/administration/administration-users/AdministrationUsers.selectors'
import { fetchUsers } from 'redux/administration/administration-users'
import ExpandableTable from 'components/molecules/table/ExpandableTable'
import useAdministrationUsersColumns from './columns'
import { FileModal, UserModal } from './modals'
import AdministrationUsersAlerts from './AdministrationUsersAlerts'

// eslint-disable-next-line react/jsx-props-no-spreading
const StyledTable = styled((props) => <ExpandableTable {...props} />)`
  width: 1150px;
  &&&& {
    td {
      &:nth-child(5) {
        text-align: center;
      }
      &:first-child {
        border-right: 0px;
      }
      &:last-child {
        padding-right: 10px;
      }
    }
  }
`

const AdministrationUsers: React.FC = () => {
  const dispatch = useAppDispatch()
  const { isLoading } = useSelector(selectFetchingUsersLoading)
  const administrationUsers = useSelector(selectAdministrationUsers)
  const userModalVisibility = useSelector(selectUserModalVisibility)
  const fileModalVisibility = useSelector(selectFileModalVisibility)
  const columns = useAdministrationUsersColumns()
  useEffect(() => {
    dispatch(fetchUsers())
  }, [dispatch])
  return (
    <Spin spinning={isLoading}>
      {!isLoading && (
        <Space direction="vertical" size="middle">
          <StyledTable
            columns={columns}
            dataSource={administrationUsers}
            bordered
            pagination={false}
            rowKey="id"
            scroll={{ y: 'calc(100vh - 320px)' }}
          />
          {userModalVisibility && <UserModal visible={userModalVisibility} />}
          {fileModalVisibility && <FileModal visible={fileModalVisibility} />}
          <AdministrationUsersAlerts />
        </Space>
      )}
    </Spin>
  )
}

export default AdministrationUsers
