import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import { RcFile } from 'antd/lib/upload'
import {
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
  getStateAsAny,
} from 'redux/redux.shared'
import { openSuccessNotification } from 'helpers/Notifications.helpers'
import { t } from '@lingui/macro'
import { ProjectFlowsExecDetails, ProjectFlowsReviewDetails } from 'models/flow'
import { Creator } from 'models/files'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import { FlowModalData, FlowsState, LoadingTypes, loadingTypes, ReviewOption, ReviewOptions } from './Flows.types'
import { fetchProjectEntries } from '../files-documentation'

export const fetchProjectExecutableFlows = createAsyncBackendThunk(
  'fetchProjectExecutableFlows',
  async (projectId: number) => {
    const response = await backendAxios.get(`/projects/${projectId}/flows/executable/`)
    return response.data
  }
)

export const getReviewers = createAsyncBackendThunk(
  'getReviewers',
  async ({ fileId, configId }: { fileId: number; configId: number }) => {
    const response = await backendAxios.get(`/files/${fileId}/flows/execute/${configId}/reviewers/`)
    return response.data
  }
)

export const execFlow = createAsyncBackendThunk(
  'execFlow',
  async (
    { fileId, configId, reviewer }: { fileId: number; configId: number; reviewer?: number },
    { getState, dispatch }
  ) => {
    const response = await backendAxios.post(`/files/${fileId}/flows/execute/`, { config: configId, reviewer })
    const state = getStateAsAny(getState)
    const projectId = selectProjectId(state) as number
    await dispatch(fetchProjectEntries({ allVersions: state.files.controls.showAllVersions, projectId }))
    openSuccessNotification({
      message: t({
        id: 'project.flows.exec.succes_notification',
        message: 'The flow has been started',
      }),
    })
    return response.data
  }
)

export const getFileCurrentFlowReviewDetails = createAsyncBackendThunk(
  'getFileCurrentFlowReviewDetails',
  async ({ fileId }: { fileId: number }) => {
    const response = await backendAxios.get(`/files/${fileId}/flows/execute/review/details/`)
    return response.data
  }
)

export const reviewFlow = createAsyncBackendThunk(
  'review',
  async (
    { fileId, reviewFile, decision }: { reviewFile?: RcFile; fileId: number; decision: ReviewOption },
    { getState, dispatch }
  ) => {
    const formData = new FormData()
    if (reviewFile) {
      formData.append('reviewFile', reviewFile)
    }
    formData.append('status', ReviewOptions[decision])
    const response = await backendAxios.post(`/files/${fileId}/flows/execute/review/`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    const state = getStateAsAny(getState)
    const projectId = selectProjectId(state) as number
    await dispatch(fetchProjectEntries({ allVersions: state.files.controls.showAllVersions, projectId }))
    openSuccessNotification({
      message: t({
        id: 'project.flows.review.succes_notification',
        message: 'The flow has been finished',
      }),
    })
    return response.data
  }
)

const initialState: FlowsState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  projectFlows: [],
  modalData: { isVisible: false },
  modalReviewData: { isVisible: false },
  reviewers: [],
}

const flowsSlice = createSlice({
  name: 'documentation',
  initialState,
  reducers: {
    setFlowModalVisibility: (state, visible: PayloadAction<FlowModalData>) => {
      state.modalData.isVisible = visible.payload.isVisible
      state.modalData.flow = visible.payload.flow
      state.modalData.frontendFile = visible.payload.frontendFile
      state.selectedReviewer = undefined
    },
    setFlowReviewModalVisibility: (state, visible: PayloadAction<FlowModalData>) => {
      state.modalReviewData.isVisible = visible.payload.isVisible
      state.modalReviewData.frontendFile = visible.payload.frontendFile
      state.reviewDecision = undefined
    },
    setReviewer: (state, action: PayloadAction<number>) => {
      state.selectedReviewer = action.payload
    },
    setAfterReviewStatus: (state, action: PayloadAction<ReviewOption>) => {
      state.reviewDecision = action.payload
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<FlowsState, LoadingTypes, ProjectFlowsExecDetails[]>({
      promise: fetchProjectExecutableFlows,
      loadingType: 'fetchProjectExecutableFlows',
      onFulfilled: (state, action) => {
        state.projectFlows = action.payload
      },
    }),
    generateExtraBackendReducers<FlowsState, LoadingTypes>({
      promise: execFlow,
      loadingType: 'execFlow',
      onFulfilled: (state) => {
        state.modalData = initialState.modalData
        state.selectedReviewer = undefined
      },
    }),
    generateExtraBackendReducers<FlowsState, LoadingTypes, Creator[]>({
      promise: getReviewers,
      loadingType: 'getReviewers',
      onFulfilled: (state, action) => {
        state.reviewers = action.payload
      },
    }),
    generateExtraBackendReducers<FlowsState, LoadingTypes, Creator[]>({
      promise: reviewFlow,
      loadingType: 'review',
      onFulfilled: (state, action) => {
        state.reviewers = action.payload
        state.modalReviewData = initialState.modalReviewData
      },
    }),
    generateExtraBackendReducers<FlowsState, LoadingTypes, ProjectFlowsReviewDetails>({
      promise: getFileCurrentFlowReviewDetails,
      loadingType: 'getFileCurrentFlowReviewDetails',
      onFulfilled: (state, action) => {
        state.reviewDetails = action.payload
      },
    }),
  ]),
})

export const {
  setFlowReviewModalVisibility,
  setFlowModalVisibility,
  setReviewer,
  setAfterReviewStatus,
} = flowsSlice.actions

export const flowsReducer = flowsSlice.reducer
