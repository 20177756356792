import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { Space, Spin } from 'antd'
import DividerElement from 'components/atoms/divider'
import Text from 'components/atoms/text'
import ButtonElement from 'components/molecules/button-element'
import Table from 'components/molecules/table'
import styled from 'styled-components'
import {
  selectBackupCreationModalVisibility,
  selectBackups,
  selectBackupSettingsModalVisibility,
  selectBackupsListLoading,
  selectFileModalVisibility,
} from 'redux/administration/administration-backups/AdministrationBackups.selectors'
import {
  fetchSystemBackupsList,
  setFileModalVisibility,
  fetchBackupSettings,
} from 'redux/administration/administration-backups/AdministrationBackups.slice'
import { useAppDispatch } from 'redux/store'
import { Trans } from '@lingui/macro'
import useAdministrationBackupsColumns from './columns/useAdministrationBackupsColumns'
import BackupSettings from './settings'
import AdministrationBackupsAlert from './AdministrationBackupsAlert'
import { BackupCreationModal, BackupSettingsModal, FileModal } from './modals'

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
`

const StyledSpace = styled(Space)`
  height: 480px;
  width: 1000px;
`

const AdministrationBackups: React.FC = () => {
  const dispatch = useAppDispatch()
  const { isLoading } = useSelector(selectBackupsListLoading)
  const fileModalVisibility = useSelector(selectFileModalVisibility)
  const backupCreationModalVisibility = useSelector(selectBackupCreationModalVisibility)
  const settingsModalVisibility = useSelector(selectBackupSettingsModalVisibility)
  useEffect(() => {
    dispatch(fetchSystemBackupsList())
    dispatch(fetchBackupSettings())
  }, [dispatch])
  const backups = useSelector(selectBackups)
  const columns = useAdministrationBackupsColumns(backups.length)
  return (
    <Spin spinning={isLoading}>
      {!isLoading && (
        <StyledDiv>
          <Space direction="vertical" size="small">
            <StyledSpace direction="vertical" size="small">
              <Text textStyle="regularText-smallSemiBold">
                <Trans id="administration.backups.table_description_text">Available backups</Trans>
              </Text>
              <Table
                columns={columns}
                dataSource={backups}
                bordered
                pagination={false}
                rowKey="id"
                scroll={{ y: 356 }}
              />
            </StyledSpace>
            <ButtonElement type="secondary" onClick={() => dispatch(setFileModalVisibility(true))}>
              <Trans id="administration.backups.import_backup_button_text">Import backup</Trans>
            </ButtonElement>
            <AdministrationBackupsAlert />
            <DividerElement />
            <BackupSettings />
          </Space>
          {fileModalVisibility && <FileModal visible={fileModalVisibility} />}
          {backupCreationModalVisibility && <BackupCreationModal />}
          {settingsModalVisibility && <BackupSettingsModal />}
        </StyledDiv>
      )}
    </Spin>
  )
}

export default AdministrationBackups
