import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { selectFilesDetailsActiveTab } from 'redux/files/files-details/FilesDetails.selectors'
import { setActiveTab } from 'redux/files/files-details'
import Text from 'components/atoms/text'
import { ActiveTab } from 'models/files'

interface FilesDetailsOptionProps {
  tabType: ActiveTab
  children: React.ReactNode
}

interface StyledDivProps {
  readonly $isActive: boolean
}

const StyledDiv = styled.div<StyledDivProps>`
  ${(props) => props.$isActive && `border-bottom: 2px solid ${props.theme.colors.primaryP04};`}
  cursor: pointer;
  padding: 0 ${(props) => props.theme.sizes.xs};
  height: ${(props) => props.theme.sizes.l};
  ${(props) => props.theme.mixins.flexCenter}
`

const FilesDetailsTab: React.FC<FilesDetailsOptionProps> = ({ tabType, children }) => {
  const dispatch = useDispatch()
  const activeTab = useSelector(selectFilesDetailsActiveTab)
  const isActive = tabType === activeTab
  return (
    <StyledDiv $isActive={isActive} onClick={() => dispatch(setActiveTab(tabType))}>
      <Text
        color={isActive ? 'primaryP04' : 'baseBlackish'}
        textStyle={isActive ? 'regularText-smallSemiBold' : 'regularText-small'}
      >
        {children}
      </Text>
    </StyledDiv>
  )
}

export default FilesDetailsTab
