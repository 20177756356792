import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ActiveTab, MetadataDetails } from 'models/files'
import backendAxios from 'axios/axios'
import {
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  BackendLoading,
  generateInitialLoadingState,
  getStateAsAny,
} from 'redux/redux.shared'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'

const loadingTypes = ['fetchEntriesCommonMetadata'] as const
type LoadingTypes = typeof loadingTypes[number]

export interface FilesDetailsState {
  loading: Record<LoadingTypes, BackendLoading>
  isVisible: boolean
  activeTab: ActiveTab
  entriesCommonMetadata?: MetadataDetails[]
}

export const fetchEntriesCommonMetadata = createAsyncBackendThunk(
  'details/fetchEntriesCommonMetadata',
  async (entryIds: number[], { getState }) => {
    const projectId = selectProjectId(getStateAsAny(getState))
    const response = await backendAxios.post(`/projects/${projectId}/entries/common-metadata/`, entryIds)
    return response.data
  }
)

const filesDetailsSlice = createSlice({
  name: 'details',
  initialState: {
    isVisible: false,
    activeTab: 'CHARACTERISTICS',
    loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  } as FilesDetailsState,
  reducers: {
    toggleDetailsVisibility: (state) => {
      state.isVisible = !state.isVisible
    },
    setActiveTab: (state, action: PayloadAction<ActiveTab>) => {
      state.activeTab = action.payload
    },
    hideDetails: (state) => {
      state.isVisible = false
    },
    clearEntriesCommonMetadata: (state) => {
      state.entriesCommonMetadata = undefined
    },
    showFileDetails: (state) => {
      state.isVisible = true
    },
  },
  extraReducers: generateExtraBackendReducers<FilesDetailsState, LoadingTypes, MetadataDetails[]>({
    promise: fetchEntriesCommonMetadata,
    loadingType: 'fetchEntriesCommonMetadata',
    onFulfilled: (state, action) => {
      state.entriesCommonMetadata = action.payload
    },
  }),
})

export const {
  toggleDetailsVisibility,
  setActiveTab,
  hideDetails,
  clearEntriesCommonMetadata,
  showFileDetails,
} = filesDetailsSlice.actions

export const filesDetailsReducer = filesDetailsSlice.reducer
