import { createSlice } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import {
  BackendLoading,
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
} from 'redux/redux.shared'
import { SuitabilityCode } from 'models/administration'

const loadingTypes = ['fetchDefaultSuitabilityCode'] as const
type LoadingTypes = typeof loadingTypes[number]

export interface FilesSuitabilityState {
  loading: Record<LoadingTypes, BackendLoading>
  defaultSuitabilityCode?: SuitabilityCode | null
}

export const fetchDefaultSuitabilityCode = createAsyncBackendThunk('newEntry/fetchDefaultSuitabilityCode', async () => {
  return (await backendAxios.get('/suitability/default/')).data
})

const initialState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
} as FilesSuitabilityState

const filesSuitabilitySlice = createSlice({
  name: 'filesSuitability',
  initialState,
  reducers: {},
  extraReducers: combine([
    generateExtraBackendReducers<FilesSuitabilityState, LoadingTypes, { code: SuitabilityCode | null }>({
      promise: fetchDefaultSuitabilityCode,
      loadingType: 'fetchDefaultSuitabilityCode',
      onFulfilled: (state, action) => {
        state.defaultSuitabilityCode = action.payload.code
      },
    }),
  ]),
})

export const filesSuitabilityReducer = filesSuitabilitySlice.reducer
