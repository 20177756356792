import {
  ActionReducerMapBuilder,
  AsyncThunk,
  AsyncThunkPayloadCreator,
  createAsyncThunk,
  Draft,
  PayloadAction,
} from '@reduxjs/toolkit'
import { HttpBackendError, HttpError, ThunkAPIConfig } from 'models/errors'
import { parseResponse } from 'helpers/HttpError.helpers'

export const createAsyncBackendThunk = <Returned, ThunkArg = void>(
  typePrefix: string,
  thunk: AsyncThunkPayloadCreator<Returned, ThunkArg>
): AsyncThunk<Returned, ThunkArg, ThunkAPIConfig> => {
  return createAsyncThunk<Returned, ThunkArg, ThunkAPIConfig>(typePrefix, async (arg, thunkAPI) => {
    try {
      return await thunk(arg, thunkAPI)
    } catch (err) {
      return thunkAPI.rejectWithValue(parseResponse(err.response))
    }
  })
}

export interface Loading<T> {
  isLoading: boolean
  error?: T
}

export type HttpLoading = Loading<HttpError>

export type BackendLoading = Loading<HttpBackendError>

interface WithLoading<T> {
  loading: Record<string, Loading<T>>
}

export const initialLoadingState: HttpLoading = {
  isLoading: false,
}

export function generateInitialLoadingState<T extends string>(loadingTypes: readonly T[]): Record<string, HttpLoading> {
  return loadingTypes.map((t) => ({ [t]: { isLoading: false } })).reduce((acc, val) => ({ ...acc, ...val }))
}

type PromiseAction<ThunkArg, DataType = void> = PayloadAction<DataType, string, { arg: ThunkArg }>

interface CallArgs<State, LoadingType, DataType, ThunkArg> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  promise: any
  loadingType: LoadingType
  onPending?: (state: Draft<State>, action: PromiseAction<ThunkArg>) => void
  onRejected?: (state: Draft<State>, action: PromiseAction<ThunkArg>) => void
  onFulfilled?: (state: Draft<State>, action: PromiseAction<ThunkArg, DataType>) => void
}

export function generateExtraReducers<
  ErrorType extends HttpError,
  State extends WithLoading<HttpError>,
  LoadingType extends string,
  DataType = void,
  ThunkArg = void
>(call: CallArgs<State, LoadingType, DataType, ThunkArg>): (builder: ActionReducerMapBuilder<State>) => void {
  return (builder): void => {
    const { promise, onPending, onRejected, onFulfilled, loadingType } = call
    builder
      .addCase(promise.pending, (state: Draft<State>, action: PromiseAction<ThunkArg>) => {
        state.loading[loadingType].error = undefined
        state.loading[loadingType].isLoading = true
        if (onPending) onPending(state, action)
      })
      .addCase(promise.rejected, (state: Draft<State>, action: PromiseAction<ThunkArg>) => {
        state.loading[loadingType].isLoading = false
        state.loading[loadingType].error = (action.payload as unknown) as ErrorType // need to do this as error is returned in payload and redux-toolkit doesn't handle types well
        if (onRejected) onRejected(state, action)
      })
      .addCase(promise.fulfilled, (state: Draft<State>, action: PromiseAction<ThunkArg, DataType>) => {
        state.loading[loadingType].isLoading = false
        if (onFulfilled) onFulfilled(state, action)
      })
  }
}

export function generateExtraBackendReducers<
  State extends WithLoading<HttpBackendError>,
  LoadingType extends string,
  DataType = void,
  ThunkArg = void
>(call: CallArgs<State, LoadingType, DataType, ThunkArg>): (builder: ActionReducerMapBuilder<State>) => void {
  return generateExtraReducers<HttpBackendError, State, LoadingType, DataType, ThunkArg>(call)
}

export function combine<State>(
  generators: ((builder: ActionReducerMapBuilder<State>) => void)[]
): (builder: ActionReducerMapBuilder<State>) => void {
  return (builder): void => {
    generators.forEach((generator) => generator(builder))
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getStateAsAny = (getState: () => unknown): any => getState() as any
