import { Trans } from '@lingui/macro'
import Text, { ExpandableText } from 'components/atoms/text'

interface DisplayOrganizationAddressProps {
  address?: string | null
  isExpanded: boolean
}

const DisplayOrganizationAddress: React.FC<DisplayOrganizationAddressProps> = ({ address, isExpanded }) => {
  if (!address) {
    return (
      <Text textStyle="regularText-small" color="grayG05">
        <Trans id="administration.organizations.table.address.no_address_text">No address</Trans>
      </Text>
    )
  }
  return (
    <ExpandableText isExpanded={isExpanded} color="grayG07">
      {address}
    </ExpandableText>
  )
}

export default DisplayOrganizationAddress
