import { t, Trans } from '@lingui/macro'
import { AsyncThunkAction, unwrapResult } from '@reduxjs/toolkit'
import { Modal, Space, Spin } from 'antd'
import Text from 'components/atoms/text'
import FullScreenModalFooter from 'components/organisms/fullscreen-modal/FullscreenModalFooter'
import FullScreenModalHeader from 'components/organisms/fullscreen-modal/FullScreenModalHeader'
import FullScreenModalTemplate from 'components/templates/fullscreen-modal'
import { cancelText, nextText } from 'data/messages/controls'
import { yesText } from 'data/messages/misc'
import trans from 'helpers/i18n.helpers'
import { openNotification } from 'helpers/Notifications.helpers'
import useStepsParams from 'hooks/stepsParams'
import { ThunkAPIConfig } from 'models/errors'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { selectFlowId, selectGettingFlowLoading } from 'redux/flow-conifg/general/FlowGeneralConfig.selectors'
import {
  addFlowConfigFinishedStep,
  selectAddingFlowConfigFinishedStepLoading,
  selectFetchingFlowConfigFinishedStepsLoading,
} from 'redux/flow-conifg/steps'
import { FlowConfigStep } from 'redux/flow-conifg/steps/FlowStepsConfig.types'
import { steps } from 'redux/project/project-config/status/ProjectStatusConfig.types'
import {
  selectFetchProjectDetailsLoading,
  selectProjectId,
} from 'redux/project/project-details/ProjectDetails.selectors'
import { useAppDispatch } from 'redux/store'
import flowConfigStepsData from '../flowConfigSteps'
import FlowConfigSteps from './FlowConfigSteps'

interface BaseFlowConfigViewProps {
  content: React.ReactNode
  isContentValid?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveStepThunk?: AsyncThunkAction<any, any, ThunkAPIConfig>
  handleCompleteConfig?: (onConfigLeave: () => void) => () => void
  isContentLoading?: boolean
  stepName: FlowConfigStep
}

const getCancelAction = (isValid: boolean, handleSaveStep: () => void) => () => {
  Modal.confirm({
    title: t({
      id: 'flow_config.base.leave_config_confirm_modal_title',
      message: 'Exiting the window will abort the flow configuration.',
    }),
    content: isValid
      ? t({
          id: 'flow_config.base.leave_config_confirm_modal_content',
          message: 'The changes made so far will be saved. You can complete the setup at any time.',
        })
      : t({
          id: 'flow_config.base.leave_config_confirm_modal_content_invalid',
          message:
            'The entered data is incorrect. Interrupting the configuration will result in the loss of the entered data.',
        }),
    cancelText: trans(cancelText),
    okText: trans(yesText),
    onOk: handleSaveStep,
  })
}
// eslint-disable-next-line max-lines-per-function
const BaseFlowConfigView: React.FC<BaseFlowConfigViewProps> = ({
  content,
  isContentValid = true,
  saveStepThunk,
  handleCompleteConfig,
  isContentLoading = false,
  stepName,
}) => {
  const { stepIdx, step } = useStepsParams()
  const history = useHistory()
  const { isLoading: isFetchingDetailsLoading } = useSelector(selectFetchProjectDetailsLoading)
  const { isLoading: isFetchingGeneralFlowConfigLoading } = useSelector(selectGettingFlowLoading)
  const { isLoading: isFetchingFlowConfigFinishedStepsLoading } = useSelector(
    selectFetchingFlowConfigFinishedStepsLoading
  )
  const { isLoading: isAddingFlowConfigFinishedStepLoading } = useSelector(selectAddingFlowConfigFinishedStepLoading)
  const isLoading =
    isContentLoading ||
    isFetchingDetailsLoading ||
    isFetchingGeneralFlowConfigLoading ||
    isFetchingFlowConfigFinishedStepsLoading ||
    isAddingFlowConfigFinishedStepLoading

  const flowId = useSelector(selectFlowId)
  const projectId = useSelector(selectProjectId)

  const dispatch = useAppDispatch()
  const handleNextStep = () =>
    projectId && !Number.isNaN(flowId) && history.push(`/flow-config/${projectId}/${stepIdx + 2}/${flowId}`)
  const handlePrevStep = () =>
    projectId && !Number.isNaN(flowId) && history.push(`/flow-config/${projectId}/${stepIdx}/${flowId}`)
  const handleLeave = () => {
    history.replace(`/project-config/${projectId}/${steps.length}`)
  }

  const isLastStep = flowConfigStepsData.length - 1 === stepIdx

  const getSavingProgressHandler = (
    onComplete: () => void,
    shouldCompleteConfig: boolean,
    completeWithError?: boolean
  ) => () => {
    if (saveStepThunk)
      if (isContentValid && projectId)
        dispatch(saveStepThunk)
          .then(unwrapResult)
          .then(() => dispatch(addFlowConfigFinishedStep({ projectId, step: stepName })))
          .then(unwrapResult)
          .then(() => {
            openNotification('success', {
              message: t({
                id: 'flow_config.base.notification.success_message',
                message: `Step ${step} has been saved!`,
              }),
              placement: 'bottomLeft',
            })
            if (flowId !== undefined) {
              onComplete()
            }
          })
          .catch(() => {
            if (completeWithError) {
              onComplete()
            }
          })
      else
        Modal.confirm({
          title: t({
            id: 'flow_config.base.invalid_modal.confirm_title',
            message: 'Inserted data are invalid. Do you want to continue?',
          }),
          cancelText: trans(cancelText),
          okText: trans(yesText),
          onOk: onComplete,
        })
    else if (handleCompleteConfig && shouldCompleteConfig) handleCompleteConfig(onComplete)()
    else onComplete()
  }
  return (
    <FullScreenModalTemplate
      header={
        <FullScreenModalHeader
          text={
            <Text textStyle="regularText-medium">
              <Trans id="flow_config.base.header_text">Flow definition</Trans>
            </Text>
          }
          handleClose={getCancelAction(isContentValid, getSavingProgressHandler(handleLeave, true, true))}
        />
      }
      contentLeft={
        <FlowConfigSteps
          currentStep={stepIdx}
          isLoading={isLoading}
          getSavingStepHandler={getSavingProgressHandler}
          stepName={stepName}
        />
      }
      contentRight={
        <Spin spinning={isLoading}>
          <Space direction="vertical">{content}</Space>
        </Spin>
      }
      footer={
        <FullScreenModalFooter
          onNextBtnClick={getSavingProgressHandler(
            handleCompleteConfig || isLastStep ? handleLeave : handleNextStep,
            true
          )}
          onPrevBtnClick={getSavingProgressHandler(handlePrevStep, false)}
          isPrevBtnDisabled={stepIdx === 0}
          isNextBtnDisabled={!isContentValid}
          nextBtnText={
            handleCompleteConfig || isLastStep
              ? t({ id: 'flow_config.base.modal.finish_config_button', message: 'Save flow' })
              : trans(nextText)
          }
          isLoading={isLoading}
        />
      }
    />
  )
}

export default BaseFlowConfigView
