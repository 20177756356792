import React, { Component, ReactNode } from 'react'
import CesiumViewerError from './CesiumViewerError'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
}

class CesiumViewerErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  public static getDerivedStateFromError(): State {
    return { hasError: true }
  }

  render(): ReactNode {
    const { hasError } = this.state
    if (hasError) {
      return <CesiumViewerError />
    }
    const { children } = this.props
    return children
  }
}

export default CesiumViewerErrorBoundary
