import { useSelector } from 'react-redux'
import { addProjectConfigFinishedStep } from 'redux/project/project-config/status/ProjectStatusConfig.slice'
import { selectIsProjectSuitabilityConfigContentLoading } from 'redux/project/project-config/suitability/ProjectSuitabilityConfig.selectors'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import BaseProjectConfigView from '../base-config-view/BaseProjectConfigView'
import ProjectSuitabilityConfigContent from './content'

const ProjectSuitabilityConfig: React.FC = () => {
  const isContentLoading = useSelector(selectIsProjectSuitabilityConfigContentLoading)
  const projectId = useSelector(selectProjectId)
  return (
    <BaseProjectConfigView
      content={<ProjectSuitabilityConfigContent />}
      isContentLoading={isContentLoading}
      saveStepThunk={projectId ? addProjectConfigFinishedStep({ projectId, step: 'SUITABILITY_CODES' }) : undefined}
    />
  )
}

export default ProjectSuitabilityConfig
