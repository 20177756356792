import SpaceElement from 'components/atoms/space'
import Text from 'components/atoms/text'

interface UserDataDisplayProps {
  name: string
  surname?: string
  organization?: string
  email: string
  allowSendEmail?: boolean
}

const UserDataDisplay: React.FC<UserDataDisplayProps> = ({
  name,
  surname,
  organization,
  email,
  allowSendEmail = false,
}) => {
  const emailDisplay = (
    <Text textStyle="regularText-small" color="grayG05">
      {email}
    </Text>
  )
  return (
    <SpaceElement size="xxxs">
      <SpaceElement size="xxs" direction="horizontal">
        <Text textStyle="regularText-smallSemiBold">{`${name} ${surname || ''}`}</Text>
        {organization && <Text textStyle="regularText-small">{`(${organization})`}</Text>}
      </SpaceElement>
      {allowSendEmail ? (
        <a href={`mailto:${email}`} target="_blank" rel="noreferrer noopener">
          {emailDisplay}
        </a>
      ) : (
        emailDisplay
      )}
    </SpaceElement>
  )
}

export default UserDataDisplay
