import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import {
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  BackendLoading,
  generateInitialLoadingState,
} from 'redux/redux.shared'
import { EntryLayers } from 'models/map'

const loadingTypes = ['fetchGisFileLayers'] as const
type LoadingTypes = typeof loadingTypes[number]

export interface GisFileLayersState {
  loading: Record<LoadingTypes, BackendLoading>
  layers: EntryLayers[]
  visibleLayers: string[]
  checkedLayerNodes: (string | number)[]
}

export const fetchGisFileLayers = createAsyncBackendThunk(
  'gisFileLayers/fetchGisFileLayers',
  async (projectId: number) => {
    const response = await backendAxios.get(`/projects/${projectId}/geodata/layers/file/`)
    return response.data
  }
)

const gisFileLayersSlice = createSlice({
  name: 'gisFileLayers',
  initialState: {
    loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
    layers: [],
    visibleLayers: [],
    checkedLayerNodes: [],
  } as GisFileLayersState,
  reducers: {
    setVisibleGisFileLayers: (state, action: PayloadAction<string[]>) => {
      state.visibleLayers = action.payload
    },
    setCheckedGisFileNodes: (state, action: PayloadAction<(string | number)[]>) => {
      state.checkedLayerNodes = action.payload
    },
  },
  extraReducers: generateExtraBackendReducers<GisFileLayersState, LoadingTypes, EntryLayers[]>({
    promise: fetchGisFileLayers,
    loadingType: 'fetchGisFileLayers',
    onFulfilled: (state, action) => {
      state.layers = action.payload
    },
  }),
})

export const { setVisibleGisFileLayers, setCheckedGisFileNodes } = gisFileLayersSlice.actions

export const gisFileLayersReducer = gisFileLayersSlice.reducer
