import { SelectProps } from 'antd/lib/select'
import Organization from './organizations'
import { Member } from './project/projectConfig'

interface AbstractUser {
  id: number
  email: string
  firstName: string
  lastName: string
  organization: Organization
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ActiveUser extends AbstractUser {}

export const getUsersOptions = (users?: Member[]): SelectProps<number[]>['options'] =>
  users?.map(({ name, id }) => ({ label: name, value: id }))

export interface UserActivationDetails {
  isActive: boolean
  activationCodeExpiry?: string
}

export interface User extends AbstractUser {
  isAdmin: boolean
  activationDetails: UserActivationDetails
}
