import { UploadOutlined } from '@ant-design/icons'
import Icon from 'components/atoms/icon'
import Text from 'components/atoms/text'
import SpaceElement from 'components/atoms/space'
import { Trans } from '@lingui/macro'

const FilesUploadText: React.FC = () => {
  return (
    <SpaceElement direction="horizontal" size="m">
      <Icon color="grayG07">
        <UploadOutlined />
      </Icon>
      <div>
        <Text color="primaryP03" textStyle="regularText-medium">
          <Trans id="common.file_upload.select_file_text_part">Select a file</Trans>
        </Text>
        &nbsp;
        <Text textStyle="regularText-medium">
          <Trans id="common.file_upload.drop_file_text_part">or drop it here</Trans>
        </Text>
      </div>
    </SpaceElement>
  )
}

export default FilesUploadText
