import styled from 'styled-components'

interface WithHeaderTemplateProps {
  header: React.ReactNode
  content: React.ReactNode
}

const StyledSection = styled.section`
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
`
const StyledDiv = styled.div`
  height: 100%;
  overflow: auto;
`

const WithHeaderTemplate: React.FC<WithHeaderTemplateProps> = ({ header, content }) => {
  return (
    <StyledSection>
      {header}
      <StyledDiv>{content}</StyledDiv>
    </StyledSection>
  )
}

export default WithHeaderTemplate
