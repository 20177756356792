import { Divider, Space } from 'antd'
import { SelectProps, SelectValue } from 'antd/lib/select'
import CheckboxElement from 'components/molecules/checkbox'
import { allAvailableValuesText, anyValueText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import styled from 'styled-components'
import MultipleSelectWithTag from './MultipleSelectWithTag'

interface MultipleSelectWithTagAndAllOptionProps {
  options?: SelectProps<(number | string)[]>['options']
  placeholder?: React.ReactNode
  onChange?: (val: SelectValue) => void
  value?: (string | number)[]
}

const StyledSpace = styled(Space)`
  margin-left: ${({ theme }) => theme.sizes.s};
  width: 100%;
`

const Dropdown: React.FC<MultipleSelectWithTagAndAllOptionProps> = ({ options, value, onChange, children }) => {
  const valueLength = value?.length
  const optionsLength = options?.length
  const isAnyValCheckboxChecked = valueLength === 0
  const isAllValCheckboxChecked = valueLength === optionsLength
  return (
    <div>
      <StyledSpace direction="vertical">
        <CheckboxElement
          checked={isAnyValCheckboxChecked}
          onChange={() => {
            if (!isAnyValCheckboxChecked) {
              onChange?.([])
            }
          }}
        >
          {trans(anyValueText)}
        </CheckboxElement>
        <Divider style={{ margin: 0 }} />
        <CheckboxElement
          checked={isAllValCheckboxChecked}
          onChange={() => {
            if (isAllValCheckboxChecked) onChange?.([])
            else if (options) onChange?.(options.map((option) => option.value))
          }}
        >
          {trans(allAvailableValuesText)}
        </CheckboxElement>
        <Divider style={{ margin: 0 }} />
      </StyledSpace>
      {children}
    </div>
  )
}

const MultipleSelectWithTagAndAllOption: React.FC<MultipleSelectWithTagAndAllOptionProps> = ({
  options = [],
  placeholder,
  onChange,
  value = [],
}) => {
  return (
    <MultipleSelectWithTag
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      options={options}
      dropdownRender={(originNode) => (
        <Dropdown options={options} value={value} onChange={onChange}>
          {originNode}
        </Dropdown>
      )}
    />
  )
}

export default MultipleSelectWithTagAndAllOption
