import { I18nProvider } from '@lingui/react'
import { i18n } from '@lingui/core'
import { ConfigProvider } from 'antd'
import { useSelector } from 'react-redux'
import { selectAntdLocale } from 'redux/select-language/Language.selectors'
import App from './App'

function LocalisedApp(): JSX.Element {
  const antdLocale = useSelector(selectAntdLocale)
  return (
    <ConfigProvider locale={antdLocale}>
      <I18nProvider i18n={i18n}>
        <App />
      </I18nProvider>
    </ConfigProvider>
  )
}

export default LocalisedApp
