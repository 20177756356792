import styled from 'styled-components'
import { Link } from 'react-router-dom'

interface StyledLiProps {
  $hover: boolean
}

export const StyledLi = styled.li<StyledLiProps>`
  transition: ${(props) => props.theme.transitions.default};
  ${(props) => props.$hover && 'transform: translateY(-4px);'}
  ${(props) => props.$hover && `filter: ${props.theme.effects.dropShadow};`}
  h2 {
    margin: 0;
  }
  min-width: 20rem;
`
export const StyledLink = styled(Link)`
  overflow: hidden;
  justify-self: start;
  max-width: 100%;
`
export const StyledImg = styled.img`
  width: 100%;
`
export const StyledDescription = styled.div`
  padding: ${(props) => props.theme.sizes.s} ${(props) => props.theme.sizes.default};
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 20px;
  align-items: center;
  white-space: nowrap;
  background-color: ${(props) => props.theme.colors.baseWhiteish};
  > :nth-last-child(2) {
    grid-column: -1;
  }
`
export const StyledDescriptionBottomContainer = styled.div`
  display: flex;
  grid-column: 1 / span 3;
`
export const StyledDescriptionBottomItem = styled.div`
  display: flex;
  max-width: calc(33.3333% - 18px);
  &:not(:last-child) {
    margin-right: 18px;
  }
  > .ant-typography {
    margin-left: 10px;
  }
`
