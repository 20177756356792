import Icon from 'components/atoms/icon'
import useSideDrawerOption from 'hooks/side-drawer/sideDrawerOption'

interface FilesOptionProps {
  children: React.ReactNode
  type: string
}

const FilesOption: React.FC<FilesOptionProps> = ({ children, type }) => {
  const { handleOption, isActive } = useSideDrawerOption('files', type)
  return (
    <li>
      <Icon color={isActive ? 'primaryP04' : 'grayG08'} onClick={handleOption}>
        {children}
      </Icon>
    </li>
  )
}

export default FilesOption
