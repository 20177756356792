import { BackupStatus } from 'models/administration'
import Text from 'components/atoms/text'
import TooltipElement from 'components/molecules/tooltip-element'
import backupStatusDict from 'data/backups'
import trans from 'helpers/i18n.helpers'

interface BackupsStatusDisplayProps {
  status: BackupStatus
}

const BackupsStatusDisplay: React.FC<BackupsStatusDisplayProps> = ({ status }) => {
  const { color, text, tooltip } = backupStatusDict[status]
  return (
    <TooltipElement placement="topLeft" title={trans(tooltip)}>
      <Text color={color} textStyle="regularText-small">
        {trans(text)}
      </Text>
    </TooltipElement>
  )
}

export default BackupsStatusDisplay
