import { ColumnsType } from 'antd/lib/table'
import { useSelector } from 'react-redux'
import useTableColumnFiltering, { onFilterStringColumn } from 'components/molecules/table/useTableColumnFiltering'
import { ProjectStatusTypes } from 'redux/administration/administration-projects/AdministrationProjects.types'
import { selectAdministrationCodeLengthDict } from 'redux/administration/administration-code-settings/AdministrationCodeSettings.selectors'
import { Project } from 'models/administration'
import WithValidityInfo from 'components/atoms/with-validity-info'
import { t, Trans } from '@lingui/macro'
import { actionsTableColumnText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import CodeColumnTableHeader from 'components/molecules/table/CodeColumnTableHeader'
import { getProjectStatus, projectStatusOrderDict } from 'helpers/Models.helpers'
import { ExpandableTableColumnType } from 'components/molecules/table/ExpandableTable'
import { ExpandableText } from 'components/atoms/text'
import useTableColumnSorting from 'components/molecules/table/useTableColumnSorting'
import { sortByDict } from 'helpers/Collection.helpers'
import DisplayProjectAdmins from './DisplayProjectAdmins'
import DisplayProjectStatus from './DisplayProjectStatus'
import ProjectActions from './ProjectActions'
import projectStatusLabels from '../texts'

const useAdministrationProjectsColumns = (): ColumnsType<Project & ExpandableTableColumnType> => {
  const codeLengthDict = useSelector(selectAdministrationCodeLengthDict)
  return [
    {
      title: <Trans id="administration.projects.table.column_name_label">Name</Trans>,
      dataIndex: 'projectName',
      key: 'projectName',
      width: 400,
      render: (value, { name, isTableRowExpanded }) => (
        <ExpandableText isExpanded={isTableRowExpanded} color="grayG07">
          {name}
        </ExpandableText>
      ),
      ...useTableColumnFiltering(
        t({ id: 'administration.projects.table.search_name_placeholder', message: 'Search for name' })
      ),
      onFilter: (value: string | number | boolean, record: Project) => onFilterStringColumn(value, record.name),
      ...useTableColumnSorting({ sortBy: (p: Project) => p.name }),
    },
    {
      title: (
        <CodeColumnTableHeader>
          <Trans id="administration.projects.table.column_code_label">Code</Trans>
        </CodeColumnTableHeader>
      ),
      dataIndex: 'projectCode',
      key: 'projectCode',
      width: 120,
      render: (value, { code }) => (
        <WithValidityInfo
          isValid={code.code.length === codeLengthDict.PROJECT_CODE}
          invalidMessage={t({ id: 'administration.projects.table.invalid_code_error', message: 'Invalid code' })}
          center
        >
          {code.code}
        </WithValidityInfo>
      ),
      ...useTableColumnFiltering(
        t({ id: 'administration.projects.table.search_code_placeholder', message: 'Search for code' })
      ),
      onFilter: (value: string | number | boolean, record: Project) => onFilterStringColumn(value, record.code.code),
      ...useTableColumnSorting({ sortBy: (p: Project) => p.code.code }),
    },
    {
      title: <Trans id="administration.projects.table.column_administrators_label">Administrators</Trans>,
      dataIndex: 'projectAdmins',
      key: 'projectAdmins',
      width: 250,
      render: (value, { admins, isTableRowExpanded }) => (
        <DisplayProjectAdmins admins={admins} isExpanded={isTableRowExpanded} />
      ),
      ...useTableColumnFiltering(
        t({ id: 'administration.projects.search_administrators_placeholder', message: 'Search for administrator' })
      ),
      onFilter: (value: string | number | boolean, record: Project) =>
        onFilterStringColumn(value, record.admins.map((a) => a.name).join(' ')),
    },
    {
      title: <Trans id="administration.projects.table.column_location_label">Location</Trans>,
      dataIndex: 'projectLocation',
      key: 'projectLocation',
      width: 250,
      render: (value, { location, isTableRowExpanded }) => (
        <ExpandableText isExpanded={isTableRowExpanded} color="grayG07">
          {location}
        </ExpandableText>
      ),
      ...useTableColumnFiltering(
        t({ id: 'administration.projects.search_location_placeholder', message: 'Search for location' })
      ),
      onFilter: (value: string | number | boolean, record: Project) => onFilterStringColumn(value, record.location),
      ...useTableColumnSorting({ sortBy: (p: Project) => p.location }),
    },
    {
      title: <Trans id="administration.projects.table.column_activity_status_label">Activity status</Trans>,
      dataIndex: 'projectStatus',
      key: 'projectStatus',
      width: 175,
      render: (value, { isEnded, isActive }) => <DisplayProjectStatus isEnded={isEnded} isActive={isActive} />,
      filters: [
        { text: trans(projectStatusLabels[ProjectStatusTypes.IN_CONFIG]), value: ProjectStatusTypes.IN_CONFIG },
        { text: trans(projectStatusLabels[ProjectStatusTypes.ACTIVE]), value: ProjectStatusTypes.ACTIVE },
        { text: trans(projectStatusLabels[ProjectStatusTypes.ARCHIVED]), value: ProjectStatusTypes.ARCHIVED },
      ],
      onFilter: (value, record) => getProjectStatus({ isActive: record.isActive, isEnded: record.isEnded }) === value,
      ...useTableColumnSorting({
        sortBy: (p: Project) => getProjectStatus({ isActive: p.isActive, isEnded: p.isEnded }),
        comparator: sortByDict(projectStatusOrderDict),
        defaultOrder: 'ascend',
      }),
    },
    {
      title: trans(actionsTableColumnText),
      dataIndex: 'actions',
      key: 'actions',
      render: (value, { id }) => <ProjectActions projectId={id} />,
    },
  ]
}

export default useAdministrationProjectsColumns
