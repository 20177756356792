import { t } from '@lingui/macro'
import ErrorAlert from 'components/atoms/error-alert'
import { useSelector } from 'react-redux'
import { selectFetchingFlowsLoading } from 'redux/project/project-config/flow/ProjectFlowConfig.selectors'

const ProjectFlowConfigContentAlerts: React.FC = () => {
  const { error } = useSelector(selectFetchingFlowsLoading)
  const alertMessageDict = [
    {
      error,
      message: t({
        id: 'project_config.flow.alert.fetching_flows_failed',
        message: 'Could not retrieve flows.',
      }),
    },
  ]
  return <ErrorAlert alertMessageDict={alertMessageDict} />
}

export default ProjectFlowConfigContentAlerts
