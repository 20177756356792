import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectFlowDecisionConfigValidity,
  selectIsDecisionsConfigContentLoading,
} from 'redux/flow-conifg/decisions/FlowDecisionsConfig.selectors'
import {
  fetchProjectMembers,
  fetchSuitabilityOverview,
  getFlowDecisions,
  updateFlowDecisions,
} from 'redux/flow-conifg/decisions/FlowDecisionsConfig.slice'
import { selectFlowId } from 'redux/flow-conifg/general/FlowGeneralConfig.selectors'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import BaseFlowConfigView from '../base-flow-config-view/BaseFlowConfigView'
import FlowDecisionsConfigContent from './content/FlowDecisionsConfigContent'

const FlowDecisionsConfig: React.FC = () => {
  const isLoading = useSelector(selectIsDecisionsConfigContentLoading)
  const flowConfigValidity = useSelector(selectFlowDecisionConfigValidity)
  const dispatch = useDispatch()
  const projectId = useSelector(selectProjectId)
  const flowId = useSelector(selectFlowId)

  useEffect(() => {
    if (projectId) {
      if (flowId && !Number.isNaN(flowId)) {
        dispatch(getFlowDecisions({ flowId, projectId }))
      }
      dispatch(fetchSuitabilityOverview())
      dispatch(fetchProjectMembers(projectId))
    }
  }, [dispatch, flowId, projectId])
  if (!projectId) return null
  const savingThunk = updateFlowDecisions({ projectId, flowId: Number(flowId) })
  return (
    <BaseFlowConfigView
      isContentValid={flowConfigValidity}
      saveStepThunk={savingThunk}
      content={<FlowDecisionsConfigContent />}
      isContentLoading={isLoading}
      stepName="START_CHANGES"
    />
  )
}

export default FlowDecisionsConfig
