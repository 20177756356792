import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import { FlowStepsConfigState, FlowConfigStep } from './FlowStepsConfig.types'

const selectFlowStepsConfig: (state: RootState) => FlowStepsConfigState = (state) => state.flowConfig.steps

export const selectFlowConfigFinishedSteps: (state: RootState) => FlowConfigStep[] = (state) =>
  selectFlowStepsConfig(state).finishedSteps

export const selectFlowConfigFinishedStepsLoaded: (state: RootState) => boolean = (state) =>
  selectFlowStepsConfig(state).finishedStepsLoaded

export const selectFetchingFlowConfigFinishedStepsLoading: (state: RootState) => BackendLoading = (state) =>
  selectFlowStepsConfig(state).loading.fetchFlowConfigFinishedSteps

export const selectAddingFlowConfigFinishedStepLoading: (state: RootState) => BackendLoading = (state) =>
  selectFlowStepsConfig(state).loading.fetchFlowConfigFinishedSteps
