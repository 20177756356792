import { CdeIconStatus } from 'components/atoms/cde-icons/BaseCdeIcon'
import CdePermissions from 'components/molecules/cde-permissions/CdePermissions'
import { CdeStatus } from 'models/files'
import { useDispatch, useSelector } from 'react-redux'
import { selectUsersPermissionsByDiscipline } from 'redux/project/project-config/members-permissions/ProjectMembersPermissionsConfig.selectors'
import {
  handleAcceptStatusChange,
  handleCheckStatusChange,
  handleReadStatusChange,
  handleWriteStatusChange,
} from 'redux/project/project-config/members-permissions/ProjectMembersPermissionsConfig.slice'

interface CdeStatusPermissionsProps {
  disciplineId: number
  cdeStatus: CdeStatus
}

const CdeStatusPermissions: React.FC<CdeStatusPermissionsProps> = ({ disciplineId, cdeStatus }) => {
  const permissions = useSelector(selectUsersPermissionsByDiscipline(disciplineId))
  const dispatch = useDispatch()
  const getHandlersProps = (cdeIconStatus?: CdeIconStatus) => ({
    discipline: disciplineId,
    cdeStatus,
    isIconStatusOff: cdeIconStatus === 'OFF',
    isIconStatusMixed: cdeIconStatus === 'ON/OFF',
  })
  const handleReadIconClick = (cdeIconStatus?: CdeIconStatus) =>
    dispatch(handleReadStatusChange(getHandlersProps(cdeIconStatus)))
  const handleWriteIconClick = (cdeIconStatus?: CdeIconStatus) =>
    dispatch(handleWriteStatusChange(getHandlersProps(cdeIconStatus)))
  const handleReviewIconClick = (cdeIconStatus?: CdeIconStatus) =>
    dispatch(handleCheckStatusChange(getHandlersProps(cdeIconStatus)))
  const handleAcceptIconClick = (cdeIconStatus?: CdeIconStatus) =>
    dispatch(handleAcceptStatusChange(getHandlersProps(cdeIconStatus)))
  return (
    <CdePermissions
      cdeStatus={cdeStatus}
      permissions={permissions}
      onReadIconClick={handleReadIconClick}
      onWriteIconClick={handleWriteIconClick}
      onReviewIconClick={handleReviewIconClick}
      onAcceptIconClick={handleAcceptIconClick}
    />
  )
}

export default CdeStatusPermissions
