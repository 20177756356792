import { defineMessage, t } from '@lingui/macro'
import { MessageErrorAlert } from 'components/atoms/error-alert'
import { handleApiErrors } from 'helpers/HttpError.helpers'
import { useSelector } from 'react-redux'
import {
  selectFetchingWipGroupsLoading,
  selectUpdatingWipGroupsLoading,
} from 'redux/project/project-config/wip-groups/ProjectWipGroupsConfig.selectors'

const updateUserErrorDict = {
  'organization_group.missing_organization': defineMessage({
    id: 'project_config.wips.alert.missing_organization_error',
    message: 'Each organization should be in one WIP group.',
  }),
  'organization_group.id_not_unique': defineMessage({
    id: 'project_config.wips.alert.wip_id_error',
    message: 'Organization group id should be unique.',
  }),
  'organization_group.organization_not_unique': defineMessage({
    id: 'project_config.wips.alert.duplicated_error',
    message: 'Each organization should be in one WIP group.',
  }),
}

const ProjectWipGroupsConfigAlerts: React.FC = () => {
  const fetchingWipGroupsLoading = useSelector(selectFetchingWipGroupsLoading)
  const editingLoading = useSelector(selectUpdatingWipGroupsLoading)
  return (
    <div>
      {fetchingWipGroupsLoading.error && (
        <MessageErrorAlert
          message={t({
            id: 'project_config.wips.alert.wips_fetch_failed',
            message: 'Could not retrieve organizations groups.',
          })}
        />
      )}
      {editingLoading.error && (
        <MessageErrorAlert
          message={t({
            id: 'project_config.wips.alert.wips_update_failed',
            message: 'Could not update organizations groups.',
          })}
          description={handleApiErrors(updateUserErrorDict, editingLoading.error.errors)}
        />
      )}
    </div>
  )
}

export default ProjectWipGroupsConfigAlerts
