import { identity } from 'helpers/Functions.helpers'
import { FrontendFile } from 'models/files'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCheckedFrontendFiles, selectNewlyAddedFilesId } from 'redux/files/files-table/FilesTable.selectors'
import { setCheckedFrontendFiles } from 'redux/files/files-table/FilesTable.slice'
import StyledTable from '../FilesTable.styles'
import useFrontendFilesColumns from './useFrontendFilesColumns'

interface FilesFrontendFilesTableProps {
  frontendFiles?: FrontendFile[]
}

const FilesFrontendFilesTable: React.FC<FilesFrontendFilesTableProps> = ({ frontendFiles }) => {
  const columns = useFrontendFilesColumns()
  const dispatch = useDispatch()
  const newlyAddedFilesIds = useSelector(selectNewlyAddedFilesId)
  const checkedFrontendFiles = useSelector(selectCheckedFrontendFiles)
  const selectedRowKeys = useMemo(() => checkedFrontendFiles.map(({ key }) => key), [checkedFrontendFiles])
  return (
    <StyledTable
      dataSource={frontendFiles}
      rowClassName={(record: FrontendFile) =>
        newlyAddedFilesIds.find((id) => id === record.fileId) ? 'highlighted-success' : ''
      }
      columns={columns}
      rowSelection={{
        type: 'checkbox',
        selectedRowKeys,
        onChange: (selectedKeys: React.Key[]) =>
          dispatch(
            setCheckedFrontendFiles(
              selectedKeys
                .map((key) => frontendFiles?.find((frontendFile) => frontendFile.key === key))
                .filter(identity) as FrontendFile[]
            )
          ),
      }}
      rowKey="key"
      pagination={false}
    />
  )
}

export default FilesFrontendFilesTable
