import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import {
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  BackendLoading,
  generateInitialLoadingState,
} from 'redux/redux.shared'
import { ProjectLayer } from 'models/map'

const loadingTypes = ['fetchGisProjectLayers'] as const
type LoadingTypes = typeof loadingTypes[number]

export interface GisProjectLayersState {
  loading: Record<LoadingTypes, BackendLoading>
  layers: ProjectLayer[]
  visibleLayers: string[]
  checkedLayerNodes: (string | number)[]
}

export const fetchGisProjectLayers = createAsyncBackendThunk(
  'gisProjectLayers/fetchGisProjectLayers',
  async (projectId: number) => {
    const response = await backendAxios.get(`/projects/${projectId}/geodata/layers/`)
    return response.data
  }
)

const gisProjectLayersSlice = createSlice({
  name: 'gisProjectLayers',
  initialState: {
    loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
    layers: [],
    visibleLayers: [],
    checkedLayerNodes: [],
  } as GisProjectLayersState,
  reducers: {
    setVisibleGisProjectLayers: (state, action: PayloadAction<string[]>) => {
      state.visibleLayers = action.payload
    },
    setCheckedGisProjectNodes: (state, action: PayloadAction<(string | number)[]>) => {
      state.checkedLayerNodes = action.payload
    },
  },
  extraReducers: generateExtraBackendReducers<GisProjectLayersState, LoadingTypes, ProjectLayer[]>({
    promise: fetchGisProjectLayers,
    loadingType: 'fetchGisProjectLayers',
    onFulfilled: (state, action) => {
      state.layers = action.payload
    },
  }),
})

export const { setVisibleGisProjectLayers, setCheckedGisProjectNodes } = gisProjectLayersSlice.actions

export const gisProjectLayersReducer = gisProjectLayersSlice.reducer
