import { CopyOutlined, EditOutlined } from '@ant-design/icons'
import { defineMessage, t } from '@lingui/macro'
import { WithDividerElement } from 'components/atoms/divider'
import ButtonWithTooltip, { DeleteButton } from 'components/molecules/buttons'
import { deleteText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import showConfirmationModal from 'helpers/Modals.helpers'
import { useDispatch } from 'react-redux'
import { MessageDescriptor } from '@lingui/core'
import {
  deleteNamingScheme,
  openSchemeModal,
} from 'redux/administration/administration-naming-scheme/AdministrationNamingScheme.slice'

interface NamingSchemeActionsProps {
  id: number
  name: string
  isDefault: boolean
  isEditable: boolean
  isUsed: boolean
}

const deleteButtonTooltips = {
  PREDEFINED: defineMessage({
    id: 'administration.naming_schemes.delete_predefined_scheme_disabled_tooltip',
    message: 'This scheme is predefined and cannot be deleted',
  }),
  DEFAULT: defineMessage({
    id: 'administration.naming_schemes.delete_default_scheme_disabled_tooltip',
    message: 'This scheme is set as default and cannot be deleted',
  }),
  USED: defineMessage({
    id: 'administration.naming_schemes.delete_used_scheme_disabled_tooltip',
    message: 'This scheme is used and cannot be deleted',
  }),
}

const getDisabledButtonDeleteButtonTooltip: (
  isDefault: boolean,
  isEditable: boolean,
  isUsed: boolean
) => MessageDescriptor | undefined = (isDefault, isEditable, isUsed) => {
  if (!isEditable) return deleteButtonTooltips.PREDEFINED
  if (isDefault) return deleteButtonTooltips.DEFAULT
  if (isUsed) return deleteButtonTooltips.USED
  return undefined
}

const NamingSchemeActions: React.FC<NamingSchemeActionsProps> = ({ id, name, isDefault, isEditable, isUsed }) => {
  const dispatch = useDispatch()
  const deleteButtonDisabledTooltip = getDisabledButtonDeleteButtonTooltip(isDefault, isEditable, isUsed)
  return (
    <WithDividerElement>
      <ButtonWithTooltip
        onClick={() => dispatch(openSchemeModal({ schemeId: id, copy: false }))}
        disabled={!isEditable}
        tooltipText={
          isEditable
            ? t({ id: 'administration.naming_schemes.edit_scheme_tooltip', message: 'Edit scheme' })
            : t({
                id: 'administration.naming_schemes.edit_scheme_disabled_tooltip',
                message: 'This scheme is predefined and cannot be edited',
              })
        }
        icon={<EditOutlined />}
      />
      <ButtonWithTooltip
        onClick={() => dispatch(openSchemeModal({ schemeId: id, copy: true }))}
        tooltipText={t({ id: 'administration.naming_schemes.copy_scheme_tooltip', message: 'Copy scheme' })}
        icon={<CopyOutlined />}
      />
      <DeleteButton
        onClick={() =>
          showConfirmationModal({
            content: t({
              id: 'administration.naming_schemes.delete_confirmation_text',
              message: `Delete scheme "${name}"?`,
            }),
            okText: trans(deleteText),
            onOk: async () => {
              dispatch(deleteNamingScheme(id))
            },
          })
        }
        disabled={isDefault || !isEditable || isUsed}
        tooltip={t({ id: 'administration.naming_schemes.delete_scheme_tooltip', message: 'Delete scheme' })}
        disabledTooltip={deleteButtonDisabledTooltip ? trans(deleteButtonDisabledTooltip) : ''}
      />
    </WithDividerElement>
  )
}

export default NamingSchemeActions
