import styled from 'styled-components'

const StyledUl = styled.ul`
  > *:not(:last-child) {
    margin-bottom: ${(props) => props.theme.sizes.default};
    @media (max-height: 750px) {
      margin-bottom: ${(props) => props.theme.sizes.s};
    }
  }
`

const MapOptionsBar: React.FC = () => {
  return (
    <StyledUl>
      {/* {mapSideDrawerOptions.map(({ type, option, tooltip }) => (
        <TooltipElement key={type} placement="top" title={trans(tooltip || notYetReadyDebugText)}>
          <MapOption type={type} icon={option} />
        </TooltipElement>
      ))} */}
    </StyledUl>
  )
}

export default MapOptionsBar
