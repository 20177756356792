import { SuitabilityCode } from 'models/administration'
import { FlowConsequencesConfig } from 'models/flow'
import type { BackendLoading } from 'redux/redux.shared'

export const loadingType = ['getFlowConsequences', 'updateFlowConsequences', 'fetchSuitabilityOverview'] as const
export type LoadingType = typeof loadingType[number]

export interface FlowConsequencesConfigState {
  loading: Record<LoadingType, BackendLoading>
  flowConsequencesConfig: FlowConsequencesConfig
  codes: SuitabilityCode[]
}
