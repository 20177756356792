import { Flow } from 'models/flow'
import { BackendLoading } from 'redux/redux.shared'

export const loadingTypes = ['fetchFlows', 'deleteFlow'] as const
export type LoadingTypes = typeof loadingTypes[number]

export interface ProjectFlowConfigState {
  loading: Record<LoadingTypes, BackendLoading>
  flows: Required<Flow>[]
}
