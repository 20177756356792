import { defineMessage, t } from '@lingui/macro'
import { FolderOutlined } from '@material-ui/icons'
import { leastRecentUpdateText, mostRecentUpdateText } from 'data/messages/files'
import { projectText } from 'data/messages/misc'
import formatDate, { reduceToNewestTimeEntry, reduceToOldestTimeEntry } from 'helpers/Dates.helpers'
import trans from 'helpers/i18n.helpers'
import { SelectedGroup, DetailsItem, CdeStatus } from 'models/files'
import FilesDetailsContent from '../FileDetailsContent'

interface FilesDetailsGroupProps {
  group: SelectedGroup
  showName?: boolean
}

const directoryText = defineMessage({ id: 'project.files.file_info.directory_name', message: 'Directory' })

const getCharacteristics = (group: SelectedGroup, showName: boolean): DetailsItem[] => {
  const { isRoot, groups, lastUpdated, frontendFiles } = group
  if (frontendFiles && frontendFiles.length) {
    const newestDateFrontendFile = frontendFiles.reduce(reduceToNewestTimeEntry)
    const oldestDateFrontendFile = frontendFiles.reduce(reduceToOldestTimeEntry)
    return [
      ...(showName
        ? [
            {
              name: trans(isRoot ? projectText : directoryText),
              value: group.displayName,
            },
          ]
        : []),
      {
        name: t({ id: 'project.files.file_info.number_of_files', message: 'Number of files' }),
        value: frontendFiles.length,
      },
      {
        name: t({ id: 'project.files.file_info.oldest_file', message: 'Oldest file' }),
        value: `${oldestDateFrontendFile.filename}, ${formatDate(
          oldestDateFrontendFile.lastUpdated || oldestDateFrontendFile.uploadDate || ''
        )}`,
      },
      {
        name: t({ id: 'project.files.file_info.newest_file', message: 'Newest file' }),
        value: `${newestDateFrontendFile.filename}, ${formatDate(
          newestDateFrontendFile.lastUpdated || newestDateFrontendFile.uploadDate || ''
        )}`,
      },
      ...(lastUpdated
        ? [
            {
              name: t({ id: 'project.files.file_info.update_date', message: 'Update date' }),
              value: formatDate(lastUpdated),
            },
          ]
        : []),
    ]
  }
  const newestGroup = groups?.reduce(reduceToNewestTimeEntry)
  const oldestGroup = groups?.reduce(reduceToOldestTimeEntry)
  return [
    ...(showName ? [{ name: trans(isRoot ? projectText : directoryText), value: group.displayName }] : []),
    {
      name: t({ id: 'project.files.file_info.number_of_directories', message: 'Number of directories' }),
      value: groups?.length || 0,
    },
    {
      name: trans(leastRecentUpdateText),
      value: `${oldestGroup?.value.name}, ${formatDate(oldestGroup?.lastUpdated || '')}`,
    },
    {
      name: trans(mostRecentUpdateText),
      value: `${newestGroup?.value.name}, ${formatDate(newestGroup?.lastUpdated || '')}`,
    },
  ]
}

const commonToCdeStatusDict: Record<string, CdeStatus> = {
  'Work in progress': 'IN_PROGRESS',
  Shared: 'SHARED',
  Published: 'PUBLISHED',
  Archived: 'ARCHIVED',
}

const FilesDetailsGroup: React.FC<FilesDetailsGroupProps> = ({ group, showName = true }) => {
  const characteristics = getCharacteristics(group, showName)
  const attributes = group.attributes.map((attr) => ({
    ...attr,
    value: attr.key === 'status' ? commonToCdeStatusDict[attr.value] : attr.value,
  }))
  return (
    <FilesDetailsContent
      characteristics={characteristics}
      attributes={attributes}
      title={group.displayName}
      icon={<FolderOutlined />}
    />
  )
}

export default FilesDetailsGroup
