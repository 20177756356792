import { ProjectDetails } from 'models/project/projectDetails'
import { BackendLoading } from 'redux/redux.shared'

export const loadingTypes = ['fetchProjectDetails', 'fetchPossibleProjections'] as const
export type LoadingTypes = typeof loadingTypes[number]

export interface ProjectDetailsState {
  loading: Record<LoadingTypes, BackendLoading>
  details?: ProjectDetails
  currentProjection?: Projection
  projections: Projection[]
}

export interface Projection {
  id: number
  name: string
  mapping: string
  isDefault: boolean
}
