import Text from 'components/atoms/text'
import CesiumViewer from 'components/organisms/cesium-viewer/CesiumViewer'
import styled from 'styled-components'
import { useEffect } from 'react'
import { useAppDispatch } from 'redux/store'
import CoordinatesPanel from 'components/organisms/coordinates-panel'
import ProjectTemplate from 'components/templates/project'
import { fetchPossibleProjections } from 'redux/project/project-details/ProjectDetails.slice'
import useProjectId from 'hooks/useProjectId'
import { Trans } from '@lingui/macro'

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
  grid-template-columns: auto;
  grid-template-rows: auto auto 1fr;
  gap: ${({ theme }) => theme.sizes.s};
  > *:last-child {
    width: 100%;
  }
`

const ProjectMapConfigContent: React.FC = () => {
  const dispatch = useAppDispatch()
  const projectId = useProjectId()
  useEffect(() => {
    if (projectId) {
      dispatch(fetchPossibleProjections(projectId))
    }
  }, [dispatch, projectId])
  return (
    <StyledDiv>
      <Text textStyle="regularText-medium">
        <Trans id="project_config.map.step_header">Map settings</Trans>
      </Text>
      <Text textStyle="regularText-small">
        <Trans id="project_config.map.step_description">
          Set the position, scale, and coordinate system to be displayed by default when opening a project.
        </Trans>
      </Text>
      <ProjectTemplate
        viewer={<CesiumViewer isConfigMode />}
        topPanel={null}
        leftAside={null}
        rightAside={null}
        bottomPanel={<CoordinatesPanel />}
      />
    </StyledDiv>
  )
}

export default ProjectMapConfigContent
