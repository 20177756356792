import { t } from '@lingui/macro'
import { Space } from 'antd'
import CheckboxCircle from 'components/atoms/checkbox-circle'
import Text from 'components/atoms/text'
import WithClickLoadingWrapper from 'components/atoms/with-click-loading'
import WithOverflow from 'components/atoms/with-overflow'
import { useDispatch } from 'react-redux'
import { readNotifications } from 'redux/notifications'
import styled from 'styled-components'
import TooltipElement from '../tooltip-element'

interface NotificationReadStatusProps {
  isRead: boolean
  dateStr: string
  id: number
}

const StyledSpace = styled(Space)`
  && {
    .ant-space-item:last-child {
      max-width: 100px;
    }
  }
`

const NotificationReadStatus: React.FC<NotificationReadStatusProps> = ({ isRead, dateStr, id }) => {
  const dispatch = useDispatch()
  return (
    <StyledSpace size="middle">
      <TooltipElement
        title={
          isRead
            ? t({ id: 'notifications.popover.notification_read_tooltip', message: 'Already read' })
            : t({ id: 'notifications.popover.mark_as_read_tooltip', message: 'Mark as read' })
        }
      >
        <WithClickLoadingWrapper size="small" onClick={isRead ? undefined : () => dispatch(readNotifications([id]))}>
          <CheckboxCircle checked={isRead} />
        </WithClickLoadingWrapper>
      </TooltipElement>
      <WithOverflow>
        <Text textStyle={isRead ? 'regularText-small' : 'regularText-smallSemiBold'} color="grayG06">
          {dateStr}
        </Text>
      </WithOverflow>
    </StyledSpace>
  )
}

export default NotificationReadStatus
