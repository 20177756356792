import { wrapWithClear } from 'helpers/Functions.helpers'
import { combineReducers } from 'redux'
import { flowConfigClear } from 'redux/action.types'
import { flowGeneralConfigReducer } from './general/FlowGeneralConfig.slice'
import { flowDecisionsConfigReducer } from './decisions/FlowDecisionsConfig.slice'
import { flowConditionsConfigReducer } from './conditions'
import { flowAttributesConfigReducer } from './attributes/FlowAttributesConfig.slice'
import { flowConsequencesConfigReducer } from './consequences/FlowConsequencesConfig.slice'
import { flowStepsConfigReducer } from './steps'

const flowConfigReducer = combineReducers({
  general: flowGeneralConfigReducer,
  attributes: flowAttributesConfigReducer,
  decisions: flowDecisionsConfigReducer,
  conditions: flowConditionsConfigReducer,
  consequences: flowConsequencesConfigReducer,
  steps: flowStepsConfigReducer,
})

export default wrapWithClear(flowConfigReducer, flowConfigClear)
