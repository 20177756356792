import { BackendLoading } from 'redux/redux.shared'

export const loadingTypes = ['fetchProjectConfigFinishedSteps', 'addProjectConfigFinishedStep'] as const
export type LoadingTypes = typeof loadingTypes[number]

export const steps = [
  'GENERAL',
  'STAGES',
  'NAMING_SCHEME',
  'DISCIPLINES',
  'METADATA',
  'ENTRIES_STRUCTURE',
  'USERS',
  'PERMISSIONS',
  'SUITABILITY_CODES',
  'ORGANIZATION_GROUPS',
  'MAP_SETTINGS',
  'ACTIVATION',
] as const

export type Step = typeof steps[number]

export interface ProjectStatusConfigState {
  loading: Record<LoadingTypes, BackendLoading>
  finishedSteps: Step[]
  finishedStepsLoaded: boolean
}
