import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import { removedErrorsFromLoading } from 'helpers/HttpError.helpers'
import { SuitabilityCode } from 'models/administration'
import { createAsyncBackendThunk, generateExtraBackendReducers, generateInitialLoadingState } from 'redux/redux.shared'
import {
  loadingTypes,
  LoadingTypes,
  ProjectSuitabilityConfigState,
  SelectedStatus,
} from './ProjectSuitabilityConfig.types'

export const fetchSuitabilityOverview = createAsyncBackendThunk('fetchSuitabilityOverview', async () => {
  return (await backendAxios.get('/suitability/overview/')).data
})

const initialState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  codes: [],
  selectedStatus: 'ALL',
} as ProjectSuitabilityConfigState

const projectSuitabilityConfigSlice = createSlice({
  name: 'projectSuitability',
  initialState,
  reducers: {
    setSelectedStatus: (state, action: PayloadAction<SelectedStatus>) => {
      state.selectedStatus = action.payload
    },
  },
  extraReducers: generateExtraBackendReducers<ProjectSuitabilityConfigState, LoadingTypes, SuitabilityCode[]>({
    promise: fetchSuitabilityOverview,
    loadingType: 'fetchSuitabilityOverview',
    onPending: (state) => {
      state.loading = removedErrorsFromLoading(state.loading)
    },
    onFulfilled: (state, action) => {
      state.codes = action.payload
    },
  }),
})

export const { setSelectedStatus } = projectSuitabilityConfigSlice.actions

export const projectSuitabilityConfigReducer = projectSuitabilityConfigSlice.reducer
