import { FunctionComponent } from 'react'
import { ScreenSpaceEvent, ScreenSpaceEventHandler } from 'resium'
import { Cartesian2, ScreenSpaceEventType } from 'cesium'

interface EventsHandlerProps {
  onLeftClick: (event: { position: Cartesian2 }) => void
}

const EventsHandler: FunctionComponent<EventsHandlerProps> = ({ onLeftClick }) => {
  return (
    <ScreenSpaceEventHandler useDefault={false}>
      <ScreenSpaceEvent type={ScreenSpaceEventType.LEFT_DOWN} action={onLeftClick} />
    </ScreenSpaceEventHandler>
  )
}

export default EventsHandler
