import { Trans } from '@lingui/macro'
import BaseFormItem from 'components/molecules/form-item/BaseFormItem'
import YesNoRadioGroup from 'components/molecules/radio/YesNoRadioGroup'

const FileRequiredRadio: React.FC = () => {
  return (
    <BaseFormItem
      label={<Trans id="flow_config.consequences.file_required_radio_label">Is the review file required?</Trans>}
      tooltip={
        <Trans id="flow_config.consequences.file_required_radio_tooltip">
          If &quot;Yes&quot; is selected, then file reviewer will be forced to upload review file.
        </Trans>
      }
      name="fileUploadRequired"
    >
      <YesNoRadioGroup />
    </BaseFormItem>
  )
}

export default FileRequiredRadio
