import { InteractionOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import ButtonWithTooltip from 'components/molecules/buttons'
import { resendLink } from 'redux/administration/administration-users/AdministrationUsers.slice'
import { useAppDispatch } from 'redux/store'

interface ActionProps {
  id: number
}

const LinkResendButton: React.FC<ActionProps> = ({ id }) => {
  const dispatch = useAppDispatch()
  return (
    <ButtonWithTooltip
      onClick={async () => {
        if (id) {
          dispatch(resendLink(id))
        }
      }}
      tooltipText={t({
        id: 'administration.users.buttons.resend_account_activation_code_text',
        message: 'Resend activation code',
      })}
      icon={<InteractionOutlined />}
      withLoading
    />
  )
}

export default LinkResendButton
