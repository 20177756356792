import { useState } from 'react'
import Text from 'components/atoms/text'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import styled, { Color } from 'styled-components'
import { useSelector } from 'react-redux'
import { getSchemeData } from 'redux/administration/administration-naming-scheme/AdministrationNamingScheme.helpers'
import { selectAdministrationCodesSettingsDict } from 'redux/administration/administration-code-settings/AdministrationCodeSettings.selectors'
import { Space } from 'antd'
import ActivableText from 'components/molecules/activable-text'
import { Attribute, NamingSchemeElemenentArg, NamingSchemeElement } from 'models/administration'
import TooltipElement from 'components/molecules/tooltip-element'
import isFieldInShortName from 'helpers/NamingScheme.helpers'
import { Trans } from '@lingui/macro'
import { moveArrayItemToNewIndex } from 'helpers/Collection.helpers'

const StyledDiv = styled.div`
  display: flex;
  gap: ${(props) => props.theme.sizes.xs};
  align-items: center;
  overflow-x: auto;
`

interface SchemeOrderDraggerProps {
  attributes: Attribute[]
  scheme?: NamingSchemeElement[]
  disableDragging?: boolean
  onChange?: (value: NamingSchemeElement[]) => void
}

const getElementColor: (isDisabled: boolean, args?: NamingSchemeElemenentArg) => Color = (isDisabled, args) => {
  const fieldInShortName = isFieldInShortName(args)
  if (isDisabled) {
    return fieldInShortName ? 'grayG04' : 'grayG02'
  }
  return fieldInShortName ? 'primaryP03' : 'primaryP01'
}

const StyledSpace = styled(Space)`
  max-width: 100%;
`

const SchemeOrderDragger: React.FC<SchemeOrderDraggerProps> = ({
  attributes,
  scheme,
  disableDragging = false,
  onChange,
}) => {
  const [draggingElementIdx, setDraggingElementIdx] = useState<number | null>()
  const settings = useSelector(selectAdministrationCodesSettingsDict)

  if (scheme === undefined || attributes.length === 0) return null
  const schemeData = getSchemeData(scheme, attributes, settings)

  return (
    <StyledSpace direction="vertical">
      <Text textStyle="regularText-small">
        <Trans id="scheme_order_dragger.naming_schemes.sample_filename_text">Sample filename:</Trans>
      </Text>
      <DragDropContext
        onDragStart={({ source }) => {
          setDraggingElementIdx(source.index)
        }}
        onDragEnd={({ source, destination }) => {
          setDraggingElementIdx(null)
          if (onChange && destination && destination.index !== scheme.length - 1) {
            onChange(moveArrayItemToNewIndex(scheme ? [...scheme] : [], source.index, destination.index))
          }
        }}
      >
        <Droppable droppableId="droppable" direction="horizontal">
          {(droppableProvided) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <StyledDiv ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
              {schemeData.map(({ name, code, type, args }, idx) => {
                const isRevision = type === 'revision'
                const isDragDisabled = isRevision || disableDragging
                const key = `${name}_${code}`
                return (
                  <Draggable key={key} draggableId={key} index={idx} isDragDisabled={isDragDisabled}>
                    {(draggableProvided) => (
                      <div
                        ref={draggableProvided.innerRef}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...draggableProvided.draggableProps}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...draggableProvided.dragHandleProps}
                        style={draggableProvided.draggableProps.style}
                      >
                        <Space size="small">
                          <TooltipElement placement="top" title={name}>
                            <ActivableText activeColor={getElementColor(isDragDisabled, args)} isActive>
                              {code}
                            </ActivableText>
                          </TooltipElement>
                          {idx !== schemeData.length - 1 && idx !== draggingElementIdx && <span>-</span>}
                        </Space>
                      </div>
                    )}
                  </Draggable>
                )
              })}
              {droppableProvided.placeholder}
            </StyledDiv>
          )}
        </Droppable>
      </DragDropContext>
    </StyledSpace>
  )
}

export default SchemeOrderDragger
