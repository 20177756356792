import styled from 'styled-components'
import ShowLocation from 'components/molecules/map/show-location'
import MapToolBarPerspectiveSwitcher from './MapToolBarPerspectiveSwitcher'
import MapToolBarOptions from './MapToolBarOptions'

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  > * {
    position: relative;
    margin-top: ${(props) => props.theme.sizes.default};
    @media (max-height: 750px) {
      margin-top: ${(props) => props.theme.sizes.s};
    }
    &:nth-child(2) {
      margin-top: auto;
    }
    &:last-child {
      margin-bottom: ${(props) => props.theme.sizes.l};
      @media (max-height: 750px) {
        margin-bottom: ${(props) => props.theme.sizes.m};
      }
    }
  }
`

const MapToolBar: React.FC = () => {
  return (
    <StyledDiv>
      <MapToolBarOptions />
      <ShowLocation />
      <MapToolBarPerspectiveSwitcher />
      {/* <Zoom /> */}
    </StyledDiv>
  )
}

export default MapToolBar
