import { useSelector } from 'react-redux'
import { selectIsProjectMembersPermissionsConfigContentLoading } from 'redux/project/project-config/members-permissions/ProjectMembersPermissionsConfig.selectors'
import { updateMembersPermissions } from 'redux/project/project-config/members-permissions/ProjectMembersPermissionsConfig.slice'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import BaseProjectConfigView from '../base-config-view/BaseProjectConfigView'
import ProjectMembersPermissionsConfigContent from './content'

const ProjectMembersPermissionsConfig: React.FC = () => {
  const projectId = useSelector(selectProjectId)
  const isContentLoading = useSelector(selectIsProjectMembersPermissionsConfigContentLoading)
  return (
    <BaseProjectConfigView
      content={<ProjectMembersPermissionsConfigContent />}
      saveStepThunk={updateMembersPermissions(projectId as number)}
      isContentLoading={isContentLoading}
    />
  )
}

export default ProjectMembersPermissionsConfig
