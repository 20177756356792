import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import { ProjectActivationData, ProjectConfigCompletion } from 'models/project/projectConfig'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import {
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  combine,
  generateInitialLoadingState,
  getStateAsAny,
} from 'redux/redux.shared'
import { addProjectConfigFinishedStep } from '../status/ProjectStatusConfig.slice'
import { selectNewProjectActivationTimestamp } from './ProjectActivationConfig.selectors'
import { loadingTypes, LoadingTypes, ProjectActivationConfigState } from './ProjectActivationConfig.types'

export const activateProject = createAsyncBackendThunk('general/activateProject', async (args, { getState }) => {
  const state = getStateAsAny(getState)
  const projectId = selectProjectId(state)
  await backendAxios.post(`/projects/${projectId}/activate/`)
})

export const scheduleProjectActivation = createAsyncBackendThunk(
  'activation/scheduleProjectActivation',
  async (args, { getState, dispatch }) => {
    const state = getStateAsAny(getState)
    const projectId = selectProjectId(state) as number
    const activationTimestamp = selectNewProjectActivationTimestamp(state)
    await backendAxios.post(`/projects/${projectId}/activate/schedule/`, { timestamp: activationTimestamp })
    await dispatch(addProjectConfigFinishedStep({ projectId, step: 'ACTIVATION' }))
  }
)

export const removeScheduledProjectActivation = createAsyncBackendThunk(
  'activation/removeScheduledProjectActivation',
  async (args, { getState }) => {
    const state = getStateAsAny(getState)
    const projectId = selectProjectId(state)
    await backendAxios.delete(`/projects/${projectId}/activate/`)
  }
)

export const fetchProjectActivation = createAsyncBackendThunk(
  'activation/fetchProjectActivation',
  async (projectId: number) => {
    return (await backendAxios.get(`/projects/${projectId}/activate/`)).data
  }
)

const initialState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  configCompletionType: 'runProjectImmediately',
  activationData: {
    isScheduled: false,
  },
} as ProjectActivationConfigState

const projectActivationConfigSlice = createSlice({
  name: 'activation',
  initialState,
  reducers: {
    setConfigCompletionType: (state, action: PayloadAction<ProjectConfigCompletion>) => {
      state.configCompletionType = action.payload
    },
    setNewProjectActivationTime: (state, action: PayloadAction<string>) => {
      state.newActivationDatetime = action.payload
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<ProjectActivationConfigState, LoadingTypes>({
      promise: activateProject,
      loadingType: 'activateProject',
    }),
    generateExtraBackendReducers<ProjectActivationConfigState, LoadingTypes>({
      promise: scheduleProjectActivation,
      loadingType: 'scheduleProjectActivation',
    }),
    generateExtraBackendReducers<ProjectActivationConfigState, LoadingTypes>({
      promise: removeScheduledProjectActivation,
      loadingType: 'removeScheduledProjectActivation',
    }),
    generateExtraBackendReducers<ProjectActivationConfigState, LoadingTypes, ProjectActivationData>({
      promise: fetchProjectActivation,
      loadingType: 'fetchProjectActivation',
      onFulfilled: (state, action) => {
        state.activationData = action.payload
        if (action.payload.isScheduled) {
          state.configCompletionType = 'runAtCustomTime'
          state.newActivationDatetime = action.payload.details?.executionTimestamp
        }
      },
    }),
  ]),
})

export const { setConfigCompletionType, setNewProjectActivationTime } = projectActivationConfigSlice.actions

export const projectActivationConfigReducer = projectActivationConfigSlice.reducer
