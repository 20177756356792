import { Backup, BackupSettings } from 'models/administration'
import { BackendLoading } from 'redux/redux.shared'

export const loadingTypes = [
  'fetchSystemBackupsList',
  'createBackup',
  'deleteBackup',
  'importBackupFromFile',
  'restoreBackup',
  'fetchBackupSettings',
  'editBackupSettings',
] as const
export type LoadingTypes = typeof loadingTypes[number]

interface ImportModalData {
  isVisible: boolean
}

export interface WarningData {
  nextBackup: string
  isInProgress: boolean
}

export interface AdministrationBackupsState {
  loading: Record<LoadingTypes, BackendLoading>
  backups: Backup[]
  settings?: BackupSettings
  importModalData: ImportModalData
  isCreateBackupModalVisible: boolean
  isSettingsModalVisible: boolean
}
