import { Trans } from '@lingui/macro'
import { Space, Spin } from 'antd'
import Text from 'components/atoms/text'
import ButtonElement from 'components/molecules/button-element'
import { memo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectFetchDisciplinesLoading } from 'redux/project/project-config/disciplines/ProjectDisciplinesConfig.selectors'
import { fetchDisciplines, setAddingNewDiscipline } from 'redux/project/project-config/disciplines'
import { useAppDispatch } from 'redux/store'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import ProjectDisciplinesConfigAlerts from './ProjectDisciplinesConfigAlerts'
import ProjectDisciplinesConfigTable from './ProjectDisciplinesConfigTable'

const ProjectDisciplinesConfigContent: React.FC = memo(() => {
  const dispatch = useAppDispatch()
  const { isLoading } = useSelector(selectFetchDisciplinesLoading)
  const projectId = useSelector(selectProjectId)
  useEffect(() => {
    if (projectId) dispatch(fetchDisciplines(projectId))
  }, [dispatch, projectId])
  return (
    <>
      <Text textStyle="regularText-medium">
        <Trans id="project_config.disciplines.step_header">Disciplines settings</Trans>
      </Text>
      <Spin spinning={isLoading}>
        <Space direction="vertical">
          <ProjectDisciplinesConfigTable />
          <ButtonElement onClick={() => dispatch(setAddingNewDiscipline(true))} type="secondary" size="medium">
            <Trans id="project_config.disciplines.add_button">Add discipline</Trans>
          </ButtonElement>
        </Space>
      </Spin>
      <ProjectDisciplinesConfigAlerts />
    </>
  )
})

export default ProjectDisciplinesConfigContent
