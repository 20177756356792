import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import { BACKEND_SERVER_URL } from 'environment/environment'
import { generateExtraBackendReducers, generateInitialLoadingState } from 'redux/redux.shared'
import { FilesViewerLoadingTypes, filesViewerLoadingTypes, FilesViewerState } from './FilesViewer.types'

interface ShowFileViewerModalProps {
  fileId: number
  fileName: string
}

export const showFileViewerModal = createAsyncThunk(
  'fileViewer/showViewerModal',
  async ({ fileId }: ShowFileViewerModalProps) => {
    const token = (await backendAxios.get(`files/${fileId}/token_for_download/`)).data
    return `${BACKEND_SERVER_URL}/files/${fileId}/?token=${token}&original_filename=false`
  }
)

const initialState: FilesViewerState = {
  loading: generateInitialLoadingState<FilesViewerLoadingTypes>(filesViewerLoadingTypes),
  isModalVisible: false,
}

const filesViewerSlice = createSlice({
  name: 'fileViewer',
  initialState,
  reducers: {
    hideFileViewerModal: (state) => {
      state.isModalVisible = false
    },
  },
  extraReducers: generateExtraBackendReducers<
    FilesViewerState,
    FilesViewerLoadingTypes,
    string,
    ShowFileViewerModalProps
  >({
    promise: showFileViewerModal,
    loadingType: 'showFileViewerModal',
    onPending: (state) => {
      state.isModalVisible = true
      state.fileLink = undefined
      state.fileName = undefined
    },
    onFulfilled: (state, action) => {
      state.fileLink = action.payload
      state.fileName = action.meta.arg.fileName
    },
  }),
})

export const filesViewerReducer = filesViewerSlice.reducer

export const { hideFileViewerModal } = filesViewerSlice.actions
