import { t } from '@lingui/macro'
import { SearchProps } from 'antd/lib/input'
import FormInputSearch from 'components/molecules/form-item/FormInputSearch'
import { setNotificationsSearchValue } from 'redux/notifications'
import { useAppDispatch } from 'redux/store'

const NotificationsSearchInput: React.FC = () => {
  const dispatch = useAppDispatch()
  const handleInputSearch: SearchProps['onSearch'] = (value) => dispatch(setNotificationsSearchValue(value))
  return (
    <FormInputSearch
      placeholder={t({ id: 'notifications.search_placeholder', message: 'Enter text to search for' })}
      onSearch={handleInputSearch}
    />
  )
}

export default NotificationsSearchInput
