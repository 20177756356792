import { useEffect, useState } from 'react'

function useInfiniteScroll<T>(
  data: T[],
  loadCount: number
): { currentData: T[]; getMoreData: () => void; hasMore: boolean } {
  const [currentlyVisibleCount, setCurrentlyVisibleCount] = useState(loadCount)
  const [hasMore, setHasMore] = useState(true)
  const [currentData, setCurrentData] = useState(data.slice(0, currentlyVisibleCount))

  const getMoreData = () => {
    if (currentData.length >= data.length) {
      setHasMore(false)
      return
    }
    setCurrentlyVisibleCount((prev) => prev + loadCount)
  }

  useEffect(() => {
    let displayedItemsCount = currentlyVisibleCount
    const dataLength = data.length
    setHasMore(true)
    if (dataLength < currentlyVisibleCount) {
      setCurrentlyVisibleCount(dataLength)
      setHasMore(false)
      displayedItemsCount = dataLength
    }
    setCurrentData(() => [...data.slice(0, displayedItemsCount)])
  }, [data, currentlyVisibleCount])

  return { currentData, getMoreData, hasMore }
}

export default useInfiniteScroll
