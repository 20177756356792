import { Trans } from '@lingui/macro'
import { ColumnsType } from 'antd/lib/table'
import TextBox from 'components/atoms/text-box/TextBox'
import { ProjectStage } from 'models/administration'
import { useSelector } from 'react-redux'
import { selectProjectStages } from 'redux/project/project-config/stages/ProjectStagesConfig.selectors'
import StageStatus from './StageStatus'

const useStagesOrderColumns = (): ColumnsType<ProjectStage> => {
  const currentStage = useSelector(selectProjectStages)?.current
  return [
    {
      title: <Trans id="project_config.stages.table.column_stages_label">Stages</Trans>,
      key: 'stages',
      render: (val, { name, id }, idx) => <TextBox isActive={currentStage === id} number={idx + 1} text={name} />,
    },
    {
      title: <Trans id="project_config.stages.table.column_status_label">Status</Trans>,
      key: 'status',
      width: 75,
      render: (val, { id }) => <StageStatus stageId={id} />,
      align: 'center',
    },
  ]
}

export default useStagesOrderColumns
