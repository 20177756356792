import styled from 'styled-components'
import { useSelector } from 'react-redux'
import DmsCoordinates from 'dms-conversion'
import Proj4 from 'proj4'
import { selectMousePositionCoordinates } from 'redux/cesium-viewer/CesiumViewer.selectors'
import {
  selectFetchMapProjectionsLoading,
  selectFetchProjectDetailsLoading,
  selectMapCurrentProjection,
  selectProjectMapSettings,
} from 'redux/project/project-details/ProjectDetails.selectors'
import CoordinatePanelItem from 'components/molecules/coordinates-panel'
import Text from 'components/atoms/text'
import SkeletonButton from 'antd/lib/skeleton/Button'
import MapScalePanelItem from 'components/molecules/coordinates-panel/MapScalePanelItem'
import CoordinatesSystemItem from 'components/molecules/coordinates-panel/CoordinatesSystemItem'
import { Trans } from '@lingui/macro'

type TextType = 'regularText-small' | 'regularText-smallSemiBold'

export interface Coordinate {
  name: string
  value: string | number
  nameStyle: TextType
  valueStyle: TextType
  isLoading?: boolean
}

const StyledUl = styled.ul`
  display: flex;
  flex-grow: 1;
  > * {
    margin-left: ${(props) => props.theme.sizes.default};
    display: flex;
    align-items: center;
    &:last-child {
      margin-left: auto;
    }
  }
`

const CoordinatesPanelItems: React.FC = () => {
  const mousePositionCoordinates = useSelector(selectMousePositionCoordinates)
  const mapSettings = useSelector(selectProjectMapSettings)
  const { isLoading } = useSelector(selectFetchProjectDetailsLoading)
  const { isLoading: isProjectionsLoading } = useSelector(selectFetchMapProjectionsLoading)
  const currentProjection = useSelector(selectMapCurrentProjection)

  const dmsCoords = new DmsCoordinates(mousePositionCoordinates.latitude, mousePositionCoordinates.longitude)
  const { longitude, latitude } = dmsCoords.dmsArrays
  const [longitudeD, longitudeM, longitudeS, longitudeNsew] = longitude
  const [latitudeD, latitudeM, latitudeS, latitudeNsew] = latitude

  const scale = mapSettings && mapSettings.scale.toFixed()

  const toProjection =
    currentProjection?.mapping ??
    `+proj=tmerc +lat_0=0 +lon_0=19 +k=0.9993 +x_0=500000 +y_0=-5300000 '
  '+ellps=GRS80 +units=m +no_defs`

  const position = Proj4(toProjection, {
    x: mousePositionCoordinates.longitude,
    y: mousePositionCoordinates.latitude,
  })
  const coordinates: Coordinate[] = [
    {
      name: 'X',
      value: position.y.toFixed(2),
      nameStyle: 'regularText-smallSemiBold',
      valueStyle: 'regularText-small',
    },
    {
      name: 'Y',
      value: position.x.toFixed(2),
      nameStyle: 'regularText-smallSemiBold',
      valueStyle: 'regularText-small',
    },
    {
      name: latitudeNsew,
      value: `${latitudeD}° ${latitudeM}' ${latitudeS.toFixed(2)}"`,
      nameStyle: 'regularText-smallSemiBold',
      valueStyle: 'regularText-small',
    },
    {
      name: longitudeNsew,
      value: `${longitudeD}° ${longitudeM}' ${longitudeS.toFixed(2)}"`,
      nameStyle: 'regularText-smallSemiBold',
      valueStyle: 'regularText-small',
    },
  ]
  return (
    <StyledUl>
      {!isProjectionsLoading ? (
        <CoordinatesSystemItem system={currentProjection?.name ?? ''} />
      ) : (
        <SkeletonButton active size="small" />
      )}
      {coordinates.map((coordinate) =>
        coordinate.isLoading ? (
          <SkeletonButton active size="small" />
        ) : (
          <CoordinatePanelItem
            key={coordinate.name}
            name={<Text textStyle={coordinate.nameStyle}>{coordinate.name}:</Text>}
            value={<Text textStyle={coordinate.valueStyle}>{coordinate.value}</Text>}
          />
        )
      )}
      {!isLoading ? (
        <MapScalePanelItem
          name={
            <Text textStyle="regularText-small">
              <Trans id="coordinates_panel.map_scale_label">Scale</Trans>
            </Text>
          }
          value={<Text textStyle="regularText-smallSemiBold">1:{scale}</Text>}
        />
      ) : (
        <SkeletonButton active size="small" />
      )}
    </StyledUl>
  )
}

export default CoordinatesPanelItems
