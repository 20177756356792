import styled from 'styled-components'
import Text from 'components/atoms/text'
import Divider from 'components/atoms/divider'
import { DetailsItemWithRender } from 'models/files'
import themePxValueToNumber from 'helpers/Styles.helpers'

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  > * {
    &:not(:last-child) {
      margin: 0 ${(props) => props.theme.sizes.m};
    }

    &:first-child {
      margin-bottom: ${(props) => props.theme.sizes.xs};
    }
  }
`

const ScrollWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  max-height: ${(props) => `${themePxValueToNumber(props.theme.sizes.default) * 3}px`};
  overflow-y: auto;
  pointer-events: auto;
`

const FilesDetailsItem: React.FC<DetailsItemWithRender> = ({ name, value, renderValue }) => {
  return (
    <StyledDiv>
      <Text textStyle="regularText-small" color="grayG06">
        {name}
      </Text>
      {renderValue ? (
        renderValue(value.toString())
      ) : (
        <ScrollWrapper>
          <Text textStyle="regularText-small">{value}</Text>
        </ScrollWrapper>
      )}
      <Divider size="xs" />
    </StyledDiv>
  )
}

export default FilesDetailsItem
