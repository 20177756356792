import { Organization } from 'models/administration'
import { CdeStatus } from 'models/files'
import { FlowConditionsConfig, FlowUsersTypeRadioOption } from 'models/flow'
import { selectFetchingAvailableDisciplinesLoading } from 'redux/project/project-config/members-permissions/ProjectMembersPermissionsConfig.selectors'
import { selectProjectSuitabilityOverviewLoading } from 'redux/project/project-config/suitability/ProjectSuitabilityConfig.selectors'
import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import { selectGettingMembersConfigLoading } from '../decisions/FlowDecisionsConfig.selectors'
import { FlowConditionsConfigState } from './FlowConditionsConfig.types'

const selectFlowConditionsConfigState = (state: RootState): FlowConditionsConfigState => state.flowConfig.conditions

export const selectFlowConditionsConfig = (state: RootState): FlowConditionsConfig | undefined =>
  selectFlowConditionsConfigState(state).flowConditionsConfig

export const selectFlowConditionsInitialCdeStatuses = (state: RootState): CdeStatus[] | undefined =>
  selectFlowConditionsConfigState(state).flowConditionsConfig?.initialCdeStatuses

export const selectOrganizations = (state: RootState): Organization[] =>
  selectFlowConditionsConfigState(state).organizations

export const selectFlowConditionsConfigValidity = (state: RootState): boolean => {
  const conditionsConfig = selectFlowConditionsConfig(state)
  if (!conditionsConfig) return false

  const isUsersListEmpty = conditionsConfig?.initialUsersList.length === 0
  const usersTypes = conditionsConfig?.initialUsersTypes as string[]
  const isUsersTypesEmpty = conditionsConfig?.initialUsersTypes.length === 0
  const isSelectingSpecificUsers = !!usersTypes?.includes('SELECTED')

  return isSelectingSpecificUsers ? !isUsersListEmpty : !isUsersTypesEmpty
}

export const selectFlowUsersTypeRadioOption = (state: RootState): FlowUsersTypeRadioOption => {
  const conditionsConfig = selectFlowConditionsConfig(state)
  const usersTypes = conditionsConfig?.initialUsersTypes as string[]
  const isSelectingSpecificUsers = !!usersTypes?.includes('SELECTED')
  const isSelectingAllUsers = !!usersTypes?.includes('ALL')
  if (isSelectingSpecificUsers) return 'SELECTED'
  if (isSelectingAllUsers) return 'ALL'
  return 'PERMISSIONS'
}

export const selectGettingFlowConditionsLoading = (state: RootState): BackendLoading =>
  selectFlowConditionsConfigState(state).loading.getFlowConditions

export const selectGettingOrganizationsLoading = (state: RootState): BackendLoading =>
  selectFlowConditionsConfigState(state).loading.getOrganizations

export const selectUpdatingFlowConditionsLoading = (state: RootState): BackendLoading =>
  selectFlowConditionsConfigState(state).loading.updateFlowConditions

export const selectIsConditionsConfigContentLoading = (state: RootState): boolean =>
  selectGettingFlowConditionsLoading(state).isLoading ||
  selectGettingOrganizationsLoading(state).isLoading ||
  selectFetchingAvailableDisciplinesLoading(state).isLoading ||
  selectProjectSuitabilityOverviewLoading(state).isLoading ||
  selectGettingMembersConfigLoading(state).isLoading ||
  selectUpdatingFlowConditionsLoading(state).isLoading
