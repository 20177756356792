import { defineMessage } from '@lingui/macro'

export const errorText = defineMessage({ id: 'common.controls.error_text', message: 'Error' })
export const successText = defineMessage({ id: 'common.controls.success_text', message: 'Success' })
export const emptyFieldError = defineMessage({
  id: 'common.controls.empty_field_error',
  message: 'This field is required',
})
export const invalidCodeError = defineMessage({
  id: 'common.controls.invalid_code_error',
  message: 'Invalid code',
})
export const saveText = defineMessage({ id: 'common.controls.save_text', message: 'Save' })
export const saveChangesText = defineMessage({ id: 'common.controls.save_changes_text', message: 'Save changes' })
export const addText = defineMessage({ id: 'common.controls.add_text', message: 'Add' })
export const cancelText = defineMessage({ id: 'common.controls.cancel_text', message: 'Cancel' })
export const stepText = defineMessage({ id: 'common.controls.step_text', message: 'Step' })
export const deleteText = defineMessage({ id: 'common.controls.delete_text', message: 'Delete' })
export const confirmText = defineMessage({ id: 'common.controls.confirm_text', message: 'Confirm' })
export const okText = defineMessage({ id: 'common.controls.ok_text', message: 'OK' })
export const closeText = defineMessage({ id: 'common.controls.close_text', message: 'Close' })
export const nextText = defineMessage({ id: 'common.controls.next_text', message: 'Next' })
export const previousStepText = defineMessage({ id: 'common.controls.previous_step_text', message: 'Previous step' })
export const selectText = defineMessage({ id: 'common.controls.select_text', message: 'Select' })
export const noChangesText = defineMessage({ id: 'common.controls.no_changes_text', message: 'No changes' })
export const anyValueText = defineMessage({ id: 'common.controls.any_value_text', message: 'Any value' })
export const allAvailableValuesText = defineMessage({
  id: 'common.controls.all_available_values_text',
  message: 'All available values',
})
export const actionsTableColumnText = defineMessage({
  id: 'common.controls.actions_table_column_text',
  message: 'Actions',
})
