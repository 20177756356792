import { Notification } from 'models/notifications'
import notificationNameDict from 'data/notifications'
import trans from 'helpers/i18n.helpers'

export const markNotificationsAsRead = (notifications: Notification[], ids: number[]): Notification[] =>
  notifications.map((notification) => ({
    ...notification,
    isRead: !!ids.find((id) => id === notification.id) || notification.isRead,
  }))

export const mapWithNotificationNameAndKey = (notifications: Notification[]): Notification[] =>
  notifications.map((notification) => ({
    ...notification,
    key: notification.id,
    message: trans(notificationNameDict[notification.message]),
  }))
