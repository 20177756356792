import { BackendLoading } from 'redux/redux.shared'
import { RootState } from '../store'
import { GlobalPermissions, UserAccountData } from './Login.types'

export const selectIsAuthenticated = (state: RootState): boolean => state.login.isAuthenticated
export const selectLoginUserLoading = (state: RootState): BackendLoading => state.login.loading.loginUser
export const selectInitialTokenRefreshLoading = (state: RootState): BackendLoading =>
  state.login.loading.initialTokenRefresh
export const selectAccessToken = (state: RootState): string | undefined => state.login.accessToken
export const selectIsInitialTokenLoading = (state: RootState): boolean =>
  !state.login.accessToken && !!state.login.refreshToken && !state.login.isAuthenticated
export const selectGlobalPermissions = (state: RootState): GlobalPermissions | undefined => state.login.permissions
export const selectUserAccountData = (state: RootState): UserAccountData | undefined => state.login.accountData

export const selectRedirectLink = (state: RootState): string | undefined => state.login.redirectLink
