import styled from 'styled-components'
import { Checkbox } from 'antd'
import { CheckboxProps } from 'antd/lib/checkbox'
import Text from 'components/atoms/text'

interface CheckboxElementProps
  extends CheckboxProps,
    Omit<React.ComponentProps<typeof Text>, 'children' | 'textStyle'> {
  textStyle?: React.ComponentProps<typeof Text>['textStyle']
  noEvents?: boolean
}

interface StyledCheckboxProps {
  $noEvents?: boolean
}

const StyledCheckbox = styled(Checkbox)<StyledCheckboxProps>`
  && {
    ${(props) => (props.$noEvents ? 'pointer-events: none;' : '')}
  }
`

const CheckboxElement: React.FC<CheckboxElementProps> = ({
  children,
  textStyle = 'regularText-small',
  color = 'baseBlackish',
  onChange,
  checked = false,
  indeterminate = false,
  disabled,
  noEvents,
  className,
}) => {
  return (
    <StyledCheckbox
      $noEvents={noEvents}
      disabled={disabled}
      onChange={onChange}
      checked={checked}
      indeterminate={indeterminate}
      className={className}
    >
      {children && (
        <Text textStyle={textStyle} color={color} ellipsis>
          {children}
        </Text>
      )}
    </StyledCheckbox>
  )
}

export default CheckboxElement
