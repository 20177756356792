import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import { CodeSetting, CodeType } from './AdministrationCodeSettings.types'

export const selectAdministrationCodeLengthDict: (state: RootState) => Record<CodeType, number> = (state) =>
  state.administration.codeSettings.codeLengthDict

export const selectAdministrationCodesSettingsDict: (state: RootState) => Record<CodeType, CodeSetting> = (state) =>
  state.administration.codeSettings.codesData

export const selectCodeLengthModalVisibility: (state: RootState) => boolean = (state) =>
  state.administration.codeSettings.modalData.isVisible

export const selectEditingCodeLengthLoading: (state: RootState) => BackendLoading = (state) =>
  state.administration.codeSettings.loading.editCodeLength

export const selectFetchingCodeLengthLoading: (state: RootState) => BackendLoading = (state) =>
  state.administration.codeSettings.loading.fetchCodeLength

export const selectIsFetchingCodeLengthAnyLoading: (state: RootState) => boolean = (state) =>
  state.administration.codeSettings.loading.fetchCodeSettings.isLoading
