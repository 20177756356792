import { Trans } from '@lingui/macro'
import Text from 'components/atoms/text'
import {
  selectAvailableMetadata,
  selectSelectedMetadata,
  selectFetchMetadataLoading,
  selectIsAddingMetadata,
  selectSelectMetadataLoading,
  selectModalVisibility,
} from 'redux/project/project-config/attributes/ProjectAttributesConfig.selectors'
import { fetchMetadata } from 'redux/project/project-config/attributes'
import ButtonElement from 'components/molecules/button-element'
import { memo, useEffect, useState } from 'react'
import SelectElement from 'components/atoms/select-element'
import { useAppDispatch } from 'redux/store'
import { useSelector } from 'react-redux'
import {
  removeMetadata,
  addNewMetadata,
  setAddingMetadata,
  toggleAttributeValue,
} from 'redux/project/project-config/attributes/ProjectAttributesConfig.slice'
import { itemArr } from 'helpers/Collection.helpers'
import { SelectValue } from 'antd/lib/select'
import Table from 'components/molecules/table'
import { selectText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import styled from 'styled-components'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import ProjectAttributesConfigAlerts from './ProjectAttributesConfigAlerts'
import ConfigAttributeRecord from './ProjectAttributesConfig.types'
import ProjectAttributeModal from './modal'
import useAttributesColumns from './useAttributesColumns'

const StyledTable = styled(Table)`
  width: 90%;
  margin: ${({ theme }) => theme.sizes.m} 0;
  td {
    &:nth-child(2),
    &:nth-child(4) {
      text-align: center;
    }
  }
`

const ProjectAttributesConfig: React.FC = memo(() => {
  const dispatch = useAppDispatch()
  const isAddingMetadata = useSelector(selectIsAddingMetadata)
  const attributeModalVisibility = useSelector(selectModalVisibility)
  const metadata = useSelector(selectSelectedMetadata)
  const availableMetadata = useSelector(selectAvailableMetadata)
  const selectMetadataLoading = useSelector(selectSelectMetadataLoading)
  const fetchMetadataLoading = useSelector(selectFetchMetadataLoading)
  const projectId = useSelector(selectProjectId)
  const [dataSource, setDataSource] = useState<ConfigAttributeRecord[]>()

  useEffect(() => {
    if (projectId) {
      dispatch(fetchMetadata(projectId))
    }
  }, [dispatch, projectId])

  useEffect(() => {
    setDataSource([
      ...metadata.map((data) => ({
        key: data.id.toString(),
        attrId: data.id,
        name: data.name,
        nameText: data.name,
        projectValuesAllowed: data.projectValuesAllowed,
        valueCodeLength: data.valueCodeLength,
        attributeValues: data.attributeValues,
        toggleValue: (metadataValueId: SelectValue, isAdding: boolean, metadataId?: number) =>
          dispatch(
            toggleAttributeValue({
              attributeId: metadataId as number,
              isSelected: isAdding,
              valueId: metadataValueId as number,
            })
          ),
        remove: (attrId?: number) => dispatch(removeMetadata(attrId as number)),
        isUsed: data.isUsed,
      })),
      ...itemArr(
        {
          key: 'addAttribute',
          projectValuesAllowed: false,
          valueCodeLength: 0,
          attributeValues: [],
          isUsed: false,
          name: (
            <SelectElement
              loading={selectMetadataLoading.isLoading}
              disabled={selectMetadataLoading.isLoading}
              bordered={false}
              placeholder={trans(selectText)}
              options={availableMetadata.map(({ name, id }) => ({ label: name, value: id }))}
              onChange={(val) => dispatch(addNewMetadata(val as number))}
            />
          ),
          nameText: '',
          remove: () => dispatch(setAddingMetadata(false)),
        },
        isAddingMetadata
      ),
    ])
  }, [metadata, isAddingMetadata, availableMetadata, dispatch, selectMetadataLoading])

  const handleAddAttribute = () => {
    if (!isAddingMetadata) {
      dispatch(setAddingMetadata(true))
    }
  }
  const columns = useAttributesColumns()
  return (
    <>
      <Text textStyle="regularText-medium">
        <Trans id="project_config.metadata.step_header">Attributes settings</Trans>
      </Text>
      <StyledTable columns={columns} dataSource={dataSource} bordered pagination={false} />
      {!fetchMetadataLoading.error && (
        <ButtonElement onClick={handleAddAttribute} type="secondary" size="medium">
          <Trans id="project_config.metadata.add_button">Add attribute</Trans>
        </ButtonElement>
      )}
      <ProjectAttributesConfigAlerts />
      {attributeModalVisibility && <ProjectAttributeModal visible={attributeModalVisibility} />}
    </>
  )
})

export default ProjectAttributesConfig
