import { AttributeValue } from 'models/administration'
import styled from 'styled-components'
import Text from 'components/atoms/text'
import { Menu } from 'antd'
import SpaceElement from 'components/atoms/space'
import themePxValueToNumber from 'helpers/Styles.helpers'

const StyledMenu = styled(Menu)`
  background-color: ${({ theme }) => theme.colors.grayG01};
  max-height: 300px;
  margin-top: -${({ theme }) => themePxValueToNumber(theme.sizes.xxs)}px;
  margin-left: -${({ theme }) => themePxValueToNumber(theme.sizes.xs)}px;
  width: 400px;
  overflow: auto;
`

const StyledSpace = styled(SpaceElement)`
  margin: ${({ theme }) => theme.sizes.s};
  > * {
    height: 20px;
  }
`

interface AttributeParams {
  id: number
  values: AttributeValue[]
  valueCodeLength: number
}

const AttributeValuesListDropdownMenu: React.FC<AttributeParams> = ({ values }) => {
  return (
    <StyledMenu>
      <StyledSpace direction="vertical" size="s">
        {values.map(({ code, value }) => (
          <Text textStyle="regularText-small">{`${code} - ${value}`}</Text>
        ))}
      </StyledSpace>
    </StyledMenu>
  )
}

export default AttributeValuesListDropdownMenu
