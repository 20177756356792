import { Modal, Spin } from 'antd'
import Text from 'components/atoms/text'
import IfcViewer from 'components/organisms/ifc-viewer/IfcViewer'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectGettingLinkToIfcFileLoading,
  selectIfcFileLink,
  selectIfcFileName,
} from 'redux/files/ifc-viewer/FilesViewer.selectors'
import { hideFileViewerModal } from 'redux/files/ifc-viewer/FilesViewer.slice'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .ant-modal-content {
    height: 600px;
    width: 800px;
  }
  .ant-modal-body {
    height: calc(100% - 56px);
    padding: 0;
  }
`

const IfcViewerModal: React.FC = () => {
  const dispatch = useDispatch()
  const { isLoading } = useSelector(selectGettingLinkToIfcFileLoading)
  const link = useSelector(selectIfcFileLink)
  const fileName = useSelector(selectIfcFileName)
  return (
    <StyledModal
      visible
      title={<Text textStyle="header-H2b">{fileName}</Text>}
      onCancel={() => dispatch(hideFileViewerModal())}
      footer={null}
    >
      <Spin spinning={isLoading}>{link && <IfcViewer ifcUrl={link} />}</Spin>
    </StyledModal>
  )
}

export default IfcViewerModal
