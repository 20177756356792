import { FlowConfig } from 'models/flow'
import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import { FlowGeneralConfigState } from './FlowGeneralConfig.types'

const selectFlowGeneralConfigState = (state: RootState): FlowGeneralConfigState => state.flowConfig.general

export const selectFlowConfig = (state: RootState): FlowConfig => selectFlowGeneralConfigState(state).flowConfig

export const selectFlowId = (state: RootState): number | undefined => selectFlowConfig(state).id

export const selectShouldCreateFlow = (state: RootState): boolean =>
  selectFlowGeneralConfigState(state).shouldCreateFlow

export const selectPrevFlowsTableVisibility = (state: RootState): boolean =>
  selectFlowGeneralConfigState(state).prevFlowsTableVisibility

export const selectOriginalFlowName = (state: RootState): string | undefined =>
  selectFlowGeneralConfigState(state).originalName

export const selectCreatingFlowLoading = (state: RootState): BackendLoading =>
  selectFlowGeneralConfigState(state).loading.createFlow

export const selectUpdatingFlowLoading = (state: RootState): BackendLoading =>
  selectFlowGeneralConfigState(state).loading.updateFlow

export const selectGettingFlowLoading = (state: RootState): BackendLoading =>
  selectFlowGeneralConfigState(state).loading.getFlow

export const selectPrevFlowsValidity = (state: RootState): boolean => {
  const prevFlowsRequired = selectPrevFlowsTableVisibility(state)
  const prevFlowsSelected = selectFlowConfig(state).prevFlows
  return !prevFlowsRequired || prevFlowsSelected.length > 0
}

export const selectIsGeneralConfigContentLoading = (state: RootState): boolean =>
  selectCreatingFlowLoading(state).isLoading ||
  selectUpdatingFlowLoading(state).isLoading ||
  selectGettingFlowLoading(state).isLoading
