import { Attribute } from 'models/administration'
import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'

export const selectAdministrationAttributes: (state: RootState) => Attribute[] = (state) =>
  state.administration.attributes.attributes

export const getSelectAttributeByIdSelector = (id: number) => (state: RootState): Attribute | undefined =>
  selectAdministrationAttributes(state).find((attr) => attr.id === id)

export const selectFetchingAttributesLoading: (state: RootState) => BackendLoading = (state) =>
  state.administration.attributes.loading.fetchAttributes

export const selectAddingAttributeLoading: (state: RootState) => BackendLoading = (state) =>
  state.administration.attributes.loading.addAttribute

export const selectEditingAttributeLoading: (state: RootState) => BackendLoading = (state) =>
  state.administration.attributes.loading.editAttribute

export const selectRemovingAttributeLoading: (state: RootState) => BackendLoading = (state) =>
  state.administration.attributes.loading.removeAttribute

export const selectRemovingAttributesValueLoading: (state: RootState) => BackendLoading = (state) =>
  state.administration.attributes.loading.removeAttributeValue

export const selectIsAttributeModalVisible = (state: RootState): boolean =>
  state.administration.attributes.modalData.isModalVisible

export const selectModalAttribute = (state: RootState): Attribute | undefined =>
  state.administration.attributes.modalData.attribute
