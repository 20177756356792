import { SuitabilityCode } from 'models/administration'
import { CdeStatus } from 'models/files'
import { BackendLoading } from 'redux/redux.shared'

export const loadingTypes = ['fetchSuitabilityOverview'] as const
export type LoadingTypes = typeof loadingTypes[number]

export type SelectedStatus = CdeStatus | 'ALL'

export interface ProjectSuitabilityConfigState {
  loading: Record<LoadingTypes, BackendLoading>
  codes: SuitabilityCode[]
  selectedStatus: SelectedStatus
}
