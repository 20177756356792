import { Trans } from '@lingui/macro'
import { Checkbox, Radio, Space } from 'antd'
import BaseFormItem from 'components/molecules/form-item/BaseFormItem'
import trans from 'helpers/i18n.helpers'
import { FlowConditionsConfig, FlowUsersTypeRadioOption, userPermissionsOptions } from 'models/flow'
import { useDispatch } from 'react-redux'
import { setInitialUsersTypes } from 'redux/flow-conifg/conditions'
import { setInitialUsersList } from 'redux/flow-conifg/conditions/FlowConditionsConfig.slice'
import theme from 'styles/Styles.theme'

interface UsersRadioGroupProps {
  flowConditionsConfig: FlowConditionsConfig
  flowUsersTypeRadioOption: FlowUsersTypeRadioOption
}

const UsersRadioGroup: React.FC<UsersRadioGroupProps> = ({ flowConditionsConfig, flowUsersTypeRadioOption }) => {
  const dispatch = useDispatch()
  return (
    <BaseFormItem
      label={<Trans id="flow_config.conditions.users_radio_label">Users who can run the flow</Trans>}
      tooltip={
        <Trans id="flow_config.conditions.users_radio_tooltip">
          The flow will be available only to selected users.
        </Trans>
      }
    >
      <Radio.Group
        value={flowUsersTypeRadioOption}
        onChange={(e) => {
          const val = e.target.value
          if (val === 'ALL' || val === 'SELECTED') dispatch(setInitialUsersTypes([val]))
          else if (val === 'PERMISSIONS') {
            dispatch(setInitialUsersTypes(['AUTHOR']))
          }
          if (val !== 'SELECTED') dispatch(setInitialUsersList([]))
        }}
      >
        <Space direction="vertical">
          <Radio value="PERMISSIONS">
            <Trans id="flow_config.conditions.users_with_specific_permissions_radio">
              Users with specific permissions:
            </Trans>
          </Radio>
          {flowUsersTypeRadioOption === 'PERMISSIONS' && (
            <Checkbox.Group
              style={{ marginLeft: theme.sizes.m }}
              value={flowConditionsConfig?.initialUsersTypes}
              onChange={(val) => {
                dispatch(setInitialUsersTypes(val as FlowConditionsConfig['initialUsersTypes']))
              }}
            >
              <Space direction="vertical">
                {userPermissionsOptions.map(({ value, label }) => (
                  <Checkbox value={value} key={value}>
                    {trans(label)}
                  </Checkbox>
                ))}
              </Space>
            </Checkbox.Group>
          )}
          <Radio value="ALL">
            <Trans id="flow_config.conditions.every_user_with_file_access_radio">
              Everyone with access to the file
            </Trans>
          </Radio>
          <Radio value="SELECTED">
            <Trans id="flow_config.conditions.selected_users_radio">Selected users</Trans>
          </Radio>
        </Space>
      </Radio.Group>
    </BaseFormItem>
  )
}

export default UsersRadioGroup
