import { combineReducers } from 'redux'
import { wrapWithClear } from 'helpers/Functions.helpers'
import { filesClear } from 'redux/action.types'
import projectConfigReducer from './project-config'
import { projectDetailsReducer } from './project-details'

const projectReducer = combineReducers({
  details: projectDetailsReducer,
  config: projectConfigReducer,
})

export default wrapWithClear(projectReducer, filesClear)
