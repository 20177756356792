import styled from 'styled-components'
import Text from 'components/atoms/text'
import { Trans } from '@lingui/macro'
import { useSelector } from 'react-redux'
import { selectReviewDetails, selectIsFlowReviewModalVisible } from 'redux/files/flows/Flows.selectors'
import { Space } from 'antd'

const StyledSpace = styled(Space)`
  width: 365px;
`

const FlowDetailsPanel: React.FC = () => {
  const flow = useSelector(selectReviewDetails)
  const isVisible = useSelector(selectIsFlowReviewModalVisible)
  if (!isVisible || flow === undefined) {
    return null
  }
  const { name, description, command } = flow
  return (
    <StyledSpace direction="vertical" size="large">
      <Space direction="vertical">
        <Text textStyle="regularText-smallSemiBold">
          <Trans id="project.flows.modal.grid.name">Flow name</Trans>
        </Text>
        <Text textStyle="regularText-small">{name}</Text>
      </Space>
      <Space direction="vertical">
        <Text textStyle="regularText-smallSemiBold">
          <Trans id="project.flows.modal.grid.description">Description</Trans>
        </Text>
        <Text textStyle="regularText-small" paragraph>
          {description}
        </Text>
      </Space>
      <Space direction="vertical">
        <Text textStyle="regularText-smallSemiBold">
          <Trans id="project.flows.modal.grid.command">Command</Trans>
        </Text>
        <Text textStyle="regularText-small">{command}</Text>
      </Space>
    </StyledSpace>
  )
}

export default FlowDetailsPanel
