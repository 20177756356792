import { useAppDispatch } from 'redux/store'
import { useSelector } from 'react-redux'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import { removeProjectMember } from 'redux/project/project-config/members/ProjectMembersConfig.slice'
import { DeleteButton } from 'components/molecules/buttons'
import showConfirmationModal from 'helpers/Modals.helpers'
import trans from 'helpers/i18n.helpers'
import { deleteText } from 'data/messages/controls'
import { t } from '@lingui/macro'

interface UserActionsProps {
  id: number
  isAdmin: boolean
  canBeRemoved: boolean
  name: string
}

const UserActions: React.FC<UserActionsProps> = ({ id, isAdmin, canBeRemoved, name }) => {
  const dispatch = useAppDispatch()
  const projectId = useSelector(selectProjectId)
  const onDeleteClick =
    projectId !== undefined && canBeRemoved
      ? () =>
          showConfirmationModal({
            content: t({
              id: 'project_config.members.table.actions.delete_modal',
              message: `Are you sure you want to delete this user: "${name}" from project?`,
            }),
            okText: trans(deleteText),
            onOk: async () => {
              await dispatch(removeProjectMember({ projectId, userId: id }))
            },
          })
      : undefined
  return (
    <DeleteButton
      onClick={onDeleteClick}
      disabled={!canBeRemoved}
      tooltip={t({ id: 'project_config.members.table.actions.delete_tooltip', message: 'Remove user from project' })}
      disabledTooltip={
        isAdmin
          ? t({
              id: 'project_config.members.table.actions.delete_disabled_tooltip.admin',
              message: 'Administrator cannot be removed',
            })
          : t({
              id: 'project_config.members.table.actions.delete_disabled_tooltip.files',
              message: 'User who added files to project cannot be removed',
            })
      }
    />
  )
}

export default UserActions
