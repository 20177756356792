import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import {
  BackendLoading,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  combine,
  generateInitialLoadingState,
  getStateAsAny,
} from 'redux/redux.shared'
import { SettingsFields } from 'models/project/projectDetails'

import { itemArr, findFieldValueByName } from 'helpers/Collection.helpers'
import { selectProjectId, selectProjectName } from 'redux/project/project-details/ProjectDetails.selectors'
import { ProjectStage } from 'models/administration'
import { fetchProjectDetails } from '../../project-details'
import {
  selectCanEnd,
  selectExpectedCompletionDate,
  selectGeneralSettingsFields,
} from './ProjectGeneralConfig.selectors'
import { addProjectConfigFinishedStep } from '../status/ProjectStatusConfig.slice'

const loadingTypes = ['fetchGeneralSettings', 'updateGeneralSettings'] as const
type LoadingTypes = typeof loadingTypes[number]

interface ProjectGeneralConfigState {
  loading: Record<LoadingTypes, BackendLoading>
  generalSettingsFields: SettingsFields
  initialSettingsFetched: boolean
  expectedCompletionDate?: string | null
  canEnd?: boolean
  stages: ProjectStage[]
}

interface ProjectInformationDto {
  expectedCompletionDate: string
  sector: string | null
  location: string
  contractingAuthority: string | null
  contractor: string
}
interface GeneralSettingsDto extends ProjectInformationDto {
  name: string
  isEnded: boolean
}

export const fetchGeneralSettings = createAsyncBackendThunk(
  'general/fetchGeneralSettings',
  async ({ projectId, name, isEnded }: { projectId: number; name: string; isEnded: boolean }) => {
    const response = await backendAxios.get(`/projects/${projectId}/information/`)
    const projectInformation = response.data as ProjectInformationDto
    return { ...projectInformation, name, isEnded }
  }
)
export const updateGeneralSettings = createAsyncBackendThunk(
  'general/updateGeneralSettings',
  async (args, { getState, dispatch }) => {
    const state = getStateAsAny(getState)
    const projectId = selectProjectId(state) as number
    const expectedCompletionDate = selectExpectedCompletionDate(state)
    const projectName = selectProjectName(state)
    const canEnd = selectCanEnd(state)
    const generalSettingsFields = selectGeneralSettingsFields(state)
    const location = findFieldValueByName(generalSettingsFields, 'location')
    const contractingAuthority = findFieldValueByName(generalSettingsFields, 'contractingAuthority')
    const sector = findFieldValueByName(generalSettingsFields, 'sector')
    const isEnded = !!findFieldValueByName(generalSettingsFields, 'isEnded')
    const name = findFieldValueByName(generalSettingsFields, 'name')
    const shouldSendEndRequest = canEnd && isEnded
    const shouldSendUpdateNameRequest = name !== projectName
    await Promise.all([
      backendAxios.patch(`/projects/${projectId}/information/`, {
        expectedCompletionDate,
        location,
        contractingAuthority,
        sector,
      }),
      ...itemArr(
        shouldSendEndRequest ? backendAxios.post(`projects/${projectId}/end/`) : undefined,
        shouldSendEndRequest
      ),
      ...itemArr(
        shouldSendUpdateNameRequest ? backendAxios.patch(`projects/${projectId}/`, { name }) : undefined,
        shouldSendUpdateNameRequest
      ),
    ])
    await dispatch(addProjectConfigFinishedStep({ projectId, step: 'GENERAL' }))
    if (shouldSendEndRequest || shouldSendUpdateNameRequest) {
      dispatch(fetchProjectDetails(projectId))
    }
    return { canEnd: shouldSendEndRequest ? false : canEnd }
  }
)

const initialState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  generalSettingsFields: [],
  initialSettingsFetched: false,
  shouldSettingsUpdate: false,
  stages: [],
} as ProjectGeneralConfigState

const projectGeneralConfigSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setGeneralSettingsFields: (state, action: PayloadAction<SettingsFields>) => {
      state.generalSettingsFields = action.payload
    },
    setExpectedCompletionDate: (state, action: PayloadAction<string>) => {
      state.expectedCompletionDate = action.payload
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<ProjectGeneralConfigState, LoadingTypes, GeneralSettingsDto>({
      promise: fetchGeneralSettings,
      loadingType: 'fetchGeneralSettings',
      onFulfilled: (state, action) => {
        const {
          expectedCompletionDate,
          sector,
          location,
          contractingAuthority,
          contractor,
          name,
          isEnded,
        } = action.payload
        state.generalSettingsFields = [
          { name: 'sector', value: sector },
          { name: 'location', value: location },
          { name: 'contractingAuthority', value: contractingAuthority },
          { name: 'contractor', value: contractor },
          { name: 'name', value: name },
          { name: 'isEnded', value: isEnded ? 1 : 0 },
        ]
        state.expectedCompletionDate = expectedCompletionDate
        state.initialSettingsFetched = true
        state.canEnd = !isEnded
      },
    }),
    generateExtraBackendReducers<ProjectGeneralConfigState, LoadingTypes, { canEnd: boolean }>({
      promise: updateGeneralSettings,
      loadingType: 'updateGeneralSettings',
      onFulfilled: (state, action) => {
        state.canEnd = action.payload.canEnd
      },
    }),
  ]),
})

export const { setGeneralSettingsFields, setExpectedCompletionDate } = projectGeneralConfigSlice.actions

export const projectGeneralConfigReducer = projectGeneralConfigSlice.reducer
