import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import { SettingsFields } from 'models/project/projectDetails'

export const selectFetchGeneralSettingsLoading = (state: RootState): BackendLoading =>
  state.project.config.general.loading.fetchGeneralSettings

export const selectUpdateGeneralSettingsLoading = (state: RootState): BackendLoading =>
  state.project.config.general.loading.updateGeneralSettings

export const selectIsGeneralSettingsContentLoading = (state: RootState): boolean =>
  selectUpdateGeneralSettingsLoading(state).isLoading || selectFetchGeneralSettingsLoading(state).isLoading

export const selectGeneralSettingsFields: (state: RootState) => SettingsFields = (state) =>
  state.project.config.general.generalSettingsFields

export const selectExpectedCompletionDate = (state: RootState): string | undefined | null =>
  state.project.config.general.expectedCompletionDate

export const selectInitialSettingsFetched = (state: RootState): boolean =>
  state.project.config.general.initialSettingsFetched

export const selectCanEnd: (state: RootState) => boolean | undefined = (state) => state.project.config.general.canEnd
