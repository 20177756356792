import { useLocation, useHistory } from 'react-router-dom'
import useSideDrawerParams from '../query/sideDrawerParams'
import SideDrawerType from './sideDrawer.types'

interface UseSideDrawerOptionReturnValue {
  handleOption: () => void
  isActive: boolean
}

const useSideDrawerOption = (sideDrawerType: SideDrawerType, contentType: string): UseSideDrawerOptionReturnValue => {
  const history = useHistory()
  const { pathname } = useLocation()
  const { contentParam, sideDrawerParam } = useSideDrawerParams()
  const isActive = contentParam === contentType && sideDrawerParam === sideDrawerType
  const handleOption = () => {
    if (isActive) {
      history.push(pathname)
    } else {
      history.push(`${pathname}?sideDrawer=${sideDrawerType}&content=${contentType}`)
    }
  }
  return { handleOption, isActive }
}

export default useSideDrawerOption
