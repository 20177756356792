import { getUndefinedIfNegative } from 'helpers/Collection.helpers'
import { ProjectStage } from 'models/administration'
import { selectProjectIsActive } from 'redux/project/project-details/ProjectDetails.selectors'
import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import { ProjectStages, ProjectStagesConfigState } from './ProjectStagesConfig.types'

const selectProjectStagesConfig: (state: RootState) => ProjectStagesConfigState = (state) => state.project.config.stages

export const selectStagesOverview: (state: RootState) => ProjectStage[] = (state) =>
  selectProjectStagesConfig(state).stagesOverview

export const selectProjectStages: (state: RootState) => ProjectStages | undefined = (state) =>
  selectProjectStagesConfig(state).stages

export const selectProjectStagesAvailableToAdd: (state: RootState) => ProjectStage[] = (state) => {
  const stagesOverview = selectStagesOverview(state)
  const projectStages = selectProjectStages(state)
  return stagesOverview.filter(({ id }) => !projectStages?.stages.find((stage) => stage.id === id))
}

export const selectCurrentStage: (state: RootState) => number | null | undefined = (state) =>
  selectProjectStages(state)?.current

export const selectEndedStage: (state: RootState) => number | undefined = (state) =>
  selectProjectStagesConfig(state).endedStage

export const selectFetchingStagesOverviewLoading: (state: RootState) => BackendLoading = (state) =>
  selectProjectStagesConfig(state).loading.fetchStagesOverview

export const selectFetchingProjectStagesLoading: (state: RootState) => BackendLoading = (state) =>
  selectProjectStagesConfig(state).loading.fetchProjectStages

export const selectUpdatingProjectStagesLoading: (state: RootState) => BackendLoading = (state) =>
  selectProjectStagesConfig(state).loading.updateProjectStages

export const selectIsProjectStagesConfigContentLoading: (state: RootState) => boolean = (state) =>
  selectFetchingStagesOverviewLoading(state).isLoading ||
  selectFetchingProjectStagesLoading(state).isLoading ||
  selectUpdatingProjectStagesLoading(state).isLoading

const getStageIndex = (stageId?: number | null, stages?: ProjectStage[]): number | undefined =>
  stages?.findIndex(({ id }) => id === stageId)

export const selectCurrentStageIndex: (state: RootState) => number | undefined = (state) =>
  getUndefinedIfNegative(getStageIndex(selectCurrentStage(state), selectProjectStages(state)?.stages))

export const selectEndedStageIndex: (state: RootState) => number | undefined = (state) =>
  getUndefinedIfNegative(getStageIndex(selectEndedStage(state), selectProjectStages(state)?.stages))

export const createCanActivateStageSelector: (stageId: number) => (state: RootState) => boolean = (stageId) => (
  state
) => {
  const currentStage = selectCurrentStage(state)
  const isProjectActive = selectProjectIsActive(state)
  const projectStages = selectProjectStages(state)?.stages
  if (isProjectActive) {
    const endedStageIdx = selectEndedStageIndex(state)
    const selectedStageIdx = getStageIndex(stageId, projectStages)
    if (!endedStageIdx || !selectedStageIdx) return false
    return selectedStageIdx === endedStageIdx + 1
  }

  return currentStage !== stageId
}

export const createIsProjectStageCurrentSelector: (stageId: number) => (state: RootState) => boolean = (stageId) => (
  state
) => selectCurrentStage(state) === stageId

export const createCanStageBeModifiedSelector: (stageId: number) => (state: RootState) => boolean = (stageId) => (
  state
) => {
  const isProjectActive = selectProjectIsActive(state)
  const projectStages = selectProjectStages(state)?.stages
  if (isProjectActive) {
    const endedStageIdx = selectEndedStageIndex(state)
    const currentStageIdx = selectCurrentStageIndex(state)
    const selectedStageIdx = projectStages?.findIndex(({ id }) => id === stageId) as number
    if (currentStageIdx && currentStageIdx > 0) return selectedStageIdx > currentStageIdx
    if (endedStageIdx && endedStageIdx > 0) return selectedStageIdx > endedStageIdx
  }
  return true
}

export const selectIsStagesStepValid: (state: RootState) => boolean = (state) => {
  const stages = selectProjectStages(state)
  return !!stages && stages.stages.length !== 0 && !!stages.current
}
