import { Color } from 'styled-components'
import { FlowStatusInfo, CdeStatus } from 'models/files'
import formatDate from 'helpers/Dates.helpers'
import { MessageDescriptor } from '@lingui/core'
import { defineMessage, t } from '@lingui/macro'
import trans from 'helpers/i18n.helpers'

export const flowStatuses: { [key: string]: { color?: Color; text: MessageDescriptor } } = {
  IN_PROGRESS: {
    text: defineMessage({ id: 'files.acceptance_status.in_progress', message: 'In progress' }),
  },
  IN_REVIEW: {
    text: defineMessage({ id: 'files.acceptance_status.in_review', message: 'Awaits acceptance' }),
  },
  ACCEPTED: {
    text: defineMessage({ id: 'files.acceptance_status.accepted', message: 'Accepted' }),
    color: 'successText',
  },
  REJECTED: {
    text: defineMessage({ id: 'files.acceptance_status.rejected', message: 'Rejected' }),
    color: 'failureText',
  },
  FINISHED: {
    text: defineMessage({ id: 'files.acceptance_status.finished', message: 'Accepted' }),
    color: 'successText',
  },
  WIP_FINISHED: {
    text: defineMessage({ id: 'files.acceptance_status.wip_finished', message: 'In progress' }),
  },
  INVALIDATED: {
    text: defineMessage({ id: 'files.acceptance_status.invalidated', message: 'Abandoned' }),
  },
}

export const flowStatusesFilterData = Object.keys(flowStatuses).map((key) => ({
  value: key,
  text: flowStatuses[key].text,
}))

export const getFlowStatusText = (flowStatus?: string, cdeStatus?: CdeStatus): string => {
  if (flowStatus) {
    return trans(flowStatuses[flowStatus].text)
  }
  return cdeStatus === 'IN_PROGRESS' ? trans(flowStatuses.IN_PROGRESS.text) : ''
}

export const getFlowStatusTextWithDate = (
  flowStatus: FlowStatusInfo | undefined,
  cdeStatus: CdeStatus | undefined
): string => {
  if (flowStatus?.since) {
    const statusText = trans(flowStatuses[flowStatus.status].text)
    const sinceText = t({
      id: 'files.acceptance_status_since_text',
      message: `since ${formatDate(flowStatus.since)}`,
    })
    return `${statusText} (${sinceText})`
  }
  return getFlowStatusText(flowStatus?.status, cdeStatus)
}

export const getFlowStatusColor = (acceptanceStatus: string | undefined): Color | undefined =>
  acceptanceStatus ? flowStatuses[acceptanceStatus].color : undefined
