import { Trans } from '@lingui/macro'
import Text from 'components/atoms/text'
import styled from 'styled-components'
import AddingFlowButton from './AddingFlowButton'

const StyledEmptyFlowContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.sizes.default};
`

const ProjectFlowConfigEmpty: React.FC = () => {
  return (
    <StyledEmptyFlowContent>
      <Text textStyle="regularText-smallSemiBold" color="volcano5">
        <Trans id="project_config.flow.empty_flow_warning">Warning! No added flows for project</Trans>
      </Text>
      <Text textStyle="regularText-smallSemiBold">
        <Trans id="project_config.flow.empty_flow_description_info">
          File flow is used to run file sharing processes for a specific purpose.
        </Trans>
      </Text>
      <AddingFlowButton />
    </StyledEmptyFlowContent>
  )
}

export default ProjectFlowConfigEmpty
