import { Trans } from '@lingui/macro'
import { Skeleton, Space } from 'antd'
import Text from 'components/atoms/text'
import themePxValueToNumber from 'helpers/Styles.helpers'
import { useSelector } from 'react-redux'
import {
  selectFetchProjectDetailsLoading,
  selectProjectDetails,
} from 'redux/project/project-details/ProjectDetails.selectors'
import styled from 'styled-components'

const StyledSpace = styled(Space)`
  max-width: calc(100% - ${({ theme }) => themePxValueToNumber(theme.sizes.m)}px);
  padding-right: ${({ theme }) => theme.sizes.s};
  & > .ant-space-item:last-child {
    max-width: calc(100vw - 370px);
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const FullScreenModalHeaderText: React.FC = () => {
  const projectInformation = useSelector(selectProjectDetails)
  const { isLoading: isFetchingDetailsLoading } = useSelector(selectFetchProjectDetailsLoading)
  const projectName = projectInformation?.name
  return (
    <StyledSpace>
      <Text textStyle="regularText-medium">
        <Trans id="project_config.base_config_view.header">Project configuration</Trans>
      </Text>
      {isFetchingDetailsLoading ? (
        <Skeleton.Button active size="small" />
      ) : (
        <Text textStyle="header-H2b">
          <Trans id="project_config.project_header">Project {projectName}</Trans>
        </Text>
      )}
    </StyledSpace>
  )
}

export default FullScreenModalHeaderText
