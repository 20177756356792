import { useSelector } from 'react-redux'
import { selectIsProjectWipGroupsConfigContentLoading } from 'redux/project/project-config/wip-groups/ProjectWipGroupsConfig.selectors'
import { updateWipGroups } from 'redux/project/project-config/wip-groups/ProjectWipGroupsConfig.slice'
import BaseProjectConfigView from '../base-config-view/BaseProjectConfigView'
import ProjectWipGroupsConfigContent from './content'

const ProjectWipGroupsConfig: React.FC = () => {
  const isContentLoading = useSelector(selectIsProjectWipGroupsConfigContentLoading)
  return (
    <BaseProjectConfigView
      content={<ProjectWipGroupsConfigContent />}
      saveStepThunk={updateWipGroups()}
      isContentLoading={isContentLoading}
    />
  )
}

export default ProjectWipGroupsConfig
