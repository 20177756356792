import { t } from '@lingui/macro'
import { Transfer } from 'antd'
import { addText, deleteText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import { ActiveUser } from 'models/users'
import { useState } from 'react'
import UserDataDisplay from '../columns/UserDataDisplay'

export interface TransferUser {
  key: string
  user: ActiveUser
}

interface UsersTransferProps {
  users: TransferUser[] | undefined
  targetKeys: string[]
  selectedOrganizations: number[] | undefined
  usersFilter: string | undefined
  onChange: (target: string[]) => void
}

const TransferItem: React.FC<{ user: ActiveUser }> = ({ user }) => {
  return (
    <UserDataDisplay
      name={`${user.firstName} ${user.lastName}`}
      email={user.email}
      organization={user.organization.abbreviation}
    />
  )
}

const userContainsText: (user: ActiveUser, text: string | undefined) => boolean = (user, text) =>
  text === undefined || `${user.firstName} ${user.lastName}`.includes(text) || user.email.includes(text)

const userInOrganizations: (user: ActiveUser, organizations: number[] | undefined) => boolean = (user, organizations) =>
  organizations === undefined || organizations.includes(user.organization.id)

const UsersTransfer: React.FC<UsersTransferProps> = ({
  targetKeys,
  users,
  selectedOrganizations,
  usersFilter,
  onChange,
}) => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>([])
  return (
    <Transfer
      listStyle={{
        width: 350,
        height: 400,
      }}
      selectAllLabels={[() => null, () => null]}
      targetKeys={targetKeys}
      selectedKeys={selectedKeys}
      onChange={onChange}
      onSelectChange={(source, target) => setSelectedKeys([...source, ...target])}
      dataSource={users?.filter(
        (user) =>
          targetKeys.includes(user.key) ||
          (userInOrganizations(user.user, selectedOrganizations) && userContainsText(user.user, usersFilter))
      )}
      titles={[
        t({ id: 'project_config.members.modal.available_users', message: 'Available users' }),
        t({ id: 'project_config.members.modal.users_to_add', message: 'Users to add' }),
      ]}
      operations={[trans(addText), trans(deleteText)]}
      render={({ user }) => <TransferItem user={user} />}
    />
  )
}

export default UsersTransfer
