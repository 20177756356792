import { Trans } from '@lingui/macro'

const flowConfigStepsData = [
  {
    description: <Trans id="flow_config.steps.general_settings">General settings</Trans>,
    stepName: 'GENERAL',
  },
  {
    description: <Trans id="flow_config.steps.conditions">Preconditions for starting files</Trans>,
    stepName: 'INITIAL',
  },
  {
    description: <Trans id="flow_config.steps.attributes_settings">Attributes Settings</Trans>,
    stepName: 'METADATA',
  },
  {
    description: <Trans id="flow_config.steps.decisions">Execution settings</Trans>,
    stepName: 'START_CHANGES',
  },
  {
    description: <Trans id="flow_config.steps.consequences">Consequences</Trans>,
    stepName: 'EXIT_CHANGES',
  },
]

export default flowConfigStepsData
