import { WithDividerElement } from 'components/atoms/divider'
import { CellWrapper } from 'components/molecules/table/Table'
import { DeleteButton } from 'components/molecules/buttons'
import showConfirmationModal from 'helpers/Modals.helpers'
import { useAppDispatch } from 'redux/store'
import { t } from '@lingui/macro'
import { deleteText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import { removeDisciplineFromSelected } from 'redux/project/project-config/disciplines'

const DisciplineActions: React.FC<{ id: number | undefined; canDelete: boolean; name: string }> = ({
  id,
  canDelete,
  name,
}) => {
  const dispatch = useAppDispatch()
  return (
    <CellWrapper>
      <WithDividerElement>
        <DeleteButton
          onClick={() => {
            if (id !== -1) {
              showConfirmationModal({
                content: t({
                  id: 'project_config.disciplines.delete_confirmation_text',
                  message: `Delete discipline "${name}"?`,
                }),
                okText: trans(deleteText),
                onOk: async () => {
                  if (id) await dispatch(removeDisciplineFromSelected(id as number))
                },
              })
            } else {
              dispatch(removeDisciplineFromSelected(id as number))
            }
          }}
          disabled={!canDelete}
          tooltip={t({ id: 'project_config.disciplines.delete_discipline_tooltip', message: 'Delete discipline' })}
          disabledTooltip={t({
            id: 'project_config.disciplines.delete_used_discipline_tooltip',
            message: 'Discipline is used and cannot be deleted',
          })}
        />
      </WithDividerElement>
    </CellWrapper>
  )
}

export default DisciplineActions
