import { t } from '@lingui/macro'
import ErrorAlert from 'components/atoms/error-alert'
import { useSelector } from 'react-redux'
import {
  selectFetchGeneralSettingsLoading,
  selectUpdateGeneralSettingsLoading,
} from 'redux/project/project-config/general/ProjectGeneralConfig.selectors'

const ProjectGeneralConfigAlert: React.FC = () => {
  const updateGeneralSettingsLoading = useSelector(selectUpdateGeneralSettingsLoading)
  const fetchGeneralSettingsLoading = useSelector(selectFetchGeneralSettingsLoading)

  const alertMessageDict = [
    {
      error: updateGeneralSettingsLoading.error,
      message: t({
        id: 'project_config.general.alert.settings_update_failed',
        message: 'Could not update general settings.',
      }),
    },
    {
      error: fetchGeneralSettingsLoading.error,
      message: t({
        id: 'project_config.general.alert.settings_fetch_failed',
        message: 'Could not retrieve general settings.',
      }),
    },
  ]
  return <ErrorAlert alertMessageDict={alertMessageDict} />
}

export default ProjectGeneralConfigAlert
