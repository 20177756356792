import { t } from '@lingui/macro'
import ErrorAlert from 'components/atoms/error-alert'
import { useSelector } from 'react-redux'
import {
  selectGetEntriesStructureLoading,
  selectUpdateEntriesStructureLoading,
} from 'redux/project/project-config/entries-structure/ProjectEntriesStructureConfig.selectors'
import { selectFetchAvailableMetadataLoading } from 'redux/project/project-config/naming-scheme/ProjectNamingSchemeConfig.selectors'

const ProjectEntriesStructureAlerts: React.FC = () => {
  const getEntryStructureLoading = useSelector(selectGetEntriesStructureLoading)
  const updateEntryStructureLoading = useSelector(selectUpdateEntriesStructureLoading)
  const fetchAvailableMetadataLoading = useSelector(selectFetchAvailableMetadataLoading)

  const alertMessageDict = [
    {
      error: getEntryStructureLoading.error,
      message: t({
        id: 'project_config.entries_structure.alert.entry_structure_fetch_failed',
        message: 'Could not retrieve smart directories structure.',
      }),
    },
    {
      error: fetchAvailableMetadataLoading.error,
      message: t({
        id: 'project_config.entries_structure.alert.metadata_fetch_failed',
        message: 'Could not retrieve attributes.',
      }),
    },
    {
      error: updateEntryStructureLoading.error,
      message: t({
        id: 'project_config.entries_structure.alert.entry_structure_update_failed',
        message: 'Could not update smart directories structure.',
      }),
    },
  ]
  return <ErrorAlert alertMessageDict={alertMessageDict} />
}

export default ProjectEntriesStructureAlerts
