import { MessageDescriptor } from '@lingui/core'
import { defineMessage } from '@lingui/macro'
import { FieldData } from './components'
import { CdeStatus } from './files'

export interface Flow {
  id?: number
  name: string
  description: string
  command: string
}

export interface FlowConfig extends Flow {
  prevFlows: Flow[]
  project?: number
}

export const flowStatuses = ['IN_REVIEW', 'STARTED', 'FINISHED', 'ACCEPTED', 'REJECTED'] as const
export type FlowStatus = typeof flowStatuses[number]

export const reviewersUsersTypes = ['CHECKING_USER', 'ACCEPTING_USER', 'SELECTED'] as const
export type ReviewersUsersType = typeof reviewersUsersTypes[number]
export const ReviewersUsersTypes = {
  CHECKING_USER: 'CHECKING_USER',
  ACCEPTING_USER: 'ACCEPTING_USER',
  SELECTED: 'SELECTED',
} as const

export const reviewTypes = ['SINGULAR_REVIEWER'] as const
export type ReviewType = typeof reviewTypes[number]
export const ReviewTypes = {
  SINGULAR_REVIEWER: 'SINGULAR_REVIEWER',
} as const

export const reviewerSelectionMethods = ['SELECTED', 'RANDOM'] as const
export type ReviewerSelectionMethod = typeof reviewerSelectionMethods[number]
export const ReviewerSelectionMethods = {
  SELECTED: 'SELECTED',
  RANDOM: 'RANDOM',
} as const

export interface FlowDecisionConfig {
  afterInitSuitabilityCode?: number
  reviewersUsersType?: ReviewersUsersType
  reviewUsersList: number[]
  reviewType?: ReviewType
  reviewerSelectionMethod?: ReviewerSelectionMethod
  reviewRequired: boolean
}

export interface FlowConditionsConfig {
  id: number
  initialCdeStatuses: CdeStatus[]
  initialOrganizations: number[]
  initialDisciplines: number[]
  initialSuitabilityCodes: number[]
  initialUsersTypes: UserPermissionType[] | AllUsersType[] | SelectedUsersType[]
  initialUsersList: number[]
}

export type FlowUsersTypeRadioOption = 'PERMISSIONS' | 'ALL' | 'SELECTED'

export const userPermissionsTypes = ['AUTHOR', 'CHECKING_USER', 'ACCEPTING_USER'] as const

export const userPermissionsTypesLabels: Record<UserPermissionType, MessageDescriptor> = {
  AUTHOR: defineMessage({ id: 'flow.initiator_types.author', message: 'Author' }),
  CHECKING_USER: defineMessage({ id: 'flow.initiator_types.checking_user', message: 'Checking user' }),
  ACCEPTING_USER: defineMessage({ id: 'flow.initiator_types.accepting_user', message: 'Accepting user' }),
}

export const userPermissionsOptions = userPermissionsTypes.map((userType) => ({
  value: userType,
  label: userPermissionsTypesLabels[userType],
}))

export type UserPermissionType = typeof userPermissionsTypes[number]

export type AllUsersType = 'ALL'

export type SelectedUsersType = 'SELECTED'

export interface FlowAttributeValueConfig {
  id: number
  value: string
  code: string
  isSelected: boolean
}

export interface FlowAttributeConfig {
  id?: number
  name: string
  isSelected: boolean
  values: FlowAttributeValueConfig[]
}

export const consequencesTypes = ['POSITIVE', 'NEGATIVE', 'NEUTRAL'] as const
export type ConsequencesType = typeof consequencesTypes[number]
export const ConsequencesTypes = {
  POSITIVE: 'POSITIVE',
  NEGATIVE: 'NEGATIVE',
  NEUTRAL: 'NEUTRAL',
} as const

export interface FlowConsequence {
  id?: number
  consequenceType: ConsequencesType
  followingSuitabilityCode?: number | null
  followingCdeStatus?: CdeStatus | null
  fileUploadRequired: boolean
}

export type FlowSettingsFields = FieldData<
  string | number | boolean | null,
  'consequenceType' | 'followingSuitabilityCode' | 'followingCdeStatus' | 'fileUploadRequired'
>[]

export interface FlowConsequencesConfig {
  onFinished: FlowSettingsFields[]
}

export interface ProjectFlowsExecDetails {
  id: number
  name: string
  description: string
  command: string
  reviewRequired: boolean
  reviewerSelectionMethod?: ReviewerSelectionMethod
}

export interface ProjectFlowsReviewDetails {
  id: number
  name: string
  description: string
  command: string
  config: number
  consequences: FlowConsequence[]
  file: number
  reviewer: number
  status: FlowStatus
}
