import { useSelector } from 'react-redux'
import ErrorAlert from 'components/atoms/error-alert'
import {
  selectProjectMembersOrganizationsFetchingLoading,
  selectProjectMembersUserFetchingLoading,
} from 'redux/project/project-config/members/ProjectMembersConfig.selectors'
import { t } from '@lingui/macro'

const ProjectMembersModalErrors: React.FC = () => {
  const organizationsLoading = useSelector(selectProjectMembersOrganizationsFetchingLoading)
  const usersLoading = useSelector(selectProjectMembersUserFetchingLoading)

  const errors = [
    {
      error: organizationsLoading.error,
      message: t({
        id: 'project_config.members.modal.alert.organization_fetch_failed',
        message: 'Could not retrieve organizations.',
      }),
    },
    {
      error: usersLoading.error,
      message: t({ id: 'project_config.members.modal.alert.users_fetch_failed', message: 'Could not retrieve users.' }),
    },
  ]

  return <ErrorAlert alertMessageDict={errors} />
}

export default ProjectMembersModalErrors
