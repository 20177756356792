import { ProjectActivationData, ProjectConfigCompletion } from 'models/project/projectConfig'
import { BackendLoading } from 'redux/redux.shared'

export const loadingTypes = [
  'activateProject',
  'fetchProjectActivation',
  'scheduleProjectActivation',
  'removeScheduledProjectActivation',
] as const
export type LoadingTypes = typeof loadingTypes[number]

export interface ProjectActivationConfigState {
  loading: Record<LoadingTypes, BackendLoading>
  configCompletionType: ProjectConfigCompletion
  activationData: ProjectActivationData
  newActivationDatetime?: string
}
