import { defineMessage } from '@lingui/macro'
import { BackupStatus } from 'models/administration'
import { Color } from 'styled-components'
import { MessageDescriptor } from '@lingui/core'

const backupStatusDict: Record<BackupStatus, { color: Color; text: MessageDescriptor; tooltip: MessageDescriptor }> = {
  CREATED: {
    color: 'grayG04',
    text: defineMessage({ id: 'administration.backups.status.in_queue', message: 'Queued for execution' }),
    tooltip: defineMessage({
      id: 'administration.backups.status.in_queue_tooltip',
      message: 'Backup creation will begin in a few minutes',
    }),
  },
  IN_PROGRESS: {
    color: 'blue4',
    text: defineMessage({ id: 'administration.backups.status.in_progress', message: 'Pending' }),
    tooltip: defineMessage({
      id: 'administration.backups.status.in_progress_tooltip',
      message: 'Backup is currently being created',
    }),
  },
  ERROR: {
    color: 'red6',
    text: defineMessage({ id: 'administration.backups.status.error', message: 'Error' }),
    tooltip: defineMessage({
      id: 'administration.backups.status.error_tooltip',
      message: 'An error occured during backup creation',
    }),
  },
  FINISHED: {
    color: 'green8',
    text: defineMessage({ id: 'administration.backups.status.finished', message: 'Created' }),
    tooltip: defineMessage({
      id: 'administration.backups.status.finished_tooltip',
      message: 'Backup has been successfully created',
    }),
  },
}

export const MIN_NUMBER_OF_BACKUPS = 3

export default backupStatusDict
