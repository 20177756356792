import styled from 'styled-components'
import Text from 'components/atoms/text'
import { Trans } from '@lingui/macro'

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
`

interface CodeLengthFieldLabelProps {
  minValue: number
  maxValue: number
}

const CodeLengthFieldLabel: React.FC<CodeLengthFieldLabelProps> = ({ minValue, maxValue }) => {
  return (
    <StyledDiv>
      <Text textStyle="regularText-small" color="grayG08">
        <Trans id="administration.common.codes.modal.code_length_label">Code length</Trans>
      </Text>
      <Text textStyle="regularText-small" color="grayG05">
        <Trans id="administration.common.codes.modal.code_length_suggested_lengths_text">
          Suggested length: {minValue}-{maxValue}
        </Trans>
      </Text>
    </StyledDiv>
  )
}

export default CodeLengthFieldLabel
