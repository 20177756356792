import { plural, t } from '@lingui/macro'
import { ActivationDetails } from 'models/administration'
import {
  AccountActivityStatus,
  AccountActivityStatuses,
} from 'redux/administration/administration-users/AdministrationUsers.types'

export default function getAccountStatus(activationDetails: ActivationDetails): AccountActivityStatus {
  if (activationDetails && activationDetails.isActive) return AccountActivityStatuses.ACTIVE
  if (activationDetails && activationDetails.deactivated) return AccountActivityStatuses.LINK_DEACTIVATED
  if (
    activationDetails &&
    activationDetails.activationCodeExpiry &&
    new Date(activationDetails.activationCodeExpiry) < new Date()
  ) {
    return AccountActivityStatuses.LINK_EXPIRED
  }
  if (activationDetails && activationDetails.activationCodeExpiry) return AccountActivityStatuses.LINK_ACTIVE
  return AccountActivityStatuses.INACTIVE
}

export const formatAddedUsersMessage: (addedCount: number, errorsCount: number) => string = (
  addedCount,
  errorsCount
) => {
  if (errorsCount === 0) {
    return t({
      id: 'administration.users.notifications.users_loaded_without_errors_text',
      message: `${plural(addedCount, { one: '# user has', other: '# users have' })} been added`,
    })
  }
  if (addedCount === 0) {
    return t({
      id: 'administration.users.notifications.no_users_loaded_text',
      message: `No user has been added. ${plural(errorsCount, {
        one: '# user is',
        other: '# users are',
      })} already in the system`,
    })
  }
  return t({
    id: 'administration.users.notifications.users_loaded_with_errors_text',
    message: `${plural(addedCount, { one: '# user has', other: '# users have' })} been added. ${plural(errorsCount, {
      one: '# user is',
      other: '# users are',
    })} already in the system`,
  })
}
