import { useParams } from 'react-router'

interface ProjectPageParams {
  projectId: string | undefined
}

const useProjectId = (): number | undefined => {
  const { projectId } = useParams<ProjectPageParams>()
  return projectId !== undefined ? Number(projectId) : undefined
}

export default useProjectId
