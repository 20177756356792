import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import {
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  combine,
  generateInitialLoadingState,
  getStateAsAny,
} from 'redux/redux.shared'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import { Attribute, NamingScheme, NamingSchemeElement } from 'models/administration'
import { Discipline, EntryNamingScheme } from 'models/project/projectConfig'
import { selectSchemeSource, selectScheme } from './ProjectNamingSchemeConfig.selectors'
import { ProjectNamingSchemeConfigState, loadingTypes, LoadingTypes } from './ProjectNamingSchemeConfig.types'
import { addProjectConfigFinishedStep } from '../status/ProjectStatusConfig.slice'

const unknownCodeSymbol = '?'

export const fetchNamingSchemes = createAsyncBackendThunk('fetchNamingSchemes', async () => {
  return (await backendAxios.get('/entries/naming-scheme/')).data
})

export const fetchNamingScheme = createAsyncBackendThunk(
  'namingScheme/fetchNamingScheme',
  async (projectId: number, { dispatch }) => {
    const fetchingSchemesPromise = dispatch(fetchNamingSchemes())
    const fetchingSchemePromise = backendAxios.get(`/projects/${projectId}/naming-scheme/`)
    const respArr = await Promise.all([fetchingSchemesPromise, fetchingSchemePromise])
    return respArr[1].data
  }
)

export const fetchAvailableMetadata = createAsyncBackendThunk(
  'namingScheme/fetchAvailableMetadata',
  async (projectId: number) => {
    const response = await backendAxios.get(`/projects/${projectId}/metadata/available/`)
    return response.data
  }
)

export const fetchDisciplineCode = createAsyncBackendThunk(
  'namingScheme/fetchDisciplineCode',
  async (args, { getState }) => {
    const state = getStateAsAny(getState)
    const projectId = selectProjectId(state)
    const response = await backendAxios.get(`/projects/${projectId}/disciplines/`)
    const disciplines = response.data as Discipline[]
    const codes = disciplines.filter(({ code }) => code !== undefined).map(({ code }) => code)
    return codes[0] ? codes[0] : unknownCodeSymbol
  }
)

export const updateNamingScheme = createAsyncBackendThunk(
  'namingScheme/updateNamingScheme',
  async (args, { getState, dispatch }) => {
    const state = getStateAsAny(getState)
    const projectId = selectProjectId(state) as number
    const scheme = selectScheme(state)
    const namingSchemeSource = selectSchemeSource(state)
    await backendAxios.put(`/projects/${projectId}/naming-scheme/`, { scheme, namingSchemeSource })
    await dispatch(addProjectConfigFinishedStep({ projectId, step: 'NAMING_SCHEME' }))
  }
)

const initialState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  availableSchemes: [],
  availableMetadata: [],
  schemeDraft: [],
  shouldUpdateScheme: false,
  scheme: { scheme: [] },
  disciplineData: { codeLength: 0, values: [], exampleCode: unknownCodeSymbol },
} as ProjectNamingSchemeConfigState

const projectNamingSchemeConfigSlice = createSlice({
  name: 'namingScheme',
  initialState,
  reducers: {
    setSelectedScheme: (state, action: PayloadAction<number | undefined>) => {
      const selectedScheme = state.availableSchemes.find(({ id }) => id === action.payload)
      state.scheme.scheme = selectedScheme ? selectedScheme.scheme : []
      state.scheme.namingSchemeSource = selectedScheme ? selectedScheme.id : undefined
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<ProjectNamingSchemeConfigState, LoadingTypes, EntryNamingScheme>({
      promise: fetchNamingScheme,
      loadingType: 'fetchNamingScheme',
      onFulfilled: (state, action) => {
        if (action.payload.scheme.length === 0) {
          const defaultScheme = state.availableSchemes.find((sch) => sch.isDefault)
          state.scheme.scheme = defaultScheme?.scheme ?? []
          state.scheme.namingSchemeSource = defaultScheme?.id ?? undefined
          state.schemeDraft = defaultScheme?.scheme ?? []
        } else {
          state.scheme = action.payload
          state.schemeDraft = action.payload.scheme
        }
      },
    }),
    generateExtraBackendReducers<ProjectNamingSchemeConfigState, LoadingTypes, NamingSchemeElement[]>({
      promise: updateNamingScheme,
      loadingType: 'updateNamingScheme',
    }),
    generateExtraBackendReducers<ProjectNamingSchemeConfigState, LoadingTypes, Attribute[]>({
      promise: fetchAvailableMetadata,
      loadingType: 'fetchAvailableMetadata',
      onFulfilled: (state, action) => {
        state.availableMetadata = action.payload
      },
    }),
    generateExtraBackendReducers<ProjectNamingSchemeConfigState, LoadingTypes, Discipline[]>({
      promise: fetchDisciplineCode,
      loadingType: 'fetchDisciplineCode',
      onFulfilled: (state, action) => {
        const codes = action.payload.filter(({ code }) => code !== undefined).map(({ code }) => code)
        state.disciplineCode = codes[0] ? codes[0] : unknownCodeSymbol
      },
    }),
    generateExtraBackendReducers<ProjectNamingSchemeConfigState, LoadingTypes, NamingScheme[]>({
      promise: fetchNamingSchemes,
      loadingType: 'fetchNamingSchemes',
      onFulfilled: (state, action) => {
        state.availableSchemes = action.payload
      },
    }),
  ]),
})

export const { setSelectedScheme } = projectNamingSchemeConfigSlice.actions

export const projectNamingSchemeConfigReducer = projectNamingSchemeConfigSlice.reducer
