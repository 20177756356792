import { Trans } from '@lingui/macro'
import { Space } from 'antd'
import Text from 'components/atoms/text'
import { useSelector } from 'react-redux'
import {
  selectFlowConditionsConfig,
  selectFlowUsersTypeRadioOption,
} from 'redux/flow-conifg/conditions/FlowConditionsConfig.selectors'
import { setInitialUsersList } from 'redux/flow-conifg/conditions/FlowConditionsConfig.slice'
import FlowConditionsConfigForm from './form'
import FlowConditionsConfigContentAlerts from './FlowConditionsConfigContentAlerts'
import MembersSelectionTable from '../../form-items/members/MembersSelectionTable'

const FlowConditionsConfigContent: React.FC = () => {
  const flowConditionsConfig = useSelector(selectFlowConditionsConfig)
  const flowUsersTypeRadioOption = useSelector(selectFlowUsersTypeRadioOption)
  return (
    <Space direction="vertical">
      <Space direction="vertical" size={4}>
        <Text textStyle="regularText-medium">
          <Trans id="flow_config.conditions.heading">Starting files</Trans>
        </Text>
        <Text textStyle="regularText-small">
          <Trans id="flow_config.conditions.heading_description">Conditions required to start this flow</Trans>
        </Text>
      </Space>
      <FlowConditionsConfigForm
        flowConditionsConfig={flowConditionsConfig}
        flowUsersTypeRadioOption={flowUsersTypeRadioOption}
      />
      {flowUsersTypeRadioOption === 'SELECTED' && flowConditionsConfig && (
        <MembersSelectionTable
          initialSelection={flowConditionsConfig?.initialUsersList}
          updateUsersFn={setInitialUsersList}
        />
      )}
      <FlowConditionsConfigContentAlerts />
    </Space>
  )
}

export default FlowConditionsConfigContent
