import { monthNames } from 'data/messages/dates'
import moment, { Moment } from 'moment'
import trans from './i18n.helpers'

export const getDaysInMonth: (month: number, year?: number) => number = (month, year = undefined) => {
  const days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  if (month !== 1) return days[month]
  const y = year || new Date().getFullYear()
  return y % 4 === 0 && (y % 100 !== 0 || y % 400 === 0) ? 29 : 28
}

export const formatTimeString = (x: number): string => String(x).padStart(2, '0')

const formatDate = (dateStr: string | number): string => {
  const date = new Date(dateStr)
  const minute = date.getMinutes()
  const hour = date.getHours()
  const day = date.getDate()
  const monthStr = trans(monthNames[date.getMonth()])
  const year = date.getFullYear()

  return `${day} ${monthStr} ${year}, ${formatTimeString(hour)}:${formatTimeString(minute)}`
}

export default formatDate

interface WithLastUpdated {
  lastUpdated?: string
}
interface WithUploadDate {
  uploadDate?: string
}

export const reduceTime = (compare: (a: number, b: number) => boolean) => <T extends WithLastUpdated & WithUploadDate>(
  previousValue: T,
  currentValue: T
): T => {
  return compare(
    new Date(currentValue.lastUpdated || currentValue.uploadDate || '').getTime(),
    new Date(previousValue.lastUpdated || previousValue.uploadDate || '').getTime()
  )
    ? currentValue
    : previousValue
}
export const reduceToNewestTimeEntry = reduceTime((a, b) => a > b)
export const reduceToOldestTimeEntry = reduceTime((a, b) => a < b)

export const formatInputDate = (dateStr: string): string => {
  const date = new Date(dateStr)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  return `${year}-${formatTimeString(month)}-${formatTimeString(day)}`
}

export const getMsSinceMidnight = (): number => new Date().getTime() - new Date().setHours(0, 0, 0, 0)

export const datetimeToMoment = (dt: string | undefined, format?: string): Moment => moment(dt, format)

export const addTimezoneInfo = (st: string): string => new Date(st).toISOString()

export const sortByDate = (a: string | number, b: string | number): number => Number(new Date(b)) - Number(new Date(a))
