import { useState } from 'react'

interface UseLoadingReturnValue<T> {
  isLoading: boolean
  functionWithLoading: (arg: T) => Promise<unknown>
}

function useLoading<T>(promiseGenerator?: (arg: T) => Promise<unknown> | void): UseLoadingReturnValue<T> {
  const [isLoading, setIsLoading] = useState(false)
  const functionWithLoading = async (arg: T) => {
    setIsLoading(true)
    if (promiseGenerator) {
      try {
        const resp = await promiseGenerator(arg)
        setIsLoading(false)
        return resp
      } catch (err) {
        setIsLoading(false)
      }
    }
    return undefined
  }
  return { isLoading, functionWithLoading }
}

export default useLoading
