import { Trans } from '@lingui/macro'
import BaseFormItem from 'components/molecules/form-item/BaseFormItem'
import FormSelect from 'components/molecules/form-item/FormSelect'
import { noChangesText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import { FlowDecisionConfig } from 'models/flow'
import { useSelector } from 'react-redux'
import { selectSuitabilityCodesConfig } from 'redux/flow-conifg/decisions/FlowDecisionsConfig.selectors'
import { setDecisionFlowValues } from 'redux/flow-conifg/decisions/FlowDecisionsConfig.slice'
import { useAppDispatch } from 'redux/store'

interface FlowGeneralConfigFormProps {
  flow: FlowDecisionConfig
}

const SuitabilityCodesSelect: React.FC<FlowGeneralConfigFormProps> = ({ flow }) => {
  const dispatch = useAppDispatch()
  const codes = useSelector(selectSuitabilityCodesConfig)
  return (
    <BaseFormItem
      label={
        <Trans id="flow_config.decisions.after_init_suitability_code_label">After execution suitability code</Trans>
      }
      tooltip={
        <Trans id="flow_config.decisions.after_init_suitability_code_tooltip">
          This suitability code will be assigned to file after start command is executed.
        </Trans>
      }
    >
      <FormSelect
        showSearch
        name="afterInitSuitabilityCode"
        placeholder={trans(noChangesText)}
        options={[
          { value: -1, label: trans(noChangesText) },
          ...codes.map(({ id, name, code }) => ({ value: id, label: `${code} (${name})` })),
        ]}
        onChange={(value) => {
          if (value !== -1) {
            dispatch(setDecisionFlowValues({ ...flow, afterInitSuitabilityCode: Number(value) }))
          } else {
            dispatch(setDecisionFlowValues({ ...flow, afterInitSuitabilityCode: undefined }))
          }
        }}
      />
    </BaseFormItem>
  )
}

export default SuitabilityCodesSelect
