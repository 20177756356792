import styled from 'styled-components'
import { CloseOutlined } from '@ant-design/icons'
import Text from 'components/atoms/text'
import Icon from 'components/atoms/icon'
import Button from 'components/molecules/button-element'
import Modal from 'components/molecules/modal/Modal'
import { Trans } from '@lingui/macro'
import trans from 'helpers/i18n.helpers'
import { cancelText } from 'data/messages/controls'
import { useSelector } from 'react-redux'
import {
  selectExecutedFlow,
  selectIsFlowModalVisible,
  selectExecutedFile,
  selectAvailableReviewers,
  selectExecutingFlowLoading,
  selectSelectedReviewer,
} from 'redux/files/flows/Flows.selectors'
import { useAppDispatch } from 'redux/store'
import { setFlowModalVisibility, execFlow, getReviewers } from 'redux/files/flows'
import { ReviewerSelectionMethods } from 'models/flow'
import { useEffect } from 'react'
import FlowDetailsPanel from './content/FlowDetailsPanel'
import FlowExecModalAlerts from './FlowExecModalAlerts'
import NoReviewersWarning from './content/NoReviewersWarning'
import ReviewersTable from './content/ReviewersTable'

const StyledModal = styled(Modal)`
  .ant-modal-content {
    min-height: 300px;
    min-width: 800px;
  }
`

const StyledDiv = styled.div`
  margin: ${(props) => props.theme.sizes.defaultM};
`

const FlowExecModal: React.FC = () => {
  const dispatch = useAppDispatch()
  const flow = useSelector(selectExecutedFlow)
  const file = useSelector(selectExecutedFile)
  const reviewers = useSelector(selectAvailableReviewers)
  const reviewer = useSelector(selectSelectedReviewer)
  const { isLoading } = useSelector(selectExecutingFlowLoading)
  const isVisible = useSelector(selectIsFlowModalVisible)
  const isReviewerSelectionRequired =
    flow && flow.reviewRequired && flow.reviewerSelectionMethod === ReviewerSelectionMethods.SELECTED
  useEffect(() => {
    if (file && file.fileId && flow) {
      dispatch(getReviewers({ fileId: file.fileId, configId: flow.id }))
    }
  }, [file, flow, dispatch])
  if (!isVisible || flow === undefined || file === undefined) {
    return null
  }
  const { command, id: configId, reviewRequired, reviewerSelectionMethod } = flow
  const { fileId } = file
  const isValid = !isReviewerSelectionRequired || !!reviewer
  const handleCancel = () => dispatch(setFlowModalVisibility({ isVisible: false }))
  const handleSubmit = () => fileId && dispatch(execFlow({ configId, fileId, reviewer }))
  return (
    <StyledModal
      title={<Text textStyle="header-H2b">{command}</Text>}
      closeIcon={
        <Icon>
          <CloseOutlined />
        </Icon>
      }
      maskClosable={false}
      footer={
        <>
          <Button onClick={handleCancel} type="tertiary">
            {trans(cancelText)}
          </Button>
          <Button loading={isLoading} onClick={isValid ? handleSubmit : undefined} disabled={!isValid}>
            <Trans id="project.flows.modal.execute">Execute</Trans>
          </Button>
        </>
      }
      onCancel={handleCancel}
      visible
    >
      <StyledDiv>
        <FlowDetailsPanel />
        {reviewRequired && reviewerSelectionMethod === ReviewerSelectionMethods.RANDOM && (
          <Text textStyle="regularText-small">
            <Trans id="project.flows.modal.random_reviewer">The reviewer will be selected automatically.</Trans>
          </Text>
        )}
        {isReviewerSelectionRequired && reviewers.length === 0 && <NoReviewersWarning />}
        {isReviewerSelectionRequired && reviewers.length > 0 && (
          <div>
            <Text textStyle="regularText-small">
              <Trans id="project.flows.modal.selected_reviewer">
                Ther reviewer has to be selected before flow is executed.
              </Trans>
            </Text>
            <ReviewersTable />
          </div>
        )}
        <FlowExecModalAlerts />
      </StyledDiv>
    </StyledModal>
  )
}

export default FlowExecModal
