import { useState } from 'react'
import styled from 'styled-components'
import { SettingOutlined, CaretDownFilled } from '@ant-design/icons'
import Icon from 'components/atoms/icon'
import DividerElement from 'components/atoms/divider'
import CoordinatesPanelItems from './CoordinatesPanelItems'

interface StyledDivProps {
  $isVisible: boolean
}

const StyledDiv = styled.div<StyledDivProps>`
  padding: ${(props) => props.theme.sizes.xs} ${(props) => props.theme.sizes.default};
  background-color: rgba(${(props) => props.theme.colors.baseWhiteishRgb}, 0.8);
  display: flex;
  height: ${(props) => props.theme.sizes.l};
  align-items: center;
  transition: ${(props) => props.theme.transitions.default};
  > *:last-child {
    transition: ${(props) => props.theme.transitions.default};
  }
  ${(props) =>
    !props.$isVisible && 'transform: translateY(32px); > *:last-child {transform: translateY(-32px) rotate(180deg);}'}
`

const CoordinatesPanel: React.FC = () => {
  const [isVisible, setIsVisible] = useState(true)
  return (
    <StyledDiv $isVisible={isVisible}>
      <Icon color="grayG07" size="default">
        <SettingOutlined />
      </Icon>
      <CoordinatesPanelItems />
      <DividerElement size="default" type="vertical" color="grayG03" />
      <Icon onClick={() => setIsVisible((prev) => !prev)} color="primaryP04">
        <CaretDownFilled />
      </Icon>
    </StyledDiv>
  )
}

export default CoordinatesPanel
