import { Trans } from '@lingui/macro'
import { useDispatch } from 'react-redux'
import { setCreateBackupModalVisiblity } from 'redux/administration/administration-backups'
import AdministrationToolbarButton from '../toolbar'

const AdministrationBackupsToolbar: React.FC = () => {
  const dispatch = useDispatch()
  return (
    <AdministrationToolbarButton
      text={<Trans id="administration.backups.create_now_button_text">Create backup now</Trans>}
      onClick={() => dispatch(setCreateBackupModalVisiblity(true))}
    />
  )
}

export default AdministrationBackupsToolbar
