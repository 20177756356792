import React from 'react'
import { Color } from 'styled-components'
import Text from 'components/atoms/text'
import SpaceElement from '../space'
import { TextType } from '../text/text.types'

interface FoldableListProps<T extends { id: number | string }> {
  numberOfItemsIfFolded: number
  isFolded: boolean
  plusTextStyle?: TextType
  plusTextColor?: Color
  items: T[]
  renderItem: (item: T) => React.ReactNode
}

// eslint-disable-next-line @typescript-eslint/ban-types
const FoldableList = <T extends { id: number | string }>({
  numberOfItemsIfFolded,
  isFolded,
  items,
  renderItem,
  plusTextStyle = 'regularText-smallSemiBold',
  plusTextColor = 'baseBlackish',
}: FoldableListProps<T>): JSX.Element => {
  const allCount = items.length
  const displayed = isFolded ? numberOfItemsIfFolded : allCount
  return (
    <ul>
      {items.slice(0, displayed).map((item, idx) => (
        <li key={item.id.toString()}>
          <SpaceElement size="xxs" direction="horizontal">
            {renderItem(item)}
            {idx === displayed - 1 && allCount > displayed && (
              <Text color={plusTextColor} textStyle={plusTextStyle}>
                +{allCount - numberOfItemsIfFolded}
              </Text>
            )}
          </SpaceElement>
        </li>
      ))}
    </ul>
  )
}

FoldableList.defaultProps = {
  plusTextStyle: 'regularText-smallSemiBold',
  plusTextColor: 'baseBlackish',
}

export default FoldableList
