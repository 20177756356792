import TooltipElement from 'components/molecules/tooltip-element'
import Icon from 'components/atoms/icon'
import { Color } from 'styled-components'
import WithClickLoadingWrapper from 'components/atoms/with-click-loading'
import { TooltipProps } from 'antd/lib/tooltip'

interface ButtonProps {
  onClick?: React.MouseEventHandler<HTMLDivElement>
  tooltipText: string
  tooltipPlacement?: TooltipProps['placement']
  icon: React.ReactNode
  iconColor?: Color
  withLoading?: boolean
  disabled?: boolean
}

const ButtonWithTooltip: React.FC<ButtonProps> = ({
  onClick,
  tooltipText,
  tooltipPlacement = 'top',
  icon,
  iconColor = 'grayG05',
  withLoading = false,
  disabled = false,
}) => {
  const content = (
    <TooltipElement placement={tooltipPlacement} title={tooltipText}>
      <Icon
        color={disabled ? 'disabled' : iconColor}
        size="default"
        onClick={withLoading || disabled ? undefined : onClick}
      >
        {icon}
      </Icon>
    </TooltipElement>
  )
  if (withLoading) {
    return <WithClickLoadingWrapper onClick={disabled ? undefined : onClick}>{content}</WithClickLoadingWrapper>
  }
  return content
}

export default ButtonWithTooltip
