import { Space } from 'antd'
import { HolderOutlined } from '@ant-design/icons'
import Text from 'components/atoms/text'
import styled from 'styled-components'
import Icon from 'components/atoms/icon'

const StyledDiv = styled.div`
  display: flex;
  width: 350px;
  padding: ${(props) => props.theme.sizes.xxs};
  background-color: ${(props) => props.theme.colors.baseWhiteish};
  box-shadow: 4px 5px ${(props) => props.theme.colors.grayG02};
`

interface ItemProps {
  text: string
}

const DraggableItem: React.FC<ItemProps> = ({ text }) => {
  return (
    <StyledDiv>
      <Space size="middle">
        <Icon size="s">
          <HolderOutlined />
        </Icon>
        <Text textStyle="regularText-smallSemiBold" paragraph>
          {text}
        </Text>
      </Space>
    </StyledDiv>
  )
}

export default DraggableItem
