import { t, Trans } from '@lingui/macro'
import { Form, Spin, Card, Space, Alert } from 'antd'
import Text from 'components/atoms/text'
import ButtonElement from 'components/molecules/button-element'
import FormInputPassword from 'components/molecules/form-item/FormInputPassword'
import PasswordTemplate from 'components/templates/password'
import common_messages from 'data/messages/passwords'
import { errorText, saveText } from 'data/messages/controls'
import { required } from 'helpers/Validators.helpers'
import { handleApiErrors } from 'helpers/HttpError.helpers'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { selectChangePasswordLoading } from 'redux/password/Password.selectors'
import { changePassword } from 'redux/password/Password.slice'
import { useAppDispatch } from 'redux/store'
import trans from 'helpers/i18n.helpers'
import FormInputPasswordPanel from 'components/molecules/form-item/FormInputPasswordPanel'

const ChangePasswordPage: React.FC = () => {
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { isLoading, error } = useSelector(selectChangePasswordLoading)

  const handleFinish = (values: { oldPassword: string; password: string }) => {
    form.validateFields().then(() => {
      dispatch(
        changePassword({
          oldPassword: values.oldPassword,
          newPassword: values.password,
          onSuccess: () => {
            history.push('/')
          },
        })
      )
    })
  }

  return (
    <PasswordTemplate>
      <Spin spinning={isLoading}>
        <Card>
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <Text textStyle="header-H1" center>
              <Trans id="password_change.header_text">Edit password</Trans>
            </Text>
            <Form form={form} layout="vertical" onFinish={handleFinish}>
              <FormInputPassword
                label={<Trans id="password_change.current_password_label">Current password</Trans>}
                name="oldPassword"
                rules={[required()]}
                placeholder={t({
                  id: 'password_change.current_password_placeholder',
                  message: 'Enter current password',
                })}
              />
              <Space direction="vertical" size="small" style={{ width: '100%' }}>
                <FormInputPasswordPanel
                  passwordLabel={<Trans id="password_change.new_password_label">New password</Trans>}
                />
                <ButtonElement block size="large" htmlType="submit">
                  {trans(saveText)}
                </ButtonElement>
                <Link to="/">
                  <ButtonElement block type="tertiary" htmlType="submit">
                    <Trans id="password_change.return_to_portfolio_text">Return to portfolio</Trans>
                  </ButtonElement>
                </Link>
              </Space>
            </Form>
            {error && (
              <Alert
                message={trans(errorText)}
                description={handleApiErrors(common_messages, error.errors)}
                type="error"
                showIcon
              />
            )}
          </Space>
        </Card>
      </Spin>
    </PasswordTemplate>
  )
}

export default ChangePasswordPage
