import { ReactNode, FC } from 'react'
import {
  StyledViewer,
  StyledBottomPanel,
  StyledLeftAside,
  StyledTopPanel,
  StyledRightAside,
  StyledContent,
} from './ProjectTemplate.styles'

interface ProjectTemplateProps {
  viewer: ReactNode
  leftAside: ReactNode
  rightAside: ReactNode
  bottomPanel: ReactNode
  topPanel: ReactNode
}

const ProjectTemplate: FC<ProjectTemplateProps> = ({ leftAside, rightAside, viewer, bottomPanel, topPanel }) => {
  return (
    <StyledContent>
      <StyledViewer>{viewer}</StyledViewer>
      <StyledTopPanel>{topPanel}</StyledTopPanel>
      <StyledLeftAside>{leftAside}</StyledLeftAside>
      <StyledRightAside>{rightAside}</StyledRightAside>
      <StyledBottomPanel>{bottomPanel}</StyledBottomPanel>
    </StyledContent>
  )
}

export default ProjectTemplate
