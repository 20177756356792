import Text from 'components/atoms/text'
import { OrganizationDetails } from 'models/administration'

const DisplayOrganizationUsers: React.FC<Pick<OrganizationDetails, 'users'>> = ({ users }) => {
  return (
    <Text textStyle="regularText-small" color="grayG07">
      {users ? `${users.active} / ${users.inactive}` : ''}
    </Text>
  )
}

export default DisplayOrganizationUsers
