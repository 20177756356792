import { defineMessage } from '@lingui/macro'
import { MessageDescriptor } from '@lingui/core'
import UserProfilePermissions from './user-profile-content/permissions'

const userProfileOptions: {
  component?: React.ReactNode
  key: string
  name: MessageDescriptor
  items: {
    key: string
    name: MessageDescriptor
    component?: React.ReactNode
    heading?: React.ReactNode
  }[]
}[] = [
  {
    name: defineMessage({ id: 'profile.menu.my_profile', message: 'My profile' }),
    key: 'profile',
    items: [
      {
        key: 'general',
        name: defineMessage({ id: 'profile.menu.general', message: 'General' }),
      },
      {
        key: 'disciplines',
        name: defineMessage({ id: 'profile.menu.disciplines', message: 'Disciplines' }),
      },
    ],
  },
  {
    name: defineMessage({ id: 'profile.menu.project_settings', message: 'Project settings' }),
    key: 'projectSettings',
    items: [
      {
        key: 'permissions',
        name: defineMessage({ id: 'profile.menu.permissions', message: 'Permissions' }),
        component: <UserProfilePermissions />,
      },
      {
        key: 'groups',
        name: defineMessage({ id: 'profile.menu.groups', message: 'Groups' }),
      },
    ],
  },
]

export default userProfileOptions
