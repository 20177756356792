import { Space, Spin } from 'antd'
import Table from 'components/molecules/table'
import EditCodeLengthModal from 'components/organisms/administration/administration-content/modals/edit-code-length/EditCodeLengthModal'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useAppDispatch } from 'redux/store'
import styled from 'styled-components'

import {
  selectCodeLengthModalVisibility,
  selectFetchingCodeLengthLoading,
} from 'redux/administration/administration-code-settings/AdministrationCodeSettings.selectors'
import { fetchCodeLength } from 'redux/administration/administration-code-settings'
import { CodeTypes } from 'redux/administration/administration-code-settings/AdministrationCodeSettings.types'
import {
  selectAdministrationProjectStages,
  selectFetchingProjectStagesLoading,
  selectIsProjectStageModalVisible,
} from 'redux/administration/administration-project-stages/AdministrationProjectStages.selectors'
import { fetchProjectStages } from 'redux/administration/administration-project-stages'
import { t } from '@lingui/macro'
import useAdministrationProjectStagesColumns from './table-columns/useAdministrationProjectStagesColumns'
import AdministrationProjectStagesAlerts from './AdministrationProjectStagesAlerts'
import ProjectStageModal from './modals'

const StyledTable = styled(Table)`
  width: 825px;
  &&&& {
    td {
      &:nth-child(2) {
        text-align: center;
      }
    }
  }
`

const AdministrationProjectStages: React.FC = () => {
  const dispatch = useAppDispatch()
  const { isLoading: fetchingProjectStagesLoading } = useSelector(selectFetchingProjectStagesLoading)
  const { isLoading: fetchingCodeLoading } = useSelector(selectFetchingCodeLengthLoading)
  const stages = useSelector(selectAdministrationProjectStages)

  const columns = useAdministrationProjectStagesColumns()
  useEffect(() => {
    dispatch(fetchProjectStages())
    dispatch(fetchCodeLength(CodeTypes.PROJECT_STAGE_CODE))
  }, [dispatch])
  const isStagesModalVisible = useSelector(selectIsProjectStageModalVisible)
  const isCodeModalVisible = useSelector(selectCodeLengthModalVisibility)
  return (
    <Spin spinning={fetchingProjectStagesLoading || fetchingCodeLoading}>
      {!fetchingProjectStagesLoading && !fetchingCodeLoading && (
        <Space direction="vertical" size="middle">
          <StyledTable
            columns={columns}
            dataSource={stages}
            bordered
            pagination={false}
            rowKey="id"
            scroll={{ y: 'calc(100vh - 310px)' }}
          />
          {isStagesModalVisible && <ProjectStageModal />}
          <AdministrationProjectStagesAlerts />
          {isCodeModalVisible && (
            <EditCodeLengthModal
              codeType="PROJECT_STAGE_CODE"
              data={stages}
              reloadDataFun={fetchProjectStages}
              nameFieldLabel={t({
                id: 'administration.project_stages.code_length_modal.name_field_label',
                message: 'Name',
              })}
            />
          )}
        </Space>
      )}
    </Spin>
  )
}

export default AdministrationProjectStages
