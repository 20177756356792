import styled from 'styled-components'
import SpaceElement from 'components/atoms/space'
import Text from 'components/atoms/text'
import { FilesUploadName } from 'components/organisms/upload/single-file'
import { Trans } from '@lingui/macro'

interface AddFileHeadingProps {
  name: string
}

const StyledDiv = styled.div`
  padding: ${(props) => props.theme.sizes.m} 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.grayG03};
`

const AddFileHeading: React.FC<AddFileHeadingProps> = ({ name }) => {
  return (
    <StyledDiv>
      <SpaceElement direction="horizontal" size="l">
        <Text textStyle="regularText-medium" color="grayG06">
          <Trans id="project.files.add_file_modal.selected_file_text">Selected file:</Trans>
        </Text>
        <FilesUploadName name={name} />
      </SpaceElement>
    </StyledDiv>
  )
}

export default AddFileHeading
