import { ColumnsType } from 'antd/lib/table'
import useTableColumnFiltering, { onFilterStringColumn } from 'components/molecules/table/useTableColumnFiltering'
import TooltipElement from 'components/molecules/tooltip-element'
import { actionsTableColumnText } from 'data/messages/controls'
import { t, Trans } from '@lingui/macro'
import trans from 'helpers/i18n.helpers'
import {
  AttributeType,
  SchemeElementData,
} from 'redux/administration/administration-naming-scheme/AdministrationNamingScheme.types'
import FieldUsedInShortName from './FieldUsedInShortName'
import SchemeFieldActions from './SchemeFieldActions'
import SchemeFieldValuesDisplay from './SchemeFieldValuesDisplay'
import SelectFileNumberCodeLength from './SelectFileNumberCodeLength'

const useSchemeDetailsColumns = (
  isEditable: boolean,
  setUsedInShortName: (key: AttributeType, id: number | undefined, isDefault: boolean) => void,
  setNumberLength: (length: number) => void,
  removeField: (key: AttributeType, id: number | undefined) => void
): ColumnsType<SchemeElementData> => {
  return [
    {
      title: <Trans id="administration.naming_schemes.table.column_field_label">Field</Trans>,
      dataIndex: 'field',
      key: 'field',
      width: 250,
      render: (value, { name }) => name,
      ...useTableColumnFiltering(
        t({ id: 'administration.naming_schemes.table.search_field_placeholder', message: 'Search for field' })
      ),
      onFilter: (value: string | number | boolean, record: SchemeElementData) =>
        onFilterStringColumn(value, record.name),
    },
    {
      title: <Trans id="administration.naming_schemes.table.column_code_label">Code</Trans>,
      dataIndex: 'code',
      key: 'code',
      width: 100,
      render: (value, { code }) => code,
      ...useTableColumnFiltering(
        t({ id: 'administration.naming_schemes.table.search_code_placeholder', message: 'Search for code' })
      ),
      onFilter: (value: string | number | boolean, record: SchemeElementData) =>
        onFilterStringColumn(value, record.code),
    },
    {
      title: <Trans id="administration.naming_schemes.table.column_values_label">Values</Trans>,
      dataIndex: 'values',
      key: 'values',
      width: 250,
      render: (value, { values }) => <SchemeFieldValuesDisplay values={values} />,
    },
    {
      title: (
        <Trans id="administration.naming_schemes.table.column_field_in_short_name_label">Appears in short name</Trans>
      ),
      dataIndex: 'inShortName',
      key: 'inShortName',
      width: 150,
      render: (value, { type, args }) => (
        <FieldUsedInShortName
          args={args}
          type={type}
          disabled={!isEditable}
          onChange={(selected: boolean) => setUsedInShortName(type, args?.id, selected)}
        />
      ),
    },
    {
      title: <Trans id="administration.naming_schemes.table.column_code_length_label">Field length</Trans>,
      dataIndex: 'length',
      key: 'length',
      width: 125,
      render: (value, { length, type }) =>
        type === 'fileNumber' ? (
          <SelectFileNumberCodeLength length={length} onChange={isEditable ? setNumberLength : undefined} />
        ) : (
          <TooltipElement
            placement="bottom"
            disabled={type === 'revision' || type === 'date'}
            title={t({
              id: 'administration.naming_schemes.edit_field_length_info',
              message: 'Length may be changed in attributes or other metadata settings',
            })}
          >
            {length}
          </TooltipElement>
        ),
      ...useTableColumnFiltering(
        t({
          id: 'administration.naming_schemes.table.search_code_length_placeholder',
          message: 'Search for code length',
        })
      ),
      onFilter: (value: string | number | boolean, record: SchemeElementData) =>
        record.length ? onFilterStringColumn(value, record.length?.toString()) : false,
    },
    {
      title: trans(actionsTableColumnText),
      dataIndex: 'actions',
      key: 'actions',
      width: 75,
      render: (value, { type, args, name }) => (
        <SchemeFieldActions
          name={name}
          type={type}
          onDelete={isEditable ? () => removeField(type, args?.id) : undefined}
          metadataId={(type === 'metadata' && args?.id) || undefined}
          disabled={type === 'revision' || type === 'fileNumber'}
        />
      ),
    },
  ]
}

export default useSchemeDetailsColumns
