import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllCodeSettings } from 'redux/administration/administration-code-settings/AdministrationCodeSettings.slice'
import {
  fetchNamingScheme,
  fetchAvailableMetadata,
} from 'redux/project/project-config/naming-scheme/ProjectNamingSchemeConfig.slice'
import {
  selectAvailableMetadata,
  selectScheme,
} from 'redux/project/project-config/naming-scheme/ProjectNamingSchemeConfig.selectors'
import { Divider, Space } from 'antd'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import SchemeOrderDragger from 'components/organisms/scheme-order-dragger/SchemeOrderDragger'
import ProjectNamingSchemeAlerts from './ProjectNamingSchemeAlerts'
import SelectScheme from './scheme-chooser/SelectScheme'
import NamingSchemeTable from './scheme-chooser/NamingSchemeTable'

const ProjectNamingSchemeConfigContent: React.FC = () => {
  const dispatch = useDispatch()
  const selectedScheme = useSelector(selectScheme)
  const projectId = useSelector(selectProjectId)
  const attributes = useSelector(selectAvailableMetadata)
  useEffect(() => {
    dispatch(fetchAllCodeSettings())
  }, [dispatch])

  useEffect(() => {
    if (projectId) {
      dispatch(fetchNamingScheme(projectId))
      dispatch(fetchAvailableMetadata(projectId))
    }
  }, [dispatch, projectId])
  return (
    <>
      <SelectScheme />
      <Divider />
      <Space direction="vertical">
        <SchemeOrderDragger scheme={selectedScheme} attributes={attributes} disableDragging />
        <NamingSchemeTable scheme={selectedScheme} attributes={attributes} />
        <ProjectNamingSchemeAlerts />
      </Space>
    </>
  )
}

export default ProjectNamingSchemeConfigContent
