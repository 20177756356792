import { Trans } from '@lingui/macro'
import { useDispatch } from 'react-redux'
import { openSchemeModal } from 'redux/administration/administration-naming-scheme'
import AdministrationToolbarButton from '../toolbar'

const AdministrationNamingSchemeToolbar: React.FC = () => {
  const dispatch = useDispatch()
  return (
    <AdministrationToolbarButton
      text={<Trans id="administration.naming_schemes.add_scheme_button_text">Create new scheme</Trans>}
      onClick={() => dispatch(openSchemeModal())}
    />
  )
}

export default AdministrationNamingSchemeToolbar
