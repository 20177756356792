import SceneMode from 'cesium/Source/Scene/SceneMode'
import { RootState } from '../store'
import { FeatureInfo, MousePositionCoordinates, GeoserverAuthenticationData } from './CesiumViewer.slice'

export const selectFeaturesInfo = (state: RootState): FeatureInfo[] => state.cesiumViewer.featuresInfo
export const selectMousePositionCoordinates = (state: RootState): MousePositionCoordinates =>
  state.cesiumViewer.mousePositionCoordinates
export const selectGeoserverAuthenticationData = (state: RootState): GeoserverAuthenticationData =>
  state.cesiumViewer.geoserverAuthenticationData
export const selectSceneMode = (state: RootState): SceneMode => state.cesiumViewer.sceneMode
export const selectIsScaleLocked = (state: RootState): boolean => state.cesiumViewer.isScaleLocked
export const selectShouldCameraReset = (state: RootState): boolean => state.cesiumViewer.shouldCameraReset
