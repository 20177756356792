import { combineReducers } from 'redux'
import { wrapWithClear } from 'helpers/Functions.helpers'
import { filesClear } from 'redux/action.types'
import { gisProjectLayersReducer } from './map-gis-layers/map-gis-project-layers'
import { wmsLayersReducer } from './map-wms-layers'
import { gisFileLayersReducer } from './map-gis-layers/map-gis-file-layers'

const mapReducer = combineReducers({
  gisProjectLayers: gisProjectLayersReducer,
  gisFileLayers: gisFileLayersReducer,
  wmsLayers: wmsLayersReducer,
})

export default wrapWithClear(mapReducer, filesClear)
