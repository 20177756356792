import { useDispatch, useSelector } from 'react-redux'
import { Modal, Spin, Alert } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import Text from 'components/atoms/text'
import Icon from 'components/atoms/icon'
import { handleApiErrors } from 'helpers/HttpError.helpers'
import { User } from 'models/administration'
import { useEffect } from 'react'
import {
  selectAddingUsersLoading,
  selectEditedUser,
  selectEditingAdminLoading,
} from 'redux/administration/administration-users/AdministrationUsers.selectors'
import {
  addUser,
  editUserAccount,
  hideUserModal,
} from 'redux/administration/administration-users/AdministrationUsers.slice'
import { NewUser } from 'redux/administration/administration-users/AdministrationUsers.types'
import { fetchOrganizations } from 'redux/administration/administration-organizations'
import { BackendLoading } from 'redux/redux.shared'
import { ApiError } from 'models/errors'
import { fetchDisciplines } from 'redux/administration/administration-disciplines'
import { arraysHaveSameElements } from 'helpers/Collection.helpers'
import objectsDiff from 'helpers/Objects.helpers'
import { defineMessage, t } from '@lingui/macro'
import { errorText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import useFormValidity from 'hooks/useFormValidity'
import ModalFooter from '../ModalFooter'
import UserModalForm from './UserModalForm'
import { emailSendingFailedError } from '../../texts'

interface ModalProps {
  visible: boolean
}

interface EditFields {
  isAdmin: boolean
  disciplines: number[]
  email: string
}

interface UserLoadingValues {
  loading: boolean
  text: string
  errors: ApiError[] | undefined
  isCreateModal: boolean
  isAdmin: boolean
}

const errorMessageDict = {
  'user.email_already_used': defineMessage({
    id: 'administration.users.email_already_used_error',
    message: 'The given email address is already used',
  }),
  'user.cannot_remove_last_admin': defineMessage({
    id: 'administration.users.modal.cannot_remove_last_admin_error',
    message: 'At least one admin must remain active in the system',
  }),
  'user.cannot_change_active_user_email': defineMessage({
    id: 'administration.users.modal.cannot_change_active_user_email',
    message: 'Email address of an active user cannot be changed.',
  }),
  'email.sending_failed': emailSendingFailedError,
}

function getValues(
  editedUser: User | undefined,
  editLoading: BackendLoading,
  addLoading: BackendLoading
): UserLoadingValues {
  const isCreateModal = editedUser === undefined
  const loading: boolean = !isCreateModal ? editLoading.isLoading : addLoading.isLoading
  const text: string = !isCreateModal
    ? t({ id: 'administration.users.modal.edit_user_header', message: 'Edit user' })
    : t({ id: 'administration.users.modal.add_user_header', message: 'Add user' })
  const errors =
    editLoading.error !== undefined || addLoading.error !== undefined
      ? (editLoading.error?.errors ?? addLoading.error?.errors) || []
      : undefined
  const isAdmin = editedUser === undefined ? false : editedUser.isAdmin
  return { loading, text, errors, isCreateModal, isAdmin }
}

const UserModal: React.FC<ModalProps> = ({ visible }) => {
  const { form, isFormValid, validate, getFormItemErrors } = useFormValidity()
  const dispatch = useDispatch()
  const addLoading = useSelector(selectAddingUsersLoading)
  const editLoading = useSelector(selectEditingAdminLoading)
  const editedUser = useSelector(selectEditedUser)
  const loadingValues = getValues(editedUser, editLoading, addLoading)
  const handleSubmit = () => {
    if (editedUser === undefined) {
      const fields: NewUser = form.getFieldsValue()
      return dispatch(addUser(fields))
    }
    const formValues: EditFields = form.getFieldsValue()
    const diff = objectsDiff(
      {
        isAdmin: editedUser.isAdmin,
        email: editedUser.email,
        disciplines: editedUser.disciplines.map((d) => d.id),
      },
      formValues,
      { disciplines: arraysHaveSameElements }
    )
    return dispatch(
      editUserAccount({
        id: editedUser.id,
        ...diff,
      })
    )
  }
  const handleCancel = () => dispatch(hideUserModal())
  useEffect(() => {
    dispatch(fetchOrganizations())
    dispatch(fetchDisciplines())
  }, [dispatch])
  return (
    <Modal
      visible={visible}
      onCancel={() => dispatch(hideUserModal())}
      closeIcon={
        <Icon>
          <CloseOutlined />
        </Icon>
      }
      maskClosable={false}
      title={<Text textStyle="header-H2b">{loadingValues.text}</Text>}
      footer={
        <ModalFooter
          isCreateModal={loadingValues.isCreateModal}
          loading={loadingValues.loading}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          submitEnabled={isFormValid}
        />
      }
    >
      <Spin spinning={loadingValues.loading}>
        {!loadingValues.isCreateModal && (
          <Text textStyle="regularText-medium" color="grayG04">
            {editedUser?.firstName} {editedUser?.lastName}
          </Text>
        )}
        <UserModalForm
          form={form}
          loadingValues={loadingValues}
          validate={validate}
          getFormItemErrors={getFormItemErrors}
        />
        {loadingValues.errors !== undefined && (
          <Alert
            message={trans(errorText)}
            description={handleApiErrors(errorMessageDict, loadingValues.errors)}
            type="error"
            showIcon
          />
        )}
      </Spin>
    </Modal>
  )
}

export default UserModal
