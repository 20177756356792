import { Cartesian2, Viewer as CViewer, Math as CMath, Cartographic } from 'cesium'
import { CesiumComponentRef, CesiumMovementEvent } from 'resium'
import { useCallback, MutableRefObject, useRef } from 'react'
import { useAppDispatch } from 'redux/store'
import { setMousePositionCoordinates } from 'redux/cesium-viewer'
import { setImageToUpdate, setMapSettingsToUpdate } from 'redux/project/project-config/map'

interface UseCesiumActionsReturnValue {
  handleCameraMoveEnd: () => void
  onPreRender: () => void
  handleMouseMoveAction: (movementEvent: CesiumMovementEvent) => void
  handlePostRender: () => void
}

const useCesiumActions = (
  isConfigMode: boolean,
  viewerRef: MutableRefObject<CesiumComponentRef<CViewer> | null>
): UseCesiumActionsReturnValue => {
  const dispatch = useAppDispatch()
  const initialScreenshotTakenRef = useRef(false)

  const handleSetMousePositionCoordinates = useCallback(
    (x: number, y: number): boolean => {
      const scene = viewerRef.current?.cesiumElement?.scene
      if (scene) {
        const { ellipsoid } = scene.globe
        const cartesian = scene.camera.pickEllipsoid(new Cartesian2(x, y), ellipsoid)
        // gets the point on the surface of the ellipsoid in world coordinates base on windowPosition x and y from mouse event
        if (cartesian) {
          const cartographic = ellipsoid.cartesianToCartographic(cartesian)
          const longitude = CMath.toDegrees(cartographic.longitude)
          const latitude = CMath.toDegrees(cartographic.latitude)
          dispatch(setMousePositionCoordinates({ longitude, latitude }))
          return true
        }
      }
      return false
    },
    [dispatch, viewerRef]
  )

  const handleSavingScreenshot = () => {
    const viewer = viewerRef.current?.cesiumElement
    if (viewer && isConfigMode) {
      const currentImage = viewer.scene.canvas.toDataURL('image/png')
      if (currentImage) {
        dispatch(setImageToUpdate(currentImage))
      }
    }
  }

  const handlePostRender = () => {
    if (!initialScreenshotTakenRef.current && viewerRef.current?.cesiumElement?.scene.globe.tilesLoaded) {
      handleSavingScreenshot()
      initialScreenshotTakenRef.current = true
    }
  }

  const handleCameraMoveEnd = () => {
    const currentCameraPosition = viewerRef.current?.cesiumElement?.scene.camera.positionWC
    if (isConfigMode && currentCameraPosition) {
      const { latitude, longitude, height } = Cartographic.fromCartesian(
        currentCameraPosition,
        viewerRef.current?.cesiumElement?.scene.globe.ellipsoid
      )
      const degByPi = 180 / Math.PI
      dispatch(
        setMapSettingsToUpdate({
          longitude: longitude * degByPi,
          latitude: latitude * degByPi,
          scale: Math.floor(height),
        })
      )
      handleSavingScreenshot()
    }
  }

  const onPreRender = useCallback(() => {
    let isMousePositionInitialised = false
    return () => {
      if (!isMousePositionInitialised) {
        isMousePositionInitialised = handleSetMousePositionCoordinates(0, 0)
      }
    }
  }, [handleSetMousePositionCoordinates])

  const handleMouseMoveAction = (movementEvent: CesiumMovementEvent): void => {
    const x = movementEvent.endPosition?.x
    const y = movementEvent.endPosition?.y
    if (x && y) {
      handleSetMousePositionCoordinates(x, y)
    }
  }
  // const handleSetZoomLevel = useCallback(() => {
  //   const cameraPosition = viewerRef.current?.cesiumElement?.scene.camera.positionWC
  //   if (cameraPosition && viewerRef.current?.cesiumElement?.scene) {
  //     const ellipsoidPosition = viewerRef.current?.cesiumElement?.scene.globe.ellipsoid.scaleToGeodeticSurface(
  //       cameraPosition
  //     )
  //     const distance = Cartesian3.magnitude(Cartesian3.subtract(cameraPosition, ellipsoidPosition, new Cartesian3()))
  //     dispatch(setZoomLevel(distance))
  //   }
  // }, [dispatch])

  return { handleCameraMoveEnd, onPreRender, handleMouseMoveAction, handlePostRender }
}

export default useCesiumActions
