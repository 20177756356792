import { useSelector } from 'react-redux'
import { FileOutlined } from '@ant-design/icons'
import formatDate from 'helpers/Dates.helpers'
import { selectEntriesCommonMetadata } from 'redux/files/files-details/FilesDetails.selectors'
import { ifNotEmpty, formatRevision, metadataDetailsToAttributes, formatSuitabilityCode } from 'helpers/Files.helpers'
import { selectCheckedFrontendFiles } from 'redux/files/files-table/FilesTable.selectors'
import useFilesCommonMetadata from 'hooks/filesCommonMetadata'
import { getFlowStatusTextWithDate } from 'data/flow/status'
import { OrganizationOverview } from 'models/files'
import { t } from '@lingui/macro'
import { acceptanceStatusText, cdeDirectoryText, revisionText, suitabilityCodeText } from 'data/messages/misc'
import trans from 'helpers/i18n.helpers'
import FilesDetailsContent from './FileDetailsContent'

const FilesDetailsDoc: React.FC = () => {
  const entriesCommonMetadata = useSelector(selectEntriesCommonMetadata)
  const [checkedFrontendFile] = useSelector(selectCheckedFrontendFiles)
  useFilesCommonMetadata()
  const attributes = metadataDetailsToAttributes(entriesCommonMetadata || [])
  const {
    filename,
    originalFilename,
    description,
    revision,
    numberOfVersions,
    cdeStatus,
    creator,
    uploadDate,
    lastUpdated,
    suitabilityCode,
    currentFlow,
  } = checkedFrontendFile
  const formatOrganization = (organization: OrganizationOverview) => {
    const abbreviationStr = organization.abbreviation ? ` (${organization.abbreviation})` : ''
    return `${organization.name}${abbreviationStr}`
  }
  const characteristics = [
    {
      name: t({ id: 'project.files.file_info.name', message: 'Name' }),
      value: filename,
    },
    {
      name: t({ id: 'project.files.file_info.description', message: 'Description' }),
      value: description,
    },
    {
      name: t({ id: 'project.files.file_info.original_filename', message: 'Original filename' }),
      value: originalFilename,
    },
    ...ifNotEmpty(revision && formatRevision(revision), {
      name: trans(revisionText),
    }),
    ...ifNotEmpty(numberOfVersions, {
      name: t({ id: 'project.files.file_info.number_of_versions', message: 'Number of revisions' }),
    }),
    ...ifNotEmpty(cdeStatus, {
      name: trans(cdeDirectoryText),
      key: 'status',
    }),
    ...ifNotEmpty(suitabilityCode ? formatSuitabilityCode(suitabilityCode) : undefined, {
      name: trans(suitabilityCodeText),
    }),
    {
      name: trans(acceptanceStatusText),
      value: getFlowStatusTextWithDate(currentFlow.status, cdeStatus),
    },
    {
      name: t({ id: 'project.files.file_info.author', message: 'Author' }),
      value: creator.name,
    },
    {
      name: t({ id: 'project.files.file_info.authors_organization', message: "Author's organization" }),
      value: formatOrganization(creator.organization),
    },
    ...ifNotEmpty(uploadDate && formatDate(uploadDate), {
      name: t({ id: 'project.files.file_info.add_date', message: 'Date added' }),
    }),
    ...ifNotEmpty(lastUpdated && formatDate(lastUpdated), {
      name: t({ id: 'project.files.file_info.update_date', message: 'Update date' }),
    }),
  ]
  return (
    <FilesDetailsContent
      title={checkedFrontendFile.filename}
      icon={<FileOutlined />}
      attributes={attributes}
      characteristics={characteristics}
    />
  )
}

export default FilesDetailsDoc
