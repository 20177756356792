import { DownOutlined, RightOutlined } from '@ant-design/icons'
import { TableProps } from 'antd/lib/table'
import { useEffect, useState } from 'react'
import Table from './Table'

export interface ExpandableTableColumnType {
  isTableRowExpanded?: boolean
}

type IndexedRecord<T> = T & { tableRowIdx: number; isTableRowExpanded?: boolean }

// eslint-disable-next-line @typescript-eslint/ban-types
const addExpandableData: <T extends object>(data: T[]) => IndexedRecord<T>[] = (data) => {
  return data.map((elem, idx) => ({ ...elem, tableRowIdx: idx, isTableRowExpanded: false }))
}

const expandColumn = {
  title: '',
  dataIndex: 'expand',
  key: 'expand',
  width: 20,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render: (_: any, { isTableRowExpanded }: { isTableRowExpanded?: boolean }) => {
    return isTableRowExpanded ? <DownOutlined /> : <RightOutlined />
  },
}

// eslint-disable-next-line @typescript-eslint/ban-types
const ExpandableTable = <T extends object>({
  dataSource,
  columns,
  ...props
}: TableProps<T & ExpandableTableColumnType>): React.ReactElement => {
  const [data, setData] = useState<IndexedRecord<T>[]>([])
  useEffect(() => {
    setData(addExpandableData(dataSource || []))
  }, [dataSource])
  const toggleRowExpanded: (rowKey: number) => void = (rowKey) => {
    setData(
      data.map((d) =>
        d.tableRowIdx !== rowKey
          ? d
          : {
              ...d,
              isTableRowExpanded: !d.isTableRowExpanded,
            }
      )
    )
  }

  return (
    <Table
      columns={[expandColumn, ...(columns || [])]}
      dataSource={data}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onRow={(record: IndexedRecord<T>) => {
        return {
          onClick: () => {
            toggleRowExpanded(record.tableRowIdx)
          },
        }
      }}
    />
  )
}

export default ExpandableTable
