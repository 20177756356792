import ErrorAlert from 'components/atoms/error-alert'
import { handleApiErrors } from 'helpers/HttpError.helpers'
import { useSelector } from 'react-redux'
import { defineMessage } from '@lingui/macro'
import {
  selectRemovingAttributeLoading,
  selectRemovingAttributesValueLoading,
} from 'redux/administration/administration-attributes/AdministrationAttributes.selectors'

const removeAttributeErrorDict = {
  'metadata.is_used': defineMessage({
    id: 'administration.attributes.attribute_is_used_error',
    message: 'Attribute is used.',
  }),
  'metadata.used_in_project': defineMessage({
    id: 'administration.attributes.cannot_delete_used_in_project_error',
    message: 'Attribute is used in project(s) and cannot be deleted.',
  }),
}

const removeAttributeValueErrorDict = {
  'metadata_values.currently_used': defineMessage({
    id: 'administration.attributes.used_value_cannot_be_deleted_error',
    message: 'Attribute value is used and cannot be deleted.',
  }),
}

const AdministrationAttributesAlerts: React.FC = () => {
  const removingAttributeLoading = useSelector(selectRemovingAttributeLoading)
  const removingValueAttributeLoading = useSelector(selectRemovingAttributesValueLoading)
  const alertMessageDict = [
    {
      error: removingAttributeLoading.error,
      message: handleApiErrors(removeAttributeErrorDict, removingAttributeLoading.error?.errors),
    },
    {
      error: removingValueAttributeLoading.error,
      message: handleApiErrors(removeAttributeValueErrorDict, removingValueAttributeLoading.error?.errors),
    },
  ]
  return <ErrorAlert alertMessageDict={alertMessageDict} />
}

export default AdministrationAttributesAlerts
