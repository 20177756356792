import { Space } from 'antd'
import Text from 'components/atoms/text'
import ButtonElement from 'components/molecules/button-element'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectEntriesStructure,
  selectIsAddingEntry,
} from 'redux/project/project-config/entries-structure/ProjectEntriesStructureConfig.selectors'
import {
  addEntry,
  changeEntry,
  getEntriesStructure,
  removeEntry,
  setAddingEntry,
} from 'redux/project/project-config/entries-structure'
import styled from 'styled-components'
import NameAndId from 'models/shared'
import { itemArr } from 'helpers/Collection.helpers'
import trans from 'helpers/i18n.helpers'
import { selectText } from 'data/messages/controls'
import { Entry, EntryValue, EntryValuesLabelsDict } from 'models/project/projectConfig'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import { functionIf } from 'helpers/Functions.helpers'
import { Trans } from '@lingui/macro'
import { selectSelectedMetadata } from 'redux/project/project-config/attributes/ProjectAttributesConfig.selectors'
import { fetchMetadata } from 'redux/project/project-config/attributes'
import SelectEntryConfig from './SelectEntryConfig'
import ProjectEntriesStructureAlerts from './ProjectEntriesStructureAlerts'

const StyledSpace = styled(Space)`
  width: 450px;
`

interface Option {
  label: string
  value: Exclude<Entry['key'], 'metadata'> | number
}

const isNotMetadata = (val: EntryValue | number): boolean =>
  typeof val === 'string' && ['discipline', 'initiator', 'stage', 'status'].includes(val)

const createCurrentOption = (availableMetadata: NameAndId[], value: Option['value']): Option => ({
  label: EntryValuesLabelsDict[value as EntryValue]
    ? trans(EntryValuesLabelsDict[value as EntryValue])
    : (availableMetadata.find((metadata) => metadata.id === value)?.name as string),
  value,
})

const doesKeyExistInEntryStructure = (entryStructure: Entry[], key: Entry['key']): boolean =>
  !entryStructure.find((entry) => entry.key === key)

const getAvailableOptions = (
  availableMetadata: NameAndId[],
  entryStructure: Entry[],
  value?: Option['value']
): Option[] => [
  ...itemArr(createCurrentOption(availableMetadata, value as Option['value']), !!value),
  ...itemArr(
    { label: trans(EntryValuesLabelsDict.discipline), value: 'discipline' as const },
    doesKeyExistInEntryStructure(entryStructure, 'discipline')
  ),
  ...itemArr(
    { label: trans(EntryValuesLabelsDict.initiator), value: 'initiator' as const },
    doesKeyExistInEntryStructure(entryStructure, 'initiator')
  ),
  ...itemArr(
    { label: trans(EntryValuesLabelsDict.stage), value: 'stage' as const },
    doesKeyExistInEntryStructure(entryStructure, 'stage')
  ),
  ...itemArr(
    { label: trans(EntryValuesLabelsDict.status), value: 'status' as const },
    doesKeyExistInEntryStructure(entryStructure, 'status')
  ),
  ...availableMetadata
    .filter(({ id }) => !entryStructure.find((entry) => entry.id === id))
    .map(({ id, name }) => ({ label: name, value: id })),
]

const getEntryFromValue = (value: Option['value']): Entry => ({
  key: isNotMetadata(value) ? (value as Entry['key']) : 'metadata',
  id: isNotMetadata(value) ? undefined : (value as number),
})

const ProjectEntriesStructureConfigContent: React.FC = () => {
  const dispatch = useDispatch()
  const entriesStructure = useSelector(selectEntriesStructure)
  const isAddingEntry = useSelector(selectIsAddingEntry)
  const selectedMetadata = useSelector(selectSelectedMetadata)
  const projectId = useSelector(selectProjectId)
  useEffect(() => {
    if (projectId) {
      dispatch(getEntriesStructure(projectId))
      dispatch(fetchMetadata(projectId))
    }
  }, [dispatch, projectId])
  return (
    <Space direction="vertical" size="large">
      <Text textStyle="regularText-medium">
        <Trans id="project_config.entries_structure.step_description">Default smart directories structure</Trans>
      </Text>
      <StyledSpace direction="vertical" size="small">
        {entriesStructure.map(({ key, id }, index) => (
          <SelectEntryConfig
            onDeleteEntry={functionIf(() => dispatch(removeEntry(index)), entriesStructure.length !== 1)}
            onChange={(val) =>
              dispatch(
                changeEntry({
                  index,
                  entry: getEntryFromValue(val as Option['value']),
                })
              )
            }
            key={id || key}
            entryLevel={index + 1}
            options={getAvailableOptions(
              selectedMetadata,
              entriesStructure,
              isNotMetadata(key) ? (key as Option['value']) : (id as number)
            )}
            value={isNotMetadata(key) ? key : id}
          />
        ))}
        {isAddingEntry && (
          <SelectEntryConfig
            entryLevel={entriesStructure.length + 1}
            placeholder={trans(selectText)}
            onChange={(val) => dispatch(addEntry(getEntryFromValue(val as Option['value'])))}
            options={getAvailableOptions(selectedMetadata, entriesStructure)}
            onDeleteEntry={() => dispatch(setAddingEntry(false))}
          />
        )}
      </StyledSpace>
      <ButtonElement onClick={() => dispatch(setAddingEntry(true))} type="secondary">
        <Trans id="project_config.entries_structure.add_level_button">Add level</Trans>
      </ButtonElement>
      <ProjectEntriesStructureAlerts />
    </Space>
  )
}

export default ProjectEntriesStructureConfigContent
