import trans from 'helpers/i18n.helpers'
import { MetadataGroupData } from 'models/files'
import { cdeDirectoryText, disciplineText } from './messages/misc'

const projectEntryKeyDict = (name: MetadataGroupData | undefined): { [key: string]: string } => ({
  discipline: trans(disciplineText),
  status: trans(cdeDirectoryText),
  metadata: (name?.name as string) || '',
})

export default projectEntryKeyDict
