import { Member, MemberOrganization } from 'models/project/projectConfig'
import { BackendLoading } from 'redux/redux.shared'
import { ActiveUser } from 'models/users'

export const loadingTypes = [
  'fetchProjectMembers',
  'setProjectMemberActivity',
  'removeProjectMember',
  'fetchActiveUsers',
  'fetchOrganizations',
  'addProjectMembers',
] as const
export type LoadingTypes = typeof loadingTypes[number]

export interface ProjectMembersConfigState {
  loading: Record<LoadingTypes, BackendLoading>
  members: Member[]
  isModalVisible: boolean
  users?: ActiveUser[]
  organizations?: MemberOrganization[]
}
