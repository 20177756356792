import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import FormsValidityInitialState from './FormsValidity.types'

const initialState: FormsValidityInitialState = {
  forms: {},
}

const formsValiditySlice = createSlice({
  name: 'formsValidity',
  initialState,
  reducers: {
    setFormValidity: (state, action: PayloadAction<{ formName: string; validity: boolean }>) => {
      const { formName, validity } = action.payload
      state.forms = { ...state.forms, [formName]: validity }
    },
    deleteFormValidation: (state, action: PayloadAction<string>) => {
      const formName = action.payload
      delete state.forms[formName]
    },
  },
})

export const { deleteFormValidation, setFormValidity } = formsValiditySlice.actions

export const formsValidityReducer = formsValiditySlice.reducer
