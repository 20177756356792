import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import SceneMode from 'cesium/Source/Scene/SceneMode'
import Divider from 'components/atoms/divider'
import MapPerspectiveSwitcherItem from 'components/molecules/map/map-perspective-switcher'
import { setSceneMode } from 'redux/cesium-viewer'
import { selectSceneMode } from 'redux/cesium-viewer/CesiumViewer.selectors'

const StyledDiv = styled.div`
  background-color: ${(props) => props.theme.colors.baseWhiteish};
  border-radius: 20px;
  overflow: hidden;
  > *:nth-child(2) {
    margin: -4px 0;
  }
`

const MapToolBarPerspectiveSwitcher: React.FC = () => {
  const dispatch = useDispatch()
  const sceneMode = useSelector(selectSceneMode)
  return (
    <StyledDiv>
      <MapPerspectiveSwitcherItem
        onClick={() => dispatch(setSceneMode(SceneMode.SCENE2D))}
        active={sceneMode === SceneMode.SCENE2D}
      >
        2D
      </MapPerspectiveSwitcherItem>
      <Divider color="grayG02" />
      <MapPerspectiveSwitcherItem
        onClick={() => dispatch(setSceneMode(SceneMode.SCENE3D))}
        active={sceneMode === SceneMode.SCENE3D}
      >
        3D
      </MapPerspectiveSwitcherItem>
    </StyledDiv>
  )
}

export default MapToolBarPerspectiveSwitcher
