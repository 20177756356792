import { RootState } from 'redux/store'
import { ProjectLayer } from 'models/map'
import { BackendLoading } from 'redux/redux.shared'
import { LayerInfo } from './MapWmsLayers.slice'

export const selectWmsLayers = (state: RootState): ProjectLayer[] => state.map.wmsLayers.layers
export const selectCheckedWmsLayerNodes = (state: RootState): (string | number)[] =>
  state.map.wmsLayers.checkedLayerNodes
export const selectWmsLayersInfo = (state: RootState): LayerInfo[] => state.map.wmsLayers.layersInfo
export const selectFetchWmsLayersLoading = (state: RootState): BackendLoading =>
  state.map.wmsLayers.loading.fetchWmsLayers
