import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import { Organization } from 'models/administration'
import { FlowConditionsConfig } from 'models/flow'
import {
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
  getStateAsAny,
} from 'redux/redux.shared'
import { selectFlowConditionsConfig } from './FlowConditionsConfig.selectors'
import { FlowConditionsConfigState, loadingType, LoadingType } from './FlowConditionsConfig.types'

export const getFlowConditions = createAsyncBackendThunk(
  'flowConditionsConfig/getFlowConditions',
  async ({ projectId, flowId }: { projectId: number; flowId: number }) => {
    return (await backendAxios.get(`/projects/${projectId}/flows/config/${flowId}/conditions/general/`)).data
  }
)

export const updateFlowConditions = createAsyncBackendThunk(
  'flowConditionsConfig/updateFlowConditions',
  async ({ projectId, flowId }: { projectId: number; flowId: number }, { getState }) => {
    const state = getStateAsAny(getState)
    const flowConditionsConfig = selectFlowConditionsConfig(state)
    const shouldSendUsersList =
      flowConditionsConfig?.initialUsersList.length !== 0 &&
      (flowConditionsConfig?.initialUsersTypes as string | undefined)?.includes('SELECTED')
    await backendAxios.patch(`/projects/${projectId}/flows/config/${flowId}/conditions/general/`, {
      ...flowConditionsConfig,
      initialUsersList: shouldSendUsersList ? flowConditionsConfig?.initialUsersList : undefined,
    })
  }
)

export const getOrganizations = createAsyncBackendThunk('flowConditionsConfig/getOrganizations', async () => {
  return (await backendAxios.get('/organizations/')).data
})

const initialState: FlowConditionsConfigState = {
  loading: generateInitialLoadingState<LoadingType>(loadingType),
  organizations: [],
}

const flowConditionsConfigSlice = createSlice({
  name: 'flowConditionsConfig',
  initialState,
  reducers: {
    updateFlowConditionsConfig: (state, action: PayloadAction<FlowConditionsConfig>) => {
      state.flowConditionsConfig = { ...state.flowConditionsConfig, ...action.payload }
    },
    setInitialUsersTypes: (state, action: PayloadAction<FlowConditionsConfig['initialUsersTypes']>) => {
      if (state.flowConditionsConfig) {
        state.flowConditionsConfig.initialUsersTypes = action.payload
        if (!(action.payload as string[]).includes('SELECTED')) {
          state.flowConditionsConfig.initialUsersList = []
        }
      }
    },
    setInitialUsersList: (state, action: PayloadAction<FlowConditionsConfig['initialUsersList']>) => {
      if (state.flowConditionsConfig) {
        state.flowConditionsConfig.initialUsersList = action.payload
      }
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<FlowConditionsConfigState, LoadingType, FlowConditionsConfig>({
      promise: getFlowConditions,
      loadingType: 'getFlowConditions',
      onFulfilled: (state, action) => {
        state.flowConditionsConfig = action.payload
      },
    }),
    generateExtraBackendReducers<FlowConditionsConfigState, LoadingType, Organization[]>({
      promise: getOrganizations,
      loadingType: 'getOrganizations',
      onFulfilled: (state, action) => {
        state.organizations = action.payload
      },
    }),
    generateExtraBackendReducers<FlowConditionsConfigState, LoadingType, Organization[]>({
      promise: updateFlowConditions,
      loadingType: 'updateFlowConditions',
    }),
  ]),
})

export const {
  setInitialUsersTypes,
  setInitialUsersList,
  updateFlowConditionsConfig,
} = flowConditionsConfigSlice.actions

export const flowConditionsConfigReducer = flowConditionsConfigSlice.reducer
