import { PlusOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import Icon from '../icon'

interface PlusProps {
  onClick?: () => void
}

const StyledDiv = styled.div`
  width: ${(props) => props.theme.sizes.l};
  height: ${(props) => props.theme.sizes.l};
  ${(props) => props.theme.mixins.flexCenter}
  border: 1px solid ${(props) => props.theme.colors.primaryPO5};
  border-radius: ${(props) => props.theme.sizes.xs};
  cursor: pointer;
`

const Plus: React.FC<PlusProps> = ({ onClick }) => {
  return (
    <StyledDiv onClick={onClick}>
      <Icon size="default" color="primaryP05">
        <PlusOutlined />
      </Icon>
    </StyledDiv>
  )
}

export default Plus
