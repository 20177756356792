import { Trans } from '@lingui/macro'
import { Space } from 'antd'
import Text from 'components/atoms/text'
import { useSelector } from 'react-redux'
import { selectProjectStages } from 'redux/project/project-config/stages/ProjectStagesConfig.selectors'
import AddingStagesPopover from './adding-stages-popover/AddingStagesPopover'
import StagesDragger from './stages-dragger/StagesDragger'

const StagesManagement: React.FC = () => {
  const stages = useSelector(selectProjectStages)?.stages
  return (
    <Space direction="vertical" style={{ overflow: 'auto', width: '100%' }}>
      <Text textStyle="regularText-small" color="grayG06">
        <Trans id="project_config.stages.dragger.order.description">Project&apos;s stages order</Trans>
      </Text>
      {(!stages || stages.length === 0) && (
        <Text textStyle="regularText-smallSemiBold" color="grayG07">
          <Trans id="project_config.stages.dragger.order.alert">Add at least one stage.</Trans>
        </Text>
      )}
      <Space style={{ flexWrap: 'wrap', gap: 8, width: '1100px' }}>
        <StagesDragger />
        <AddingStagesPopover />
      </Space>
    </Space>
  )
}

export default StagesManagement
