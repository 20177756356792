import { useSelector } from 'react-redux'
import {
  selectFetchDisciplinesLoading,
  selectIsDisciplinesConfigValid,
} from 'redux/project/project-config/disciplines/ProjectDisciplinesConfig.selectors'
import { updateDisciplinesConfig } from 'redux/project/project-config/disciplines/ProjectDisciplinesConfig.slice'
import BaseProjectConfigView from '../base-config-view/BaseProjectConfigView'
import ProjectDisciplinesConfigContent from './content'

const ProjectDisciplinesConfig: React.FC = () => {
  const { isLoading } = useSelector(selectFetchDisciplinesLoading)
  const isContentValid = useSelector(selectIsDisciplinesConfigValid)
  return (
    <BaseProjectConfigView
      isContentLoading={isLoading}
      content={<ProjectDisciplinesConfigContent />}
      saveStepThunk={updateDisciplinesConfig()}
      isContentValid={isContentValid}
    />
  )
}

export default ProjectDisciplinesConfig
