import { itemArr, withStepTitles } from 'helpers/Collection.helpers'
import { t } from '@lingui/macro'
import StepData from 'models/components'
import { useSelector } from 'react-redux'
import { selectProjectDetails } from 'redux/project/project-details/ProjectDetails.selectors'
import { selectFinishedSteps } from 'redux/project/project-config/status/ProjectStatusConfig.selectors'
import { Step, steps } from 'redux/project/project-config/status/ProjectStatusConfig.types'

const getStepValidity = (stepName: Step, finishedSteps: Step[]): boolean =>
  !!finishedSteps?.find((step) => step === stepName)

const getStepFinishStatus = (stepName: Step, finishedSteps: Step[]): boolean => {
  return getStepValidity(stepName, finishedSteps)
}

const getIsStepAfterFinishStepsStatus = (finishedSteps: Step[], stepIdx: number): boolean => {
  return finishedSteps.length === stepIdx
}

const getStepsData: (isProjectActive: boolean, finishedSteps: Step[], stepIdx: number) => StepData[] = (
  isProjectActive,
  finishedSteps,
  currentStepIdx
) => {
  const getStepData = (description: string, stepName?: Step): Omit<StepData, 'title'> => {
    const isFinishedStep = isProjectActive || (stepName && getStepFinishStatus(stepName, finishedSteps))
    const stepIdx = steps.findIndex((step) => step === stepName)
    const isFlowDefinitionStep = isProjectActive && steps[currentStepIdx + 1] === undefined && stepName === undefined
    const isCurrentStep = stepName === steps[currentStepIdx] || isFlowDefinitionStep
    const isStepAfterFinishedSteps = getIsStepAfterFinishStepsStatus(finishedSteps, stepIdx)
    const statusWhenNotStepFinished = isCurrentStep ? 'process' : 'wait'
    const statusWhenStepFinished = isCurrentStep ? 'process' : 'finish'
    return {
      description,
      disabled: !isFinishedStep && !isCurrentStep && !isStepAfterFinishedSteps,
      status: isFinishedStep ? statusWhenStepFinished : statusWhenNotStepFinished,
    }
  }
  return withStepTitles([
    getStepData(t({ id: 'project_config.steps.general', message: 'General settings' }), 'GENERAL'),
    getStepData(t({ id: 'project_config.steps.stages', message: 'Project stages' }), 'STAGES'),
    getStepData(t({ id: 'project_config.steps.naming_scheme', message: 'Naming scheme' }), 'NAMING_SCHEME'),
    getStepData(t({ id: 'project_config.steps.disciplines', message: 'Disciplines settings' }), 'DISCIPLINES'),
    getStepData(t({ id: 'project_config.steps.metadata', message: 'Attributes settings' }), 'METADATA'),
    getStepData(
      t({ id: 'project_config.steps.entries_structure', message: 'Smart directories structure' }),
      'ENTRIES_STRUCTURE'
    ),
    getStepData(t({ id: 'project_config.steps.users', message: 'Users settings' }), 'USERS'),
    getStepData(t({ id: 'project_config.steps.permissions', message: 'Users permissions' }), 'PERMISSIONS'),
    getStepData(t({ id: 'project_config.steps.suitability_codes', message: 'Suitability codes' }), 'SUITABILITY_CODES'),
    getStepData(t({ id: 'project_config.steps.organization_groups', message: 'WIP zones' }), 'ORGANIZATION_GROUPS'),
    getStepData(t({ id: 'project_config.steps.map_settings', message: 'Map settings' }), 'MAP_SETTINGS'),
    ...itemArr(getStepData(t({ id: 'project_config.steps.flow', message: 'Flow' })), isProjectActive),
    ...itemArr(
      getStepData(t({ id: 'project_config.steps.activation', message: 'Project activation' }), 'ACTIVATION'),
      !isProjectActive
    ),
  ])
}

const useProjectConfigSteps = (currentStepIdx: number): StepData[] => {
  const isProjectActive = !!useSelector(selectProjectDetails)?.isActive
  const finishedSteps = useSelector(selectFinishedSteps)
  return getStepsData(isProjectActive, finishedSteps, currentStepIdx)
}

export default useProjectConfigSteps
