import { WipGroup } from 'models/administration'
import { BackendLoading } from 'redux/redux.shared'

export const loadingTypes = ['fetchWipGroups', 'updateWipGroups'] as const
export type LoadingTypes = typeof loadingTypes[number]

export interface ProjectWipGroupsConfigState {
  loading: Record<LoadingTypes, BackendLoading>
  wipGroups: WipGroup[]
}
