import { WarningOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import Text from 'components/atoms/text'
import Icon from 'components/atoms/icon'
import { Trans } from '@lingui/macro'

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const CesiumViewerError: React.FC = () => {
  return (
    <StyledDiv>
      <Icon size="l">
        <WarningOutlined />
      </Icon>
      <Text textStyle="regularText-medium" color="grayG05">
        <Trans id="map.error.map_loading_failed_generic_eror">
          Map could not be loaded. Please check if your browser supports WebGl and try again.
        </Trans>
      </Text>
    </StyledDiv>
  )
}

export default CesiumViewerError
