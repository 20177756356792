import backendAxios from 'axios/axios'
import {
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
  getStateAsAny,
} from 'redux/redux.shared'
import { createSlice } from '@reduxjs/toolkit'
import { loadingTypes, LoadingTypes, FlowStepsConfigState, FlowConfigStep } from './FlowStepsConfig.types'
import { selectFlowConfigFinishedSteps } from './FlowStepsConfig.selectors'
import { selectFlowId } from '../general/FlowGeneralConfig.selectors'

export const fetchFlowConfigFinishedSteps = createAsyncBackendThunk(
  'fetchFlowConfigFinishedSteps',
  async ({ projectId, flowId }: { projectId: number; flowId: number }) => {
    return (await backendAxios.get(`/projects/${projectId}/flows/config/${flowId}/status/`)).data
  }
)

export const addFlowConfigFinishedStep = createAsyncBackendThunk(
  'addFlowConfigFinishedStep',
  async ({ projectId, step }: { projectId: number; step: FlowConfigStep }, { getState }) => {
    const state = getStateAsAny(getState)
    const finishedSteps = selectFlowConfigFinishedSteps(state)
    const isStepAlreadyFinished = finishedSteps.includes(step)
    const flowId = selectFlowId(state) as number | undefined
    if (isStepAlreadyFinished || !flowId) return finishedSteps
    return (await backendAxios.post(`/projects/${projectId}/flows/config/${flowId}/status/`, { step })).data
  }
)

const initialState: FlowStepsConfigState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  finishedSteps: [],
  finishedStepsLoaded: false,
}

const flowStepsConfigSlice = createSlice({
  name: 'flowStepsConfig',
  initialState,
  reducers: {},
  extraReducers: combine([
    generateExtraBackendReducers<FlowStepsConfigState, LoadingTypes, FlowConfigStep[]>({
      promise: fetchFlowConfigFinishedSteps,
      loadingType: 'fetchFlowConfigFinishedSteps',
      onFulfilled: (state, action) => {
        state.finishedSteps = action.payload
        state.finishedStepsLoaded = true
      },
    }),
    generateExtraBackendReducers<FlowStepsConfigState, LoadingTypes, FlowConfigStep[]>({
      promise: addFlowConfigFinishedStep,
      loadingType: 'addFlowConfigFinishedStep',
      onFulfilled: (state, action) => {
        state.finishedSteps = action.payload
      },
    }),
  ]),
})

export const flowStepsConfigReducer = flowStepsConfigSlice.reducer
