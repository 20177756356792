import React from 'react'
import styled, { Color, Size } from 'styled-components'
import DividerElement from './DividerElement'

interface WithDividerElementProps {
  children: React.ReactNode
  layout?: 'horizontal' | 'vertical'
  size?: Size
  height?: string
  color?: Color
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

interface StyledDivProps {
  layout: string
}

const StyledDiv = styled.div<StyledDivProps>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props) => (props.layout === 'horizontal' ? 'row' : 'column')};
`

const WithDividerElement: React.FC<WithDividerElementProps> = ({
  children,
  layout = 'horizontal',
  size,
  color,
  onClick,
  height,
}) => {
  const childrenCount = React.Children.count(children)
  const divider = (
    <DividerElement
      type={layout === 'horizontal' ? 'vertical' : 'horizontal'}
      size={size}
      color={color}
      style={{ height }}
    />
  )
  return (
    <StyledDiv layout={layout} onClick={onClick}>
      {React.Children.map(children, (child, idx) => (
        <>
          {child}
          {idx !== childrenCount - 1 && divider}
        </>
      ))}
    </StyledDiv>
  )
}

export default WithDividerElement
