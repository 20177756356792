import { DeleteOutlined } from '@ant-design/icons'
import { Color } from 'styled-components'
import ButtonWithTooltip from './ButtonWithTooltip'

interface DeleteButtonProps {
  onClick?: React.MouseEventHandler<HTMLDivElement>
  disabled?: boolean
  withLoading?: boolean
  tooltip: string
  disabledTooltip?: string
}

const DeleteButton: React.FC<DeleteButtonProps> = ({
  onClick,
  disabled = false,
  withLoading = false,
  disabledTooltip,
  tooltip: enabledTooltip,
}) => {
  const iconColor: Color = disabled ? 'grayG02' : 'grayG05'
  const tooltip = disabled && disabledTooltip ? disabledTooltip : enabledTooltip
  return (
    <ButtonWithTooltip
      onClick={disabled ? undefined : onClick}
      withLoading={withLoading}
      icon={<DeleteOutlined />}
      iconColor={iconColor}
      tooltipText={tooltip}
      tooltipPlacement="topRight"
    />
  )
}

export default DeleteButton
