import styled from 'styled-components'
import ButtonWithTooltip from 'components/molecules/buttons'
import trans from 'helpers/i18n.helpers'
import Icon from 'components/atoms/icon'
import { DeleteOutlined } from '@ant-design/icons'
import { FormListFieldData } from 'antd/lib/form/FormList'
import {
  deleteAttributeValueText,
  deleteUsedAttributeValueText,
} from 'components/organisms/administration/administration-content/attributes/texts'

interface DeleteButtonProps {
  isFirstInColumn: boolean
  showButtons: boolean
  isDeletable: boolean
  field: FormListFieldData
  removeValue: (index: number | number[]) => void
}

const ButtonDiv = styled.div<{ firstRow: boolean }>`
  display: inline-block;
  padding-top: ${(props) => (props.firstRow ? props.theme.sizes.l : '')};
`

const DeleteValueButton: React.FC<DeleteButtonProps> = ({
  isFirstInColumn,
  showButtons,
  isDeletable,
  field,
  removeValue,
}) => {
  if (!showButtons) {
    return null
  }
  return (
    <ButtonDiv firstRow={isFirstInColumn}>
      <ButtonWithTooltip
        tooltipText={trans(isDeletable ? deleteAttributeValueText : deleteUsedAttributeValueText)}
        icon={
          <Icon size="m" color={isDeletable ? 'grayG05' : 'grayG02'}>
            <DeleteOutlined />
          </Icon>
        }
        onClick={isDeletable ? () => removeValue(field.name) : undefined}
      />
    </ButtonDiv>
  )
}

export default DeleteValueButton
