import { DataNode } from 'rc-tree/lib/interface'
import { DisciplineOverview, Revision } from 'redux/files/files-documentation/FilesDocumentation.dto'

export interface ProjectEntries {
  key: EntryKey
  value?: MetadataGroupData
  groups: Group[]
}

export interface MetadataGroupData {
  id?: number
  name?: string
}

export interface Group {
  value: GroupValue
  grouping?: ProjectEntries
  frontendFiles?: FrontendFile[]
  lastUpdated: string
}

export interface GroupValue {
  id?: number | null
  name: string
  code?: string | null
}

export interface FlowStatusInfo {
  status: string
  since?: string
}

export interface FileSuitabilityCode {
  name: string
  code: string
}

export interface CurrentFlow {
  id?: number
  status?: FlowStatusInfo
  reviewer?: number
}

export interface FrontendFile {
  key: string
  discipline: DisciplineOverview
  fileId?: number
  entryId: number
  shortName: string
  type: FrontendFileType
  isCurrent: boolean
  isNewest: boolean
  creator: Creator
  size: number | null
  originalFilename: string
  description: string
  revision?: Revision
  uploadDate?: string
  lastUpdated?: string
  cdeStatus: CdeStatus
  filename: string
  numberOfVersions?: number
  permissions: Permissions
  suitabilityCode: FileSuitabilityCode | null
  flows: number[]
  currentFlow: CurrentFlow
}

export interface Permissions {
  addNewVersion: boolean
  changeStatusTo: CdeStatus | null
  delete: boolean
  deleteEntry: boolean
  requestAcceptance: boolean
  lock: boolean
}

type FrontendFileType = 'DIR' | 'DOC'

export interface OrganizationOverview {
  id: number
  name: string
  abbreviation: string
}

export interface Creator {
  id: number
  name: string
  organization: OrganizationOverview
}

export const cdeStatuses = ['IN_PROGRESS', 'SHARED', 'PUBLISHED', 'ARCHIVED'] as const

export const cdeStatusesOrderDict: Record<CdeStatus, number> = {
  IN_PROGRESS: 0,
  SHARED: 1,
  PUBLISHED: 2,
  ARCHIVED: 3,
}

export type CdeStatus = typeof cdeStatuses[number]

export const CdeStatuses = {
  IN_PROGRES: 'IN_PROGRESS',
  SHARED: 'SHARED',
  PUBLISHED: 'PUBLISHED',
  ARCHIVED: 'ARCHIVED',
} as const

export type AcceptanceStatus = 'IN_REVIEW' | 'ACCEPTED' | 'REJECTED'

export type ActiveTab = 'CHARACTERISTICS' | 'ATTRIBUTES'

export interface MetadataDetails {
  metadata: {
    id: number
    name: string
  }
  value: {
    id: number
    value: string
    code: string
  }
}

export type DetailsType = 'DOC' | 'DOCS' | 'DIR_CURRENT' | 'DIR_CHECKED'

export interface DetailsItem {
  name: string
  value: string | number
  key?: EntryKey
}

export interface DetailsItemWithRender extends Omit<DetailsItem, 'key'> {
  renderValue?: (value: string) => JSX.Element
}

export interface SelectedGroup {
  containFrontendFiles: boolean
  groups?: Group[]
  frontendFiles?: FrontendFile[]
  displayName: string
  isRoot: boolean
  lastUpdated?: string
  attributes: DetailsItem[]
  key?: EntryKey
  value?: MetadataGroupData
}

export type EntryKey = 'status' | 'metadata' | 'discipline'

export interface TreeNodeAdditionalProps {
  path?: string
  id?: number | null
  selectedGroup?: SelectedGroup
}

export interface TreeNode extends DataNode, TreeNodeAdditionalProps {}

export interface FileInfo {
  fileId?: number | null
  entryId?: number | null
}
