import styled from 'styled-components'
import Text from 'components/atoms/text'
import TooltipElement from 'components/molecules/tooltip-element'
import { t } from '@lingui/macro'
import { ReactComponent as ProjectValuesIcon } from 'assets/administration-icons/attribute-project-values.svg'

interface AttributeNameDisplayProps {
  name: string
  projectValuesAllowed: boolean
}

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
`

const AttributeNameDisplay: React.FC<AttributeNameDisplayProps> = ({ name, projectValuesAllowed }) => {
  return (
    <StyledDiv>
      <Text textStyle="regularText-small">{name}</Text>
      {projectValuesAllowed && (
        <TooltipElement
          placement="top"
          title={t({
            id: 'administration.attributes.table.project_values_allowed_tooltip',
            message: 'Project-specific values allowed',
          })}
        >
          <ProjectValuesIcon />
        </TooltipElement>
      )}
    </StyledDiv>
  )
}

export default AttributeNameDisplay
