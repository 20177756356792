import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import { Attribute } from 'models/administration'
import { FlowAttributeConfig } from 'models/flow'
import {
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
  getStateAsAny,
} from 'redux/redux.shared'
import isFlowAttributeValueSelected from './FlowAttributesConfig.helpers'
import { selectFlowUsedAttributes } from './FlowAttributesConfig.selectors'
import { FlowAttributesConfigState, loadingType, LoadingType } from './FlowAttributesConfig.types'

interface RawFlowMetadata {
  metadata: number
  values: number[]
}

const processFlowAttributes: (flowAttributes: RawFlowMetadata[], attributes: Attribute[]) => FlowAttributeConfig[] = (
  flowAttributes,
  attributes
) => {
  return attributes.map(({ id, name, values }) => ({
    id,
    name,
    isSelected: !!flowAttributes.find(({ metadata }) => metadata === id),
    values: values.map(({ id: valueId, value, code }) => ({
      id: valueId,
      value,
      code,
      isSelected: isFlowAttributeValueSelected(flowAttributes, id, valueId),
    })),
  }))
}

export const fetchProjectAttributes = createAsyncBackendThunk(
  'flowAttributes/fetchProjectAttributes',
  async (projectId: number) => {
    return (await backendAxios.get(`/projects/${projectId}/metadata/`)).data
  }
)

export const fetchFlowData = createAsyncBackendThunk(
  'flowAttributes/fetchFlowData',
  async ({ projectId, flowId }: { projectId: number; flowId: number }, { dispatch }) => {
    await dispatch(fetchProjectAttributes(projectId))
    return (await backendAxios.get(`/projects/${projectId}/flows/config/${flowId}/conditions/metadata/`)).data
  }
)

export const updateFlowAttributes = createAsyncBackendThunk(
  'flowAttributes/updateFlowAttributes',
  async (args: { projectId: number; flowId: number }, { getState }) => {
    const state = getStateAsAny(getState)
    const flowAttributes: FlowAttributeConfig[] = selectFlowUsedAttributes(state)
    const attributesData = flowAttributes.map(({ id, values }) => ({
      metadata: id,
      values: values.filter(({ isSelected }) => isSelected).map(({ id: valId }) => valId),
    }))
    return (
      await backendAxios.patch(
        `/projects/${args.projectId}/flows/config/${args.flowId}/conditions/metadata/`,
        attributesData
      )
    ).data
  }
)

const flowAttributesConfigSlice = createSlice({
  name: 'flowAttributesConfig',
  initialState: {
    loading: generateInitialLoadingState<LoadingType>(loadingType),
    projectMetadata: [],
    flowMetadataConfig: [],
    projectAttributes: [],
    isAdding: false,
  } as FlowAttributesConfigState,
  reducers: {
    setAttributeSelected: (state, action: PayloadAction<{ attributeId: number; selected: boolean }>) => {
      const { attributeId, selected } = action.payload
      const attributeToSet = state.flowMetadataConfig.find(({ id }) => id === attributeId)
      if (attributeToSet) {
        const { isSelected, values, ...rest } = attributeToSet
        const newAttributeData = {
          isSelected: selected,
          values: selected ? values.map((value) => ({ ...value, isSelected: false })) : values,
          ...rest,
        }
        const remainingAttributes = state.flowMetadataConfig.filter(({ id }) => id !== attributeId)
        state.flowMetadataConfig = [...remainingAttributes, newAttributeData]
      }
    },
    toggleValueSelected: (state, action: PayloadAction<{ attributeId: number; valueId: number }>) => {
      const { attributeId, valueId } = action.payload
      state.flowMetadataConfig = state.flowMetadataConfig.map(({ id: attrId, values, ...attrRest }) => ({
        id: attrId,
        values:
          attrId === attributeId
            ? values.map(({ id, isSelected, ...valRest }) => ({
                id,
                isSelected: id === valueId ? !isSelected : isSelected,
                ...valRest,
              }))
            : values,
        ...attrRest,
      }))
    },
    setAllValuesSelected: (state, action: PayloadAction<{ attributeId: number; selected: boolean }>) => {
      const { attributeId, selected } = action.payload
      state.flowMetadataConfig = state.flowMetadataConfig.map(({ id, values, ...rest }) => ({
        id,
        values:
          id === attributeId
            ? values.map(({ isSelected, ...valRest }) => ({ isSelected: selected, ...valRest }))
            : values,
        ...rest,
      }))
    },
    setIsAdding: (state, action: PayloadAction<boolean>) => {
      state.isAdding = action.payload
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<FlowAttributesConfigState, LoadingType, Attribute[]>({
      promise: fetchProjectAttributes,
      loadingType: 'fetchProjectAttributes',
      onFulfilled: (state, action) => {
        state.projectAttributes = action.payload
      },
    }),
    generateExtraBackendReducers<FlowAttributesConfigState, LoadingType, RawFlowMetadata[]>({
      promise: fetchFlowData,
      loadingType: 'fetchFlowData',
      onFulfilled: (state, action) => {
        state.flowMetadataConfig = processFlowAttributes(action.payload, state.projectAttributes)
      },
    }),
    generateExtraBackendReducers<FlowAttributesConfigState, LoadingType, RawFlowMetadata[]>({
      promise: updateFlowAttributes,
      loadingType: 'updateFlowAttributes',
      onFulfilled: (state, action) => {
        state.flowMetadataConfig = processFlowAttributes(action.payload, state.projectAttributes)
      },
    }),
  ]),
})

export const {
  toggleValueSelected,
  setAttributeSelected,
  setIsAdding,
  setAllValuesSelected,
} = flowAttributesConfigSlice.actions

export const flowAttributesConfigReducer = flowAttributesConfigSlice.reducer
