import { ColumnsType } from 'antd/lib/table'
import { useSelector } from 'react-redux'
import { selectAdministrationCodeLengthDict } from 'redux/administration/administration-code-settings/AdministrationCodeSettings.selectors'
import { OrganizationDetails } from 'models/administration'
import useTableColumnFiltering, { onFilterStringColumn } from 'components/molecules/table/useTableColumnFiltering'
import WithValidityInfo from 'components/atoms/with-validity-info'
import { t, Trans } from '@lingui/macro'
import { actionsTableColumnText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import CodeColumnTableHeader from 'components/molecules/table/CodeColumnTableHeader'
import { ExpandableTableColumnType } from 'components/molecules/table/ExpandableTable'
import { ExpandableText } from 'components/atoms/text'
import useTableColumnSorting from 'components/molecules/table/useTableColumnSorting'
import DisplayOrganizationUsers from './DisplayOrganizationUsers'
import OrganizationActions from './OrganizationActions'
import DisplayOrganizationActivityStatus from './DisplayOrganizationActivityStatus'
import DisplayContactUser from './DisplayContactUser'
import {
  noAddressDefinedText,
  noContactPersonDefinedText,
  organizationActiveText,
  organizationInactiveText,
} from '../texts'
import DisplayOrganizationAddress from './DisplayOrganizationAddress'

// eslint-disable-next-line max-lines-per-function
const useAdministrationOrganizationsColumns = (): ColumnsType<OrganizationDetails & ExpandableTableColumnType> => {
  const codeLengthDict = useSelector(selectAdministrationCodeLengthDict)
  return [
    {
      title: t({ id: 'administration.organizations.table.column_name_header', message: 'Organization name' }),
      dataIndex: 'organization',
      key: 'organization',
      width: 375,
      render: (value, { name, isTableRowExpanded }) => (
        <ExpandableText isExpanded={isTableRowExpanded} color="grayG07">
          {name}
        </ExpandableText>
      ),
      ...useTableColumnFiltering(
        t({ id: 'administration.organizations.table.search_name_placeholder', message: 'Search for name' })
      ),
      onFilter: (value: string | number | boolean, record: OrganizationDetails) =>
        onFilterStringColumn(value, record.name),
      ...useTableColumnSorting({ sortBy: (o: OrganizationDetails) => o.name, defaultOrder: 'ascend' }),
    },
    {
      title: (
        <CodeColumnTableHeader>
          <Trans id="administration.organizations.table.column_code_header">Code</Trans>
        </CodeColumnTableHeader>
      ),
      dataIndex: 'organizationCode',
      key: 'organizationCode',
      width: 120,
      render: (value, { code }) => (
        <WithValidityInfo
          isValid={code.code.length === codeLengthDict.ORGANIZATION_CODE}
          invalidMessage={t({ id: 'administration.organizations.table.invalid_code_error', message: 'Invalid code' })}
          center
        >
          {code.code}
        </WithValidityInfo>
      ),
      ...useTableColumnFiltering(
        t({ id: 'administration.organizations.table.search_code_placeholder', message: 'Search for code' })
      ),
      onFilter: (value: string | number | boolean, record: OrganizationDetails) =>
        onFilterStringColumn(value, record.abbreviation),
      ...useTableColumnSorting({ sortBy: (o: OrganizationDetails) => o.code.code }),
    },
    {
      title: t({ id: 'administration.organizations.table.column_users_header', message: 'Users (active / inactive)' }),
      dataIndex: 'organizationUserCount',
      key: 'organizationUserCount',
      width: 110,
      render: (value, { users }) => <DisplayOrganizationUsers users={users} />,
    },
    {
      title: t({ id: 'administration.organizations.table.column_abbreviation_header', message: 'Abbreviation' }),
      dataIndex: 'organizationAbbreviation',
      key: 'organizationAbbreviation',
      width: 200,
      render: (value, { abbreviation, isTableRowExpanded }) => (
        <ExpandableText isExpanded={isTableRowExpanded} color="grayG07">
          {abbreviation}
        </ExpandableText>
      ),
      ...useTableColumnFiltering(
        t({
          id: 'administration.organizations.search_abbreviation_placeholder',
          message: 'Search for abbreviation',
        })
      ),
      onFilter: (value: string | number | boolean, record: OrganizationDetails) =>
        onFilterStringColumn(value, record.abbreviation),
      ...useTableColumnSorting({ sortBy: (o: OrganizationDetails) => o.abbreviation }),
    },
    {
      title: t({ id: 'administration.organizations.table.column_address_header', message: 'Address' }),
      dataIndex: 'organizationAddress',
      key: 'organizationAddress',
      width: 200,
      render: (value, { address, isTableRowExpanded }) => (
        <DisplayOrganizationAddress address={address} isExpanded={!!isTableRowExpanded} />
      ),
      ...useTableColumnFiltering(
        t({ id: 'administration.organizations.search_address_placeholder', message: 'Search for address' })
      ),
      onFilter: (value: string | number | boolean, record: OrganizationDetails) =>
        onFilterStringColumn(value, record.address || trans(noAddressDefinedText)),
    },
    {
      title: t({ id: 'administration.organizations.table.column_contact_header', message: 'Contact details' }),
      dataIndex: 'contact',
      key: 'contact',
      width: 225,
      render: (value, { contact, isTableRowExpanded }) => (
        <DisplayContactUser contact={contact} isExpanded={!!isTableRowExpanded} />
      ),
      ...useTableColumnFiltering(
        t({ id: 'administration.organizations.search_contact_placeholder', message: 'Search for contact' })
      ),
      onFilter: (value: string | number | boolean, record: OrganizationDetails) => {
        if (record.contact) {
          const { firstName, lastName, position, phoneNumber, email } = record.contact
          const text = `${firstName} ${lastName} ${position} ${phoneNumber} ${email}`
          return onFilterStringColumn(value, text)
        }
        return onFilterStringColumn(value, trans(noContactPersonDefinedText))
      },
    },
    {
      title: t({ id: 'administration.organizations.table.column_activity_status_header', message: 'Activity status' }),
      dataIndex: 'organizationIsActive',
      key: 'organizationIsActive',
      width: 175,
      render: (value, { isActive }) => <DisplayOrganizationActivityStatus isActive={isActive} />,
      filters: [
        { text: trans(organizationActiveText), value: true },
        { text: trans(organizationInactiveText), value: false },
      ],
      onFilter: (value, record) => record.isActive === value,
      ...useTableColumnSorting({ sortBy: (o: OrganizationDetails) => o.isActive }),
    },
    {
      title: trans(actionsTableColumnText),
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      render: (value, { id, name, isUsed }) => (
        <OrganizationActions organizationId={id} name={name} canDelete={!isUsed} />
      ),
    },
  ]
}

export default useAdministrationOrganizationsColumns
