import { t } from '@lingui/macro'
import ErrorAlert from 'components/atoms/error-alert'
import { useSelector } from 'react-redux'
import {
  selectFetchingAvailableDisciplinesLoading,
  selectFetchingMembersPermissionsLoading,
  selectUpdatingMembersPermissionsLoading,
} from 'redux/project/project-config/members-permissions/ProjectMembersPermissionsConfig.selectors'
import { selectProjectMembersFetchingLoading } from 'redux/project/project-config/members/ProjectMembersConfig.selectors'

const ProjectMembersPermissionsConfigAlert: React.FC = () => {
  const fetchingMembersPermissionsLoading = useSelector(selectFetchingMembersPermissionsLoading)
  const updatingMembersPermissionsLoading = useSelector(selectUpdatingMembersPermissionsLoading)
  const fetchDisciplinesLoading = useSelector(selectFetchingAvailableDisciplinesLoading)
  const fetchingMembersLoading = useSelector(selectProjectMembersFetchingLoading)
  const alertMessageDict = [
    {
      error: fetchingMembersPermissionsLoading.error,
      message: t({
        id: 'project_config.members_permissions.alert.permissions_fetch_failed',
        message: 'Could not retrieve users permissions.',
      }),
    },
    {
      error: updatingMembersPermissionsLoading.error,
      message: t({
        id: 'project_config.members_permissions.alert.permissions_update_failed',
        message: 'Could not update users permissions.',
      }),
    },
    {
      error: fetchDisciplinesLoading.error,
      message: t({
        id: 'project_config.members_permissions.alert.disciplines_fetch_failed',
        message: 'Could not retrieve disciplines.',
      }),
    },
    {
      error: fetchingMembersLoading.error,
      message: t({
        id: 'project_config.members_permissions.alert.users_fetch_failed',
        message: 'Could not retrieve users.',
      }),
    },
  ]
  return <ErrorAlert alertMessageDict={alertMessageDict} />
}

export default ProjectMembersPermissionsConfigAlert
