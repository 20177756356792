import styled from 'styled-components'
import Text from 'components/atoms/text'
import { Trans } from '@lingui/macro'
import { useSelector } from 'react-redux'
import { selectExecutedFlow, selectIsFlowModalVisible, selectExecutedFile } from 'redux/files/flows/Flows.selectors'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 150px auto;
  grid-template-rows: auto 1fr auto;
  grid-gap: ${(props) => props.theme.sizes.l} ${(props) => props.theme.sizes.m};
`

const FlowDetailsPanel: React.FC = () => {
  const flow = useSelector(selectExecutedFlow)
  const file = useSelector(selectExecutedFile)
  const isVisible = useSelector(selectIsFlowModalVisible)
  if (!isVisible || flow === undefined || file === undefined) {
    return null
  }
  const { name, description } = flow
  return (
    <Grid>
      <Text textStyle="regularText-smallSemiBold">
        <Trans id="project.flows.modal.grid.name">Flow name</Trans>
      </Text>
      <Text textStyle="regularText-small">{name}</Text>
      <Text textStyle="regularText-smallSemiBold">
        <Trans id="project.flows.modal.grid.description">Description</Trans>
      </Text>
      <Text textStyle="regularText-small">{description}</Text>
    </Grid>
  )
}

export default FlowDetailsPanel
