import Text from 'components/atoms/text'
import WithValidityInfo from 'components/atoms/with-validity-info'
import formatDate from 'helpers/Dates.helpers'
import { t } from '@lingui/macro'

interface BackupsCreationDisplayProps {
  creationDate: string | null
  expectedCreationDate: string | null
}

const BackupsCreationDateDisplay: React.FC<BackupsCreationDisplayProps> = ({ creationDate, expectedCreationDate }) => {
  const isBackupCreated = creationDate !== null
  const color = isBackupCreated ? 'baseBlackish' : 'grayG04'
  const date = creationDate || expectedCreationDate
  return (
    <WithValidityInfo
      isValid={isBackupCreated}
      warningColor={color}
      invalidMessage={t({
        id: 'administration.backups.backup_not_yet_created_warning',
        message: 'Backup is not yet created',
      })}
    >
      <Text color={color} textStyle="regularText-small">
        {date ? formatDate(date) : ''}
      </Text>
    </WithValidityInfo>
  )
}

export default BackupsCreationDateDisplay
