import { MessageDescriptor } from '@lingui/core'
import { defineMessage } from '@lingui/macro'

export const reviewersUsersTypesDict: { [key: string]: MessageDescriptor } = {
  CHECKING_USER: defineMessage({ id: 'flow.reviewers_type.checking', message: 'Checking user' }),
  ACCEPTING_USER: defineMessage({ id: 'flow.reviewers_type.accepting', message: 'Accepting user' }),
  SELECTED: defineMessage({ id: 'flow.reviewers_type.selected', message: 'Selected' }),
}
export const reviewTypesDict: { [key: string]: MessageDescriptor } = {
  SINGULAR_REVIEWER: defineMessage({ id: 'flow.review_type.single_reviewer', message: 'Single reviewer' }),
}
export const reviewerSelectionMethodsDict: { [key: string]: MessageDescriptor } = {
  SELECTED: defineMessage({ id: 'flow.review_selection_method.selected', message: 'Requester selection from list' }),
  RANDOM: defineMessage({ id: 'flow.review_selection_method.random', message: 'Random selection' }),
}
