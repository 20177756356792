import { LockOutlined, UnlockOutlined } from '@ant-design/icons'
import { defineMessage } from '@lingui/macro'
import trans from 'helpers/i18n.helpers'
import { useSelector, useDispatch } from 'react-redux'
import { setIsScaleLocked } from 'redux/cesium-viewer/CesiumViewer.slice'
import styled from 'styled-components'
import { selectIsScaleLocked } from '../../../redux/cesium-viewer/CesiumViewer.selectors'
import TooltipElement from '../tooltip-element'

interface MapScalePanelItemProps {
  name: React.ReactNode
  value: React.ReactNode
}

const StyledDiv = styled.div`
  & :last-child {
    margin-left: 5px;
  }
`

const lockScaleMessage = defineMessage({ id: 'molecules.coordinates_panel.scale.lock_scale', message: 'Lock scale' })
const unlockScaleMessage = defineMessage({
  id: 'molecules.coordinates_panel.scale.unlock_scale',
  message: 'Unock scale',
})

const MapScalePanelItem: React.FC<MapScalePanelItemProps> = ({ name }) => {
  const dispatch = useDispatch()
  const isScaleLocked = useSelector(selectIsScaleLocked)

  const lockScale = () => dispatch(setIsScaleLocked(true))
  const unlockScale = () => dispatch(setIsScaleLocked(false))

  const icon = isScaleLocked ? <LockOutlined onClick={unlockScale} /> : <UnlockOutlined onClick={lockScale} />
  return (
    <StyledDiv>
      {name} {/* value */}
      <TooltipElement placement="topRight" title={trans(isScaleLocked ? unlockScaleMessage : lockScaleMessage)}>
        {icon}
      </TooltipElement>
    </StyledDiv>
  )
}

export default MapScalePanelItem
