import { RootState } from 'redux/store'
import { BackendLoading } from 'redux/redux.shared'
import { EntryLayers } from '../../../../models/map'

export const selectGisFileLayers = (state: RootState): EntryLayers[] => state.map.gisFileLayers.layers
export const selectVisibleGisFileLayers = (state: RootState): string[] => state.map.gisFileLayers.visibleLayers
export const selectCheckedGisFileLayerNodes = (state: RootState): (string | number)[] =>
  state.map.gisFileLayers.checkedLayerNodes
export const selectFetchGisFileLayersLoading = (state: RootState): BackendLoading =>
  state.map.gisFileLayers.loading.fetchGisFileLayers
