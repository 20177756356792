import { ColumnsType } from 'antd/lib/table'
import { useSelector } from 'react-redux'
import { selectAdministrationCodeLengthDict } from 'redux/administration/administration-code-settings/AdministrationCodeSettings.selectors'
import { ProjectStageWithUsage } from 'models/administration'
import useTableColumnFiltering, { onFilterStringColumn } from 'components/molecules/table/useTableColumnFiltering'
import WithValidityInfo from 'components/atoms/with-validity-info'
import { actionsTableColumnText } from 'data/messages/controls'
import { t, Trans } from '@lingui/macro'
import trans from 'helpers/i18n.helpers'
import CodeColumnTableHeader from 'components/molecules/table/CodeColumnTableHeader'
import useTableColumnSorting from 'components/molecules/table/useTableColumnSorting'
import ProjectStageActions from './ProjectStageActions'

const useAdministrationProjectStagesColumns = (): ColumnsType<ProjectStageWithUsage> => {
  const codeLengthDict = useSelector(selectAdministrationCodeLengthDict)
  return [
    {
      title: t({ id: 'administration.project_stages.table.column_name_header', message: 'Name' }),
      dataIndex: 'projectStage',
      key: 'projectStage',
      width: 600,
      render: (value, { name }) => name,
      ...useTableColumnFiltering(
        t({ id: 'administration.project_stages.table.search_name_placeholder', message: 'Search for name' })
      ),
      onFilter: (value: string | number | boolean, record: ProjectStageWithUsage) =>
        onFilterStringColumn(value, record.name),
      ...useTableColumnSorting({ sortBy: (s: ProjectStageWithUsage) => s.name, defaultOrder: 'ascend' }),
    },
    {
      title: (
        <CodeColumnTableHeader>
          <Trans id="administration.project_stages.table.column_code_header">Code</Trans>
        </CodeColumnTableHeader>
      ),
      dataIndex: 'code',
      key: 'code',
      width: 100,
      render: (value, { code }) => (
        <WithValidityInfo
          isValid={code.code.length === codeLengthDict.PROJECT_STAGE_CODE}
          invalidMessage={t({ id: 'administration.project_stages.table.invalid_code_error', message: 'Invalid code' })}
          center
        >
          {code.code}
        </WithValidityInfo>
      ),
      ...useTableColumnFiltering(
        t({ id: 'administration.project_stages.table.search_code_placeholder', message: 'Search for code' })
      ),
      onFilter: (value: string | number | boolean, record: ProjectStageWithUsage) =>
        onFilterStringColumn(value, record.code.code),
      ...useTableColumnSorting({ sortBy: (s: ProjectStageWithUsage) => s.code.code }),
    },
    {
      title: trans(actionsTableColumnText),
      dataIndex: 'actions',
      key: 'actions',
      width: 75,
      render: (value, { id, name, isUsed }) => <ProjectStageActions stageId={id} name={name} canDelete={!isUsed} />,
    },
  ]
}

export default useAdministrationProjectStagesColumns
