import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectIsConditionsConfigContentLoading,
  selectFlowConditionsConfigValidity,
} from 'redux/flow-conifg/conditions/FlowConditionsConfig.selectors'
import {
  getFlowConditions,
  getOrganizations,
  updateFlowConditions,
} from 'redux/flow-conifg/conditions/FlowConditionsConfig.slice'
import { fetchProjectMembers } from 'redux/flow-conifg/decisions/FlowDecisionsConfig.slice'
import { selectFlowId } from 'redux/flow-conifg/general/FlowGeneralConfig.selectors'
import { fetchAvailableDisciplines } from 'redux/project/project-config/members-permissions/ProjectMembersPermissionsConfig.slice'
import { fetchSuitabilityOverview } from 'redux/project/project-config/suitability'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import BaseFlowConfigView from '../base-flow-config-view/BaseFlowConfigView'
import FlowConditionsConfigContent from './content'

const FlowConditionsConfig: React.FC = () => {
  const isLoading = useSelector(selectIsConditionsConfigContentLoading)
  const dispatch = useDispatch()
  const projectId = useSelector(selectProjectId)
  const flowId = useSelector(selectFlowId)
  const flowConditionsConfigValidity = useSelector(selectFlowConditionsConfigValidity)

  useEffect(() => {
    if (projectId && flowId) {
      dispatch(getFlowConditions({ projectId, flowId }))
      dispatch(fetchAvailableDisciplines(projectId))
      dispatch(fetchProjectMembers(projectId))
    }
  }, [dispatch, projectId, flowId])

  useEffect(() => {
    dispatch(getOrganizations())
    dispatch(fetchSuitabilityOverview())
  }, [dispatch])

  if (!projectId || !flowId) return null

  return (
    <BaseFlowConfigView
      content={<FlowConditionsConfigContent />}
      isContentLoading={isLoading}
      saveStepThunk={updateFlowConditions({ flowId, projectId })}
      isContentValid={flowConditionsConfigValidity}
      stepName="INITIAL"
    />
  )
}

export default FlowConditionsConfig
