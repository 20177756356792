import { useSelector } from 'react-redux'
import { Modal, Space, Spin } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import Text from 'components/atoms/text'
import Icon from 'components/atoms/icon'
import Button from 'components/molecules/button-element'
import { cancelText, saveText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import {
  addProjectMembers,
  fetchActiveUsers,
  fetchOrganizations,
  setProjectMembersModalVisible,
} from 'redux/project/project-config/members/ProjectMembersConfig.slice'
import { useAppDispatch } from 'redux/store'
import { useEffect, useState } from 'react'
import {
  selectProjectMembersOrganizations,
  selectProjectMembersUsersAddableToProject,
} from 'redux/project/project-config/members/ProjectMembersConfig.selectors'
import { Trans } from '@lingui/macro'
import ProjectMembersModalErrors from './ProjectMembersModalErrors'
import OrganizationFilter from './OrganizationFilter'
import UserDataFilter from './UserDataFilter'
import UsersTransfer from './UsersTransfer'

const ProjectMembersModal: React.FC = () => {
  const dispatch = useAppDispatch()
  const projectId = useSelector(selectProjectId)
  const users = useSelector(selectProjectMembersUsersAddableToProject)
  const organizations = useSelector(selectProjectMembersOrganizations)

  const [targetKeys, setTargetKeys] = useState<string[]>([])
  const [selectedOrganizations, setSelectedOrganizations] = useState<number[] | undefined>(undefined)
  const [usersFilter, setUsersFilter] = useState<string>('')

  useEffect(() => {
    if (users === undefined) {
      dispatch(fetchActiveUsers())
    }
  }, [dispatch, users])
  useEffect(() => {
    if (organizations === undefined) {
      dispatch(fetchOrganizations())
    }
  }, [dispatch, organizations])

  const handleCancel = () => dispatch(setProjectMembersModalVisible(false))
  const handleSubmit = () => {
    if (projectId && targetKeys.length > 0) {
      dispatch(addProjectMembers({ projectId, users: targetKeys.map(Number) }))
    } else {
      handleCancel()
    }
  }

  const handleOrganizationSelectChange = (arg: number[]) => {
    if (arg.length > 0) {
      setSelectedOrganizations(arg)
    } else {
      setSelectedOrganizations(undefined)
    }
  }

  return (
    <Modal
      visible
      width={900}
      onCancel={handleCancel}
      closeIcon={
        <Icon>
          <CloseOutlined />
        </Icon>
      }
      maskClosable={false}
      title={
        <Text textStyle="header-H2b">
          <Trans id="project_config.members.modal.header_text">Add users</Trans>
        </Text>
      }
      footer={
        <>
          <Button onClick={handleCancel} type="tertiary">
            {trans(cancelText)}
          </Button>
          <Button loading={false} onClick={handleSubmit}>
            {trans(saveText)}
          </Button>
        </>
      }
    >
      <Spin spinning={false}>
        <Space direction="vertical">
          <Space direction="horizontal">
            <OrganizationFilter
              selectedOrganizations={selectedOrganizations || []}
              organizations={organizations}
              onChange={handleOrganizationSelectChange}
            />
            <UserDataFilter value={usersFilter} onChange={(val) => setUsersFilter(val)} />
          </Space>
          <UsersTransfer
            users={users}
            targetKeys={targetKeys}
            selectedOrganizations={selectedOrganizations}
            usersFilter={usersFilter}
            onChange={(target) => setTargetKeys(target)}
          />
          <ProjectMembersModalErrors />
        </Space>
      </Spin>
    </Modal>
  )
}

export default ProjectMembersModal
