import { css } from 'styled-components'

const menuItemMixin = css`
  padding: ${(props) => props.theme.sizes.xs};
  margin: 0;
  margin-bottom: 0 !important;
  height: ${(props) => props.theme.sizes.l};
  &-disabled {
    cursor: auto;
    > * {
      cursor: auto;
    }
  }
  &-active {
    background-color: ${(props) => props.theme.colors.actionHover};
  }
  display: flex;
  align-items: center;
`

export default menuItemMixin
