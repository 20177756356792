import { FlowAttributeConfig } from 'models/flow'
import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import { FlowAttributesConfigState } from './FlowAttributesConfig.types'

const selectFlowAttributesConfigState = (state: RootState): FlowAttributesConfigState => state.flowConfig.attributes

export const selectFlowAttributesDataFetchingLoading = (state: RootState): BackendLoading =>
  selectFlowAttributesConfigState(state).loading.fetchFlowData

export const selectFlowAttributesFetchingLoading = (state: RootState): BackendLoading =>
  selectFlowAttributesConfigState(state).loading.fetchProjectAttributes

export const selectFlowAttributesUpdatingLoading = (state: RootState): BackendLoading =>
  selectFlowAttributesConfigState(state).loading.updateFlowAttributes

export const selectFlowAttributesIsLoading = (state: RootState): boolean =>
  selectFlowAttributesDataFetchingLoading(state).isLoading ||
  selectFlowAttributesFetchingLoading(state).isLoading ||
  selectFlowAttributesUpdatingLoading(state).isLoading

const selectFlowAttributes = (state: RootState): FlowAttributeConfig[] =>
  selectFlowAttributesConfigState(state).flowMetadataConfig

export const selectFlowUsedAttributes = (state: RootState): FlowAttributeConfig[] =>
  selectFlowAttributes(state).filter(({ isSelected }) => isSelected)

export const selectFlowNotUsedAttributes = (state: RootState): FlowAttributeConfig[] =>
  selectFlowAttributes(state).filter(({ isSelected }) => !isSelected)

export const selectFlowAttributesIsAdding = (state: RootState): boolean =>
  selectFlowAttributesConfigState(state).isAdding

export const selectAreAnyFlowAttributesNotSelected = (state: RootState): boolean =>
  selectFlowNotUsedAttributes(state).length > 0

export const selectFlowAttributesConfigValidity = (state: RootState): boolean => {
  const selectedAttributes = selectFlowUsedAttributes(state)
  return selectedAttributes.reduce(
    (agg: boolean, attr) => agg && attr.values.filter(({ isSelected }) => isSelected).length > 0,
    true
  )
}
