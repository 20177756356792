import { SuitabilityCode } from 'models/administration'
import { FlowDecisionConfig } from 'models/flow'
import { Member } from 'models/project/projectConfig'
import type { BackendLoading } from 'redux/redux.shared'

export const loadingType = [
  'getFlowDecisions',
  'updateFlowDecisions',
  'fetchSuitabilityOverview',
  'fetchProjectMembers',
] as const
export type LoadingType = typeof loadingType[number]

export interface FlowDecisionsConfigState {
  loading: Record<LoadingType, BackendLoading>
  flowDecisionConfig: FlowDecisionConfig
  codes: SuitabilityCode[]
  members: Member[]
}
