import { defineMessage } from '@lingui/macro'
import { MessageDescriptor } from '@lingui/core'
import AdministrationAttributes from './administration-content/attributes'
import AdministrationDisciplines from './administration-content/disciplines'
import AdministrationOrganizations from './administration-content/organizations'
import AdministrationProjectStages from './administration-content/project-stages'
import AdministrationProjects from './administration-content/projects'
import AdministrationSuitability from './administration-content/suitability'
import AdministrationUsers from './administration-content/users'
import AdministrationNamingScheme from './administration-content/naming-scheme'
import AdministrationBackups from './administration-content/backups/AdministrationBackups'
import AdministrationSuitabilityToolbar from './administration-content/suitability/AdministrationSuitabilityToolbar'
import AdministrationProjectStagesToolbar from './administration-content/project-stages/AdministrationProjectStagesToolbar'
import AdministrationDisciplinesToolbar from './administration-content/disciplines/AdministrationDisciplinesToolbar'
import AdministrationProjectsToolbar from './administration-content/projects/AdministrationProjectsToolbar'
import AdministrationOrganizationsToolbar from './administration-content/organizations/AdministrationOrganizationsToolbar'
import AdministrationNamingSchemeToolbar from './administration-content/naming-scheme/AdministrationNamingSchemesToolbar'
import AdministrationUsersToolbar from './administration-content/users/AdministrationUsersToolbar'
import AdministrationBackupsToolbar from './administration-content/backups/AdministrationBackupsToolbar'
import AdministrationAttributesToolbar from './administration-content/attributes/AdministrationAttributesToolbar'

const administrationOptions: {
  component: React.ReactNode
  key: string
  name: MessageDescriptor
  heading?: React.ReactNode
}[] = [
  {
    key: 'organization',
    name: defineMessage({ id: 'administration.menu.organizations_key', message: 'Organizations' }),
    component: <AdministrationOrganizations />,
    heading: <AdministrationOrganizationsToolbar />,
  },
  {
    key: 'disciplines',
    name: defineMessage({ id: 'administration.menu.disciplines_key', message: 'Disciplines' }),
    component: <AdministrationDisciplines />,
    heading: <AdministrationDisciplinesToolbar />,
  },
  {
    key: 'projectStages',
    name: defineMessage({ id: 'administration.menu.project_stages_key', message: 'Project stages' }),
    component: <AdministrationProjectStages />,
    heading: <AdministrationProjectStagesToolbar />,
  },
  {
    key: 'users',
    name: defineMessage({ id: 'administration.menu.users_key', message: 'Users' }),
    component: <AdministrationUsers />,
    heading: <AdministrationUsersToolbar />,
  },
  {
    key: 'projects',
    name: defineMessage({ id: 'administration.menu.projects_key', message: 'Projects' }),
    component: <AdministrationProjects />,
    heading: <AdministrationProjectsToolbar />,
  },
  {
    key: 'attributes',
    name: defineMessage({ id: 'administration.menu.attributes_key', message: 'Attributes' }),
    component: <AdministrationAttributes />,
    heading: <AdministrationAttributesToolbar />,
  },
  {
    key: 'suitability',
    name: defineMessage({ id: 'administration.menu.suitability_key', message: 'Suitability codes' }),
    component: <AdministrationSuitability />,
    heading: <AdministrationSuitabilityToolbar />,
  },
  {
    key: 'fileNamingSchema',
    name: defineMessage({ id: 'administration.menu.naming_scheme_key', message: 'Files naming scheme' }),
    component: <AdministrationNamingScheme />,
    heading: <AdministrationNamingSchemeToolbar />,
  },
  {
    key: 'backups',
    name: defineMessage({ id: 'administration.menu.backups_key', message: 'Backups' }),
    component: <AdministrationBackups />,
    heading: <AdministrationBackupsToolbar />,
  },
]

export default administrationOptions
