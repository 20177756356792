import { notification } from 'antd'
import { ArgsProps } from 'antd/lib/notification'
import { AxiosError } from 'axios'
import { MessageDescriptor } from '@lingui/core'
import { successText, errorText } from 'data/messages/controls'
import { handleApiErrors } from './HttpError.helpers'
import trans, { transIfDescriptorType } from './i18n.helpers'

export type NotificationType = 'success' | 'warning' | 'error' | 'info'

export const openNotification = (type: NotificationType, args: ArgsProps): void => {
  notification[type]({ ...args })
}

export const openDocumentationNotification = (
  type: NotificationType,
  { message = '', description = '' }: { message?: string; description?: string }
): void => {
  openNotification(type, {
    message,
    description,
    placement: 'bottomRight',
    bottom: 0,
    style: { marginBottom: 0, marginRight: 40 },
  })
}

export const openDocumentationErrorNotification = (
  error_msg: Record<string, MessageDescriptor>,
  error: Error
): void => {
  openDocumentationNotification('error', {
    message: trans(errorText),
    description: handleApiErrors(error_msg, (error as AxiosError).response?.data.errors),
  })
}

export const openSuccessNotification = ({
  message = successText,
  description = '',
}: {
  message?: string | MessageDescriptor
  description?: string
}): void => {
  openNotification('success', {
    message: transIfDescriptorType(message),
    description,
    placement: 'bottomLeft',
    bottom: 0,
    style: { marginBottom: 20, marginRight: 20 },
  })
}
