import styled from 'styled-components'

const barHeight = 56

export const StyledContainer = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: ${barHeight}px calc(100vh - ${barHeight * 2}px) ${barHeight}px;
  grid-template-columns: 100vw;
  background-color: ${({ theme }) => theme.colors.baseWhiteish};
  > header,
  > footer {
    display: flex;
    align-items: center;
    padding: ${({ theme }) => theme.sizes.default} ${({ theme }) => theme.sizes.m};
  }
`

export const StyledContent = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.grayG02};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayG02};
  display: flex;
  padding: ${({ theme }) => theme.sizes.m} 0;
`

export const StyledContentLeft = styled.div`
  height: 100%;
  padding: 0 ${({ theme }) => theme.sizes.m};
  border-right: 1px solid ${({ theme }) => theme.colors.grayG02};
  overflow: auto;
  flex-shrink: 0;
`

export const StyledContentRight = styled.div`
  height: 100%;
  padding: 0 ${({ theme }) => theme.sizes.m};
  overflow: auto;
  flex-grow: 1;
`
