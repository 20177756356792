import { Menu } from 'antd'
import styled from 'styled-components'
import Icon from 'components/atoms/icon'
import Text from 'components/atoms/text'
import menuItemMixin from './FilesEntryMenu.styles'

interface FilesEntryMenuItemProps {
  text: string
  icon: React.ReactNode
  disabled?: boolean
  key: string
  onClick?: () => void
}

const StyledMenuItem = styled(Menu.Item)`
  &.ant-menu-item {
    ${menuItemMixin}
    > * {
      &:first-child {
        margin-right: ${(props) => props.theme.sizes.xs};
      }
    }
  }
`

const FilesEntryMenuItem: React.FC<FilesEntryMenuItemProps> = ({ text, icon, disabled, ...otherProps }) => {
  const itemColor = disabled ? 'grayG04' : 'grayG07'
  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <StyledMenuItem disabled={disabled} {...otherProps}>
      <Icon color={itemColor} size="default">
        {icon}
      </Icon>
      <Text color={itemColor} textStyle="regularText-small">
        {text}
      </Text>
    </StyledMenuItem>
  )
}

export default FilesEntryMenuItem
