import { Card, Space, Spin } from 'antd'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectAllNotifications,
  selectGetAllNotificationsLoading,
  selectNotificationsSearchValue,
} from 'redux/notifications/Notifications.selectors'
import React, { useEffect, useMemo } from 'react'
import { getAllNotifications } from 'redux/notifications'
import { ColumnsType } from 'antd/lib/table'
import Text from 'components/atoms/text'
import { Notification } from 'models/notifications'
import NotificationDescription from 'components/molecules/notifications/notifications-description'
import NotificationReadStatus from 'components/molecules/notifications/NotificationReadStatus'
import { getMsSinceMidnight } from 'helpers/Dates.helpers'
import { generateStyledTableWithType } from 'helpers/Styles.helpers'
import moment from 'moment'
import { dateText } from 'data/messages/misc'
import trans from 'helpers/i18n.helpers'
import { defineMessage, t, Trans } from '@lingui/macro'
import NotificationsSearchInput from './NotificationsSearchInput'

interface TableRecord extends Notification {
  dateInfo?: string
}

const StyledCard = styled(Card)`
  & {
    padding: ${(props) => props.theme.sizes.m} ${(props) => props.theme.sizes.l};
  }
  .ant-form-item-control-input {
    width: 400px;
  }
`

const StyledTable = generateStyledTableWithType<TableRecord>(css`
  &&& {
    width: 1000px;
    thead th {
      border: none;
      background-color: transparent;
      &:first-child {
        > * {
          margin-left: 28px;
        }
      }
    }
  }
`)

const StyledDateInfo = styled.div`
  position: absolute;
  top: 0;
  left: 28px;
`

const TableCellWrapper = styled.div<{ $paddingTop: boolean }>`
  position: relative;
  ${(props) => (props.$paddingTop ? `padding-top: ${props.theme.sizes.l};` : '')}
`

const StyledSpace = styled(Space)`
  && {
    .ant-space-item:last-child {
      span {
        max-width: 100%;
        white-space: normal;
      }
    }
  }
`

const getColumns = (): ColumnsType<TableRecord> => {
  return [
    {
      title: (
        <Text textStyle="regularText-small" color="grayG04">
          {trans(dateText)}
        </Text>
      ),
      dataIndex: 'date',
      key: 'date',
      width: 150,
      ellipsis: true,
      render: (value, { id, isRead, creationDate, dateInfo }) => (
        <TableCellWrapper $paddingTop={!!dateInfo}>
          {dateInfo && (
            <StyledDateInfo>
              <Text textStyle="regularText-smallSemiBold" color="grayG05">
                {dateInfo}
              </Text>
            </StyledDateInfo>
          )}
          <NotificationReadStatus
            isRead={isRead}
            id={id}
            dateStr={moment(creationDate).format('Do MMM h:mm').replace('.', '')}
          />
        </TableCellWrapper>
      ),
    },
    {
      title: (
        <Text textStyle="regularText-small" color="grayG04">
          <Trans id="notifications.table.details_column_header">Details</Trans>
        </Text>
      ),
      dataIndex: 'description',
      key: 'description',
      render: (value, { message, isRead, details, dateInfo, id }) => (
        <TableCellWrapper $paddingTop={!!dateInfo}>
          <NotificationDescription message={message} details={details} isRead={isRead} id={id} />
        </TableCellWrapper>
      ),
      ellipsis: true,
      width: 500,
    },
    {
      title: (
        <Text textStyle="regularText-small" color="grayG04">
          <Trans id="notifications.table.author_column_header">Changed by</Trans>
        </Text>
      ),
      dataIndex: 'author',
      key: 'author',
      render: (value, { details, isRead, dateInfo }) =>
        details.user?.name && (
          <TableCellWrapper $paddingTop={!!dateInfo}>
            <StyledSpace direction="vertical" size="small">
              <Text textStyle={isRead ? 'regularText-small' : 'regularText-smallSemiBold'}>{details.user.name},</Text>
              <Text textStyle="regularText-small" block>
                {details.user.organization}
              </Text>
            </StyledSpace>
          </TableCellWrapper>
        ),
    },
  ]
}

const timeSpans = [
  {
    text: defineMessage({ id: 'notification.date_groups.today', message: 'Today' }),
    maxTimeDiff: getMsSinceMidnight(),
  },
  {
    text: defineMessage({ id: 'notification.date_groups.last_7_days', message: 'Last 7 days' }),
    maxTimeDiff: getMsSinceMidnight() + 6 * 24 * 60 * 60 * 1000,
  },
  {
    text: defineMessage({ id: 'notification.date_groups.last_month', message: 'Last month' }),
    maxTimeDiff: 30 * 24 * 60 * 60 * 1000,
  },
]

const getDateInfoFromNotificationDate = (timeDiffInMs: number, timeSpanIdx = 0): string => {
  if (timeSpanIdx >= timeSpans.length) {
    return t({ id: 'notification.date_groups.more_than_month_ago', message: 'Everything else' })
  }
  return timeDiffInMs <= timeSpans[timeSpanIdx].maxTimeDiff
    ? trans(timeSpans[timeSpanIdx].text)
    : getDateInfoFromNotificationDate(timeDiffInMs, timeSpanIdx + 1)
}

const filteredElementsGenerators = [
  (notification: Notification) => notification.details.project?.name,
  (notification: Notification) => notification.details.user?.name,
  (notification: Notification) => notification.message,
  (notification: Notification) => notification.details.entry?.name,
  (notification: Notification) => notification.details.file?.name,
]

const NotificationsDashboard: React.FC = () => {
  const getAllNotificationsLoading = useSelector(selectGetAllNotificationsLoading)
  const allNotifications = useSelector(selectAllNotifications)
  const notificationsSearchValue = useSelector(selectNotificationsSearchValue)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAllNotifications())
  }, [dispatch])
  const dataSource = useMemo(() => {
    const timeSpanTextUsed: string[] = []
    return allNotifications
      .filter(
        (notification) =>
          !!filteredElementsGenerators.find((gen) =>
            gen(notification)?.toLowerCase().includes(notificationsSearchValue)
          )
      )
      .map((notification) => {
        const notificationCreationTime = new Date(notification.creationDate).getTime()
        const timeDiffInMs = new Date().getTime() - notificationCreationTime
        const dateInfo = getDateInfoFromNotificationDate(timeDiffInMs)
        const doesDateInfoExistsAlready = !!timeSpanTextUsed.find((info) => info === dateInfo)
        if (!doesDateInfoExistsAlready) {
          timeSpanTextUsed.push(dateInfo)
        }
        return {
          ...notification,
          dateInfo: !doesDateInfoExistsAlready ? dateInfo : undefined,
        }
      })
  }, [notificationsSearchValue, allNotifications])
  const columns = useMemo(getColumns, [])
  return (
    <Spin spinning={getAllNotificationsLoading.isLoading}>
      <StyledCard>
        <NotificationsSearchInput />
        {!getAllNotificationsLoading.isLoading && (
          <StyledTable
            columns={columns}
            hideOnSinglePage
            locale={{
              emptyText: (
                <Text textStyle="regularText-small">
                  <Trans id="notifications.table.no_notifications_text">No notifications</Trans>
                </Text>
              ),
            }}
            dataSource={dataSource}
          />
        )}
      </StyledCard>
    </Spin>
  )
}

export default NotificationsDashboard
