import { defineMessage } from '@lingui/macro'
import ErrorAlert from 'components/atoms/error-alert'
import { handleApiErrors } from 'helpers/HttpError.helpers'
import { useSelector } from 'react-redux'
import { selectCreateBackupLoading } from 'redux/administration/administration-backups/AdministrationBackups.selectors'

const backupErrorDict = {
  'backup.already_being_prepared': defineMessage({
    id: 'administration.backups.errors.backup_is_currently_being_created',
    message: 'Backup creation failed. Another backup is currently being created or is scheduled to be created soon.',
  }),
}

const CreatingBackupAlert: React.FC = () => {
  const creatingLoading = useSelector(selectCreateBackupLoading)

  const alertMessageDict = [
    {
      error: creatingLoading.error,
      message: handleApiErrors(backupErrorDict, creatingLoading.error?.errors),
    },
  ]
  return <ErrorAlert alertMessageDict={alertMessageDict} />
}

export default CreatingBackupAlert
