import { ReactNode } from 'react'
import { StyledContainer, StyledContentLeft, StyledContentRight, StyledContent } from './FullScreenModalTemplate.styles'

interface FullScreenModalTemplateProps {
  header: ReactNode
  contentLeft: ReactNode
  contentRight: ReactNode
  footer: ReactNode
}

const FullScreenModalTemplate: React.FC<FullScreenModalTemplateProps> = ({
  header,
  contentLeft,
  contentRight,
  footer,
}) => {
  return (
    <StyledContainer>
      <header>{header}</header>
      <StyledContent>
        <StyledContentLeft>{contentLeft}</StyledContentLeft>
        <StyledContentRight>{contentRight}</StyledContentRight>
      </StyledContent>
      <footer>{footer}</footer>
    </StyledContainer>
  )
}

export default FullScreenModalTemplate
