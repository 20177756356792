import { BackendLoading } from 'redux/redux.shared'
import { ProjectFlowsExecDetails, ProjectFlowsReviewDetails } from 'models/flow'
import { Creator, FrontendFile } from 'models/files'

export const loadingTypes = [
  'fetchProjectExecutableFlows',
  'execFlow',
  'getReviewers',
  'getFileCurrentFlowReviewDetails',
  'review',
] as const
export type LoadingTypes = typeof loadingTypes[number]

export interface FlowsState {
  loading: Record<LoadingTypes, BackendLoading>
  projectFlows: ProjectFlowsExecDetails[]
  modalData: FlowModalData
  modalReviewData: FlowModalData
  reviewers: Creator[]
  selectedReviewer?: number
  reviewDetails?: ProjectFlowsReviewDetails
  reviewDecision?: ReviewOption
}

export interface FlowModalData {
  isVisible: boolean
  flow?: ProjectFlowsExecDetails
  frontendFile?: FrontendFile
}

export const reviewOptions = ['ACCEPTED', 'ACCEPTED_WITH_COMMENT', 'REJECTED', 'REJECTED_WITH_COMMENT'] as const
export type ReviewOption = typeof reviewOptions[number]
export const ReviewOptions = {
  ACCEPTED: 'ACCEPTED',
  ACCEPTED_WITH_COMMENT: 'ACCEPTED',
  REJECTED: 'REJECTED',
  REJECTED_WITH_COMMENT: 'REJECTED',
} as const
