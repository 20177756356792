import styled from 'styled-components'
import FileUpload from 'components/atoms/file-upload'
import { RcFile } from 'antd/lib/upload/interface'
import FilesUploadName from './FilesUploadName'
import FilesUploadText from './FilesUploadText'

interface UploadSingleFileProps {
  file: RcFile | null
}

const StyledDiv = styled.div`
  margin: 0 auto;
  align-self: center;
  max-width: 370px; // make sure element width does not break modal layout
`

const UploadSingleFile: React.FC<UploadSingleFileProps & React.ComponentProps<typeof FileUpload>> = ({
  onRemove,
  file,
  beforeUpload,
}) => {
  const handleRemove: React.MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation()
    onRemove()
  }
  return (
    <StyledDiv>
      <FileUpload showUploadList={false} beforeUpload={beforeUpload}>
        {file ? <FilesUploadName name={file.name} handleRemove={handleRemove} /> : <FilesUploadText />}
      </FileUpload>
    </StyledDiv>
  )
}

export default UploadSingleFile
