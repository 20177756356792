import { Input } from 'antd'
import { FormItemProps } from 'antd/lib/form'
import { TextAreaProps } from 'antd/lib/input'
import { WithGetErrors } from 'hooks/useFormValidity'
import BaseFormItem from './BaseFormItem'

const FormTextArea: React.FC<FormItemProps & TextAreaProps & WithGetErrors> = ({
  placeholder,
  disabled = false,
  onChange,
  value,
  label,
  name,
  rules,
  size,
  prefix,
  allowClear,
  bordered,
  onFocus,
  onBlur,
  validateStatus,
  autoSize,
  tooltip,
  style,
  getErrors,
}) => (
  <BaseFormItem
    rules={rules}
    label={label}
    name={name}
    validateStatus={validateStatus}
    getErrors={getErrors}
    tooltip={tooltip}
  >
    <Input.TextArea
      size={size}
      prefix={prefix}
      bordered={bordered}
      onFocus={onFocus}
      onBlur={onBlur}
      allowClear={allowClear}
      value={value}
      onChange={onChange}
      placeholder={placeholder && placeholder.toString()}
      disabled={disabled}
      autoSize={autoSize}
      style={style}
    />
  </BaseFormItem>
)

export default FormTextArea
