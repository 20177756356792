import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import { ProjectDetails } from 'models/project/projectDetails'
import {
  BackendLoading,
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
} from 'redux/redux.shared'
import { loadingTypes, LoadingTypes, ProjectDetailsState, Projection } from './ProjectDetails.types'

export const fetchPossibleProjections = createAsyncBackendThunk(
  'fetchPossibleProjections',
  async (projectId: number) => {
    const response = await backendAxios.get(`/projects/${projectId}/projections/`)
    return response.data
  }
)

export const fetchProjectDetails = createAsyncBackendThunk('details/fetchProjectDetails', async (id: number) => {
  const response = await backendAxios.get(`/projects/${id}/`)
  return response.data
})

const initialState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  projections: [],
  shouldProjectionUpdate: false,
} as ProjectDetailsState

const projectDetailsSlice = createSlice({
  name: 'details',
  initialState,
  reducers: {
    clearProjectDetailsState: () => initialState,
    clearProjectDetailsError: (state) => {
      state.loading = generateInitialLoadingState<LoadingTypes>(loadingTypes) as Record<LoadingTypes, BackendLoading>
    },
    editCurrentEPSG: (state, action: PayloadAction<number>) => {
      state.currentProjection = state.projections.find((p) => p.id === action.payload)
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<ProjectDetailsState, LoadingTypes, ProjectDetails>({
      promise: fetchProjectDetails,
      loadingType: 'fetchProjectDetails',
      onFulfilled: (state, action) => {
        state.details = action.payload
      },
    }),
    generateExtraBackendReducers<ProjectDetailsState, LoadingTypes, Projection[]>({
      promise: fetchPossibleProjections,
      loadingType: 'fetchPossibleProjections',
      onFulfilled: (state, action) => {
        const projections = action.payload
        state.projections = projections
        state.currentProjection = projections.find((p) => p.isDefault)
      },
    }),
  ]),
})

export const { editCurrentEPSG, clearProjectDetailsState, clearProjectDetailsError } = projectDetailsSlice.actions

export const projectDetailsReducer = projectDetailsSlice.reducer
