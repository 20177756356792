import { BackendLoading } from 'redux/redux.shared'
import { RootState } from '../store'
import { PasswordResetState } from './Password.slice'

export const passwordSelector = (state: RootState): PasswordResetState => state.passwordReset

export const passwordResetTokenInvalidSelector = (state: RootState): boolean => {
  const { loading, isTokenValid } = passwordSelector(state)
  return !isTokenValid && !loading.verifyPasswordResetToken.isLoading
}

export const passwordResetResultSelector = (state: RootState): 'pending' | 'success' | 'failed' => {
  const { isPasswordResetFinished, isPasswordResetSuccessful } = passwordSelector(state)
  if (!isPasswordResetFinished) return 'pending'
  if (isPasswordResetSuccessful) return 'success'
  return 'failed'
}

export const selectChangePasswordLoading: (state: RootState) => BackendLoading = (state) =>
  passwordSelector(state).loading.changePassword
