import { Attribute } from 'models/project/projectConfig'
import { BackendLoading, generateInitialLoadingState } from 'redux/redux.shared'

export const loadingTypes = [
  'fetchMetadata',
  'patchMetadataValue',
  'selectMetadata',
  'removeMetadata',
  'toggleMetadataRequired',
  'editMetadata',
] as const
export type LoadingTypes = typeof loadingTypes[number]

interface ModalData {
  isVisible: boolean
  attr?: Attribute
}

export interface EditAttributeProps {
  metadataId: number
  values: { id?: number; code: string; value: string }[]
}

export interface ProjectAttributesConfigState {
  loading: Record<LoadingTypes, BackendLoading>
  metadata: Attribute[]
  isAddingMetadata: boolean
  initialMetadataFetched: boolean
  modalData: ModalData
}

export interface MetadataDto {
  id: number
  name: string
  projectValuesAllowed: boolean
  valueCodeLength: number
  isUsed: boolean
  values: {
    id: number
    value: string
    code: string
    isProjectValue: boolean
    isUsed: boolean
  }[]
}

export interface EditRequest {
  metadata: number
  values: number[]
}

export const initialState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  metadata: [],
  isAddingMetadata: false,
  initialMetadataFetched: false,
  modalData: { isVisible: false },
} as ProjectAttributesConfigState
