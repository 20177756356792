import { useEffect } from 'react'
import BaseErrorAlert from 'components/atoms/error-alert/BaseErrorAlert'
import { handleApiErrors } from 'helpers/HttpError.helpers'
import { useDispatch, useSelector } from 'react-redux'
import { selectCreatingSuitabilityCodeLoading } from 'redux/administration/administration-suitability/AdministrationSuitability.selectors'
import { removeErrors } from 'redux/administration/administration-suitability'
import { defineMessage } from '@lingui/macro'
import { invalidCodeErrorMessage } from 'data/messages/misc'

const errorsDict = {
  'suitability_code.name_exists': defineMessage({
    id: 'administration.suitability.modal.name_already_exists_error',
    message: 'Suitability code with given name already exists in selected directory',
  }),
  'suitability_code.invalid_code_length': defineMessage({
    id: 'administration.suitability.modal.invalid_code_length_error',
    message: "Invalid suitability code's code length",
  }),
  'suitability_code.code_exists': defineMessage({
    id: 'administration.suitability.modal.code_already_exists_error',
    message: 'Suitability code with given code already exists',
  }),
  'codes.code_invalid': invalidCodeErrorMessage,
}

const AddingSuitabilityCodeModalAlert: React.FC = () => {
  const { error } = useSelector(selectCreatingSuitabilityCodeLoading)
  const dispatch = useDispatch()
  useEffect(() => {
    return () => {
      dispatch(removeErrors())
    }
  }, [dispatch])
  if (!error) return null
  return <BaseErrorAlert description={handleApiErrors(errorsDict, error?.errors)} />
}

export default AddingSuitabilityCodeModalAlert
