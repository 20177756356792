import { UnlockOutlined } from '@ant-design/icons'
import { t, Trans } from '@lingui/macro'
import { Form, Checkbox, Row } from 'antd'
import ButtonElement from 'components/atoms/button.legacy/Button'
import Text from 'components/atoms/text'
import FormInput from 'components/molecules/form-item/FormInput'
import FormInputPassword from 'components/molecules/form-item/FormInputPassword'
import { required } from 'helpers/Validators.helpers'
import { Link } from 'react-router-dom'

interface LoginFormProps {
  submitForm: (values: { loginName: string; password: string }) => void
  loading: boolean
}

const LoginForm: React.FunctionComponent<LoginFormProps> = ({ submitForm, loading }) => {
  return (
    <Form layout="vertical" hideRequiredMark name="normal_login" className="login-form" onFinish={submitForm}>
      <FormInput
        label={<Trans id="login.email_field">Email</Trans>}
        placeholder={t({ id: 'login.email_field_placeholder', message: 'Enter email' })}
        name="loginName"
        prefix={<UnlockOutlined className="site-form-item-icon" />}
        allowClear
        rules={[required()]}
      />
      <FormInputPassword
        label={<Trans id="login.password_field">Password</Trans>}
        placeholder={t({ id: 'login.password_field_placeholder', message: 'Enter password' })}
        prefix={<UnlockOutlined className="site-form-item-icon" />}
        allowClear
        name="password"
        rules={[required()]}
      />
      <Form.Item>
        <Row justify="space-between" align="middle">
          <Checkbox>
            <Text textStyle="regularText-small" color="grayG07">
              <Trans id="login.remember_me_text">Remember me</Trans>
            </Text>
          </Checkbox>
          <Link to="/password-reset">
            <Text textStyle="regularText-small" color="primaryP04">
              <Trans id="login.forgot_password_text">Forgot password</Trans>
            </Text>
          </Link>
        </Row>
      </Form.Item>
      <ButtonElement type="primary" size="large" block elementLoading={loading} elementType="submit">
        <Trans id="login.login_button_text">Sign in</Trans>
      </ButtonElement>
    </Form>
  )
}

export default LoginForm
