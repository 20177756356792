import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchBackupWarning } from 'redux/administration/administration-backups/AdministrationBackups.slice'
import { selectIsAuthenticated } from 'redux/login/Login.selectors'

const MODAL_APPEARANCE_INTERVAL = 60000

const useBackupWarnings = (): void => {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const backupWarningModalVisibilityRef = useRef(false)
  const backupStartWarningModalAppearedRef = useRef(false)
  const showBackupWarningModalAgainRef = useRef(true)

  useEffect(() => {
    let interval: number
    if (isAuthenticated) {
      dispatch(
        fetchBackupWarning({
          backupWarningModalVisibilityRef,
          backupStartWarningModalAppearedRef,
          showBackupWarningModalAgainRef,
        })
      )
      interval = setInterval(() => {
        dispatch(
          fetchBackupWarning({
            backupWarningModalVisibilityRef,
            backupStartWarningModalAppearedRef,
            showBackupWarningModalAgainRef,
          })
        )
      }, MODAL_APPEARANCE_INTERVAL)
    }
    return () => {
      clearInterval(interval)
    }
  }, [dispatch, isAuthenticated])

  useEffect(() => {
    if (!isAuthenticated) {
      backupWarningModalVisibilityRef.current = false
      backupStartWarningModalAppearedRef.current = false
      showBackupWarningModalAgainRef.current = true
    }
  }, [isAuthenticated])
}

export default useBackupWarnings
