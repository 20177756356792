import { useSelector } from 'react-redux'
import { selectIsProjectMembersConfigContentLoading } from 'redux/project/project-config/members/ProjectMembersConfig.selectors'
import { addProjectConfigFinishedStep } from 'redux/project/project-config/status/ProjectStatusConfig.slice'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import BaseProjectConfigView from '../base-config-view/BaseProjectConfigView'
import ProjectMembersConfigContent from './content'

const ProjectMembersConfig: React.FC = () => {
  const isContentLoading = useSelector(selectIsProjectMembersConfigContentLoading)
  const projectId = useSelector(selectProjectId)
  return (
    <BaseProjectConfigView
      isContentLoading={isContentLoading}
      saveStepThunk={projectId ? addProjectConfigFinishedStep({ projectId, step: 'USERS' }) : undefined}
      content={<ProjectMembersConfigContent />}
    />
  )
}

export default ProjectMembersConfig
