import SpaceElement from 'components/atoms/space'
import Text from 'components/atoms/text'
import styled, { Color } from 'styled-components'

interface ActivableTextProps {
  activeColor: Color
  isActive: boolean
  onClick?: () => void
  icon?: React.ReactNode
  textColor?: Color
  dashed?: boolean
}

interface StyledDivProps {
  readonly $activeColor: Color
  readonly $isActive: boolean
  readonly onClick?: () => void
  readonly $dashed?: boolean
  readonly $textColor: Color
}

const StyledDiv = styled.div<StyledDivProps>`
  padding: 0 ${(props) => props.theme.sizes.xs};
  height: ${(props) => props.theme.sizes.l};
  background-color: ${(props) => (props.$isActive ? props.theme.colors[props.$activeColor] : 'transparent')};
  border-radius: ${(props) => props.theme.sizes.xs};
  transition: ${(props) => props.theme.transitions.default};
  ${(props) => (props.onClick ? 'cursor: pointer;' : '')}
  ${(props) => (props.$dashed && !props.$isActive ? `border: 1px dashed ${props.theme.colors[props.$textColor]};` : '')}
`

const StyledSpaceElement = styled(SpaceElement)`
  align-items: center;
  height: 100%;
  flex-wrap: nowrap;
`

const ActivableText: React.FC<ActivableTextProps> = ({
  children,
  activeColor,
  isActive,
  onClick,
  icon,
  textColor = 'grayG07',
  dashed = false,
}) => {
  return (
    <StyledDiv
      onClick={onClick}
      $activeColor={activeColor}
      $isActive={isActive}
      $dashed={dashed}
      $textColor={textColor}
    >
      <StyledSpaceElement size="xxs" direction="horizontal">
        <Text textStyle="regularText-smallSemiBold" color={textColor}>
          {children}
        </Text>
        {icon}
      </StyledSpaceElement>
    </StyledDiv>
  )
}

export default ActivableText
