import { t } from '@lingui/macro'
import { Modal } from 'antd'
import { errorText } from 'data/messages/controls'
import { uniqueArr } from 'helpers/Collection.helpers'
import { findSelectedGroupByKey, getDirectoriesKeysFromPath, getPathToFile, preparePath } from 'helpers/Files.helpers'
import trans from 'helpers/i18n.helpers'
import useCurrentPath from 'hooks/query/currentPath'
import useSideDrawerParams from 'hooks/query/sideDrawerParams'
import { FileInfo, TreeNode } from 'models/files'
import { DataNode, EventDataNode } from 'rc-tree/lib/interface'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { showFileDetails } from 'redux/files/files-details'
import {
  selectFetchProjectEntriesLoading,
  selectProjectEntries,
} from 'redux/files/files-documentation/FilesDocumentation.selectors'
import { checkFrontendFileByInitialFileChecked, setInitialFileChecked, setSelectedGroup } from 'redux/files/files-table'
import { selectIntialFileChecked } from 'redux/files/files-table/FilesTable.selectors'
import { selectFetchProjectDetailsLoading } from 'redux/project/project-details/ProjectDetails.selectors'
import useEntryIdParam from './useEntryIdParam'

type OnSelect = (
  keys: React.Key[],
  info: {
    event: 'select'
    selected: boolean
    node: EventDataNode
    selectedNodes: DataNode[]
    nativeEvent: MouseEvent
  }
) => void

interface UseDocumentationRoutingProps {
  projectName: string
  treeData: TreeNode[]
  setExpandedKeys: React.Dispatch<React.SetStateAction<React.Key[]>>
}

interface UseDocumentationRoutingReturnValue {
  onSelect: OnSelect
}

const useDocumentationRouting = ({
  projectName,
  treeData,
  setExpandedKeys,
}: UseDocumentationRoutingProps): UseDocumentationRoutingReturnValue => {
  const dispatch = useDispatch()
  const projectEntries = useSelector(selectProjectEntries)
  const entryId = useEntryIdParam()
  const initialFileChecked = useSelector(selectIntialFileChecked)
  const { contentParam, sideDrawerParam } = useSideDrawerParams()
  const shouldUpdate = sideDrawerParam === 'files' && contentParam === 'documentation' && projectEntries
  const projectEntriesLoading = useSelector(selectFetchProjectEntriesLoading)
  const projectDetailsLoading = useSelector(selectFetchProjectDetailsLoading)
  const pathParam = useCurrentPath()
  const history = useHistory()
  const { pathname } = useLocation()

  useEffect(() => {
    if (projectName && shouldUpdate) {
      if (!pathParam) {
        dispatch(setSelectedGroup(treeData[0].selectedGroup))
        history.replace(preparePath(pathname, projectName.toString()))
      } else {
        const selectedGroup = findSelectedGroupByKey(pathParam, treeData)
        if (selectedGroup) {
          setExpandedKeys((prev) => uniqueArr([...prev, ...getDirectoriesKeysFromPath(pathParam)]))
          dispatch(setSelectedGroup(selectedGroup))
        } else {
          history.replace(preparePath(pathname))
        }
      }
    }
  }, [dispatch, treeData, pathParam, pathname, projectEntries, history, shouldUpdate, projectName, setExpandedKeys])
  useEffect(() => {
    const canRouteToInitialFileChecked =
      initialFileChecked &&
      projectEntries &&
      projectName &&
      !projectEntriesLoading.isLoading &&
      !projectDetailsLoading.isLoading
    if (canRouteToInitialFileChecked) {
      const path = getPathToFile(treeData, initialFileChecked)
      history.replace(preparePath(pathname, path))
      Promise.resolve().then(() => {
        dispatch(checkFrontendFileByInitialFileChecked(initialFileChecked as FileInfo))
        dispatch(setInitialFileChecked(undefined))
        dispatch(showFileDetails())
      })
    }
  }, [
    initialFileChecked,
    treeData,
    projectEntries,
    projectName,
    dispatch,
    history,
    pathname,
    projectDetailsLoading.isLoading,
    projectEntriesLoading.isLoading,
  ])
  // generating path from entryId
  useEffect(() => {
    if (entryId && projectName && shouldUpdate) {
      const path = getPathToFile(treeData, { entryId })
      if (path === undefined) {
        Modal.error({
          title: trans(errorText),
          content: t({
            id: 'project.files.errors.nonexistent_file_error',
            message: "File does not exist or you don't have permission to view it.",
          }),
        })
      } else {
        history.replace(preparePath(pathname, path))
        dispatch(setInitialFileChecked({ entryId }))
      }
    }
  }, [entryId, dispatch, history, pathname, shouldUpdate, projectName, treeData])

  const onSelect: OnSelect = (keys, info) => {
    history.push(preparePath(pathname, (keys[0] as string) || projectName))
    setExpandedKeys((prev) => uniqueArr([...prev, info.node.key]))
  }

  return { onSelect }
}

export default useDocumentationRouting
