import { EditOutlined } from '@ant-design/icons'
import { WithDividerElement } from 'components/atoms/divider'
import { CellWrapper } from 'components/molecules/table/Table'
import ButtonWithTooltip, { DeleteButton } from 'components/molecules/buttons'
import { useSelector } from 'react-redux'
import { showProjectAttributesModal } from 'redux/project/project-config/attributes'
import { selectAttributeById } from 'redux/project/project-config/attributes/ProjectAttributesConfig.selectors'
import { useAppDispatch } from 'redux/store'
import { t } from '@lingui/macro'

interface ActionProps {
  remove?: (id?: number) => void
  attrId?: number
  projectValuesAllowed: boolean
  isUsed: boolean
  attributeValuesExist: boolean
}

function editTooltipText(projectValuesAllowed: boolean, attributeValuesExist: boolean): string {
  if (projectValuesAllowed) {
    return t({ id: 'project_config.attributes.table.add_or_edit_tooltip', message: 'Add or edit values.' })
  }
  if (attributeValuesExist) {
    return t({ id: 'project_config.attributes.table.edit_tooltip', message: 'Edit values.' })
  }
  return t({
    id: 'project_config.attributes.table.edit_disabled_tooltip',
    message: 'The system administrator has not allowed adding project attributes values.',
  })
}

const AttributesActions: React.FC<ActionProps> = ({
  remove,
  isUsed,
  attrId,
  projectValuesAllowed,
  attributeValuesExist,
}) => {
  const dispatch = useAppDispatch()
  const editedAttribute = useSelector(selectAttributeById(attrId))
  return (
    <CellWrapper>
      <WithDividerElement>
        <ButtonWithTooltip
          onClick={() => {
            if (editedAttribute) {
              dispatch(showProjectAttributesModal(editedAttribute))
            }
          }}
          disabled={!attributeValuesExist && !projectValuesAllowed}
          tooltipText={editTooltipText(projectValuesAllowed, attributeValuesExist)}
          iconColor={editedAttribute ? 'grayG05' : 'disabled'}
          icon={<EditOutlined />}
        />
        <DeleteButton
          onClick={() => !isUsed && remove && remove(attrId)}
          disabled={remove === undefined || isUsed}
          tooltip={t({
            id: 'project_config.attributes.table.delete_tooltip',
            message: 'Remove attribute from project',
          })}
          disabledTooltip={t({
            id: 'project_config.attributes.table.delete_disabled_tooltip',
            message: 'Used attribute could not be removed',
          })}
        />
      </WithDividerElement>
    </CellWrapper>
  )
}

export default AttributesActions
