import styled from 'styled-components'
import { CheckOutlined } from '@ant-design/icons'

interface CheckboxCircleProps {
  checked: boolean
}

interface StyledDivProps {
  $checked: boolean
}

const StyledDiv = styled.div<StyledDivProps>`
  width: ${(props) => props.theme.sizes.s};
  height: ${(props) => props.theme.sizes.s};
  font-size: ${(props) => props.theme.sizes.xs};
  color: ${(props) => props.theme.colors.accentA02};
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.colors.accentA02};
  ${(props) => props.theme.mixins.flexCenter}
  display: inline-flex;
  background-color: ${(props) => (props.$checked ? 'transparent' : props.theme.colors.accentA02)};
  border-radius: 50%;
`

const Check: React.FC<CheckboxCircleProps> = ({ checked }) => {
  return (
    <StyledDiv $checked={checked}>
      <CheckOutlined />
    </StyledDiv>
  )
}

export default Check
