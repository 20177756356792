import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import { createAsyncBackendThunk, generateExtraBackendReducers, combine, getStateAsAny } from 'redux/redux.shared'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import { AttributeValue, Attribute } from 'models/project/projectConfig'
import { moveArrayItemToNewIndex } from 'helpers/Collection.helpers'
import {
  allMetadataToAttributes,
  mapAttributesWithAttrValuesById,
  setLoadingAndSelected,
} from './ProjectAttributesConfig.helpers'
import {
  EditAttributeProps,
  LoadingTypes,
  initialState,
  MetadataDto,
  ProjectAttributesConfigState,
} from './ProjectAttributesConfig.types'
import { addProjectConfigFinishedStep } from '../status/ProjectStatusConfig.slice'
import { generateSelectedMetadataListIdSelector } from './ProjectAttributesConfig.selectors'

interface ToggleAttributeValuePayload {
  attributeId: number
  valueId: number
  isSelected: boolean
}

export const fetchMetadata = createAsyncBackendThunk('attributes/fetchMetadata', async (projectId: number) => {
  const availableMetadataPromise = backendAxios.get(`projects/${projectId}/metadata/`)
  const allMetadataPromise = backendAxios.get(`projects/${projectId}/metadata/available/`)
  const [selectedMetadataResponse, allMetadataResponse] = await Promise.all([
    availableMetadataPromise,
    allMetadataPromise,
  ])
  const selectedMetadata = selectedMetadataResponse.data as MetadataDto[]
  const allMetadata = allMetadataResponse.data as MetadataDto[]
  return allMetadataToAttributes(allMetadata, selectedMetadata)
})

export const editAttributeValues = createAsyncBackendThunk(
  'editMetadata',
  async ({ metadataId, values }: EditAttributeProps, { dispatch, getState }) => {
    const state = getStateAsAny(getState)
    const projectId = selectProjectId(state) as number
    const response = await backendAxios.patch(`/projects/${projectId}/metadata/values/${metadataId}/`, { values })
    dispatch(fetchMetadata(projectId))
    return response.data
  }
)

export const updateAttributesConfig = createAsyncBackendThunk(
  'attributes/updateAttributesConfig',
  async (arg, { dispatch, getState }) => {
    const state = getStateAsAny(getState)
    const projectId = selectProjectId(state) as number
    const metadataList = generateSelectedMetadataListIdSelector(state)
    await backendAxios.put(`/projects/${projectId}/metadata/edit/`, metadataList)
    await dispatch(addProjectConfigFinishedStep({ projectId, step: 'METADATA' }))
  }
)

const projectAttributesSlice = createSlice({
  name: 'attributes',
  initialState,
  reducers: {
    setAddingMetadata: (state, action: PayloadAction<boolean>) => {
      state.isAddingMetadata = action.payload
    },
    showProjectAttributesModal: (state, attr: PayloadAction<Attribute>) => {
      state.modalData.isVisible = true
      state.modalData.attr = attr.payload
    },
    hideProjectAttributesModal: (state) => {
      state.modalData = initialState.modalData
    },
    toggleAttributeValue: (state, payload: PayloadAction<ToggleAttributeValuePayload>) => {
      const { attributeId, valueId, isSelected } = payload.payload
      state.metadata = mapAttributesWithAttrValuesById(
        state.metadata,
        attributeId,
        (attributeValues: AttributeValue[]) => setLoadingAndSelected(attributeValues, valueId, isSelected, false)
      )
    },
    addNewMetadata: (state, payload: PayloadAction<number>) => {
      const newMetadata = state.metadata.map((metadata) => ({
        ...metadata,
        selected: metadata.id === payload.payload ? true : metadata.selected,
      }))
      const newItemIdx = newMetadata.findIndex((item) => item.id === payload.payload)
      state.metadata = moveArrayItemToNewIndex(newMetadata, newItemIdx, newMetadata.length - 1)
      state.isAddingMetadata = false
    },
    removeMetadata: (state, payload: PayloadAction<number>) => {
      state.metadata = state.metadata.map((metadata) => ({
        ...metadata,
        selected: metadata.id === payload.payload ? false : metadata.selected,
        attributeValues:
          metadata.id === payload.payload
            ? metadata.attributeValues.map((val) => ({ ...val, selected: false, loading: false }))
            : metadata.attributeValues,
      }))
      state.isAddingMetadata = false
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<ProjectAttributesConfigState, LoadingTypes, Attribute[]>({
      promise: fetchMetadata,
      loadingType: 'fetchMetadata',
      onFulfilled: (state, action) => {
        state.metadata = action.payload
        state.initialMetadataFetched = true
      },
    }),
    generateExtraBackendReducers<ProjectAttributesConfigState, LoadingTypes, Attribute[]>({
      promise: editAttributeValues,
      loadingType: 'editMetadata',
      onFulfilled: (state) => {
        state.modalData = initialState.modalData
      },
    }),
  ]),
})

export const {
  setAddingMetadata,
  showProjectAttributesModal,
  hideProjectAttributesModal,
  toggleAttributeValue,
  addNewMetadata,
  removeMetadata,
} = projectAttributesSlice.actions

export const projectAttributesConfigReducer = projectAttributesSlice.reducer
