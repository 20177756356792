import { Trans } from '@lingui/macro'
import { Space } from 'antd'
import Text from 'components/atoms/text'
import { useSelector } from 'react-redux'
import { selectProjectFlows } from 'redux/project/project-config/flow/ProjectFlowConfig.selectors'
import { selectProjectDetails } from 'redux/project/project-details/ProjectDetails.selectors'
import styled from 'styled-components'
import AddingFlowButton from './AddingFlowButton'
import ProjectFlowConfigEmpty from './ProjectFlowConfigEmpty'
import ProjectFlowsTable from './table/ProjectFlowsTable'
import ProjectFlowConfigContentAlerts from './ProjectFlowConfigContentAlerts'

const StyledDiv = styled.div`
  height: 100%;
  position: relative;
  .ant-space {
    width: 100%;
    &-item {
      width: 100%;
    }
  }
`

const ProjectFlowConfigContent: React.FC = () => {
  const projectDetails = useSelector(selectProjectDetails)
  const projectFlows = useSelector(selectProjectFlows)
  return (
    <StyledDiv>
      <Space direction="vertical">
        <Text textStyle="header-H2">
          <Trans id="project_config.flow.heading_project">Project</Trans> {projectDetails?.name}
        </Text>
        <Text textStyle="header-H2b">
          <Trans id="project_config.flow.heading_file_flow">Files flow</Trans>
        </Text>
        {projectFlows.length === 0 ? (
          <ProjectFlowConfigEmpty />
        ) : (
          <Space direction="vertical" size="large">
            <ProjectFlowsTable flows={projectFlows} />
            <AddingFlowButton />
          </Space>
        )}
        <ProjectFlowConfigContentAlerts />
      </Space>
    </StyledDiv>
  )
}

export default ProjectFlowConfigContent
