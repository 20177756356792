import cdeStatusDict from 'data/cdeStatus'
import { ProjectStatusType } from 'redux/administration/administration-projects/AdministrationProjects.types'
import { CdeStatus, CdeStatuses, cdeStatusesOrderDict } from '../models/files'
import trans from './i18n.helpers'

const sortByStatus = (status1: CdeStatus, status2: CdeStatus): number =>
  cdeStatusesOrderDict[status1] - cdeStatusesOrderDict[status2]

export const getProjectStatus: ({
  isActive,
  isEnded,
}: {
  isActive?: boolean
  isEnded?: boolean
}) => ProjectStatusType = ({ isActive, isEnded }) => {
  if (!isActive) {
    return 'IN_CONFIG'
  }
  return isEnded ? 'ARCHIVED' : 'ACTIVE'
}

export const projectStatusOrderDict: Record<ProjectStatusType, number> = {
  ACTIVE: 0,
  IN_CONFIG: 1,
  ARCHIVED: 2,
}

export const cdeStatusFilters = (): { text: string; value: CdeStatus }[] => [
  { text: trans(cdeStatusDict[CdeStatuses.IN_PROGRES].text), value: CdeStatuses.IN_PROGRES },
  { text: trans(cdeStatusDict[CdeStatuses.SHARED].text), value: CdeStatuses.SHARED },
  { text: trans(cdeStatusDict[CdeStatuses.PUBLISHED].text), value: CdeStatuses.PUBLISHED },
  { text: trans(cdeStatusDict[CdeStatuses.ARCHIVED].text), value: CdeStatuses.ARCHIVED },
]

export default sortByStatus
