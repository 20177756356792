import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsAuthenticated } from 'redux/login/Login.selectors'
import { getNewNotifications } from 'redux/notifications'

const useNotifications = (canFetchNewNotifications: boolean): void => {
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const dispatch = useDispatch()
  useEffect(() => {
    let interval: number
    if (canFetchNewNotifications && isAuthenticated) {
      dispatch(getNewNotifications())
      interval = setInterval(() => {
        dispatch(getNewNotifications())
      }, 60000)
    }
    return () => {
      clearInterval(interval)
    }
  }, [isAuthenticated, dispatch, canFetchNewNotifications])
}

export default useNotifications
