import useQueryParams from './queryParams'

interface UseSideDrawerParamsReturnValue {
  sideDrawerParam: string | null
  contentParam: string | null
}

const useSideDrawerParams = (): UseSideDrawerParamsReturnValue => {
  const query = useQueryParams()
  const sideDrawerParam = query.get('sideDrawer')
  const contentParam = query.get('content')
  return { sideDrawerParam, contentParam }
}

export default useSideDrawerParams
