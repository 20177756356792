import { Trans } from '@lingui/macro'
import { Form } from 'antd'
import Title from 'antd/lib/typography/Title'
import ButtonElement from 'components/molecules/button-element'
import FormInput from 'components/molecules/form-item/FormInput'
import FormInputPasswordPanel from 'components/molecules/form-item/FormInputPasswordPanel'
import useFormValidity from 'hooks/useFormValidity'
import { useDispatch } from 'react-redux'
import { activateAccount } from 'redux/account-activation'

interface ActivationFormProps {
  activationCode: string | null
  email?: string
  username?: string
}

const ActivationForm: React.FC<ActivationFormProps> = ({ activationCode, email, username }) => {
  const dispatch = useDispatch()
  const { form, validate, isFormValid } = useFormValidity()
  const submitForm = () =>
    dispatch(activateAccount({ activationCode: activationCode || '', password: form.getFieldValue('password') }))
  return (
    <Form layout="vertical" form={form} onFieldsChange={validate}>
      <Title level={4}>
        <Trans id="account_activation.set_password_header">Set password</Trans>
      </Title>
      <FormInput disabled value={email} label={<Trans id="account_activation.user.email_label">Email</Trans>} />
      <FormInput
        disabled
        value={username}
        label={<Trans id="account_activation.user.username_label">Name and surname</Trans>}
      />
      <FormInputPasswordPanel passwordLabel={<Trans id="account_activation.set_password_label">Password</Trans>} />
      <ButtonElement onClick={submitForm} disabled={!isFormValid}>
        <Trans id="account_activation.set_password_button_text">Set password</Trans>
      </ButtonElement>
    </Form>
  )
}

export default ActivationForm
