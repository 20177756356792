import { Trans } from '@lingui/macro'
import { Checkbox, Space, Divider } from 'antd'
import Text from 'components/atoms/text'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCheckedUsers,
  selectFilteredUsers,
} from 'redux/project/project-config/members-permissions/ProjectMembersPermissionsConfig.selectors'
import {
  checkUsers,
  uncheckUsers,
} from 'redux/project/project-config/members-permissions/ProjectMembersPermissionsConfig.slice'
import { selectProjectMembers } from 'redux/project/project-config/members/ProjectMembersConfig.selectors'
import UserCheckbox from './UserCheckbox'

const ProjectMembersPermissionsConfigUsers: React.FC = () => {
  const filteredUsers = useSelector(selectFilteredUsers)
  const checkedUsers = useSelector(selectCheckedUsers)
  const dispatch = useDispatch()
  const isCheckedAll = filteredUsers.length === checkedUsers.length && checkedUsers.length !== 0
  const members = useSelector(selectProjectMembers)

  useEffect(() => {
    if (members.length !== 0) {
      dispatch(checkUsers(members.map(({ userId }) => userId)))
    }
  }, [dispatch, members])

  return (
    <Space direction="vertical" style={{ width: 212 }}>
      <Checkbox
        indeterminate={!isCheckedAll && checkedUsers.length !== 0}
        onChange={() => {
          if (isCheckedAll) dispatch(uncheckUsers())
          else dispatch(checkUsers(filteredUsers.map(({ userId }) => userId)))
        }}
        checked={isCheckedAll}
      >
        <Text textStyle="regularText-smallSemiBold">
          <Trans id="project_config.members.current_selection">Current selection</Trans>
        </Text>
      </Checkbox>
      <Divider style={{ margin: '4px 0' }} />
      {filteredUsers?.map((user) => (
        <UserCheckbox key={user.userId} user={user} />
      ))}
    </Space>
  )
}

export default ProjectMembersPermissionsConfigUsers
