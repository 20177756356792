import { useState } from 'react'
import { RcFile } from 'antd/lib/upload/interface'

interface UseUploadReturn {
  file: RcFile | null
  onRemove: () => void
  beforeUpload: (file: RcFile, FileList: RcFile[]) => boolean
}

const useUpload = (): UseUploadReturn => {
  const [file, setFile] = useState<RcFile | null>(null)

  const onRemove = () => setFile(null)
  const beforeUpload = (f: RcFile) => {
    setFile(f)
    return false
  }

  return { file, onRemove, beforeUpload }
}

export default useUpload
