import styled from 'styled-components'
import Text from '../text'

interface TextBoxProps {
  number?: number
  text: string
  isActive?: boolean
}

interface StyledContainerProps {
  $isActive: boolean
}

const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  height: ${({ theme }) => theme.sizes.l};
  border: 1px solid ${({ theme, $isActive }) => ($isActive ? theme.colors.primaryP04 : theme.colors.grayG04)};
  div:not(:last-child) {
    border-right: 1px solid ${({ theme, $isActive }) => ($isActive ? theme.colors.primaryP04 : theme.colors.grayG04)};
  }
`

const StyledBox = styled.div`
  padding: 0 ${({ theme }) => theme.sizes.s};
  height: 100%;
  ${({ theme }) => theme.mixins.flexCenter}
`

const TextBox: React.FC<TextBoxProps> = ({ number, text, isActive = false }) => {
  const textStyle = isActive ? 'regularText-smallSemiBold' : 'regularText-small'
  return (
    <StyledContainer $isActive={isActive}>
      {number !== undefined && (
        <StyledBox>
          <Text textStyle={textStyle}>{number}</Text>
        </StyledBox>
      )}
      <StyledBox>
        <Text textStyle={textStyle}>{text}</Text>
      </StyledBox>
    </StyledContainer>
  )
}

export default TextBox
