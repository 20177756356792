import { Spin } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import useLoading from 'hooks/loading'
import CheckboxElement from '.'

interface CheckboxWithLoadingProps {
  onChange?: (e: CheckboxChangeEvent) => Promise<unknown> | void
  loading?: boolean
}

const CheckboxWithLoading: React.FC<
  Omit<React.ComponentProps<typeof CheckboxElement>, 'onChange'> & CheckboxWithLoadingProps
  // eslint-disable-next-line react/jsx-props-no-spreading
> = ({ onChange, loading, ...props }) => {
  const { isLoading, functionWithLoading } = useLoading(onChange)
  return (
    <Spin spinning={isLoading || !!loading}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <CheckboxElement onChange={functionWithLoading} {...props} />
    </Spin>
  )
}

export default CheckboxWithLoading
