import { defineMessage, Trans } from '@lingui/macro'
import { Form, Modal } from 'antd'
import ButtonElement from 'components/molecules/button-element'
import { cancelText, saveText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import { useDispatch, useSelector } from 'react-redux'
import { closeSchemeModal } from 'redux/administration/administration-naming-scheme'
import {
  selectAdministationNamingSchemeInModal,
  selectAdministationNamingSchemeIsModalActionCopy,
  selectAdministrationNamingSchemes,
} from 'redux/administration/administration-naming-scheme/AdministrationNamingScheme.selectors'
import {
  createNamingScheme,
  patchScheme,
} from 'redux/administration/administration-naming-scheme/AdministrationNamingScheme.slice'
import { MessageDescriptor } from '@lingui/core'
import { useEffect } from 'react'
import { NamingScheme } from 'models/administration'
import useFormValidity from 'hooks/useFormValidity'
import Text from 'components/atoms/text'
import NamingSchemeModalForm from './form'
import NamingSchemeModalAlert from './NamingSchemeModalAlerts'

const modalTitles = {
  CREATE: defineMessage({
    id: 'administration.naming_schemes.modal.create_new_scheme_header',
    message: 'Create naming scheme',
  }),
  EDIT: defineMessage({ id: 'administration.naming_schemes.modal.edit_scheme_header', message: 'Edit naming scheme' }),
  COPY: defineMessage({ id: 'administration.naming_schemes.modal.copy_scheme_header', message: 'Copy naming scheme' }),
}

const getModalTitle: (isCreate: boolean, isCopy: boolean) => MessageDescriptor = (isCreate, isCopy) => {
  if (isCreate) return modalTitles.CREATE
  if (isCopy) return modalTitles.COPY
  return modalTitles.EDIT
}

const getFormDefaultValue = (namingScheme: NamingScheme | undefined, isActionCopy: boolean) => {
  if (namingScheme === undefined) {
    return {
      name: undefined,
      isDefault: false,
      scheme: [
        {
          key: 'fileNumber',
          args: { length: 3, inShortName: true },
        },
        {
          key: 'revision',
          args: { inShortName: false },
        },
      ],
    }
  }
  const { name, isDefault, scheme } = namingScheme
  return { name, isDefault: isDefault && !isActionCopy, scheme }
}

const NamingSchemeModal: React.FC = () => {
  const dispatch = useDispatch()
  const { form, isFormValid, validate } = useFormValidity()
  const scheme = useSelector(selectAdministationNamingSchemeInModal)
  const isActionCopy = useSelector(selectAdministationNamingSchemeIsModalActionCopy)
  const isActionCreate = scheme === undefined
  const currentNames = useSelector(selectAdministrationNamingSchemes)
    .map(({ name }) => name)
    .filter((name) => isActionCopy || name !== scheme?.name)
  const onCancel = () => {
    dispatch(closeSchemeModal())
  }
  useEffect(() => {
    if (isActionCopy) {
      form.validateFields()
    }
  }, [form, isActionCopy])
  const onSubmit = () => {
    if (scheme && !isActionCopy) {
      dispatch(patchScheme({ schemeId: scheme?.id, data: form.getFieldsValue() }))
    } else {
      dispatch(createNamingScheme(form.getFieldsValue()))
    }
  }

  return (
    <Modal
      visible
      onCancel={onCancel}
      maskClosable={false}
      width={1050}
      title={<Text textStyle="header-H2b">{trans(getModalTitle(isActionCreate, isActionCopy))}</Text>}
      footer={
        <>
          <ButtonElement onClick={onCancel} type="tertiary">
            {trans(cancelText)}
          </ButtonElement>
          <ButtonElement onClick={onSubmit} disabled={!isFormValid}>
            {isActionCreate ? (
              <Trans id="administration.naming_schemes.modal.create_new_scheme_confirm_button_text">Create</Trans>
            ) : (
              trans(saveText)
            )}
          </ButtonElement>
        </>
      }
    >
      <Form
        form={form}
        labelCol={{ sm: { span: 7 } }}
        initialValues={getFormDefaultValue(scheme, isActionCopy)}
        layout="vertical"
        onFieldsChange={validate}
      >
        <NamingSchemeModalForm currentNames={currentNames} canSetAsNotDefault={!scheme?.isDefault || isActionCopy} />
      </Form>
      <NamingSchemeModalAlert />
    </Modal>
  )
}

export default NamingSchemeModal
