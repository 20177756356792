import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Breadcrumb, Skeleton } from 'antd'
import Icon from 'components/atoms/icon'
import {
  selectProjectDetails,
  selectFetchProjectDetailsLoading,
} from 'redux/project/project-details/ProjectDetails.selectors'
import { WorkOutline } from '@material-ui/icons'
import useProjectId from 'hooks/useProjectId'
import themePxValueToNumber from 'helpers/Styles.helpers'
import { Trans } from '@lingui/macro'

const StyledDiv = styled.div`
  padding: ${(props) => props.theme.sizes.xs} ${(props) => props.theme.sizes.default};
  background-color: rgba(${(props) => props.theme.colors.grayG02Rgb}, 0.8);
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledBreadcrumb = styled(Breadcrumb)`
  width: calc(100% - ${({ theme }) => themePxValueToNumber(theme.sizes.l)}px);
  display: flex;
  & > span:last-child {
    max-width: calc(100% - 100px);
  }
`

const StyledBreadcrumbItem = styled(Breadcrumb.Item)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;
`

const ProjectNamePanel: React.FC = () => {
  const projectDetails = useSelector(selectProjectDetails)
  const { isLoading } = useSelector(selectFetchProjectDetailsLoading)
  const settingsIconActive = projectDetails?.permissions.isAdmin
  const projectId = useProjectId()
  return (
    <StyledDiv>
      {projectDetails ? (
        <StyledBreadcrumb>
          <Breadcrumb.Item>
            <Link to="/">
              <Trans id="project.name_panel.portfolio_text">Portfolio</Trans>
            </Link>
          </Breadcrumb.Item>
          <StyledBreadcrumbItem>{projectDetails && projectDetails.name}</StyledBreadcrumbItem>
        </StyledBreadcrumb>
      ) : (
        <Skeleton.Button size="small" active={isLoading} />
      )}
      {settingsIconActive && (
        <Link to={`/project-config/${projectId}/1`}>
          <Icon color="baseBlackish">
            <WorkOutline />
          </Icon>
        </Link>
      )}
    </StyledDiv>
  )
}

export default ProjectNamePanel
