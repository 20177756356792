import { Camera, CesiumComponentRef, Globe, Viewer, Scene, ImageryLayer } from 'resium'
import { MutableRefObject, useMemo, useRef, useEffect, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Cartesian2,
  Cartesian3,
  ImageryLayerFeatureInfo,
  OpenStreetMapImageryProvider,
  Viewer as CViewer,
} from 'cesium'
import EventsHandler from 'components/molecules/cesium/EventsHandler'
import { selectProjectDetails, selectProjectMapSettings } from 'redux/project/project-details/ProjectDetails.selectors'
import {
  selectShouldCameraReset,
  selectGeoserverAuthenticationData,
  selectSceneMode,
  selectIsScaleLocked,
} from 'redux/cesium-viewer/CesiumViewer.selectors'
import createImageryProvider from 'helpers/Geoserver.helpers'
import { GeoserverAuthenticationData, setShouldCameraReset } from 'redux/cesium-viewer/CesiumViewer.slice'
import { selectVisibleGisFileLayers } from 'redux/map/map-gis-layers/map-gis-file-layers/MapGisFileLayers.selectors'
import { selectVisibleGisProjectLayers } from 'redux/map/map-gis-layers/map-gis-project-layers/MapGisProjectLayers.selectors'
import { selectWmsLayersInfo } from 'redux/map/map-wms-layers/MapWmsLayers.selectors'
import SurfaceNormalCamera from './SurfaceNormalCamera'
import useCesiumActions from './useCesiumActions'
import CesiumViewerErrorBoundary from './CesiumViewerErrorBoundary'

export interface CesiumViewerProps {
  onFeatureSelected?: (featurePromise?: Promise<ImageryLayerFeatureInfo[]>) => void
  isConfigMode?: boolean
}

/* eslint-disable max-lines-per-function */
/* eslint-disable-next-line max-statements */
const CesiumViewer: React.FunctionComponent<CesiumViewerProps> = memo(({ onFeatureSelected, isConfigMode = false }) => {
  const dispatch = useDispatch()
  const viewerRef: MutableRefObject<CesiumComponentRef<CViewer> | null> = useRef(null)
  const projectDetails = useSelector(selectProjectDetails)
  const mapSettings = useSelector(selectProjectMapSettings)
  const sceneMode = useSelector(selectSceneMode)
  const shouldCameraReset = useSelector(selectShouldCameraReset)
  const isScaleLocked = useSelector(selectIsScaleLocked)

  const { handleCameraMoveEnd, onPreRender, handleMouseMoveAction, handlePostRender } = useCesiumActions(
    isConfigMode,
    viewerRef
  )

  function leftClickAction(event: { position: Cartesian2 }) {
    const viewer = viewerRef?.current?.cesiumElement
    if (viewer) {
      const pickRay = viewer.camera.getPickRay(event.position)
      const featurePromise = viewer.imageryLayers.pickImageryLayerFeatures(pickRay, viewer.scene)
      onFeatureSelected?.(featurePromise)
    }
  }

  useEffect(() => {
    const controller = viewerRef?.current?.cesiumElement?.scene?.screenSpaceCameraController
    if (controller) {
      controller.enableZoom = !isScaleLocked
    }
  }, [viewerRef, isScaleLocked])

  const cameraPosition = useMemo(
    () => mapSettings && Cartesian3.fromDegrees(mapSettings.longitude, mapSettings.latitude, mapSettings.scale),
    [mapSettings]
  )
  useEffect(() => {
    if (!isConfigMode && shouldCameraReset) {
      const camera = viewerRef?.current?.cesiumElement?.camera
      if (camera) {
        camera.flyTo({ destination: cameraPosition, duration: 0 })
      }
      dispatch(setShouldCameraReset(false))
    }
  }, [isConfigMode, cameraPosition, shouldCameraReset, dispatch])

  const openStreetMapImageryProvider = useMemo(
    () => new OpenStreetMapImageryProvider({ url: '//a.tile.openstreetmap.org/' }),
    []
  )

  // if we try to download too many layers in one call then the URL will be too long so we split the layers
  // into chunks and use multiple providers
  const createGisProviders = (
    gisLayers: string[],
    geoserverUrl: string,
    geoserverAuth: GeoserverAuthenticationData,
    chunkSize = 32
  ) => {
    return [...Array(Math.ceil(gisLayers.length / chunkSize))].map((_, i) => {
      return {
        key: `provider${i}`,
        provider: createImageryProvider(
          geoserverUrl,
          gisLayers.slice(i * chunkSize, (i + 1) * chunkSize).join(','),
          geoserverAuth
        ),
      }
    })
  }

  const gisFileLayers = useSelector(selectVisibleGisFileLayers) || []
  const gisProjectLayers = useSelector(selectVisibleGisProjectLayers) || []
  const allGisLayers = [...gisFileLayers, ...gisProjectLayers]
  const wmsLayersInfo = useSelector(selectWmsLayersInfo)
  const geoserverAuthenticationData = useSelector(selectGeoserverAuthenticationData)
  const imageryProviders = useMemo(() => {
    const geoserverUrl = projectDetails?.geoserverUrl
    const gisProviders = geoserverUrl && createGisProviders(allGisLayers, geoserverUrl, geoserverAuthenticationData)
    const wmsProviders = wmsLayersInfo
      .filter((info) => info.visibleLayers.length > 0)
      .map((info) => ({
        key: info.url,
        provider: createImageryProvider(info.url, info.visibleLayers.join(',')),
      }))
    return gisProviders && allGisLayers.length > 0 ? [...gisProviders, ...wmsProviders] : [...wmsProviders]
  }, [allGisLayers, wmsLayersInfo, projectDetails, geoserverAuthenticationData])

  const contextProps = isConfigMode
    ? {
        contextOptions: {
          webgl: { preserveDrawingBuffer: true },
        },
      }
    : {}

  return (
    <CesiumViewerErrorBoundary>
      <Viewer
        ref={viewerRef}
        full
        onMouseMove={handleMouseMoveAction}
        imageryProvider={openStreetMapImageryProvider}
        shadows={false}
        geocoder={false}
        navigationInstructionsInitiallyVisible={false}
        navigationHelpButton={false}
        baseLayerPicker={false}
        timeline={false}
        homeButton={false}
        fullscreenButton={false}
        automaticallyTrackDataSourceClocks={false}
        animation={false}
        sceneMode={sceneMode}
        requestRenderMode
        maximumRenderTimeChange={Infinity}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...contextProps}
      >
        <Scene onPreRender={onPreRender} onPostRender={handlePostRender} fxaa={false}>
          <Globe maximumScreenSpaceError={1.5} />
        </Scene>
        <Camera onMoveEnd={isConfigMode ? handleCameraMoveEnd : undefined} />
        {cameraPosition && <SurfaceNormalCamera actualPosition={cameraPosition} />}
        {imageryProviders.map((provider) => (
          <ImageryLayer key={provider.key} imageryProvider={provider.provider} alpha={1} />
        ))}
        <EventsHandler onLeftClick={leftClickAction} />
      </Viewer>
    </CesiumViewerErrorBoundary>
  )
})

export default CesiumViewer
