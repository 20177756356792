import { useState } from 'react'
import { TreeProps } from 'rc-tree'
import { ProjectLayer } from 'models/map'
import { isUndefinedOrEmpty, isDefinedAndNotEmpty } from 'helpers/Collection.helpers'
import renderTitle from './RenderTitle'
import StyledTree from './MapSideDrawerTree.styles'
import { TreeGeodataLayer, TreeNode } from './MapSideDrawerTreeTypes'
import { EntryLayers } from '../../../../models/map'

interface MapSideDrawerTreeProps {
  data: EntryLayers[] | ProjectLayer[]
  onCheck?: TreeProps['onCheck']
  checkedKeys?: (string | number)[]
}

const withIsLeafAndKeys = (treeData: (ProjectLayer | TreeGeodataLayer)[]): TreeNode[] => {
  return (treeData as Array<ProjectLayer | TreeGeodataLayer>).map((node: ProjectLayer | TreeGeodataLayer) => {
    const { name, id, geoServerUrl, ...other } = node
    const isLeaf = isUndefinedOrEmpty(node.children) && isUndefinedOrEmpty(node.geodataLayers)
    const children = withIsLeafAndKeys(
      isDefinedAndNotEmpty(node.children) ? node.children || [] : node.geodataLayers || []
    )
    return { ...other, key: `${geoServerUrl}:${name}:${id}`, title: name, geoServerUrl, isLeaf, children }
  })
}

const parseEntryLayersTree = (data: EntryLayers[]) => {
  return data.map((entry) => {
    return {
      title: entry.entry.name,
      key: `${entry.entry.id}`,
      isLeaf: false,
      children: entry.files.map((file) => {
        return {
          title: file.file.name,
          key: `${entry.entry.id}_${file.file.id}}`,
          isLeaf: false,
          children: file.layers.map((layer) => {
            return {
              title: layer.name,
              key: `${entry.entry.id}_${file.file.id}_${layer.name}`,
              isLeaf: true,
              gsLayersReferences: layer.gsLayersReferences,
            }
          }),
        }
      }),
    }
  })
}

const MapSideDrawerTree: React.FC<MapSideDrawerTreeProps> = ({ data, onCheck, checkedKeys }) => {
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([])
  const handleSelectNode = (selectedKeysValue: React.Key[]) => setSelectedKeys(selectedKeysValue)
  const handleClearSelectedKeys = () => setSelectedKeys([])

  const treeData =
    data.length > 0 && 'entry' in data[0]
      ? parseEntryLayersTree(data as EntryLayers[])
      : withIsLeafAndKeys(data as TreeGeodataLayer[])
  return (
    <StyledTree
      onSelect={handleSelectNode}
      onCheck={onCheck}
      checkedKeys={checkedKeys}
      titleRender={renderTitle(handleClearSelectedKeys)}
      treeData={treeData}
      selectedKeys={selectedKeys}
      checkable
      showLine={{ showLeafIcon: false }}
    />
  )
}

export default MapSideDrawerTree
