import { defineMessage, t } from '@lingui/macro'
import ErrorAlert from 'components/atoms/error-alert'
import { handleApiErrors } from 'helpers/HttpError.helpers'
import { useSelector } from 'react-redux'
import {
  selectActivateProjectLoading,
  selectFetchProjectScheduledActivationLoading,
  selectScheduleProjectActivationLoading,
} from 'redux/project/project-config/activation/ProjectActivationConfig.selectors'

const errorMessageDict = {
  'project.already_active': defineMessage({
    id: 'project_config.activation.alert.already_active_error',
    message: 'Project has already been activated.',
  }),
  'project.activation.future_datetime_required': defineMessage({
    id: 'project_config.activation.alert.future_datetime_required_error',
    message: 'Only in the future project activation is possible.',
  }),
}

const ProjectActivationConfigAlert: React.FC = () => {
  const activateProjectLoading = useSelector(selectActivateProjectLoading)
  const scheduleProjectActivationLoading = useSelector(selectScheduleProjectActivationLoading)
  const fetchActivationLoading = useSelector(selectFetchProjectScheduledActivationLoading)

  const alertMessageDict = [
    {
      error: fetchActivationLoading.error,
      message: t({
        id: 'project_config.activation.alert.fetch_failed',
        message: 'Could not retrieve activation project data.',
      }),
    },
    {
      error: activateProjectLoading.error,
      message: handleApiErrors(errorMessageDict, activateProjectLoading.error?.errors),
    },
    {
      error: scheduleProjectActivationLoading.error,
      message: handleApiErrors(errorMessageDict, scheduleProjectActivationLoading.error?.errors),
    },
  ]
  return <ErrorAlert alertMessageDict={alertMessageDict} />
}

export default ProjectActivationConfigAlert
