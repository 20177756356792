import { RootState } from 'redux/store'
import { BackendLoading } from 'redux/redux.shared'
import { ProjectFlowsExecDetails, ProjectFlowsReviewDetails } from 'models/flow'
import { Creator, FrontendFile } from 'models/files'
import { FlowsState, ReviewOption } from './Flows.types'

export const selectProjectFlowsState = (state: RootState): FlowsState => state.files.flows

export const selectProjectFlows = (state: RootState): ProjectFlowsExecDetails[] =>
  selectProjectFlowsState(state).projectFlows

export const selectFetchingProjectFlowsLoading = (state: RootState): BackendLoading =>
  selectProjectFlowsState(state).loading.fetchProjectExecutableFlows

export const selectExecutingFlowLoading = (state: RootState): BackendLoading =>
  selectProjectFlowsState(state).loading.execFlow

export const selectFetchingReviewersLoading = (state: RootState): BackendLoading =>
  selectProjectFlowsState(state).loading.getReviewers

export const selectFetchingReviewDetailsLoading = (state: RootState): BackendLoading =>
  selectProjectFlowsState(state).loading.getFileCurrentFlowReviewDetails

export const selectIsFlowModalVisible = (state: RootState): boolean =>
  selectProjectFlowsState(state).modalData.isVisible

export const selectExecutedFlow = (state: RootState): ProjectFlowsExecDetails | undefined =>
  selectProjectFlowsState(state).modalData.flow

export const selectExecutedFile = (state: RootState): FrontendFile | undefined =>
  selectProjectFlowsState(state).modalData.frontendFile

export const selectIsFlowReviewModalVisible = (state: RootState): boolean =>
  selectProjectFlowsState(state).modalReviewData.isVisible

export const selectReviewedFile = (state: RootState): FrontendFile | undefined =>
  selectProjectFlowsState(state).modalReviewData.frontendFile

export const selectReviewDetails = (state: RootState): ProjectFlowsReviewDetails | undefined =>
  selectProjectFlowsState(state).reviewDetails

export const selectReviewDecision = (state: RootState): ReviewOption | undefined =>
  selectProjectFlowsState(state).reviewDecision

export const selectAvailableReviewers = (state: RootState): Creator[] => selectProjectFlowsState(state).reviewers

export const selectSelectedReviewer = (state: RootState): number | undefined =>
  selectProjectFlowsState(state).selectedReviewer
