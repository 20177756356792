import { FolderOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { ColumnsType } from 'antd/lib/table'
import Icon from 'components/atoms/icon'
import FilesTableCellItem from 'components/molecules/files/files-table-cells/FilesTableCellItem'
import useTableColumnFiltering, { onFilterStringColumn } from 'components/molecules/table/useTableColumnFiltering'
import formatDate from 'helpers/Dates.helpers'
import { preparePath } from 'helpers/Files.helpers'
import useCurrentPath from 'hooks/query/currentPath'
import { useHistory, useLocation } from 'react-router'
import { GroupDataSource } from './FilesGroups.types'

const useFilesGroupColumns: () => ColumnsType<GroupDataSource> = () => {
  const { pathname } = useLocation()
  const pathParam = useCurrentPath()
  const currentPath = preparePath(pathname, pathParam)
  const history = useHistory()
  return [
    {
      title: t({ id: 'files.documentation.table.groups.column_name_header', message: 'Name' }),
      dataIndex: 'name',
      key: 'name',
      render: (val, { name }) => (
        <FilesTableCellItem
          text={name}
          icon={
            <Icon size="default" color="grayG06">
              <FolderOutlined />
            </Icon>
          }
          onDoubleClick={() => history.push(`${currentPath}/${name}`)}
        />
      ),
      ...useTableColumnFiltering(
        t({ id: 'files.documentation.table.groups.search_name_placeholder', message: 'Search for name' })
      ),
      onFilter: (value: string | number | boolean, record: GroupDataSource) => onFilterStringColumn(value, record.name),
    },
    {
      title: t({ id: 'files.documentation.table.groups.column_update_header', message: 'Update date' }),
      dataIndex: 'update',
      key: 'update',
      render: (val, { lastUpdated }) => <FilesTableCellItem text={formatDate(lastUpdated)} />,
      ...useTableColumnFiltering(
        t({ id: 'files.documentation.table.groups.search_update_placeholder', message: 'Search for update date' })
      ),
      onFilter: (value: string | number | boolean, record: GroupDataSource) =>
        onFilterStringColumn(value, formatDate(record.lastUpdated)),
    },
  ]
}

export default useFilesGroupColumns
