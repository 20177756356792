import { WebMapServiceImageryProvider, Resource } from 'cesium'
import { GeoserverAuthenticationData } from '../redux/cesium-viewer/CesiumViewer.slice'

function createAuthenticationHeaders(geoserverAuthenticationData: GeoserverAuthenticationData) {
  if (geoserverAuthenticationData !== undefined) {
    return {
      Authorization: `Basic ${geoserverAuthenticationData.token}`,
    }
  }
  return {}
}

export default function createImageryProvider(
  wmsUrl: string,
  layers: string,
  geoserverAuthenticationData: GeoserverAuthenticationData = undefined
): WebMapServiceImageryProvider {
  return new WebMapServiceImageryProvider({
    url: new Resource({
      url: wmsUrl,
      headers: createAuthenticationHeaders(geoserverAuthenticationData),
    }),
    layers,
    parameters: {
      transparent: 'true',
      format: 'image/png',
    },
    enablePickFeatures: true,
  })
}
