import { Trans } from '@lingui/macro'
import { ColumnsType } from 'antd/lib/table'
import { actionsTableColumnText } from 'data/messages/controls'
import { attributeText } from 'data/messages/misc'
import trans from 'helpers/i18n.helpers'
import { FlowAttributeConfig } from 'models/flow'
import AttributeActions from './AttributeActions'
import AttributeName from './AttributeName'
import SelectAttributeValues from './SelectAttributeValues'

const useAttributesColumns = (): ColumnsType<FlowAttributeConfig> => {
  return [
    {
      title: trans(attributeText),
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (value, { id, name }) => <AttributeName id={id} name={name} />,
    },
    {
      title: <Trans id="project_config.attributes.table.colum_values_label">Available values</Trans>,
      dataIndex: 'availableValues',
      key: 'availableValues',
      width: 500,
      render: (value, { id, values }) => <SelectAttributeValues values={values} attributeId={id} />,
    },
    {
      title: trans(actionsTableColumnText),
      dataIndex: 'actions',
      key: 'actions',
      width: 75,
      render: (value, { id }) => <AttributeActions id={id} />,
    },
  ]
}

export default useAttributesColumns
