import { Trans } from '@lingui/macro'
import { Attribute, NamingSchemeElement } from 'models/administration'
import styled from 'styled-components'
import Table from 'components/molecules/table'
import { getSchemeData } from 'redux/administration/administration-naming-scheme/AdministrationNamingScheme.helpers'
import { useSelector } from 'react-redux'
import { selectAdministrationCodesSettingsDict } from 'redux/administration/administration-code-settings/AdministrationCodeSettings.selectors'
import { Space } from 'antd'
import Text from 'components/atoms/text'
import namingSchemeColumns from './namingSchemeColumns'

interface AdministrationNamingSchemeDetailsProps {
  scheme?: NamingSchemeElement[]
  attributes: Attribute[]
}

const StyledTable = styled(Table)`
  &&&& {
    .ant-table table {
      width: auto;
    }
    td {
      border-color: #e3e3e8;
      &:nth-child(5),
      &:nth-child(2),
      &:nth-child(4) {
        text-align: center;
      }
    }
  }
`

const StyledDiv = styled.div`
  display: inline-flex;
  width: 100%;
`

const NamingSchemeTable: React.FC<AdministrationNamingSchemeDetailsProps> = ({ scheme, attributes }) => {
  const columns = namingSchemeColumns
  const settings = useSelector(selectAdministrationCodesSettingsDict)
  if (scheme === undefined) {
    return (
      <Text textStyle="regularText-medium">
        <Trans id="project_config.naming_scheme.table.empty_message">NO SCHEME SELECTED</Trans>
      </Text>
    )
  }
  const schemeData = getSchemeData(scheme, attributes, settings, true)
  return (
    <StyledDiv>
      <Space direction="vertical" size="large" style={{ display: 'inline-flex' }}>
        <StyledTable columns={columns} dataSource={schemeData} bordered pagination={false} scroll={{ y: '50vh' }} />
      </Space>
    </StyledDiv>
  )
}

export default NamingSchemeTable
