import styled from 'styled-components'
import BaseFormItem from 'components/molecules/form-item/BaseFormItem'
import RadioGroup from 'components/molecules/radio/RadioGroup'
import { Trans } from '@lingui/macro'
import { useSelector } from 'react-redux'
import {
  selectReviewDetails,
  selectIsFlowReviewModalVisible,
  selectReviewDecision,
} from 'redux/files/flows/Flows.selectors'
import { Space, Form } from 'antd'
import { itemArr } from 'helpers/Collection.helpers'
import { useAppDispatch } from 'redux/store'
import { setAfterReviewStatus } from 'redux/files/flows'
import { ConsequencesTypes } from 'models/flow'
import { acceptOption, acceptWithCommentOption, rejectOption, rejectWithCommentOption } from './flowOptions'

const StyledSpace = styled(Space)`
  width: 365px;
`

const FlowReviewDecisionPanel: React.FC = () => {
  const dispatch = useAppDispatch()
  const flow = useSelector(selectReviewDetails)
  const decision = useSelector(selectReviewDecision)
  const isVisible = useSelector(selectIsFlowReviewModalVisible)
  if (!isVisible || flow === undefined) {
    return null
  }
  const options = [
    acceptOption,
    ...itemArr(
      acceptWithCommentOption,
      flow.consequences.find(({ consequenceType }) => consequenceType === ConsequencesTypes.POSITIVE)
        ?.fileUploadRequired
    ),
    rejectOption,
    ...itemArr(
      rejectWithCommentOption,
      flow.consequences.find(({ consequenceType }) => consequenceType === ConsequencesTypes.NEGATIVE)
        ?.fileUploadRequired
    ),
  ]
  return (
    <StyledSpace direction="vertical" size="large">
      <Form labelCol={{ sm: { span: 20 } }}>
        <BaseFormItem label={<Trans id="project.flows.modal.decision.label">Decision</Trans>} boldLabel>
          <RadioGroup
            selectedValue={decision}
            options={options}
            onChange={(e) => dispatch(setAfterReviewStatus(e.target.value))}
          />
        </BaseFormItem>
      </Form>
    </StyledSpace>
  )
}

export default FlowReviewDecisionPanel
