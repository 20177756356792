import styled from 'styled-components'
import SelectLanguage from './SelectLanguage'

const StyledDiv = styled.div`
  position: fixed;
  right: 3%;
  bottom: ${(props) => props.theme.sizes.m};
`

const SelectLanguageWithPlacement: React.FC = () => {
  return (
    <StyledDiv>
      <SelectLanguage />
    </StyledDiv>
  )
}

export default SelectLanguageWithPlacement
