interface CoordinatesPanelItemProps {
  name: React.ReactNode
  value: React.ReactNode
}

const CoordinatesPanelItem: React.FC<CoordinatesPanelItemProps> = ({ name, value }) => {
  return (
    <li>
      {name} {value}
    </li>
  )
}

export default CoordinatesPanelItem
