import styled from 'styled-components'
import { Card } from 'antd'
import useSideDrawer from 'hooks/side-drawer/sideDrawer'
import mapSideDrawerOptions from './MapSideDrawerOptions'

interface StyledCardProps {
  $isVisible: boolean
}

const StyledCard = styled(Card)<StyledCardProps>`
  position: absolute;
  left: ${(props) => props.theme.sizes.projectAsideWidth};
  top: 0;
  width: 422px;
  max-height: calc(100% - 32px);
  overflow-y: auto;
  overflow-x: hidden;
  transition: ${(props) => props.theme.transitions.default};
  ${(props) => (props.$isVisible ? 'transform: translateX(calc(-100% - 64px));' : '')}
  border-radius: ${(props) => props.theme.sizes.xs};
  margin: ${(props) => props.theme.sizes.default} 0;
  .ant-card-body {
    padding: ${(props) => props.theme.sizes.default};
  
  @media (max-width: 1400px) {
    margin: ${(props) => props.theme.sizes.s} 0;
    max-height: calc(100% - 16px);
  }
`

const MapSideDrawer: React.FC = () => {
  const { isVisible, content } = useSideDrawer('map', mapSideDrawerOptions)
  return <StyledCard $isVisible={isVisible}>{content}</StyledCard>
}

export default MapSideDrawer
