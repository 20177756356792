import { Color } from 'styled-components'
import Text from 'components/atoms/text'
import WithOverflow from 'components/atoms/with-overflow'
import TooltipElement from 'components/molecules/tooltip-element'
import { Space } from 'antd'

interface FilesTableCellItemProps {
  text?: React.ReactNode
  tooltip?: React.ReactNode
  icon?: JSX.Element
  checkbox?: JSX.Element
  bold?: boolean
  indent?: boolean
  options?: React.ReactNode
  textColor?: Color
  onDoubleClick?: React.MouseEventHandler
  abbreviated?: boolean
}

const FilesTableCellItem: React.FC<FilesTableCellItemProps> = ({
  text,
  tooltip,
  icon,
  checkbox,
  bold = false,
  indent = false,
  options,
  textColor,
  onDoubleClick,
  abbreviated = false,
}) => {
  return (
    <div onDoubleClick={onDoubleClick} style={{ cursor: onDoubleClick ? 'pointer' : 'auto' }}>
      <Space>
        {icon}
        <TooltipElement placement="top" title={tooltip ?? text} disabled={!abbreviated}>
          <WithOverflow indent={indent}>
            {text && (
              <Text textStyle={bold ? 'regularText-smallSemiBold' : 'regularText-small'} color={textColor || 'grayG06'}>
                {text}
              </Text>
            )}
            {checkbox && checkbox}
          </WithOverflow>
        </TooltipElement>
        {options}
      </Space>
    </div>
  )
}

export default FilesTableCellItem
