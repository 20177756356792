import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Input, Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { SelectValue } from 'antd/lib/select'
import Icon from 'components/atoms/icon'
import Text from 'components/atoms/text'
import SelectElement from 'components/atoms/select-element'
import {
  selectProjectsTypeSelectState,
  selectPortfolioSearchBarValue,
} from 'redux/portfolio/PortfolioProjects.selectors'
import { setProjectsTypeSelectState, setSearchBarValue } from 'redux/portfolio/PortfolioProjects.slice'
import ButtonElement from 'components/molecules/button-element'
import { selectGlobalPermissions } from 'redux/login/Login.selectors'
import { selectIsProjectModalVisible } from 'redux/administration/administration-projects/AdministrationProjects.selectors'
import ProjectModal from 'components/organisms/administration/administration-content/projects/modals'
import { openProjectModal } from 'redux/administration/administration-projects'
import { useHistory } from 'react-router-dom'
import { setSelectedAdministrationMenuKey } from 'redux/administration/administration-menu'
import { Plural, t, Trans } from '@lingui/macro'

interface PortfolioHeaderProps {
  projectNumber: number
}

const StyledHeader = styled.header`
  display: grid;
  grid-template-columns: minmax(140px, 15rem) auto auto 1fr auto;
  gap: ${(props) => props.theme.sizes.l};
  > * {
    &:last-child {
      grid-column: -1 / span 1;
      justify-self: end;
    }
    &.ant-input-affix-wrapper {
      grid-column: 1 / span 1;
    }
    &.ant-btn:nth-child(2) {
      grid-column: -1 / span 1;
    }
    &.ant-typography {
      margin-bottom: 0;
    }
  }
`

const { Option } = Select

const selectValuesDict: { [key: string]: boolean } = {
  active: false,
  ended: true,
}

const PortfolioHeader: React.FC<PortfolioHeaderProps> = ({ projectNumber }) => {
  const history = useHistory()
  const projectsTypeSelectState = useSelector(selectProjectsTypeSelectState)
  const searchBarValue = useSelector(selectPortfolioSearchBarValue)
  const newProjectModalVisibility = useSelector(selectIsProjectModalVisible)
  const userGlobalPermissions = useSelector(selectGlobalPermissions)
  const dispatch = useDispatch()

  const handleSelectChange = (selectValue: SelectValue): void => {
    dispatch(
      setProjectsTypeSelectState({
        selectElementValue: selectValue as string,
        isEnded: selectValuesDict[selectValue as string],
      })
    )
  }
  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> | undefined = (e) => {
    dispatch(setSearchBarValue(e.target.value))
  }
  return (
    <StyledHeader>
      <Text textStyle="header-H1">
        <Plural id="portfolio.projects_count_text" value={projectNumber} one="# project" other="# projects" />
      </Text>
      {userGlobalPermissions?.isAdmin && (
        <ButtonElement
          onClick={() => {
            history.push('/administration')
            dispatch(setSelectedAdministrationMenuKey('projects'))
            dispatch(openProjectModal())
          }}
          type="primary"
          size="medium"
        >
          <Trans id="portfolio.new_project_button_text">Create new project</Trans>
        </ButtonElement>
      )}
      {newProjectModalVisibility && <ProjectModal />}
      <Input
        onChange={handleInputChange}
        value={searchBarValue}
        suffix={
          <Icon color="grayG06" size="default">
            <SearchOutlined />
          </Icon>
        }
        placeholder={t({ id: 'portfolio.search_placeholder', message: 'Search' })}
      />
      <SelectElement onChange={handleSelectChange} defaultValue={projectsTypeSelectState.selectElementValue}>
        <Option value="active">
          <Trans id="portfolio.project_activity_active">Active</Trans>
        </Option>
        <Option value="ended">
          <Trans id="portfolio.project_activity_finished">Finished</Trans>
        </Option>
      </SelectElement>
    </StyledHeader>
  )
}

export default PortfolioHeader
