import { BackendLoading, generateInitialLoadingState } from 'redux/redux.shared'
import { Discipline as SystemDiscipline } from 'models/administration'

export const loadingTypes = ['fetchDisciplines', 'updateDisciplinesConfig'] as const
export type LoadingTypes = typeof loadingTypes[number]

export interface ProjectDisciplinesConfigState {
  loading: Record<LoadingTypes, BackendLoading>
  disciplines: SystemDiscipline[]
  selectedDisciplines: Discipline[]
  isAddingNewDiscipline: boolean
}

export interface Discipline {
  id: number
  name: string
  code: string
  isUsed: boolean
}

export const initialState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  disciplines: [],
  selectedDisciplines: [],
  isAddingNewDiscipline: false,
} as ProjectDisciplinesConfigState
