import { t } from '@lingui/macro'
import ErrorAlert from 'components/atoms/error-alert'
import BaseErrorAlert from 'components/atoms/error-alert/BaseErrorAlert'
import { useSelector } from 'react-redux'
import { selectFetchingNewEntryMetadataAndDisciplinesLoading } from 'redux/files/files-new-entry/FilesNewEntry.selectors'
import {
  selectFetchingFilesDefaultSuitabilityCodeLoading,
  selectFilesDefaultSuitabilityCode,
} from 'redux/files/files-suitability/FilesSuitability.selectors'

const EntryAttributesFormError: React.FC = () => {
  const fetchingAttributesLoading = useSelector(selectFetchingNewEntryMetadataAndDisciplinesLoading)
  const fetchingDefaultSuitabilityCodeLoading = useSelector(selectFetchingFilesDefaultSuitabilityCodeLoading)
  const defaultSuitabilityCode = useSelector(selectFilesDefaultSuitabilityCode)

  if (defaultSuitabilityCode === null) {
    return (
      <BaseErrorAlert
        description={t({
          id: 'project.files.add_file_modal.attributes.no_default_suitability_code_error',
          message: 'No default suitability code is defined. No files can be added.',
        })}
      />
    )
  }

  const alertMessageDict = [
    {
      error: fetchingAttributesLoading.error,
      message: t({
        id: 'project.files.add_file_modal.attributes.fetch_failed',
        message: 'Fetching attributes failed',
      }),
    },
    {
      error: fetchingDefaultSuitabilityCodeLoading.error,
      message: t({
        id: 'project.files.add_file_modal.attributes.default_suitability_code_fetch_failed',
        message: 'Fetching default suitability code failed',
      }),
    },
  ]
  return <ErrorAlert alertMessageDict={alertMessageDict} />
}

export default EntryAttributesFormError
