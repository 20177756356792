import SpaceElement from 'components/atoms/space'
import ActivableText from 'components/molecules/activable-text'
import { useSelector } from 'react-redux'
import { selectProjectStagesAvailableToAdd } from 'redux/project/project-config/stages/ProjectStagesConfig.selectors'

interface AvailableStagesListProps {
  selectedStages: number[]
  setSelectedStages: React.Dispatch<React.SetStateAction<number[]>>
}

const activeColor = 'primaryP01'

const AvailableStagesList: React.FC<AvailableStagesListProps> = ({ selectedStages, setSelectedStages }) => {
  const projectStagesAvailableToAdd = useSelector(selectProjectStagesAvailableToAdd)
  return (
    <SpaceElement direction="horizontal" size="default">
      {projectStagesAvailableToAdd.map(({ name, id }) => {
        const isActive = !!selectedStages.find((stage) => stage === id)
        return (
          <ActivableText
            key={id}
            onClick={() => {
              if (isActive) setSelectedStages((prev) => prev.filter((stageId) => stageId !== id))
              else setSelectedStages((prev) => [...prev, id])
            }}
            activeColor={activeColor}
            isActive={isActive}
            dashed
          >
            {name}
          </ActivableText>
        )
      })}
    </SpaceElement>
  )
}

export default AvailableStagesList
