import { Trans } from '@lingui/macro'
import ButtonElement from 'components/molecules/button-element'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  createCanActivateStageSelector,
  createIsProjectStageCurrentSelector,
} from 'redux/project/project-config/stages/ProjectStagesConfig.selectors'
import { endStage, setCurrentStage } from 'redux/project/project-config/stages/ProjectStagesConfig.slice'
import { selectProjectIsActive } from 'redux/project/project-details/ProjectDetails.selectors'

interface StageStatusProps {
  stageId: number
}

const StageStatus: React.FC<StageStatusProps> = ({ stageId }) => {
  const [isHovered, setIsHovered] = useState(false)
  const isStageCurrent = useSelector(createIsProjectStageCurrentSelector(stageId))
  const canActivateStage = useSelector(createCanActivateStageSelector(stageId))
  const isProjectActive = useSelector(selectProjectIsActive)
  const dispatch = useDispatch()
  const isEndStageActionVisible = isHovered && isProjectActive
  if (isStageCurrent)
    return (
      <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <ButtonElement
          type={isEndStageActionVisible ? 'secondary' : 'primary'}
          onClick={isProjectActive ? () => dispatch(endStage()) : undefined}
        >
          {isEndStageActionVisible ? (
            <Trans id="project_config.stages.table.column_status.finish">Finish</Trans>
          ) : (
            <Trans id="project_config.stages.table.column_status.active">Active</Trans>
          )}
        </ButtonElement>
      </div>
    )

  if (canActivateStage)
    return (
      <ButtonElement onClick={() => dispatch(setCurrentStage(stageId))} type="secondary">
        <Trans id="project_config.stages.table.column_status.activate">Activate</Trans>
      </ButtonElement>
    )
  return null
}

export default StageStatus
