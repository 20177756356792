import { t } from '@lingui/macro'
import ErrorAlert from 'components/atoms/error-alert'
import { useSelector } from 'react-redux'
import {
  selectFetchPermissionsLoading,
  selectFetchProjectsDisciplinesLoading,
  selectFetchProjectsLoading,
} from 'redux/user-profile/permissions/UserPermissions.selectors'

const UserProfilePermissionsAlert: React.FC = () => {
  const fetchingProjectsLoading = useSelector(selectFetchProjectsLoading)
  const fetchingDisciplinesLoading = useSelector(selectFetchProjectsDisciplinesLoading)
  const fetchingPermissionsLoading = useSelector(selectFetchPermissionsLoading)
  const alertMessageDict = [
    {
      error: fetchingProjectsLoading.error,
      message: t({
        id: 'profile.permissions.errors.project_fetching_failed',
        message: 'Fetching projects failed',
      }),
    },
    {
      error: fetchingDisciplinesLoading.error,
      message: t({
        id: 'profile.permissions.errors.discipline_fetching_failed',
        message: 'Fetching disciplines failed',
      }),
    },
    {
      error: fetchingPermissionsLoading.error,
      message: t({
        id: 'profile.permissions.errors.permissions_fetching_failed',
        message: 'Fetching permissions failed',
      }),
    },
  ]
  return <ErrorAlert alertMessageDict={alertMessageDict} />
}

export default UserProfilePermissionsAlert
