import { useDispatch, useSelector } from 'react-redux'
import Text from 'components/atoms/text'
import { Space } from 'antd'
import { useEffect } from 'react'
import {
  selectIsProjectMembersModalVisible,
  selectProjectMembers,
} from 'redux/project/project-config/members/ProjectMembersConfig.selectors'
import {
  fetchProjectMembers,
  setProjectMembersModalVisible,
} from 'redux/project/project-config/members/ProjectMembersConfig.slice'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import Table from 'components/molecules/table'
import styled from 'styled-components'
import ButtonElement from 'components/molecules/button-element'
import { Trans } from '@lingui/macro'
import ProjectMembersModal from './modal'
import ProjectAttributesConfigAlerts from './ProjectMembersConfigAlerts'
import useMembersColumns from './columns/useMembersColumns'

const StyledTable = styled(Table)`
  &&&& {
    width: 700px;
    td {
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        text-align: center;
      }
    }
  }
`

const ProjectMembersConfigContent: React.FC = () => {
  const dispatch = useDispatch()
  const projectId = useSelector(selectProjectId)
  const isModalVisible = useSelector(selectIsProjectMembersModalVisible)
  const members = useSelector(selectProjectMembers)

  useEffect(() => {
    if (projectId) {
      dispatch(fetchProjectMembers(projectId))
    }
  }, [dispatch, projectId])

  const columns = useMembersColumns()

  return (
    <Space direction="vertical">
      <header>
        <Text textStyle="regularText-medium">
          <Trans id="project_config.members.step_header">Users settings</Trans>
        </Text>
      </header>
      <StyledTable
        columns={columns}
        dataSource={members}
        bordered
        pagination={false}
        rowKey="id"
        scroll={{ y: 'calc(100vh - 310px)' }}
      />
      <ButtonElement
        onClick={() => {
          dispatch(setProjectMembersModalVisible(true))
        }}
        type="secondary"
      >
        <Trans id="project_config.members.add_users_button">Add users</Trans>
      </ButtonElement>
      {isModalVisible && <ProjectMembersModal />}
      <ProjectAttributesConfigAlerts />
    </Space>
  )
}

export default ProjectMembersConfigContent
