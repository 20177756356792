import { t } from '@lingui/macro'
import { ColumnsType } from 'antd/lib/table'
import useTableColumnFiltering, { onFilterStringColumn } from 'components/molecules/table/useTableColumnFiltering'
import { Flow } from 'models/flow'

const usePrevFlowsTableColumns: () => ColumnsType<Flow> = () => [
  {
    title: t({ id: 'flow_config.general.prev_flows_table_name_heading', message: 'Flow name' }),
    dataIndex: 'name',
    key: 'name',
    render: (value, { name }) => name,
    ...useTableColumnFiltering(
      t({ id: 'flow_config.general.prev_flows_table_name_filter_placeholder', message: 'Search for name' })
    ),
    onFilter: (value: string | number | boolean, record: Flow) => onFilterStringColumn(value, record.name),
  },
]

export default usePrevFlowsTableColumns
