import styled from 'styled-components'
import FilesOption from 'components/molecules/files/files-option'
import filesSideDrawerOptions from 'components/organisms/files/sidedrawer/FilesSideDrawerOptions'
import TooltipElement from 'components/molecules/tooltip-element'
import trans from 'helpers/i18n.helpers'
import { inPreparationText } from 'data/messages/misc'

const StyledUl = styled.ul`
  > * {
    margin-bottom: ${(props) => props.theme.sizes.default};
  }
`

const FilesOptions: React.FC = () => {
  return (
    <StyledUl>
      {filesSideDrawerOptions.map(({ type, option, tooltip }) => (
        <FilesOption type={type} key={type}>
          <TooltipElement placement="topLeft" title={trans(tooltip || inPreparationText)}>
            {option}
          </TooltipElement>
        </FilesOption>
      ))}
    </StyledUl>
  )
}

export default FilesOptions
