import { Typography } from 'antd'
import styled, { css } from 'styled-components'
import merge from 'deepmerge'
import { TextProps, ElementPropertiesInterface, StyledProps } from './text.types'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const stylesDict: { [key: string]: any } = {
  'regularText-small': {
    $styledProps: {
      fontSize: 's',
    },
  },
  'regularText-smallSemiBold': {
    $styledProps: {
      fontSize: 's',
      fontWeight: 'semiBold',
    },
  },
  'regularText-medium': {
    $styledProps: {
      lineHeight: 'm',
    },
  },
  'regularText-large': {
    $styledProps: {
      fontSize: 'm',
      lineHeight: 'l',
    },
  },
  'header-H1': {
    $styledProps: {
      fontSize: 'm',
      lineHeight: 'l',
      fontWeight: 'semiBold',
    },
    elementType: 'header',
    level: 1,
  },
  'header-H2': {
    $styledProps: {
      lineHeight: 'm',
    },
    elementType: 'header',
    level: 2,
  },
  'header-H2b': {
    $styledProps: {
      lineHeight: 'm',
      fontWeight: 'semiBold',
    },
    elementType: 'header',
    level: 2,
  },
  'hyperlinkText-small': {
    $styledProps: {
      fontSize: 's',
      color: 'blue6',
    },
  },
}

const typographyMixin = css<StyledProps>`
  &.ant-typography {
    font-size: ${(props) => props.theme.sizes[props.$styledProps.fontSize]};
    line-height: ${(props) => props.theme.sizes[props.$styledProps.lineHeight]};
    font-weight: ${(props) => props.theme.fontWeights[props.$styledProps.fontWeight]};
    color: ${(props) => props.theme.colors[props.$styledProps.color]};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    ${(props) => (props.$styledProps.center ? 'display: block; text-align: center;' : '')}
    ${(props) => (props.$styledProps.block ? 'display: block;' : '')}
    margin-bottom: 0;
  }
`

const { Text, Title, Paragraph } = Typography

const StyledText = styled(Text)<StyledProps>`
  ${typographyMixin}
`
const StyledTitle = styled(Title)<StyledProps>`
  ${typographyMixin}
`
const StyledParapgraph = styled(Paragraph)`
  ${typographyMixin}
  &.ant-typography {
    white-space: normal;
  }
`

const TextElement: React.FunctionComponent<TextProps> = ({
  children,
  textStyle,
  color = 'baseBlackish',
  center = false,
  paragraph = false,
  copyable = false,
  block = false,
  ellipsis,
}) => {
  const defaultProps: ElementPropertiesInterface = {
    $styledProps: {
      fontSize: 'default',
      fontWeight: 'default',
      lineHeight: 'default',
      color,
      center,
      block,
    },
    elementType: 'regularText',
    copyable,
  }
  const elementProperties = merge(defaultProps, stylesDict[textStyle])

  if (paragraph) {
    return (
      <StyledParapgraph copyable={copyable} $styledProps={elementProperties.$styledProps} ellipsis={ellipsis}>
        {children}
      </StyledParapgraph>
    )
  }

  if (elementProperties.elementType === 'header') {
    return (
      <StyledTitle
        copyable={copyable}
        level={elementProperties.level}
        $styledProps={elementProperties.$styledProps}
        ellipsis={ellipsis}
      >
        {children}
      </StyledTitle>
    )
  }
  return (
    <StyledText
      copyable={copyable}
      $styledProps={elementProperties.$styledProps}
      ellipsis={typeof ellipsis === 'boolean' ? ellipsis : undefined}
    >
      {children}
    </StyledText>
  )
}

export default TextElement
