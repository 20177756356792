import { defineMessage } from '@lingui/macro'
import { MAX_COMMAND_LENGTH, MAX_DESCRIPTION_LENGTH, MAX_NAME_LENGTH } from 'data/flow/generalConfigValidationData'

export const nameMaxLenghtText = defineMessage({
  id: 'flow_config.general.input_name_max_length_message',
  message: `The name can be up to ${MAX_NAME_LENGTH} characters long`,
})
export const nameRequiredText = defineMessage({
  id: 'flow_config.general.input_name_required_message',
  message: 'Input flow name',
})
export const namePlaceholderText = defineMessage({
  id: 'flow_config.general.input_name_placeholder',
  message: 'Unique flow name',
})
export const descriptionPlaceholderText = defineMessage({
  id: 'flow_config.general.input_description_placeholder',
  message: 'Description for a given flow',
})
export const descriptionRequiredText = defineMessage({
  id: 'flow_config.general.input_description_required_message',
  message: 'Input flow description',
})
export const descriptionMaxLengthText = defineMessage({
  id: 'flow_config.general.input_description_max_length_message',
  message: `The description can be up to ${MAX_DESCRIPTION_LENGTH} characters long`,
})
export const commandPlaceholderText = defineMessage({
  id: 'flow_config.general.input_command_placeholder',
  message: 'Enter command',
})
export const commandRequiredText = defineMessage({
  id: 'flow_config.general.input_command_required_message',
  message: 'Input flow command',
})
export const commandMaxLengthText = defineMessage({
  id: 'flow_config.general.input_command_max_length_message',
  message: `The flow command can be up to ${MAX_COMMAND_LENGTH} characters long`,
})
