import { Dropdown, Menu } from 'antd'
import { LogoutOutlined, ToolOutlined, UserOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { logoutUser } from 'redux/login'
import { useState } from 'react'
import Icon from 'components/atoms/icon'
import { ReactComponent as UserCircle } from 'assets/UserCircle.svg'
import { useHistory, useLocation } from 'react-router'
import { setSelectedProject } from 'redux/user-profile/permissions'
import { setSelectedUserProfileMenuKey } from 'redux/user-profile/user-profile-menu'
import { t } from '@lingui/macro'

interface MainNavBarUserMenuProps {
  setIsVisible: (isVisible: boolean) => void
}

const StyledMenu = styled(Menu)`
  padding: 0;
  background-color: ${(props) => props.theme.colors.baseWhiteish};
`

const MainNavBarUserMenuOverlay: React.FC<MainNavBarUserMenuProps & React.ComponentProps<typeof Menu>> = ({
  setIsVisible,
  selectable,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const handleClick = () => {
    setIsVisible(false)
  }
  const menuItemsProps: {
    icon: JSX.Element
    text: string
    onClick: () => void
  }[] = [
    {
      icon: <UserCircle />,
      text: t({ id: 'user_menu.profile_text', message: 'Profile' }),
      onClick: () => {
        const path = location.pathname.split('/')
        if (path[1] === 'project') {
          const projectId = Number(path[2])
          dispatch(setSelectedProject(projectId))
          dispatch(setSelectedUserProfileMenuKey({ subMenu: 'projectSettings', item: 'permissions' }))
        } else {
          dispatch(setSelectedProject(undefined))
          dispatch(setSelectedUserProfileMenuKey({ subMenu: 'profile', item: 'general' }))
        }
        history.push('/profile')
      },
    },
    {
      icon: <ToolOutlined />,
      text: t({ id: 'user_menu.change_password_text', message: 'Edit password' }),
      onClick: () => history.push('/change-password'),
    },
    {
      icon: <LogoutOutlined />,
      text: t({ id: 'user_menu.logout_text', message: 'Log out' }),
      onClick: () => dispatch(logoutUser()),
    },
  ]
  return (
    <StyledMenu selectable={selectable} onClick={handleClick}>
      {menuItemsProps.map((props) => (
        <Menu.Item key={props.text} icon={props.icon} onClick={props.onClick}>
          {props.text}
        </Menu.Item>
      ))}
    </StyledMenu>
  )
}

const MainNavBarUserMenu: React.FC = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  return (
    <Dropdown
      visible={isDropdownVisible}
      onVisibleChange={setIsDropdownVisible}
      trigger={['click']}
      overlay={<MainNavBarUserMenuOverlay setIsVisible={setIsDropdownVisible} />}
    >
      <Icon>
        <UserOutlined />
      </Icon>
    </Dropdown>
  )
}

export default MainNavBarUserMenu
