import { MapSettings, ProjectDetails, ProjectPermissions } from 'models/project/projectDetails'
import NameAndId from 'models/shared'
import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import { ProjectDetailsState } from '.'
import { Projection } from './ProjectDetails.types'

const selectDetails = (state: RootState): ProjectDetailsState => state.project.details

export const selectProjectDetails = (state: RootState): ProjectDetails | undefined => selectDetails(state).details

export const selectProjectOriginalMapSettings = (state: RootState): MapSettings | undefined =>
  selectDetails(state).details?.mapSettings

const defaultMapSettings = {
  longitude: 19.42356111,
  latitude: 52.11450278,
  scale: 1100000,
}

export const selectProjectMapSettings = (state: RootState): MapSettings =>
  selectProjectOriginalMapSettings(state) || defaultMapSettings

export const selectProjectId: (state: RootState) => number | undefined = (state) => selectProjectDetails(state)?.id

export const selectProjectName = (state: RootState): string | undefined => selectProjectDetails(state)?.name

export const selectProjectPermissions = (state: RootState): ProjectPermissions | undefined =>
  selectProjectDetails(state)?.permissions

export const selectEntryCreationPermission = (state: RootState): boolean =>
  selectProjectPermissions(state)?.canCreateEntry as boolean

export const selectIsProjectAdmin = (state: RootState): boolean | undefined => selectProjectPermissions(state)?.isAdmin

export const selectProjectCreator: (state: RootState) => NameAndId | undefined = (state) =>
  selectProjectDetails(state)?.creator

export const selectProjectIsActive: (state: RootState) => boolean | undefined = (state) =>
  selectProjectDetails(state)?.isActive

export const selectMapProjections: (state: RootState) => Projection[] = (state) => selectDetails(state).projections

export const selectMapCurrentProjection: (state: RootState) => Projection | undefined = (state) =>
  selectDetails(state).currentProjection

export const selectFetchMapProjectionsLoading: (state: RootState) => BackendLoading = (state) =>
  selectDetails(state).loading.fetchPossibleProjections

export const selectFetchProjectDetailsLoading = (state: RootState): BackendLoading =>
  selectDetails(state).loading.fetchProjectDetails
