import { TextProps, TextType } from './text.types'
import Text from './Text'

interface ExpandableTextProps {
  rowsIfFolded?: number
  isExpanded?: boolean
  textStyle?: TextType
}

const ExpandableText: React.FC<Omit<TextProps, 'ellipsis' | 'paragraph' | 'textStyle'> & ExpandableTextProps> = ({
  rowsIfFolded = 2,
  textStyle = 'regularText-small',
  isExpanded = false,
  children,
  ...props
}) => {
  return (
    <Text
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      textStyle={textStyle}
      paragraph
      ellipsis={
        isExpanded
          ? undefined
          : {
              rows: rowsIfFolded,
            }
      }
    >
      {children}
    </Text>
  )
}

export default ExpandableText
