import { Space } from 'antd'
import { CdeStatus } from 'models/files'
import WithOverflow from 'components/atoms/with-overflow'
import CdeStatusItem from 'components/molecules/files/cde-status'

interface FilesTableCellStatusProps {
  status?: CdeStatus
}

const FilesTableCellStatus: React.FC<FilesTableCellStatusProps> = ({ status }) => {
  return (
    <WithOverflow>
      {status && (
        <Space>
          <CdeStatusItem abbreviated status={status} textColor="grayG06" />
        </Space>
      )}
    </WithOverflow>
  )
}

export default FilesTableCellStatus
