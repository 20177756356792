import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Row, Col } from 'antd'
import styled from 'styled-components'
import { fetchNewVersionEntryMetadata } from 'redux/files/files-new-version'
import {
  selectNewVersionEntryMetadata,
  selectNewVersionFile,
  selectFetchNewVersionEntryMetadataLoading,
} from 'redux/files/files-new-version/FilesNewVersion.selectors'
import { formatRevision, ifCharacteristicNotEmpty } from 'helpers/Files.helpers'
import FormSelect from 'components/molecules/form-item/FormSelect'
import { FrontendFile } from 'models/files'
import { SpinModalLayout } from 'components/molecules/modal/Modal'
import { Revision } from 'redux/files/files-documentation/FilesDocumentation.dto'
import { t } from '@lingui/macro'
import trans from 'helpers/i18n.helpers'
import { disciplineText } from 'data/messages/misc'
import { fetchDefaultSuitabilityCode } from 'redux/files/files-suitability'
import {
  selectFetchingFilesDefaultSuitabilityCodeLoading,
  selectFilesDefaultSuitabilityCode,
} from 'redux/files/files-suitability/FilesSuitability.selectors'
import FileSuitabilityCodeItem from 'components/molecules/files/suitability-code/FileSuitabilityCodeItem'
import AddNewFileVersionModalError from './AddNewFileVersionModalError'

const StyledDiv = styled.div`
  width: 100%;
  max-width: 680px; // calculated from modal width minus steps aside and margins
`

function formatNextRevision(revision: Revision): string | undefined {
  const { cdeFolderPrefix, version } = revision
  const newMinor = version.minor ? version.minor + 1 : version.minor
  return formatRevision({ cdeFolderPrefix, version: { major: version.major, minor: newMinor } })
}

const prepareCharacteristics = (frontendFile: FrontendFile) => [
  {
    label: t({ id: 'project.files.add_revision_modal.description', message: 'Description' }),
    value: frontendFile.description,
  },
  {
    label: t({ id: 'project.files.add_revision_modal.current_revision_name', message: 'Name of current revision' }),
    value: frontendFile.filename,
  },
  {
    label: trans(disciplineText),
    value: frontendFile.discipline.name,
  },
  ...ifCharacteristicNotEmpty(
    t({ id: 'project.files.add_revision_modal.new_revision', message: 'New revision' }),
    frontendFile.revision ? formatNextRevision(frontendFile.revision) : undefined
  ),
]

const AddNewFilesVersionAttributes: React.FC = () => {
  const dispatch = useDispatch()
  const newVersionEntryMetadata = useSelector(selectNewVersionEntryMetadata)
  const newVersionFrontendFile = useSelector(selectNewVersionFile)
  const defaultSuitabilityCode = useSelector(selectFilesDefaultSuitabilityCode)
  const { isLoading: isMetadataFetchingLoading } = useSelector(selectFetchNewVersionEntryMetadataLoading)
  const { isLoading: isSuitabilityCodeLoading } = useSelector(selectFetchingFilesDefaultSuitabilityCodeLoading)
  useEffect(() => {
    if (newVersionFrontendFile) dispatch(fetchNewVersionEntryMetadata(newVersionFrontendFile.entryId))
  }, [dispatch, newVersionFrontendFile])
  useEffect(() => {
    dispatch(fetchDefaultSuitabilityCode())
  }, [dispatch])
  if (!newVersionEntryMetadata || !newVersionFrontendFile) return null
  const characteristicsData = prepareCharacteristics(newVersionFrontendFile)
  return (
    <StyledDiv>
      <SpinModalLayout spinning={isMetadataFetchingLoading || isSuitabilityCodeLoading}>
        <Row wrap={false} gutter={[50, 0]}>
          <Col span={12}>
            <Form layout="vertical">
              {newVersionEntryMetadata.map(({ metadata, value }) => (
                <FormSelect
                  key={metadata.name}
                  disabled
                  showArrow={false}
                  label={metadata.name}
                  placeholder={value.value}
                />
              ))}
            </Form>
          </Col>
          <Col span={16}>
            <Form layout="vertical">
              {characteristicsData.map(({ label, value }) => (
                <FormSelect key={label} disabled showArrow={false} label={label} placeholder={value.toString()} />
              ))}
            </Form>
            <FileSuitabilityCodeItem code={defaultSuitabilityCode} />
          </Col>
        </Row>
        <AddNewFileVersionModalError />
      </SpinModalLayout>
    </StyledDiv>
  )
}

export default AddNewFilesVersionAttributes
