import { Form } from 'antd'
import { FormInstance } from 'antd/lib/form'
import useForceUpdate from 'hooks/useForceUpdate'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import getFormValiditySelector from 'redux/forms-validity/FormsValidity.selectors'
import { deleteFormValidation, setFormValidity as setFormValidityAction } from 'redux/forms-validity'
import { NamePath } from 'antd/lib/form/interface'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getFormValidity = (formInstance: FormInstance<any>): boolean => {
  const hasErrors = formInstance.getFieldsError().some(({ errors }) => errors.length)
  return !hasErrors
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getFormItemErrorsHandler = (formInstance: FormInstance<any>): GetFormItemErrors => (name) => {
  const errors = formInstance.getFieldError(name)
  const isTouched = formInstance.isFieldTouched(name)
  return isTouched && errors && (errors.length === 0 ? false : errors)
}

export interface UseFormValidityReturnVal {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: FormInstance<any>
  validate: () => void
  getFormItemErrors: GetFormItemErrors
  isFormValid: boolean
}

export type GetFormItemErrors = (name: NamePath) => NamePath[] | false

export interface WithGetErrors {
  getErrors?: GetFormItemErrors
}

const useFormValidity = (formDataFetched?: boolean, name = 'baseForm'): UseFormValidityReturnVal => {
  const [form] = Form.useForm()
  const forceUpdate = useForceUpdate()
  const dispatch = useDispatch()
  const setFormValidity = useMemo(
    () => (val: boolean) => dispatch(setFormValidityAction({ formName: name, validity: val })),
    [name, dispatch]
  )
  const isFormValid = !!useSelector(getFormValiditySelector(name))
  // initial valdation after first render
  useEffect(() => {
    // sometimes we want to wait with validation until form's data is fetched
    if (formDataFetched === false) {
      setFormValidity(false)
    } else
      form
        .validateFields()
        .then(() => setFormValidity(true))
        .catch(() => setFormValidity(false))
  }, [form, setFormValidity, formDataFetched])

  useEffect(() => {
    return () => {
      dispatch(deleteFormValidation(name))
    }
  }, [name, dispatch])

  const validate = () => {
    setFormValidity(getFormValidity(form))
    forceUpdate()
  }

  return { validate, form, getFormItemErrors: getFormItemErrorsHandler(form), isFormValid }
}

export default useFormValidity
