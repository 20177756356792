import { useSelector } from 'react-redux'
import Text from 'components/atoms/text'
import { selectProjectsTypeSelectState } from 'redux/portfolio/PortfolioProjects.selectors'
import { selectGlobalPermissions } from 'redux/login/Login.selectors'
import styled from 'styled-components'
import { Space } from 'antd'
import { Trans } from '@lingui/macro'

const StyledSection = styled.section`
  position: absolute;
  bottom: 40%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  text-align: center;
`

const EmptyPortfolioContent: React.FC = () => {
  const projectsEnded = useSelector(selectProjectsTypeSelectState).isEnded
  const isAdmin = useSelector(selectGlobalPermissions)?.isAdmin
  const adminEmail = 'admin.systemu@infracde.pl'

  const message = projectsEnded ? (
    <Trans id="portfolio.no_finished_projects_text">No finished projects</Trans>
  ) : (
    <Trans id="portfolio.no_active_projects_text">No active projects</Trans>
  )

  return (
    <StyledSection>
      <Space direction="vertical">
        <Text textStyle="header-H1">{message}</Text>
        {!isAdmin && (
          <Text textStyle="header-H2" color="grayG06">
            <Trans id="portfolio.no_projects_contact_info">
              In case you need more information you may contact system administrator via email: ${adminEmail}
            </Trans>
          </Text>
        )}
      </Space>
    </StyledSection>
  )
}

export default EmptyPortfolioContent
