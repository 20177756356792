function withKeys(components: JSX.Element[]): { component: JSX.Element; key: number }[] {
  return components.map((component, idx) => ({
    component,
    key: idx,
  }))
}

export function typeWithKeys(
  arrayWithTypes: { component: JSX.Element; type: string }[]
): { component: JSX.Element; type: string; key: number }[] {
  return arrayWithTypes.map(({ component, type }, idx) => ({
    component,
    type,
    key: idx,
  }))
}

export function withKeysAndOnClick<
  T extends { component: JSX.Element; onClick?: React.MouseEventHandler<HTMLElement> }
>(arrayWithKeysAndOnClick: T[]): (T & { key: number })[] {
  return arrayWithKeysAndOnClick.map((value, index) => ({
    ...value,
    key: index,
  }))
}

export function recordWithKeys<T>(recordsArray: T[]): (T & { key: number })[] {
  return recordsArray.map((record, idx) => ({ ...record, key: idx }))
}

export const booleanToNumeral = (booleanVal: boolean): number => (booleanVal ? 1 : 0)

export const numeralToBoolean = (numberalVal: number): boolean => numberalVal !== 0

export default withKeys
