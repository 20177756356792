import styled from 'styled-components'

interface ClickableProps {
  $enabled: boolean
}

const Clickable = styled.div<ClickableProps>`
  cursor: ${(props) => (props.$enabled ? 'pointer' : 'auto')};
`

export default Clickable
