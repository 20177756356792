import { t } from '@lingui/macro'
import { Popover } from 'antd'
import Plus from 'components/atoms/plus/Plus'
import TooltipElement from 'components/molecules/tooltip-element'
import { useState } from 'react'
import AddingStagesPopoverContent from './content/AddingStagesPopoverContent'

const AddingStagesPopover: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false)
  const handlePopoverClose = () => setIsVisible(false)
  return (
    <Popover
      visible={isVisible}
      trigger={['click']}
      content={isVisible && <AddingStagesPopoverContent handlePopoverClose={handlePopoverClose} />}
      placement="rightTop"
    >
      <TooltipElement
        title={t({
          id: 'project_config.stages.popover.header',
          message: 'Add stages to project',
        })}
      >
        <Plus onClick={() => setIsVisible((prev) => !prev)} />
      </TooltipElement>
    </Popover>
  )
}

export default AddingStagesPopover
