import { t } from '@lingui/macro'
import { ColumnsType } from 'antd/lib/table'
import useTableColumnFiltering, { onFilterStringColumn } from 'components/molecules/table/useTableColumnFiltering'
import { actionsTableColumnText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import { Flow } from 'models/flow'
import FlowActions from './FlowActions'

const useProjectFlowsTableColumns: () => ColumnsType<Flow> = () => [
  {
    title: t({
      id: 'project_config.flow.table_name_heading',
      message: 'Name',
    }),
    dataIndex: 'name',
    key: 'name',
    width: 50,
    render: (value, { name }) => name,
    ...useTableColumnFiltering(
      t({
        id: 'project_config.flow.table_name_filter_placeholder',
        message: 'Search for name',
      })
    ),
    onFilter: (value: string | number | boolean, record: Flow) => onFilterStringColumn(value, record.name),
  },
  {
    title: t({
      id: 'project_config.flow.table_description_heading',
      message: 'Description',
    }),
    dataIndex: 'description',
    key: 'description',
    width: 250,
    render: (value, { description }) => description,
    ...useTableColumnFiltering(
      t({
        id: 'project_config.flow.table_description_filter_placeholder',
        message: 'Search for description',
      })
    ),
    onFilter: (value: string | number | boolean, record: Flow) => onFilterStringColumn(value, record.description),
  },
  {
    title: t({
      id: 'project_config.flow.table_command_heading',
      message: 'Command',
    }),
    dataIndex: 'command',
    key: 'command',
    width: 50,
    render: (value, { command }) => command,
    ...useTableColumnFiltering(
      t({
        id: 'project_config.flow.table_command_filter_placeholder',
        message: 'Search for command',
      })
    ),
    onFilter: (value: string | number | boolean, record: Flow) => onFilterStringColumn(value, record.command),
  },
  {
    title: trans(actionsTableColumnText),
    dataIndex: 'actions',
    key: 'actions',
    width: 50,
    render: (value, { id, name }) => <FlowActions id={id as number} name={name} />,
  },
]

export default useProjectFlowsTableColumns
