import { MapSettings } from 'models/project/projectDetails'
import { selectFetchProjectDetailsLoading } from 'redux/project/project-details/ProjectDetails.selectors'
import { RootState } from 'redux/store'
import { ProjectMapConfigState } from './ProjectMapConfig.types'

export const selectMapConfigState: (state: RootState) => ProjectMapConfigState = (state) => state.project.config.map

export const selectMapSettingsToUpdate: (state: RootState) => MapSettings = (state) =>
  selectMapConfigState(state).mapSettingsToUpdate

export const selectImageToUpdate: (state: RootState) => string | undefined = (state) =>
  selectMapConfigState(state).imageToUpdate

export const selectIsMapConfigLoading: (state: RootState) => boolean = (state) =>
  selectMapConfigState(state).loading.updateMapConfig.isLoading || selectFetchProjectDetailsLoading(state).isLoading
