import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import PortfolioProject from 'models/portfolio'
import {
  BackendLoading,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
} from 'redux/redux.shared'

const loadingTypes = ['fetchProjects'] as const
type LoadingTypes = typeof loadingTypes[number]

export interface PortfolioProjectsState {
  loading: Record<LoadingTypes, BackendLoading>
  projects?: PortfolioProject[]
  projectsTypeSelectState: ProjectsTypeSelectState
  searchBarValue: string
}

export interface ProjectsTypeSelectState {
  selectElementValue: string
  isEnded: boolean
}

export const fetchProjects = createAsyncBackendThunk(
  'portfolio/fetchProjects',
  async ({ ended }: { ended: boolean }) => {
    const response = await backendAxios.get(`/projects/`, { params: { ended } })
    return response.data
  }
)

const initialState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  projectsTypeSelectState: {
    selectElementValue: 'active',
    isEnded: false,
  },
  searchBarValue: '',
} as PortfolioProjectsState

const portfolioProjectsSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    setProjectsTypeSelectState: (state, action: PayloadAction<ProjectsTypeSelectState>) => {
      state.projectsTypeSelectState = action.payload
    },
    setSearchBarValue: (state, action: PayloadAction<string>) => {
      state.searchBarValue = action.payload
    },
    clearPortfolioProjectsState: () => initialState,
  },
  extraReducers: generateExtraBackendReducers<PortfolioProjectsState, LoadingTypes, PortfolioProject[]>({
    promise: fetchProjects,
    loadingType: 'fetchProjects',
    onFulfilled: (state, action) => {
      state.projects = action.payload
    },
  }),
})

export const {
  setProjectsTypeSelectState,
  setSearchBarValue,
  clearPortfolioProjectsState,
} = portfolioProjectsSlice.actions

export const portfolioProjectsReducer = portfolioProjectsSlice.reducer
