import styled, { Color } from 'styled-components'

interface BackgroundOnHoverWrapperProps {
  color: Color
}

const BackgroundOnHoverWrapper = styled.div<BackgroundOnHoverWrapperProps>`
  :hover {
    background-color: ${(props) => props.theme.colors[props.color]};
  }
`

export default BackgroundOnHoverWrapper
