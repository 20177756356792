import { Checkbox } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { selectCheckedUsers } from 'redux/project/project-config/members-permissions/ProjectMembersPermissionsConfig.selectors'
import {
  checkUser,
  uncheckUser,
} from 'redux/project/project-config/members-permissions/ProjectMembersPermissionsConfig.slice'
import { Member } from 'models/project/projectConfig'
import Text from 'components/atoms/text'

interface UserCheckboxProps {
  user: Member
}

const UserCheckbox: React.FC<UserCheckboxProps> = ({ user }) => {
  const dispatch = useDispatch()
  const { userId, name } = user
  const checkedusers = useSelector(selectCheckedUsers)
  useEffect(() => {
    return () => {
      dispatch(uncheckUser(userId))
    }
  }, [dispatch, userId])
  return (
    <Checkbox
      onChange={(e) => {
        if (e.target.checked) dispatch(checkUser(userId))
        else dispatch(uncheckUser(userId))
      }}
      checked={!!checkedusers.find((checkedUserId) => checkedUserId === userId)}
    >
      <Text textStyle="regularText-small">{name}</Text>
    </Checkbox>
  )
}

export default UserCheckbox
