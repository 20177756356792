import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import { ProjectStatusConfigState, Step } from './ProjectStatusConfig.types'

const selectProjectStagesConfig: (state: RootState) => ProjectStatusConfigState = (state) => state.project.config.status

export const selectFinishedSteps: (state: RootState) => Step[] = (state) =>
  selectProjectStagesConfig(state).finishedSteps

export const selectFinishedStepsLoaded: (state: RootState) => boolean = (state) =>
  selectProjectStagesConfig(state).finishedStepsLoaded

export const selectFetchingProjectConfigFinishedStepsLoading: (state: RootState) => BackendLoading = (state) =>
  selectProjectStagesConfig(state).loading.fetchProjectConfigFinishedSteps

export const selectAddingProjectConfigFinishedStepLoading: (state: RootState) => BackendLoading = (state) =>
  selectProjectStagesConfig(state).loading.fetchProjectConfigFinishedSteps
