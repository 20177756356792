import { Input } from 'antd'
import { FormItemProps } from 'antd/lib/form'
import { InputProps } from 'antd/lib/input'
import { WithGetErrors } from 'hooks/useFormValidity'
import BaseFormItem from './BaseFormItem'

const FormInput: React.FC<FormItemProps & InputProps & WithGetErrors> = ({
  placeholder,
  disabled = false,
  onChange,
  value,
  label,
  name,
  rules,
  size,
  prefix,
  allowClear,
  bordered,
  onFocus,
  onBlur,
  validateStatus,
  type,
  tooltip,
  getErrors,
  help,
  validateFirst,
}) => {
  return (
    <BaseFormItem
      rules={rules}
      label={label}
      name={name}
      validateStatus={validateStatus}
      getErrors={getErrors}
      tooltip={tooltip}
      help={help}
      validateFirst={validateFirst}
    >
      <Input
        size={size}
        prefix={prefix}
        bordered={bordered}
        onFocus={onFocus}
        onBlur={onBlur}
        allowClear={allowClear}
        value={value}
        onChange={onChange}
        placeholder={placeholder && placeholder.toString()}
        disabled={disabled}
        type={type}
      />
    </BaseFormItem>
  )
}
export default FormInput
