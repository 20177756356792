import SpaceElement from 'components/atoms/space'
import styled from 'styled-components'
import Text from 'components/atoms/text'
import { t, Trans } from '@lingui/macro'
import TooltipElement from 'components/molecules/tooltip-element'
import Clickable from 'components/atoms/clickable'
import DefaultIcon from 'components/atoms/icons/DefaultIcon'

const StyledSpace = styled(SpaceElement)`
  align-items: center;
`

interface DefaultSchemeCheckboxProps {
  value?: boolean
  onChange?: (value: boolean) => void
  disableable: boolean
}

const DefaultSchemeCheckbox: React.FC<DefaultSchemeCheckboxProps> = ({ value, onChange, disableable }) => {
  const enabled = !!(onChange && (disableable || !value))
  const color = 'primaryP04'
  return (
    <TooltipElement
      disabled={enabled}
      title={t({
        id: 'administration.naming_scheme.modal.select_as_default_disabled_tooltip',
        message: 'Exactly one scheme must be selected as default',
      })}
    >
      <Clickable onClick={enabled ? () => onChange?.(!value) : undefined} $enabled={enabled && !!onChange}>
        <StyledSpace direction="horizontal" size="s">
          <DefaultIcon checked={!!value} />
          <Text textStyle="regularText-smallSemiBold" color={color}>
            <Trans id="administration.naming_scheme.modal.select_as_default_text">Select as default</Trans>
          </Text>
        </StyledSpace>
      </Clickable>
    </TooltipElement>
  )
}

export default DefaultSchemeCheckbox
