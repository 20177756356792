import { defineMessage, t } from '@lingui/macro'
import ErrorAlert from 'components/atoms/error-alert'
import { handleApiErrors } from 'helpers/HttpError.helpers'
import { useSelector } from 'react-redux'
import {
  selectFetchingActiveUsersLoading,
  selectFetchingProjectsLoading,
  selectRemovingProjectLoading,
} from 'redux/administration/administration-projects/AdministrationProjects.selectors'

const errors = {
  'project.is_active': defineMessage({
    id: 'administration.projects.cannot_delete_active_project_error',
    message: 'Active projects cannot be deleted.',
  }),
}

const AdministrationProjectsAlerts: React.FC = () => {
  const fetchingProjectsLoading = useSelector(selectFetchingProjectsLoading)
  const removingProjectLoading = useSelector(selectRemovingProjectLoading)
  const fetchingUsersLoading = useSelector(selectFetchingActiveUsersLoading)
  const alertMessageDict = [
    {
      error: fetchingProjectsLoading.error,
      message: t({ id: 'administration.projects.fetch_failed', message: 'Could not retrieve projects' }),
    },
    {
      error: fetchingUsersLoading.error,
      message: t({ id: 'administration.projects.fetching_users_failed', message: 'Could not retrieve users' }),
    },
    {
      error: removingProjectLoading.error,
      message: handleApiErrors(errors, removingProjectLoading.error?.errors),
    },
  ]
  return <ErrorAlert alertMessageDict={alertMessageDict} />
}

export default AdministrationProjectsAlerts
