import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getFlowConditions } from 'redux/flow-conifg/conditions/FlowConditionsConfig.slice'
import {
  selectFlowConsequencesValidity,
  selectIsConsequencesConfigContentLoading,
} from 'redux/flow-conifg/consequences/FlowConsequencesConfig.selectors'
import {
  fetchSuitabilityOverview,
  getFlowConsequences,
  updateFlowConsequences,
} from 'redux/flow-conifg/consequences/FlowConsequencesConfig.slice'
import { getFlowDecisions } from 'redux/flow-conifg/decisions/FlowDecisionsConfig.slice'
import { selectFlowId } from 'redux/flow-conifg/general/FlowGeneralConfig.selectors'
import { fetchFlows } from 'redux/project/project-config/flow/ProjectFlowConfig.slice'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import { useAppDispatch } from 'redux/store'
import BaseFlowConfigView from '../base-flow-config-view/BaseFlowConfigView'
import FlowDecisionsConfigContent from './content/FlowConsequencesConfigContent'

const FlowConsequencesConfig: React.FC = () => {
  const isLoading = useSelector(selectIsConsequencesConfigContentLoading)
  const consequencesValidity = useSelector(selectFlowConsequencesValidity)
  const dispatch = useAppDispatch()
  const projectId = useSelector(selectProjectId)
  const flowId = useSelector(selectFlowId)

  useEffect(() => {
    if (projectId && flowId) {
      dispatch(getFlowDecisions({ flowId, projectId }))
      dispatch(getFlowConditions({ projectId, flowId }))
      dispatch(getFlowConsequences({ flowId, projectId }))
    }
  }, [dispatch, flowId, projectId])
  useEffect(() => {
    if (projectId) {
      dispatch(fetchFlows(projectId))
      dispatch(fetchSuitabilityOverview())
    }
  }, [dispatch, projectId])

  if (!flowId || !projectId) return null
  const savingThunk = updateFlowConsequences({ projectId, flowId })
  return (
    <BaseFlowConfigView
      isContentValid={consequencesValidity}
      saveStepThunk={savingThunk}
      content={<FlowDecisionsConfigContent />}
      isContentLoading={isLoading}
      stepName="EXIT_CHANGES"
    />
  )
}

export default FlowConsequencesConfig
